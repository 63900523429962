import { Container } from '@mui/material';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography/Typography';
import { FC } from 'react';
import GenericTemplate from '../../../template/GenericTemplate';
import { ErrorData } from './StudentBulkInsertErrorData';
import { StudentBulkInsertRequest } from 'API';
import { FormControl, FormHelperText } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField/TextField';

const styles = {
  categoryTitle: {
    borderLeft: 5,
    borderColor: '#0058a0',
    pl: '10px',
    mb: '30px',
  },

  loadButton: {
    minWidth: '120px',
    fontWeight: 'bold',
    ml: 1,
  },

  textField: {
    width: '100%',
    '& input': {
      padding: '8px 14px',
    },
  },

  button: {
    minWidth: '80px',
    fontWeight: 'bold',
  },

  fileName: {
    width: '60%',
    float: 'right',
    border: 'solid 1px #bdbdbd',
    display: 'flex',
    borderRadius: '5px',
    minHeight: 42,
    padding: 0,
    textAlign: 'center',
  },

  boxRoot: {
    margin: 'auto',
    paddingTop: '30px',
    paddingBottom: '50px',
    maxWidth: '1000px',
  },
};

export interface Props {
  errorAttribute: ErrorData;
  message: string;
  inputData: StudentBulkInsertRequest;
  handleClickDownloadSample: () => void;
  handleChangeUploadFile: (_: React.ChangeEvent<HTMLInputElement>) => void;
  executeBulkInsert: () => void;
  handleClose: () => void;
  handleMessageDialogClose: () => void;
  handleDeleteUploadFile: () => void;
}

const UseStudentBulkInsert: FC<Props> = (props) => {
  return (
    <GenericTemplate title="生徒一括登録">
      <Container>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          生徒情報をアップロードします。
        </Typography>
        <Grid container>
          <Grid item xs={6} style={{ paddingLeft: 120 }}>
            <TextField
              sx={styles.textField}
              value={props.inputData.UploadFileName}
              disabled
              error={props.errorAttribute.UploadFileName.IsError}
              helperText={props.errorAttribute.UploadFileName.ErrorMessage}
            />
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: 10 }}>
            {!!props.inputData.UploadFileName ? (
              <Button variant="outlined" onClick={props.handleDeleteUploadFile}>
                削除
              </Button>
            ) : (
              <label htmlFor="uploadFile">
                <input id="uploadFile" type="file" hidden onChange={props.handleChangeUploadFile} />
                <Button variant="outlined" component="span">
                  参照
                </Button>
              </label>
            )}
          </Grid>
        </Grid>
        <Box sx={styles.boxRoot}>
          <Grid container>
            <Typography style={{ fontSize: '15px' }}>
              * アップロードできるファイルは、拡張子が「.csv」のものに限ります。
            </Typography>
          </Grid>
          <Grid container>
            <Typography style={{ fontSize: '15px' }}>
              *
              一度にアップロードする生徒数は、「1000」以下にしてください（1000件以上登録する場合はファイルを別けてください）。
            </Typography>
          </Grid>
          <Grid container>
            <Typography style={{ fontSize: '15px' }}>
              *アップロードするファイルの記入方法は、サンプルを参考にしてください。下記の「サンプルダウンロード」ボタンよりダウンロードできます。
            </Typography>
          </Grid>
          <Grid container>
            <Typography style={{ fontSize: '15px' }}>
              * 記入項目は次の通りです。必須とある項目は入力がないとエラーになります。
            </Typography>
          </Grid>
          <Grid container style={{ paddingLeft: '20px' }}>
            <Typography style={{ fontSize: '15px' }}>* 1列目：組（数値）必須</Typography>
          </Grid>
          <Grid container style={{ paddingLeft: '20px' }}>
            <Typography style={{ fontSize: '15px' }}>* 2列目：生徒番号（数値）必須</Typography>
          </Grid>
          <Grid container style={{ paddingLeft: '20px' }}>
            <Typography style={{ fontSize: '15px' }}>* 3列目：生徒名（文字列）必須</Typography>
          </Grid>
          <Grid container style={{ paddingLeft: '20px' }}>
            <Typography style={{ fontSize: '15px' }}>* 4列目：生徒カナ名（文字列）必須</Typography>
          </Grid>
          <Grid container style={{ paddingLeft: '20px' }}>
            <Typography style={{ fontSize: '15px' }}>
              * 5列目：性別（数値。1:男性 or 2:女性 or 3:その他）必須
            </Typography>
          </Grid>
          <Grid container style={{ paddingLeft: '20px' }}>
            <Typography style={{ fontSize: '15px' }}>* 6列目：アレルギー（文字列）</Typography>
          </Grid>
          <Grid container style={{ paddingLeft: '20px' }}>
            <Typography style={{ fontSize: '15px' }}>* 7列目：持病（文字列）</Typography>
          </Grid>
          <Grid container style={{ paddingLeft: '20px' }}>
            <Typography style={{ fontSize: '15px' }}>* 8列目：不安事項（文字列）</Typography>
          </Grid>
          <Grid container style={{ paddingLeft: '20px' }}>
            <Typography style={{ fontSize: '15px' }}>* 9列目：注意事項（文字列）</Typography>
          </Grid>
          <Grid container style={{ paddingLeft: '20px' }}>
            <Typography style={{ fontSize: '15px' }}>* 10列目：民家ID（数値）※入力不要 </Typography>
          </Grid>
          <Grid container>
            <Typography style={{ fontSize: '15px' }}>
              * 組と生徒の組み合わせで、一意となるようにしてください。例えば、1組1番が2つあるとエラーになります。
            </Typography>
          </Grid>
          <Grid container>
            <Typography style={{ fontSize: '15px' }}>
              * 一行目はヘッダーです。2行目より生徒情報を記入してください。
            </Typography>
          </Grid>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" sx={styles.loadButton} onClick={props.handleClickDownloadSample}>
            サンプルダウンロード
          </Button>
          <Button variant="contained" sx={styles.loadButton} onClick={props.executeBulkInsert}>
            アップロード
          </Button>
          <Button variant="contained" sx={styles.loadButton} onClick={props.handleClose}>
            閉じる
          </Button>
        </Box>
        <Dialog open={props.message !== ''} onClose={props.handleMessageDialogClose}>
          <DialogContent>
            <DialogContentText>{props.message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleMessageDialogClose} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </GenericTemplate>
  );
};
export default UseStudentBulkInsert;
