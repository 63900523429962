import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Notification } from 'API';
import { FC, useState } from 'react';
import GenericTemplate from 'views/template/GenericTemplate';
import Link from '@mui/material/Link';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Grid from '@mui/material/Grid';
import { getDisplayDate } from 'util/DateUtil';
import TextField from '@mui/material/TextField';

export interface Props {
  systemNotifications: Notification[];
  reserveNotifications: Notification[];
}

const styles = {
  categoryHeader: {
    fontWeight: 'bold',
  },
  label: {
    mt: '7px',
  },
  categoryTitle: {
    borderLeft: 5,
    borderColor: '#0058a0',
    pl: '10px',
  },
  categoryBlock: {
    mt: '5px',
    mb: '10px',
  },
};

const Home: FC<Props> = ({ systemNotifications, reserveNotifications }) => {
  const [systemPage, setSystemPage] = useState(0);
  const [systemRowsPerPage, setSystemRowsPerPage] = useState(10);
  const [reservePage, setReservePage] = useState(0);
  const [reserveRowsPerPage, setReserveRowsPerPage] = useState(10);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [notification, setNotification] = useState({} as Notification);

  const handleClickDialogOpen = (notification: Notification) => {
    setNotification(notification);
    setDialogOpen(true);
  };

  const handleClickDialogClose = () => {
    setNotification({} as Notification);
    setDialogOpen(false);
  };

  const handleChangeSystemPage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setSystemPage(newPage);
  };

  const handleChangeRowsPerSystemPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSystemRowsPerPage(+event.target.value);
    setSystemPage(0);
  };

  const handleChangeReservePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setReservePage(newPage);
  };

  const handleChangeRowsPerReservePage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReserveRowsPerPage(+event.target.value);
    setReservePage(0);
  };

  return (
    <GenericTemplate title="OKI-JOURNEY(オキジャニ) 民泊予約管理システム - HOME">
      <>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          お知らせ
        </Typography>
        <TableContainer sx={{ minHeight: 600 }}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableBody>
              {systemNotifications
                .slice(systemPage * systemRowsPerPage, systemPage * systemRowsPerPage + systemRowsPerPage)
                .map((notification, index) => (
                  <TableRow key={index}>
                    <TableCell component="td" scope="row" sx={{ width: '200px' }}>
                      {getDisplayDate(new Date(notification.NotificationDate), true)}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      <Link
                        href="javascript:void(0)"
                        underline="always"
                        onClick={() => handleClickDialogOpen(notification)}
                      >
                        {notification.Subject}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={systemNotifications.length}
            rowsPerPage={systemRowsPerPage}
            page={systemPage}
            onPageChange={handleChangeSystemPage}
            onRowsPerPageChange={handleChangeRowsPerSystemPage}
          />
        </TableContainer>
        {/* 
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          予約に関するお知らせ
        </Typography>
        <TableContainer sx={{ minHeight: 300 }}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableBody>
              {reserveNotifications
                .slice(reservePage * reserveRowsPerPage, reservePage * reserveRowsPerPage + reserveRowsPerPage)
                .map((notification, index) => (
                  <TableRow key={index}>
                    <TableCell component="td" scope="row" sx={{ width: '200px' }}>
                      {getDisplayDate(new Date(notification.NotificationDate), true)}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      <Link
                        href="javascript:void(0)"
                        underline="always"
                        onClick={() => handleClickDialogOpen(notification)}
                      >
                        {notification.Subject}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[7, 25, 100]}
            component="div"
            count={reserveNotifications.length}
            rowsPerPage={reserveRowsPerPage}
            page={reservePage}
            onPageChange={handleChangeReservePage}
            onRowsPerPageChange={handleChangeRowsPerReservePage}
          />
        </TableContainer> */}

        <Dialog open={dialogOpen} onClose={handleClickDialogClose} maxWidth={'md'}>
          <DialogTitle>
            <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
              {notification.Subject}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} sx={styles.categoryBlock}>
              <Grid item xs={3}>
                <Typography sx={styles.label}>通知日</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography sx={styles.label}>
                  {getDisplayDate(new Date(notification.NotificationDate), true)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={styles.label}>通知内容</Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  required
                  fullWidth
                  hiddenLabel
                  name="Contents"
                  value={notification.Contents}
                  multiline
                  rows="7"
                  sx={styles.label}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickDialogClose}>閉じる</Button>
          </DialogActions>
        </Dialog>
      </>
    </GenericTemplate>
  );
};
export default Home;
