import { Flex, Text, useTheme } from '@aws-amplify/ui-react';
import Box from '@mui/material/Box';

const Footer = () => {
  const { tokens } = useTheme();

  return (
    <Box
      component="footer"
      sx={{
        py: 1,
        px: 2,
        mt: 'auto',
        backgroundColor: (theme) =>
          theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
      }}
    >
      <Flex justifyContent="center" padding={tokens.space.medium}>
        <Text>&copy; TM.Planning. All Rights Reserved.</Text>
      </Flex>
    </Box>
  );
};

export default Footer;
