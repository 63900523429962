import FormErrorAttribute from 'types/FormErrorAttribute';

export interface ErrorData {
  Header: FormErrorAttribute;
  ReservedDays: FormErrorAttribute;
  SchoolConfirmationDoc: FormErrorAttribute;
}

export const CreateEmptyErrorData = () => {
  return {
    Header: {} as FormErrorAttribute,
    ReservedDays: {} as FormErrorAttribute,
    SchoolConfirmationDoc: {} as FormErrorAttribute,
  } as ErrorData;
};
