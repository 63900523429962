import { FC } from 'react';
import SessionInvalidMessageDialog from './SessionInvalidMessageDialog';
import UseSessionInvalidMessageDialog from './UseSessionInvalidMessageDialog';

export interface Props {
  handleDialogClose: () => void;
  dialogOpen: boolean;
}

const SessionInvalidMessageDialogContainer: FC<Props> = (props: Props) => {
  return <SessionInvalidMessageDialog {...UseSessionInvalidMessageDialog(props)} />;
};

export default SessionInvalidMessageDialogContainer;
