import { useAuthenticator } from '@aws-amplify/ui-react';
import {
  AddReservationAgentMutation,
  Branch,
  CompanyBranch,
  RegistReservationAgent,
  ReservationAgent,
  ReservationDay,
  UpdateReservationAgentMutation,
} from 'API';
import { useAPI } from 'contexts/APIRequestContext';
import { useMaster } from 'contexts/CommonMasterContext';
import { addReservationAgent, updateReservationAgent } from 'graphql/mutations';
import { useCallback, useEffect, useState } from 'react';
import { CreateEmptyErrorData, ErrorData } from './AgentDialogErrorData';

const UseAgentDialog = ({
  handleDialogClose,
  dialogOpen,
  reservationId,
  updateDatetime,
  agent,
  mode,
  reservationDays,
  schoolConfirmed,
}: {
  handleDialogClose: (_?: { updateDatetime: string; agent: ReservationAgent }) => void;
  dialogOpen: boolean;
  reservationId: string;
  updateDatetime: string;
  agent: ReservationAgent;
  mode: string;
  reservationDays: ReservationDay[];
  schoolConfirmed: boolean;
}) => {
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [inputData, setInputData] = useState({} as ReservationAgent);
  const [companyList, setCompanyList] = useState([] as CompanyBranch[]);
  const [branchList, setBranchList] = useState([] as Branch[]);
  const master = useMaster();
  const api = useAPI();
  const { user } = useAuthenticator();
  const [, setError] = useState<undefined>();
  const [canAllDept, setCanAllDept] = useState(false);

  useEffect(() => {
    if (dialogOpen) {
      master.waitForInitialized(() => {
        setCompanyList(master.getCompanies().filter((x) => x.CategoryCode === '03' && x.IsActive));
        setCanAllDept((user as any).attributes['custom:can_all_dept'] === 'True');

        if (
          (user as any).attributes['custom:authority_code'] !== '1' &&
          (user as any).attributes['custom:authority_code'] !== '2' &&
          mode !== 'MODIFY'
        ) {
          const companyId = (user as any).attributes['custom:companyid'];
          agent.CompanyId = companyId;
          // 旅行代理店の場合で、ログインユーザの所属支店を選択したい場合は、以下のコメントを解除
          const branchId = (user as any).attributes['custom:branchid'];
          agent.BookingBranchId = branchId;

          var bookingUserMail = master.getBranchBookingMailJoinedByComma(companyId, branchId);
          if (!!bookingUserMail) {
            agent.BookingUserMail = bookingUserMail;
          }
          var branchTel = master.getBranchTel(companyId, branchId);
          if (!!branchTel) {
            agent.BookingUserTel = branchTel;
          }
          if (!canAllDept) {
            agent.SalesBranchId = branchId;

            var salesUserMail = master.getBranchSalesMailJoinedByComma(companyId, branchId);
            if (!!salesUserMail) {
              agent.SalesUserMail = salesUserMail;
            }
            if (!!branchTel) {
              agent.SalesUserTel = branchTel;
            }
          }
        }

        if (agent.CompanyId) {
          var _branchList = master.getBranchs(agent.CompanyId) as Branch[];
          if (_branchList) {
            setBranchList(_branchList.filter((x) => x.IsActive));
          } else {
            setBranchList([] as Branch[]);
          }
        }

        setInputData(agent);
      });

      setErrorAttribute({ ...CreateEmptyErrorData() });
    } else {
      setInputData({} as ReservationAgent);
    }
  }, [dialogOpen]);

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  /**
   * 代理店情報　代理店名更新
   * @param event イベント
   */
  const handleChangeCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Step1. 通常の入力値変更と店名クリア
    inputData.CompanyId = event.target.value;
    inputData.BookingBranchId = '';
    inputData.SalesBranchId = '';
    inputData.BookingUserMail = '';
    inputData.BookingUserTel = '';
    inputData.SalesUserMail = '';
    inputData.SalesUserTel = '';

    setInputData({ ...inputData });
    setErrorAttribute({
      ...errorAttribute,
      BookingBranchId: { IsError: false, ErrorMessage: '' },
      SalesBranchId: { IsError: false, ErrorMessage: '' },
      BookingUserMail: { IsError: false, ErrorMessage: '' },
      BookingUserTel: { IsError: false, ErrorMessage: '' },
      SalesUserMail: { IsError: false, ErrorMessage: '' },
      SalesUserTel: { IsError: false, ErrorMessage: '' },
      [event.target.name]: { IsError: false, ErrorMessage: '' },
    });

    // Step2. 支店プルダウンデータ変更
    var _branchList = master.getBranchs(event.target.value) as Branch[];
    if (_branchList) {
      setBranchList(_branchList.filter((x) => x.IsActive));
    } else {
      setBranchList([] as Branch[]);
    }
  };

  /**
   * 代理店情報　手配担当店名更新
   * @param event イベント
   */
  const handleChangeBookingBranch = (event: React.ChangeEvent<HTMLInputElement>) => {
    inputData.BookingBranchId = event.target.value;
    const branch = branchList.filter((x) => x.BranchId === event.target.value)[0];
    if (branch) {
      var bookingUserMail = master.getBranchBookingMailJoinedByComma(branch.CompanyId, branch.BranchId);
      if (!!bookingUserMail) {
        inputData.BookingUserMail = bookingUserMail;
        setErrorAttribute({
          ...errorAttribute,
          BookingUserMail: { IsError: false, ErrorMessage: '' },
        });
      } else {
        inputData.BookingUserMail = '';
      }
      if (!!branch.Tel) {
        inputData.BookingUserTel = branch.Tel;
        setErrorAttribute({
          ...errorAttribute,
          BookingUserTel: { IsError: false, ErrorMessage: '' },
        });
      }
    }
    setInputData({ ...inputData });

    // 値が変更されたらエラー情報をクリア
    setErrorAttribute({ ...errorAttribute, BookingBranchId: { IsError: false, ErrorMessage: '' } });
  };

  /**
   * 代理店情報　営業担当店名更新
   * @param event イベント
   */
  const handleChangeSalesBranch = (event: React.ChangeEvent<HTMLInputElement>) => {
    inputData.SalesBranchId = event.target.value;
    const branch = branchList.filter((x) => x.BranchId === event.target.value)[0];

    if (branch) {
      var salesUserMail = master.getBranchSalesMailJoinedByComma(branch.CompanyId, branch.BranchId);
      if (!!salesUserMail) {
        inputData.SalesUserMail = salesUserMail;
        setErrorAttribute({
          ...errorAttribute,
          SalesUserMail: { IsError: false, ErrorMessage: '' },
        });
      } else {
        inputData.SalesUserMail = '';
      }
      if (!!branch.Tel) {
        inputData.SalesUserTel = branch.Tel;
        setErrorAttribute({
          ...errorAttribute,
          SalesUserTel: { IsError: false, ErrorMessage: '' },
        });
      }
    }
    setInputData({ ...inputData });

    // 値が変更されたらエラー情報をクリア
    setErrorAttribute({ ...errorAttribute, SalesBranchId: { IsError: false, ErrorMessage: '' } });
  };

  /**
   * 代理店情報　代理店情報更新
   * @param event イベント
   */
  const handleChangeAgentInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    (inputData[event.target.name as keyof ReservationAgent] as any) = event.target.value;
    setInputData({ ...inputData });

    // 値が変更されたらエラー情報をクリア
    setErrorAttribute({ ...errorAttribute, [event.target.name]: { isError: false, errorMessage: '' } });
  };

  /**
   * 予約代理店追加処理
   * @param data リクエストパラメータ
   */
  const executeAddReservationAgent = async (data: RegistReservationAgent): Promise<string | undefined> => {
    const response = (
      (await api.graphql({
        query: addReservationAgent,
        variables: { data },
      })) as AddReservationAgentMutation
    ).addReservationAgent;

    if (response?.IsSuccess) {
      return response.Body!;
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  };

  /**
   * 予約代理店更新処理
   * @param data リクエストパラメータ
   */
  const executeUpdateReservationAgent = async (data: RegistReservationAgent): Promise<string | undefined> => {
    const response = (
      (await api.graphql({
        query: updateReservationAgent,
        variables: { data },
      })) as UpdateReservationAgentMutation
    ).updateReservationAgent;

    if (response?.IsSuccess) {
      return response.Body!;
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  };

  /**
   * 行程表変更時
   * @param event イベント
   */
  const handleChangeItinerary = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files && event.target.files.length) {
      if (event.target.files[0].size > 500 * 1024) {
        setErrorAttribute({
          ...errorAttribute,
          Itinerary: { IsError: true, ErrorMessage: 'ファイルサイズは、「500KB」以下にしてください。' },
        });
        return;
      } else {
        setErrorAttribute({
          ...errorAttribute,
          Itinerary: { IsError: false, ErrorMessage: '' },
        });
      }

      inputData.ItineraryName = event.target.files[0].name;

      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        setInputData({
          ...inputData,
          ItineraryName: inputData.ItineraryName,
          Itinerary: (reader.result as string).split(';base64,').pop(),
        });
      };
    }
  };

  /**
   * 行程表削除時
   */
  const handleDeleteItinerary = (): void => {
    setInputData({ ...inputData, Itinerary: '', ItineraryName: '' });
    setErrorAttribute({
      ...errorAttribute,
      Itinerary: { IsError: false, ErrorMessage: '' },
    });
  };

  /**
   * 学校決定通知書変更時
   * @param event イベント
   */
  const handleChangeSchoolConfirmationDoc = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files && event.target.files.length) {
      if (event.target.files[0].size > 500 * 1024) {
        setErrorAttribute({
          ...errorAttribute,
          SchoolConfirmationDoc: { IsError: true, ErrorMessage: 'ファイルサイズは、「500KB」以下にしてください。' },
        });
        return;
      } else {
        setErrorAttribute({
          ...errorAttribute,
          SchoolConfirmationDoc: { IsError: false, ErrorMessage: '' },
        });
      }

      inputData.SchoolConfirmationDocName = event.target.files[0].name;

      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        setInputData({
          ...inputData,
          SchoolConfirmationDocName: inputData.SchoolConfirmationDocName,
          SchoolConfirmationDoc: (reader.result as string).split(';base64,').pop(),
        });
      };
    }
  };

  /**
   * 学校決定通知書削除時
   */
  const handleDeleteSchoolConfirmationDoc = (): void => {
    setInputData({ ...inputData, SchoolConfirmationDoc: '', SchoolConfirmationDocName: '' });
    setErrorAttribute({
      ...errorAttribute,
      SchoolConfirmationDoc: { IsError: false, ErrorMessage: '' },
    });
  };

  /**
   * 決定通知書変更時
   * @param event イベント
   */
  const handleChangeAgentConfirmationDoc = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files && event.target.files.length) {
      if (event.target.files[0].size > 500 * 1024) {
        setErrorAttribute({
          ...errorAttribute,
          AgentConfirmationDoc: { IsError: true, ErrorMessage: 'ファイルサイズは500KB以下にしてください。' },
        });
        return;
      } else {
        setErrorAttribute({
          ...errorAttribute,
          AgentConfirmationDoc: { IsError: false, ErrorMessage: '' },
        });
      }

      inputData.AgentConfirmationDocName = event.target.files[0].name;

      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        setInputData({
          ...inputData,
          AgentConfirmationDocName: inputData.AgentConfirmationDocName,
          AgentConfirmationDoc: (reader.result as string).split(';base64,').pop(),
        });
      };
    }
  };

  /**
   * 決定通知書削除時
   */
  const handleDeleteAgentConfirmationDoc = (): void => {
    setInputData({ ...inputData, AgentConfirmationDocName: '', AgentConfirmationDoc: '' });
    setErrorAttribute({
      ...errorAttribute,
      AgentConfirmationDoc: { IsError: false, ErrorMessage: '' },
    });
  };

  /**
   * 決定確認書変更時
   * @param event イベント
   */
  const handleChangeCenterConfirmationDoc = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files && event.target.files.length) {
      if (event.target.files[0].size > 500 * 1024) {
        setErrorAttribute({
          ...errorAttribute,
          CenterConfirmationDoc: { IsError: true, ErrorMessage: 'ファイルサイズは500KB以下にしてください。' },
        });
        return;
      } else {
        setErrorAttribute({
          ...errorAttribute,
          CenterConfirmationDoc: { IsError: false, ErrorMessage: '' },
        });
      }

      inputData.CenterConfirmationDocName = event.target.files[0].name;

      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        setInputData({
          ...inputData,
          CenterConfirmationDocName: inputData.CenterConfirmationDocName,
          CenterConfirmationDoc: (reader.result as string).split(';base64,').pop(),
        });
      };
    }
  };

  /**
   * 決定確認書削除時
   */
  const handleDeleteCenterConfirmationDoc = (): void => {
    setInputData({ ...inputData, CenterConfirmationDocName: '', CenterConfirmationDoc: '' });
    setErrorAttribute({
      ...errorAttribute,
      CenterConfirmationDoc: { IsError: false, ErrorMessage: '' },
    });
  };

  /**
   * 代理店情報　登録
   */
  const handleRegist = async (): Promise<void> => {
    // 代理店名、手配担当店名、営業担当店名
    if (inputData.CompanyId) {
      const company = companyList.find((x) => x.CompanyId === inputData.CompanyId);
      inputData.CompanyName = company!.CompanyName;
      inputData.BookingBranchName = company!.BranchList.find(
        (x) => x.BranchId === inputData.BookingBranchId
      )?.BranchName!;
      inputData.SalesBranchName = company!.BranchList.find((x) => x.BranchId === inputData.SalesBranchId)?.BranchName;
    } else {
      inputData.CompanyName = '';
      inputData.BookingBranchId = '';
      inputData.BookingBranchName = '';
      inputData.SalesBranchId = '';
      inputData.SalesBranchName = '';
    }

    if (mode) {
      const data = {
        ReservationId: reservationId,
        CompanyId: inputData.CompanyId,
        BookingBranchId: inputData.BookingBranchId,
        BookingUserName: inputData.BookingUserName,
        BookingUserTel: inputData.BookingUserTel,
        BookingUserMail: inputData.BookingUserMail,
        SalesBranchId: inputData.SalesBranchId,
        SalesUserName: inputData.SalesUserName,
        SalesUserTel: inputData.SalesUserTel,
        SalesUserMail: inputData.SalesUserMail,
        Itinerary: inputData.Itinerary,
        ItineraryName: inputData.ItineraryName,
        AgentConfirmationDoc: inputData.AgentConfirmationDoc,
        AgentConfirmationDocName: inputData.AgentConfirmationDocName,
        CenterConfirmationDoc: inputData.CenterConfirmationDoc,
        CenterConfirmationDocName: inputData.CenterConfirmationDocName,
        SchoolConfirmationDoc: inputData.SchoolConfirmationDoc,
        SchoolConfirmationDocName: inputData.SchoolConfirmationDocName,
        Note: inputData.Note,
        UpdateDatetime: updateDatetime,
      } as RegistReservationAgent;

      if (['NEW', 'SHARE'].includes(mode)) {
        const updateDatetime = await executeAddReservationAgent(data);
        if (updateDatetime) {
          handleDialogClose({ updateDatetime, agent: inputData });
        }
        return;
      } else if (mode === 'MODIFY') {
        const updateDatetime = await executeUpdateReservationAgent(data);
        if (updateDatetime) {
          handleDialogClose({ updateDatetime, agent: inputData });
        }
      }
    } else {
      handleDialogClose({ updateDatetime: '', agent: inputData });
    }
  };

  /**
   * 代理店情報　閉じる
   */
  const handleClose = (): void => {
    handleDialogClose();
  };

  return {
    user,
    inputData,
    companyList,
    branchList,
    mode,
    reservationDays,
    schoolConfirmed,
    canAllDept,
    handleChangeCompany,
    handleChangeBookingBranch,
    handleChangeSalesBranch,
    handleChangeAgentInput,
    handleChangeItinerary,
    handleDeleteItinerary,
    handleChangeSchoolConfirmationDoc,
    handleDeleteSchoolConfirmationDoc,
    handleChangeAgentConfirmationDoc,
    handleDeleteAgentConfirmationDoc,
    handleChangeCenterConfirmationDoc,
    handleDeleteCenterConfirmationDoc,
    handleRegist,
    handleClose,
    dialogOpen,
    errorAttribute,
  };
};
export default UseAgentDialog;
