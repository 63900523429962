import { CancelReservationMutation, RegistCancelReservation } from 'API';
import { useAPI } from 'contexts/APIRequestContext';
import { cancelReservation } from 'graphql/mutations';
import { useCallback, useEffect, useState } from 'react';
import { CreateEmptyErrorData, ErrorData } from './CancelDialogErrorData';

const UseCancelDialog = ({
  handleDialogClose,
  dialogOpen,
  reservationId,
  updateDatetime,
}: {
  handleDialogClose: () => void;
  dialogOpen: boolean;
  reservationId: string;
  updateDatetime: string;
}) => {
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [inputData, setInputData] = useState('');
  const api = useAPI();
  const [, setError] = useState<undefined>();

  useEffect(() => {
    if (dialogOpen) {
    } else {
      setInputData('');
    }
  }, [dialogOpen]);

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  /**
   * キャンセル　キャンセル理由更新
   * @param event イベント
   */
  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputData(event.target.value);

    // 値が変更されたらエラー情報をクリア
    setErrorAttribute({ ...errorAttribute, [event.target.name]: { isError: false, errorMessage: '' } });
  };

  /**
   * 予約取消を行う
   * @param reservationId 予約ID
   * @param cancelReason キャンセル理由
   */
  const executeCancelReservation = async (): Promise<void> => {
    const response = (
      (await api.graphql({
        query: cancelReservation,
        variables: {
          data: {
            ReservationId: reservationId,
            CancelledReason: inputData,
            UpdateDatetime: updateDatetime,
          } as RegistCancelReservation,
        },
      })) as CancelReservationMutation
    ).cancelReservation;

    if (response?.IsSuccess) {
      window.history.back();
      return;
    } else if (response?.IsSystemError) {
      throwError(response.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  };

  /**
   * キャンセル　登録
   */
  const handleRegist = (): void => {
    executeCancelReservation();
  };

  /**
   * キャンセル　閉じる
   */
  const handleClose = (): void => {
    handleDialogClose();
  };

  return {
    inputData,
    handleChangeInput,
    handleRegist,
    handleClose,
    dialogOpen,
    errorAttribute,
  };
};
export default UseCancelDialog;
