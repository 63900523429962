import { MSchools } from 'API';
import { FC } from 'react';
import SchoolSearchDialog from './SchoolSearchDialog';
import UseSchoolSearchDialog from './UseSchoolSearchDialog';

export interface Props {
  handleDialogClose: (_?: MSchools) => void;
  dialogOpen: boolean;
}

const SchoolSearchDialogContainer: FC<Props> = (props: Props) => {
  return <SchoolSearchDialog {...UseSchoolSearchDialog(props)} />;
};

export default SchoolSearchDialogContainer;
