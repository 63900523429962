/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addReservationDay = /* GraphQL */ `
  mutation AddReservationDay($data: AddReservationDayRequest!) {
    addReservationDay(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        ReservationDays {
          ReservationId
          ReservedDay
          ReservedMonth
          ReservedYear
          ReservationStatusCode
          ReservationStatus
          DurationDate
          CanExtend
        }
        UpdateDatetime
      }
      ErrorData
    }
  }
`;
export const addReservationAgent = /* GraphQL */ `
  mutation AddReservationAgent($data: RegistReservationAgent!) {
    addReservationAgent(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const applyReservation = /* GraphQL */ `
  mutation ApplyReservation($data: RegistApplyReservation!) {
    applyReservation(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const applySchoolDecision = /* GraphQL */ `
  mutation ApplySchoolDecision($data: RegistApplySchoolDecision!) {
    applySchoolDecision(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const approveReservation = /* GraphQL */ `
  mutation ApproveReservation($data: ApproveReservation!) {
    approveReservation(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const approveSchoolDecision = /* GraphQL */ `
  mutation ApproveSchoolDecision($data: ReservationCommonRequest!) {
    approveSchoolDecision(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const backReservationApproval = /* GraphQL */ `
  mutation BackReservationApproval($data: ReservationCommonRequest!) {
    backReservationApproval(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const cancelReservation = /* GraphQL */ `
  mutation CancelReservation($data: RegistCancelReservation!) {
    cancelReservation(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const deleteOptionalService = /* GraphQL */ `
  mutation DeleteOptionalService($data: RegistOptionalService!) {
    deleteOptionalService(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const deleteReservationAgent = /* GraphQL */ `
  mutation DeleteReservationAgent($data: DeleteReservationAgent!) {
    deleteReservationAgent(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const deleteReservedDay = /* GraphQL */ `
  mutation DeleteReservedDay($data: DeleteReservedDay!) {
    deleteReservedDay(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const extendReservedDay = /* GraphQL */ `
  mutation ExtendReservedDay($data: ExtendReservedDayRequest!) {
    extendReservedDay(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        DurationDate
        UpdateDatetime
      }
      ErrorData
    }
  }
`;
export const registCompanyInfo = /* GraphQL */ `
  mutation RegistCompanyInfo($data: RegistCompanyInfo!) {
    registCompanyInfo(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const registOptionalService = /* GraphQL */ `
  mutation RegistOptionalService($data: RegistOptionalService!) {
    registOptionalService(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const registReservation = /* GraphQL */ `
  mutation RegistReservation($data: RegistReservation!) {
    registReservation(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const updateCompanyInfo = /* GraphQL */ `
  mutation UpdateCompanyInfo($data: UpdateCompanyInfo!) {
    updateCompanyInfo(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const updateDurationDate = /* GraphQL */ `
  mutation UpdateDurationDate($data: UpdateDurationDateRequest!) {
    updateDurationDate(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const updateGuestCount = /* GraphQL */ `
  mutation UpdateGuestCount($data: UpdateGuestCount!) {
    updateGuestCount(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const updateGuestInfo = /* GraphQL */ `
  mutation UpdateGuestInfo($data: UpdateGuestInfo!) {
    updateGuestInfo(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const updateOptionalService = /* GraphQL */ `
  mutation UpdateOptionalService($data: RegistOptionalService!) {
    updateOptionalService(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const updateReservationAgent = /* GraphQL */ `
  mutation UpdateReservationAgent($data: RegistReservationAgent!) {
    updateReservationAgent(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const updateReservationService = /* GraphQL */ `
  mutation UpdateReservationService($data: UpdateReservatioinService!) {
    updateReservationService(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const transferReservation = /* GraphQL */ `
  mutation TransferReservation($data: TransferReservationRequest!) {
    transferReservation(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const registStudent = /* GraphQL */ `
  mutation RegistStudent($data: StudentRequest) {
    registStudent(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const updateStudent = /* GraphQL */ `
  mutation UpdateStudent($data: StudentRequest) {
    updateStudent(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const deleteStudent = /* GraphQL */ `
  mutation DeleteStudent($data: StudentRequest) {
    deleteStudent(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const bulkInsertStudent = /* GraphQL */ `
  mutation BulkInsertStudent($data: StudentBulkInsertRequest) {
    bulkInsertStudent(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const clearAllocateStudent = /* GraphQL */ `
  mutation ClearAllocateStudent($data: AllocateStudentRequest) {
    clearAllocateStudent(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        Students {
          ReservationId
          ClassCode
          StudentNo
          StudentName
          StudentKanaName
          Sex
          SexDisplayName
          MinkaId
          MinkaName
          Allergy
          Disease
          Consern
          Notice
          Memo
          IsActive
          IsSelected
          HasAnotherAllocated
          CompanyId
          UpdateDatetime
        }
        ClassCodes
        HasStudent
        UpdateDatetime
      }
      ErrorData
    }
  }
`;
export const updateAllocateStudent = /* GraphQL */ `
  mutation UpdateAllocateStudent($data: AllocateStudentRequest) {
    updateAllocateStudent(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const deleteAllocateStudent = /* GraphQL */ `
  mutation DeleteAllocateStudent($data: AllocateStudentRequest) {
    deleteAllocateStudent(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        Students {
          ReservationId
          ClassCode
          StudentNo
          StudentName
          StudentKanaName
          Sex
          SexDisplayName
          MinkaId
          MinkaName
          Allergy
          Disease
          Consern
          Notice
          Memo
          IsActive
          IsSelected
          HasAnotherAllocated
          CompanyId
          UpdateDatetime
        }
        ClassCodes
        HasStudent
        UpdateDatetime
      }
      ErrorData
    }
  }
`;
export const changeMinka = /* GraphQL */ `
  mutation ChangeMinka($data: ChangeMinkaRequest) {
    changeMinka(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const sendMailAcceptMinka = /* GraphQL */ `
  mutation SendMailAcceptMinka($data: SendMailAcceptMinkaRequest) {
    sendMailAcceptMinka(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const updateMinkaAllocation = /* GraphQL */ `
  mutation UpdateMinkaAllocation($data: UpdateMinkaAllocateRequest) {
    updateMinkaAllocation(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const registSchool = /* GraphQL */ `
  mutation RegistSchool($data: RegistSchoolRequest) {
    registSchool(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const updateSchool = /* GraphQL */ `
  mutation UpdateSchool($data: RegistSchoolRequest) {
    updateSchool(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const deleteSchool = /* GraphQL */ `
  mutation DeleteSchool($data: DeleteSchoolRequest) {
    deleteSchool(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const registMinka = /* GraphQL */ `
  mutation RegistMinka($data: MinkaRequest) {
    registMinka(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const updateMinka = /* GraphQL */ `
  mutation UpdateMinka($data: MinkaRequest) {
    updateMinka(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const deleteMinka = /* GraphQL */ `
  mutation DeleteMinka($data: MinkaRequest) {
    deleteMinka(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const registMinkaResident = /* GraphQL */ `
  mutation RegistMinkaResident($data: MinkaResidentRequest) {
    registMinkaResident(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const updateMinkaResident = /* GraphQL */ `
  mutation UpdateMinkaResident($data: MinkaResidentRequest) {
    updateMinkaResident(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const deleteMinkaResident = /* GraphQL */ `
  mutation DeleteMinkaResident($data: MinkaResidentRequest) {
    deleteMinkaResident(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const registMinkaVehicle = /* GraphQL */ `
  mutation RegistMinkaVehicle($data: MinkaVehicleRequest) {
    registMinkaVehicle(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const updateMinkaVehicle = /* GraphQL */ `
  mutation UpdateMinkaVehicle($data: MinkaVehicleRequest) {
    updateMinkaVehicle(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const deleteMinkaVehicle = /* GraphQL */ `
  mutation DeleteMinkaVehicle($data: MinkaVehicleRequest) {
    deleteMinkaVehicle(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const registMinkaActivity = /* GraphQL */ `
  mutation RegistMinkaActivity($data: MinkaActivityRequest) {
    registMinkaActivity(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const deleteMinkaActivity = /* GraphQL */ `
  mutation DeleteMinkaActivity($data: MinkaActivityRequest) {
    deleteMinkaActivity(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const registMinkaClosingSetting = /* GraphQL */ `
  mutation RegistMinkaClosingSetting($data: MinkaClosingSettingRequest) {
    registMinkaClosingSetting(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const registCompany = /* GraphQL */ `
  mutation RegistCompany($data: CompanyRequest) {
    registCompany(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany($data: CompanyRequest) {
    updateCompany(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany($data: CompanyRequest) {
    deleteCompany(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const registCompanyBranch = /* GraphQL */ `
  mutation RegistCompanyBranch($data: CompanyBranchRequest) {
    registCompanyBranch(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const updateCompanyBranch = /* GraphQL */ `
  mutation UpdateCompanyBranch($data: CompanyBranchRequest) {
    updateCompanyBranch(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const deleteCompanyBranch = /* GraphQL */ `
  mutation DeleteCompanyBranch($data: CompanyBranchRequest) {
    deleteCompanyBranch(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const registService = /* GraphQL */ `
  mutation RegistService($data: ServiceRequest) {
    registService(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const updateService = /* GraphQL */ `
  mutation UpdateService($data: ServiceRequest) {
    updateService(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const deleteService = /* GraphQL */ `
  mutation DeleteService($data: ServiceRequest) {
    deleteService(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const registServiceRate = /* GraphQL */ `
  mutation RegistServiceRate($data: ServiceRateRequest) {
    registServiceRate(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const updateServiceRate = /* GraphQL */ `
  mutation UpdateServiceRate($data: ServiceRateRequest) {
    updateServiceRate(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const deleteServiceRate = /* GraphQL */ `
  mutation DeleteServiceRate($data: ServiceRateRequest) {
    deleteServiceRate(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const registArea = /* GraphQL */ `
  mutation RegistArea($data: AreaRequest) {
    registArea(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const updateArea = /* GraphQL */ `
  mutation UpdateArea($data: AreaRequest) {
    updateArea(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const deleteArea = /* GraphQL */ `
  mutation DeleteArea($data: AreaRequest) {
    deleteArea(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const registAreaManager = /* GraphQL */ `
  mutation RegistAreaManager($data: AreaManagerRequest) {
    registAreaManager(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const updateAreaManager = /* GraphQL */ `
  mutation UpdateAreaManager($data: AreaManagerRequest) {
    updateAreaManager(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const deleteAreaManager = /* GraphQL */ `
  mutation DeleteAreaManager($data: AreaManagerRequest) {
    deleteAreaManager(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const registAreaService = /* GraphQL */ `
  mutation RegistAreaService($data: AreaServiceRequest) {
    registAreaService(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const updateAreaService = /* GraphQL */ `
  mutation UpdateAreaService($data: AreaServiceRequest) {
    updateAreaService(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const deleteAreaService = /* GraphQL */ `
  mutation DeleteAreaService($data: AreaServiceRequest) {
    deleteAreaService(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const registRegion = /* GraphQL */ `
  mutation RegistRegion($data: RegionRequest) {
    registRegion(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const updateRegion = /* GraphQL */ `
  mutation UpdateRegion($data: RegionRequest) {
    updateRegion(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const deleteRegion = /* GraphQL */ `
  mutation DeleteRegion($data: RegionRequest) {
    deleteRegion(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const updateAreaReservationFrame = /* GraphQL */ `
  mutation UpdateAreaReservationFrame($data: AreaReservationFrameRequest) {
    updateAreaReservationFrame(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const bulkRegistAreaReservationFrame = /* GraphQL */ `
  mutation BulkRegistAreaReservationFrame(
    $data: BulkAreaReservationFrameRequest
  ) {
    bulkRegistAreaReservationFrame(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const bulkUpdateAreaReservationFrame = /* GraphQL */ `
  mutation BulkUpdateAreaReservationFrame(
    $data: BulkAreaReservationFrameRequest
  ) {
    bulkUpdateAreaReservationFrame(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const bulkUpdateNonBusinessDay = /* GraphQL */ `
  mutation BulkUpdateNonBusinessDay($data: BulkAreaReservationFrameRequest) {
    bulkUpdateNonBusinessDay(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const registUser = /* GraphQL */ `
  mutation RegistUser($data: UserRequest) {
    registUser(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($data: UserRequest) {
    updateUser(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($data: String) {
    deleteUser(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const isUserLoggedIn = /* GraphQL */ `
  mutation IsUserLoggedIn($data: String) {
    IsUserLoggedIn(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
