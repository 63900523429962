import { Branch, Company, InitAcceptCompanyQuery, RegistCompanyInfoMutation, UpdateCompanyInfoMutation } from 'API';
import { useAPI } from 'contexts/APIRequestContext';
import { useMaster } from 'contexts/CommonMasterContext';
import { registCompanyInfo, updateCompanyInfo } from 'graphql/mutations';
import { initAcceptCompany } from 'graphql/queries';
import { useCallback, useEffect, useState } from 'react';
import { ServiceProvider } from 'types/ServiceProvider';
import { CreateEmptyErrorData, ErrorData } from './AcceptCompanyInfoDialogErrorData';

const UseAcceptCompanyInfoDialog = ({
  handleDialogClose,
  dialogOpen,
  serviceProvider,
  isReserveAdmin,
  reservationId,
  mode,
  updateDatetime,
}: {
  handleDialogClose: (_?: ServiceProvider, updateDatetime?: string) => void;
  dialogOpen: boolean;
  serviceProvider: ServiceProvider;
  isReserveAdmin: boolean;
  reservationId: string;
  mode: string;
  updateDatetime: string;
}) => {
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [inputData, setInputData] = useState({} as ServiceProvider);
  const [companyList, setCompanyList] = useState([] as Company[]);
  const [branchList, setBranchList] = useState([] as Branch[]);
  const [canUpdateServiceProvider, setCanUpdateServiceProvider] = useState(false);
  const api = useAPI();
  const [, setError] = useState<undefined>();

  useEffect(() => {
    if (dialogOpen) {
      // 初期化
      setInputData(serviceProvider);
      setErrorAttribute({ ...CreateEmptyErrorData() });

      executeInitAcceptCompany();
    } else {
      setInputData({} as ServiceProvider);
    }
  }, [dialogOpen]);

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  /**
   * 民泊受入事業者情報初期処理
   */
  const executeInitAcceptCompany = async (): Promise<void> => {
    const response = (
      (await api.graphql({
        query: initAcceptCompany,
        variables: {
          data: {
            ReservationId: reservationId,
            UpdateDatetime: updateDatetime,
          },
        },
      })) as InitAcceptCompanyQuery
    ).initAcceptCompany;

    if (response?.IsSuccess) {
      setCompanyList(response.Body?.Companies! as Company[]);
      setBranchList(response.Body?.Branches! as Branch[]);
      setCanUpdateServiceProvider(response.Body?.CanUpdateServiceProvider ?? false);
    } else if (response?.IsSystemError) {
      throwError(response.ErrorData ?? '');
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...errorAttribute,
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  };

  /**
   * 民泊受入事業者情報　会社名更新
   * @param event イベント
   */
  const handleChangeCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputData({
      ...inputData,
      CompanyId: event.target.value,
      CompanyName: event.target.value ? companyList.find((x) => x.CompanyId === event.target.value)!.CompanyName : '',
      BranchId: '',
      BranchName: '',
    });

    setErrorAttribute({
      ...errorAttribute,
      CompanyId: { IsError: false, ErrorMessage: '' },
      BranchId: { IsError: false, ErrorMessage: '' },
    });
  };

  /**
   * 民泊受入事業者情報　営業所名更新
   * @param event イベント
   */
  const handleChangeBranchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputData({
      ...inputData,
      BranchId: event.target.value,
      BranchName: event.target.value ? branchList.find((x) => x.BranchId === event.target.value)!.BranchName : '',
    });

    // 値が変更されたらエラー情報をクリア
    setErrorAttribute({ ...errorAttribute, BranchId: { IsError: false, ErrorMessage: '' } });
  };

  /**
   * 民泊受入事業者情報　入力値更新
   * @param event イベント
   */
  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    (inputData[event.target.name as keyof ServiceProvider] as any) = event.target.value;
    setInputData({ ...inputData });

    // 値が変更されたらエラー情報をクリア
    setErrorAttribute({ ...errorAttribute, [event.target.name]: { IsError: false, ErrorMessage: '' } });
  };

  /**
   * 民泊受入事業者情報登録処理
   */
  async function executeRegistCompanyInfo() {
    const response = (
      (await api.graphql({
        query: registCompanyInfo,
        variables: {
          data: {
            ReservationId: reservationId,
            CompanyId: inputData.CompanyId,
            BranchId: inputData.BranchId,
            ManagingUserName: inputData.ManagingUserName,
            CheckInPlace: inputData.CheckInPlace,
            CheckOutPlace: inputData.CheckOutPlace,
            Memo: inputData.Memo,
            UpdateDatetime: updateDatetime,
          },
        },
      })) as RegistCompanyInfoMutation
    ).registCompanyInfo;

    if (response?.IsSuccess) {
      handleDialogClose(inputData, response.Body!);
    } else if (response?.IsSystemError) {
      throwError(response.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  /**
   * 民泊受入事業者情報更新処理
   */
  async function executeUpdateCompanyInfo() {
    const response = (
      (await api.graphql({
        query: updateCompanyInfo,
        variables: {
          data: {
            ReservationId: reservationId,
            CompanyId: inputData.CompanyId,
            BranchId: inputData.BranchId,
            ManagingUserName: inputData.ManagingUserName,
            CheckInPlace: inputData.CheckInPlace,
            CheckOutPlace: inputData.CheckOutPlace,
            Memo: inputData.Memo,
            UpdateDatetime: updateDatetime,
          },
        },
      })) as UpdateCompanyInfoMutation
    ).updateCompanyInfo;

    if (response?.IsSuccess) {
      handleDialogClose(inputData, response.Body!);
    } else if (response?.IsSystemError) {
      throwError(response.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  /**
   * 民泊受入事業者情報　登録
   */
  const handleRegist = (): void => {
    if (mode === 'MODIFY') {
      executeUpdateCompanyInfo();
    } else {
      executeRegistCompanyInfo();
    }
  };

  /**
   * 民泊受入事業者情報　閉じる
   */
  const handleClose = (): void => {
    handleDialogClose();
  };

  return {
    inputData,
    mode,
    companyList,
    branchList,
    canUpdateServiceProvider,
    isReserveAdmin,
    handleChangeCompany,
    handleChangeBranchInput,
    handleChangeInput,
    handleRegist,
    handleClose,
    dialogOpen,
    errorAttribute,
  };
};
export default UseAcceptCompanyInfoDialog;
