import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { FormControl, FormHelperText, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { OptionalService, Service } from 'API';
import jaLocale from 'date-fns/locale/ja';
import React, { FC } from 'react';
import { SelectValue } from 'types/SelectValue';
import SelectFormControl from 'views/parts/SelectFormControl';
import { ErrorData } from './OptionDialogErrorData';
export interface Props {
  inputData: OptionalService;
  services: Service[];
  mode: string;
  handleChangeInput: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeService: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeProvideDate: (_: Date | null) => void;
  handleRegist: () => void;
  handleClose: () => void;
  dialogOpen: boolean;
  errorAttribute: ErrorData;
}

const styles = {
  categoryHeader: {
    fontWeight: 'bold',
  },
  itemHeader: {
    backgroundColor: '#ececec',
    fontWeight: 'bold',
  },
  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
  },
  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 1,
    ml: 1,
  },
  label: {
    mt: '7px',
  },
  categoryTitle: {
    borderLeft: 5,
    borderColor: '#0058a0',
    pl: '10px',
  },
  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },
  listButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    m: 0,
  },
};

const OptionDialog: FC<Props> = ({
  inputData,
  services,
  mode,
  handleChangeInput,
  handleChangeService,
  handleChangeProvideDate,
  handleRegist,
  handleClose,
  dialogOpen,
  errorAttribute,
}) => {
  return (
    <Dialog open={dialogOpen} onClose={() => handleClose()} maxWidth={'md'}>
      <DialogTitle>
        宿泊オプション
        {errorAttribute.Header.ErrorMessage && (
          <Grid container spacing={2} sx={{ pl: 0, pb: 2 }}>
            <Grid item xs={12}>
              <FormControl component="fieldset" error={errorAttribute.Header.IsError}>
                <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                  {errorAttribute.Header.ErrorMessage}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          宿泊オプション
        </Typography>
        <Grid container spacing={2} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>プラン *</Typography>
          </Grid>
          <Grid item xs={9}>
            {mode === 'NEW' ? (
              <SelectFormControl
                value={inputData.ServiceId ?? ''}
                selectName="ServiceId"
                selectValues={services.map((x) => {
                  return { value: x.ServiceId, displayName: x.ServiceDisplayName } as SelectValue;
                })}
                setCondition={handleChangeService}
                sx={styles.inputItem}
                error={errorAttribute.ServiceId.IsError}
                errorMessage={errorAttribute.ServiceId.ErrorMessage}
              />
            ) : (
              <Typography sx={styles.label}>{inputData.ServiceDisplayName}</Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>提供日 *</Typography>
          </Grid>
          <Grid item xs={9}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
              <DesktopDatePicker
                label="yyyy/mm/dd"
                mask="____/__/__"
                value={inputData.ProvideDate ?? null}
                onChange={handleChangeProvideDate}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
            <FormControl error={errorAttribute.ProvideDate.IsError}>
              <FormHelperText>{errorAttribute.ProvideDate.ErrorMessage}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>開始時間 *</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              required
              hiddenLabel
              name="StartTime"
              value={inputData.StartTime ?? ''}
              onChange={handleChangeInput}
              size="small"
              error={errorAttribute.StartTime.IsError}
              helperText={errorAttribute.StartTime.ErrorMessage}
              inputProps={{ type: 'time' }}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>終了時間 *</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              required
              hiddenLabel
              name="EndTime"
              value={inputData.EndTime ?? ''}
              onChange={handleChangeInput}
              size="small"
              error={errorAttribute.EndTime.IsError}
              helperText={errorAttribute.EndTime.ErrorMessage}
              inputProps={{ type: 'time' }}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>単価 *</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              required
              hiddenLabel
              name="Rate"
              value={inputData.Rate ?? ''}
              onChange={handleChangeInput}
              size="small"
              error={errorAttribute.Rate.IsError}
              helperText={errorAttribute.Rate.ErrorMessage}
              inputProps={{ type: 'number', min: 0 }}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>数量 *</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              required
              hiddenLabel
              name="Count"
              value={inputData.Count ?? ''}
              onChange={handleChangeInput}
              size="small"
              error={errorAttribute.Count.IsError}
              helperText={errorAttribute.Count.ErrorMessage}
              inputProps={{ type: 'number', min: 1 }}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>メモ</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              required
              hiddenLabel
              name="Memo"
              value={inputData.Memo ?? ''}
              onChange={handleChangeInput}
              multiline
              rows="4"
              sx={styles.inputItem}
              error={errorAttribute.Memo.IsError}
              helperText={errorAttribute.Memo.ErrorMessage}
              inputProps={{ maxLength: 255 }}
            />
          </Grid>
        </Grid>

        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" sx={styles.button} onClick={handleRegist}>
              登録
            </Button>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" sx={styles.button} onClick={handleClose}>
              閉じる
            </Button>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
export default OptionDialog;
