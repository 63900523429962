import { FC } from 'react';
import GenericTemplate from '../../../template/GenericTemplate';
import React from 'react';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import Grid from '@mui/material/Grid/Grid';
import FormControl from '@mui/material/FormControl/FormControl';
import Button from '@mui/material/Button/Button';
import Box from '@mui/material/Box/Box';
import TextField from '@mui/material/TextField/TextField';
import FormGroup from '@mui/material/FormGroup/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TablePagination from '@mui/material/TablePagination/TablePagination';
import { Company, FindCompanyListRequest } from '../../../../API';
import Radio from '@mui/material/Radio/Radio';
import RadioGroup from '@mui/material/RadioGroup/RadioGroup';
import { ErrorData } from './CompanyListErrorData';
import FormHelperText from '@mui/material/FormHelperText';
import AlertDialog from '../../alert';
import { UserCategoryCode } from 'constant/Constant';
import BackToTopButton from 'views/parts/BackToTop';

const styles = {
  searchField: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
  },

  label: {
    margin: 'auto',
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
  },

  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
  },

  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
    '& > div > input': {
      maxHeight: '10px',
    },
  },

  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 1,
    ml: 1,
  },

  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },

  listButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    m: 0,
  },

  textField: {
    width: '100%',
    '& input': {
      padding: '8px 14px',
    },
  },

  deleteCell: {
    '& > a': {
      textDecoration: 'none',
    },
  },
};

export interface Props {
  companyName: string;
  isActive: string;
  categoryCode: string;
  resultData: Company[];
  errorAttribute: ErrorData;
  alertOpen: boolean;
  alertContent: string;
  page: number;
  rowsPerPage: number;
  handleChangeCompanyName: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeIsActive: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeCategoryCode: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeInput: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickSearch: () => void;
  handleClickDeleteButton: (_: Company) => void;
  handleClickCreateButton: () => void;
  handleClickDetailButton: (_: Company) => void;
  handleAlertClose: (_: boolean) => void;
  handleChangePage: (_: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => void;
  handleChangeRowsPerPage: (_: React.ChangeEvent<HTMLInputElement>) => void;
}

const CompanyList: FC<Props> = (props) => {
  return (
    <GenericTemplate title="会社一覧">
      <div>
        <BackToTopButton />
      </div>
      <Container sx={styles.searchField}>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          検索条件
        </Typography>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>会社名</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name="CompanyName"
              sx={styles.textField}
              defaultValue={''}
              placeholder={'会社名の一部を入力してください'}
              value={props.companyName}
              onChange={props.handleChangeCompanyName}
              inputProps={{ maxLength: 40 }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>会社種別</Typography>
          </Grid>
          <Grid item xs={7}>
            <FormControl component="fieldset">
              <FormGroup style={{ display: 'block' }}>
                <RadioGroup row aria-label="position" defaultValue={UserCategoryCode.MinpakuCompany}>
                  <FormControlLabel
                    value={UserCategoryCode.MinpakuCompany}
                    control={
                      <Radio
                        color="primary"
                        name="CategoryCode"
                        value={UserCategoryCode.MinpakuCompany}
                        onChange={props.handleChangeCategoryCode}
                        checked={props.categoryCode === UserCategoryCode.MinpakuCompany}
                      />
                    }
                    label="民泊事業者"
                  />
                  <FormControlLabel
                    value={UserCategoryCode.Agent}
                    control={
                      <Radio
                        color="primary"
                        name="CategoryCode"
                        value={UserCategoryCode.Agent}
                        onChange={props.handleChangeCategoryCode}
                        checked={props.categoryCode === UserCategoryCode.Agent}
                      />
                    }
                    label="旅行代理店"
                  />
                  <FormControlLabel
                    value={UserCategoryCode.ServiceCompany}
                    control={
                      <Radio
                        color="primary"
                        name="CategoryCode"
                        value={UserCategoryCode.ServiceCompany}
                        onChange={props.handleChangeCategoryCode}
                        checked={props.categoryCode === UserCategoryCode.ServiceCompany}
                      />
                    }
                    label="サービス提供業者"
                  />
                </RadioGroup>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>有効区分</Typography>
          </Grid>
          <Grid item xs={7}>
            <RadioGroup row aria-label="position" defaultValue="1">
              <FormControlLabel
                value="1"
                control={
                  <Radio
                    color="primary"
                    name="IsActive"
                    value={'1'}
                    onChange={props.handleChangeIsActive}
                    checked={props.isActive === '1'}
                  />
                }
                label="有効"
              />
              <FormControlLabel
                value="0"
                control={
                  <Radio
                    color="primary"
                    name="IsActive"
                    value={'0'}
                    onChange={props.handleChangeIsActive}
                    checked={props.isActive === '0'}
                  />
                }
                label="無効"
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'center', '& > a': { textDecoration: 'none' } }}>
          <Button variant="outlined" sx={styles.button} onClick={props.handleClickCreateButton}>
            新規作成
          </Button>
          <Button variant="contained" sx={styles.button} onClick={props.handleClickSearch}>
            検索
          </Button>
        </Box>
      </Container>
      {props.errorAttribute.Header.ErrorMessage && (
        <Grid container spacing={2} sx={{ pl: 0, pb: 2 }}>
          <Grid item xs={12}>
            <FormControl component="fieldset" error={props.errorAttribute.Header.IsError}>
              <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                {props.errorAttribute.Header.ErrorMessage}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      )}
      <Container sx={styles.searchField}>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          会社一覧
        </Typography>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>会社ID</TableCell>
                <TableCell>会社名</TableCell>
                <TableCell>会社表示名</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.resultData
                .slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage)
                .map((company, index) => (
                  <TableRow key={index}>
                    <TableCell component="td" scope="row">
                      <Button
                        variant="contained"
                        sx={styles.listButton}
                        size="small"
                        onClick={() => props.handleClickDeleteButton(company)}
                      >
                        削除
                      </Button>
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {company.CompanyId}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {company.CompanyName}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {company.CompanyShortName}
                    </TableCell>
                    <TableCell component="td" scope="row" sx={styles.deleteCell}>
                      <Button
                        variant="contained"
                        sx={styles.listButton}
                        size="small"
                        onClick={() => props.handleClickDetailButton(company)}
                      >
                        詳細
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={props.resultData.length}
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          onPageChange={props.handleChangePage}
          onRowsPerPageChange={props.handleChangeRowsPerPage}
        />
        <AlertDialog
          handleDialogClose={props.handleAlertClose}
          dialogOpen={props.alertOpen}
          content={props.alertContent}
        />
      </Container>
    </GenericTemplate>
  );
};

export default CompanyList;
