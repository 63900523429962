import { useEffect, useState, useCallback } from 'react';
import { sendMailAcceptMinka } from 'graphql/mutations';
import { findAcceptMinka } from 'graphql/queries';
import {
  SendMailAcceptMinkaRequest,
  AcceptMinkaData,
  AllocateMinkaData,
  FindAcceptMinkaQuery,
  SendMailAcceptMinkaMutation,
} from 'API';
import { useAPI } from 'contexts/APIRequestContext';
import { useLocation } from 'react-router-dom';
import { CreateEmptyErrorData, ErrorData } from './AcceptMinkaErrorData';
import { useNavigate } from 'react-router-dom';
import { checkAuthority } from 'util/AuthorityCheck';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';

const UseAcceptMinkaAllDeliver = () => {
  const [minkaData, setMinkaData] = useState({} as AcceptMinkaData);
  const [allocateMinkaList, setAllocateMinka] = useState([] as AllocateMinkaData[]);
  const [inputData, setInputData] = useState({} as SendMailAcceptMinkaRequest);
  const api = useAPI();
  const location = useLocation();
  const reservationId = location.state as string;
  const [, setError] = useState<undefined>();
  const [message, setMessage] = useState('');
  const [reserveInfo, setReserveInfo] = useState('');
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const navigator = useNavigate();
  const { user } = useAuthenticator();
  const userData = new UserData(user);

  const showMessage = (message: string) => {
    setMessage(message);
  };

  const handleMessageDialogClose = () => {
    setMessage('');
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    setInputData({ ...inputData, [name]: value });

    // 値が変更されたらエラー情報をクリア
    setErrorAttribute({ ...errorAttribute, [name]: { isError: false, errorMessage: '' } });
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    const checked = event.target.checked;

    let list = inputData['MinkaIdList'];
    if (!list) {
      list = [] as string[];
    }
    if (checked) {
      if (!list?.find((x) => x == value)) {
        list?.push(value);
      }
    } else {
      if (list?.find((x) => x == value)) {
        list = list.filter((x) => x != value);
      }
    }
    setInputData({ ...inputData, [name]: list });

    // 値が変更されたらエラー情報をクリア
    setErrorAttribute({ ...errorAttribute, [name]: { isError: false, errorMessage: '' } });
  };

  async function fetchData() {
    const response = (await api.graphql({
      query: findAcceptMinka,
      variables: {
        data: reservationId,
      },
    })) as FindAcceptMinkaQuery;

    if (response?.findAcceptMinka?.Body) {
      const _acceptMinka = response?.findAcceptMinka?.Body as AcceptMinkaData;
      setMinkaData(_acceptMinka);
      setAllocateMinka(_acceptMinka.AllocateList);
      setInputData({ ...inputData, ['MinkaIdList']: [] as string[] });
      setReserveInfo(
        [
          `予約ID：${_acceptMinka.ReservationId}`,
          `学校名：${_acceptMinka.SchoolName}`,
          `団名　：${_acceptMinka.GroupingName ?? ''}`,
          `プラン：${_acceptMinka.ServiceName}`,
          `入村日：${_acceptMinka.CheckInDate}`,
          `退村日：${_acceptMinka.CheckOutDate}`,
        ].join('\n')
      );
    } else if (response?.findAcceptMinka?.IsSystemError) {
      throwError(response?.findAcceptMinka?.ErrorData ?? '');
    } else if (response?.findAcceptMinka?.ErrorData) {
      setErrorAttribute({
        ...errorAttribute,
        Header: { IsError: true, ErrorMessage: response?.findAcceptMinka?.ErrorData },
      });
      window.scrollTo(0, 0);
    } else {
      throwError();
    }
  }

  /**
   * メール送信
   */
  const handleSendMail = (): void => {
    executeSendMail();
  };

  /**
   * メール送信実行
   */
  async function executeSendMail() {
    const response = (
      (await api.graphql({
        query: sendMailAcceptMinka,
        variables: {
          data: inputData,
        },
      })) as SendMailAcceptMinkaMutation
    ).sendMailAcceptMinka;

    if (response?.IsSuccess) {
      showMessage('メール送信が完了しました。');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...errorAttribute,
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
      window.scrollTo(0, 0);
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...errorAttribute,
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
      window.scrollTo(0, 0);
    } else if (response?.IsSystemError) {
      throw response?.ErrorData;
    }
  }

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('AcceptMinkaAllDeliver', userData.authorityCode)) {
      navigator('/');
    }

    if (!reservationId) {
      // 予約IDが指定されていない場合、前の画面に戻る
      back();
      return;
    }
    // データ取得
    fetchData();
  }, []);

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  /**
   * 閉じる
   */
  const handleClose = (): void => {
    back();
  };

  /**
   * ブラウザバック
   */
  const back = (): void => {
    window.history.back();
  };

  return {
    handleChangeInput,
    handleChangeCheckbox,
    handleClose,
    handleSendMail,
    handleMessageDialogClose,
    minkaData,
    allocateMinkaList,
    inputData,
    errorAttribute,
    message,
    reserveInfo,
  };
};
export default UseAcceptMinkaAllDeliver;
