import { useEffect, useState, useCallback } from 'react';
import { useAPI } from 'contexts/APIRequestContext';
import { useLocation } from 'react-router-dom';
import { ChangeMinkaMutation, MinkaInRegion, FindMinkaInRegionQuery, ChangeMinkaRequest, MinkaLabelValue } from 'API';
import { MinkaChangeInputParam } from './MinkaChangeInputParam';
import { findMinkaInRegion } from 'graphql/queries';
import { changeMinka } from 'graphql/mutations';
import { ErrorData, CreateEmptyErrorData } from './MinkaChangeErrorData';
import { useNavigate } from 'react-router-dom';
import { checkAuthority } from 'util/AuthorityCheck';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';

const UseMinkaChange = () => {
  const [minkaList, setMinkaList] = useState([] as MinkaLabelValue[]);
  const [minkaInRegions, setMinkaInRegions] = useState([] as MinkaInRegion[]);
  const [inputData, setInputData] = useState({} as ChangeMinkaRequest);
  const [afterRegion, setAfterRegion] = useState('');
  const [minkaName, setMinkaName] = useState('');
  const api = useAPI();
  const location = useLocation();
  const param = location.state as MinkaChangeInputParam;
  const [, setError] = useState<undefined>();
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const navigator = useNavigate();
  const { user } = useAuthenticator();
  const userData = new UserData(user);

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  /**
   * ブラウザバック
   */
  const back = (): void => {
    window.history.back();
  };

  /**
   * 閉じる
   */
  const handleClose = (): void => {
    back();
  };

  /**
   * 登録
   */
  const handleClickRegist = () => {
    executeMinkaChange();
  };

  /**
   * 登録ボタン押下時
   */
  async function executeMinkaChange() {
    const response = (
      (await api.graphql({
        query: changeMinka,
        variables: { data: inputData },
      })) as ChangeMinkaMutation
    ).changeMinka;

    if (response?.IsSuccess) {
      back();
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  /**
   * 変更後民家所在地域　変更イベント
   * @param event イベント
   */
  const handleChangeRegion = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorAttribute({
      ...errorAttribute,
      [event.target.name]: { IsError: false, ErrorMessage: '' },
    });
    setAfterRegion(event.target.value);
    setInputData({ ...inputData, AfterMinkaId: null });

    if (!event.target.value) {
      setMinkaList([] as MinkaLabelValue[]);
    } else {
      setMinkaList(
        minkaInRegions.find((x) => x.RegionCode === event.target.value)?.MinkaList ?? ([] as MinkaLabelValue[])
      );
    }
  };

  /**
   * 入力値変更
   * @param event
   */
  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    setInputData({ ...inputData, [name]: value });
    setErrorAttribute({
      ...errorAttribute,
      [event.target.name]: { IsError: false, ErrorMessage: '' },
    });
  };

  /**
   * 画面表示データの取得
   */
  const fetchFindInRegion = async (): Promise<void> => {
    const response = (
      (await api.graphql({
        query: findMinkaInRegion,
        variables: {
          ReservationId: param.ReservationId,
          AreaId: param.AreaId,
        },
      })) as FindMinkaInRegionQuery
    ).findMinkaInRegion;

    if (response?.IsSuccess) {
      setMinkaInRegions(response.Body);
      setInputData({
        ReservationId: param.ReservationId ?? 0,
        AreaId: param.AreaId,
        BeforeMinkaId: param.MinkaId,
        ClassCode: param.ClassCode,
        GroupCode: param.GroupCode,
        ChangeReason: '',
      } as ChangeMinkaRequest);
      setAfterRegion(param.RegionCode ?? '');
      setMinkaName(param.MinkaName ?? '');
      setMinkaInRegions(response.Body);
      setMinkaList(
        response.Body.find((x) => x.RegionCode === param.RegionCode)?.MinkaList ?? ([] as MinkaLabelValue[])
      );
    } else if (response?.IsSystemError) {
      throwError(response.ErrorData ?? '');
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...errorAttribute,
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  };

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('MinkaChange', userData.authorityCode)) {
      navigator('/');
    }

    if (
      !param.AreaId ||
      !param.ClassCode ||
      !param.GroupCode ||
      !param.MinkaId ||
      !param.MinkaName ||
      !param.RegionCode ||
      !param.ReservationId
    ) {
      // 必要なパラメタが渡ってきていない場合、前画面へ戻る
      back();
      return;
    }
    // 画面表示データ取得
    fetchFindInRegion();
  }, []);

  return {
    handleChangeRegion,
    handleChangeInput,
    handleClickRegist,
    handleClose,
    minkaInRegions,
    minkaList,
    inputData,
    minkaName,
    afterRegion,
    errorAttribute,
  };
};
export default UseMinkaChange;
