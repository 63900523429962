import { useAuthenticator } from '@aws-amplify/ui-react';
import { FindReserveReceptionQuery, Reservation, ReserveReception } from 'API';
import { graphqlOperation } from 'aws-amplify';
import { useAPI } from 'contexts/APIRequestContext';
import { useMaster } from 'contexts/CommonMasterContext';
import { findReserveReception } from 'graphql/queries';
import { UserData } from 'models/UserData';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { checkAuthority } from 'util/AuthorityCheck';
import { ReservationFrame } from 'views/reserve/reception/regist/ReservationFrame';

const UseReserveLedger = () => {
  const [reserveReception, setReserveReception] = useState([] as ReserveReception[]);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [displayDays, setDisplayDays] = useState([] as Date[]);
  const [textBoxStartDate, setTextBoxStartDate] = useState(null as Date | null);
  const navigator = useNavigate();
  const api = useAPI();
  const [searchParams] = useSearchParams();
  const master = useMaster();
  const { user } = useAuthenticator();
  const userData = new UserData(user);
  const [isIncludeCancel, setIsIncludeCancel] = useState(false);
  const [durationMultiplier, setDurationMultiplier] = useState<number>(2);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const handleChangeTextBoxStartDate = (value: Date | null) => {
    setTextBoxStartDate(value);
  };

  /** 予約セルクリックハンドラ */
  const handleDetailClick = (
    ledger: ReserveReception,
    date: string,
    idxFrame: number,
    idxDate: number,
    reservation: Reservation
  ) => {
    navigator('/reserve/detail', {
      state: {
        ReservationId: reservation.ReservationId,
        PrefectureCode: searchParams.get('PrefectureCode'),
        PrefectureName: master.getPrefecture().find((x) => x.PrefectureCode === searchParams.get('PrefectureCode'))
          ?.PrefectureName,
        SearchStartDate: startDate,
        ReservedDay: date,
        AreaId: ledger.AreaId,
        AreaName: ledger.AreaName,
        MaxGuestCount: ledger.FrameList[idxFrame].DateList[idxDate].MaxGuestCount,
        MaxSchoolCount: ledger.FrameList[idxFrame].DateList[idxDate].MaxSchoolCount,
        MaxMultiSchoolGuestCount: ledger.FrameList[idxFrame].DateList[idxDate].MaxMultiSchoolGuestCount,
        CheckInTime: ledger.FrameList[idxFrame].DateList[idxDate].CheckInTime,
        CheckOutTime: ledger.FrameList[idxFrame].DateList[idxDate].CheckOutTime,
        Memo1: ledger.Memo1,
      } as ReservationFrame,
    });
  };

  /** 予約登録セルクリックハンドラ */
  const handleRegistClick = (ledger: ReserveReception, date: string, idxFrame: number, idxDate: number) => {
    navigator('/reserve/regist', {
      state: {
        PrefectureCode: searchParams.get('PrefectureCode'),
        PrefectureName: master.getPrefecture().find((x) => x.PrefectureCode === searchParams.get('PrefectureCode'))
          ?.PrefectureName,
        SearchStartDate: startDate,
        ReservedDay: date,
        AreaId: ledger.AreaId,
        AreaName: ledger.AreaName,
        MaxGuestCount: ledger.FrameList[idxFrame].DateList[idxDate].MaxGuestCount,
        MaxSchoolCount: ledger.FrameList[idxFrame].DateList[idxDate].MaxSchoolCount,
        MaxMultiSchoolGuestCount: ledger.FrameList[idxFrame].DateList[idxDate].MaxMultiSchoolGuestCount,
        CheckInTime: ledger.FrameList[idxFrame].DateList[idxDate].CheckInTime,
        CheckOutTime: ledger.FrameList[idxFrame].DateList[idxDate].CheckOutTime,
        Memo1: ledger.Memo1,
      } as ReservationFrame,
    });
  };

  /** 画面再表示ハンドラ */
  const handleRefresh = () => {
    setTextBoxStartDate(startDate);
    fetchReserveReception(displayDays);
  };

  const isValidDate = (d: any) => {
    return d instanceof Date && !isNaN(d.getTime());
  };

  /** 表示期間変更ハンドラ */
  const handleDateChange = (newStartDate: Date | null) => {
    if (newStartDate && isValidDate(newStartDate)) {
      setStartDate(newStartDate);
      setTextBoxStartDate(newStartDate);
      fetchReserveReception(calcDisplayDays(newStartDate));
    }
  };

  /** 表示期間変更（前週）ハンドラ */
  const handleBeforeWeek = () => {
    var newStartDate = new Date(startDate.getTime());
    newStartDate.setDate(newStartDate.getDate() - 7 * durationMultiplier);
    setStartDate(newStartDate);
    setTextBoxStartDate(newStartDate);

    fetchReserveReception(calcDisplayDays(newStartDate));
  };

  /** 表示期間変更（次週）ハンドラ */
  const handleNextWeek = () => {
    var newStartDate = new Date(startDate.getTime());
    newStartDate.setDate(newStartDate.getDate() + 7 * durationMultiplier);
    setStartDate(newStartDate);
    setTextBoxStartDate(newStartDate);

    fetchReserveReception(calcDisplayDays(newStartDate));
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsIncludeCancel(event.target.checked);
  };

  const handleChangeDuration = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDurationMultiplier(Number(event.target.value));
  };

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
    fetchReserveReception(calcDisplayDays(startDate));
  }, [durationMultiplier]);

  /** 表示期間を算出する */
  function calcDisplayDays(newStartDate: Date): Date[] {
    var newDisplayDays = [] as Date[];
    for (var idx = 0; idx < 7 * durationMultiplier; idx++) {
      var thisDate = new Date(newStartDate.getTime());
      thisDate.setDate(newStartDate.getDate() + idx);
      newDisplayDays.push(thisDate);
    }
    setDisplayDays(newDisplayDays);
    return newDisplayDays;
  }

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('ReserveLedgerSearch', userData.authorityCode)) {
      navigator('/');
    }

    if (!searchParams.get('DisplayStartDate') || !searchParams.get('PrefectureCode')) {
      // 必要なパラメタが渡っていない場合、検索画面へディスパッチ
      navigator('/reserve/ledger/search');
    } else {
      // 指定された条件で予約台帳を取得
      var date = new Date(searchParams.get('DisplayStartDate') ?? '');
      setStartDate(date);
      setTextBoxStartDate(date);
      fetchReserveReception(calcDisplayDays(date));
    }
  }, []);

  function formatDate(date: Date) {
    var y = date.getFullYear();
    var m = ('00' + (date.getMonth() + 1)).slice(-2);
    var d = ('00' + date.getDate()).slice(-2);
    return y + '-' + m + '-' + d;
  }

  /** 予約台帳検索 */
  async function fetchReserveReception(_displayDays: Date[]) {
    // URLを変更する
    const url = new URL(window.location.href);
    url.searchParams.set('DisplayStartDate', formatDate(_displayDays[0]));
    window.history.replaceState(null, '', url.href);

    const response = (await api.graphql(
      graphqlOperation(findReserveReception, {
        PrefectureCode: searchParams.get('PrefectureCode'),
        SearchStartDate: formatDate(_displayDays[0]),
        SearchEndDate: formatDate(_displayDays[_displayDays.length - 1]),
        IsIncludeCancel: isIncludeCancel,
      })
    )) as FindReserveReceptionQuery;

    if (response?.findReserveReception?.Body?.length) {
      setReserveReception(response?.findReserveReception?.Body as ReserveReception[]);
    } else {
      setReserveReception([] as ReserveReception[]);
    }

    window.scrollTo(0, 0); // 画面全体のスクロールをリセット

    const tableContainer = document.querySelector('.ledgerTableContainer');
    if (tableContainer) {
      tableContainer.scrollTo(0, 0); // 特定の要素のスクロールをリセット
    }
  }

  return {
    reserveReception,
    startDate,
    displayDays,
    isIncludeCancel,
    durationMultiplier,
    textBoxStartDate,
    handleDetailClick,
    handleRegistClick,
    handleRefresh,
    handleDateChange,
    handleBeforeWeek,
    handleNextWeek,
    handleChangeCheckbox,
    handleChangeDuration,
    handleChangeTextBoxStartDate,
  };
};
export default UseReserveLedger;
