import { Authenticator, useTheme } from '@aws-amplify/ui-react';

import Footer from 'views/parts/Footer';
import { SignInHeader } from './SignInHeader';
import { SignInFooter } from './SignInFooter';
import App from 'App';
import Box from '@mui/material/Box';

const Header = () => {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        overflow: 'auto',
        pt: 5,
      }}
    ></Box>
  );
};

const components = {
  Header: Header,
  SignIn: {
    Header: SignInHeader,
    Footer: SignInFooter,
  },
  Footer: Footer,
};

const Login = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        backgroundColor: (theme) => theme.palette.background.paper,
      }}
    >
      <Authenticator components={components}>{({ signOut, user }) => <App />}</Authenticator>
    </Box>
  );
};

export default Login;
