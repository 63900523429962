import { FC } from 'react';
import GenericTemplate from '../../../template/GenericTemplate';
import React from 'react';
import { Container, FormControl, FormHelperText, TablePagination } from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import Grid from '@mui/material/Grid/Grid';
import SelectFormControl from '../../../parts/SelectFormControl';
import { SelectValue } from '../../../../types/SelectValue';
import { CodeMaster, CompanyBranch, MServices, ServiceRate } from '../../../../API';
import Button from '@mui/material/Button/Button';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import TextField from '@mui/material/TextField/TextField';
import Radio from '@mui/material/Radio/Radio';
import RadioGroup from '@mui/material/RadioGroup/RadioGroup';
import TableBody from '@mui/material/TableBody/TableBody';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import { Link } from 'react-router-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import jaLocale from 'date-fns/locale/ja';
import DesktopDatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ErrorData } from './ServiceDetailErrorData';
import AlertDialog from '../../alert';
import BackToTopButton from 'views/parts/BackToTop';

const styles = {
  searchField: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
  },

  label: {
    margin: 'auto',
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
  },

  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
  },

  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
    '& > div > input': {
      maxHeight: '10px',
    },
  },

  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 5,
    float: 'right',
    mb: 5,
    mr: 1,
  },

  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },

  createButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    mt: 1,
    float: 'left',
    mb: 1,
  },

  textField: {
    width: '100%',
    '& input': {
      padding: '8px 14px',
    },
  },

  textArea: {
    mt: '9px',
  },

  listButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    m: 0,
  },

  deleteCell: {
    '& > a': {
      textDecoration: 'none',
    },
  },
};

export interface Props {
  isUpdate: boolean;
  serviceData: MServices;
  serviceRateData: ServiceRate[];
  companyList: CompanyBranch[];
  serviceCategoryList: CodeMaster[];
  errorAttribute: ErrorData;
  alertOpen: boolean;
  alertContent: string;
  handleChangeDetail: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickRegist: () => void;
  handleClickServiceRateCreateButton: () => void;
  handleClickServiceRateEditButton: (_: ServiceRate) => void;
  handleClickServiceRateDeleteButton: (_: ServiceRate) => void;
  handleAlertClose: (_: boolean) => void;
  handleChangeFromDate: (_: Date | null) => void;
  handleChangeToDate: (_: Date | null) => void;
}

const ServiceDetail: FC<Props> = (props) => {
  return (
    <GenericTemplate title="サービス詳細">
      <div>
        <BackToTopButton />
      </div>
      <Container sx={styles.searchField}>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          サービス情報
        </Typography>
        {props.errorAttribute.Header.ErrorMessage && (
          <Grid container spacing={2} sx={{ pl: 0, pb: 2 }}>
            <Grid item xs={12}>
              <FormControl component="fieldset" error={props.errorAttribute.Header.IsError}>
                <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                  {props.errorAttribute.Header.ErrorMessage}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>提供事業者</Typography>
          </Grid>
          <Grid item xs={7}>
            <SelectFormControl
              value={props.serviceData.CompanyId ?? ''}
              selectName="CompanyId"
              selectValues={props.companyList
                .filter((x) => x.CategoryCode === '01' || x.CategoryCode === '02')
                .map((companies, idx) => {
                  return { value: companies.CompanyId, displayName: companies.CompanyName } as SelectValue;
                })}
              setCondition={props.handleChangeDetail}
              sx={styles.inputItem}
              isDisabled={props.isUpdate}
              error={props.errorAttribute.CompanyId.IsError}
              errorMessage={props.errorAttribute.CompanyId.ErrorMessage}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>サービスカテゴリ</Typography>
          </Grid>
          <Grid item xs={7}>
            <SelectFormControl
              value={props.serviceData.ServiceCategoryCode ?? ''}
              selectName="ServiceCategoryCode"
              selectValues={props.serviceCategoryList.map((categoryCodes, idx) => {
                return {
                  value: categoryCodes.Code,
                  displayName: categoryCodes.Name,
                } as SelectValue;
              })}
              setCondition={props.handleChangeDetail}
              sx={styles.inputItem}
              isDisabled={props.isUpdate}
              error={props.errorAttribute.ServiceCategoryCode.IsError}
              errorMessage={props.errorAttribute.ServiceCategoryCode.ErrorMessage}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>サービス名</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'ServiceName'}
              value={props.serviceData.ServiceName}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              inputProps={{ maxLength: 40 }}
              error={props.errorAttribute.ServiceName.IsError}
              helperText={props.errorAttribute.ServiceName.ErrorMessage}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>サービス表示名</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'ServiceDisplayName'}
              value={props.serviceData.ServiceDisplayName}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              inputProps={{ maxLength: 40 }}
              error={props.errorAttribute.ServiceDisplayName.IsError}
              helperText={props.errorAttribute.ServiceDisplayName.ErrorMessage}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>予約受付開始日数</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'ReservationStartDays'}
              value={props.serviceData.ReservationStartDays}
              onChange={props.handleChangeDetail}
              type="number"
              sx={styles.textField}
              error={props.errorAttribute.ReservationStartDays.IsError}
              helperText={props.errorAttribute.ReservationStartDays.ErrorMessage}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>管理者フラグ</Typography>
          </Grid>
          <Grid item xs={9}>
            <RadioGroup
              row
              name="IsAdmin"
              aria-label="position"
              value={props.serviceData.IsAdmin ?? '0'}
              onChange={props.handleChangeDetail}
            >
              <FormControlLabel value={1} control={<Radio color="primary" />} label="有効" />
              <FormControlLabel value={0} control={<Radio color="primary" />} label="無効" />
            </RadioGroup>
            <FormHelperText>{props.errorAttribute.IsAdmin.ErrorMessage}</FormHelperText>
          </Grid>
        </Grid>
        <Grid container spacing={0.0} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}></Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography sx={styles.textArea}>
              ※有効の場合、予約センターのみ当該サービスを予約できます。
              <br />
              ※旅行代理店に対しては非表示としたいサービスの場合に有効としてください。
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>泊数</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'StayNights'}
              value={props.serviceData.StayNights}
              onChange={props.handleChangeDetail}
              type="number"
              sx={styles.textField}
              error={props.errorAttribute.StayNights.IsError}
              helperText={props.errorAttribute.StayNights.ErrorMessage}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>食事数</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'Meals'}
              value={props.serviceData.Meals}
              onChange={props.handleChangeDetail}
              type="number"
              defaultValue={0}
              sx={styles.textField}
              error={props.errorAttribute.Meals.IsError}
              helperText={props.errorAttribute.Meals.ErrorMessage}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>提供時間</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'OfferingHours'}
              value={props.serviceData.OfferingHours}
              onChange={props.handleChangeDetail}
              type="number"
              sx={styles.textField}
              error={props.errorAttribute.OfferingHours.IsError}
              helperText={props.errorAttribute.OfferingHours.ErrorMessage}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>提供日区分</Typography>
          </Grid>
          <Grid item xs={7}>
            <RadioGroup
              row
              name="OfferingDayDiv"
              aria-label="position"
              value={props.serviceData.OfferingDayDiv ?? '1'}
              onChange={props.handleChangeDetail}
            >
              <FormControlLabel value="1" control={<Radio color="primary" />} label="指定なし" />
              <FormControlLabel value="2" control={<Radio color="primary" />} label="初日" />
              <FormControlLabel value="3" control={<Radio color="primary" />} label="最終日" />
            </RadioGroup>
            <FormHelperText>{props.errorAttribute.IsAdmin.ErrorMessage}</FormHelperText>
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>開始日</Typography>
          </Grid>
          <Grid item xs={7}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
              <DesktopDatePicker
                label="yyyy/mm/dd"
                mask="____/__/__"
                value={new Date(props.serviceData.FromDate ?? '')}
                onChange={(newValue) => props.handleChangeFromDate(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    style={{ width: '100%' }}
                    error={props.errorAttribute.FromDate.IsError}
                    helperText={props.errorAttribute.FromDate.ErrorMessage}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>終了日</Typography>
          </Grid>
          <Grid item xs={7}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
              <DesktopDatePicker
                label="yyyy/mm/dd"
                mask="____/__/__"
                value={new Date(props.serviceData.ToDate ?? '')}
                onChange={(newValue) => props.handleChangeToDate(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    style={{ width: '100%' }}
                    error={props.errorAttribute.ToDate.IsError}
                    helperText={props.errorAttribute.ToDate.ErrorMessage}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>表示順</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'DisplayOrder'}
              value={props.serviceData.DisplayOrder}
              onChange={props.handleChangeDetail}
              type="number"
              sx={styles.textField}
              error={props.errorAttribute.DisplayOrder.IsError}
              helperText={props.errorAttribute.DisplayOrder.ErrorMessage}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>有効区分</Typography>
          </Grid>
          <Grid item xs={9}>
            <RadioGroup
              row
              name="IsActive"
              aria-label="position"
              value={props.serviceData.IsActive ?? '1'}
              onChange={props.handleChangeDetail}
            >
              <FormControlLabel value={1} control={<Radio color="primary" />} label="有効" />
              <FormControlLabel value={0} control={<Radio color="primary" />} label="無効" />
            </RadioGroup>
            <FormHelperText>{props.errorAttribute.IsActive.ErrorMessage}</FormHelperText>
          </Grid>
        </Grid>
        {props.isUpdate ? (
          <Grid>
            <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
              料金
            </Typography>
            <Grid container spacing={0.5} sx={styles.categoryBlock}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  sx={styles.createButton}
                  size="small"
                  onClick={() => props.handleClickServiceRateCreateButton()}
                >
                  追加
                </Button>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>税別単価</TableCell>
                        <TableCell>開始日</TableCell>
                        <TableCell>終了日</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.serviceRateData.map((service, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Button
                              variant="contained"
                              sx={styles.listButton}
                              size="small"
                              onClick={() => props.handleClickServiceRateDeleteButton(service)}
                            >
                              削除
                            </Button>
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {service.Rate}
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {service.FromDate}
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {service.EndDate}
                          </TableCell>
                          <TableCell sx={styles.deleteCell}>
                            <Button
                              variant="contained"
                              sx={styles.listButton}
                              size="small"
                              onClick={() => props.handleClickServiceRateEditButton(service)}
                            >
                              編集
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          ''
        )}
        <Link to="/settings/service/list">
          <Button variant="contained" sx={styles.button}>
            閉じる
          </Button>
        </Link>
        <Button variant="contained" sx={styles.button} onClick={props.handleClickRegist}>
          登録
        </Button>
      </Container>
      <AlertDialog
        handleDialogClose={props.handleAlertClose}
        dialogOpen={props.alertOpen}
        content={props.alertContent}
      />
    </GenericTemplate>
  );
};

export default ServiceDetail;
