import { useAPI } from 'contexts/APIRequestContext';
import { useMaster } from 'contexts/CommonMasterContext';
import { registSchool, updateSchool } from 'graphql/mutations';
import { getSchool } from 'graphql/queries';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  GetSchoolQuery,
  MSchools,
  Prefecture,
  RegistSchoolMutation,
  RegistSchoolRequest,
  UpdateSchoolMutation,
} from '../../../../API';
import { CreateEmptyErrorData, ErrorData } from './SchoolDetailErrorData';
import { useNavigate } from 'react-router-dom';
import { checkAuthority } from 'util/AuthorityCheck';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';

const queryString = require('query-string');

const UseSchoolDetail = () => {
  const location = useLocation();
  var id = queryString.parse(location.search).id;

  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [prefectureList, setPrefectureList] = useState([] as Prefecture[]);
  const [inputData, setInputData] = useState<MSchools>({
    SchoolId: '',
    SchoolCode: '',
    SchoolCategoryCode: 'C1',
    PrefectureCode: '',
    ClassificationCode: '1',
    SchoolName: '',
    SchoolKanaName: '',
    Address: '',
    PostalCode: '',
    Tel: '',
    Fax: '',
    IsActive: 1,
  } as MSchools);
  const master = useMaster();
  const api = useAPI();
  const [, setError] = useState<undefined>();
  const navigator = useNavigate();
  const { user } = useAuthenticator();
  const userData = new UserData(user);

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('SchoolDetail', userData.authorityCode)) {
      navigator('/');
    }

    if (id) {
      fetchReservation();
    }

    master.waitForInitialized(() => {
      setPrefectureList(master.getPrefecture());
    });
  }, []);

  async function fetchReservation() {
    const response = (
      (await api.graphql({
        query: getSchool,
        variables: {
          SchoolId: id,
        },
      })) as GetSchoolQuery
    ).getSchool;

    if (response?.IsSuccess) {
      setInputData({ ...inputData, ...(response?.Body as MSchools) });
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...errorAttribute,
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  const handleChangePrefectureCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputData({ ...inputData, PrefectureCode: event.target.value });
  };

  const handleChangeDetail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    setInputData({ ...inputData, [name]: value });
  };

  const executeRegistSchool = async (data: RegistSchoolRequest): Promise<void> => {
    const response = (
      (await api.graphql({
        query: registSchool,
        variables: { data },
      })) as RegistSchoolMutation
    ).registSchool;

    if (response?.IsSuccess) {
      window.history.back();
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  };

  const executeUpdateSchool = async (data: RegistSchoolRequest): Promise<void> => {
    const response = (
      (await api.graphql({
        query: updateSchool,
        variables: { data },
      })) as UpdateSchoolMutation
    ).updateSchool;

    if (response?.IsSuccess) {
      window.history.back();
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  };

  const handleClickRegist = (): void => {
    if (id) {
      executeUpdateSchool({
        SchoolId: inputData.SchoolId,
        SchoolCode: inputData.SchoolCode,
        SchoolCategoryCode: inputData.SchoolCategoryCode,
        PrefectureCode: inputData.PrefectureCode,
        ClassificationCode: inputData.ClassificationCode,
        SchoolName: inputData.SchoolName,
        SchoolKanaName: inputData.SchoolKanaName,
        Address: inputData.Address,
        PostalCode: inputData.PostalCode,
        Tel: inputData.Tel,
        Fax: inputData.Fax,
        IsActive: inputData.IsActive,
        UpdateDatetime: inputData.UpdateDatetime,
      });
    } else {
      executeRegistSchool({
        SchoolCode: inputData.SchoolCode,
        SchoolCategoryCode: inputData.SchoolCategoryCode,
        PrefectureCode: inputData.PrefectureCode,
        ClassificationCode: inputData.ClassificationCode,
        SchoolName: inputData.SchoolName,
        SchoolKanaName: inputData.SchoolKanaName,
        Address: inputData.Address,
        PostalCode: inputData.PostalCode,
        Tel: inputData.Tel,
        Fax: inputData.Fax,
        IsActive: inputData.IsActive,
      });
    }
  };

  return {
    prefectureList,
    inputData,
    handleChangePrefectureCode,
    handleChangeDetail,
    handleClickRegist,
    errorAttribute,
  };
};
export default UseSchoolDetail;
