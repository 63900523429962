import { DeleteStudentMutation, FindStudentListQuery, FindStudentListRequest, Student, StudentRequest } from 'API';
import { findStudentList } from 'graphql/queries';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { graphqlOperation } from 'aws-amplify';
import { useAPI } from 'contexts/APIRequestContext';
import { deleteStudent } from 'graphql/mutations';
import { CreateEmptyErrorData, ErrorData } from './StudentListErrorData';
import { checkAuthority } from 'util/AuthorityCheck';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';

const UseStudentList = () => {
  const location = useLocation();
  const reservationId = location.state as number;
  const [classCode, setClassCode] = useState('');
  const [studentNo, setStudentNo] = useState('');
  const [studentName, setStudentName] = useState('');
  const [classCodeList, setClassCodeList] = useState([] as Student[]);
  const [resultData, setResultData] = useState([] as Student[]);
  const [inputData, setInputData] = useState({} as FindStudentListRequest);
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [, setError] = useState<undefined>();
  const navigator = useNavigate();
  const api = useAPI();
  const { user } = useAuthenticator();
  const userData = new UserData(user);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertDialogCallback, setAlertDialogCallback] = useState({ fn: (_: boolean) => {} });
  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  const handleChangeClassCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClassCode(event.target.value);
    setInputData({ ...inputData, ClassCode: event.target.value });
  };

  const handleChangeStudentNo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStudentNo(event.target.value);
    setInputData({ ...inputData, StudentNo: event.target.value });
  };

  const handleChangeStudentName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStudentName(event.target.value);
    setInputData({ ...inputData, StudentName: event.target.value });
  };

  const handleChangeIsActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputData({ ...inputData, IsActive: event.target.value });
  };

  const handleChangeSex = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputData({ ...inputData, Sex: event.target.value });
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    const checked = event.target.checked;

    let isMinkaAllocatedStr = inputData['IsMinkaAllocated'] ? inputData['IsMinkaAllocated'] : '';
    let list;
    if (isMinkaAllocatedStr != '') {
      list = isMinkaAllocatedStr.split(',') as string[];
    } else {
      list = [] as string[];
    }

    if (checked) {
      if (!list?.find((x) => x == value)) {
        list?.push(value);
      }
    } else {
      if (list?.find((x) => x == value)) {
        list = list.filter((x) => x != value);
      }
    }
    setInputData({ ...inputData, IsMinkaAllocated: list.join(',') });
  };

  const handleClickSearch = () => {
    fetchStudentList(inputData);
  };

  const handleClickStudentbulkButton = () => {
    navigator('/reserve/management/studentbulk', {
      state: reservationId,
    });
  };

  const handleClickCreateButton = () => {
    navigator('/reserve/management/studentdetail?mode=new', {
      state: {
        ReservationId: reservationId,
      } as StudentRequest,
    });
  };

  const handleClickDetailButton = (student: Student) => {
    navigator('/reserve/management/studentdetail?mode=update', {
      state: {
        ReservationId: student.ReservationId,
        ClassCode: student.ClassCode,
        StudentNo: student.StudentNo,
      } as StudentRequest,
    });
  };

  const handleDelete = async (student: Student): Promise<void> => {
    setAlertDialogCallback({
      fn: async (result: boolean) => {
        if (result) {
          deleteStd(student);
        }
      },
    });
    setAlertContent('削除します。よろしいですか？');
    setAlertOpen(true);
  };

  async function deleteStd(student: Student) {
    const studentRequest = {
      ReservationId: student.ReservationId,
      ClassCode: student.ClassCode,
      StudentNo: student.StudentNo,
      UpdateDatetime: student.UpdateDatetime,
    } as StudentRequest;

    const response = (
      (await api.graphql({
        query: deleteStudent,
        variables: { data: studentRequest },
      })) as DeleteStudentMutation
    ).deleteStudent;

    if (response?.IsSuccess) {
      // リストから削除に成功したレコードを除外
      let newStudentList = resultData.filter(
        (item) =>
          item.ReservationId.toString() + '-' + item.ClassCode.toString() + '-' + item.StudentNo.toString() !=
          student.ReservationId.toString() + '-' + student.ClassCode.toString() + '-' + student.StudentNo.toString()
      );
      setResultData(newStudentList);
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  async function fetchStudentList(param: object) {
    const response = (await api.graphql(
      graphqlOperation(findStudentList, {
        data: param,
      })
    )) as FindStudentListQuery;

    if (response?.findStudentList?.Body?.length) {
      let students = response?.findStudentList?.Body as Student[];
      setResultData(students);
      let uniqueStudents = students.filter(function (value, index, self) {
        let idx = self.findIndex((val) => val.ClassCode === value.ClassCode);
        return idx === index;
      });
      setClassCodeList(uniqueStudents);
    } else {
      setResultData([] as Student[]);
      setClassCodeList([] as Student[]);
    }
  }

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('StudentList', userData.authorityCode)) {
      navigator('/');
    }

    if (!reservationId) {
      // 予約IDが指定されていない場合、前の画面に戻る
      back();
      return;
    }
    setInputData({ ...inputData, ReservationId: reservationId });

    const param = { ReservationId: reservationId };
    fetchStudentList(param);
  }, []);

  /**
   * ブラウザバック
   */
  const back = (): void => {
    window.history.back();
  };

  /**
   * 警告ダイアログ　閉じる
   * @param result 結果
   */
  const handleAlertClose = (result: boolean): void => {
    alertDialogCallback.fn(result);
    setAlertOpen(false);
  };

  return {
    classCode,
    studentNo,
    studentName,
    resultData,
    errorAttribute,
    classCodeList,
    alertOpen,
    alertContent,
    handleChangeClassCode,
    handleChangeStudentNo,
    handleChangeStudentName,
    handleClickSearch,
    handleChangeIsActive,
    handleChangeSex,
    handleClickDetailButton,
    handleClickCreateButton,
    handleClickStudentbulkButton,
    handleChangeCheckbox,
    handleDelete,
    handleAlertClose,
  };
};
export default UseStudentList;
