import { useAuthenticator } from '@aws-amplify/ui-react';
import {
  Area,
  Branch,
  CompanyBranch,
  FindBelongsAreaQuery,
  FindMSchoolsQuery,
  FindReserveList,
  FindReserveListQuery,
  FindReserveListRequest,
  MSchools,
  Prefecture,
  SchoolRequestData,
  SchoolYearSetting,
} from 'API';
import { graphqlOperation } from 'aws-amplify';
import { UserCategoryCode } from 'constant/Constant';
import { useAPI } from 'contexts/APIRequestContext';
import { useMaster } from 'contexts/CommonMasterContext';
import { findBelongsArea, findMSchools, findReserveList } from 'graphql/queries';
import { UserData } from 'models/UserData';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReservationFrame } from 'views/reserve/reception/regist/ReservationFrame';
import { checkAuthority } from 'util/AuthorityCheck';
import Encoding from 'encoding-japanese';

const UseReserveList = () => {
  const [prefectureCode, setPrefectureCode] = useState('');
  const [schoolPrefectureCode, setSchoolPrefectureCode] = useState('');
  const [agentCompanyId, setAgentCompanyId] = useState('');
  const [acceptCompanyId, setAcceptCompanyId] = useState('');
  const [schoolYearCode, setSchoolYearCode] = useState('');
  const [areaId, setAreaId] = useState('');
  const [bookingBranchId, setBookingBranchId] = useState('');
  const [salesBranchId, setSalesBranchId] = useState('');

  const [isIncludeCancel, setIsIncludeCancel] = useState(false);
  const [stayStartDate, setStayStartDate] = useState(null as Date | null);
  const [stayEndDate, setStayEndDate] = useState(null as Date | null);
  const [inputData, setInputData] = useState({} as FindReserveListRequest);
  const [schoolYearSettingList, setSchoolYearSettingList] = useState([] as SchoolYearSetting[]);
  const [schoolInputData, setSchoolInputData] = useState({} as SchoolRequestData);
  const [resultData, setResultData] = useState([] as FindReserveList[]);
  const [prefectureList, setPrefectureList] = useState([] as Prefecture[]);
  const [companyList, setCompanyList] = useState([] as CompanyBranch[]);
  const [areaList, setAreaList] = useState([] as Area[]);
  const [branchList, setBranchList] = useState([] as Branch[]);
  const [searchSchoolOpen, setSearchSchoolOpen] = useState(false);
  const [selectSchool, setSelectSchool] = useState({} as MSchools);
  const master = useMaster();
  const { user } = useAuthenticator();
  const userData = new UserData(user);
  const api = useAPI();
  const navigator = useNavigate();
  const csvHeaders = [
    '入村日',
    '退村日',
    '学校',
    '人数',
    '泊数',
    '都道府県',
    '地区名',
    '受入事業者',
    '予約ID',
    '承認状態',
    '旅行代理店',
    '手配担当店',
    '営業担当店',
  ];
  const defalutPage = 0;
  const defaultRowsPerPage = 25;

  const [page, setPage] = useState(defalutPage);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    setInputData({ ...inputData, [name]: value });
  };

  const handleChangeFromReserveDay = (value: Date | null) => {
    setStayStartDate(value);
    if (value) {
      setInputData({
        ...inputData,
        ['StayStartDate']: value.getFullYear() + '-' + (value.getMonth() + 1) + '-' + value.getDate(),
      });
    } else {
      setInputData({
        ...inputData,
        ['StayStartDate']: '',
      });
    }

    setSchoolYearCode('');
  };

  const handleChangeToReserveDay = (value: Date | null) => {
    setStayEndDate(value);
    if (value) {
      setInputData({
        ...inputData,
        ['StayEndDate']: value.getFullYear() + '-' + (value.getMonth() + 1) + '-' + value.getDate(),
      });
    } else {
      setInputData({
        ...inputData,
        ['StayEndDate']: '',
      });
    }
    setSchoolYearCode('');
  };

  const handleSearchSchoolOpen = (): void => {
    setSearchSchoolOpen(true);
  };

  const handleResetSearchForm = (): void => {
    setPrefectureCode('');
    setSchoolPrefectureCode('');
    setAreaId('');
    setAreaPullDownList('');
    setAcceptCompanyId('');
    setSchoolYearCode('');
    setStayStartDate(null);
    setStayEndDate(null);
    setIsIncludeCancel(false);
    setSelectSchool({} as MSchools);
    setAgentCompanyId('');
    setBookingBranchId('');
    setSalesBranchId('');
    setInputData({} as FindReserveListRequest);
  };

  const handleSearchSchoolClose = (school?: MSchools): void => {
    if (school) {
      setInputData({
        ...inputData,
        SchoolId: school.SchoolId,
        SchoolPrefectureCode: '',
      });
      setSelectSchool(school);
      setSchoolPrefectureCode('');
    } else {
      setInputData({
        ...inputData,
        SchoolId: '',
      });
      setSelectSchool({} as MSchools);
    }
    setSearchSchoolOpen(false);
  };

  const handleClickSearch = () => {
    fetchReserveList();
    setPage(defalutPage);
    setRowsPerPage(defaultRowsPerPage);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const checked = event.target.checked;
  };

  async function fetchBelongArea() {
    const response = (await api.graphql(graphqlOperation(findBelongsArea))) as FindBelongsAreaQuery;
    if (response?.findBelongsArea?.Body) {
      const prefectureCode = response?.findBelongsArea?.Body?.PrefectureCode;
      setPrefectureCode(prefectureCode ? prefectureCode : '');

      const areaId = response?.findBelongsArea?.Body?.AreaId;
      // 先に支店のプルダウンリストを旅行代理店IDに基づき設定する
      setAreaPullDownList(prefectureCode ?? '');
      setAreaId(areaId ? areaId : '');

      setInputData({
        ...inputData,
        PrefectureCode: prefectureCode,
        AreaId: areaId,
      });
    }
  }

  async function fetchSchool() {
    const response = (await api.graphql(
      graphqlOperation(findMSchools, {
        data: schoolInputData,
      })
    )) as FindMSchoolsQuery;

    if (response?.findMSchools?.Body?.length) {
      setInputData({
        ...inputData,
        SchoolId: response?.findMSchools?.Body[0].SchoolId,
      });
      setSelectSchool(response?.findMSchools?.Body[0] as MSchools);
    }
  }

  const handleClickDetailButton = (reservation: FindReserveList) => {
    // ローカルストレージまたはセッションストレージに検索条件を退避する
    localStorage.setItem(
      'ReserveList_SearchConditions',
      JSON.stringify({ inputData, selectSchool, schoolYearCode, rowsPerPage, page })
    );
    localStorage.setItem('ReserveList_SearchResult', JSON.stringify(resultData));
    // 戻った時に状態を復元するフラグをセット
    sessionStorage.setItem('ReserveList_ShouldRestore', 'true');

    navigator('/reserve/detail', {
      state: {
        ReservationId: reservation.ReservationId,
        ReservedDay: reservation.CheckInDate,
      } as ReservationFrame,
    });
  };

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('ReserveList', userData.authorityCode)) {
      navigator('/');
    }

    master.waitForInitialized(() => {
      setPrefectureList(master.getPrefecture());
      setCompanyList(master.getCompanies());
      setSchoolYearSettingList(
        master
          .getSchoolYearSettings()
          .filter((x) => Date.parse(x.SchoolYear.OpenDatetime) < Date.now())
          .sort((n1, n2) => {
            if (n1.SchoolYear.SchoolYearCode > n2.SchoolYear.SchoolYearCode) {
              return -1;
            }
            if (n1.SchoolYear.SchoolYearCode < n2.SchoolYear.SchoolYearCode) {
              return 1;
            }
            return 0;
          })
      );

      // NOTE: 変更を検知して条件をセットしている為、初期値のある項目は起動時に条件指定する。
      inputData.IsDescending = true;

      // 詳細画面から戻ってきた場合、ローカルストレージに退避していた情報を復元する
      const shouldRestore = sessionStorage.getItem('ReserveList_ShouldRestore');
      if (shouldRestore) {
        const storedConditionsString = localStorage.getItem('ReserveList_SearchConditions');
        const storedResultString = localStorage.getItem('ReserveList_SearchResult');
        let storedConditions = JSON.parse(storedConditionsString ?? '');
        let storedResult = JSON.parse(storedResultString ?? '');
        if (storedConditions) {
          setPrefectureCode(storedConditions?.inputData?.PrefectureCode ?? '');
          setSchoolPrefectureCode(storedConditions?.inputData?.SchoolPrefectureCode ?? '');
          setAreaId(storedConditions?.inputData?.AreaId ?? '');
          setAcceptCompanyId(storedConditions?.inputData?.AcceptCompanyId ?? '');
          setStayStartDate(
            storedConditions?.inputData?.StayStartDate ? new Date(storedConditions.inputData.StayStartDate) : null
          );
          setStayEndDate(
            storedConditions?.inputData?.StayEndDate ? new Date(storedConditions.inputData.StayEndDate) : null
          );
          setIsIncludeCancel(storedConditions?.inputData?.IsIncludeCancel ?? false);
          setSelectSchool(storedConditions?.selectSchool ?? ({} as MSchools));
          setAgentCompanyId(storedConditions?.inputData?.AgentCompanyId ?? '');

          // 先に支店のプルダウンリストを旅行代理店IDに基づき設定する
          setBranchPullDownList(storedConditions?.inputData?.AgentCompanyId ?? '');

          setBookingBranchId(storedConditions?.inputData?.BookingBranchId ?? '');
          setSalesBranchId(storedConditions?.inputData?.SalesBranchId ?? '');
          setSchoolYearCode(storedConditions?.schoolYearCode ?? '');
          setInputData(storedConditions?.inputData);
          setRowsPerPage(storedConditions?.rowsPerPage);
          setPage(storedConditions?.page);
        }
        if (storedResult) {
          setResultData(storedResult);
        }
      } else {
        if (userData.userCategoryCode == UserCategoryCode.MinpakuCompany) {
          fetchBelongArea();
        } else if (userData.userCategoryCode == UserCategoryCode.School) {
          schoolInputData.SchoolId = userData.schoolId;
          fetchSchool();
        } else if (userData.userCategoryCode == UserCategoryCode.Agent) {
          changeCompany(userData.companyId);
        }
      }

      // フラグをリセット
      sessionStorage.removeItem('ReserveList_ShouldRestore');
    });
  }, []);

  async function fetchReserveList() {
    const response = (await api.graphql(
      graphqlOperation(findReserveList, {
        data: inputData,
      })
    )) as FindReserveListQuery;

    if (response?.findReserveList?.Body?.length) {
      setResultData(response?.findReserveList?.Body as FindReserveList[]);
    } else {
      setResultData([] as FindReserveList[]);
    }
  }
  const handleChangePrefectureCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPrefectureCode(value);

    changeArea(value);
  };

  /**
   * エリア情報更新
   */
  const changeArea = (prefectureCode: string) => {
    // Step1. 通常の入力値変更と店名クリア
    inputData.PrefectureCode = prefectureCode;
    inputData.AreaId = '';

    setInputData({
      ...inputData,
      PrefectureCode: prefectureCode,
      AreaId: '',
    });

    // Step2. 支店プルダウンデータ変更
    setAreaPullDownList(prefectureCode);
  };

  /**
   * エリアプルダウンリストを更新する
   */
  const setAreaPullDownList = (prefectureCode: string) => {
    const _areaList = master.getAreasByPrefectureCode(prefectureCode) as Area[];
    if (_areaList) {
      setAreaList(_areaList);
    } else {
      setAreaList([] as Area[]);
    }
  };

  const handleChangeArea = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setAreaId(value);
    setInputData({ ...inputData, AreaId: value });
  };

  const handleChangeSchoolPrefectureCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSchoolPrefectureCode(value);
    setInputData({ ...inputData, SchoolPrefectureCode: value });
  };

  const handleChangeAgentCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgentCompanyId(event.target.value);
    changeCompany(event.target.value);
  };

  const handleChangeAcceptCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setAcceptCompanyId(value);
    setInputData({ ...inputData, AcceptCompanyId: value });
  };

  const handleChangeSchoolYearCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSchoolYearCode(value);

    if (value === '' || value === null) {
      setStayStartDate(null);
      setStayEndDate(null);
      setInputData({
        ...inputData,
        StayStartDate: '',
        StayEndDate: '',
      });
    } else {
      const schoolYearSetting = schoolYearSettingList.find((x) => x.SchoolYear.SchoolYearCode === value);

      if (schoolYearSetting?.SchoolYear?.StartDate && schoolYearSetting?.SchoolYear?.EndDate) {
        const stayStartDate = new Date(schoolYearSetting.SchoolYear.StartDate);
        const stayEndDate = new Date(schoolYearSetting.SchoolYear.EndDate);

        setStayEndDate(new Date(stayEndDate));
        setStayStartDate(new Date(stayStartDate));
        setInputData({
          ...inputData,
          StayStartDate: `${stayStartDate.getFullYear()}-${stayStartDate.getMonth() + 1}-${stayStartDate.getDate()}`,
          StayEndDate: `${stayEndDate.getFullYear()}-${stayEndDate.getMonth() + 1}-${stayEndDate.getDate()}`,
        });
      }
    }
  };

  const handleChangeBranchName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    if (name === 'BookingBranchId') {
      setBookingBranchId(value);
      setInputData({ ...inputData, BookingBranchId: value });
    } else if (name === 'SalesBranchId') {
      setSalesBranchId(value);
      setInputData({ ...inputData, SalesBranchId: value });
    }
  };

  const handleChangeOrder = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputData({ ...inputData, IsDescending: value === 'true' ? true : false });
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsIncludeCancel(event.target.checked);
    setInputData({ ...inputData, [event.target.name]: event.target.checked });
  };

  const handleClickCsvOutput = () => {
    // 検索結果をcsv形式でダウンロード
    let dd = new Date();
    const fileName = `reservations_${dd.getFullYear()}${(dd.getMonth() + 1).toString().padStart(2, '0')}${dd
      .getDate()
      .toString()
      .padStart(2, '0')}.csv`;

    let exportData = [];
    exportData.push(csvHeaders.join(','));
    for (const reserve of resultData) {
      exportData.push(
        [
          reserve.CheckInDate,
          reserve.CheckOutDate,
          reserve.SchoolName,
          reserve.GuestCount,
          reserve.StayNights,
          reserve.PrefectureName,
          reserve.AreaName,
          reserve.AcceptCompanyName,
          reserve.ReservationId,
          reserve.ApprovalStatus,
          reserve.CompanyName,
          reserve.BookingBranchName,
          reserve.SalesBranchName,
        ].join(',')
      );
    }

    let utf16Array = Encoding.stringToCode(exportData.join('\r\n'));
    let sjisArray = Encoding.convert(utf16Array, {
      to: 'SJIS',
      from: 'UNICODE',
      type: 'array',
    });

    const data = new Blob([new Uint8Array(sjisArray)], { type: 'text/csv' });
    const csvURL = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = csvURL;
    link.setAttribute('download', fileName);
    link.click();
    document.body.removeChild(link);
  };

  /**
   * 代理店情報　代理店名更新
   */
  const changeCompany = (companyId: string) => {
    // Step1. 通常の入力値変更と店名クリア
    inputData.AgentCompanyId = companyId;
    inputData.BookingBranchId = '';
    inputData.SalesBranchId = '';

    setAgentCompanyId(companyId);
    setInputData({
      ...inputData,
      AgentCompanyId: companyId,
      BookingBranchId: '',
      SalesBranchId: '',
    });

    // Step2. 支店プルダウンデータ変更
    setBranchPullDownList(companyId);
  };

  /**
   * 旅行代理店支店プルダウンリストを更新する
   */
  const setBranchPullDownList = (companyId: string) => {
    const _branchList = master.getBranchs(companyId) as Branch[];
    if (_branchList) {
      setBranchList(_branchList);
    } else {
      setBranchList([] as Branch[]);
    }
  };

  return {
    prefectureCode,
    schoolPrefectureCode,
    agentCompanyId,
    acceptCompanyId,
    schoolYearCode,
    areaId,
    bookingBranchId,
    salesBranchId,
    isIncludeCancel,
    stayStartDate,
    stayEndDate,

    companyList,
    prefectureList,
    areaList,
    branchList,
    schoolYearSettingList,
    searchSchoolOpen,
    selectSchool,
    resultData,
    userData,
    page,
    rowsPerPage,
    handleChangePrefectureCode,
    handleChangeSchoolPrefectureCode,
    handleChangeAgentCompany,
    handleChangeAcceptCompany,
    handleChangeSchoolYearCode,
    handleChangeArea,
    handleChangeBranchName,
    handleChangeCheckbox,
    handleChangeOrder,
    handleRadioChange,
    handleChangeFromReserveDay,
    handleChangeToReserveDay,
    handleResetSearchForm,
    handleSearchSchoolOpen,
    handleSearchSchoolClose,
    handleClickSearch,
    handleClickDetailButton,
    handleClickCsvOutput,
    handleChangePage,
    handleChangeRowsPerPage,
  };
};
export default UseReserveList;
