import { FC, useState } from 'react';
import GenericTemplate from '../../../template/GenericTemplate';
import React from 'react';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import Grid from '@mui/material/Grid/Grid';
import SelectFormControl from '../../../parts/SelectFormControl';
import { SelectValue } from '../../../../types/SelectValue';
import FormGroup from '@mui/material/FormGroup/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import Button from '@mui/material/Button/Button';
import Box from '@mui/material/Box/Box';
import TableBody from '@mui/material/TableBody/TableBody';
import TablePagination from '@mui/material/TablePagination/TablePagination';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import FormControl from '@mui/material/FormControl/FormControl';
import Radio from '@mui/material/Radio/Radio';
import RadioGroup from '@mui/material/RadioGroup/RadioGroup';
import { MServices, FindServiceListRequest, CompanyBranch } from '../../../../API';
import { ErrorData } from './ServiceListErrorData';
import FormHelperText from '@mui/material/FormHelperText';
import AlertDialog from '../../alert';
import BackToTopButton from 'views/parts/BackToTop';

const styles = {
  searchField: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
  },

  label: {
    margin: 'auto',
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
  },

  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
  },

  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
    '& > div > input': {
      maxHeight: '10px',
    },
  },

  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 1,
    ml: 1,
  },

  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },

  listButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    m: 0,
  },

  deleteCell: {
    '& > a': {
      textDecoration: 'none',
    },
  },
};

export interface Props {
  inputData: FindServiceListRequest;
  companyId: string;
  isActive: string;
  resultData: MServices[];
  errorAttribute: ErrorData;
  alertOpen: boolean;
  alertContent: string;
  companyList: CompanyBranch[];
  serviceCategoryCodeList: Array<string>;
  page: number;
  rowsPerPage: number;
  handleChangeInput: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeCompany: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeIsActive: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickSearch: () => void;
  handleClickDeleteButton: (_: MServices) => void;
  handleClickCreateButton: () => void;
  handleClickDetailButton: (_: MServices) => void;
  handleAlertClose: (_: boolean) => void;
  handleChangeCheckbox: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangePage: (_: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => void;
  handleChangeRowsPerPage: (_: React.ChangeEvent<HTMLInputElement>) => void;
}

const ServiceList: FC<Props> = (props) => {
  return (
    <GenericTemplate title="サービス一覧">
      <div>
        <BackToTopButton />
      </div>
      <Container sx={styles.searchField}>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          検索条件
        </Typography>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>提供事業者</Typography>
          </Grid>
          <Grid item xs={7}>
            <SelectFormControl
              value={props.companyId}
              selectName="CompanyId"
              selectValues={props.companyList
                .filter((x) => x.CategoryCode === '01' || x.CategoryCode === '02')
                .map((company, idx) => {
                  return { value: company.CompanyId, displayName: company.CompanyName } as SelectValue;
                })}
              setCondition={props.handleChangeCompany}
              sx={styles.inputItem}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>サービスカテゴリ</Typography>
          </Grid>
          <Grid item xs={7}>
            <FormGroup style={{ display: 'block' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="ServiceCategoryCode"
                    value={1}
                    onChange={props.handleChangeCheckbox}
                    checked={props.serviceCategoryCodeList.indexOf('1') > -1}
                  />
                }
                label="民家体験学習泊"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="ServiceCategoryCode"
                    value={2}
                    onChange={props.handleChangeCheckbox}
                    checked={props.serviceCategoryCodeList.indexOf('2') > -1}
                  />
                }
                label="民泊オプション"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="ServiceCategoryCode"
                    value={3}
                    onChange={props.handleChangeCheckbox}
                    checked={props.serviceCategoryCodeList.indexOf('3') > -1}
                  />
                }
                label="日帰り民家体験"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="ServiceCategoryCode"
                    value={4}
                    onChange={props.handleChangeCheckbox}
                    checked={props.serviceCategoryCodeList.indexOf('4') > -1}
                  />
                }
                label="民家下見"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="ServiceCategoryCode"
                    value={5}
                    onChange={props.handleChangeCheckbox}
                    checked={props.serviceCategoryCodeList.indexOf('5') > -1}
                  />
                }
                label="マリン"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="ServiceCategoryCode"
                    value={6}
                    onChange={props.handleChangeCheckbox}
                    checked={props.serviceCategoryCodeList.indexOf('6') > -1}
                  />
                }
                label="レンタル"
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>有効区分</Typography>
          </Grid>
          <Grid item xs={7}>
            <RadioGroup row aria-label="position" defaultValue="1">
              <FormControlLabel
                value="1"
                control={
                  <Radio
                    color="primary"
                    name="IsActive"
                    value={'1'}
                    onChange={props.handleChangeIsActive}
                    checked={props.isActive === '1'}
                  />
                }
                label="有効"
              />
              <FormControlLabel
                value="0"
                control={
                  <Radio
                    color="primary"
                    name="IsActive"
                    value={'0'}
                    onChange={props.handleChangeIsActive}
                    checked={props.isActive === '0'}
                  />
                }
                label="無効"
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'center', '& > a': { textDecoration: 'none' } }}>
          <Button variant="outlined" sx={styles.button} onClick={props.handleClickCreateButton}>
            新規作成
          </Button>
          <Button variant="contained" sx={styles.button} onClick={props.handleClickSearch}>
            検索
          </Button>
        </Box>
      </Container>
      {props.errorAttribute.Header.ErrorMessage && (
        <Grid container spacing={2} sx={{ pl: 0, pb: 2 }}>
          <Grid item xs={12}>
            <FormControl component="fieldset" error={props.errorAttribute.Header.IsError}>
              <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                {props.errorAttribute.Header.ErrorMessage}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      )}
      <Container sx={styles.searchField}>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          サービス一覧
        </Typography>
        <TableContainer sx={{ minHeight: 300 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>会社名</TableCell>
                <TableCell>サービス名</TableCell>
                <TableCell>カテゴリ</TableCell>
                <TableCell>税抜き単価</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.resultData
                .slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage)
                .map((services, index) => (
                  <TableRow key={index}>
                    <TableCell component="td" scope="row">
                      <Button
                        variant="contained"
                        sx={styles.listButton}
                        size="small"
                        onClick={() => props.handleClickDeleteButton(services)}
                      >
                        削除
                      </Button>
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {services.CompanyName}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {services.ServiceName}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {services.ServiceCategoryName}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {services.ServiceRate}
                    </TableCell>
                    <TableCell component="td" scope="row" sx={styles.deleteCell}>
                      <Button
                        variant="contained"
                        sx={styles.listButton}
                        size="small"
                        onClick={() => props.handleClickDetailButton(services)}
                      >
                        詳細
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={props.resultData.length}
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          onPageChange={props.handleChangePage}
          onRowsPerPageChange={props.handleChangeRowsPerPage}
        />
        <AlertDialog
          handleDialogClose={props.handleAlertClose}
          dialogOpen={props.alertOpen}
          content={props.alertContent}
        />
      </Container>
    </GenericTemplate>
  );
};

export default ServiceList;
