import { Area, InitMinkaAllocateSearchQuery, Prefecture, SchoolYear, SchoolYearPeriod } from 'API';
import { useAPI } from 'contexts/APIRequestContext';
import { initMinkaAllocateSearch } from 'graphql/queries';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkAuthority } from 'util/AuthorityCheck';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';

const UseMinkaAllocateLedgerSearch = () => {
  const [prefecture, setPrefecture] = useState('');
  const [area, setArea] = useState('');
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [prefectureList, setPrefectureList] = useState([] as Prefecture[]);
  const [areaList, setAreaList] = useState([] as Area[]);
  const [yearList, setYearList] = useState([] as SchoolYear[]);
  const [monthList, setMonthList] = useState([] as SchoolYearPeriod[]);
  const navigate = useNavigate();
  const api = useAPI();
  const [, setError] = useState<undefined>();
  const { user } = useAuthenticator();
  const userData = new UserData(user);

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('MinkaAllocateLedgerSearch', userData.authorityCode)) {
      navigate('/');
    }

    executeInitMinkaAllocationSearch();
  }, []);

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  const executeInitMinkaAllocationSearch = async (): Promise<void> => {
    const response = (
      (await api.graphql({
        query: initMinkaAllocateSearch,
      })) as InitMinkaAllocateSearchQuery
    ).InitMinkaAllocateSearch;

    if (response?.IsSuccess) {
      setPrefectureList(response.Body?.Prefectures as Prefecture[]);
      setAreaList(response.Body?.Areas as Area[]);
      setYearList(response.Body?.Years as SchoolYear[]);
      setMonthList(response.Body?.Months as SchoolYearPeriod[]);

      const prefecture = response.Body?.Prefectures?.length ? response.Body?.Prefectures[0].PrefectureCode : '';
      setPrefecture(prefecture);

      const areas = response.Body?.Areas?.filter((x) => x.PrefectureCode === prefecture);
      if (areas?.length) {
        setArea(areas[0].AreaId);
      }

      const year = response.Body?.Years?.length ? response.Body?.Years[0].SchoolYearCode : '';
      setYear(year);

      const months = response.Body?.Months?.filter((x) => x.SchoolYearCode === year);
      if (months?.length) {
        setMonth(months[0].PeriodCode);
      }
    } else if (response?.IsSystemError) {
      throwError(response.ErrorData ?? '');
    } else {
      throwError();
    }
  };

  const handleChangePrefecture = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrefecture(event.target.value);
    setArea('');
  };

  const handleChangeArea = (event: React.ChangeEvent<HTMLInputElement>) => {
    setArea(event.target.value);
  };

  const handleChangeYear = (event: React.ChangeEvent<HTMLInputElement>) => {
    setYear(event.target.value);
    setMonth('');
  };

  const handleChangeMonth = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMonth(event.target.value);
  };

  const handleClickSearch = () => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('area', area);
    urlSearchParams.append('year', year);
    urlSearchParams.append('month', month);
    navigate('/minka/ledger/?' + urlSearchParams.toString());
  };

  return {
    prefecture,
    area,
    year,
    month,
    prefectureList,
    areaList,
    yearList,
    monthList,
    handleChangePrefecture,
    handleChangeArea,
    handleChangeYear,
    handleChangeMonth,
    handleClickSearch,
  };
};
export default UseMinkaAllocateLedgerSearch;
