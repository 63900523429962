import { useEffect, useState, useCallback } from 'react';
import { CreateEmptyErrorData, ErrorData } from './StudentBulkInsertErrorData';
import { useAPI } from 'contexts/APIRequestContext';
import { bulkInsertStudent } from 'graphql/mutations';
import { downloadPublicFile } from 'graphql/queries';
import { StudentBulkInsertRequest, BulkInsertStudentMutation, DownloadPublicFileQuery } from 'API';
import { useLocation } from 'react-router-dom';
import { Buffer } from 'buffer';
import Encoding from 'encoding-japanese';
import { useNavigate } from 'react-router-dom';
import { checkAuthority } from 'util/AuthorityCheck';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';

const UseStudentBulkInsert = () => {
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [, setError] = useState<undefined>();
  const api = useAPI();
  const [message, setMessage] = useState('');
  const location = useLocation();
  const reservationId = location.state as string;
  const [inputData, setInputData] = useState({ ReservationId: reservationId } as StudentBulkInsertRequest);
  const navigator = useNavigate();
  const { user } = useAuthenticator();
  const userData = new UserData(user);

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  /**
   * CSVファイルのサンプルダウンロード処理
   */
  const handleClickDownloadSample = async (): Promise<void> => {
    const response = (
      (await api.graphql({
        query: downloadPublicFile,
        variables: {
          data: '生徒一括登録サンプル.csv',
        },
      })) as DownloadPublicFileQuery
    ).downloadPublicFile;
    if (response?.IsSuccess) {
      if (response.Body) {
        // Shift-JISでファイルをダウンロードする
        var unicodeData = new TextDecoder('sjis').decode(Buffer.from(response.Body?.FileBase64String, 'base64'));
        const data = new Blob(
          [
            new Uint8Array(
              Encoding.convert(Encoding.stringToCode(unicodeData), {
                to: 'SJIS',
                from: 'UNICODE',
              })
            ),
          ],
          {
            type: 'text/csv',
          }
        );

        const anchor = document.createElement('a');
        anchor.href = window.URL.createObjectURL(data);
        anchor.download = '生徒一括登録サンプル.csv';
        document.body.appendChild(anchor);
        anchor.click();
        URL.revokeObjectURL(anchor.href);
        document.body.removeChild(anchor);
      }
    } else if (response?.IsSystemError) {
      throwError(response.ErrorData ?? '');
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...errorAttribute,
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  };

  /**
   * アップロードファイル変更時処理
   * @param event イベント
   */
  const handleChangeUploadFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files) {
      const fileName = event.target.files[0].name;
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        setInputData({
          ...inputData,
          UploadFileName: fileName,
          UploadFileBase64String: (reader.result as string).split(';base64,').pop(),
        });
      };
    }
  };

  /**
   * csvファイルをアップロードし、生徒一括登録を行う
   */
  const executeBulkInsert = async (): Promise<void> => {
    const response = (
      (await api.graphql({
        query: bulkInsertStudent,
        variables: {
          data: inputData,
        },
      })) as BulkInsertStudentMutation
    ).bulkInsertStudent;

    if (response?.IsSuccess) {
      showMessage('登録が完了しました。');
    } else if (response?.IsSystemError) {
      throwError(response.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      } as ErrorData);
    } else {
      throwError();
    }
  };

  /**
   * アップロードファイル削除時
   */
  const handleDeleteUploadFile = (): void => {
    setInputData({ ...inputData, UploadFileBase64String: '', UploadFileName: '' });
    setErrorAttribute({
      ...errorAttribute,
      UploadFileName: { IsError: false, ErrorMessage: '' },
    });
  };

  /**
   * 閉じる
   */
  const handleClose = (): void => {
    back();
  };

  /**
   * ブラウザバック
   */
  const back = (): void => {
    window.history.back();
  };

  const showMessage = (message: string) => {
    setMessage(message);
  };

  const handleMessageDialogClose = () => {
    setMessage('');
  };

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('StudentBulkInsert', userData.authorityCode)) {
      navigator('/');
    }

    if (!reservationId) {
      // 予約IDが指定されていない場合、前の画面に戻る
      back();
      return;
    }
  }, []);

  return {
    errorAttribute,
    message,
    inputData,
    handleClickDownloadSample,
    handleChangeUploadFile,
    executeBulkInsert,
    handleClose,
    handleMessageDialogClose,
    handleDeleteUploadFile,
  };
};
export default UseStudentBulkInsert;
