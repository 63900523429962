import FormErrorAttribute from 'types/FormErrorAttribute';

export interface ErrorData {
  Header: FormErrorAttribute;
  // 民家基本情報
  MinkaName: FormErrorAttribute;
  BussinessName: FormErrorAttribute;
  MinkaNo: FormErrorAttribute;
  PostalCode: FormErrorAttribute;
  PrefectureCode: FormErrorAttribute;
  AreaId: FormErrorAttribute;
  RegionCode: FormErrorAttribute;
  City: FormErrorAttribute;
  Address: FormErrorAttribute;
  Tel: FormErrorAttribute;
  Latitude: FormErrorAttribute;
  Longitude: FormErrorAttribute;
  GuestCount: FormErrorAttribute;
  MaxGuestcount: FormErrorAttribute;
  AuthorityNumber: FormErrorAttribute;
  AcceptanceGender: FormErrorAttribute;
  Pets: FormErrorAttribute;
  DressingRoom: FormErrorAttribute;
  AirConditionar: FormErrorAttribute;
  ToiletType: FormErrorAttribute;
  SmokeType: FormErrorAttribute;
  InternetType: FormErrorAttribute;
  BarrierFreeCapability: FormErrorAttribute;
  AuthorizedDate: FormErrorAttribute;
  OtherAddress: FormErrorAttribute;
  BankName: FormErrorAttribute;
  BankBranchName: FormErrorAttribute;
  AccountType: FormErrorAttribute;
  AccountNumber: FormErrorAttribute;
  AccountName: FormErrorAttribute;
  AccountNameKana: FormErrorAttribute;
  Memo: FormErrorAttribute;
  BussinessStartDate: FormErrorAttribute;
  ConstructionDate: FormErrorAttribute;
  ArchitecturalStructure: FormErrorAttribute;
  LandslideWarningArea: FormErrorAttribute;
  TsunamiWarningArea: FormErrorAttribute;
  EvacuationSite: FormErrorAttribute;
  FloodHazard: FormErrorAttribute;
  RainHazard: FormErrorAttribute;
  HighTideHazard: FormErrorAttribute;

  // 民家同居人（代表者）
  IsAvailable: FormErrorAttribute;
  IsActive: FormErrorAttribute;
  LastName: FormErrorAttribute;
  FirstName: FormErrorAttribute;
  LastKanaName: FormErrorAttribute;
  FirstKanaName: FormErrorAttribute;
  BirthDay: FormErrorAttribute;
  Sex: FormErrorAttribute;
  Occupation: FormErrorAttribute;
  ResidentsRelationCode: FormErrorAttribute;
  Mail: FormErrorAttribute;
  WorkHistory: FormErrorAttribute;
  Character: FormErrorAttribute;

  // アクティビティ
  Activity: FormErrorAttribute;
}

export const CreateEmptyErrorData = () => {
  return {
    Header: {} as FormErrorAttribute,
    // 民家基本情報
    MinkaName: {} as FormErrorAttribute,
    BussinessName: {} as FormErrorAttribute,
    MinkaNo: {} as FormErrorAttribute,
    PostalCode: {} as FormErrorAttribute,
    PrefectureCode: {} as FormErrorAttribute,
    AreaId: {} as FormErrorAttribute,
    RegionCode: {} as FormErrorAttribute,
    City: {} as FormErrorAttribute,
    Address: {} as FormErrorAttribute,
    Tel: {} as FormErrorAttribute,
    Latitude: {} as FormErrorAttribute,
    Longitude: {} as FormErrorAttribute,
    GuestCount: {} as FormErrorAttribute,
    MaxGuestcount: {} as FormErrorAttribute,
    AuthorityNumber: {} as FormErrorAttribute,
    AcceptanceGender: {} as FormErrorAttribute,
    Pets: {} as FormErrorAttribute,
    DressingRoom: {} as FormErrorAttribute,
    AirConditionar: {} as FormErrorAttribute,
    ToiletType: {} as FormErrorAttribute,
    SmokeType: {} as FormErrorAttribute,
    InternetType: {} as FormErrorAttribute,
    BarrierFreeCapability: {} as FormErrorAttribute,
    AuthorizedDate: {} as FormErrorAttribute,
    OtherAddress: {} as FormErrorAttribute,
    BankName: {} as FormErrorAttribute,
    BankBranchName: {} as FormErrorAttribute,
    AccountType: {} as FormErrorAttribute,
    AccountNumber: {} as FormErrorAttribute,
    AccountName: {} as FormErrorAttribute,
    AccountNameKana: {} as FormErrorAttribute,
    Memo: {} as FormErrorAttribute,
    IsActive: {} as FormErrorAttribute,
    IsAvailable: {} as FormErrorAttribute,
    BussinessStartDate: {} as FormErrorAttribute,
    ConstructionDate: {} as FormErrorAttribute,
    ArchitecturalStructure: {} as FormErrorAttribute,
    FloodHazard: {} as FormErrorAttribute,
    LandslideWarningArea: {} as FormErrorAttribute,
    TsunamiWarningArea: {} as FormErrorAttribute,
    EvacuationSite: {} as FormErrorAttribute,
    RainHazard: {} as FormErrorAttribute,
    HighTideHazard: {} as FormErrorAttribute,

    // 民家同居人（代表者）
    LastName: {} as FormErrorAttribute,
    FirstName: {} as FormErrorAttribute,
    LastKanaName: {} as FormErrorAttribute,
    FirstKanaName: {} as FormErrorAttribute,
    BirthDay: {} as FormErrorAttribute,
    Sex: {} as FormErrorAttribute,
    Occupation: {} as FormErrorAttribute,
    ResidentsRelationCode: {} as FormErrorAttribute,
    Mail: {} as FormErrorAttribute,
    WorkHistory: {} as FormErrorAttribute,
    Character: {} as FormErrorAttribute,

    // アクティビティ
    Activity: {} as FormErrorAttribute,
  } as ErrorData;
};
