import FormErrorAttribute from 'types/FormErrorAttribute';

export interface ErrorData {
  Header: FormErrorAttribute;
  MaxGuestCount: FormErrorAttribute;
  MaxSchoolCount: FormErrorAttribute;
  MaxMultiSchoolGuestCount: FormErrorAttribute;
  MaxTemporaryReservationCount: FormErrorAttribute;
  MaxReservationCount: FormErrorAttribute;
  CheckInTime: FormErrorAttribute;
  CheckOutTime: FormErrorAttribute;
  MultiStayCheckInTime: FormErrorAttribute;
  MultiStayCheckOutTime: FormErrorAttribute;
  ClosedReason: FormErrorAttribute;
}

export const CreateEmptyErrorData = () => {
  return {
    Header: {} as FormErrorAttribute,
    MaxGuestCount: {} as FormErrorAttribute,
    MaxSchoolCount: {} as FormErrorAttribute,
    MaxMultiSchoolGuestCount: {} as FormErrorAttribute,
    MaxTemporaryReservationCount: {} as FormErrorAttribute,
    MaxReservationCount: {} as FormErrorAttribute,
    CheckInTime: {} as FormErrorAttribute,
    CheckOutTime: {} as FormErrorAttribute,
    MultiStayCheckInTime: {} as FormErrorAttribute,
    MultiStayCheckOutTime: {} as FormErrorAttribute,
    ClosedReason: {} as FormErrorAttribute,
  } as ErrorData;
};
