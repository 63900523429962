import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Branch } from 'API';
import { FC } from 'react';
import { SelectValue } from 'types/SelectValue';
import SelectFormControl from 'views/parts/SelectFormControl';
import GenericTemplate from 'views/template/GenericTemplate';
import { ErrorData } from './AccountSettingErrorData';
import { AccountSettingData } from './AccountSettingData';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { AuthorityCode, UserCategoryCode } from 'constant/Constant';
import BackToTopButton from 'views/parts/BackToTop';

export interface Props {
  inputData: AccountSettingData;
  errorAttribute: ErrorData;
  branchList: Branch[];
  companyName: string;
  schoolName: string;
  handleClickRegist: () => void;
  handleChangeInput: (_: React.ChangeEvent<HTMLInputElement>) => void;
}

const styles = {
  inputItem: {
    width: '100%',
  },
  label: {
    mt: '7px',
  },
  categoryBlock: {
    mt: '5px',
    mx: '5px',
    mb: '10px',
  },
  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 3,
    ml: 1,
  },
  headerMessage: {
    color: '#d32f2f',
    fontWeight: 'bold',
  },
};

const AccountSetting: FC<Props> = ({
  inputData,
  errorAttribute,
  branchList,
  companyName,
  schoolName,
  handleClickRegist,
  handleChangeInput,
}) => {
  const { user } = useAuthenticator();
  const userData = new UserData(user);

  return (
    <GenericTemplate title="アカウント設定">
      <div>
        <BackToTopButton />
      </div>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          {errorAttribute.Header.ErrorMessage && (
            <Grid container spacing={2} sx={{ pl: 0, pb: 2 }}>
              <Grid item xs={12}>
                <FormControl component="fieldset" error={errorAttribute.Header.IsError}>
                  <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                    {errorAttribute.Header.ErrorMessage}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2} sx={styles.categoryBlock}>
            <Grid item xs={3}>
              <Typography sx={styles.label}>ユーザID</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography sx={styles.label}>{userData.userName}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>元のパスワード</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                type="password"
                hiddenLabel
                name="OldPassword"
                value={inputData.OldPassword}
                onChange={handleChangeInput}
                size="small"
                sx={styles.inputItem}
                error={errorAttribute.OldPassword.IsError}
                helperText={errorAttribute.OldPassword.ErrorMessage}
                inputProps={{ maxLength: '64' }}
                placeholder="パスワードを変更する場合に入力してください。変更しない場合は空白のままとしてください。"
              />
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>新しいパスワード</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                type="password"
                hiddenLabel
                name="Password"
                value={inputData.Password}
                onChange={handleChangeInput}
                size="small"
                sx={styles.inputItem}
                error={errorAttribute.Password.IsError}
                helperText={errorAttribute.Password.ErrorMessage}
                inputProps={{ maxLength: '64' }}
                placeholder="パスワードを変更する場合に入力してください。変更しない場合は空白のままとしてください。"
              />
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>パスワードの確認</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                type="password"
                hiddenLabel
                name="PasswordConfirm"
                value={inputData.PasswordConfirm}
                onChange={handleChangeInput}
                size="small"
                sx={styles.inputItem}
                error={errorAttribute.PasswordConfirm.IsError}
                helperText={errorAttribute.PasswordConfirm.ErrorMessage}
                inputProps={{ maxLength: '64' }}
                placeholder="パスワードを変更する場合に入力してください。変更しない場合は空白のままとしてください。"
              />
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>氏名</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                hiddenLabel
                name="Name"
                value={inputData.Name}
                onChange={handleChangeInput}
                size="small"
                sx={styles.inputItem}
                error={errorAttribute.Name.IsError}
                helperText={errorAttribute.Name.ErrorMessage}
                inputProps={{ maxLength: '20' }}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>カナ氏名</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                hiddenLabel
                name="KanaName"
                value={inputData.KanaName}
                onChange={handleChangeInput}
                size="small"
                sx={styles.inputItem}
                error={errorAttribute.KanaName.IsError}
                helperText={errorAttribute.KanaName.ErrorMessage}
                inputProps={{ maxLength: '40' }}
              />
            </Grid>
            {userData.authorityCode !== AuthorityCode.School && (
              <>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>会社</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography sx={styles.label}>{companyName}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>支店・営業店</Typography>
                </Grid>
                <Grid item xs={9}>
                  <SelectFormControl
                    value={inputData.BranchId ?? ''}
                    selectName="BranchId"
                    selectValues={branchList.map((branch, idx) => {
                      return { value: branch.BranchId.toString(), displayName: branch.BranchName } as SelectValue;
                    })}
                    setCondition={handleChangeInput}
                    isDisabled={
                      userData.userCategoryCode !== UserCategoryCode.MinpakuCompany &&
                      userData.userCategoryCode !== UserCategoryCode.ServiceCompany &&
                      !userData.canAllDept
                    }
                    sx={styles.inputItem}
                    error={errorAttribute.BranchId.IsError}
                    errorMessage={errorAttribute.BranchId.ErrorMessage}
                  />
                </Grid>
              </>
            )}
            {userData.authorityCode === AuthorityCode.School && (
              <>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>学校</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography sx={styles.label}>{schoolName}</Typography>
                </Grid>
              </>
            )}
            <Grid item xs={3}>
              <Typography sx={styles.label}>メール1</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                required
                hiddenLabel
                name="Mail1"
                value={inputData.Mail1}
                onChange={handleChangeInput}
                size="small"
                sx={styles.inputItem}
                error={errorAttribute.Mail1.IsError}
                helperText={errorAttribute.Mail1.ErrorMessage}
                inputProps={{ type: 'email', maxLength: '255' }}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>メール2</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                required
                hiddenLabel
                name="Mail2"
                value={inputData.Mail2}
                onChange={handleChangeInput}
                size="small"
                sx={styles.inputItem}
                error={errorAttribute.Mail2.IsError}
                helperText={errorAttribute.Mail2.ErrorMessage}
                inputProps={{ type: 'email', maxLength: '255' }}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>メール3</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                required
                hiddenLabel
                name="Mail3"
                value={inputData.Mail3}
                onChange={handleChangeInput}
                size="small"
                sx={styles.inputItem}
                error={errorAttribute.Mail3.IsError}
                helperText={errorAttribute.Mail3.ErrorMessage}
                inputProps={{ type: 'email', maxLength: '255' }}
              />
            </Grid>
          </Grid>
        </Box>

        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" sx={styles.button} onClick={handleClickRegist}>
              登録
            </Button>
          </Box>
        </Stack>
      </Container>
    </GenericTemplate>
  );
};
export default AccountSetting;
