import React, { useEffect, useState, useCallback } from 'react';
import { registMinkaResident, updateMinkaResident } from 'graphql/mutations';
import { useLocation } from 'react-router';
import { CodeMaster, UpdateMinkaResidentMutation, RegistMinkaResidentMutation, MinkaResidentRequest } from 'API';
import { CreateEmptyErrorData, ErrorData } from './HousemateDetailErrorData';
import { useMaster } from 'contexts/CommonMasterContext';
import { useAPI } from 'contexts/APIRequestContext';
import { useNavigate } from 'react-router-dom';
import { checkAuthority } from 'util/AuthorityCheck';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';
import { getAge } from 'util/DateUtil';

const UseHousemateDetail = () => {
  const api = useAPI();
  const queryString = require('query-string');
  const location = useLocation();
  const minkaResidentPara = location.state as MinkaResidentRequest;
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [birthday, setBirthday] = useState(null as Date | null);
  const [residentData, setResidentData] = useState({} as MinkaResidentRequest);
  const [residentRelationList, setResidentRelationList] = useState([] as CodeMaster[]);
  const master = useMaster();
  const [, setError] = useState<undefined>();
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);
  const navigator = useNavigate();
  const { user } = useAuthenticator();
  const userData = new UserData(user);
  const [housemateAge, setHousemateAge] = useState('');

  var isUpdate = queryString.parse(location.search).mode === 'update';

  /**
   * ブラウザバック
   */
  const back = (): void => {
    window.history.back();
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    setResidentData({ ...residentData, [name]: value });
  };

  const handleChangeBirthday = (value: Date | null) => {
    if (value && value.toLocaleDateString()) {
      setHousemateAge(getAge(value));
    } else {
      setHousemateAge('---');
    }
    setResidentData({ ...residentData, BirthDay: value?.toLocaleDateString() });
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const checked = event.target.checked;
  };

  const handleClickRegist = () => {
    if (isUpdate) {
      updateResident();
    } else {
      registResident();
    }
  };

  async function registResident() {
    const response = (
      (await api.graphql({
        query: registMinkaResident,
        variables: { data: residentData },
      })) as RegistMinkaResidentMutation
    ).registMinkaResident;

    if (response?.IsSuccess) {
      back();
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  async function updateResident() {
    const response = (
      (await api.graphql({
        query: updateMinkaResident,
        variables: { data: residentData },
      })) as UpdateMinkaResidentMutation
    ).updateMinkaResident;

    if (response?.IsSuccess) {
      back();
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('HousemateDetail', userData.authorityCode)) {
      navigator('/');
    }

    master.waitForInitialized(() => {
      setResidentRelationList(
        master.getCodeMaster().filter((x) => x.CodeKind === 'residents_relation_code' && x.Code != '0')
      );
    });

    if (isUpdate) {
      setResidentData(minkaResidentPara as MinkaResidentRequest);

      if (minkaResidentPara.BirthDay) {
        setHousemateAge(getAge(new Date(minkaResidentPara.BirthDay)));
      } else {
        setHousemateAge('---');
      }
    } else {
      // 新規登録の場合、初期値のある値を予めセットする
      setResidentData({ MinkaId: minkaResidentPara.MinkaId, Sex: '1' } as unknown as MinkaResidentRequest);
    }
  }, []);

  return {
    birthday,
    residentData,
    residentRelationList,
    housemateAge,
    errorAttribute,
    handleChangeInput,
    handleChangeBirthday,
    handleRadioChange,
    handleClickRegist,
  };
};

export default UseHousemateDetail;
