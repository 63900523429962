import { FormControl, FormHelperText, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Branch, Company } from 'API';
import { FC } from 'react';
import { SelectValue } from 'types/SelectValue';
import { ServiceProvider } from 'types/ServiceProvider';
import SelectFormControl from 'views/parts/SelectFormControl';
import { ErrorData } from './AcceptCompanyInfoDialogErrorData';

export interface Props {
  inputData: ServiceProvider;
  mode: string;
  companyList: Company[];
  branchList: Branch[];
  canUpdateServiceProvider: boolean;
  isReserveAdmin: boolean;
  handleChangeCompany: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeBranchInput: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeInput: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleRegist: () => void;
  handleClose: () => void;
  dialogOpen: boolean;
  errorAttribute: ErrorData;
}

const styles = {
  categoryHeader: {
    fontWeight: 'bold',
  },
  itemHeader: {
    backgroundColor: '#ececec',
    fontWeight: 'bold',
  },
  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
  },
  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 1,
    ml: 1,
  },
  label: {
    mt: '7px',
  },
  categoryTitle: {
    borderLeft: 5,
    borderColor: '#0058a0',
    pl: '10px',
  },
  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },
  listButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    m: 0,
  },
};

const AcceptCompanyInfoDialog: FC<Props> = ({
  inputData,
  mode,
  companyList,
  branchList,
  canUpdateServiceProvider,
  isReserveAdmin,
  handleChangeCompany,
  handleChangeBranchInput,
  handleChangeInput,
  handleRegist,
  handleClose,
  dialogOpen,
  errorAttribute,
}) => (
  <Dialog open={dialogOpen} onClose={() => handleClose()} maxWidth={'md'}>
    <DialogTitle>
      民泊受入事業者情報
      {errorAttribute.Header.ErrorMessage && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl component="fieldset" error={errorAttribute.Header.IsError}>
              <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                {errorAttribute.Header.ErrorMessage}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      )}
    </DialogTitle>
    <DialogContent>
      <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
        民泊受入事業者情報
      </Typography>
      <Grid container spacing={2} sx={styles.categoryBlock}>
        <Grid item xs={3}>
          <Typography sx={styles.label}>会社名{mode === 'REGIST' && ' *'}</Typography>
        </Grid>
        <Grid item xs={9}>
          {mode === 'REGIST' || canUpdateServiceProvider || isReserveAdmin ? (
            <SelectFormControl
              value={inputData.CompanyId ?? ''}
              selectName="CompanyId"
              selectValues={companyList.map((company, idx) => {
                return { value: company.CompanyId.toString(), displayName: company.CompanyName } as SelectValue;
              })}
              setCondition={handleChangeCompany}
              sx={styles.inputItem}
              error={errorAttribute.CompanyId.IsError}
              errorMessage={errorAttribute.CompanyId.ErrorMessage}
            />
          ) : (
            <Typography sx={styles.label}> {inputData.CompanyName} </Typography>
          )}
        </Grid>
        <Grid item xs={3}>
          <Typography sx={styles.label}>営業所名{mode === 'REGIST' && ' *'}</Typography>
        </Grid>
        <Grid item xs={9}>
          {mode === 'REGIST' || canUpdateServiceProvider || isReserveAdmin ? (
            <SelectFormControl
              value={inputData.BranchId ?? ''}
              selectName="BookingBranchId"
              selectValues={branchList
                .filter((x) => x.CompanyId === inputData.CompanyId)
                .map((branch) => {
                  return { value: branch.BranchId.toString(), displayName: branch.BranchName } as SelectValue;
                })}
              setCondition={handleChangeBranchInput}
              sx={styles.inputItem}
              error={errorAttribute.BranchId.IsError}
              errorMessage={errorAttribute.BranchId.ErrorMessage}
            />
          ) : (
            <Typography sx={styles.label}> {inputData.BranchName} </Typography>
          )}
        </Grid>
        <Grid item xs={3}>
          <Typography sx={styles.label}>担当者</Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField
            required
            hiddenLabel
            name="ManagingUserName"
            value={inputData.ManagingUserName ?? ''}
            onChange={handleChangeInput}
            size="small"
            sx={styles.inputItem}
            error={errorAttribute.ManagingUserName.IsError}
            helperText={errorAttribute.ManagingUserName.ErrorMessage}
            inputProps={{ maxLength: 40 }}
          />
        </Grid>
        <Grid item xs={3}>
          <Typography sx={styles.label}>入村会場</Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField
            required
            hiddenLabel
            name="CheckInPlace"
            value={inputData.CheckInPlace ?? ''}
            onChange={handleChangeInput}
            size="small"
            sx={styles.inputItem}
            error={errorAttribute.CheckInPlace.IsError}
            helperText={errorAttribute.CheckInPlace.ErrorMessage}
            inputProps={{ maxLength: 20 }}
          />
        </Grid>
        <Grid item xs={3}>
          <Typography sx={styles.label}>退村会場</Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField
            required
            hiddenLabel
            name="CheckOutPlace"
            value={inputData.CheckOutPlace ?? ''}
            onChange={handleChangeInput}
            size="small"
            sx={styles.inputItem}
            error={errorAttribute.CheckOutPlace.IsError}
            helperText={errorAttribute.CheckOutPlace.ErrorMessage}
            inputProps={{ maxLength: 20 }}
          />
        </Grid>
        <Grid item xs={3}>
          <Typography sx={styles.label}>メモ</Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField
            required
            hiddenLabel
            name="Memo"
            value={inputData.Memo}
            onChange={handleChangeInput}
            multiline
            rows="4"
            sx={styles.inputItem}
            error={errorAttribute.Memo.IsError}
            helperText={errorAttribute.Memo.ErrorMessage}
            inputProps={{ maxLength: 255 }}
          />
        </Grid>
      </Grid>

      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" sx={styles.button} onClick={handleRegist}>
            登録
          </Button>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" sx={styles.button} onClick={handleClose}>
            閉じる
          </Button>
        </Box>
      </Stack>
    </DialogContent>
  </Dialog>
);
export default AcceptCompanyInfoDialog;
