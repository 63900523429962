import {
  ApplyReservationMutation,
  FindServiceQuery,
  InitApplyReservationQuery,
  RegistApplyReservation,
  ReservationAgent,
  Service,
} from 'API';
import { useAPI } from 'contexts/APIRequestContext';
import { applyReservation } from 'graphql/mutations';
import { findService, initApplyReservation } from 'graphql/queries';
import React, { useCallback, useEffect, useState } from 'react';
import { CreateEmptyErrorData, ErrorData } from './ApplicationDialogErrorData';

const UseApplicationDialog = ({
  handleDialogClose,
  dialogOpen,
  reservationId,
  updateDatetime,
}: {
  handleDialogClose: (_?: string) => void;
  dialogOpen: boolean;
  reservationId: string;
  updateDatetime: string;
}) => {
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [inputData, setInputData] = useState({} as RegistApplyReservation);
  const [reservedDays, setReservedDays] = useState([] as string[]);
  const [services, setServices] = useState([] as Service[]);
  const [reservationAgents, setReservationAgents] = useState([] as ReservationAgent[]);
  const [anotherReservationAgents, setAnotherReservationAgents] = useState([] as ReservationAgent[]);
  const [guestCount, setGuestCount] = useState(Number.MAX_SAFE_INTEGER);
  const api = useAPI();
  const [, setError] = useState<undefined>();

  useEffect(() => {
    if (dialogOpen) {
      // 初期化
      setErrorAttribute({ ...CreateEmptyErrorData() });

      executeInitApplyReservation();
    } else {
      setInputData({ ReservedDays: [] as string[] } as RegistApplyReservation);
    }
  }, [dialogOpen]);

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  /**
   * 本予約申請初期処理
   */
  const executeInitApplyReservation = async (): Promise<void> => {
    const response = (
      (await api.graphql({
        query: initApplyReservation,
        variables: {
          data: {
            ReservationId: reservationId,
            UpdateDatetime: updateDatetime,
          },
        },
      })) as InitApplyReservationQuery
    ).initApplyReservation;

    if (response?.IsSuccess) {
      setReservedDays(response.Body?.ReservedDays!);
      setReservationAgents(response.Body?.ReservationAgents!);
      setAnotherReservationAgents(response.Body?.AnotherReservationAgents!);
      setGuestCount(response.Body?.GuestCount!);

      inputData.GuestCount = response.Body?.GuestCount;
      if (response.Body?.AnotherReservationAgents && response.Body?.AnotherReservationAgents.length) {
        inputData.CompanyId = response.Body?.ReservationAgents![0].CompanyId!;
      }

      setInputData({ ...inputData });
    } else if (response?.IsSystemError) {
      throwError(response.ErrorData ?? '');
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...errorAttribute,
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  };

  /**
   * サービス取得処理
   */
  const executeFindService = async (): Promise<void> => {
    const response = (
      (await api.graphql({
        query: findService,
        variables: {
          data: {
            ReservationId: reservationId,
            ReservedDays: inputData.ReservedDays,
          },
        },
      })) as FindServiceQuery
    ).findService;

    if (response?.IsSuccess) {
      setServices(response?.Body!);
    } else if (response?.IsSystemError) {
      throwError(response.ErrorData ?? '');
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...errorAttribute,
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  };

  /**
   * 宿泊日変更時
   * @param value チェックON/OFF
   * @param idx インデックス
   */
  const handleChangeReservedDay = (value: boolean, idx: number): void => {
    if (value) {
      inputData.ReservedDays!.push(reservedDays[idx]);
    } else {
      inputData.ReservedDays!.splice(inputData.ReservedDays!.indexOf(reservedDays[idx]), 1);
    }

    if (!inputData.ReservedDays?.length) {
      // 宿泊日が未選択のため宿泊プランを削除
      inputData.ServiceId = '';
      setServices([]);
    } else {
      inputData.ServiceId = '';
      setServices([]);
      // 宿泊日が変更されたため宿泊プランを取得
      executeFindService();
    }

    setInputData(inputData);
    setErrorAttribute({
      ...errorAttribute,
      ReservedDays: { IsError: false, ErrorMessage: '' },
      ServiceId: { IsError: false, ErrorMessage: '' },
    });
  };

  /**
   * 宿泊プラン変更時
   * @param event イベント
   */
  const handleChangeService = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setInputData({ ...inputData, ServiceId: event.target.value });
    setErrorAttribute({
      ...errorAttribute,
      ServiceId: { IsError: false, ErrorMessage: '' },
    });
  };

  /**
   * 宿泊人数変更時
   * @param event イベント
   */
  const handleChangeGuestCount = (event: React.FocusEvent<HTMLInputElement>): void => {
    setInputData({ ...inputData, GuestCount: Number(event.target.value) });
    setErrorAttribute({
      ...errorAttribute,
      GuestCount: { IsError: false, ErrorMessage: '' },
    });
  };

  /**
   * 代理店変更時
   * @param event イベント
   * @param value 代理店ID
   */
  const handleChangeReservationAgent = (event: React.ChangeEvent<HTMLInputElement>, value: string): void => {
    setInputData({ ...inputData, CompanyId: value });
    setErrorAttribute({
      ...errorAttribute,
      CompanyId: { IsError: false, ErrorMessage: '' },
    });
  };

  /**
   * 行程表変更時
   * @param event イベント
   */
  const handleChangeItinerary = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files && event.target.files.length) {
      if (event.target.files[0].size > 500 * 1024) {
        setErrorAttribute({
          ...errorAttribute,
          Itinerary: { IsError: true, ErrorMessage: 'ファイルサイズは、「500KB」以下にしてください。' },
        });
        return;
      } else {
        setErrorAttribute({
          ...errorAttribute,
          Itinerary: { IsError: false, ErrorMessage: '' },
        });
      }

      inputData.ItineraryName = event.target.files[0].name;

      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        setInputData({
          ...inputData,
          ItineraryName: inputData.ItineraryName,
          Itinerary: (reader.result as string).split(';base64,').pop(),
        });
      };
    }
  };

  /**
   * 行程表削除時
   */
  const handleDeleteItinerary = (): void => {
    setInputData({ ...inputData, Itinerary: '', ItineraryName: '' });
    setErrorAttribute({
      ...errorAttribute,
      Itinerary: { IsError: false, ErrorMessage: '' },
    });
  };

  /**
   * 決定通知書変更時
   * @param event イベント
   */
  const handleChangeAgentConfirmationDoc = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files && event.target.files.length) {
      if (event.target.files[0].size > 500 * 1024) {
        setErrorAttribute({
          ...errorAttribute,
          AgentConfirmationDoc: { IsError: true, ErrorMessage: 'ファイルサイズは500KB以下にしてください。' },
        });
        return;
      } else {
        setErrorAttribute({
          ...errorAttribute,
          AgentConfirmationDoc: { IsError: false, ErrorMessage: '' },
        });
      }

      inputData.AgentConfirmationDocName = event.target.files[0].name;

      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        setInputData({
          ...inputData,
          AgentConfirmationDocName: inputData.AgentConfirmationDocName,
          AgentConfirmationDoc: (reader.result as string).split(';base64,').pop(),
        });
      };
    }
  };

  /**
   * 決定通知書削除時
   */
  const handleDeleteAgentConfirmationDoc = (): void => {
    setInputData({ ...inputData, AgentConfirmationDocName: '', AgentConfirmationDoc: '' });
    setErrorAttribute({
      ...errorAttribute,
      AgentConfirmationDoc: { IsError: false, ErrorMessage: '' },
    });
  };

  /**
   * 閉じる
   */
  const handleClose = (): void => {
    handleDialogClose();
  };

  /**
   * 本予約申請を行う
   */
  const executeApplyReservation = async (): Promise<void> => {
    const response = (
      (await api.graphql({
        query: applyReservation,
        variables: {
          data: {
            ReservationId: reservationId,
            ReservedDays: inputData.ReservedDays,
            ServiceId: inputData.ServiceId,
            CompanyId: inputData.CompanyId,
            GuestCount: inputData.GuestCount,
            ItineraryName: inputData.ItineraryName,
            Itinerary: inputData.Itinerary,
            AgentConfirmationDocName: inputData.AgentConfirmationDocName,
            AgentConfirmationDoc: inputData.AgentConfirmationDoc,
            UpdateDatetime: updateDatetime,
          },
        },
      })) as ApplyReservationMutation
    ).applyReservation;

    if (response?.IsSuccess) {
      handleDialogClose(response.Body!);
    } else if (response?.IsSystemError) {
      throwError(response.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      } as ErrorData);
    } else {
      throwError();
    }
  };

  /**
   * 次へ
   */
  const handleRegist = async (): Promise<void> => {
    executeApplyReservation();
  };

  return {
    errorAttribute,
    dialogOpen,
    inputData,
    reservedDays,
    services,
    reservationAgents,
    anotherReservationAgents,
    guestCount,
    handleChangeReservedDay,
    handleChangeService,
    handleChangeGuestCount,
    handleChangeReservationAgent,
    handleChangeItinerary,
    handleDeleteItinerary,
    handleChangeAgentConfirmationDoc,
    handleDeleteAgentConfirmationDoc,
    handleClose,
    handleRegist,
  };
};
export default UseApplicationDialog;
