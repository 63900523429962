import { Container } from '@mui/material';
import Button from '@mui/material/Button/Button';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import Grid from '@mui/material/Grid/Grid';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TextField from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography/Typography';
import { FC } from 'react';
import GenericTemplate from '../../../template/GenericTemplate';
import { AcceptMinkaData, AllocateMinkaData, SendMailAcceptMinkaRequest } from 'API';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { ErrorData } from './AcceptMinkaErrorData';
import BackToTopButton from 'views/parts/BackToTop';

const styles = {
  searchField: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
  },

  label: {
    margin: 'auto',
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
  },

  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
  },

  textArea: {
    width: '100%',
    '& input': {
      padding: '8px 14px',
    },
    background: '#f2f2f2',
  },

  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
  },

  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },

  textField: {
    width: '100%',
    '& input': {
      padding: '8px 14px',
    },
  },

  button: {
    fontWeight: 'bold',
    float: 'right',
    mt: 1,
    mr: 1,
    minWidth: '120px',
  },
};

export interface Props {
  handleChangeInput: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeCheckbox: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleClose: () => void;
  handleSendMail: () => void;
  handleMessageDialogClose: () => void;
  minkaData: AcceptMinkaData;
  allocateMinkaList: AllocateMinkaData[];
  inputData: SendMailAcceptMinkaRequest;
  errorAttribute: ErrorData;
  message: string;
  reserveInfo: string;
}

const AcceptMinkaAllDeliver: FC<Props> = (props) => {
  return (
    <GenericTemplate title="受入民家メール一斉配信">
      <div>
        <BackToTopButton />
      </div>
      <Container sx={styles.searchField}>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          {props.errorAttribute.Header.IsError && (
            <Grid item xs={12}>
              <FormControl component="fieldset" error={props.errorAttribute.Header.IsError}>
                <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                  {props.errorAttribute.Header.ErrorMessage}
                </FormHelperText>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={2}>
            <Typography sx={styles.label}>予約情報</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField sx={styles.textArea} multiline rows={6} variant="outlined" value={props.reserveInfo} disabled />
          </Grid>
          <Grid item xs={2}>
            <Typography sx={styles.label}>送信元</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField sx={styles.textArea} value={props.minkaData.Mail} inputProps={{ maxLength: 255 }} disabled />
          </Grid>
          <Grid item xs={2}>
            <Typography sx={styles.label}>送信先</Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControl component="fieldset" error={props.errorAttribute.MinkaIdList.IsError} sx={{ width: '100%' }}>
              <TableContainer sx={{ maxHeight: 350 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>組</TableCell>
                      <TableCell>班</TableCell>
                      <TableCell>民家No</TableCell>
                      <TableCell>民家名</TableCell>
                      <TableCell>屋号</TableCell>
                      <TableCell>選択</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.allocateMinkaList.map((allocateMinka, index) => (
                      <TableRow key={index}>
                        <TableCell component="td" scope="row">
                          {allocateMinka.ClassCode}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {allocateMinka.GroupCode}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {allocateMinka.MinkaId}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {allocateMinka.MinkaName}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {allocateMinka.BussinessName}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          <Checkbox
                            name="MinkaIdList"
                            value={allocateMinka.MinkaId}
                            onChange={props.handleChangeCheckbox}
                            size="small"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <FormHelperText>{props.errorAttribute.MinkaIdList.ErrorMessage}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={styles.label}>題名</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              sx={styles.textField}
              name={'MessageSubject'}
              value={props.inputData.MessageSubject}
              onChange={props.handleChangeInput}
              error={props.errorAttribute.MessageSubject.IsError}
              helperText={props.errorAttribute.MessageSubject.ErrorMessage}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography sx={styles.label}>本文</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              multiline
              rows={8}
              variant="outlined"
              sx={{ width: '100%' }}
              name={'MessageBody'}
              value={props.inputData.MessageBody}
              onChange={props.handleChangeInput}
              error={props.errorAttribute.MessageBody.IsError}
              helperText={props.errorAttribute.MessageBody.ErrorMessage}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={11}>
            <Button variant="contained" sx={styles.button} onClick={props.handleClose}>
              閉じる
            </Button>
            <Button variant="contained" sx={styles.button} onClick={props.handleSendMail}>
              送信
            </Button>
          </Grid>
        </Grid>
        <Dialog open={props.message !== ''} onClose={props.handleMessageDialogClose}>
          <DialogContent>
            <DialogContentText>{props.message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleMessageDialogClose} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </GenericTemplate>
  );
};

export default AcceptMinkaAllDeliver;
