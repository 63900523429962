import { FC } from 'react';
import ApprovalDialog from './ApprovalDialog';
import UseApprovalDialog from './UseApprovalDialog';

export interface Props {
  handleDialogClose: (_?: string) => void;
  dialogOpen: boolean;
  reservationId: string;
  updateDatetime: string;
}

const ApprovalDialogContainer: FC<Props> = (props: Props) => {
  return <ApprovalDialog {...UseApprovalDialog(props)} />;
};

export default ApprovalDialogContainer;
