import FormErrorAttribute from 'types/FormErrorAttribute';

export interface ErrorData {
  Header: FormErrorAttribute;
  ServiceId: FormErrorAttribute;
  FromDate: FormErrorAttribute;
  ToDate: FormErrorAttribute;
  UpdateDatetime: FormErrorAttribute;
}

export const CreateEmptyErrorData = () => {
  return {
    Header: {} as FormErrorAttribute,
    ServiceId: {} as FormErrorAttribute,
    FromDate: {} as FormErrorAttribute,
    ToDate: {} as FormErrorAttribute,
    UpdateDatetime: {} as FormErrorAttribute,
  } as ErrorData;
};

