import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

export interface Props {
  handleClose: () => void;
  dialogOpen: boolean;
}

const styles = {
  categoryHeader: {
    fontWeight: 'bold',
  },
  itemHeader: {
    backgroundColor: '#ececec',
    fontWeight: 'bold',
  },
  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
  },
  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 1,
    ml: 1,
  },
  label: {
    mt: '7px',
  },
  categoryTitle: {
    borderLeft: 5,
    borderColor: '#0058a0',
    pl: '10px',
  },
  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },
  listButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    m: 0,
  },
};

const SessionInvalidMessageDialog: FC<Props> = ({ handleClose, dialogOpen }) => {
  return (
    <Dialog open={dialogOpen} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
      <DialogTitle>【 再ログインが必要です 】</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>メッセージ </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography sx={styles.label}>
              同じアカウント（ユーザ
              ID）で他の端末またはブラウザにログインされたことを検知したため、更新モードで起動します。
              予約の登録・更新をする場合は、一度ログアウトして、ログインし直す必要があります。
              <br />
              なお、ご利用のアカウントでは複数人による使いまわしが禁止されています。
              <br />
              禁止行為が繰り返される場合、アカウントが停止されることがあります。
            </Typography>
          </Grid>
        </Grid>

        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" sx={styles.button} onClick={handleClose}>
              閉じる
            </Button>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
export default SessionInvalidMessageDialog;
