import { FC } from 'react';
import GenericTemplate from '../../../template/GenericTemplate';
import React from 'react';
import { Container, FormControl, FormHelperText } from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import Grid from '@mui/material/Grid/Grid';
import TextField from '@mui/material/TextField/TextField';
import Button from '@mui/material/Button/Button';
import { AreaReservationFrame } from '../../../../API';
import { ErrorData } from './AcceptPeriodAdjustmentErrorData';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import jaLocale from 'date-fns/locale/ja';
import DesktopDatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import BackToTopButton from 'views/parts/BackToTop';

const styles = {
  searchField: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
  },

  label: {
    margin: 'auto',
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
  },

  value: {
    margin: 'auto',
    textAlign: 'left',
    pr: '10px',
    mt: '7px',
  },

  text: {
    margin: 'auto',
    pr: '10px',
    mt: '7px',
    ml: '5px',
  },

  categoryHeader: {
    fontWeight: 'bold',
  },

  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
  },

  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },

  textField: {
    width: '100%',
    '& input': {
      padding: '8px 14px',
    },
  },

  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    ml: 1,
    mt: 1,
    float: 'right',
  },
};

export interface Props {
  errorAttribute: ErrorData;
  resultData: AreaReservationFrame;
  firstDt: Date | null;
  lastDt: Date | null;
  areaName: string;
  handleChangeDetail: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickRegist: () => void;
  handleChangeCheckbox: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeFirstDt: (_: Date | null) => void;
  handleChangeLastDt: (_: Date | null) => void;
}

const AcceptPeriodAdjustment: FC<Props> = (props) => {
  const pushback = function () {
    window.history.back();
  };

  return (
    <GenericTemplate title="受入調整">
      <div>
        <BackToTopButton />
      </div>
      <Container sx={styles.searchField}>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          受入調整
        </Typography>
        {props.errorAttribute.Header.ErrorMessage && (
          <Grid container spacing={2} sx={{ pl: 0, pb: 2 }}>
            <Grid item xs={12}>
              <FormControl component="fieldset" error={props.errorAttribute.Header.IsError}>
                <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                  {props.errorAttribute.Header.ErrorMessage}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>予約枠（地区名）</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography sx={styles.value}>{props.areaName}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>期間指定</Typography>
          </Grid>
          <Grid item xs={7}>
            <Grid container>
              <Grid item xs={5}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
                  <DesktopDatePicker
                    label="yyyy/mm/dd"
                    mask="____/__/__"
                    value={props.firstDt}
                    onChange={(newValue) => props.handleChangeFirstDt(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        style={{ width: '100%' }}
                        error={props.errorAttribute.StartDate.IsError}
                        helperText={props.errorAttribute.StartDate.ErrorMessage}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={2} style={{ margin: 'auto', textAlign: 'center' }}>
                ~
              </Grid>
              <Grid item xs={5}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
                  <DesktopDatePicker
                    label="yyyy/mm/dd"
                    mask="____/__/__"
                    value={props.lastDt}
                    onChange={(newValue) => props.handleChangeLastDt(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        style={{ width: '100%' }}
                        error={props.errorAttribute.EndDate.IsError}
                        helperText={props.errorAttribute.EndDate.ErrorMessage}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>
          {/*
          <Grid item xs={3} sx={{ mt: '20px' }}>
            <Typography sx={styles.label}>受付停止</Typography>
          </Grid>
          <Grid item xs={7} sx={{ display: 'flex', mt: '20px' }}>
            <Checkbox
              name="IsStopped"
              value={6}
              onChange={props.handleChangeCheckbox}
              checked={!!props.resultData.IsStopped ?? false}
            />
            <Typography sx={styles.text}>※新規の予約受付けを停止します。</Typography>
          </Grid>
          */}
          <Grid item xs={3}>
            <Typography sx={styles.label}>最大受入人数</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'MaxGuestCount'}
              value={props.resultData.MaxGuestCount}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="number"
              error={props.errorAttribute.MaxGuestCount.IsError}
              helperText={props.errorAttribute.MaxGuestCount.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>同時受入校数</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'MaxSchoolCount'}
              value={props.resultData.MaxSchoolCount}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="number"
              error={props.errorAttribute.MaxSchoolCount.IsError}
              helperText={props.errorAttribute.MaxSchoolCount.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>同時受入人数</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'MaxMultiSchoolGuestCount'}
              value={props.resultData.MaxMultiSchoolGuestCount}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="number"
              error={props.errorAttribute.MaxMultiSchoolGuestCount.IsError}
              helperText={props.errorAttribute.MaxMultiSchoolGuestCount.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>入村時間</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'CheckInTime'}
              value={props.resultData.CheckInTime}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="time"
              error={props.errorAttribute.CheckInTime.IsError}
              helperText={props.errorAttribute.CheckInTime.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>退村時間</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'CheckOutTime'}
              value={props.resultData.CheckOutTime}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="time"
              error={props.errorAttribute.CheckOutTime.IsError}
              helperText={props.errorAttribute.CheckOutTime.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>連泊入村時間</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'MultiStayCheckInTime'}
              value={props.resultData.MultiStayCheckInTime}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="time"
              error={props.errorAttribute.MultiStayCheckInTime.IsError}
              helperText={props.errorAttribute.MultiStayCheckInTime.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>連泊退村時間</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'MultiStayCheckOutTime'}
              value={props.resultData.MultiStayCheckOutTime}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="time"
              error={props.errorAttribute.MultiStayCheckOutTime.IsError}
              helperText={props.errorAttribute.MultiStayCheckOutTime.ErrorMessage}
            />
          </Grid>
          <Grid item xs={10}>
            <Button variant="contained" sx={styles.button} onClick={pushback}>
              閉じる
            </Button>
            <Button variant="contained" sx={styles.button} onClick={props.handleClickRegist}>
              登録
            </Button>
          </Grid>
        </Grid>
      </Container>
    </GenericTemplate>
  );
};

export default AcceptPeriodAdjustment;
