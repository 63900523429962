import { useCallback, useEffect, useState } from 'react';
import {
  BulkAreaReservationFrameRequest,
  AreaReservationFrame,
  FindAreaReservationFrameQuery,
  BulkUpdateAreaReservationFrameMutation,
} from '../../../../API';
import { useLocation } from 'react-router';
import { graphqlOperation } from 'aws-amplify';
import { useMaster } from 'contexts/CommonMasterContext';
import { useAPI } from 'contexts/APIRequestContext';
import { bulkUpdateAreaReservationFrame } from 'graphql/mutations';
import { CreateEmptyErrorData, ErrorData } from './AcceptPeriodAdjustmentErrorData';
import { findAreaReservationFrame } from 'graphql/queries';
import { useNavigate } from 'react-router-dom';
import { checkAuthority } from 'util/AuthorityCheck';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';

const queryString = require('query-string');
const UseAcceptPeriodAdjustment = () => {
  const location = useLocation();
  const [firstDt, setFirstDt] = useState(null as Date | null);
  const [lastDt, setLastDt] = useState(null as Date | null);
  const [resultData, setResultData] = useState({} as AreaReservationFrame);
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const api = useAPI();
  const [, setError] = useState<undefined>();
  const master = useMaster();
  var areaId = queryString.parse(location.search).areaId;
  var start = queryString.parse(location.search).firstDt;
  const [areaName, setAreaName] = useState('');
  const navigator = useNavigate();
  const { user } = useAuthenticator();
  const userData = new UserData(user);

  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  /**
   * ブラウザバック
   */
  const back = (): void => {
    window.history.back();
  };

  const handleChangeDetail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.type === 'number' && event.target.value === '' ? undefined : event.target.value; // MEMO: スキーマ定義が Int の場合、画面入力値を空に変更した場合、"" 空文字扱いとなり、エラーとなる為の対応
    const name = event.target.name;
    setResultData({ ...resultData, [name]: value });
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setResultData({
      ...resultData,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangeFirstDt = (value: Date | null) => {
    setFirstDt(value);
  };

  const handleChangeLastDt = (value: Date | null) => {
    setLastDt(value);
  };

  async function fetchData() {
    const response = (await api.graphql(
      graphqlOperation(findAreaReservationFrame, {
        data: {
          ['AreaId']: areaId,
          ['Date']: start,
        },
      })
    )) as FindAreaReservationFrameQuery;

    if (response?.findAreaReservationFrame?.IsSuccess) {
      if (response?.findAreaReservationFrame?.Body) {
        let result = response.findAreaReservationFrame.Body as AreaReservationFrame[];
        setResultData(result[0]);
      }
    } else if (response?.findAreaReservationFrame?.IsSystemError) {
      throwError(response?.findAreaReservationFrame?.ErrorData ?? '');
    } else if (response?.findAreaReservationFrame?.IsInputCheckError && response?.findAreaReservationFrame?.ErrorData) {
      throwError(response?.findAreaReservationFrame?.ErrorData ?? '');
    } else if (response?.findAreaReservationFrame?.ErrorData) {
      throwError(response?.findAreaReservationFrame?.ErrorData ?? '');
    } else {
      throwError();
    }
  }

  const handleClickRegist = async () => {
    const areaReservationFrame = {
      AreaId: String(resultData.AreaId),
      StartDate: firstDt?.toLocaleDateString(),
      EndDate: lastDt?.toLocaleDateString(),
      AreaReservationFrame: {
        AreaId: areaId,
        IsStopped: resultData.IsStopped ? 1 : 0,
        MaxGuestCount: resultData.MaxGuestCount,
        MaxSchoolCount: resultData.MaxSchoolCount,
        MaxMultiSchoolGuestCount: resultData.MaxMultiSchoolGuestCount,
        CheckInTime: resultData.CheckInTime,
        CheckOutTime: resultData.CheckOutTime,
        MultiStayCheckInTime: resultData.MultiStayCheckInTime,
        MultiStayCheckOutTime: resultData.MultiStayCheckOutTime,
      } as AreaReservationFrame,
    } as BulkAreaReservationFrameRequest;

    const response = (
      (await api.graphql({
        query: bulkUpdateAreaReservationFrame,
        variables: { data: areaReservationFrame },
      })) as BulkUpdateAreaReservationFrameMutation
    ).bulkUpdateAreaReservationFrame;

    if (response?.IsSuccess) {
      back();
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  };

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('AcceptPeriodAdjustment', userData.authorityCode)) {
      navigator('/');
    }

    master.waitForInitialized(() => {
      setAreaName(master.getAreaName(areaId));
    });

    fetchData();
    setFirstDt(new Date(queryString.parse(location.search).firstDt));
    setLastDt(new Date(queryString.parse(location.search).lastDt));
  }, []);

  return {
    resultData,
    firstDt,
    lastDt,
    areaName,
    errorAttribute,
    handleChangeDetail,
    handleClickRegist,
    handleChangeCheckbox,
    handleChangeFirstDt,
    handleChangeLastDt,
  };
};
export default UseAcceptPeriodAdjustment;
