import { SxProps, Theme } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SelectValue } from 'types/SelectValue';

const SelectFormControl = ({
  value,
  labelName,
  selectValues,
  setCondition,
  selectName,
  isDisabled = false,
  sx,
  error = false,
  errorMessage,
}: {
  value: any;
  labelName?: string;
  selectValues: Array<SelectValue>;
  setCondition: any;
  selectName: string;
  isDisabled?: boolean;
  sx?: SxProps<Theme>;
  error?: boolean;
  errorMessage?: string;
}) => {
  return (
    <FormControl size="small" component="fieldset" sx={sx} error={error}>
      {labelName && <FormHelperText>{labelName}</FormHelperText>}
      <Select
        name={selectName}
        value={value}
        onChange={(e: any) => {
          setCondition(e);
        }}
        disabled={isDisabled}
        sx={{ pt: 0, pb: 0 }}
      >
        <MenuItem value={''}>　</MenuItem>
        {selectValues.map((selectValue) => (
          <MenuItem key={selectValue.value} value={selectValue.value}>
            {selectValue.displayName}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  );
};

export default SelectFormControl;
