import FormErrorAttribute from 'types/FormErrorAttribute';

export interface ErrorData {
  Header: FormErrorAttribute;
  CompanyId: FormErrorAttribute;
  CompanyName: FormErrorAttribute;
  BookingBranchId: FormErrorAttribute;
  BookingUserName: FormErrorAttribute;
  BookingUserTel: FormErrorAttribute;
  BookingUserMail: FormErrorAttribute;
  SalesBranchId: FormErrorAttribute;
  SalesUserName: FormErrorAttribute;
  SalesUserTel: FormErrorAttribute;
  SalesUserMail: FormErrorAttribute;
  Itinerary: FormErrorAttribute;
  SchoolConfirmationDoc: FormErrorAttribute;
  AgentConfirmationDoc: FormErrorAttribute;
  CenterConfirmationDoc: FormErrorAttribute;
  Note: FormErrorAttribute;
}

export const CreateEmptyErrorData = () => {
  return {
    Header: {} as FormErrorAttribute,
    CompanyId: {} as FormErrorAttribute,
    CompanyName: {} as FormErrorAttribute,
    BookingBranchId: {} as FormErrorAttribute,
    BookingUserName: {} as FormErrorAttribute,
    BookingUserTel: {} as FormErrorAttribute,
    BookingUserMail: {} as FormErrorAttribute,
    SalesBranchId: {} as FormErrorAttribute,
    SalesUserName: {} as FormErrorAttribute,
    SalesUserTel: {} as FormErrorAttribute,
    SalesUserMail: {} as FormErrorAttribute,
    Itinerary: {} as FormErrorAttribute,
    SchoolConfirmationDoc: {} as FormErrorAttribute,
    AgentConfirmationDoc: {} as FormErrorAttribute,
    CenterConfirmationDoc: {} as FormErrorAttribute,
    Note: {} as FormErrorAttribute,
  } as ErrorData;
};
