import { useCallback, useState, useEffect } from 'react';
import React from 'react';
import {
  Area,
  Prefecture,
  AreaManager,
  AreaService,
  Region,
  AreaRequest,
  FindAreaDetailQuery,
  AreaManagerRequest,
  AreaServiceRequest,
  RegionRequest,
  RegistAreaMutation,
  UpdateAreaMutation,
  DeleteAreaManagerMutation,
  DeleteAreaServiceMutation,
  DeleteRegionMutation,
} from '../../../../API';
import { useLocation, useNavigate } from 'react-router';
import { useMaster } from 'contexts/CommonMasterContext';
import { useAPI } from 'contexts/APIRequestContext';
import { findAreaDetail } from 'graphql/queries';
import { registArea, updateArea, deleteAreaManager, deleteAreaService, deleteRegion } from 'graphql/mutations';
import { CreateEmptyErrorData, ErrorData } from './ReserveAreaDetailErrorData';
import { checkAuthority } from 'util/AuthorityCheck';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';
import { scrollToTop } from 'util/Scroll';

const queryString = require('query-string');
const UseReserveAreaDetail = () => {
  const location = useLocation();
  var areaPara = location.state as Area;
  const [isUpdate, setIsUpdate] = useState(Boolean);
  const [, setError] = useState<undefined>();
  const master = useMaster();
  const api = useAPI();
  const [areaData, setAreaData] = useState({} as Area);
  const [areaManagerData, setAreaManagerData] = useState([] as AreaManager[]);
  const [areaServiceData, setAreaServiceData] = useState([] as AreaService[]);
  const [regionData, setRegionData] = useState([] as Region[]);
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const navigator = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertDialogCallback, setAlertDialogCallback] = useState({ fn: (_: boolean) => {} });
  const [prefectureList, setPrefectureList] = useState([] as Prefecture[]);
  const { user } = useAuthenticator();
  const userData = new UserData(user);

  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  /**
   * ブラウザバック
   */
  const back = (): void => {
    window.history.back();
  };

  /**
   * 警告ダイアログ　閉じる
   * @param result 結果
   */
  const handleAlertClose = (result: boolean): void => {
    alertDialogCallback.fn(result);
    setAlertOpen(false);
  };

  const handleChangeDetail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.type === 'number' && event.target.value === '' ? undefined : event.target.value; // MEMO: スキーマ定義が Int の場合、画面入力値を空に変更した場合、"" 空文字扱いとなり、エラーとなる為の対応
    const name = event.target.name;
    setAreaData({ ...areaData, [name]: value });
  };

  const handleChangeFromDate = (value: Date) => {
    setAreaData({ ...areaData, FromDate: value.toLocaleDateString() });
  };

  const handleChangeToDate = (value: Date) => {
    setAreaData({ ...areaData, ToDate: value.toLocaleDateString() });
  };

  ///////////////////////////////////////////////////////////////////////////////
  // 地区管理事業者登録更新削除
  ///////////////////////////////////////////////////////////////////////////////
  const handleClickAreaManagerEditButton = (areaManager: AreaManagerRequest) => {
    navigator('/settings/reserve/areamanager?mode=update', {
      state: {
        AreaId: areaManager.AreaId,
        CompanyId: areaManager.CompanyId,
        BranchId: areaManager.BranchId,
        IsRepresentativ: areaManager.IsRepresentativ,
        FromDate: areaManager.FromDate,
        ToDate: areaManager.ToDate,
        UpdateDatetime: areaManager.UpdateDatetime,
      } as AreaManagerRequest,
    });
  };

  const handleClickAreaManagerCreateButton = () => {
    navigator('/settings/reserve/areamanager?mode=new', {
      state: {
        AreaId: areaPara.AreaId,
      } as AreaManagerRequest,
    });
  };

  const handleClickAreaManagerDeleteButton = async (areaManager: AreaManager): Promise<void> => {
    setAlertDialogCallback({
      fn: async (result: boolean) => {
        if (result) {
          dltAreaManager(areaManager);
        }
      },
    });
    setAlertContent('削除します。よろしいですか？');
    setAlertOpen(true);
  };

  async function dltAreaManager(areaManager: AreaManager) {
    // エラー表示内容をクリア
    setErrorAttribute({
      ...CreateEmptyErrorData(),
      Header: { IsError: true, ErrorMessage: '' },
    });

    let areaManagerReq = {
      AreaId: areaManager.AreaId,
      BranchId: areaManager.BranchId,
      UpdateDatetime: areaManager.UpdateDatetime,
    } as AreaManagerRequest;

    const response = (
      (await api.graphql({
        query: deleteAreaManager,
        variables: { data: areaManagerReq },
      })) as DeleteAreaManagerMutation
    ).deleteAreaManager;

    if (response?.IsSuccess) {
      // リストから削除に成功したレコードを除外
      let newAreaManagerList = areaManagerData.filter(
        (item) => item.AreaId == areaManager.AreaId && item.BranchId != areaManager.BranchId
      );
      setAreaManagerData(newAreaManagerList);
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  ///////////////////////////////////////////////////////////////////////////////
  // 地区サービス登録更新削除
  ///////////////////////////////////////////////////////////////////////////////
  const handleClickAreaServiceEditButton = (areaService: AreaServiceRequest) => {
    navigator('/settings/reserve/areaservice?mode=update', {
      state: {
        AreaId: areaService.AreaId,
        ServiceId: areaService.ServiceId,
        FromDate: areaService.FromDate,
        ToDate: areaService.ToDate,
        UpdateDatetime: areaService.UpdateDatetime,
      } as AreaServiceRequest,
    });
  };

  const handleClickAreaServiceCreateButton = () => {
    navigator('/settings/reserve/areaservice?mode=new', {
      state: {
        AreaId: areaPara.AreaId,
      } as AreaServiceRequest,
    });
  };

  const handleClickAreaServiceDeleteButton = async (areaService: AreaService): Promise<void> => {
    setAlertDialogCallback({
      fn: async (result: boolean) => {
        if (result) {
          dltAreaService(areaService);
        }
      },
    });
    setAlertContent('削除します。よろしいですか？');
    setAlertOpen(true);
  };

  async function dltAreaService(areaService: AreaService) {
    // エラー表示内容をクリア
    setErrorAttribute({
      ...CreateEmptyErrorData(),
      Header: { IsError: true, ErrorMessage: '' },
    });

    let areaServiceReq = {
      AreaId: areaService.AreaId,
      ServiceId: areaService.ServiceId,
      UpdateDatetime: areaService.UpdateDatetime,
    } as AreaServiceRequest;

    const response = (
      (await api.graphql({
        query: deleteAreaService,
        variables: { data: areaServiceReq },
      })) as DeleteAreaServiceMutation
    ).deleteAreaService;

    if (response?.IsSuccess) {
      // リストから削除に成功したレコードを除外
      let newAreaServiceList = areaServiceData.filter(
        (item) => item.AreaId == areaService.AreaId && item.ServiceId != areaService.ServiceId
      );
      setAreaServiceData(newAreaServiceList);
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  ///////////////////////////////////////////////////////////////////////////////
  // 地域登録更新削除
  ///////////////////////////////////////////////////////////////////////////////
  const handleClickRegionEditButton = (region: RegionRequest) => {
    navigator('/settings/reserve/regiondetail?mode=update', {
      state: {
        AreaId: region.AreaId,
        RegionCode: region.RegionCode,
        RegionName: region.RegionName,
        IsActive: region.IsActive,
        UpdateDatetime: region.UpdateDatetime,
      } as RegionRequest,
    });
  };

  const handleClickRegionCreateButton = () => {
    navigator('/settings/reserve/regiondetail?mode=new', {
      state: {
        AreaId: areaPara.AreaId,
      } as RegionRequest,
    });
  };

  const handleClickRegionDeleteButton = async (region: Region): Promise<void> => {
    setAlertDialogCallback({
      fn: async (result: boolean) => {
        if (result) {
          dltRegion(region);
        }
      },
    });
    setAlertContent('削除します。よろしいですか？');
    setAlertOpen(true);
  };

  async function dltRegion(region: Region) {
    // エラー表示内容をクリア
    setErrorAttribute({
      ...CreateEmptyErrorData(),
      Header: { IsError: true, ErrorMessage: '' },
    });

    let regionReq = {
      AreaId: region.AreaId,
      RegionCode: region.RegionCode,
      UpdateDatetime: region.UpdateDatetime,
    } as RegionRequest;

    const response = (
      (await api.graphql({
        query: deleteRegion,
        variables: { data: regionReq },
      })) as DeleteRegionMutation
    ).deleteRegion;

    if (response?.IsSuccess) {
      // リストから削除に成功したレコードを除外
      let newRegionList = regionData.filter(
        (item) => item.AreaId == region.AreaId && item.RegionCode != region.RegionCode
      );
      setRegionData(newRegionList);
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  ///////////////////////////////////////////////////////////////////////////////
  // サービス情報登録更新削除
  ///////////////////////////////////////////////////////////////////////////////
  const handleClickRegist = () => {
    if (isUpdate) {
      updateAreaDetail();
    } else {
      registAreaDetail();
    }
  };

  const moveNextAfterRegist = async (id: Number): Promise<void> => {
    setAlertDialogCallback({
      fn: async (result: boolean) => {
        if (result) {
          // 編集モードで再読み込み
          navigator('/settings/reserve/areadetail?mode=update', {
            state: {
              __typename: 'Area',
              AreaId: String(id),
            } as Area,
          });
        } else {
          back();
        }
      },
    });
    setAlertContent('予約枠（地区）情報を登録しました。引き続き、サービス料金を登録しますか？');
    setAlertOpen(true);
  };

  async function registAreaDetail() {
    const areaRequest = {
      AreaName: areaData.AreaName,
      PrefectureCode: areaData.PrefectureCode,
      FromDate: areaData.FromDate,
      ToDate: areaData.ToDate,
      MaxGuestCount: areaData.MaxGuestCount,
      MaxSchoolCount: areaData.MaxSchoolCount,
      MaxMultiSchoolGuestCount: areaData.MaxMultiSchoolGuestCount,
      MaxTemporaryReservationCount: areaData.MaxTemporaryReservationCount,
      MaxReservationCount: areaData.MaxReservationCount,
      CheckInTime: areaData.CheckInTime,
      CheckOutTime: areaData.CheckOutTime,
      MultiStayCheckInTime: areaData.MultiStayCheckInTime,
      MultiStayCheckOutTime: areaData.MultiStayCheckOutTime,
      DisplayOrder: areaData.DisplayOrder,
      Url: areaData.Url,
      Memo1: areaData.Memo1,
      Memo2: areaData.Memo2,
      Memo3: areaData.Memo3,
      Memo4: areaData.Memo4,
      Memo5: areaData.Memo5,
      IsActive: areaData.IsActive,
    } as AreaRequest;

    const response = (
      (await api.graphql({
        query: registArea,
        variables: { data: areaRequest },
      })) as RegistAreaMutation
    ).registArea;

    if (response?.IsSuccess) {
      setIsUpdate(true);
      setErrorAttribute(CreateEmptyErrorData());
      moveNextAfterRegist(Number(response.Body));
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
      scrollToTop();
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
      scrollToTop();
    } else {
      throwError();
    }
  }

  async function updateAreaDetail() {
    const areaRequest = {
      AreaId: areaData.AreaId,
      AreaName: areaData.AreaName,
      PrefectureCode: areaData.PrefectureCode,
      FromDate: areaData.FromDate,
      ToDate: areaData.ToDate,
      MaxGuestCount: areaData.MaxGuestCount,
      MaxSchoolCount: areaData.MaxSchoolCount,
      MaxMultiSchoolGuestCount: areaData.MaxMultiSchoolGuestCount,
      MaxTemporaryReservationCount: areaData.MaxTemporaryReservationCount,
      MaxReservationCount: areaData.MaxReservationCount,
      CheckInTime: areaData.CheckInTime,
      CheckOutTime: areaData.CheckOutTime,
      MultiStayCheckInTime: areaData.MultiStayCheckInTime,
      MultiStayCheckOutTime: areaData.MultiStayCheckOutTime,
      DisplayOrder: areaData.DisplayOrder,
      Url: areaData.Url,
      Memo1: areaData.Memo1,
      Memo2: areaData.Memo2,
      Memo3: areaData.Memo3,
      Memo4: areaData.Memo4,
      Memo5: areaData.Memo5,
      IsActive: areaData.IsActive,
      UpdateDatetime: areaData.UpdateDatetime,
    } as AreaRequest;

    const response = (
      (await api.graphql({
        query: updateArea,
        variables: { data: areaRequest },
      })) as UpdateAreaMutation
    ).updateArea;

    if (response?.IsSuccess) {
      // 新規登録後、編集モードで再読みしている為、back すると新規モードに遷移してしまうため、
      // 直接一覧を指定（直したい・・）
      // back();
      navigator('/settings/reserve/arealist');
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
      scrollToTop();
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
      scrollToTop();
    } else {
      throwError();
    }
  }

  ///////////////////////////////////////////////////////////////////////////////
  // 初期表示処理
  ///////////////////////////////////////////////////////////////////////////////
  async function fetchAreaData(id: Number) {
    const response = (await api.graphql({
      query: findAreaDetail,
      variables: {
        AreaId: id,
      },
    })) as FindAreaDetailQuery;
    if (response?.findAreaDetail?.IsSuccess) {
      if (response?.findAreaDetail?.Body) {
        let areaResponse = response.findAreaDetail.Body.Area as Area;
        setAreaData({
          ...areaData,
          ['AreaId']: areaResponse.AreaId,
          ['AreaName']: areaResponse.AreaName,
          ['PrefectureCode']: areaResponse.PrefectureCode,
          ['FromDate']: areaResponse.FromDate,
          ['ToDate']: areaResponse.ToDate,
          ['MaxGuestCount']: areaResponse.MaxGuestCount,
          ['MaxSchoolCount']: areaResponse.MaxSchoolCount,
          ['MaxMultiSchoolGuestCount']: areaResponse.MaxMultiSchoolGuestCount,
          ['MaxTemporaryReservationCount']: areaResponse.MaxTemporaryReservationCount,
          ['MaxReservationCount']: areaResponse.MaxReservationCount,
          ['CheckInTime']: areaResponse.CheckInTime,
          ['CheckOutTime']: areaResponse.CheckOutTime,
          ['MultiStayCheckInTime']: areaResponse.MultiStayCheckInTime,
          ['MultiStayCheckOutTime']: areaResponse.MultiStayCheckOutTime,
          ['DisplayOrder']: areaResponse.DisplayOrder,
          ['Url']: areaResponse.Url,
          ['Memo1']: areaResponse.Memo1,
          ['Memo2']: areaResponse.Memo2,
          ['Memo3']: areaResponse.Memo3,
          ['Memo4']: areaResponse.Memo4,
          ['Memo5']: areaResponse.Memo5,
          ['IsActive']: areaResponse.IsActive,
          ['UpdateDatetime']: areaResponse.UpdateDatetime,
        });
        setAreaManagerData(response.findAreaDetail.Body.AreaManager as AreaManager[]);
        setAreaServiceData(response.findAreaDetail.Body.AreaService as AreaService[]);
        setRegionData(response.findAreaDetail.Body.Region as Region[]);
      }
    } else if (response?.findAreaDetail?.IsSystemError) {
      throwError(response?.findAreaDetail?.ErrorData ?? '');
    } else if (response?.findAreaDetail?.IsInputCheckError && response?.findAreaDetail?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.findAreaDetail?.ErrorData) as ErrorData),
      });
    } else if (response?.findAreaDetail?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.findAreaDetail?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('ReserveAreaDetail', userData.authorityCode)) {
      navigator('/');
    }

    master.waitForInitialized(() => {
      setPrefectureList(master.getPrefecture());
    });

    if (queryString.parse(location.search).mode === 'update') {
      setIsUpdate(true);
      fetchAreaData(Number(areaPara.AreaId));
    } else {
      setIsUpdate(false);

      // 初期値をセットする
      setAreaData({
        ...areaData,
        ['FromDate']: '1900/01/01',
        ['ToDate']: '2099/12/31',
        ['IsActive']: '1',
      });
    }
  }, []);

  return {
    isUpdate,
    areaData,
    areaManagerData,
    areaServiceData,
    regionData,
    errorAttribute,
    alertOpen,
    alertContent,
    prefectureList,
    handleChangeDetail,
    handleChangeFromDate,
    handleChangeToDate,
    handleClickRegist,
    handleClickAreaManagerCreateButton,
    handleClickAreaManagerEditButton,
    handleClickAreaManagerDeleteButton,
    handleClickAreaServiceCreateButton,
    handleClickAreaServiceEditButton,
    handleClickAreaServiceDeleteButton,
    handleClickRegionCreateButton,
    handleClickRegionEditButton,
    handleClickRegionDeleteButton,
    handleAlertClose,
  };
};
export default UseReserveAreaDetail;
