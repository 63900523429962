import { Heading, useTheme, Image } from '@aws-amplify/ui-react';
import logo from 'assets/tama_logo.png';

export function SignInHeader() {
  const { tokens } = useTheme();

  return (
    <>
      <Heading level={3} padding={`${tokens.space.xl} ${tokens.space.xl} 0`}>
        <Image alt="logo" src={logo} padding={tokens.space.medium} />
      </Heading>
      <Heading level={5} padding={`0 ${tokens.space.xl}`}>
        会員ログイン
      </Heading>
    </>
  );
}
