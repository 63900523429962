import React, { useCallback, useEffect, useState } from 'react';
import { scrollToTop } from 'util/Scroll';
import { useLocation, useNavigate } from 'react-router';
import {
  FindMinkaQuery,
  Prefecture,
  Area,
  Region,
  Minka,
  MinkaResident,
  MinkaVehicle,
  MinkaActivity,
  MinkaClosingSetting,
  MinkaRequest,
  RegistMinkaMutation,
  UpdateMinkaMutation,
  MinkaResidentRequest,
  DeleteMinkaResidentMutation,
  DeleteMinkaVehicleMutation,
  DeleteMinkaActivityMutation,
  RegistMinkaActivityMutation,
  MinkaVehicleRequest,
  MinkaClosingSettingRequest,
  MinkaActivityRequest,
} from '../../../../API';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useMaster } from 'contexts/CommonMasterContext';
import { UserData } from 'models/UserData';
import { useAPI } from 'contexts/APIRequestContext';
import { findMinka } from 'graphql/queries';
import {
  registMinka,
  updateMinka,
  deleteMinkaResident,
  deleteMinkaVehicle,
  deleteMinkaActivity,
  registMinkaActivity,
} from 'graphql/mutations';
import { CreateEmptyErrorData, ErrorData } from './MinkaDetailErrorData';
import { checkAuthority } from 'util/AuthorityCheck';
import { getDateDiffYearsMonths, getAge } from 'util/DateUtil';
import MinkaDetail from './MinkaDetail';

const queryString = require('query-string');
const UseMinkaDetail = () => {
  const location = useLocation();
  var minkaPara = location.state as Minka;
  const [isUpdate, setIsUpdate] = useState(Boolean);
  const [companyName, setCompanyName] = useState('');
  const [branchName, setBranchName] = useState('');
  const [, setError] = useState<undefined>();
  const { user } = useAuthenticator();
  const userData = new UserData(user);
  const master = useMaster();
  const api = useAPI();
  const [prefectureCode, setPrefectureCode] = useState('');
  const [areaId, setAreaId] = useState('');
  const [regionCode, setRegionCode] = useState('');
  const [prefectureList, setPrefectureList] = useState([] as Prefecture[]);
  const [areaList, setAreaList] = useState([] as Area[]);
  const [regionList, setRegionList] = useState([] as Region[]);
  const [minkaDetailData, setMinkaDetailData] = useState({} as Minka);
  const [residentRepresentativeData, setResidentRepresentativeData] = useState({} as MinkaResident);
  const [residentData, setResidentData] = useState([] as MinkaResident[]);
  const [vehicleData, setVehicleData] = useState([] as MinkaVehicle[]);
  const [activityData, setActivityData] = useState([] as MinkaActivity[]);
  const [closingSettingData, setClosingSetting] = useState([] as MinkaClosingSetting[]);
  const [activity, setActivity] = useState('');
  const [activityStatus, setActivityStatus] = useState(false);
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const navigator = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertDialogCallback, setAlertDialogCallback] = useState({ fn: (_: boolean) => {} });
  const [yearsOfExperience, setYearsOfExperience] = useState('');
  const [ageOfBuilding, setAgeOfBuilding] = useState('');
  const [representativeAge, setRepresentativeAge] = useState('');

  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  /**
   * ブラウザバック
   */
  const back = (): void => {
    window.history.back();
  };

  const handleChangeDetail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.type === 'number' && event.target.value === '' ? undefined : event.target.value; // MEMO: スキーマ定義が Int の場合、画面入力値を空に変更した場合、"" 空文字扱いとなり、エラーとなる為の対応
    const name = event.target.name;
    setMinkaDetailData({ ...minkaDetailData, [name]: value });
  };

  const handleChangeBirthday = (value: Date | null) => {
    if (value && value.toLocaleDateString()) {
      setRepresentativeAge(getAge(value));
    } else {
      setRepresentativeAge('---');
    }
    setResidentRepresentativeData({ ...residentRepresentativeData, BirthDay: value?.toLocaleDateString() });
  };

  const handleChangeAuthorizedDate = (value: Date | null) => {
    setMinkaDetailData({ ...minkaDetailData, AuthorizedDate: value?.toLocaleDateString() });
  };

  const handleChangeBussinessStartDate = (value: Date | null) => {
    if (value && value.toLocaleDateString()) {
      setYearsOfExperience(getDateDiffYearsMonths(value));
    } else {
      setYearsOfExperience('---');
    }

    setMinkaDetailData({ ...minkaDetailData, BussinessStartDate: value?.toLocaleDateString() });
  };

  const handleChangeConstructionDate = (value: Date | null) => {
    if (value && value.toLocaleDateString()) {
      setAgeOfBuilding(getDateDiffYearsMonths(value));
    } else {
      setAgeOfBuilding('---');
    }

    setMinkaDetailData({ ...minkaDetailData, ConstructionDate: value?.toLocaleDateString() });
  };

  const handleChangeResidentRepresentative = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    setResidentRepresentativeData({ ...residentRepresentativeData, [name]: value });
  };

  const handleChangePrefectureCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeDetail(event);
    setPrefectureCode(event.target.value);
    setAreaId('');
    setRegionCode('');
    minkaDetailData.AreaId = '';
    minkaDetailData.RegionCode = '';
    setMinkaDetailData({
      ...minkaDetailData,
      PrefectureCode: event.target.value,
      AreaId: '',
      RegionCode: '',
    });

    master.waitForInitialized(() => setAreaList(master.getAreasByPrefectureCode(event.target.value)));
    setRegionList([] as Region[]);
  };

  const handleChangeArea = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeDetail(event);
    setAreaId(event.target.value);
    setRegionCode('');
    minkaDetailData.RegionCode = '';
    setMinkaDetailData({
      ...minkaDetailData,
      AreaId: event.target.value,
      RegionCode: '',
    });

    master.waitForInitialized(() => setRegionList(master.getRegionsByAreaId(event.target.value)));
  };

  const handleChangeRegion = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegionCode(event.target.value);
    handleChangeDetail(event);
  };

  ///////////////////////////////////////////////////////////////////////////////
  // 同居人登録更新削除
  ///////////////////////////////////////////////////////////////////////////////
  const handleClickResidentEditButton = (minkaResident: MinkaResidentRequest) => {
    navigator('/minka/management/housemate?mode=update', {
      state: {
        MinkaId: minkaResident.MinkaId,
        ResidentId: minkaResident.ResidentId,
        LastName: minkaResident.LastName,
        FirstName: minkaResident.FirstName,
        LastKanaName: minkaResident.LastKanaName,
        FirstKanaName: minkaResident.FirstKanaName,
        BirthDay: minkaResident.BirthDay,
        Sex: minkaResident.Sex,
        Occupation: minkaResident.Occupation,
        ResidentsRelationCode: minkaResident.ResidentsRelationCode,
        Tel: minkaResident.Tel,
        Mail: minkaResident.Mail,
        Character: minkaResident.Character,
        WorkHistory: minkaResident.WorkHistory,
        UpdateDatetime: minkaResident.UpdateDatetime,
      } as MinkaResidentRequest,
    });
  };

  const handleClickResidentCreateButton = () => {
    navigator('/minka/management/housemate?mode=new', {
      state: {
        MinkaId: minkaPara.MinkaId,
      } as MinkaResidentRequest,
    });
  };

  const handleClickResidentDeleteButton = async (minkaResident: MinkaResident): Promise<void> => {
    setAlertDialogCallback({
      fn: async (result: boolean) => {
        if (result) {
          deleteResident(minkaResident);
        }
      },
    });
    setAlertContent('削除します。よろしいですか？');
    setAlertOpen(true);
  };

  async function deleteResident(minkaResident: MinkaResident) {
    let minkaResidentReq = {
      ResidentId: minkaResident.ResidentId,
      UpdateDatetime: minkaResident.UpdateDatetime,
    } as MinkaResidentRequest;

    const response = (
      (await api.graphql({
        query: deleteMinkaResident,
        variables: { data: minkaResidentReq },
      })) as DeleteMinkaResidentMutation
    ).deleteMinkaResident;

    if (response?.IsSuccess) {
      // リストから削除に成功したレコードを除外
      let newResidentList = residentData.filter((item) => item.ResidentId != minkaResident.ResidentId);
      setResidentData(newResidentList);
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  ///////////////////////////////////////////////////////////////////////////////
  // 車両登録更新削除
  ///////////////////////////////////////////////////////////////////////////////
  const handleClickVehicleEditButton = (minkaVehicle: MinkaVehicle) => {
    navigator('/minka/management/car?mode=update', {
      state: {
        MinkaId: minkaVehicle.MinkaId,
        CarNo: minkaVehicle.CarNo,
        InspectionDate: minkaVehicle.InspectionDate,
        InsuranceEndDate: minkaVehicle.InsuranceEndDate,
        CarInsurance: minkaVehicle.CarInsurance,
        CarType: minkaVehicle.CarType,
        Passenger: minkaVehicle.Passenger,
        UpdateDatetime: minkaVehicle.UpdateDatetime,
      } as MinkaVehicle,
    });
  };

  const handleClickVehicleCreateButton = () => {
    navigator('/minka/management/car?mode=new', {
      state: {
        MinkaId: minkaPara.MinkaId,
      } as MinkaVehicle,
    });
  };

  const handleClickVehicleDeleteButton = async (minkaVehicle: MinkaVehicle): Promise<void> => {
    setAlertDialogCallback({
      fn: async (result: boolean) => {
        if (result) {
          deleteVehicle(minkaVehicle);
        }
      },
    });
    setAlertContent('削除します。よろしいですか？');
    setAlertOpen(true);
  };

  async function deleteVehicle(minkaVehicle: MinkaVehicle) {
    let minkaVehicleReq = {
      MinkaId: minkaVehicle.MinkaId,
      CarNo: minkaVehicle.CarNo,
      UpdateDatetime: minkaVehicle.UpdateDatetime,
    } as MinkaVehicle;

    const response = (
      (await api.graphql({
        query: deleteMinkaVehicle,
        variables: { data: minkaVehicleReq },
      })) as DeleteMinkaVehicleMutation
    ).deleteMinkaVehicle;

    if (response?.IsSuccess) {
      // リストから削除に成功したレコードを除外
      let newResidentList = vehicleData.filter(
        (item) =>
          item.MinkaId?.toString() + '-' + item.CarNo != minkaVehicle.MinkaId?.toString() + '-' + minkaVehicle.CarNo
      );
      setVehicleData(newResidentList);
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  ///////////////////////////////////////////////////////////////////////////////
  // アクティビティ更新削除
  ///////////////////////////////////////////////////////////////////////////////
  const handleAddActivity = () => {
    setActivityStatus(true);
  };

  const handleChangeActivity = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActivity(event.target.value);
  };

  const handleCancelActivity = () => {
    setActivityStatus(false);
    setActivity('');
  };

  const handleClickActivityDeleteButton = async (minkaActivity: MinkaActivity): Promise<void> => {
    setAlertDialogCallback({
      fn: async (result: boolean) => {
        if (result) {
          deleteActivity(minkaActivity);
        }
      },
    });
    setAlertContent('削除します。よろしいですか？');
    setAlertOpen(true);
  };

  const handleClickActivityCreateButton = () => {
    registActivity();
  };

  async function registActivity() {
    const activityRequest = {
      MinkaId: minkaDetailData.MinkaId,
      Activity: activity,
    } as MinkaActivityRequest;

    const response = (
      (await api.graphql({
        query: registMinkaActivity,
        variables: { data: activityRequest },
      })) as RegistMinkaActivityMutation
    ).registMinkaActivity;

    if (response?.IsSuccess) {
      let actData = activityData;
      actData.push({
        __typename: 'MinkaActivity',
        MinkaId: minkaDetailData.MinkaId,
        Activity: activity,
      } as MinkaActivity);
      setActivityData(Object.assign([], actData));
      setActivityStatus(false);
      setActivity('');
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  async function deleteActivity(minkaActivity: MinkaActivity) {
    let minkaActivityReq = {
      MinkaId: minkaActivity.MinkaId,
      Activity: minkaActivity.Activity,
      UpdateDatetime: minkaActivity.UpdateDatetime,
    } as MinkaActivity;

    const response = (
      (await api.graphql({
        query: deleteMinkaActivity,
        variables: { data: minkaActivityReq },
      })) as DeleteMinkaActivityMutation
    ).deleteMinkaActivity;

    if (response?.IsSuccess) {
      // リストから削除に成功したレコードを除外
      let newActivityList = activityData.filter(
        (item) =>
          item.MinkaId?.toString() + '-' + item.Activity !=
          minkaActivity.MinkaId?.toString() + '-' + minkaActivity.Activity
      );
      setActivityData(newActivityList);
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  ///////////////////////////////////////////////////////////////////////////////
  // 休業日編集
  ///////////////////////////////////////////////////////////////////////////////
  const handleClickClosingSettingEditButton = (minkaClosingSetting: MinkaClosingSetting) => {
    navigator('/minka/management/closedMonth', {
      state: {
        MinkaId: minkaClosingSetting.MinkaId,
        SchoolYearCode: minkaClosingSetting.SchoolYearCode,
        Period1: minkaClosingSetting.Period1,
        Period2: minkaClosingSetting.Period2,
        Period3: minkaClosingSetting.Period3,
        Period4: minkaClosingSetting.Period4,
        Period5: minkaClosingSetting.Period5,
        Period6: minkaClosingSetting.Period6,
        Period7: minkaClosingSetting.Period7,
        Period8: minkaClosingSetting.Period8,
        Period9: minkaClosingSetting.Period9,
        Period10: minkaClosingSetting.Period10,
        Period11: minkaClosingSetting.Period11,
        Period12: minkaClosingSetting.Period12,
      } as MinkaClosingSettingRequest,
    });
  };

  ///////////////////////////////////////////////////////////////////////////////
  // 民家基本情報登録更新削除
  ///////////////////////////////////////////////////////////////////////////////
  const handleClickRegist = () => {
    if (isUpdate) {
      updateMinkaDetail();
    } else {
      registMinkaDetail();
    }
  };

  const moveNextAfterRegist = async (id: Number): Promise<void> => {
    setAlertDialogCallback({
      fn: async (result: boolean) => {
        if (result) {
          await fetchMinkaData(Number(id));
          // 編集モードで再読み込み
          navigator('/minka/management/detail?mode=update', {
            state: {
              __typename: 'Minka',
              MinkaId: Number(id),
            } as Minka,
          });
        } else {
          back();
        }
      },
    });
    setAlertContent('民家基本情報を登録しました。引き続き、同居人やアクティビティ等の付帯情報を登録しますか？');
    setAlertOpen(true);
  };

  async function registMinkaDetail() {
    const minkaRequest = {
      Minka: minkaDetailData,
      MinkaResidentRepresentative: residentRepresentativeData,
    } as MinkaRequest;

    const response = (
      (await api.graphql({
        query: registMinka,
        variables: { data: minkaRequest },
      })) as RegistMinkaMutation
    ).registMinka;

    if (response?.IsSuccess) {
      setIsUpdate(true);
      setErrorAttribute(CreateEmptyErrorData());
      await moveNextAfterRegist(Number(response.Body));
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
      scrollToTop();
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
      scrollToTop();
    } else {
      throwError();
    }
  }

  async function updateMinkaDetail() {
    const minkaRequest = {
      Minka: minkaDetailData,
      MinkaResidentRepresentative: residentRepresentativeData,
    } as MinkaRequest;

    const response = (
      (await api.graphql({
        query: updateMinka,
        variables: { data: minkaRequest },
      })) as UpdateMinkaMutation
    ).updateMinka;

    if (response?.IsSuccess) {
      // 新規登録後、編集モードで再読みしている為、back すると新規モードに遷移してしまうため、
      // 直接民家一覧を指定（直したい・・）
      // back();
      navigator('/minka/management/list');
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
      scrollToTop();
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
      scrollToTop();
    } else {
      throwError();
    }
  }

  ///////////////////////////////////////////////////////////////////////////////
  // 初期表示処理
  ///////////////////////////////////////////////////////////////////////////////
  async function fetchMinkaData(id: Number) {
    const response = (await api.graphql({
      query: findMinka,
      variables: {
        MinkaId: id,
      },
    })) as FindMinkaQuery;

    if (response?.findMinka?.IsSuccess) {
      if (response?.findMinka?.Body) {
        let minkaResponse = response.findMinka.Body.Minka as Minka;

        setAreaList(master.getAreasByPrefectureCode(response.findMinka.Body.Minka?.PrefectureCode?.toString() || ''));
        setRegionList(master.getRegionsByAreaId(response.findMinka.Body.Minka?.AreaId?.toString() || ''));
        setPrefectureCode(minkaResponse.PrefectureCode?.toString() ?? '');
        setAreaId(minkaResponse.AreaId?.toString() ?? '');
        setRegionCode(minkaResponse.RegionCode?.toString() ?? '');

        setMinkaDetailData({
          ...minkaDetailData,
          ['MinkaId']: minkaResponse.MinkaId,
          ['MinkaName']: minkaResponse.MinkaName,
          ['BussinessName']: minkaResponse.BussinessName,
          ['ManagingCompanyId']: minkaResponse.ManagingCompanyId,
          ['ManagingBranchId']: minkaResponse.ManagingBranchId,
          ['MinkaNo']: minkaResponse.MinkaNo,
          ['PostalCode']: minkaResponse.PostalCode,
          ['PrefectureCode']: minkaResponse.PrefectureCode,
          ['AreaId']: minkaResponse.AreaId,
          ['RegionCode']: minkaResponse.RegionCode,
          ['City']: minkaResponse.City,
          ['Address']: minkaResponse.Address,
          ['Tel']: minkaResponse.Tel,
          ['GuestCount']: minkaResponse.GuestCount,
          ['MaxGuestcount']: minkaResponse.MaxGuestcount,
          ['AcceptanceGender']: minkaResponse.AcceptanceGender,
          ['Pets']: minkaResponse.Pets,
          ['DressingRoom']: minkaResponse.DressingRoom,
          ['AirConditionar']: minkaResponse.AirConditionar,
          ['ToiletType']: minkaResponse.ToiletType,
          ['SmokeType']: minkaResponse.SmokeType,
          ['AuthorityNumber']: minkaResponse.AuthorityNumber,
          ['AuthorizedDate']: minkaResponse.AuthorizedDate,
          ['OtherAddress']: minkaResponse.OtherAddress,
          ['BankName']: minkaResponse.BankName,
          ['BankBranchName']: minkaResponse.BankBranchName,
          ['AccountType']: minkaResponse.AccountType,
          ['AccountNumber']: minkaResponse.AccountNumber,
          ['AccountName']: minkaResponse.AccountName,
          ['AccountNameKana']: minkaResponse.AccountNameKana,
          ['Memo']: minkaResponse.Memo,
          ['IsAvailable']: minkaResponse.IsAvailable,
          ['IsActive']: minkaResponse.IsActive,
          ['InternetType']: minkaResponse.InternetType,
          ['BarrierFreeCapability']: minkaResponse.BarrierFreeCapability,
          ['AlertResponse']: minkaResponse.AlertResponse,
          ['BussinessStartDate']: minkaResponse.BussinessStartDate,
          ['ConstructionDate']: minkaResponse.ConstructionDate,
          ['ArchitecturalStructure']: minkaResponse.ArchitecturalStructure,
          ['FacilityInsurance']: minkaResponse.FacilityInsurance,
          ['LandslideWarningArea']: minkaResponse.LandslideWarningArea,
          ['TsunamiWarningArea']: minkaResponse.TsunamiWarningArea,
          ['EvacuationSite']: minkaResponse.EvacuationSite,
          ['FloodHazard']: minkaResponse.FloodHazard,
          ['RainHazard']: minkaResponse.RainHazard,
          ['HighTideHazard']: minkaResponse.HighTideHazard,
          ['Longitude']: minkaResponse.Longitude,
          ['Latitude']: minkaResponse.Latitude,
          ['UpdateDatetime']: minkaResponse.UpdateDatetime,
        });

        let residentRepresentativeResponse = response.findMinka.Body.MinkaResidentRepresentative as MinkaResident;
        setResidentRepresentativeData({
          ...residentRepresentativeData,
          ['MinkaId']: residentRepresentativeResponse.MinkaId,
          ['ResidentId']: residentRepresentativeResponse.ResidentId,
          ['LastName']: residentRepresentativeResponse.LastName,
          ['FirstName']: residentRepresentativeResponse.FirstName,
          ['LastKanaName']: residentRepresentativeResponse.LastKanaName,
          ['FirstKanaName']: residentRepresentativeResponse.FirstKanaName,
          ['BirthDay']: residentRepresentativeResponse.BirthDay,
          ['Sex']: residentRepresentativeResponse.Sex,
          ['Occupation']: residentRepresentativeResponse.Occupation,
          ['ResidentsRelationCode']: residentRepresentativeResponse.ResidentsRelationCode,
          ['Tel']: residentRepresentativeResponse.Tel,
          ['Mail']: residentRepresentativeResponse.Mail,
          ['WorkHistory']: residentRepresentativeResponse.WorkHistory,
          ['Character']: residentRepresentativeResponse.Character,
          ['UpdateDatetime']: residentRepresentativeResponse.UpdateDatetime,
        });
        setResidentData(response.findMinka.Body.MinkaResidents as MinkaResident[]);
        setVehicleData(response.findMinka.Body.MinkaVehicles as MinkaVehicle[]);
        setActivityData(response.findMinka.Body.MinkaActivities as MinkaActivity[]);
        setClosingSetting(response.findMinka.Body.MinkaClosingSettings as MinkaClosingSetting[]);
        setCompanyName(master.getCompanyName(response.findMinka.Body.Minka?.ManagingCompanyId?.toString() || ''));
        setBranchName(
          master.getBranchName(
            response.findMinka.Body.Minka?.ManagingCompanyId?.toString() || '',
            response.findMinka.Body.Minka?.ManagingBranchId?.toString() || ''
          )
        );

        if (minkaResponse.ConstructionDate) {
          setAgeOfBuilding(getDateDiffYearsMonths(new Date(minkaResponse.ConstructionDate)));
        } else {
          setAgeOfBuilding('---');
        }

        if (minkaResponse.BussinessStartDate) {
          setYearsOfExperience(getDateDiffYearsMonths(new Date(minkaResponse.BussinessStartDate)));
        } else {
          setYearsOfExperience('---');
        }

        if (residentRepresentativeResponse.BirthDay) {
          setRepresentativeAge(getAge(new Date(residentRepresentativeResponse.BirthDay)));
        } else {
          setRepresentativeAge('---');
        }
      }
    } else if (response?.findMinka?.IsSystemError) {
      throwError(response?.findMinka?.ErrorData ?? '');
    } else if (response?.findMinka?.IsInputCheckError && response?.findMinka?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.findMinka?.ErrorData) as ErrorData),
      });
    } else if (response?.findMinka?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.findMinka?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('MinkaDetail', userData.authorityCode)) {
      navigator('/');
    }

    master.waitForInitialized(() => {
      setPrefectureList(master.getPrefecture());
    });
    if (queryString.parse(location.search).mode === 'update') {
      setIsUpdate(true);
      fetchMinkaData(Number(minkaPara.MinkaId));
    } else {
      setIsUpdate(false);
      setCompanyName(master.getCompanyName(userData.companyId));
      setBranchName(master.getBranchName(userData.companyId, userData.branchId));

      // 初期値をセットする
      setMinkaDetailData({
        ...minkaDetailData,
        ['MinkaName']: '',
        ['BussinessName']: '',
        ['ManagingBranchId']: +userData.branchId,
        ['ManagingCompanyId']: +userData.companyId,
        ['MinkaNo']: null,
        ['PostalCode']: '',
        ['PrefectureCode']: '',
        ['AreaId']: '',
        ['RegionCode']: '',
        ['City']: '',
        ['Address']: '',
        ['Tel']: '',
        ['GuestCount']: null,
        ['MaxGuestcount']: null,
        ['AcceptanceGender']: '3',
        ['Pets']: '',
        ['DressingRoom']: 1,
        ['AirConditionar']: 1,
        ['ToiletType']: '1',
        ['SmokeType']: '1',
        ['AuthorityNumber']: '',
        ['AuthorizedDate']: null,
        ['OtherAddress']: '',
        ['BankName']: '',
        ['BankBranchName']: '',
        ['AccountType']: '',
        ['AccountNumber']: '',
        ['AccountName']: '',
        ['AccountNameKana']: '',
        ['Memo']: '',
        ['IsAvailable']: 1,
        ['IsActive']: 1,
        ['InternetType']: '1',
        ['BarrierFreeCapability']: 2,
        ['AlertResponse']: '',
        ['BussinessStartDate']: null,
        ['ConstructionDate']: null,
        ['ArchitecturalStructure']: '',
        ['FacilityInsurance']: 2,
        ['LandslideWarningArea']: 2,
        ['TsunamiWarningArea']: 2,
        ['EvacuationSite']: '',
        ['FloodHazard']: 2,
        ['RainHazard']: 2,
        ['HighTideHazard']: 2,
        ['Longitude']: '',
        ['Latitude']: '',
      });

      setResidentRepresentativeData({
        ...residentRepresentativeData,
        ['Sex']: '1',
        ['ResidentsRelationCode']: '0',
        ['BirthDay']: null,
      });
    }
  }, []);

  /**
   * 警告ダイアログ　閉じる
   * @param result 結果
   */
  const handleAlertClose = (result: boolean): void => {
    alertDialogCallback.fn(result);
    setAlertOpen(false);
  };

  /**
   * Google Map を開く
   */
  const handleOpenMap = (): void => {
    const latitude = parseFloat(minkaDetailData.Latitude!);
    const longitude = parseFloat(minkaDetailData.Longitude!);
    if (!isNaN(latitude) && !isNaN(longitude)) {
      // Google MapsのURLを構築
      const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;

      // 新しいタブでGoogle Mapsを開く
      window.open(googleMapsUrl, '_blank');
    } else {
      alert('有効な経度と緯度を入力してください。');
    }
  };

  return {
    minkaDetailData,
    residentRepresentativeData,
    residentData,
    vehicleData,
    activityData,
    closingSettingData,
    isUpdate,
    activity,
    activityStatus,
    companyName,
    branchName,
    prefectureCode,
    areaId,
    regionCode,
    prefectureList,
    areaList,
    regionList,
    errorAttribute,
    alertOpen,
    alertContent,
    yearsOfExperience,
    ageOfBuilding,
    representativeAge,
    handleChangeDetail,
    handleChangeArea,
    handleChangePrefectureCode,
    handleChangeRegion,
    handleChangeBirthday,
    handleChangeBussinessStartDate,
    handleChangeConstructionDate,
    handleChangeAuthorizedDate,
    handleChangeResidentRepresentative,
    handleClickRegist,
    handleClickResidentCreateButton,
    handleClickResidentEditButton,
    handleClickResidentDeleteButton,
    handleClickVehicleCreateButton,
    handleClickVehicleEditButton,
    handleClickVehicleDeleteButton,
    handleClickClosingSettingEditButton,
    handleAddActivity,
    handleCancelActivity,
    handleChangeActivity,
    handleClickActivityCreateButton,
    handleClickActivityDeleteButton,
    handleAlertClose,
    handleOpenMap,
  };
};
export default UseMinkaDetail;
