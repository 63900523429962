import FormErrorAttribute from 'types/FormErrorAttribute';

export interface ErrorData {
  Header: FormErrorAttribute;
  ServiceId: FormErrorAttribute;
  Rate: FormErrorAttribute;
  CheckInTime: FormErrorAttribute;
  CheckOutTime: FormErrorAttribute;
}

export const CreateEmptyErrorData = () => {
  return {
    Header: {} as FormErrorAttribute,
    ServiceId: {} as FormErrorAttribute,
    Rate: {} as FormErrorAttribute,
    CheckInTime: {} as FormErrorAttribute,
    CheckOutTime: {} as FormErrorAttribute,
  } as ErrorData;
};
