import { Container } from '@mui/material';
import Button from '@mui/material/Button/Button';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TableHead from '@mui/material/TableHead/TableHead';
import TablePagination from '@mui/material/TablePagination/TablePagination';
import TableRow from '@mui/material/TableRow/TableRow';
import Typography from '@mui/material/Typography/Typography';
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { StudentData } from 'types/StudentData';
import GenericTemplate from '../../../template/GenericTemplate';

const styles = {
  searchField: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
  },

  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
  },

  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mr: 1,
    float: 'right',
    mt: 1,
  },
};

export interface Props {
  resultData: StudentData[];
}

const StudentSimpleList: FC<Props> = ({ resultData }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <GenericTemplate title="生徒一覧">
      <Container sx={styles.searchField}>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          生徒一覧
        </Typography>
        <TableContainer sx={{ minHeight: 300 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>予約ID</TableCell>
                <TableCell>組</TableCell>
                <TableCell>番号</TableCell>
                <TableCell>氏名</TableCell>
                <TableCell>カナ氏名</TableCell>
                <TableCell>性別</TableCell>
                <TableCell>アレルギー</TableCell>
                <TableCell>持病</TableCell>
                <TableCell>不安事項</TableCell>
                <TableCell>注意事項</TableCell>
                <TableCell>割当民家</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resultData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((student, index) => (
                <TableRow key={index}>
                  <TableCell component="td" scope="row">
                    {student.ReservationId}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {student.ClassCode}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {student.StudentNo}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {student.StudentName}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {student.StudentKanaName}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {student.Sex}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {student.Allergy}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {student.Disease}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {student.Consern}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {student.Notice}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {student.BusinessName}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[7, 25, 100]}
          component="div"
          count={resultData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Container>
      <Link to="/reserve/detail">
        <Button variant="contained" sx={styles.button}>
          閉じる
        </Button>
      </Link>
    </GenericTemplate>
  );
};

export default StudentSimpleList;
