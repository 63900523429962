import { DialogTitle, FormControl, FormHelperText, Link, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ApproveReservation } from 'API';
import React, { FC } from 'react';
import { ErrorData } from './ApprovalDialogErrorData';

export interface Props {
  errorAttribute: ErrorData;
  dialogOpen: boolean;
  inputData: ApproveReservation;
  handleChangeCenterConfirmationDoc: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleDeleteCenterConfirmationDoc: () => void;
  handleClose: () => void;
  handleRegist: () => void;
}

const styles = {
  categoryHeader: {
    fontWeight: 'bold',
  },
  itemHeader: {
    backgroundColor: '#ececec',
    fontWeight: 'bold',
  },
  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
  },
  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 1,
    ml: 1,
  },
  label: {
    mt: '7px',
  },
  categoryTitle: {
    borderLeft: 5,
    borderColor: '#0058a0',
    pl: '10px',
  },
  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },
  listButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    m: 0,
  },
  headerButton: {
    minWidth: '120px',
    fontWeight: 'bold',
  },
  headerMessage: {
    color: '#d32f2f',
    fontWeight: 'bold',
  },
  fileLink: {
    mt: '7px',
    mr: '10px',
    wordBreak: 'break-all',
    overflowWrap: 'break-word',
  },
};

const ApprovalDialog: FC<Props> = ({
  errorAttribute,
  dialogOpen,
  inputData,
  handleChangeCenterConfirmationDoc,
  handleDeleteCenterConfirmationDoc,
  handleClose,
  handleRegist,
}) => {
  return (
    <Dialog open={dialogOpen} onClose={handleClose} maxWidth={'md'}>
      <DialogTitle>
        本予約承認
        {errorAttribute.Header.ErrorMessage && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl component="fieldset" error={errorAttribute.Header.IsError}>
                <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                  {errorAttribute.Header.ErrorMessage}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          決定確認書をアップロードしてください。
        </Typography>
        <Grid container spacing={2} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.fileLink}>決定確認書 *</Typography>
          </Grid>
          <Grid item xs={9}>
            {!!inputData.CenterConfirmationDocName && (
              <Link
                target="_blank"
                rel="noopener noreferrer"
                sx={styles.fileLink}
                href={inputData.CenterConfirmationDoc!}
                download={inputData.CenterConfirmationDocName}
              >
                {inputData.CenterConfirmationDocName}
              </Link>
            )}
            {!!inputData.CenterConfirmationDocName ? (
              <Button variant="outlined" onClick={handleDeleteCenterConfirmationDoc}>
                削除
              </Button>
            ) : (
              <label htmlFor="CenterConfirmationDoc">
                <input id="CenterConfirmationDoc" type="file" hidden onChange={handleChangeCenterConfirmationDoc} />
                <Button variant="outlined" component="span">
                  参照
                </Button>
              </label>
            )}
            <FormControl error={errorAttribute.CenterConfirmationDoc.IsError}>
              <FormHelperText>{errorAttribute.CenterConfirmationDoc.ErrorMessage}</FormHelperText>
            </FormControl>
            <Typography variant="subtitle2">
              ・アップロードできるファイルは、拡張子が「.doc(.docx)」、「.xls(.xlsx)」、「.pdf」のものに限ります。
            </Typography>
            <Typography variant="subtitle2">・ファイルサイズは、「500KB」以下にしてください。</Typography>
          </Grid>
        </Grid>

        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" sx={styles.button} onClick={handleRegist}>
              承認する
            </Button>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" sx={styles.button} onClick={handleClose}>
              閉じる
            </Button>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
export default ApprovalDialog;
