import FormErrorAttribute from 'types/FormErrorAttribute';

export interface ErrorData {
  Header: FormErrorAttribute;
  // 会社基本情報
  CategoryCode: FormErrorAttribute;
  CompanyName: FormErrorAttribute;
  CompanyShortName: FormErrorAttribute;
  IsActive: FormErrorAttribute;

  // 本社情報
  BranchName: FormErrorAttribute;
  PostalCode: FormErrorAttribute;
  PrefectureCode: FormErrorAttribute;
  Address: FormErrorAttribute;
  Tel: FormErrorAttribute;
  Fax: FormErrorAttribute;
  Mail1: FormErrorAttribute;
  Mail2: FormErrorAttribute;
  Mail3: FormErrorAttribute;
}

export const CreateEmptyErrorData = () => {
  return {
    Header: {} as FormErrorAttribute,
    // 会社基本情報
    CategoryCode: {} as FormErrorAttribute,
    CompanyName: {} as FormErrorAttribute,
    CompanyShortName: {} as FormErrorAttribute,
    IsActive: {} as FormErrorAttribute,

    // 本社情報
    BranchName: {} as FormErrorAttribute,
    PostalCode: {} as FormErrorAttribute,
    PrefectureCode: {} as FormErrorAttribute,
    Address: {} as FormErrorAttribute,
    Tel: {} as FormErrorAttribute,
    Fax: {} as FormErrorAttribute,
    Mail1: {} as FormErrorAttribute,
    Mail2: {} as FormErrorAttribute,
    Mail3: {} as FormErrorAttribute,
  } as ErrorData;
};

