import { FC } from 'react';
import GenericTemplate from '../../../template/GenericTemplate';
import React from 'react';
import { Container, FormControl, FormHelperText, TablePagination } from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import Grid from '@mui/material/Grid/Grid';
import SelectFormControl from '../../../parts/SelectFormControl';
import { SelectValue } from '../../../../types/SelectValue';
import { Prefecture, Area, AreaManager, AreaService, Region } from '../../../../API';
import Button from '@mui/material/Button/Button';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import TextField from '@mui/material/TextField/TextField';
import Radio from '@mui/material/Radio/Radio';
import RadioGroup from '@mui/material/RadioGroup/RadioGroup';
import TableBody from '@mui/material/TableBody/TableBody';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import { Link } from 'react-router-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import jaLocale from 'date-fns/locale/ja';
import DesktopDatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ErrorData } from './ReserveAreaDetailErrorData';
import AlertDialog from '../../alert';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import BackToTopButton from 'views/parts/BackToTop';

const styles = {
  searchField: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
  },

  label: {
    margin: 'auto',
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
  },

  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
  },

  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
    '& > div > input': {
      maxHeight: '10px',
    },
  },

  textArea: {
    width: '100%',
    '& input': {
      padding: '8px 14px',
    },
    mt: 1,
    mb: 1,
  },

  button: {
    minWidth: '120px',
    mr: 1,
    fontWeight: 'bold',
    float: 'right',
    mt: 3,
    mb: 2,
  },

  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },

  textField: {
    width: '100%',
    '& input': {
      padding: '8px 14px',
    },
  },

  createButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    mt: 1,
    float: 'left',
    mb: 3,
    ml: 2,
  },

  listButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    m: 0,
  },

  deleteCell: {
    '& > a': {
      textDecoration: 'none',
    },
  },
};

export interface Props {
  isUpdate: boolean;
  areaData: Area;
  areaManagerData: AreaManager[];
  areaServiceData: AreaService[];
  regionData: Region[];
  errorAttribute: ErrorData;
  alertOpen: boolean;
  alertContent: string;
  prefectureList: Prefecture[];
  handleChangeDetail: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickRegist: () => void;
  handleClickAreaManagerCreateButton: () => void;
  handleClickAreaManagerEditButton: (_: AreaManager) => void;
  handleClickAreaManagerDeleteButton: (_: AreaManager) => void;
  handleClickAreaServiceCreateButton: () => void;
  handleClickAreaServiceEditButton: (_: AreaService) => void;
  handleClickAreaServiceDeleteButton: (_: AreaService) => void;
  handleClickRegionCreateButton: () => void;
  handleClickRegionEditButton: (_: Region) => void;
  handleClickRegionDeleteButton: (_: Region) => void;
  handleAlertClose: (_: boolean) => void;
  handleChangeFromDate: (_: Date) => void;
  handleChangeToDate: (_: Date) => void;
}

const ReserveAreaDetail: FC<Props> = (props) => {
  return (
    <GenericTemplate title="予約枠（地区）詳細">
      <div>
        <BackToTopButton />
      </div>
      <Container sx={styles.searchField}>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          地区情報
        </Typography>
        {props.errorAttribute.Header.ErrorMessage && (
          <Grid container spacing={2} sx={{ pl: 0, pb: 2 }}>
            <Grid item xs={12}>
              <FormControl component="fieldset" error={props.errorAttribute.Header.IsError}>
                <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                  {props.errorAttribute.Header.ErrorMessage}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>都道府県</Typography>
          </Grid>
          <Grid item xs={7}>
            <SelectFormControl
              value={props.areaData.PrefectureCode ?? ''}
              selectName="PrefectureCode"
              selectValues={props.prefectureList
                .filter((x) => x.IsMinpakuArea === 1)
                .map((prefecture, idx) => {
                  return { value: prefecture.PrefectureCode, displayName: prefecture.PrefectureName } as SelectValue;
                })}
              setCondition={props.handleChangeDetail}
              sx={styles.inputItem}
              isDisabled={props.isUpdate}
              error={props.errorAttribute.PrefectureCode.IsError}
              errorMessage={props.errorAttribute.PrefectureCode.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>地区名</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'AreaName'}
              value={props.areaData.AreaName}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              inputProps={{ maxLength: 255 }}
              error={props.errorAttribute.AreaName.IsError}
              helperText={props.errorAttribute.AreaName.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>受入人数</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'MaxGuestCount'}
              value={props.areaData.MaxGuestCount}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="number"
              error={props.errorAttribute.MaxGuestCount.IsError}
              helperText={props.errorAttribute.MaxGuestCount.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>同時受入校数</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'MaxSchoolCount'}
              value={props.areaData.MaxSchoolCount}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="number"
              error={props.errorAttribute.MaxSchoolCount.IsError}
              helperText={props.errorAttribute.MaxSchoolCount.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>同時受入人数</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'MaxMultiSchoolGuestCount'}
              value={props.areaData.MaxMultiSchoolGuestCount}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="number"
              error={props.errorAttribute.MaxMultiSchoolGuestCount.IsError}
              helperText={props.errorAttribute.MaxMultiSchoolGuestCount.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>最大仮予約受入校数</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'MaxTemporaryReservationCount'}
              value={props.areaData.MaxTemporaryReservationCount}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="number"
              error={props.errorAttribute.MaxTemporaryReservationCount.IsError}
              helperText={props.errorAttribute.MaxTemporaryReservationCount.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>最大予約受付校数</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'MaxReservationCount'}
              value={props.areaData.MaxReservationCount}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="number"
              error={props.errorAttribute.MaxReservationCount.IsError}
              helperText={props.errorAttribute.MaxReservationCount.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>入村時間</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'CheckInTime'}
              value={props.areaData.CheckInTime}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="time"
              error={props.errorAttribute.CheckInTime.IsError}
              helperText={props.errorAttribute.CheckInTime.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>退村時間</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'CheckOutTime'}
              value={props.areaData.CheckOutTime}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="time"
              error={props.errorAttribute.CheckOutTime.IsError}
              helperText={props.errorAttribute.CheckOutTime.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>連泊入村時間</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'MultiStayCheckInTime'}
              value={props.areaData.MultiStayCheckInTime}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="time"
              error={props.errorAttribute.MultiStayCheckInTime.IsError}
              helperText={props.errorAttribute.MultiStayCheckInTime.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>連泊退村時間</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'MultiStayCheckOutTime'}
              value={props.areaData.MultiStayCheckOutTime}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="time"
              error={props.errorAttribute.MultiStayCheckOutTime.IsError}
              helperText={props.errorAttribute.MultiStayCheckOutTime.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>表示順</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'DisplayOrder'}
              value={props.areaData.DisplayOrder}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="number"
              error={props.errorAttribute.DisplayOrder.IsError}
              helperText={props.errorAttribute.DisplayOrder.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>紹介URL</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'Url'}
              value={props.areaData.Url}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              inputProps={{ maxLength: 255 }}
              error={props.errorAttribute.Url.IsError}
              helperText={props.errorAttribute.Url.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>受入条件</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              sx={styles.textArea}
              multiline
              name={'Memo1'}
              rows={4}
              variant="outlined"
              value={props.areaData.Memo1}
              onChange={props.handleChangeDetail}
              defaultValue={
                '※連泊の場合は、XX時以降入村、X時退村となります。\n' +
                '※基本入退村時間外での受入は、前後の団体様と調整できる場合に限り、' +
                '宿泊のX年前より承ります（宿泊オプションとして別途料金）。'
              }
            />
          </Grid>
          <Grid container spacing={0.5} sx={styles.categoryBlock}>
            <Grid item xs={3}>
              <Typography sx={styles.label}>開始日</Typography>
            </Grid>
            <Grid item xs={7}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
                <DesktopDatePicker
                  label="yyyy/mm/dd"
                  mask="____/__/__"
                  value={new Date(props.areaData.FromDate ?? '')}
                  onChange={(newValue) => props.handleChangeFromDate(new Date(newValue ?? ''))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      style={{ width: '100%' }}
                      error={props.errorAttribute.FromDate.IsError}
                      helperText={props.errorAttribute.FromDate.ErrorMessage}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid container spacing={0.5} sx={styles.categoryBlock}>
            <Grid item xs={3}>
              <Typography sx={styles.label}>終了日</Typography>
            </Grid>
            <Grid item xs={7}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
                <DesktopDatePicker
                  label="yyyy/mm/dd"
                  mask="____/__/__"
                  value={new Date(props.areaData.ToDate ?? '')}
                  onChange={(newValue) => props.handleChangeToDate(new Date(newValue ?? ''))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      style={{ width: '100%' }}
                      error={props.errorAttribute.ToDate.IsError}
                      helperText={props.errorAttribute.ToDate.ErrorMessage}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>有効区分</Typography>
          </Grid>
          <Grid item xs={9}>
            <RadioGroup
              row
              name="IsActive"
              aria-label="position"
              value={props.areaData.IsActive ?? '1'}
              onChange={props.handleChangeDetail}
            >
              <FormControlLabel value={1} control={<Radio color="primary" />} label="有効" />
              <FormControlLabel value={0} control={<Radio color="primary" />} label="無効" />
            </RadioGroup>
            <FormHelperText>{props.errorAttribute.IsActive.ErrorMessage}</FormHelperText>
          </Grid>
        </Grid>
        {props.isUpdate ? (
          <Grid>
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              sx={styles.categoryTitle}
              style={{ marginTop: '40px' }}
            >
              地域
            </Typography>
            <Grid container spacing={0.5} sx={styles.categoryBlock}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  sx={styles.createButton}
                  size="small"
                  onClick={() => props.handleClickRegionCreateButton()}
                >
                  追加
                </Button>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>地域コード</TableCell>
                        <TableCell>有効区分</TableCell>
                        <TableCell sx={{ minWidth: '350px' }}>地域名</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.regionData.map((region, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Button
                              variant="contained"
                              sx={styles.listButton}
                              size="small"
                              onClick={() => props.handleClickRegionDeleteButton(region)}
                            >
                              削除
                            </Button>
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {region.RegionCode}
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {region.IsActive === '1' ? <Checkbox disabled checked /> : <Checkbox disabled />}
                          </TableCell>{' '}
                          <TableCell component="td" scope="row">
                            {region.RegionName}
                          </TableCell>
                          <TableCell sx={styles.deleteCell}>
                            <Button
                              variant="contained"
                              sx={styles.listButton}
                              size="small"
                              onClick={() => props.handleClickRegionEditButton(region)}
                            >
                              編集
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              sx={styles.categoryTitle}
              style={{ marginTop: '40px' }}
            >
              地区管理事業者
            </Typography>
            <Grid container spacing={0.5} sx={styles.categoryBlock}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  sx={styles.createButton}
                  size="small"
                  onClick={() => props.handleClickAreaManagerCreateButton()}
                >
                  追加
                </Button>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>事業者名</TableCell>
                        <TableCell>営業店名</TableCell>
                        <TableCell>開始日</TableCell>
                        <TableCell>終了日</TableCell>
                        <TableCell>地区予約窓口</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.areaManagerData.map((areaManager, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Button
                              variant="contained"
                              sx={styles.listButton}
                              size="small"
                              onClick={() => props.handleClickAreaManagerDeleteButton(areaManager)}
                            >
                              削除
                            </Button>
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {areaManager.CompanyName}
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {areaManager.BranchName}
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {areaManager.FromDate}
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {areaManager.ToDate}
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {areaManager.IsRepresentativ === '1' ? (
                              <Checkbox disabled checked />
                            ) : (
                              <Checkbox disabled />
                            )}
                          </TableCell>
                          <TableCell sx={styles.deleteCell}>
                            <Button
                              variant="contained"
                              sx={styles.listButton}
                              size="small"
                              onClick={() => props.handleClickAreaManagerEditButton(areaManager)}
                            >
                              編集
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              sx={styles.categoryTitle}
              style={{ marginTop: '40px' }}
            >
              提供サービス
            </Typography>
            <Grid container spacing={0.5} sx={styles.categoryBlock}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  sx={styles.createButton}
                  size="small"
                  onClick={() => props.handleClickAreaServiceCreateButton()}
                >
                  追加
                </Button>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>サービス提供事業者</TableCell>
                        <TableCell>カテゴリ</TableCell>
                        <TableCell>サービス名</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.areaServiceData.map((areaService, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Button
                              variant="contained"
                              sx={styles.listButton}
                              size="small"
                              onClick={() => props.handleClickAreaServiceDeleteButton(areaService)}
                            >
                              削除
                            </Button>
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {areaService.CompanyName}
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {areaService.ServiceCategoryName}
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {areaService.ServiceName}
                          </TableCell>
                          <TableCell sx={styles.deleteCell}>
                            <Button
                              variant="contained"
                              sx={styles.listButton}
                              size="small"
                              onClick={() => props.handleClickAreaServiceEditButton(areaService)}
                            >
                              編集
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          ''
        )}
        <Link to="/settings/reserve/arealist">
          <Button variant="contained" sx={styles.button}>
            閉じる
          </Button>
        </Link>
        <Button variant="contained" sx={styles.button} onClick={props.handleClickRegist}>
          登録
        </Button>
      </Container>
      <AlertDialog
        handleDialogClose={props.handleAlertClose}
        dialogOpen={props.alertOpen}
        content={props.alertContent}
      />
    </GenericTemplate>
  );
};

export default ReserveAreaDetail;
