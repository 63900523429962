import { FC } from 'react';
import GenericTemplate from '../../../template/GenericTemplate';
import React from 'react';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import Grid from '@mui/material/Grid/Grid';
import TextField from '@mui/material/TextField/TextField';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import Button from '@mui/material/Button/Button';
import { AreaReservationFrame } from '../../../../API';
import { ErrorData } from './AcceptAdjustmentErrorData';

const styles = {
  searchField: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
  },

  label: {
    margin: 'auto',
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
  },

  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    ml: 1,
    mt: 1,
    float: 'right',
  },

  text: {
    margin: 'auto',
    pr: '10px',
    mt: '7px',
    ml: '5px',
  },

  categoryHeader: {
    fontWeight: 'bold',
  },

  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
  },

  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },

  textField: {
    width: '100%',
    '& input': {
      padding: '8px 14px',
    },
  },
};

export interface Props {
  errorAttribute: ErrorData;
  resultData: AreaReservationFrame;
  targetDate: string;
  areaName: string;
  handleChangeDetail: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickRegist: () => void;
  handleChangeCheckbox: (_: React.ChangeEvent<HTMLInputElement>) => void;
}

const AcceptAdjustment: FC<Props> = (props) => {
  const pushback = function () {
    window.history.back();
  };

  return (
    <GenericTemplate title="受入調整">
      <Container sx={styles.searchField}>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          基本情報
        </Typography>

        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>予約枠（地区名）</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography sx={styles.text}>{props.areaName}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>日付</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography sx={styles.text}>{props.targetDate}</Typography>
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          受入情報
        </Typography>

        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>予約承認済校数</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography sx={styles.text}>{props.resultData.ApprovedSchoolCount}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>予約承認済人数</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography sx={styles.text}>{props.resultData.ApprovedGuestCount}</Typography>
          </Grid>
          <Grid item xs={3} sx={{ mt: '20px' }}>
            <Typography sx={styles.label}>受付停止</Typography>
          </Grid>
          <Grid item xs={7} sx={{ display: 'flex', mt: '20px' }}>
            <Checkbox
              name="IsStopped"
              value={6}
              onChange={props.handleChangeCheckbox}
              checked={props.resultData.IsStopped == 1 ? true : false}
            />
            <Typography sx={styles.text}>※新規の予約受付けを停止します。</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>最大受入人数</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'MaxGuestCount'}
              value={props.resultData.MaxGuestCount}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="number"
              error={props.errorAttribute.MaxGuestCount.IsError}
              helperText={props.errorAttribute.MaxGuestCount.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>同時受入校数</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'MaxSchoolCount'}
              value={props.resultData.MaxSchoolCount}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="number"
              error={props.errorAttribute.MaxSchoolCount.IsError}
              helperText={props.errorAttribute.MaxSchoolCount.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>同時受入人数</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'MaxMultiSchoolGuestCount'}
              value={props.resultData.MaxMultiSchoolGuestCount}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="number"
              error={props.errorAttribute.MaxMultiSchoolGuestCount.IsError}
              helperText={props.errorAttribute.MaxMultiSchoolGuestCount.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>入村時間</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'CheckInTime'}
              value={props.resultData.CheckInTime}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="time"
              error={props.errorAttribute.CheckInTime.IsError}
              helperText={props.errorAttribute.CheckInTime.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>退村時間</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'CheckOutTime'}
              value={props.resultData.CheckOutTime}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="time"
              error={props.errorAttribute.CheckOutTime.IsError}
              helperText={props.errorAttribute.CheckOutTime.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>連泊入村時間</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'MultiStayCheckInTime'}
              value={props.resultData.MultiStayCheckInTime}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="time"
              error={props.errorAttribute.MultiStayCheckInTime.IsError}
              helperText={props.errorAttribute.MultiStayCheckInTime.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>連泊退村時間</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'MultiStayCheckOutTime'}
              value={props.resultData.MultiStayCheckOutTime}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="time"
              error={props.errorAttribute.MultiStayCheckOutTime.IsError}
              helperText={props.errorAttribute.MultiStayCheckOutTime.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>受付停止理由</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'ClosedReason'}
              value={props.resultData.ClosedReason}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="text"
              inputProps={{ maxLength: 40 }}
              error={props.errorAttribute.ClosedReason.IsError}
              helperText={props.errorAttribute.ClosedReason.ErrorMessage}
            />
          </Grid>

          <Grid item xs={10}>
            <Button variant="contained" sx={styles.button} onClick={pushback}>
              閉じる
            </Button>
            <Button variant="contained" sx={styles.button} onClick={props.handleClickRegist}>
              登録
            </Button>
          </Grid>
        </Grid>
      </Container>
    </GenericTemplate>
  );
};

export default AcceptAdjustment;
