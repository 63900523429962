import { FC } from 'react';
import { GuestCount } from 'types/GuestCount';
import GuestCountDialog from './GuestCountDialog';
import UseGuestCountDialog from './UseGuestCountDialog';

export interface Props {
  handleDialogClose: (_?: GuestCount) => void;
  dialogOpen: boolean;
  guestCount: GuestCount;
  reservationId: string;
  updateDatetime: string;
}

const GuestCountDialogContainer: FC<Props> = (props: Props) => {
  return <GuestCountDialog {...UseGuestCountDialog(props)} />;
};

export default GuestCountDialogContainer;
