import { FormControl, FormHelperText, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { GuestCount } from 'types/GuestCount';
import { ErrorData } from './GuestCountDialogErrorData';

export interface Props {
  inputData: GuestCount;
  guestCount: number;
  handleChangeInput: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlurInput: () => void;
  handleRegist: () => void;
  handleClose: () => void;
  dialogOpen: boolean;
  errorAttribute: ErrorData;
}

const styles = {
  categoryHeader: {
    fontWeight: 'bold',
  },
  itemHeader: {
    backgroundColor: '#ececec',
    fontWeight: 'bold',
  },
  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
  },
  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 1,
    ml: 1,
  },
  label: {
    mt: '7px',
  },
  categoryTitle: {
    borderLeft: 5,
    borderColor: '#0058a0',
    pl: '10px',
  },
  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },
  listButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    m: 0,
  },
};

const GuestCountDialog: FC<Props> = ({
  inputData,
  guestCount,
  handleChangeInput,
  handleBlurInput,
  handleRegist,
  handleClose,
  dialogOpen,
  errorAttribute,
}) => {
  return (
    <Dialog open={dialogOpen} onClose={() => handleClose()} maxWidth={'md'}>
      <DialogTitle>
        宿泊人数
        {errorAttribute.Header.ErrorMessage && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl component="fieldset" error={errorAttribute.Header.IsError}>
                <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                  {errorAttribute.Header.ErrorMessage}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          宿泊人数
        </Typography>
        <Grid container spacing={2} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>宿泊人数 *</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              required
              hiddenLabel
              name="GuestCount"
              value={inputData.GuestCount}
              onChange={handleChangeInput}
              onBlur={handleBlurInput}
              size="small"
              sx={styles.inputItem}
              error={errorAttribute.GuestCount.IsError}
              helperText={errorAttribute.GuestCount.ErrorMessage}
              inputProps={{ type: 'number' }}
            />
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={9}>
            <Typography variant="subtitle2">※本決定後の人数変更は、原則として減員のみです。</Typography>
            <Typography variant="subtitle2">
              ※本決定後に増員する場合は、空き状況・他校の予約状況を確認し、受入可否を慎重に検討のうえ、増員してください。
            </Typography>
          </Grid>
        </Grid>

        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" sx={styles.button} onClick={handleRegist}>
              登録
            </Button>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" sx={styles.button} onClick={handleClose}>
              閉じる
            </Button>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
export default GuestCountDialog;
