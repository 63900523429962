import { translations } from '@aws-amplify/ui';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Box, Button, Typography } from '@mui/material';
import { Amplify, Auth, I18n } from 'aws-amplify';
import APIRequestContextProvider from 'contexts/APIRequestContext';
import CommonMasterContextProvider from 'contexts/CommonMasterContext';
import React from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { Route, Routes } from 'react-router-dom';
import 'styles.css';
import AccountSetting from 'views/account';
import Home from 'views/home';
import Login from 'views/login';
import ReserveLedger from 'views/reserve/reception/ledger';
import SearchLedgerCondition from 'views/reserve/reception/ledgerSearch';
import ReserveRegist from 'views/reserve/reception/regist';
import awsExports from './aws-exports';
import ReserveDateList from 'views/reserve/reception/reserveDate';
import ReserveList from './views/reserve/management/list';
import StudentAllocateLedger from './views/reserve/management/studentLedger';
import StudentList from './views/reserve/management/studentList';
import AcceptMinkaAllDeliver from './views/reserve/management/minkaDeliver';
import StudentSimpleList from './views/reserve/management/studentSimpleList';
import MinkaAllocateLedgerSearch from './views/minka/ledgerSearch';
import MinkaAllocateLedgerRegister from './views/minka/ledgerRegister';
import MinkaList from './views/minka/management/list';
import MinkaChange from './views/reserve/management/minkaChange';
import StudentAllocation from './views/reserve/management/studentAllocation';
import StudentDetail from './views/reserve/management/studentDetail';
import StudentBulkInsert from './views/reserve/management/studentBulk';
import MinkaAllocateLedger from './views/minka/ledger';
import MinkaDetail from './views/minka/management/detail';
import CarDetail from './views/minka/management/car';
import ClosedMonthDetail from './views/minka/management/closedMonth';
import HousemateDetail from './views/minka/management/housemate';
import ReserveAreaList from './views/settings/reserve/areaList';
import ReserveAreaDetail from './views/settings/reserve/areaDetail';
import AreaManagerSelect from './views/settings/reserve/areaManager';
import AreaServiceSelect from './views/settings/reserve/areaService';
import RegionDetail from './views/settings/reserve/areaRegion';
import ServiceList from './views/settings/service/list';
import ServiceDetail from './views/settings/service/detail';
import PriceSetting from './views/settings/service/price';
import ReserveFrameSettingList from './views/settings/reserve/frameList';
import ReserveFrameRegist from './views/settings/reserve/frameRegist';
import ReserveFrameEdit from './views/settings/reserve/frameEdit';
import AcceptAdjustment from './views/settings/reserve/adjustment';
import AcceptPeriodAdjustment from './views/settings/reserve/periodAdjustment';
import SchoolList from './views/settings/school/list';
import SchoolDetail from './views/settings/school/detail';
import CompanyList from './views/settings/company/list';
import CompanyDetail from './views/settings/company/detail';
import BranchInfo from './views/settings/company/branch';
import UserList from './views/settings/user/list';
import UserDetail from './views/settings/user/detail';

I18n.putVocabularies(translations);
I18n.setLanguage('ja');

Amplify.configure(
  Object.assign({}, awsExports, {
    API: {
      graphql_headers: async () => ({
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      }),
    },
  })
);

const App: React.FC = () => {
  const { signOut, user } = useAuthenticator();
  if (user) {
    // エラー時のフォールバック用のコンポーネント
    const ErrorFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
      return (
        <div>
          <Box
            sx={{
              textAlign: 'center',
              m: '5%',
            }}
          >
            <Typography sx={{ m: '10px' }}>{error.message || 'システムエラーが発生しました。'}</Typography>
            <Button variant="outlined" onClick={signOut}>
              ログイン
            </Button>
          </Box>
        </div>
      );
    };

    return (
      <>
        <APIRequestContextProvider>
          <CommonMasterContextProvider>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="login" element={<Login />} />
                <Route path="reserve/ledger/search" element={<SearchLedgerCondition />} />
                <Route path="reserve/ledger" element={<ReserveLedger />} />
                <Route path="reserve/regist" element={<ReserveRegist />} />
                <Route path="reserve/detail" element={<ReserveRegist />} />
                <Route path="account" element={<AccountSetting />} />
                <Route path="reserve/reception/datelist" element={<ReserveDateList />} />
                <Route path="reserve/management/list" element={<ReserveList />} />
                <Route path="reserve/management/studentledger" element={<StudentAllocateLedger />} />
                <Route path="reserve/management/studentallocation" element={<StudentAllocation />} />
                <Route path="reserve/management/studentdetail" element={<StudentDetail />} />
                <Route path="reserve/management/minkachange" element={<MinkaChange />} />
                <Route path="reserve/management/studentlist" element={<StudentList />} />
                <Route path="reserve/management/studentbulk" element={<StudentBulkInsert />} />
                <Route path="reserve/management/minkadeliver" element={<AcceptMinkaAllDeliver />} />
                <Route path="reserve/management/studentsimplelist" element={<StudentSimpleList />} />
                <Route path="minka/ledger/search" element={<MinkaAllocateLedgerSearch />} />
                <Route path="minka/ledger" element={<MinkaAllocateLedger />} />
                <Route path="minka/ledger/register" element={<MinkaAllocateLedgerRegister />} />
                <Route path="minka/management/list" element={<MinkaList />} />
                <Route path="minka/management/detail" element={<MinkaDetail />} />
                <Route path="minka/management/car" element={<CarDetail />} />
                <Route path="minka/management/closedMonth" element={<ClosedMonthDetail />} />
                <Route path="minka/management/housemate" element={<HousemateDetail />} />
                <Route path="settings/reserve/arealist" element={<ReserveAreaList />} />
                <Route path="settings/reserve/areadetail" element={<ReserveAreaDetail />} />
                <Route path="settings/reserve/areamanager" element={<AreaManagerSelect />} />
                <Route path="settings/reserve/areaservice" element={<AreaServiceSelect />} />
                <Route path="settings/reserve/regiondetail" element={<RegionDetail />} />
                <Route path="settings/service/list" element={<ServiceList />} />
                <Route path="settings/service/detail" element={<ServiceDetail />} />
                <Route path="settings/service/price" element={<PriceSetting />} />
                <Route path="settings/reserve/framelist" element={<ReserveFrameSettingList />} />
                <Route path="settings/reserve/frameregist" element={<ReserveFrameRegist />} />
                <Route path="settings/reserve/frameedit" element={<ReserveFrameEdit />} />
                <Route path="settings/reserve/adjustment" element={<AcceptAdjustment />} />
                <Route path="settings/reserve/periodadjustment" element={<AcceptPeriodAdjustment />} />
                <Route path="settings/school/list" element={<SchoolList />} />
                <Route path="settings/school/detail" element={<SchoolDetail />} />
                <Route path="settings/company/list" element={<CompanyList />} />
                <Route path="settings/company/detail" element={<CompanyDetail />} />
                <Route path="settings/company/branch" element={<BranchInfo />} />
                <Route path="settings/user/list" element={<UserList />} />
                <Route path="settings/user/detail" element={<UserDetail />} />
              </Routes>
            </ErrorBoundary>
          </CommonMasterContextProvider>
        </APIRequestContextProvider>
      </>
    );
  }
  return <Login />;
};
export default App;
