import { UpdateGuestCountMutation } from 'API';
import { useAPI } from 'contexts/APIRequestContext';
import { useMaster } from 'contexts/CommonMasterContext';
import { updateGuestCount } from 'graphql/mutations';
import { useCallback, useEffect, useState } from 'react';
import { GuestCount } from 'types/GuestCount';
import { CreateEmptyErrorData, ErrorData } from './GuestCountDialogErrorData';

const UseGuestCountDialog = ({
  handleDialogClose,
  dialogOpen,
  guestCount,
  reservationId,
  updateDatetime,
}: {
  handleDialogClose: (_?: GuestCount, updateDatetime?: string) => void;
  dialogOpen: boolean;
  guestCount: GuestCount;
  reservationId: string;
  updateDatetime: string;
}) => {
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [inputData, setInputData] = useState({} as GuestCount);
  const [preGuestCount, setPreGuestCount] = useState(0);
  const master = useMaster();
  const api = useAPI();
  const [, setError] = useState<undefined>();

  useEffect(() => {
    if (dialogOpen) {
      // 初期化
      setInputData(guestCount!);
      setPreGuestCount(guestCount?.GuestCount);

      setErrorAttribute({ ...CreateEmptyErrorData() });
    }
  }, [dialogOpen]);

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  /**
   * 宿泊人数　宿泊人数更新
   * @param event イベント
   */
  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputData({ ...inputData, [event.target.name]: event.target.value && Number(event.target.value) });

    // 値が変更されたらエラー情報をクリア
    setErrorAttribute({ ...errorAttribute, [event.target.name]: { isError: false, errorMessage: '' } });
  };

  /**
   * 宿泊人数　宿泊人数フォーカスアウト
   */
  const handleBlurInput = (): void => {
    if (!inputData.GuestCount || inputData.GuestCount <= 0 || inputData.GuestCount > preGuestCount) {
      errorAttribute.GuestCount = { IsError: true, ErrorMessage: '1から' + preGuestCount + 'の範囲で入力してください' };
      setErrorAttribute({ ...errorAttribute });
      return;
    }
  };

  /**
   * 宿泊者人数更新処理
   */
  async function executeUpdateGuestCount() {
    const response = (
      (await api.graphql({
        query: updateGuestCount,
        variables: {
          data: {
            ReservationId: reservationId,
            GuestCount: inputData.GuestCount,
            UpdateDatetime: updateDatetime,
          },
        },
      })) as UpdateGuestCountMutation
    ).updateGuestCount;

    if (response?.IsSuccess) {
      handleDialogClose(inputData, response.Body!);
    } else if (response?.IsSystemError) {
      throwError(response.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  /**
   * 宿泊人数　登録
   */
  const handleRegist = (): void => {
    executeUpdateGuestCount();
  };

  /**
   * 宿泊人数　閉じる
   */
  const handleClose = (): void => {
    handleDialogClose();
  };

  return {
    inputData,
    guestCount: guestCount.GuestCount,
    handleChangeInput,
    handleBlurInput,
    handleRegist,
    handleClose,
    dialogOpen,
    errorAttribute,
  };
};
export default UseGuestCountDialog;
