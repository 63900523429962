import { Image, useAuthenticator } from '@aws-amplify/ui-react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box, { BoxProps as MuiBoxProps } from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import logo from 'assets/tama_head_logo.png';
import { FC, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { ReservationFrame } from 'views/reserve/reception/regist/ReservationFrame';
import SearchIcon from '@mui/icons-material/Search';
import { useLocation, useNavigate } from 'react-router-dom';

const drawerWidth: number = 240;

interface BoxProps extends MuiBoxProps {
  open?: boolean;
}

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open',
})<BoxProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Header: FC<any> = ({ toggleDrawer, drawerOpen }) => {
  const navigator = useNavigate();
  const location = useLocation();
  const { signOut, user } = useAuthenticator();
  const [name, setName] = useState('');
  const [reservationId, setReservationId] = useState<string>('');
  const [anchorElUser, setAnchorElUser] = useState(null);
  const open = Boolean(anchorElUser);

  const handleClickJumpButton = () => {
    if (reservationId !== null) {
      // 現在のURLが'/reserve/detail'の場合
      if (location.pathname === '/reserve/detail' || location.pathname === '/reserve/regist') {
        // ブラウザバック
        // 予約詳細画面にいる状態では初期検索が掛からないため、苦しいが画面遷移するにとどめる。
        window.history.back();
      } else {
        navigator('/reserve/detail', {
          state: {
            ReservationId: reservationId,
            ReservedDay: '9999/12/31',
          } as ReservationFrame,
        });
      }
    }
  };

  useEffect(() => {
    user.getUserData((error, userData) => {
      setName(userData?.UserAttributes.find((x) => x.Name === 'name')?.Value ?? '');
    });
  }, []);

  return (
    <StyledBox sx={{ borderBottom: 2, borderColor: '#0058a0' }} style={{ backgroundColor: 'white' }}>
      <AppBar position="static" color="transparent">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleDrawer} sx={{ mr: '36px' }}>
            <MenuIcon />
          </IconButton>
          <Image alt="logo" src={logo} padding="0" />
          <Box sx={{ margin: 'auto' }}>
            <Typography component="div" variant="body2" align="left" color="#008000" noWrap>
              OKI-JOURNEY(オキジャニ) 民泊予約管理システム
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box display="flex" alignItems="center" height="100%">
            <TextField
              label="予約 ID 指定検索"
              type="number"
              sx={{ width: '160px' }}
              size="small"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  const refreshButton = document.getElementById('rsvIdSearchButton');
                  if (refreshButton) {
                    (refreshButton as HTMLElement).focus();
                  }
                }
              }}
              onChange={(e) => setReservationId(e.target.value)}
              inputProps={{
                pattern: '\\d*', // 数値のみを許容
              }}
            />
            <IconButton
              sx={{ p: '15px', mr: '20px' }}
              aria-label="search"
              onClick={handleClickJumpButton}
              id="rsvIdSearchButton"
            >
              <SearchIcon />
            </IconButton>

            <IconButton edge="start" color="inherit" aria-label="ログアウト" onClick={signOut}>
              <LogoutIcon />
            </IconButton>

            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              // onClick={handleAccountMenu}
              color="inherit"
            >
              <AccountCircle />
              &nbsp;
              {name}&nbsp;様
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </StyledBox>
  );
};

export default Header;
