import { graphqlOperation } from 'aws-amplify';
import { useAPI } from 'contexts/APIRequestContext';
import { findAreaList } from 'graphql/queries';
import { deleteArea } from 'graphql/mutations';
import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { Prefecture, Area, AreaRequest, FindAreaListRequest, FindAreaListQuery, DeleteAreaMutation } from 'API';
import { CreateEmptyErrorData, ErrorData } from './ReserveAreaListErrorData';
import { useMaster } from 'contexts/CommonMasterContext';
import { checkAuthority } from 'util/AuthorityCheck';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';

const UseReserveAreaList = () => {
  const [prefectureCode, setPrefectureCode] = useState('');
  const [inputData, setInputData] = useState({} as FindAreaListRequest);
  const [resultData, setResultData] = useState([] as Area[]);
  const master = useMaster();
  const [prefectureList, setPrefectureList] = useState([] as Prefecture[]);
  const [, setError] = useState<undefined>();
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const api = useAPI();
  const navigator = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertDialogCallback, setAlertDialogCallback] = useState({ fn: (_: boolean) => {} });
  const { user } = useAuthenticator();
  const userData = new UserData(user);
  const defalutPage = 0;
  const defaultRowsPerPage = 25;
  const [page, setPage] = useState(defalutPage);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    setInputData({ ...inputData, [name]: value });
  };

  const handleChangePrefectureCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPrefectureCode(value);
    setInputData({ ...inputData, PrefectureCode: value });
  };
  const handleClickSearch = () => {
    fetchAreas();
    setPage(defalutPage);
    setRowsPerPage(defaultRowsPerPage);
  };

  const handleClickCreateButton = () => {
    // ローカルストレージまたはセッションストレージに検索条件を退避する
    localStorage.setItem(
      'ReserveAreaList_SearchConditions',
      JSON.stringify({ inputData, prefectureCode, rowsPerPage, page })
    );
    // 戻った時に状態を復元するフラグをセット
    sessionStorage.setItem('ReserveAreaList_ShouldRestore', 'true');

    navigator('/settings/reserve/areadetail?mode=new');
  };

  const handleClickDetailButton = (area: Area) => {
    // ローカルストレージまたはセッションストレージに検索条件を退避する
    localStorage.setItem(
      'ReserveAreaList_SearchConditions',
      JSON.stringify({ inputData, prefectureCode, rowsPerPage, page })
    );
    // 戻った時に状態を復元するフラグをセット
    sessionStorage.setItem('ReserveAreaList_ShouldRestore', 'true');

    navigator('/settings/reserve/areadetail?mode=update', {
      state: {
        __typename: 'Area',
        AreaId: area.AreaId,
      } as Area,
    });
  };

  const handleClickDeleteButton = async (area: Area): Promise<void> => {
    setAlertDialogCallback({
      fn: async (result: boolean) => {
        if (result) {
          deleteAreaAndRelatedMasters(area);
        }
      },
    });
    setAlertContent('削除します。よろしいですか？');
    setAlertOpen(true);
  };

  async function fetchAreas() {
    await fetchAndSetCompanies(inputData);
  }

  async function fetchAreasWithParams(params: FindAreaListRequest) {
    await fetchAndSetCompanies(params);
  }

  async function fetchAndSetCompanies(params: FindAreaListRequest) {
    const response = (await api.graphql(
      graphqlOperation(findAreaList, {
        data: params,
      })
    )) as FindAreaListQuery;

    if (response?.findAreaList?.Body?.length) {
      setResultData(response.findAreaList.Body as Area[]);
    } else {
      setResultData([] as Area[]);
    }
  }

  async function deleteAreaAndRelatedMasters(area: Area) {
    // エラー表示内容をクリア
    setErrorAttribute({
      ...CreateEmptyErrorData(),
      Header: { IsError: true, ErrorMessage: '' },
    });

    const areaRequest = {
      AreaId: area.AreaId,
      AreaName: area.AreaName,
      UpdateDatetime: area.UpdateDatetime,
    } as AreaRequest;

    const response = (
      (await api.graphql({
        query: deleteArea,
        variables: { data: areaRequest },
      })) as DeleteAreaMutation
    ).deleteArea;

    if (response?.IsSuccess) {
      // リストから削除に成功したレコードを除外
      let newAreaList = resultData.filter((item) => item.AreaId != area.AreaId);
      setResultData(newAreaList);
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  /**
   * 警告ダイアログ　閉じる
   * @param result 結果
   */
  const handleAlertClose = (result: boolean): void => {
    alertDialogCallback.fn(result);
    setAlertOpen(false);
  };

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('ReserveAreaList', userData.authorityCode)) {
      navigator('/');
    }

    master.waitForInitialized(() => {
      var prefectureList = master.getPrefecture().filter((x) => x.IsMinpakuArea === 1);
      setPrefectureList(prefectureList);
      // 都道府県が1件しか無ければ、1件目の都道府県コードをセット
      if (prefectureList.length === 1) {
        setPrefectureCode(prefectureList[0].PrefectureCode);
        inputData.PrefectureCode = prefectureList[0].PrefectureCode;
      }
    });

    // NOTE: 変更を検知して条件をセットしている為、初期値のある項目は起動時に条件指定する。
    inputData.IsActive = '1';

    // 詳細画面から戻ってきた場合、ローカルストレージに退避していた情報を復元する
    const shouldRestore = sessionStorage.getItem('ReserveAreaList_ShouldRestore');
    if (shouldRestore) {
      const storedConditionsString = localStorage.getItem('ReserveAreaList_SearchConditions');
      let storedConditions = JSON.parse(storedConditionsString ?? '');
      if (storedConditions) {
        setPrefectureCode(storedConditions?.inputData?.PrefectureCode ?? '');
        setInputData(storedConditions?.inputData);
        fetchAreasWithParams(storedConditions?.inputData);
        setRowsPerPage(storedConditions?.rowsPerPage);
        setPage(storedConditions?.page);
      }
    }

    // フラグをリセット
    sessionStorage.removeItem('ReserveAreaList_ShouldRestore');
  }, []);

  return {
    prefectureCode,
    inputData,
    resultData,
    errorAttribute,
    alertOpen,
    alertContent,
    prefectureList,
    page,
    rowsPerPage,
    handleChangePrefectureCode,
    handleChangeInput,
    handleClickSearch,
    handleClickDeleteButton,
    handleClickCreateButton,
    handleClickDetailButton,
    handleAlertClose,
    handleChangePage,
    handleChangeRowsPerPage,
  };
};
export default UseReserveAreaList;
