import { FC } from 'react';
import SchoolDecisionApplicationDialog from './SchoolDecisionApplicationDialog';
import UseSchoolDecisionApplicationDialog from './UseSchoolDecisionApplicationDialog';

export interface Props {
  handleDialogClose: (_: boolean) => void;
  dialogOpen: boolean;
  reservationId: string;
  updateDatetime: string;
}

const SchoolDecisionApplicationDialogContainer: FC<Props> = (props: Props) => {
  return <SchoolDecisionApplicationDialog {...UseSchoolDecisionApplicationDialog(props)} />;
};

export default SchoolDecisionApplicationDialogContainer;
