import { Container } from '@mui/material';
import Button from '@mui/material/Button/Button';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl/FormControl';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import Typography from '@mui/material/Typography/Typography';
import { MinkaClosingSettingRequest } from 'API';
import React, { FC } from 'react';
import GenericTemplate from '../../../template/GenericTemplate';
import { ErrorData } from './ClosedMonthDetailErrorData';
import Grid from '@mui/material/Grid/Grid';
import BackToTopButton from 'views/parts/BackToTop';

const styles = {
  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
  },

  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 3,
    ml: 1,
    float: 'right',
  },
  label: {
    margin: 'auto',
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
  },

  labelValue: {
    margin: 'auto',
    textAlign: 'left',
    pr: '10px',
    mt: '7px',
  },
};

export interface Props {
  closingSettingData: MinkaClosingSettingRequest;
  errorAttribute: ErrorData;
  handleChangeCheckbox: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickRegist: () => void;
}

const ClosedMonthDetail: FC<Props> = (props) => {
  const pushback = function () {
    window.history.back();
  };

  return (
    <GenericTemplate title="⺠家休業⽉詳細">
      <div>
        <BackToTopButton />
      </div>
      <Container>
        {props.errorAttribute.Header.ErrorMessage && (
          <Grid container spacing={2} sx={{ pl: 0, pb: 2 }}>
            <Grid item xs={12}>
              <FormControl component="fieldset" error={props.errorAttribute.Header.IsError}>
                <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                  {props.errorAttribute.Header.ErrorMessage}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        )}
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          休業月詳細
        </Typography>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">年度</TableCell>
                <TableCell align="center">4⽉</TableCell>
                <TableCell align="center">5⽉</TableCell>
                <TableCell align="center">6⽉</TableCell>
                <TableCell align="center">7⽉</TableCell>
                <TableCell align="center">8⽉</TableCell>
                <TableCell align="center">9⽉</TableCell>
                <TableCell align="center">10⽉</TableCell>
                <TableCell align="center">11⽉</TableCell>
                <TableCell align="center">12⽉</TableCell>
                <TableCell align="center">1⽉</TableCell>
                <TableCell align="center">2⽉</TableCell>
                <TableCell align="center">3⽉</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="td" scope="row" align="center">
                  <Typography sx={styles.labelValue}>{props.closingSettingData.SchoolYearCode}</Typography> : ''
                </TableCell>
                <TableCell component="td" scope="row" align="center">
                  <Checkbox
                    name="Period1"
                    checked={props.closingSettingData.Period1 === '1'}
                    onChange={props.handleChangeCheckbox}
                  />
                </TableCell>
                <TableCell component="td" scope="row" align="center">
                  <Checkbox
                    name="Period2"
                    checked={props.closingSettingData.Period2 === '1'}
                    onChange={props.handleChangeCheckbox}
                  />
                </TableCell>
                <TableCell component="td" scope="row" align="center">
                  <Checkbox
                    name="Period3"
                    checked={props.closingSettingData.Period3 === '1'}
                    onChange={props.handleChangeCheckbox}
                  />
                </TableCell>
                <TableCell component="td" scope="row" align="center">
                  <Checkbox
                    name="Period4"
                    checked={props.closingSettingData.Period4 === '1'}
                    onChange={props.handleChangeCheckbox}
                  />
                </TableCell>
                <TableCell component="td" scope="row" align="center">
                  <Checkbox
                    name="Period5"
                    checked={props.closingSettingData.Period5 === '1'}
                    onChange={props.handleChangeCheckbox}
                  />
                </TableCell>
                <TableCell component="td" scope="row" align="center">
                  <Checkbox
                    name="Period6"
                    checked={props.closingSettingData.Period6 === '1'}
                    onChange={props.handleChangeCheckbox}
                  />
                </TableCell>
                <TableCell component="td" scope="row" align="center">
                  <Checkbox
                    name="Period7"
                    checked={props.closingSettingData.Period7 === '1'}
                    onChange={props.handleChangeCheckbox}
                  />
                </TableCell>
                <TableCell component="td" scope="row" align="center">
                  <Checkbox
                    name="Period8"
                    checked={props.closingSettingData.Period8 === '1'}
                    onChange={props.handleChangeCheckbox}
                  />
                </TableCell>
                <TableCell component="td" scope="row" align="center">
                  <Checkbox
                    name="Period9"
                    checked={props.closingSettingData.Period9 === '1'}
                    onChange={props.handleChangeCheckbox}
                  />
                </TableCell>
                <TableCell component="td" scope="row" align="center">
                  <Checkbox
                    name="Period10"
                    checked={props.closingSettingData.Period10 === '1'}
                    onChange={props.handleChangeCheckbox}
                  />
                </TableCell>
                <TableCell component="td" scope="row" align="center">
                  <Checkbox
                    name="Period11"
                    value={props.closingSettingData.Period11 ?? ''}
                    onChange={props.handleChangeCheckbox}
                  />
                </TableCell>
                <TableCell component="td" scope="row" align="center">
                  <Checkbox
                    name="Period12"
                    value={props.closingSettingData.Period12 ?? ''}
                    onChange={props.handleChangeCheckbox}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Typography style={{ marginTop: 10 }}>※受⼊不可の⽉にチェックしてください。</Typography>
        <Button variant="contained" sx={styles.button} onClick={pushback}>
          閉じる
        </Button>
        <Button variant="contained" sx={styles.button} onClick={props.handleClickRegist}>
          登録
        </Button>
      </Container>
    </GenericTemplate>
  );
};

export default ClosedMonthDetail;
