import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Theme from 'assets/Theme';
import React from 'react';
import Drawer from 'views/parts/Drawer';
import Header from 'views/parts/Header';

export interface GenericTemplateProps {
  children: React.ReactNode;
  title?: string;
}

const GenericTemplate: React.FC<GenericTemplateProps> = ({ children, title }) => {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={Theme}>
      <Box
        sx={{
          minHeight: '100vh',
          backgroundColor: (theme) => theme.palette.background.paper,
        }}
      >
        <CssBaseline />
        <Header toggleDrawer={toggleDrawer} drawerOpen={open} />
        <Box sx={{ display: 'flex', minHeight: '92vh' }}>
          <Drawer toggleDrawer={toggleDrawer} drawerOpen={open} />
          <Box component="main" sx={{ flexGrow: 1, overflow: 'auto' }}>
            <Container component="main" maxWidth="xl" sx={{ mt: 2, mb: 2, p: 2 }}>
              {title && (
                <Typography variant="h5" color="inherit" noWrap>
                  {title}
                </Typography>
              )}
              <div style={{ margin: '15px 10px 5px 10px' }}>{children}</div>
            </Container>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default GenericTemplate;
