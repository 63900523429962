import { FC, useState } from 'react';
import GenericTemplate from '../../../template/GenericTemplate';
import React from 'react';
import { FormControl, FormHelperText, Container, TablePagination } from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import Grid from '@mui/material/Grid/Grid';
import Button from '@mui/material/Button/Button';
import Box from '@mui/material/Box/Box';
import TextField from '@mui/material/TextField/TextField';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import Radio from '@mui/material/Radio/Radio';
import RadioGroup from '@mui/material/RadioGroup/RadioGroup';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper/Paper';
import InputBase from '@mui/material/InputBase/InputBase';
import Divider from '@mui/material/Divider/Divider';
import AlertDialog from '../../alert';
import IconButton from '@mui/material/IconButton/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import SchoolSearchDialog from '../../../reserve/reception/dialog/schoolSelect';
import { CompanyBranch, MSchools, User, UserRequest, Branch } from '../../../../API';
import { SelectValue } from '../../../../types/SelectValue';
import SelectFormControl from '../../../parts/SelectFormControl';
import { UserCategoryCode } from 'constant/Constant';
import { ErrorData } from './UserListErrorData';
import BackToTopButton from 'views/parts/BackToTop';

const styles = {
  searchField: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
  },

  label: {
    margin: 'auto',
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
  },

  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
  },

  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
    '& > div > input': {
      maxHeight: '10px',
    },
  },

  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 1,
    ml: 1,
  },

  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },

  leftDetailButton: {
    width: 120,
    height: 40,
    marginRight: 3,
    float: 'left',
    mb: 3,
    background: 'white',
    color: 'black',
    border: '2px solid #203764',
  },

  detailButton: {
    width: 120,
    height: 40,
    marginRight: 3,
    float: 'right',
    mb: 3,
    background: 'white',
    color: 'black',
    border: '2px solid #203764',
  },

  textField: {
    width: '100%',
    '& input': {
      padding: '8px 14px',
    },
  },

  listButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    m: 0,
  },

  deleteCell: {
    '& > a': {
      textDecoration: 'none',
    },
  },
};

export interface Props {
  userCategoryCode: string;
  canAllDept: string;
  userName: string;
  userKanaName: string;
  userId: string;
  companyId: string;
  branchId: string;
  schoolId: string;
  isActive: string;
  inputData: UserRequest;
  resultData: User[];
  selectSchool: MSchools;
  searchSchoolOpen: boolean;
  showSchool: boolean;
  showCompany: boolean;
  companyList: CompanyBranch[];
  branchList: Branch[];
  errorAttribute: ErrorData;
  alertOpen: boolean;
  alertContent: string;
  page: number;
  rowsPerPage: number;
  handleChangeUserCategoryCode: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeCanAllDept: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeUserName: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeUserKanaName: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeUserId: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeCompany: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeBranch: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeSchool: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeIsActive: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearchSchoolOpen: () => void;
  handleSearchSchoolClose: (_?: MSchools) => void;
  handleChangeDetail: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearchUser: () => void;
  handleDeleteUser: (_: string) => void;
  handleAlertClose: (_: boolean) => void;
  saveStateToLocalStorage: () => void;
  handleChangePage: (_: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => void;
  handleChangeRowsPerPage: (_: React.ChangeEvent<HTMLInputElement>) => void;
}

const UserList: FC<Props> = ({
  userCategoryCode,
  canAllDept,
  userName,
  userKanaName,
  userId,
  companyId,
  branchId,
  schoolId,
  isActive,
  resultData,
  selectSchool,
  searchSchoolOpen,
  showSchool,
  showCompany,
  companyList,
  branchList,
  errorAttribute,
  alertOpen,
  alertContent,
  page,
  rowsPerPage,
  handleChangeUserCategoryCode,
  handleChangeCanAllDept,
  handleChangeUserName,
  handleChangeUserKanaName,
  handleChangeUserId,
  handleChangeCompany,
  handleChangeBranch,
  handleChangeSchool,
  handleChangeIsActive,
  handleSearchSchoolOpen,
  handleSearchSchoolClose,
  handleSearchUser,
  handleDeleteUser,
  handleAlertClose,
  saveStateToLocalStorage,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  return (
    <GenericTemplate title=" 利用者一覧">
      <div>
        <BackToTopButton />
      </div>
      <Container sx={styles.searchField}>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          検索条件
        </Typography>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>利用者種別</Typography>
          </Grid>
          <Grid item xs={7}>
            <RadioGroup
              row
              aria-label="UserCategoryCode"
              name="UserCategoryCode"
              defaultValue="top"
              value={userCategoryCode}
              onChange={handleChangeUserCategoryCode}
            >
              <FormControlLabel
                control={<Radio color="primary" value={UserCategoryCode.MinpakuCompany} />}
                label="民泊事業者"
              />
              <FormControlLabel control={<Radio color="primary" value={UserCategoryCode.Agent} />} label="旅行代理店" />
              <FormControlLabel
                control={<Radio color="primary" value={UserCategoryCode.ServiceCompany} />}
                label="サービス提供業者"
              />
              <FormControlLabel control={<Radio color="primary" value={UserCategoryCode.Minka} />} label="民家" />
              <FormControlLabel control={<Radio color="primary" value={UserCategoryCode.School} />} label="学校" />
            </RadioGroup>
          </Grid>
        </Grid>
        {userCategoryCode === UserCategoryCode.Agent ? (
          <Grid container spacing={0.5} sx={styles.categoryBlock}>
            <Grid item xs={3}>
              <Typography sx={styles.label}>権限範囲</Typography>
            </Grid>
            <Grid item xs={7}>
              <RadioGroup
                row
                aria-label="CanAllDept"
                name="CanAllDept"
                defaultValue="top"
                value={canAllDept}
                onChange={handleChangeCanAllDept}
              >
                <FormControlLabel value="1" control={<Radio color="primary" value={'1'} />} label="全店" />
                <FormControlLabel value="0" control={<Radio color="primary" value={'0'} />} label="自店のみ" />
                <FormControlLabel value="9" control={<Radio color="primary" value={'9'} />} label="指定しない" />
              </RadioGroup>
            </Grid>
          </Grid>
        ) : (
          ''
        )}

        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>氏名</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name="UserName"
              sx={styles.textField}
              placeholder={'氏名の全部または一部を入力してください'}
              inputProps={{ maxLength: 20 }}
              value={userName}
              onChange={handleChangeUserName}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>カナ氏名</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name="UserKanaName"
              sx={styles.textField}
              placeholder={'カナ氏名の全部または一部を入力してください'}
              inputProps={{ maxLength: 40 }}
              value={userKanaName}
              onChange={handleChangeUserKanaName}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>利用者ID</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name="UserId"
              sx={styles.textField}
              placeholder={'利用者IDの全部または一部を入力してください'}
              inputProps={{ maxLength: 20 }}
              value={userId}
              onChange={handleChangeUserId}
            />
          </Grid>
        </Grid>
        {showCompany ? (
          <Grid container spacing={0.5} sx={styles.categoryBlock}>
            <Grid item xs={3}>
              <Typography sx={styles.label}>所属会社名</Typography>
            </Grid>
            <Grid item xs={7}>
              <SelectFormControl
                value={companyId}
                selectName="CompanyId"
                selectValues={companyList.map((company, idx) => {
                  return { value: company.CompanyId, displayName: company.CompanyName } as SelectValue;
                })}
                setCondition={handleChangeCompany}
                sx={styles.inputItem}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>所属支店・営業店</Typography>
            </Grid>
            <Grid item xs={7}>
              <SelectFormControl
                value={branchId}
                selectName="BranchId"
                selectValues={branchList.map((branch, idx) => {
                  return { value: branch.BranchId, displayName: branch.BranchName } as SelectValue;
                })}
                setCondition={handleChangeBranch}
                sx={styles.inputItem}
              />
            </Grid>
          </Grid>
        ) : (
          ''
        )}
        {showSchool ? (
          <Grid container spacing={0.5} sx={styles.categoryBlock}>
            <Grid item xs={3}>
              <Typography sx={styles.label}>所属学校名</Typography>
            </Grid>
            <Grid item xs={7}>
              <Paper component="form" variant="outlined" sx={{ p: '0px 4px', display: 'flex', alignItems: 'center' }}>
                <InputBase sx={{ ml: 1, flex: 1 }} readOnly={true} value={selectSchool.SchoolName ?? ''} />
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton sx={{ p: '10px' }} aria-label="search" onClick={handleSearchSchoolOpen}>
                  <SearchIcon />
                </IconButton>
              </Paper>
              <SchoolSearchDialog handleDialogClose={handleSearchSchoolClose} dialogOpen={searchSchoolOpen} />
            </Grid>
          </Grid>
        ) : (
          ''
        )}
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>有効区分</Typography>
          </Grid>
          <Grid item xs={7}>
            <RadioGroup
              row
              aria-label="IsActive"
              name="IsActive"
              defaultValue="top"
              value={isActive}
              onChange={handleChangeIsActive}
            >
              <FormControlLabel value="1" control={<Radio color="primary" value={'1'} />} label="有効" />
              <FormControlLabel value="0" control={<Radio color="primary" value={'0'} />} label="無効" />
            </RadioGroup>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'center', '& > a': { textDecoration: 'none' } }}>
          <Link to="/settings/user/detail">
            <Button
              variant="outlined"
              sx={styles.button}
              onClick={() => {
                // 状態を保存
                saveStateToLocalStorage();
              }}
            >
              新規作成
            </Button>
          </Link>
          <Button variant="contained" sx={styles.button} onClick={handleSearchUser}>
            検索
          </Button>
        </Box>
      </Container>
      {errorAttribute.Header.ErrorMessage && (
        <Grid container spacing={2} sx={{ pl: 0, pb: 2 }}>
          <Grid item xs={12}>
            <FormControl component="fieldset" error={errorAttribute.Header.IsError}>
              <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                {errorAttribute.Header.ErrorMessage}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      )}
      <Container sx={styles.searchField}>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          利用者一覧
        </Typography>
        <TableContainer sx={{ minHeight: 300 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>利用者ID</TableCell>
                <TableCell>氏名</TableCell>
                <TableCell>カナ氏名</TableCell>
                <TableCell>所属組織名</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resultData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, index) => (
                <TableRow key={index}>
                  <TableCell component="td" scope="row">
                    <Button
                      variant="contained"
                      sx={styles.listButton}
                      size="small"
                      onClick={() => handleDeleteUser(user.UserId ?? '')}
                    >
                      削除
                    </Button>
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {user.UserId}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {user.UserName}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {user.UserKanaName}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {user.CompanyName ?? user.SchoolName}
                  </TableCell>
                  <TableCell component="td" scope="row" sx={styles.deleteCell}>
                    <Link to={'/settings/user/detail?id=' + user.UserId}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          // 状態を保存
                          saveStateToLocalStorage();
                        }}
                        sx={styles.listButton}
                        size="small"
                      >
                        詳細
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={resultData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <AlertDialog handleDialogClose={handleAlertClose} dialogOpen={alertOpen} content={alertContent} />
      </Container>
    </GenericTemplate>
  );
};

export default UserList;
