import { OptionalService } from 'API';
import { FC } from 'react';
import OptionDialog from './OptionDialog';
import UseOptionDialog from './UseOptionDialog';

export interface Props {
  handleDialogClose: (_?: OptionalService) => void;
  dialogOpen: boolean;
  optionalService: OptionalService;
  reservationId: string;
  guestCount: number;
  checkInDate: string;
  updateDatetime: string;
  mode: string;
}

const OptionDialogContainer: FC<Props> = (props: Props) => {
  return <OptionDialog {...UseOptionDialog(props)} />;
};

export default OptionDialogContainer;
