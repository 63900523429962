import React, { useState, useEffect } from 'react';
import { scrollToTop } from 'util/Scroll';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import IconButton from '@mui/material/IconButton';

const BackToTop: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 20) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <>
      {isVisible && (
        <IconButton onClick={scrollToTop} style={{ position: 'fixed', bottom: '20px', right: '10px' }}>
          <ArrowCircleUpIcon style={{ fontSize: 43, color: '#0058a0' }} />
        </IconButton>
      )}
    </>
  );
};

export default BackToTop;
