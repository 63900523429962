import { ReservationAgent, ReservationDay } from 'API';
import { FC } from 'react';
import AgentDialog from './AgentDialog';
import UseAgentDialog from './UseAgentDialog';

export interface Props {
  handleDialogClose: (_?: { updateDatetime: string; agent: ReservationAgent }) => void;
  dialogOpen: boolean;
  reservationId: string;
  updateDatetime: string;
  agent: ReservationAgent;
  mode: string;
  reservationDays: ReservationDay[];
  schoolConfirmed: boolean;
}

const AgentDialogContainer: FC<Props> = (props: Props) => {
  return <AgentDialog {...UseAgentDialog(props)} />;
};

export default AgentDialogContainer;
