import { FC, useState } from 'react';
import GenericTemplate from '../../../template/GenericTemplate';
import React from 'react';
import { Container, FormHelperText } from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import Grid from '@mui/material/Grid/Grid';
import SelectFormControl from '../../../parts/SelectFormControl';
import { SelectValue } from '../../../../types/SelectValue';
import FormControl from '@mui/material/FormControl/FormControl';
import Button from '@mui/material/Button/Button';
import Box from '@mui/material/Box/Box';
import TextField from '@mui/material/TextField/TextField';
import FormGroup from '@mui/material/FormGroup/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TablePagination from '@mui/material/TablePagination/TablePagination';
import { Link } from 'react-router-dom';
import { MSchools, SchoolRequestData } from '../../../../API';
import { CodeKind } from '../../../../constant/Constant';
import { useMaster } from '../../../../contexts/CommonMasterContext';
import Radio from '@mui/material/Radio/Radio';
import RadioGroup from '@mui/material/RadioGroup/RadioGroup';
import { ErrorData } from './SchoolListErrorData';
import AlertDialog from '../alert';
import BackToTopButton from 'views/parts/BackToTop';

const styles = {
  searchField: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
  },

  label: {
    margin: 'auto',
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
  },

  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
  },

  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
    '& > div > input': {
      maxHeight: '10px',
    },
  },

  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 1,
    ml: 1,
  },

  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },

  textField: {
    width: '100%',
    '& input': {
      padding: '8px 14px',
    },
  },

  listButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    m: 0,
  },

  deleteCell: {
    '& > a': {
      textDecoration: 'none',
    },
  },
};

export interface Props {
  inputData: SchoolRequestData;
  resultData: MSchools[];
  errorAttribute: ErrorData;
  alertOpen: boolean;
  alertContent: string;
  page: number;
  rowsPerPage: number;
  handleChangeInput: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeCheckbox: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickSearch: () => void;
  handleClickDelete: (_: MSchools) => void;
  handleAlertClose: (_: boolean) => void;
  saveStateToLocalStorage: () => void;
  handleChangePage: (_: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => void;
  handleChangeRowsPerPage: (_: React.ChangeEvent<HTMLInputElement>) => void;
}

const SchoolList: FC<Props> = (props) => {
  const master = useMaster();
  return (
    <GenericTemplate title="学校一覧">
      <div>
        <BackToTopButton />
      </div>
      {props.errorAttribute.Header.ErrorMessage && (
        <Grid container spacing={2} sx={{ pl: 0, pb: 2 }}>
          <Grid item xs={12}>
            <FormControl component="fieldset" error={props.errorAttribute.Header.IsError}>
              <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                {props.errorAttribute.Header.ErrorMessage}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      )}
      <Container sx={styles.searchField}>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          検索条件
        </Typography>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>都道府県</Typography>
          </Grid>
          <Grid item xs={7}>
            <SelectFormControl
              value={props.inputData.PrefectureCode ?? ''}
              selectName="PrefectureCode"
              selectValues={master.getPrefecture().map((prefecture, idx) => {
                return { value: prefecture.PrefectureCode, displayName: prefecture.PrefectureName } as SelectValue;
              })}
              setCondition={props.handleChangeInput}
              sx={styles.inputItem}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>所在地</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name="Address"
              sx={styles.textField}
              placeholder={'住所の一部を入力してください'}
              value={props.inputData.Address}
              onChange={props.handleChangeInput}
              inputProps={{ maxLength: 40 }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>学校名</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name="SchoolName"
              sx={styles.textField}
              placeholder={'学校名の一部を入力してください'}
              value={props.inputData.SchoolName}
              onChange={props.handleChangeInput}
              inputProps={{ maxLength: 40 }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>学校カナ名</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name="SchoolKanaName"
              sx={styles.textField}
              placeholder={'学校カナ名の一部を入力してください'}
              value={props.inputData.SchoolKanaName}
              onChange={props.handleChangeInput}
              inputProps={{ maxLength: 40 }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>学校コード</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name="SchoolCode"
              sx={styles.textField}
              placeholder={'学校コードの一部を入力してください'}
              value={props.inputData.SchoolCode}
              onChange={props.handleChangeInput}
              inputProps={{ maxLength: 13 }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>学校種別</Typography>
          </Grid>
          <Grid item xs={7}>
            <FormControl component="fieldset">
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="SchoolCategoryList"
                      value={'B1'}
                      onChange={props.handleChangeCheckbox}
                      size="small"
                      checked={
                        props.inputData.SchoolCategoryList
                          ? props.inputData.SchoolCategoryList.indexOf('B1') > -1
                          : false
                      }
                    />
                  }
                  label={master.getCodeName(CodeKind.SchoolCategoryCode, 'B1')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="SchoolCategoryList"
                      value={'C1'}
                      onChange={props.handleChangeCheckbox}
                      size="small"
                      checked={
                        props.inputData.SchoolCategoryList
                          ? props.inputData.SchoolCategoryList.indexOf('C1') > -1
                          : false
                      }
                    />
                  }
                  label={master.getCodeName(CodeKind.SchoolCategoryCode, 'C1')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="SchoolCategoryList"
                      value={'C2'}
                      onChange={props.handleChangeCheckbox}
                      size="small"
                      checked={
                        props.inputData.SchoolCategoryList
                          ? props.inputData.SchoolCategoryList.indexOf('C2') > -1
                          : false
                      }
                    />
                  }
                  label={master.getCodeName(CodeKind.SchoolCategoryCode, 'C2')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="SchoolCategoryList"
                      value={'D1'}
                      onChange={props.handleChangeCheckbox}
                      size="small"
                      checked={
                        props.inputData.SchoolCategoryList
                          ? props.inputData.SchoolCategoryList.indexOf('D1') > -1
                          : false
                      }
                    />
                  }
                  label={master.getCodeName(CodeKind.SchoolCategoryCode, 'D1')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="SchoolCategoryList"
                      value={'D2'}
                      onChange={props.handleChangeCheckbox}
                      size="small"
                      checked={
                        props.inputData.SchoolCategoryList
                          ? props.inputData.SchoolCategoryList.indexOf('D2') > -1
                          : false
                      }
                    />
                  }
                  label={master.getCodeName(CodeKind.SchoolCategoryCode, 'D2')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="SchoolCategoryList"
                      value={'E1'}
                      onChange={props.handleChangeCheckbox}
                      size="small"
                      checked={
                        props.inputData.SchoolCategoryList
                          ? props.inputData.SchoolCategoryList.indexOf('E1') > -1
                          : false
                      }
                    />
                  }
                  label={master.getCodeName(CodeKind.SchoolCategoryCode, 'E1')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="SchoolCategoryList"
                      value={'F1'}
                      onChange={props.handleChangeCheckbox}
                      size="small"
                      checked={
                        props.inputData.SchoolCategoryList
                          ? props.inputData.SchoolCategoryList.indexOf('F1') > -1
                          : false
                      }
                    />
                  }
                  label={master.getCodeName(CodeKind.SchoolCategoryCode, 'F1')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="SchoolCategoryList"
                      value={'F2'}
                      onChange={props.handleChangeCheckbox}
                      size="small"
                      checked={
                        props.inputData.SchoolCategoryList
                          ? props.inputData.SchoolCategoryList.indexOf('F2') > -1
                          : false
                      }
                    />
                  }
                  label={master.getCodeName(CodeKind.SchoolCategoryCode, 'F2')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="SchoolCategoryList"
                      value={'G1'}
                      onChange={props.handleChangeCheckbox}
                      size="small"
                      checked={
                        props.inputData.SchoolCategoryList
                          ? props.inputData.SchoolCategoryList.indexOf('G1') > -1
                          : false
                      }
                    />
                  }
                  label={master.getCodeName(CodeKind.SchoolCategoryCode, 'G1')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="SchoolCategoryList"
                      value={'H1'}
                      onChange={props.handleChangeCheckbox}
                      size="small"
                      checked={
                        props.inputData.SchoolCategoryList
                          ? props.inputData.SchoolCategoryList.indexOf('H1') > -1
                          : false
                      }
                    />
                  }
                  label={master.getCodeName(CodeKind.SchoolCategoryCode, 'H1')}
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>設置区分</Typography>
          </Grid>
          <Grid item xs={7}>
            <FormControl component="fieldset">
              <FormGroup row>
                {master.getCodeKind(CodeKind.ClassificationCode).map((codeMaster, idx) => (
                  <FormControlLabel
                    key={idx}
                    control={
                      <Checkbox
                        name="ClassificationList"
                        value={codeMaster.Code}
                        onChange={props.handleChangeCheckbox}
                        checked={
                          props.inputData.ClassificationList
                            ? props.inputData.ClassificationList.indexOf(codeMaster.Code) > -1
                            : false
                        }
                        size="small"
                      />
                    }
                    label={codeMaster.Name}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>有効区分</Typography>
          </Grid>
          <Grid item xs={7}>
            <FormControl component="fieldset">
              <FormGroup style={{ display: 'block' }}>
                <RadioGroup row aria-label="position" defaultValue="1">
                  <FormControlLabel
                    value="1"
                    control={
                      <Radio
                        color="primary"
                        name="IsActive"
                        value={'1'}
                        onChange={props.handleChangeInput}
                        checked={props.inputData.IsActive === '1'}
                      />
                    }
                    label="有効"
                  />
                  <FormControlLabel
                    value="0"
                    control={
                      <Radio
                        color="primary"
                        name="IsActive"
                        value={'0'}
                        onChange={props.handleChangeInput}
                        checked={props.inputData.IsActive === '0'}
                      />
                    }
                    label="無効"
                  />
                </RadioGroup>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'center', '& > a': { textDecoration: 'none' } }}>
          <Link to={'/settings/school/detail'}>
            <Button
              variant="outlined"
              sx={styles.button}
              onClick={() => {
                props.saveStateToLocalStorage();
              }}
            >
              新規作成
            </Button>
          </Link>
          <Button variant="contained" sx={styles.button} onClick={props.handleClickSearch}>
            検索
          </Button>
        </Box>
      </Container>
      <Container sx={styles.searchField}>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          学校一覧
        </Typography>
        <TableContainer sx={{ minHeight: 300 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>学校ID</TableCell>
                <TableCell>学校名</TableCell>
                <TableCell>学校カナ名</TableCell>
                <TableCell>住所</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.resultData
                .slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage)
                .map((school, index) => (
                  <TableRow key={index}>
                    <TableCell component="td" scope="row">
                      <Button
                        variant="contained"
                        sx={styles.listButton}
                        size="small"
                        onClick={() => props.handleClickDelete(school)}
                      >
                        削除
                      </Button>
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {school.SchoolId}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {school.SchoolName}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {school.SchoolKanaName}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {school.Address}
                    </TableCell>

                    <TableCell component="td" scope="row" sx={styles.deleteCell}>
                      <Link to={'/settings/school/detail?id=' + school.SchoolId}>
                        <Button
                          variant="contained"
                          sx={styles.listButton}
                          size="small"
                          onClick={() => {
                            props.saveStateToLocalStorage();
                          }}
                        >
                          詳細
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[7, 25, 100]}
          component="div"
          count={props.resultData.length}
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          onPageChange={props.handleChangePage}
          onRowsPerPageChange={props.handleChangeRowsPerPage}
        />
      </Container>
      <AlertDialog
        handleDialogClose={props.handleAlertClose}
        dialogOpen={props.alertOpen}
        content={props.alertContent}
      />
    </GenericTemplate>
  );
};

export default SchoolList;
