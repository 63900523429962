import { useState, useEffect } from 'react';
import { CreateEmptyErrorData } from './SearchLedgerErrorData';
import { useNavigate } from 'react-router-dom';
import { useMaster } from 'contexts/CommonMasterContext';
import { Prefecture, SchoolYearSetting } from 'API';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';
import { checkAuthority } from 'util/AuthorityCheck';
import { AuthorityCode } from 'constant/Constant';

const UseSearchLedgerCondition = () => {
  const [prefectureCode, setPrefectureCode] = useState('');
  const [displayStartDate, setDisplayStartDate] = useState(null as Date | null);
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [prefectureList, setPrefectureList] = useState([] as Prefecture[]);
  const [schoolYearList, setSchoolYearList] = useState([] as SchoolYearSetting[]);
  const navigate = useNavigate();
  const master = useMaster();
  const { user } = useAuthenticator();
  const userData = new UserData(user);

  const handleChangePrefectureCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrefectureCode(event.target.value);
    setErrorAttribute({ ...errorAttribute, PrefectureCode: { IsError: false, ErrorMessage: '' } });
  };

  const handleChangeDisplayStartDate = (value: Date | null) => {
    setDisplayStartDate(value);
    setErrorAttribute({ ...errorAttribute, DisplayStartDate: { IsError: false, ErrorMessage: '' } });
  };

  const handleClickSearch = () => {
    // 入力値チェック
    if (!prefectureCode) {
      setErrorAttribute({ ...errorAttribute, PrefectureCode: { IsError: true, ErrorMessage: '入力してください' } });
      return;
    }
    if (!displayStartDate) {
      setErrorAttribute({ ...errorAttribute, DisplayStartDate: { IsError: true, ErrorMessage: '入力してください' } });
      return;
    }

    const schoolYear = schoolYearList
      ?.filter(
        (x) =>
          new Date(x.SchoolYear.StartDate) <= displayStartDate && displayStartDate <= new Date(x.SchoolYear.EndDate)
      )
      .shift();

    if (
      userData.authorityCode != AuthorityCode.ReserveAdministrator &&
      schoolYear &&
      new Date(schoolYear.SchoolYear.OpenDatetime) > new Date() &&
      schoolYear.SchoolYear.SchoolYearCode != '2024' // リリース時は、2024年度に関して参照のみOKとするため、本チェックは通す
    ) {
      // schoolYear が null や undefined ではなく、OpenDatetime が現在日時より後の場合の処理
      const errorMessage = `ご指定の日付は未だ台帳がオープンされていません。オープン予定は、${schoolYear.SchoolYear.OpenDatetime} です。`;
      setErrorAttribute({
        ...errorAttribute,
        DisplayStartDate: { IsError: true, ErrorMessage: errorMessage },
      });
      return;
    }

    // 予約台帳を呼出し
    navigate(
      `/reserve/ledger?PrefectureCode=${prefectureCode}&DisplayStartDate=${
        displayStartDate.getFullYear() + '-' + (displayStartDate.getMonth() + 1) + '-' + displayStartDate.getDate()
      }`
    );
  };

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('ReserveLedgerSearch', userData.authorityCode)) {
      navigate('/');
    }
    master.waitForInitialized(() => {
      var prefectureList = master.getPrefecture().filter((x) => x.IsMinpakuArea === 1);
      setPrefectureList(prefectureList);
      // 都道府県が1件しか無ければ、1件目の都道府県コードをセット
      if (prefectureList.length === 1) {
        setPrefectureCode(prefectureList[0].PrefectureCode);
      }
      // 初期表示時の日付は2年後
      var initialDate = new Date(Date.now());
      initialDate.setFullYear(initialDate.getFullYear() + 2);
      setDisplayStartDate(initialDate);
      setSchoolYearList(master.getSchoolYearSettings());
    });
  }, []);

  return {
    prefectureCode,
    displayStartDate,
    handleChangePrefectureCode,
    handleChangeDisplayStartDate,
    handleClickSearch,
    prefectureList,
    errorAttribute,
  };
};
export default UseSearchLedgerCondition;
