import { useAuthenticator } from '@aws-amplify/ui-react';
import { useMaster } from 'contexts/CommonMasterContext';
import { UserData } from 'models/UserData';
import { graphqlOperation } from 'aws-amplify';
import { useAPI } from 'contexts/APIRequestContext';
import { findMinkaList } from 'graphql/queries';
import {
  DeleteMinkaMutation,
  FindMinkaListRequest,
  FindMinkaListQuery,
  Prefecture,
  Area,
  Minka,
  Region,
  MinkaRequest,
  MinkaBasicRequest,
} from 'API';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteMinka } from 'graphql/mutations';
import { CreateEmptyErrorData, ErrorData } from './MinkaListErrorData';
import { checkAuthority } from 'util/AuthorityCheck';

const UseMinkaList = () => {
  const [inputData, setInputData] = useState({} as FindMinkaListRequest);
  const [resultData, setResultData] = useState([] as Minka[]);
  const [companyName, setCompanyName] = useState('');
  const [branchName, setBranchName] = useState('');
  const [prefectureCode, setPrefectureCode] = useState('');
  const [areaId, setAreaId] = useState('');
  const [minkaNo, setMinkaNo] = useState('');
  const [minkaName, setMinkaName] = useState('');
  const [isActive, setIsActive] = useState('1');
  const [prefectureList, setPrefectureList] = useState([] as Prefecture[]);
  const [areaList, setAreaList] = useState([] as Area[]);
  const [regionCode, setRegionCode] = useState('');
  const [regionList, setRegionList] = useState([] as Region[]);
  const [, setError] = useState<undefined>();
  const { user } = useAuthenticator();
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const userData = new UserData(user);
  const master = useMaster();
  const api = useAPI();
  const navigator = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertDialogCallback, setAlertDialogCallback] = useState({ fn: (_: boolean) => {} });

  const defalutPage = 0;
  const defaultRowsPerPage = 25;
  const [page, setPage] = useState(defalutPage);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('MinkaList', userData.authorityCode)) {
      navigator('/');
    }

    master.waitForInitialized(() => {
      setPrefectureList(master.getPrefecture());
      setCompanyName(master.getCompanyName(userData.companyId));
      setBranchName(master.getBranchName(userData.companyId, userData.branchId));
    });

    // 詳細画面から戻ってきた場合、ローカルストレージに退避していた情報を復元する
    const shouldRestore = sessionStorage.getItem('MinkaList_ShouldRestore');
    if (shouldRestore) {
      const storedConditionsString = localStorage.getItem('MinkaList_SearchConditions');
      let storedConditions = JSON.parse(storedConditionsString ?? '');
      if (storedConditions) {
        setPrefectureCode(storedConditions?.inputData?.PrefectureCode ?? '');
        setAreaPullDownList(storedConditions?.inputData?.PrefectureCode ?? '');
        setAreaId(storedConditions?.inputData?.AreaId ?? '');
        setRegionPullDownList(storedConditions?.inputData?.AreaId ?? '');
        setRegionCode(storedConditions?.inputData?.RegionCode ?? '');
        setMinkaNo(storedConditions?.inputData?.MinkaNo ?? '');
        setMinkaName(storedConditions?.inputData?.MinkaName ?? '');
        setIsActive(storedConditions?.inputData?.IsActive ?? '');
        setInputData(storedConditions?.inputData);
        fetchMinkasWithParams(storedConditions?.inputData);
        setRowsPerPage(storedConditions?.rowsPerPage);
        setPage(storedConditions?.page);
      }
    } else {
      // NOTE: 変更を検知して条件をセットしている為、初期値のある項目は起動時に条件指定する。
      inputData.IsActive = '1';
      inputData.ManagingCompanyId = userData.companyId;
      inputData.ManagingBranchId = userData.branchId;
    }

    // フラグをリセット
    sessionStorage.removeItem('MinkaList_ShouldRestore');
  }, []);

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    setInputData({ ...inputData, [name]: value });
  };

  const handleChangePrefecture = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrefectureCode(event.target.value);
    setAreaId('');
    setRegionCode('');
    inputData.AreaId = '';
    inputData.RegionCode = '';
    setInputData({
      ...inputData,
      PrefectureCode: event.target.value,
      AreaId: '',
      RegionCode: '',
    });
    setAreaPullDownList(event.target.value);
    setRegionList([] as Region[]);
  };
  const handleChangeArea = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeInput(event);
    setAreaId(event.target.value);
    setRegionCode('');
    inputData.RegionCode = '';
    setInputData({
      ...inputData,
      AreaId: event.target.value,
      RegionCode: '',
    });

    setRegionPullDownList(event.target.value);
  };

  const setAreaPullDownList = (prefectureCode: string) => {
    const _areaList = master.getAreasByPrefectureCode(prefectureCode) as Area[];
    if (_areaList) {
      setAreaList(_areaList);
    } else {
      setAreaList([] as Area[]);
    }
  };

  const setRegionPullDownList = (areaId: string) => {
    const _regionList = master.getRegionsByAreaId(areaId) as Region[];
    if (_regionList) {
      setRegionList(_regionList);
    } else {
      setRegionList([] as Region[]);
    }
  };

  const handleChangeRegion = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegionCode(event.target.value);
    handleChangeInput(event);
  };

  const handleChangeMinkaNo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setMinkaNo(value);
    setInputData({ ...inputData, MinkaNo: value });
  };

  const handleChangeMinkaName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setMinkaName(value);
    setInputData({ ...inputData, MinkaName: value });
  };

  const handleChangeIsActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setIsActive(value);
    setInputData({ ...inputData, IsActive: value });
  };

  const handleClickSearch = () => {
    fetchMinkas();
    setPage(defalutPage);
    setRowsPerPage(defaultRowsPerPage);
  };

  const handleClickCreateButton = () => {
    // ローカルストレージまたはセッションストレージに検索条件を退避する
    localStorage.setItem('MinkaList_SearchConditions', JSON.stringify({ inputData, rowsPerPage, page }));
    // 戻った時に状態を復元するフラグをセット
    sessionStorage.setItem('MinkaList_ShouldRestore', 'true');

    navigator('/minka/management/detail?mode=new');
  };

  const handleClickDetailButton = (minka: Minka) => {
    // ローカルストレージまたはセッションストレージに検索条件を退避する
    localStorage.setItem('MinkaList_SearchConditions', JSON.stringify({ inputData, rowsPerPage, page }));
    // 戻った時に状態を復元するフラグをセット
    sessionStorage.setItem('MinkaList_ShouldRestore', 'true');

    navigator('/minka/management/detail?mode=update', {
      state: {
        __typename: 'Minka',
        MinkaId: minka.MinkaId,
      } as Minka,
    });
  };

  const handleDelete = async (minka: Minka): Promise<void> => {
    setAlertDialogCallback({
      fn: async (result: boolean) => {
        if (result) {
          deleteMnk(minka);
        }
      },
    });
    setAlertContent('削除します。よろしいですか？');
    setAlertOpen(true);
  };

  async function deleteMnk(minka: Minka) {
    // エラー表示内容をクリア
    setErrorAttribute({
      ...CreateEmptyErrorData(),
      Header: { IsError: true, ErrorMessage: '' },
    });

    const minkaBasicRequest = {
      MinkaId: minka.MinkaId,
      UpdateDatetime: minka.UpdateDatetime,
    } as MinkaBasicRequest;

    const minkaRequest = {
      Minka: minkaBasicRequest,
    } as MinkaRequest;

    const response = (
      (await api.graphql({
        query: deleteMinka,
        variables: { data: minkaRequest },
      })) as DeleteMinkaMutation
    ).deleteMinka;

    if (response?.IsSuccess) {
      // リストから削除に成功したレコードを除外
      let newMinkaList = resultData.filter((item) => item.MinkaId != minka.MinkaId);
      setResultData(newMinkaList);
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  async function fetchMinkas() {
    await fetchAndSetCompanies(inputData);
  }

  async function fetchMinkasWithParams(params: FindMinkaListRequest) {
    await fetchAndSetCompanies(params);
  }

  async function fetchAndSetCompanies(params: FindMinkaListRequest) {
    const response = (await api.graphql(
      graphqlOperation(findMinkaList, {
        data: params,
      })
    )) as FindMinkaListQuery;

    if (response?.findMinkaList?.Body?.length) {
      setResultData(response.findMinkaList.Body as Minka[]);
    } else {
      setResultData([] as Minka[]);
    }
  }

  /**
   * 警告ダイアログ　閉じる
   * @param result 結果
   */
  const handleAlertClose = (result: boolean): void => {
    alertDialogCallback.fn(result);
    setAlertOpen(false);
  };

  return {
    companyName,
    branchName,
    prefectureCode,
    areaId,
    regionCode,
    minkaNo,
    minkaName,
    isActive,
    prefectureList,
    areaList,
    regionList,
    resultData,
    errorAttribute,
    alertOpen,
    alertContent,
    page,
    rowsPerPage,
    handleChangePrefecture,
    handleChangeArea,
    handleChangeRegion,
    handleChangeMinkaNo,
    handleChangeMinkaName,
    handleChangeIsActive,
    handleClickSearch,
    handleDelete,
    handleClickCreateButton,
    handleClickDetailButton,
    handleAlertClose,
    handleChangePage,
    handleChangeRowsPerPage,
  };
};
export default UseMinkaList;
