import React, { useEffect, useState, useCallback } from 'react';
import { registMinkaClosingSetting } from 'graphql/mutations';
import { useLocation } from 'react-router';
import { useAPI } from 'contexts/APIRequestContext';
import { MinkaClosingSettingRequest, RegistMinkaClosingSettingMutation } from 'API';
import { CreateEmptyErrorData, ErrorData } from './ClosedMonthDetailErrorData';
import { useNavigate } from 'react-router-dom';
import { checkAuthority } from 'util/AuthorityCheck';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';

const UseClosedMonthDetail = () => {
  const api = useAPI();
  const location = useLocation();
  const minkaClosingSettingPara = location.state as MinkaClosingSettingRequest;
  const [closingSettingData, setClosingSettingData] = useState({} as MinkaClosingSettingRequest);
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [, setError] = useState<undefined>();
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);
  const navigator = useNavigate();
  const { user } = useAuthenticator();
  const userData = new UserData(user);

  /**
   * ブラウザバック
   */
  const back = (): void => {
    window.history.back();
  };
  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    let checked = '0';
    if (event.target.checked) {
      checked = '1';
    }
    setClosingSettingData({
      ...closingSettingData,
      [event.target.name]: checked,
    });
  };

  const handleClickRegist = () => {
    registResident();
  };

  async function registResident() {
    const response = (
      (await api.graphql({
        query: registMinkaClosingSetting,
        variables: { data: closingSettingData },
      })) as RegistMinkaClosingSettingMutation
    ).registMinkaClosingSetting;

    if (response?.IsSuccess) {
      back();
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('ClosedMonthDetail', userData.authorityCode)) {
      navigator('/');
    }
    setClosingSettingData(minkaClosingSettingPara as MinkaClosingSettingRequest);
  }, []);

  return {
    closingSettingData,
    errorAttribute,
    handleChangeCheckbox,
    handleClickRegist,
  };
};
export default UseClosedMonthDetail;
