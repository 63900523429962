import { useCallback, useState, useEffect } from 'react';
import { graphqlOperation } from 'aws-amplify';
import React from 'react';
import { findNonBusinessDay } from 'graphql/queries';
import {
  BulkAreaReservationFrameRequest,
  BulkUpdateNonBusinessDayMutation,
  Area,
  FindNonBusinessDayRequest,
  FindNonBusinessDayResponse,
  FindNonBusinessDayQuery,
  NonBusinessDayList,
} from '../../../../API';
import { useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useMaster } from 'contexts/CommonMasterContext';
import { useAPI } from 'contexts/APIRequestContext';
import { bulkUpdateNonBusinessDay } from 'graphql/mutations';
import { CreateEmptyErrorData, ErrorData } from './ReserveFrameEditErrorData';
import { useNavigate } from 'react-router-dom';
import { checkAuthority } from 'util/AuthorityCheck';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';

const queryString = require('query-string');

const UseReserveFrameEdit = () => {
  const location = useLocation();
  var areaId = queryString.parse(location.search).areaId;
  var year = queryString.parse(location.search).year;
  var month = queryString.parse(location.search).month;
  var firstDate = new Date(year, month - 1, 1);
  var lastDate = new Date(year, month, 0);
  var numDays = lastDate.getDate();
  var calendar: any[] = [];
  const [resultData, setResultData] = useState({} as NonBusinessDayList);
  const [checkedDay, setCheckedDay] = useState([] as number[]);
  const master = useMaster();
  const [, setError] = useState<undefined>();
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const api = useAPI();
  const [areaReservationFrameData, setAreaReservationFrameData] = useState({} as Area);
  const navigator = useNavigate();
  const { user } = useAuthenticator();
  const userData = new UserData(user);

  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  /**
   * ブラウザバック
   */
  const back = (): void => {
    // 一覧に戻った際に条件保持、再検索結果が画面に反映されないため、リンク指定とする
    // window.history.back();
    navigator('/settings/reserve/framelist');
  };

  const makeCalendarData = () => {
    // FIXME: 初期起動時に、なぜか本メソッドが複数回呼ばれ、
    // カレンダーが多重に生成されるため、はじめにカレンダをクリアする。
    if (calendar.length > 0) {
      return;
    }
    var cal = [];
    var key = 0;
    while (key < firstDate.getDay()) {
      cal.push('');
      key++;
    }
    for (var i = 1; i < numDays + 1; i++) {
      cal.push(i);
    }

    if (cal.length % 7 != 0) {
      var last = 7 - (cal.length % 7);
      for (var i = 0; i < last; i++) {
        cal.push('');
      }
    }

    var week = [];
    for (var i = 0; i < cal.length; i++) {
      week.push(cal[i]);
      if ((i + 1) % 7 == 0) {
        calendar.push(week);
        week = [];
      }
    }
  };

  const handleCheckedDay = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const value = Number.parseInt(event.target.value);

    let list;
    list = checkedDay;

    if (!list) {
      list = [] as number[];
    }

    if (checked) {
      if (!list?.find((x) => x == value)) {
        list = [value, ...list];
      }
    } else {
      if (list?.find((x) => x == value)) {
        list = list.filter((x) => x != value);
      }
    }
    setCheckedDay(list);
  };

  const selectAllDay = () => {
    let list = [] as number[];
    let i = numDays;
    while (i > 0) {
      list = [i, ...list];
      i--;
    }
    setCheckedDay(list);
  };

  const deselectAllDay = () => {
    let list = [] as number[];
    setCheckedDay(list);
  };

  const handleClickRegist = async () => {
    const areaReservationFrame = {
      AreaId: String(areaId),
      Year: String(year),
      Month: String(month),
      StartDate: firstDate.toLocaleDateString(),
      EndDate: lastDate.toLocaleDateString(),
      NonBusinessDays: checkedDay.map((i) => String(i)),
    } as BulkAreaReservationFrameRequest;

    const response = (
      (await api.graphql({
        query: bulkUpdateNonBusinessDay,
        variables: { data: areaReservationFrame },
      })) as BulkUpdateNonBusinessDayMutation
    ).bulkUpdateNonBusinessDay;

    if (response?.IsSuccess) {
      back();
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  };

  async function fetchData() {
    const response = (await api.graphql(
      graphqlOperation(findNonBusinessDay, {
        data: {
          ['AreaId']: areaId,
          ['Year']: year,
          ['Month']: month,
        },
      })
    )) as FindNonBusinessDayQuery;

    if (response?.findNonBusinessDay?.IsSuccess) {
      if (response?.findNonBusinessDay?.Body) {
        let nonBussinessDay = response.findNonBusinessDay.Body as NonBusinessDayList;
        setCheckedDay(nonBussinessDay.NonBusinessDays == null ? [] : nonBussinessDay.NonBusinessDays);
      }
    } else if (response?.findNonBusinessDay?.IsSystemError) {
      throwError(response?.findNonBusinessDay?.ErrorData ?? '');
    } else if (response?.findNonBusinessDay?.IsInputCheckError && response?.findNonBusinessDay?.ErrorData) {
      throwError(response?.findNonBusinessDay?.ErrorData ?? '');
    } else if (response?.findNonBusinessDay?.ErrorData) {
      throwError(response?.findNonBusinessDay?.ErrorData ?? '');
    } else {
      throwError();
    }
  }

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('ReserveFrameEdit', userData.authorityCode)) {
      navigator('/');
    }

    master.waitForInitialized(() => {
      setAreaReservationFrameData(master.getAreas().filter((x) => x.AreaId === areaId)[0]);
    });

    fetchData();
  }, []);

  return {
    areaReservationFrameData,
    year,
    month,
    firstDate,
    lastDate,
    numDays,
    calendar,
    checkedDay,
    resultData,
    setCheckedDay,
    makeCalendarData,
    handleCheckedDay,
    selectAllDay,
    deselectAllDay,
    handleClickRegist,
  };
};
export default UseReserveFrameEdit;
