import FormErrorAttribute from 'types/FormErrorAttribute';

export interface ErrorData {
  Header: FormErrorAttribute;
  CarNo: FormErrorAttribute;
  InspectionDate: FormErrorAttribute;
  InsuranceEndDate: FormErrorAttribute;
  CarType: FormErrorAttribute;
  Passenger: FormErrorAttribute;
  CarInsurance: FormErrorAttribute;
}

export const CreateEmptyErrorData = () => {
  return {
    Header: {} as FormErrorAttribute,
    CarNo: {} as FormErrorAttribute,
    InspectionDate: {} as FormErrorAttribute,
    InsuranceEndDate: {} as FormErrorAttribute,
    CarType: {} as FormErrorAttribute,
    Passenger: {} as FormErrorAttribute,
    CarInsurance: {} as FormErrorAttribute,
  } as ErrorData;
};
