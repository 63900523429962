import React, { useEffect, useState, useCallback } from 'react';
import { registRegion, updateRegion } from 'graphql/mutations';
import { useLocation } from 'react-router';
import { UpdateRegionMutation, RegistRegionMutation, RegionRequest } from 'API';
import { CreateEmptyErrorData, ErrorData } from './RegionDetailErrorData';
import { useAPI } from 'contexts/APIRequestContext';

const UseRegionDetail = () => {
  const api = useAPI();
  const queryString = require('query-string');
  const location = useLocation();
  const regionPara = location.state as RegionRequest;
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [regionData, setRegionData] = useState({} as RegionRequest);
  const [, setError] = useState<undefined>();
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);
  var isUpdate = queryString.parse(location.search).mode === 'update';

  /**
   * ブラウザバック
   */
  const back = (): void => {
    window.history.back();
  };

  const handleChangeDetail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    setRegionData({ ...regionData, [name]: value });
  };

  const handleClickRegist = () => {
    if (isUpdate) {
      update();
    } else {
      regist();
    }
  };

  async function regist() {
    const response = (
      (await api.graphql({
        query: registRegion,
        variables: { data: regionData },
      })) as RegistRegionMutation
    ).registRegion;

    if (response?.IsSuccess) {
      back();
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  async function update() {
    const response = (
      (await api.graphql({
        query: updateRegion,
        variables: { data: regionData },
      })) as UpdateRegionMutation
    ).updateRegion;

    if (response?.IsSuccess) {
      back();
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  useEffect(() => {
    if (isUpdate) {
      setRegionData(regionPara as RegionRequest);
    } else {
      // 新規登録の場合、初期値のある値を予めセットする
      setRegionData({ AreaId: regionPara.AreaId, IsActive: '1' } as unknown as RegionRequest);
    }
  }, []);

  return {
    regionData,
    isUpdate,
    errorAttribute,
    handleChangeDetail,
    handleClickRegist,
  };
};

export default UseRegionDetail;
