import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Checkbox, Container, FormControl, FormHelperText, Grid, Link } from '@mui/material';
import Button from '@mui/material/Button/Button';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TextField from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography/Typography';
import { MinkaAllocateMinka, MinkaAllocateReservation, Minka } from 'API';
import React, { FC } from 'react';
import { getDisplayMonth, getDisplaySimpleDateSlashFromString } from 'util/DateUtil';
import GenericTemplate from 'views/template/GenericTemplate';
import { ErrorData } from './MinkaAllocateLedgerErrorData';
import BackToTopButton from 'views/parts/BackToTop';

const styles = {
  label: {
    margin: 'auto',
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
  },

  categoryHeader: {
    fontWeight: 'bold',
  },

  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
  },

  button: {
    minWidth: '100px',
    fontWeight: 'bold',
    mt: 0,
    ml: 0,
    mb: 1,
    float: 'right',
  },

  confirmButton: {
    width: '30%',
    mr: 5,
  },

  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },

  maxWidth: {
    maxWidth: '10px',
  },

  noMargin: {
    margin: 0,
  },

  textField: {
    width: '80%',
    '& input': {
      padding: '8px 14px',
    },
  },
  searchField: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1500px',
      },
    },
  },
};

export interface Props {
  editIndex: number;
  editButton: boolean;
  isActiveBefore: boolean;
  isActiveAfter: boolean;
  yearMonth: Date;
  minkas: MinkaAllocateMinka[];
  reservations: MinkaAllocateReservation[];
  handleBeforeMonth: () => void;
  handleNextMonth: () => void;
  handleClickReservationLink: (_: MinkaAllocateReservation) => void;
  handleClickMinkaLink: (_: MinkaAllocateMinka) => void;
  handleClickEditButton: (_: number) => void;
  handleClickCloseButton: () => void;
  handleClickConfirmButton: (_: MinkaAllocateReservation) => void;
  errorAttribute: ErrorData;
  checkedList: boolean[];
  memoList: string[];
  handleChangeCheckbox: (_: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  handleChangeMemo: (_: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => void;
}

const MinkaAllocateLedger: FC<Props> = ({
  editIndex,
  editButton,
  isActiveBefore,
  isActiveAfter,
  yearMonth,
  minkas,
  reservations,
  handleBeforeMonth,
  handleNextMonth,
  handleClickReservationLink,
  handleClickMinkaLink,
  handleClickEditButton,
  handleClickCloseButton,
  handleClickConfirmButton,
  errorAttribute,
  checkedList,
  memoList,
  handleChangeCheckbox,
  handleChangeMemo,
}) => {
  const pushBack = function () {
    window.history.back();
  };

  return (
    <GenericTemplate>
      <div>
        <BackToTopButton />
      </div>
      {errorAttribute.Header.ErrorMessage && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl component="fieldset" error={errorAttribute.Header.IsError}>
              <FormHelperText sx={{ ml: 0, fontSize: '1rem' }}>{errorAttribute.Header.ErrorMessage}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      )}
      <IconButton aria-label="allowBack" size="small" disabled={!isActiveBefore} onClick={handleBeforeMonth}>
        <ArrowBackIosNewIcon fontSize="inherit" />
      </IconButton>
      <Button color="inherit">
        <Typography component="div" align="center" color="inherit" noWrap>
          {`${getDisplayMonth(yearMonth)}`}
        </Typography>
      </Button>
      <IconButton aria-label="allowForward" size="small" disabled={!isActiveAfter} onClick={handleNextMonth}>
        <ArrowForwardIosIcon fontSize="inherit" />
      </IconButton>
      <TableContainer id="tableContainer" sx={{ maxHeight: 850 }}>
        <Table stickyHeader size="small" style={{ width: 'auto' }}>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  minWidth: 100,
                  position: 'sticky',
                  left: 0,
                  zIndex: 999,
                  textAlign: 'center',
                  backgroundColor: 'white',
                }}
              >
                地域
              </TableCell>
              <TableCell
                style={{
                  minWidth: 150,
                  position: 'sticky',
                  left: 100,
                  zIndex: 999,
                  textAlign: 'center',
                  backgroundColor: 'white',
                }}
              >
                ⺠家名
              </TableCell>
              <TableCell
                style={{
                  minWidth: 70,
                  position: 'sticky',
                  left: 250,
                  zIndex: 999,
                  textAlign: 'center',
                  backgroundColor: 'white',
                }}
              >
                受<br></br>⼊<br></br>性<br></br>別
              </TableCell>
              <TableCell
                style={{
                  minWidth: 65,
                  position: 'sticky',
                  left: 320,
                  zIndex: 999,
                  textAlign: 'center',
                  backgroundColor: 'white',
                }}
              >
                基<br></br>本<br></br>人<br></br>数
              </TableCell>
              <TableCell
                style={{
                  minWidth: 65,
                  position: 'sticky',
                  left: 385,
                  zIndex: 999,
                  textAlign: 'center',
                  backgroundColor: 'white',
                }}
              >
                最<br></br>⼤<br></br>人<br></br>数
              </TableCell>
              <TableCell
                style={{
                  minWidth: 65,
                  position: 'sticky',
                  left: 450,
                  zIndex: 999,
                  textAlign: 'center',
                  backgroundColor: 'white',
                }}
              >
                当<br></br>年<br></br>度<br></br>受<br></br>⼊<br></br>回<br></br>数
              </TableCell>
              {reservations.map((x, reservationIndex) => (
                <TableCell
                  style={{
                    minWidth: 250,
                    maxWidth: 250,
                    verticalAlign: 'top',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                  key={reservationIndex}
                >
                  <span>
                    <Typography component="span" style={{ color: 'red' }}>
                      {x.ApprovalStatusCode === 9 ? '【キャンセル】' : ''}
                    </Typography>
                    <Typography component="span" style={{ color: 'red' }} title={x.SchoolName ?? ''}>
                      <Link
                        component="span"
                        variant="body1"
                        style={{ color: '#2486c8', cursor: 'pointer' }}
                        onClick={() => handleClickReservationLink(x)}
                      >
                        {x.SchoolName}
                      </Link>
                    </Typography>
                  </span>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    sx={styles.noMargin}
                    title={x.ReservedGuestCount + '名' + x.ServiceName ?? ''}
                  >
                    {x.ReservedGuestCount}名{x.ServiceName}
                  </Typography>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    sx={styles.noMargin}
                    title={
                      '入村：' +
                        getDisplaySimpleDateSlashFromString(x.CheckInDate!) +
                        ' ' +
                        x.CheckInTime +
                        '@' +
                        x.CheckInPlace ?? ''
                    }
                  >
                    入村：{getDisplaySimpleDateSlashFromString(x.CheckInDate!)}&nbsp;{x.CheckInTime}@{x.CheckInPlace}
                  </Typography>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    sx={styles.noMargin}
                    title={
                      '退村：' +
                        getDisplaySimpleDateSlashFromString(x.CheckOutDate!) +
                        ' ' +
                        x.CheckOutTime +
                        '@' +
                        x.CheckOutPlace ?? ''
                    }
                  >
                    退村：{getDisplaySimpleDateSlashFromString(x.CheckOutDate!)}&nbsp;{x.CheckOutTime}@{x.CheckOutPlace}
                  </Typography>
                  <Typography sx={styles.noMargin}>担当：{x.ManagingUserName}</Typography>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    sx={styles.noMargin}
                    title={
                      '割当：' + x.PickUpMinkaCount + '軒' + x.GuestCount + '名（最大:' + x.MaxGuestCount + '名)' ?? ''
                    }
                  >
                    割当：{x.PickUpMinkaCount}軒 {x.GuestCount}名（最大:{x.MaxGuestCount}名）
                  </Typography>
                  {x.ApprovalStatusCode !== 9 &&
                    (editButton ? (
                      editIndex === reservationIndex ? (
                        <div>
                          <Button
                            variant="contained"
                            style={{ width: '40%', marginRight: '20px' }}
                            onClick={() => handleClickConfirmButton(x)}
                          >
                            確定
                          </Button>
                          <Button variant="contained" style={{ width: '50%' }} onClick={handleClickCloseButton}>
                            キャンセル
                          </Button>
                        </div>
                      ) : (
                        <Button variant="contained" style={{ width: '90%', margin: 'auto' }} disabled>
                          編集
                        </Button>
                      )
                    ) : (
                      <Button
                        variant="contained"
                        style={{ width: '90%', margin: 'auto' }}
                        key={reservationIndex}
                        onClick={() => handleClickEditButton(reservationIndex)}
                      >
                        編集
                      </Button>
                    ))}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {minkas.map((minka, minkaIndex) => (
              <TableRow key={minkaIndex} style={{ height: 45 }}>
                <TableCell
                  component="td"
                  scope="row"
                  style={{
                    minWidth: 100,
                    position: 'sticky',
                    left: 0,
                    zIndex: 800,
                    textAlign: 'left',
                    backgroundColor: 'white',
                  }}
                >
                  {minka.RegionName}
                </TableCell>
                <TableCell
                  component="td"
                  scope="row"
                  style={{
                    minWidth: 150,
                    position: 'sticky',
                    left: 100,
                    zIndex: 800,
                    textAlign: 'left',
                    backgroundColor: 'white',
                  }}
                >
                  #{minka.MinkaNo}&nbsp;
                  <Link onClick={() => handleClickMinkaLink(minka)} style={{ color: '#2486c8', cursor: 'pointer' }}>
                    {minka.BussinessName}
                  </Link>
                </TableCell>
                <TableCell
                  component="td"
                  scope="row"
                  style={{
                    minWidth: 70,
                    position: 'sticky',
                    left: 250,
                    zIndex: 800,
                    textAlign: 'center',
                    backgroundColor: 'white',
                  }}
                >
                  {minka.AcceptanceGender}
                </TableCell>
                <TableCell
                  component="td"
                  scope="row"
                  style={{
                    minWidth: 65,
                    position: 'sticky',
                    left: 320,
                    zIndex: 800,
                    textAlign: 'center',
                    backgroundColor: 'white',
                  }}
                >
                  {minka.GuestCount}
                </TableCell>
                <TableCell
                  component="td"
                  scope="row"
                  style={{
                    minWidth: 65,
                    position: 'sticky',
                    left: 385,
                    zIndex: 800,
                    textAlign: 'center',
                    backgroundColor: 'white',
                  }}
                >
                  {minka.MaxGuestcount}
                </TableCell>
                <TableCell
                  component="td"
                  scope="row"
                  style={{
                    minWidth: 65,
                    position: 'sticky',
                    left: 450,
                    zIndex: 800,
                    textAlign: 'center',
                    backgroundColor: 'white',
                  }}
                >
                  {minka.AcceptCount}
                </TableCell>
                {minka.PickUpMinkas?.map((pickUpMinka, pickUpMinkaIndex) =>
                  minka.IsClosed === 1 ? (
                    <TableCell
                      style={{ minWidth: 250, maxWidth: 250, textAlign: 'center', backgroundColor: 'lightgray' }}
                      key={pickUpMinkaIndex}
                    >
                      休業
                    </TableCell>
                  ) : minka.IsActive === 0 ? (
                    <TableCell
                      style={{ minWidth: 250, maxWidth: 250, textAlign: 'center', backgroundColor: 'lightgray' }}
                      key={pickUpMinkaIndex}
                    >
                      無効
                    </TableCell>
                  ) : pickUpMinka.IsAllocated && pickUpMinka.ApprovalStatusCode === 9 ? (
                    <TableCell style={{ minWidth: 250, maxWidth: 250, textAlign: 'center' }} key={pickUpMinkaIndex}>
                      キャンセル
                    </TableCell>
                  ) : editButton && editIndex === pickUpMinkaIndex ? (
                    <TableCell
                      className={!pickUpMinka.HasStudent ? 'edit-cel-js' : ''}
                      data-minka-id={minka.MinkaId}
                      style={{ minWidth: 250, maxWidth: 250, textAlign: 'center' }}
                      key={pickUpMinkaIndex}
                    >
                      <Checkbox
                        checked={checkedList[minkaIndex]}
                        disabled={!!pickUpMinka.HasStudent}
                        onChange={(e) => handleChangeCheckbox(e, minkaIndex)}
                      />
                      <TextField
                        sx={styles.textField}
                        disabled={!!pickUpMinka.HasStudent}
                        value={memoList[minkaIndex]}
                        onChange={(e) => handleChangeMemo(e, minkaIndex)}
                      />
                    </TableCell>
                  ) : pickUpMinka.IsAllocated ? (
                    <TableCell
                      className={!pickUpMinka.HasStudent ? 'edit-cel-js' : ''}
                      style={{
                        minWidth: 250,
                        maxWidth: 250,
                        textAlign: 'center',
                      }}
                      key={pickUpMinkaIndex}
                    >
                      〇（{pickUpMinka.GuestCount}）
                      <br />
                      <Typography
                        style={{
                          minWidth: 200,
                          maxWidth: 200,
                          textAlign: 'center',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                        title={pickUpMinka.Memo ?? ''}
                      >
                        {pickUpMinka.Memo}
                      </Typography>
                    </TableCell>
                  ) : (
                    <TableCell
                      style={{ minWidth: 250, maxWidth: 250, textAlign: 'center' }}
                      key={pickUpMinkaIndex}
                    ></TableCell>
                  )
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </GenericTemplate>
  );
};

export default MinkaAllocateLedger;
