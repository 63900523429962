import { useState, useEffect, useCallback } from 'react';
import { registServiceRate, updateServiceRate } from 'graphql/mutations';
import { useLocation, useNavigate } from 'react-router';
import React from 'react';
import { ServiceRate, ServiceRateRequest, RegistServiceRateMutation, UpdateServiceRateMutation } from '../../../../API';
import { CreateEmptyErrorData, ErrorData } from './PriceSettingErrorData';
import { useMaster } from 'contexts/CommonMasterContext';
import { useAPI } from 'contexts/APIRequestContext';
import { checkAuthority } from 'util/AuthorityCheck';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';

const UsePriceSetting = () => {
  const api = useAPI();
  const queryString = require('query-string');
  const location = useLocation();
  const serviceRatePara = location.state as ServiceRateRequest;
  const [, setError] = useState<undefined>();
  const [serviceRateData, setServiceRateData] = useState({} as ServiceRate);
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertDialogCallback, setAlertDialogCallback] = useState({ fn: (_: boolean) => {} });
  const navigator = useNavigate();
  const { user } = useAuthenticator();
  const userData = new UserData(user);
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  var isUpdate = queryString.parse(location.search).mode === 'update';

  /**
   * ブラウザバック
   */
  const back = (): void => {
    window.history.back();
  };

  /**
   * 警告ダイアログ　閉じる
   * @param result 結果
   */
  const handleAlertClose = (result: boolean): void => {
    alertDialogCallback.fn(result);
    setAlertOpen(false);
  };

  const handleChangeDetail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.type === 'number' && event.target.value === '' ? undefined : event.target.value; // MEMO: スキーマ定義が Int の場合、画面入力値を空に変更した場合、"" 空文字扱いとなり、エラーとなる為の対応
    const name = event.target.name;
    setServiceRateData({ ...serviceRateData, [name]: value });
  };

  const handleChangeFromDate = (value: Date | null) => {
    setServiceRateData({ ...serviceRateData, FromDate: value?.toLocaleDateString() });
  };

  const handleChangeEndDate = (value: Date | null) => {
    setServiceRateData({ ...serviceRateData, EndDate: value?.toLocaleDateString() });
  };

  const handleClickRegist = () => {
    if (isUpdate) {
      update();
    } else {
      regist();
    }
  };

  async function regist() {
    const response = (
      (await api.graphql({
        query: registServiceRate,
        variables: { data: serviceRateData },
      })) as RegistServiceRateMutation
    ).registServiceRate;

    if (response?.IsSuccess) {
      back();
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  async function update() {
    const response = (
      (await api.graphql({
        query: updateServiceRate,
        variables: { data: serviceRateData },
      })) as UpdateServiceRateMutation
    ).updateServiceRate;

    if (response?.IsSuccess) {
      back();
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('PriceSetting', userData.authorityCode)) {
      navigator('/');
    }

    if (isUpdate) {
      setServiceRateData(serviceRatePara as ServiceRate);
    } else {
      // 新規登録の場合、初期値のある値を予めセットする
      setServiceRateData({
        ServiceId: serviceRatePara.ServiceId,
        FromDate: '1900/01/01',
        EndDate: '2099/12/31',
      } as unknown as ServiceRate);
    }
  }, []);

  return {
    isUpdate,
    serviceRateData,
    errorAttribute,
    alertOpen,
    alertContent,
    handleChangeDetail,
    handleChangeFromDate,
    handleChangeEndDate,
    handleClickRegist,
    handleAlertClose,
  };
};
export default UsePriceSetting;
