import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { GuestInfo } from 'types/GuestInfo';
import { ErrorData } from './GuestInfoDialogErrorData';

export interface Props {
  inputData: GuestInfo;
  handleChangeInput: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeHasGroupingName: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleRegist: () => void;
  handleClose: () => void;
  dialogOpen: boolean;
  errorAttribute: ErrorData;
}

const styles = {
  categoryHeader: {
    fontWeight: 'bold',
  },
  itemHeader: {
    backgroundColor: '#ececec',
    fontWeight: 'bold',
  },
  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
  },
  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 1,
    ml: 1,
  },
  label: {
    mt: '7px',
  },
  categoryTitle: {
    borderLeft: 5,
    borderColor: '#0058a0',
    pl: '10px',
  },
  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },
  listButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    m: 0,
  },
};

const GuestInfoDialog: FC<Props> = ({
  inputData,
  handleChangeInput,
  handleChangeHasGroupingName,
  handleRegist,
  handleClose,
  dialogOpen,
  errorAttribute,
}) => {
  return (
    <Dialog open={dialogOpen} onClose={handleClose} maxWidth={'md'}>
      <DialogTitle>
        宿泊者情報
        {errorAttribute.Header.ErrorMessage && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl component="fieldset" error={errorAttribute.Header.IsError}>
                <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                  {errorAttribute.Header.ErrorMessage}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          宿泊者情報
        </Typography>
        <Grid container spacing={2} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>学校名</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography sx={styles.label}>{inputData.SchoolName}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>学校カナ名</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography sx={styles.label}>{inputData.SchoolKanaName}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>団別管理 *</Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControl component="fieldset" error={errorAttribute.HasGroupingName.IsError}>
              <RadioGroup
                row
                name="HasGroupingName"
                value={inputData.HasGroupingName ?? ''}
                onChange={handleChangeHasGroupingName}
              >
                <FormControlLabel value={0} control={<Radio />} label="しない" />
                <FormControlLabel value={1} control={<Radio />} label="する" />
              </RadioGroup>
              <FormHelperText>{errorAttribute.HasGroupingName.ErrorMessage}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>団名{inputData.HasGroupingName === 1 && ' *'}</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              required
              hiddenLabel
              name="GroupingName"
              value={inputData.GroupingName ?? ''}
              onChange={handleChangeInput}
              size="small"
              sx={styles.inputItem}
              error={errorAttribute.GroupingName.IsError}
              helperText={errorAttribute.GroupingName.ErrorMessage}
              inputProps={{ maxLength: '10' }}
              disabled={inputData.HasGroupingName !== 1}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>分宿 *</Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControl component="fieldset" error={errorAttribute.HasAnotherResv.IsError}>
              <RadioGroup row name="HasAnotherResv" value={inputData.HasAnotherResv} onChange={handleChangeInput}>
                <FormControlLabel value={0} control={<Radio />} label="しない" />
                <FormControlLabel value={1} control={<Radio />} label="する" />
              </RadioGroup>
              <FormHelperText>{errorAttribute.HasAnotherResv.ErrorMessage}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" sx={styles.button} onClick={handleRegist}>
              登録
            </Button>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" sx={styles.button} onClick={handleClose}>
              閉じる
            </Button>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
export default GuestInfoDialog;
