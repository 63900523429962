import { FC } from 'react';
import { ReservationDay } from 'API';
import DurationDateDialog from './DurationDateDialog';
import UseDurationDateDialog from './UseDurationDateDialog';

export interface Props {
  handleDialogClose: (_?: ReservationDay) => void;
  dialogOpen: boolean;
  reservationDay: ReservationDay;
  reservationId: string;
  updateDatetime: string;
}

const DurationDateDialogContainer: FC<Props> = (props: Props) => {
  return <DurationDateDialog {...UseDurationDateDialog(props)} />;
};

export default DurationDateDialogContainer;
