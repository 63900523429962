import { FC } from 'react';
import AlertDialog from './AlertDialog';
import UseAlertDialog from './UseAlertDialog';

export interface Props {
  handleDialogClose: (_: boolean) => void;
  dialogOpen: boolean;
  content: String;
}

const AlertDialogContainer: FC<Props> = (props: Props) => {
  return <AlertDialog {...UseAlertDialog(props)} />;
};

export default AlertDialogContainer;
