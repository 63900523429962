import React, { FC } from 'react';
import GenericTemplate from 'views/template/GenericTemplate';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import { AreaDateData, Reservation, ReserveReception } from 'API';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DatePicker';
import Tooltip from '@mui/material/Tooltip';
import { useMaster } from 'contexts/CommonMasterContext';
import { CodeKind, AuthorityCode, ReservationStatusCode } from 'constant/Constant';
import { getDisplayDate, getDisplaySimpleDate } from 'util/DateUtil';
import jaLocale from 'date-fns/locale/ja';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';
import FormControl from '@mui/material/FormControl/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import FormGroup from '@mui/material/FormGroup/FormGroup';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const grey = {
  50: '#F3F6F9',
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
};

const statusColor = new Map([
  ['0', '#FFFFFF'],
  ['1', '#FFeEB3'],
  ['2', '#A2CF6E'],
  ['3', '#FFC107'],
  ['4', '#FFAC33'],
  ['5', '#33EAFF'],
  ['6', '#03A9F4'],
  ['9', '#EF5350'],
]);

const Root = styled('div')(
  ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    text-align: center;
  }

  th {
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : grey[100]};
    padding: 6px;
    font-weight: normal;
  }

  td {
    padding: 2px;
  }
  .clickable:hover {
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  }
  `
);

const styles = {
  categoryHeader: {
    fontWeight: 'bold',
  },
  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 3,
    ml: 1,
  },
  cardFooter: {
    backgroundColor: grey[100],
    pt: '1px',
    pb: '1px',
  },
};

export interface Props {
  reserveReception: ReserveReception[];
  startDate: Date;
  displayDays: Date[];
  isIncludeCancel: boolean;
  durationMultiplier: Number;
  textBoxStartDate: Date | null;
  handleDetailClick: (
    _: ReserveReception,
    date: string,
    idxFrame: number,
    idxDate: number,
    reservation: Reservation
  ) => void;
  handleRegistClick: (_: ReserveReception, date: string, idxFrame: number, idxDate: number) => void;
  handleRefresh: () => void;
  handleDateChange: (_: Date | null) => void;
  handleBeforeWeek: () => void;
  handleNextWeek: () => void;
  handleChangeCheckbox: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeDuration: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeTextBoxStartDate: (_: Date | null) => void;
}

const ReserveLedger: FC<Props> = ({
  reserveReception,
  startDate,
  displayDays,
  isIncludeCancel,
  durationMultiplier,
  textBoxStartDate,
  handleDetailClick,
  handleRegistClick,
  handleRefresh,
  handleDateChange,
  handleBeforeWeek,
  handleNextWeek,
  handleChangeCheckbox,
  handleChangeDuration,
  handleChangeTextBoxStartDate,
}) => {
  const master = useMaster();
  const { user } = useAuthenticator();
  const userData = new UserData(user);

  const createDateCell = (areaDateData: AreaDateData, idxFrame: number, idxDate: number, ledger: ReserveReception) => {
    if (areaDateData.Reservation) {
      return (
        <TableCell
          key={idxDate}
          component="td"
          scope="row"
          align="center"
          sx={{
            padding: '0px',
            backgroundColor: statusColor.get(areaDateData.Reservation?.ReservationStatusCode ?? '0'),
          }}
          className="clickable"
          onClick={() => handleDetailClick(ledger, areaDateData.Date, idxFrame, idxDate, areaDateData.Reservation!)}
        >
          <Tooltip
            title={
              <React.Fragment>
                <Typography variant="body2" sx={{ textAlign: 'center' }}>
                  予約ID: {areaDateData.Reservation?.ReservationId}
                </Typography>
                <Typography variant="body2" sx={{ textAlign: 'center' }}>
                  {areaDateData.Reservation?.SchoolName}
                  {areaDateData.Reservation?.HasGroupingName === 1 && <> - {areaDateData.Reservation?.GroupingName}</>}
                </Typography>
                <Typography variant="body2" sx={{ textAlign: 'center' }}>
                  <>
                    {master.getCodeName(
                      CodeKind.ReservationStatusCode,
                      String(areaDateData.Reservation.ReservationStatusCode)
                    )}
                    {areaDateData.Reservation?.WaitingNumber}
                    {areaDateData.Reservation?.ReservationStatusCode === ReservationStatusCode.Tentative &&
                      `( 期日 ${getDisplayDate(new Date(areaDateData.Reservation.DurationDate!), true)})`}
                  </>
                </Typography>
                <br />
                {areaDateData.Reservation?.ReservationAgents?.map((agent, index) => (
                  <Typography key={index} variant="body2" sx={{ textAlign: 'center' }}>
                    <>{`${agent?.CompanyId}  ${agent?.CompanyName}`}</>
                  </Typography>
                ))}
              </React.Fragment>
            }
          >
            <span>
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                {areaDateData.Reservation?.SchoolName}
                {areaDateData.Reservation?.HasGroupingName === 1 && <> - {areaDateData.Reservation?.GroupingName}</>}(
                {areaDateData.Reservation?.GuestCount}名)
              </Typography>
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                <>
                  {master.getCodeName(
                    CodeKind.ReservationStatusCode,
                    String(areaDateData.Reservation.ReservationStatusCode)
                  )}
                  {areaDateData.Reservation?.WaitingNumber}
                </>
              </Typography>
            </span>
          </Tooltip>
        </TableCell>
      );
    } else if (areaDateData.IsStopped === 1 || areaDateData.NonBusinessDay === 1) {
      const closedReason = areaDateData.ClosedReason || '';
      const closedReasonDisplayText = closedReason.length > 8 ? closedReason.substring(0, 8) + '...' : closedReason;

      return (
        <TableCell key={idxDate} component="td" scope="row" sx={{ backgroundColor: 'lightgray' }}>
          <span>
            <Tooltip title={closedReason}>
              <Typography variant="body2" sx={{ textAlign: 'center', color: 'red' }}>
                {closedReasonDisplayText}
              </Typography>
            </Tooltip>
          </span>
        </TableCell>
      );
    } else if (
      userData.authorityCode === AuthorityCode.AcceptAdministrator ||
      userData.authorityCode === AuthorityCode.AgentSales
    ) {
      return (
        <TableCell key={idxDate} component="td" scope="row">
          <span>
            <Typography variant="body2" sx={{ textAlign: 'center', color: 'red' }}>
              {areaDateData.Availability === 0 ? '' : `残り受入 ${areaDateData.Availability} 名迄`}
              {areaDateData.ClosedReason || ''}
            </Typography>
          </span>
        </TableCell>
      );
    } else {
      return (
        <TableCell
          key={idxDate}
          component="td"
          scope="row"
          align="center"
          sx={{ padding: '0px' }}
          className="clickable"
          onClick={() => handleRegistClick(ledger, areaDateData.Date, idxFrame, idxDate)}
        >
          <span>
            <Typography variant="body2" sx={{ textAlign: 'center', color: 'red' }}>
              {areaDateData.Availability === 0 ? '' : `残り受入 ${areaDateData.Availability} 名迄`}
              {areaDateData.ClosedReason || ''}
            </Typography>
          </span>
        </TableCell>
      );
    }
  };

  return (
    <GenericTemplate>
      <Root>
        <Container sx={{ display: 'flex', justifyContent: 'left' }} maxWidth="xl">
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
            <DesktopDatePicker
              mask="____/__/__"
              value={startDate}
              onChange={(newValue) => {
                handleDateChange(newValue);
              }}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <input ref={inputRef} style={{ visibility: 'collapse', width: '10px' }} {...inputProps} />
                  {InputProps?.endAdornment}
                </Box>
              )}
            />
          </LocalizationProvider>
          <Container sx={{ display: 'flex', justifyContent: 'left' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
              <DesktopDatePicker
                label="yyyy/mm/dd"
                mask="____/__/__"
                value={textBoxStartDate}
                onChange={(newValue) => handleChangeTextBoxStartDate(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ minWidth: '120px' }}
                    size="small"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        const refreshButton = document.getElementById('refreshButton');
                        if (refreshButton) {
                          (refreshButton as HTMLElement).focus();
                        }
                      }
                    }}
                    onBlur={(e) => handleDateChange(textBoxStartDate)}
                    InputProps={{
                      endAdornment: null, // カレンダーアイコンを非表示にする
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            <IconButton aria-label="refresh" size="small" onClick={handleRefresh} id="refreshButton">
              <RefreshIcon sx={{ fontSize: 30 }} />
            </IconButton>
            <IconButton aria-label="allowBack" size="small" onClick={handleBeforeWeek}>
              <ArrowBackIosNewIcon fontSize="inherit" />
            </IconButton>
            <Typography
              component="div"
              align="center"
              color="inherit"
              sx={{
                pt: '9px',
                px: '3px',
                maxWidth: '100%', // 必要に応じて最大幅を設定
                whiteSpace: 'nowrap', // テキストを1行に強制
              }}
            >
              {displayDays.length > 0 &&
                `${getDisplayDate(displayDays[0], true)} ～ ${getDisplayDate(
                  displayDays[displayDays.length - 1],
                  true
                )}`}
            </Typography>
            <IconButton aria-label="allowForward" size="small" onClick={handleNextWeek}>
              <ArrowForwardIosIcon fontSize="inherit" />
            </IconButton>
          </Container>
          <Container sx={{ display: 'flex', justifyContent: 'left' }}>
            <FormGroup style={{ display: 'block' }}>
              <FormControl variant="outlined" size="small" style={{ minWidth: '120px' }}>
                <InputLabel id="durationMultiplier">表示期間</InputLabel>
                <Select
                  labelId="durationMultiplier"
                  value={durationMultiplier}
                  onChange={(e: any) => {
                    handleChangeDuration(e);
                  }}
                  label="表示期間"
                >
                  <MenuItem value={1}>1 週間</MenuItem>
                  <MenuItem value={2}>2 週間</MenuItem>
                  <MenuItem value={3}>3 週間</MenuItem>
                  <MenuItem value={4}>4 週間</MenuItem>
                </Select>
              </FormControl>
            </FormGroup>
          </Container>
          <Container sx={{ display: 'flex', justifyContent: 'right' }}>
            <FormGroup style={{ display: 'block' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="IsIncludeCancel"
                    value={6}
                    onChange={handleChangeCheckbox}
                    checked={isIncludeCancel ?? false}
                  />
                }
                label="キャンセルを含める"
              />
            </FormGroup>
          </Container>
        </Container>
        <TableContainer className="ledgerTableContainer" sx={{ maxHeight: 750, overflowX: 'auto' }}>
          <Table stickyHeader sx={{ minWidth: 650 * Number(durationMultiplier) }} size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  colSpan={2}
                  sx={styles.categoryHeader}
                  style={{
                    position: 'sticky',
                    left: 0,
                    width: '100px',
                  }}
                >
                  宿泊地区
                </TableCell>
                {displayDays.map((day, index) => (
                  <TableCell
                    key={index}
                    component="th"
                    scope="row"
                    sx={styles.categoryHeader}
                    style={{
                      position: 'sticky',
                      left: '100px',
                    }}
                  >
                    {getDisplaySimpleDate(day, true)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {reserveReception.map((area, idxArea) =>
                area.FrameList.map((frameData, idxFrame) => (
                  <TableRow key={(idxArea + 1) * 10 * (idxFrame + 1)}>
                    {idxFrame === 0 && (
                      <TableCell
                        component="td"
                        scope="row"
                        rowSpan={area.MaxReservationCount}
                        style={{
                          position: 'sticky',
                          left: 0,
                          width: '100px',
                          backgroundColor: 'white',
                        }}
                      >
                        {area.AreaName}
                        <br />
                        {`${frameData.DateList[0].MaxGuestCount}名`}
                        <br />
                        {`1日${frameData.DateList[0].MaxSchoolCount}校${frameData.DateList[0].MaxMultiSchoolGuestCount}名迄`}
                      </TableCell>
                    )}
                    <TableCell
                      component="td"
                      scope="row"
                      sx={{ width: '30px', padding: '2px' }}
                      style={{
                        position: 'sticky',
                        left: '100px',
                        backgroundColor: 'white',
                      }}
                    >
                      {frameData.FrameNo}
                    </TableCell>
                    {frameData.DateList.map((areaDate, idxDate) => {
                      return createDateCell(areaDate, idxFrame, idxDate, area);
                    })}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Root>
    </GenericTemplate>
  );
};
export default ReserveLedger;
