import { TransferReservationMutation } from 'API';
import { useAPI } from 'contexts/APIRequestContext';
import { transferReservation } from 'graphql/mutations';
import { useCallback, useEffect, useState } from 'react';
import { CreateEmptyErrorData, ErrorData } from './TransferReservationErrorData';

const UseTransferReservationDialog = ({
  handleDialogClose,
  dialogOpen,
  reservationId,
  updateDatetime,
}: {
  handleDialogClose: (_: boolean) => void;
  dialogOpen: boolean;
  reservationId: string;
  updateDatetime: string;
}) => {
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const api = useAPI();
  const [, setError] = useState<undefined>();
  const [targetReservationId, setTargetReservationId] = useState('');
  const [sourceReservationId, setSourceReservationId] = useState('');

  useEffect(() => {
    if (dialogOpen) {
      // 初期化
      setSourceReservationId(reservationId);

      setErrorAttribute({ ...CreateEmptyErrorData() });
    }
  }, [dialogOpen]);

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  /**
   * 付け替え先予約ID変更時
   */
  const handleChangeTargetReservationId = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = parseInt(event.target.value, 10);
    // maxとminに指定した値を変数で定義
    const max = 2147483647;
    const min = 1;

    // 入力値が範囲外の場合にはエラーを設定
    if (inputValue < min || inputValue > max) {
      setErrorAttribute((prev) => ({
        ...prev,
        TargetReservationId: {
          IsError: true,
          ErrorMessage: `${min}以上${max}以下の数値を入力してください。`,
        },
      }));
      return;
    }

    // 値が変更されたらエラー情報をクリア
    setErrorAttribute({ ...errorAttribute, TargetReservationId: { IsError: false, ErrorMessage: '' } });
    setTargetReservationId(inputValue.toString());
  };

  /**
   * 予約ID付け替え処理
   */
  const executeTransferReservation = async (): Promise<void> => {
    const response = (
      (await api.graphql({
        query: transferReservation,
        variables: {
          data: {
            TargetReservationId: targetReservationId,
            SourceReservationId: sourceReservationId,
            UpdateDatetime: updateDatetime,
          },
        },
      })) as TransferReservationMutation
    ).transferReservation;

    if (response?.IsSuccess) {
      handleDialogClose(true);
    } else if (response?.IsSystemError) {
      throwError(response.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  };

  /**
   * 仮予約期限　登録
   */
  const handleRegist = async (): Promise<void> => {
    executeTransferReservation();
  };

  /**
   * 仮予約期限　閉じる
   */
  const handleClose = (): void => {
    handleDialogClose(false);
  };

  return {
    handleChangeTargetReservationId,
    handleRegist,
    handleClose,
    sourceReservationId,
    targetReservationId,
    dialogOpen,
    errorAttribute,
  };
};
export default UseTransferReservationDialog;
