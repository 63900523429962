import FormErrorAttribute from 'types/FormErrorAttribute';

export interface ErrorData {
  Header: FormErrorAttribute;
  AreaId: FormErrorAttribute;
  RegionCode: FormErrorAttribute;
  RegionName: FormErrorAttribute;
  IsActive: FormErrorAttribute;
}

export const CreateEmptyErrorData = () => {
  return {
    Header: {} as FormErrorAttribute,
    AreaId: {} as FormErrorAttribute,
    RegionCode: {} as FormErrorAttribute,
    RegionName: {} as FormErrorAttribute,
    IsActive: {} as FormErrorAttribute,
  } as ErrorData;
};
