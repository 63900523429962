import FormErrorAttribute from 'types/FormErrorAttribute';

export interface ErrorData {
  Header: FormErrorAttribute;
  MinkaIdList: FormErrorAttribute;
  MessageSubject: FormErrorAttribute;
  MessageBody: FormErrorAttribute;
}

export const CreateEmptyErrorData = () => {
  return {
    Header: {} as FormErrorAttribute,
    MinkaIdList: {} as FormErrorAttribute,
    MessageSubject: {} as FormErrorAttribute,
    MessageBody: {} as FormErrorAttribute,
  } as ErrorData;
};

