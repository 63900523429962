import SearchIcon from '@mui/icons-material/Search';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Container } from '@mui/material';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import FormGroup from '@mui/material/FormGroup/FormGroup';
import Grid from '@mui/material/Grid/Grid';
import IconButton from '@mui/material/IconButton/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup/RadioGroup';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TableHead from '@mui/material/TableHead/TableHead';
import TablePagination from '@mui/material/TablePagination/TablePagination';
import TableRow from '@mui/material/TableRow/TableRow';
import TextField from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography/Typography';
import { Area, Branch, CompanyBranch, FindReserveList, MSchools, Prefecture, SchoolYearSetting } from 'API';
import { UserCategoryCode } from 'constant/Constant';
import jaLocale from 'date-fns/locale/ja';
import { UserData } from 'models/UserData';
import React, { FC, useState } from 'react';
import { Link } from '@mui/material';
import SchoolSearchDialog from 'views/reserve/reception/dialog/schoolSelect';
import { SelectValue } from '../../../../types/SelectValue';
import SelectFormControl from '../../../parts/SelectFormControl';
import GenericTemplate from '../../../template/GenericTemplate';
import BackToTopButton from 'views/parts/BackToTop';

const styles = {
  searchField: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
  },

  label: {
    margin: 'auto',
    textAlign: 'right',
    pr: '10px',
  },

  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
    mb: '20px',
  },

  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
  },

  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 1,
    ml: 1,
  },

  textField: {
    width: '100%',
    '& input': {
      padding: '8.5px 14px',
    },
  },

  csvButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    mt: 1,
    ml: 1,
    mb: 3,
  },

  listButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    m: 0,
  },
};

export interface Props {
  prefectureCode: string;
  schoolPrefectureCode: string;
  agentCompanyId: string;
  acceptCompanyId: string;
  schoolYearCode: string;
  areaId: string;
  bookingBranchId: string;
  salesBranchId: string;
  stayStartDate: Date | null;
  stayEndDate: Date | null;
  isIncludeCancel: boolean;

  companyList: CompanyBranch[];
  prefectureList: Prefecture[];
  areaList: Area[];
  branchList: Branch[];
  schoolYearSettingList: SchoolYearSetting[];
  searchSchoolOpen: boolean;
  selectSchool: MSchools;
  //  inputData: FindReserveListRequest;

  resultData: FindReserveList[];
  userData: UserData;
  page: number;
  rowsPerPage: number;

  handleChangePrefectureCode: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeSchoolPrefectureCode: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeAgentCompany: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeAcceptCompany: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeSchoolYearCode: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeArea: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeBranchName: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeCheckbox: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeOrder: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeFromReserveDay: (_: Date | null) => void;
  handleChangeToReserveDay: (_: Date | null) => void;
  handleResetSearchForm: () => void;
  handleSearchSchoolOpen: () => void;
  handleSearchSchoolClose: (_?: MSchools) => void;
  handleClickSearch: () => void;
  handleClickDetailButton: (_: FindReserveList) => void;
  handleClickCsvOutput: () => void;
  handleChangePage: (_: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => void;
  handleChangeRowsPerPage: (_: React.ChangeEvent<HTMLInputElement>) => void;
}

const ReserveList: FC<Props> = (props) => {
  return (
    <GenericTemplate title="予約一覧">
      <Container sx={styles.searchField}>
        <div>
          <BackToTopButton />
        </div>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          検索条件
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container>
              <Grid item xs={3} sm={3} md={3} sx={styles.label}>
                <Typography>年度</Typography>
              </Grid>
              <Grid item xs={9} sm={9} md={9}>
                <Grid container>
                  <SelectFormControl
                    value={props.schoolYearCode}
                    selectName="SchoolYearCode"
                    selectValues={props.schoolYearSettingList.map((schoolYear, idx) => {
                      return {
                        value: schoolYear.SchoolYear.SchoolYearCode,
                        displayName: schoolYear.SchoolYear.SchoolYearCode,
                      } as SelectValue;
                    })}
                    setCondition={props.handleChangeSchoolYearCode}
                    sx={styles.inputItem}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container>
              <Grid item xs={3} sm={3} md={3} sx={styles.label}>
                <Typography>宿泊（入村）日</Typography>
              </Grid>
              <Grid item xs={9} sm={9} md={9}>
                <Grid container>
                  <Grid item xs={12} sm={5} md={5}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
                      <DesktopDatePicker
                        label="yyyy/mm/dd"
                        mask="____/__/__"
                        className="StayStartDate"
                        value={props.stayStartDate}
                        onChange={(newValue) => props.handleChangeFromReserveDay(newValue)}
                        renderInput={(params) => <TextField {...params} size="small" />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={2} md={2} style={{ margin: 'auto', textAlign: 'center' }}>
                    <Typography>~</Typography>
                  </Grid>
                  <Grid item xs={12} sm={5} md={5}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
                      <DesktopDatePicker
                        label="yyyy/mm/dd"
                        mask="____/__/__"
                        className="StayEndDate"
                        value={props.stayEndDate}
                        onChange={(newValue) => props.handleChangeToReserveDay(newValue)}
                        renderInput={(params) => <TextField {...params} size="small" />}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Grid container>
              <Grid item xs={3} sm={3} md={3} sx={styles.label}>
                <Typography>学校所在都道府県</Typography>
              </Grid>
              <Grid item xs={9} sm={9} md={9}>
                <SelectFormControl
                  value={props.schoolPrefectureCode}
                  selectName="SchoolPrefectureCode"
                  selectValues={props.prefectureList.map((prefecture, idx) => {
                    return {
                      value: prefecture.PrefectureCode,
                      displayName: prefecture.PrefectureName,
                    } as SelectValue;
                  })}
                  setCondition={props.handleChangeSchoolPrefectureCode}
                  sx={styles.inputItem}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container>
              <Grid item xs={3} sm={3} md={3} sx={styles.label}>
                <Typography>学校</Typography>
              </Grid>
              <Grid item xs={9} sm={9} md={9}>
                <Paper component="form" variant="outlined" sx={{ p: '0px 4px', display: 'flex', alignItems: 'center' }}>
                  <InputBase sx={{ ml: 1, flex: 1 }} readOnly={true} value={props.selectSchool.SchoolName ?? ''} />
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  {props.userData.userCategoryCode === UserCategoryCode.School ? (
                    <IconButton disabled sx={{ p: '6px' }} aria-label="search" onClick={props.handleSearchSchoolOpen}>
                      <SearchIcon />
                    </IconButton>
                  ) : (
                    <IconButton sx={{ p: '6px' }} aria-label="search" onClick={props.handleSearchSchoolOpen}>
                      <SearchIcon />
                    </IconButton>
                  )}
                </Paper>
                <SchoolSearchDialog
                  handleDialogClose={props.handleSearchSchoolClose}
                  dialogOpen={props.searchSchoolOpen}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Grid container>
              <Grid item xs={3} sm={3} md={3} sx={styles.label}>
                <Typography>宿泊先都道府県</Typography>
              </Grid>
              <Grid item xs={9} sm={9} md={9}>
                <SelectFormControl
                  value={props.prefectureCode}
                  selectName="PrefectureCode"
                  selectValues={props.prefectureList
                    .filter((x) => x.IsMinpakuArea === 1)
                    .map((prefecture, idx) => {
                      return {
                        value: prefecture.PrefectureCode,
                        displayName: prefecture.PrefectureName,
                      } as SelectValue;
                    })}
                  setCondition={props.handleChangePrefectureCode}
                  sx={styles.inputItem}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container>
              <Grid item xs={3} sm={3} md={3} sx={styles.label}>
                <Typography>宿泊先地区</Typography>
              </Grid>
              <Grid item xs={9} sm={9} md={9}>
                {props.areaList.length > 0 ? (
                  <SelectFormControl
                    value={props.areaId}
                    selectName="AreaId"
                    selectValues={props.areaList.map((area, idx) => {
                      return { value: area.AreaId, displayName: area.AreaName } as SelectValue;
                    })}
                    setCondition={props.handleChangeArea}
                    sx={styles.inputItem}
                  />
                ) : (
                  <>
                    <SelectFormControl
                      value={'1'}
                      selectName="NoneAreaId"
                      sx={styles.inputItem}
                      isDisabled
                      setCondition={props.handleChangeArea}
                      selectValues={[{ value: '1', displayName: '宿泊先都道府県を選択すると指定できます' }]}
                    />
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container>
              <Grid item xs={3} sm={3} md={3} sx={styles.label}>
                <Typography>旅行代理店</Typography>
              </Grid>
              {/* ログインユーザが旅行代理店の場合は、自社のみ（検索条件固定）としていたが一旦解除
              {props.userData.userCategoryCode === UserCategoryCode.Agent ? (
                <Grid item xs={9} sm={9} md={9}>
                  <SelectFormControl
                    isDisabled
                    value={props.userData.companyId ?? ''}
                    selectName="AgentCompanyId"
                    selectValues={props.companyList
                      .filter((x) => x.CategoryCode === '03')
                      .map((company, idx) => {
                        return { value: company.CompanyId, displayName: company.CompanyName } as SelectValue;
                      })}
                    setCondition={props.handleChangeAgentCompany}
                    sx={styles.inputItem}
                  />
                </Grid>
              ) : (
                <Grid item xs={9} sm={9} md={9}>
                  <SelectFormControl
                    value={props.agentCompanyId}
                    selectName="AgentCompanyId"
                    selectValues={props.companyList
                      .filter((x) => x.CategoryCode === '03')
                      .map((company, idx) => {
                        return { value: company.CompanyId, displayName: company.CompanyName } as SelectValue;
                      })}
                    setCondition={props.handleChangeAgentCompany}
                    sx={styles.inputItem}
                  />
                </Grid>
              )}
              */}
              <Grid item xs={9} sm={9} md={9}>
                <SelectFormControl
                  value={props.agentCompanyId}
                  selectName="AgentCompanyId"
                  selectValues={props.companyList
                    .filter((x) => x.CategoryCode === '03')
                    .map((company, idx) => {
                      return { value: company.CompanyId, displayName: company.CompanyName } as SelectValue;
                    })}
                  setCondition={props.handleChangeAgentCompany}
                  sx={styles.inputItem}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container>
              <Grid item xs={3} sm={3} md={3} sx={styles.label}>
                <Typography>受入事業者</Typography>
              </Grid>
              <Grid item xs={9} sm={9} md={9}>
                <SelectFormControl
                  value={props.acceptCompanyId}
                  selectName="AcceptCompanyId"
                  selectValues={props.companyList
                    .filter((x) => x.CategoryCode === '01')
                    .map((company, idx) => {
                      return { value: company.CompanyId, displayName: company.CompanyName } as SelectValue;
                    })}
                  setCondition={props.handleChangeAcceptCompany}
                  sx={styles.inputItem}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container>
              <Grid item xs={3} sm={3} md={3} sx={styles.label}>
                <Typography>手配担当店</Typography>
              </Grid>
              <Grid item xs={9} sm={9} md={9}>
                {props.branchList.length > 0 ? (
                  <SelectFormControl
                    value={props.bookingBranchId}
                    selectName="BookingBranchId"
                    selectValues={props.branchList.map((branch, idx) => {
                      return { value: branch.BranchId, displayName: branch.BranchName } as SelectValue;
                    })}
                    setCondition={props.handleChangeBranchName}
                    sx={styles.inputItem}
                  />
                ) : (
                  <>
                    <SelectFormControl
                      value={'1'}
                      selectName="NoneBookingBranch"
                      sx={styles.inputItem}
                      isDisabled
                      setCondition={props.handleChangeArea}
                      selectValues={[{ value: '1', displayName: '旅行代理店を選択すると指定できます' }]}
                    />
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container>
              <Grid item xs={3} sm={3} md={3} sx={styles.label}>
                <Typography>営業担当店</Typography>
              </Grid>
              <Grid item xs={9} sm={9} md={9}>
                {props.branchList.length > 0 ? (
                  <SelectFormControl
                    value={props.salesBranchId}
                    selectName="SalesBranchId"
                    selectValues={props.branchList.map((branch, idx) => {
                      return { value: branch.BranchId, displayName: branch.BranchName } as SelectValue;
                    })}
                    setCondition={props.handleChangeBranchName}
                    sx={styles.inputItem}
                  />
                ) : (
                  <>
                    <SelectFormControl
                      value={'1'}
                      selectName="NoneSalesBranch"
                      sx={styles.inputItem}
                      isDisabled
                      setCondition={props.handleChangeArea}
                      selectValues={[{ value: '1', displayName: '旅行代理店を選択すると指定できます' }]}
                    />
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container>
              <Grid item xs={3} sm={3} md={3} sx={styles.label}>
                <Typography>予約ステータス</Typography>
              </Grid>
              <Grid item xs={9} sm={9} md={9}>
                <FormControl component="fieldset">
                  <FormGroup style={{ display: 'block' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="IsIncludeCancel"
                          value={6}
                          onChange={props.handleChangeCheckbox}
                          checked={props.isIncludeCancel ?? false}
                        />
                      }
                      label="キャンセルを含む"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container>
              <Grid item xs={3} sm={3} md={3} sx={styles.label}>
                <Typography>並び順</Typography>
              </Grid>
              <Grid item xs={9} sm={9} md={9}>
                <FormControl component="fieldset">
                  <FormGroup style={{ display: 'block' }}>
                    <RadioGroup row aria-label="position" defaultValue="true">
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            name="IsDescending"
                            value={'true'}
                            onChange={props.handleChangeOrder}
                          />
                        }
                        label="日付が新しい順"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            name="IsDescending"
                            value={'false'}
                            onChange={props.handleChangeOrder}
                          />
                        }
                        label="日付が古い順"
                      />
                    </RadioGroup>
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button variant="outlined" sx={styles.button} onClick={props.handleResetSearchForm}>
            検索条件リセット
          </Button>
          <Button variant="contained" sx={styles.button} onClick={props.handleClickSearch}>
            検索
          </Button>
        </Box>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          予約一覧
        </Typography>
        <Button variant="contained" sx={styles.csvButton} onClick={props.handleClickCsvOutput}>
          CSV
        </Button>
        <TableContainer sx={{ minHeight: 300 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>入村日</TableCell>
                <TableCell>退村日</TableCell>
                <TableCell>学校</TableCell>
                <TableCell>人数</TableCell>
                <TableCell>泊数</TableCell>
                <TableCell>都道府県</TableCell>
                <TableCell>地区名</TableCell>
                <TableCell>受入事業者</TableCell>
                <TableCell>予約ID</TableCell>
                <TableCell>承認状態</TableCell>
                <TableCell>旅行代理店</TableCell>
                <TableCell>手配担当店</TableCell>
                <TableCell>営業担当店</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.resultData
                .slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage)
                .map((reserve, index) => (
                  <TableRow key={index}>
                    <TableCell component="td" scope="row">
                      {reserve.CheckInDate}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {reserve.CheckOutDate}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {reserve.SchoolName}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {reserve.GuestCount}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {reserve.StayNights}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {reserve.PrefectureName}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {reserve.AreaName}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {reserve.AcceptCompanyName}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {reserve.ReservationId}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {reserve.ApprovalStatus}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {reserve.CompanyName}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {reserve.BookingBranchName}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {reserve.SalesBranchName}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      <Link onClick={() => props.handleClickDetailButton(reserve)}>
                        <Button variant="contained" sx={styles.listButton} size="small">
                          詳細
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={props.resultData.length}
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          onPageChange={props.handleChangePage}
          onRowsPerPageChange={props.handleChangeRowsPerPage}
        />
      </Container>
    </GenericTemplate>
  );
};

export default ReserveList;
