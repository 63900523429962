import { FC } from 'react';
import TransferReservationDialog from './TransferReservationDialog';
import UseTransferReservationDialog from './UseTransferReservationDialog';

export interface Props {
  handleDialogClose: (_: boolean) => void;
  dialogOpen: boolean;
  reservationId: string;
  updateDatetime: string;
}

const TransferReservationDialogContainer: FC<Props> = (props: Props) => {
  return <TransferReservationDialog {...UseTransferReservationDialog(props)} />;
};

export default TransferReservationDialogContainer;
