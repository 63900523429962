import { FindServiceQuery, Service, UpdateReservationServiceMutation } from 'API';
import { useAPI } from 'contexts/APIRequestContext';
import { updateReservationService } from 'graphql/mutations';
import { findService } from 'graphql/queries';
import { useCallback, useEffect, useState } from 'react';
import { CreateEmptyErrorData, ErrorData } from './PlanDialogErrorData';
import { getDateDiffFromString } from 'util/DateUtil';

const UsePlanDialog = ({
  handleDialogClose,
  dialogOpen,
  reservationId,
  service,
  mode,
  updateDatetime,
}: {
  handleDialogClose: (_?: Service, updateDatetime?: string) => void;
  dialogOpen: boolean;
  reservationId: string;
  service: Service;
  mode: string;
  updateDatetime: string;
}) => {
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [inputData, setInputData] = useState({} as Service);
  const [services, setServices] = useState([] as Service[]);
  const api = useAPI();
  const [, setError] = useState<undefined>();

  useEffect(() => {
    if (dialogOpen) {
      // 初期化
      executeFindService();
      setInputData(service!);

      setErrorAttribute({ ...CreateEmptyErrorData() });
    } else {
      setInputData({} as Service);
    }
  }, [dialogOpen]);

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  /**
   * 宿泊プラン　入力値更新
   * @param event イベント
   */
  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'Rate') {
      setInputData({ ...inputData, [event.target.name]: Number(event.target.value) });
    } else {
      setInputData({ ...inputData, [event.target.name]: event.target.value });
    }

    // 値が変更されたらエラー情報をクリア
    setErrorAttribute({ ...errorAttribute, [event.target.name]: { isError: false, errorMessage: '' } });
  };

  /**
   * 宿泊プラン変更時
   * @param event イベント
   */
  const handleChangeService = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    const selectedService = services.find((s) => s.ServiceId === value);

    if (selectedService) {
      setInputData({
        ...inputData,
        ServiceId: value,
        ServiceDisplayName: selectedService.ServiceDisplayName,
        ServiceName: selectedService.ServiceName,
        Rate: selectedService.Rate,
      });

      setErrorAttribute((prevErrorAttribute) => ({
        ...prevErrorAttribute,
        ServiceId: { IsError: false, ErrorMessage: '' },
      }));
    }
  };
  /**
   * サービス取得処理
   */
  const executeFindService = async (): Promise<void> => {
    var diffDays = getDateDiffFromString(service.CheckInDate!, service.CheckOutDate!);
    if (diffDays == 0 && service.CheckInDate == service.CheckOutDate) {
      diffDays = 1;
    }
    const startDate = new Date(service.CheckInDate!);
    const reservedDays = [];
    for (let i = 0; i < diffDays; i++) {
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + i);
      reservedDays.push(date);
    }

    const response = (
      (await api.graphql({
        query: findService,
        variables: {
          data: {
            ReservationId: reservationId,
            ReservedDays: reservedDays,
          },
        },
      })) as FindServiceQuery
    ).findService;

    if (response?.IsSuccess) {
      setServices(response?.Body!);
    } else if (response?.IsSystemError) {
      throwError(response.ErrorData ?? '');
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...errorAttribute,
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  };

  /**
   * 宿泊プラン更新処理
   * @param data リクエストパラメータ
   * @param idx 削除するインデックス
   */
  const executeUpdateReservationService = async (): Promise<void> => {
    const response = (
      (await api.graphql({
        query: updateReservationService,
        variables: {
          data: {
            ReservationId: reservationId,
            ServiceId: inputData.ServiceId,
            Rate: inputData.Rate,
            CheckInTime: inputData.CheckInTime,
            CheckOutTime: inputData.CheckOutTime,
            UpdateDatetime: updateDatetime,
          },
        },
      })) as UpdateReservationServiceMutation
    ).updateReservationService;

    if (response?.IsSuccess) {
      handleDialogClose(inputData, response.Body!);
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  };

  /**
   * 宿泊プラン　登録
   */
  const handleRegist = async (): Promise<void> => {
    if (mode === 'MODIFY') {
      executeUpdateReservationService();
    } else {
      handleDialogClose(inputData);
    }
  };

  /**
   * 宿泊プラン　閉じる
   */
  const handleClose = (): void => {
    handleDialogClose();
  };

  return {
    inputData,
    services,
    handleChangeInput,
    handleChangeService,
    handleRegist,
    handleClose,
    dialogOpen,
    errorAttribute,
  };
};
export default UsePlanDialog;
