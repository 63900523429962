import { FC, useState } from 'react';
import GenericTemplate from '../../../template/GenericTemplate';
import React from 'react';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import Grid from '@mui/material/Grid/Grid';
import {
  Prefecture,
  SchoolYearSetting,
  ReservationFrameOpenSettingForList,
  FindReservationFrameOpenSettingListRequest,
} from '../../../../API';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import Button from '@mui/material/Button/Button';
import TableBody from '@mui/material/TableBody/TableBody';
import TablePagination from '@mui/material/TablePagination/TablePagination';
import Box from '@mui/material/Box/Box';
import FormControl from '@mui/material/FormControl/FormControl';
import SelectFormControl from '../../../parts/SelectFormControl';
import { SelectValue } from '../../../../types/SelectValue';
import FormHelperText from '@mui/material/FormHelperText';
import { ErrorData } from './ReserveFrameSettingListErrorData';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import BackToTopButton from 'views/parts/BackToTop';

const styles = {
  searchField: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
  },

  label: {
    margin: 'auto',
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
  },

  categoryHeader: {
    fontWeight: 'bold',
  },

  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
  },

  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
    '& > div > input': {
      maxHeight: '10px',
    },
  },

  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 1,
  },

  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },
};

export interface Props {
  prefectureCode: string;
  schoolYearCode: string;
  year: number | null;
  nextYear: number | null;
  resultData: ReservationFrameOpenSettingForList[];
  prefectureList: Prefecture[];
  schoolYearSettingList: SchoolYearSetting[];
  page: number;
  rowsPerPage: number;
  errorAttribute: ErrorData;
  handleChangePrefectureCode: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeSchoolYearCode: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickSearch: () => void;
  saveStateToLocalStorage: () => void;
  handleChangePage: (_: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => void;
  handleChangeRowsPerPage: (_: React.ChangeEvent<HTMLInputElement>) => void;
}

const ReserveFrameSettingList: FC<Props> = (props) => {
  return (
    <GenericTemplate title="予約枠公開設定一覧">
      <div>
        <BackToTopButton />
      </div>
      <Container sx={styles.searchField}>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          検索条件
        </Typography>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>年度</Typography>
          </Grid>
          <Grid item xs={7}>
            <SelectFormControl
              value={props.schoolYearCode}
              selectName="SchoolYearCode"
              selectValues={props.schoolYearSettingList.map((schoolYear, idx) => {
                return {
                  value: schoolYear.SchoolYear.SchoolYearCode,
                  displayName: schoolYear.SchoolYear.SchoolYearCode,
                } as SelectValue;
              })}
              setCondition={props.handleChangeSchoolYearCode}
              sx={styles.inputItem}
              error={props.errorAttribute.SchoolYearCode.IsError}
              errorMessage={props.errorAttribute.SchoolYearCode.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>都道府県</Typography>
          </Grid>
          <Grid item xs={7}>
            <SelectFormControl
              value={props.prefectureCode}
              selectName="PrefectureCode"
              selectValues={props.prefectureList
                .filter((x) => x.IsMinpakuArea === 1)
                .map((prefecture, idx) => {
                  return { value: prefecture.PrefectureCode, displayName: prefecture.PrefectureName } as SelectValue;
                })}
              setCondition={props.handleChangePrefectureCode}
              sx={styles.inputItem}
              error={props.errorAttribute.PrefectureCode.IsError}
              errorMessage={props.errorAttribute.PrefectureCode.ErrorMessage}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" sx={styles.button} onClick={props.handleClickSearch}>
            検索
          </Button>
        </Box>
      </Container>
      {props.errorAttribute.Header.ErrorMessage && (
        <Grid container spacing={2} sx={{ pl: 0, pb: 2 }}>
          <Grid item xs={12}>
            <FormControl component="fieldset" error={props.errorAttribute.Header.IsError}>
              <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                {props.errorAttribute.Header.ErrorMessage}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      )}
      <Container sx={styles.searchField}>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          予約枠設定
        </Typography>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={12}>
            <TableContainer sx={{ minHeight: 300 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>地区名</TableCell>
                    <TableCell>4月</TableCell>
                    <TableCell>5月</TableCell>
                    <TableCell>6月</TableCell>
                    <TableCell>7月</TableCell>
                    <TableCell>8月</TableCell>
                    <TableCell>9月</TableCell>
                    <TableCell>10月</TableCell>
                    <TableCell>11月</TableCell>
                    <TableCell>12月</TableCell>
                    <TableCell>1月</TableCell>
                    <TableCell>2月</TableCell>
                    <TableCell>3月</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.resultData
                    .slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage)
                    .map((openSetting, index) => (
                      <TableRow key={index}>
                        <TableCell>{openSetting.AreaName}</TableCell>
                        <TableCell>
                          {openSetting.Period1 === '1' ? (
                            <a
                              href={
                                '/settings/reserve/frameedit?areaId=' +
                                openSetting.AreaId +
                                '&year=' +
                                props.year +
                                '&month=' +
                                4
                              }
                              style={{ color: 'blue' }}
                              onClick={() => {
                                // 状態を保存
                                props.saveStateToLocalStorage();
                              }}
                            >
                              <Checkbox disabled checked />
                            </a>
                          ) : (
                            <a
                              href={
                                '/settings/reserve/frameregist?areaId=' +
                                openSetting.AreaId +
                                '&year=' +
                                props.year +
                                '&month=' +
                                4
                              }
                              style={{ color: 'blue' }}
                              onClick={() => {
                                // 状態を保存
                                props.saveStateToLocalStorage();
                              }}
                            >
                              <Checkbox disabled />
                            </a>
                          )}
                        </TableCell>
                        <TableCell>
                          {openSetting.Period2 === '1' ? (
                            <a
                              href={
                                '/settings/reserve/frameedit?areaId=' +
                                openSetting.AreaId +
                                '&year=' +
                                props.year +
                                '&month=' +
                                5
                              }
                              style={{ color: 'blue' }}
                              onClick={() => {
                                // 状態を保存
                                props.saveStateToLocalStorage();
                              }}
                            >
                              <Checkbox disabled checked />
                            </a>
                          ) : (
                            <a
                              href={
                                '/settings/reserve/frameregist?areaId=' +
                                openSetting.AreaId +
                                '&year=' +
                                props.year +
                                '&month=' +
                                5
                              }
                              style={{ color: 'blue' }}
                              onClick={() => {
                                // 状態を保存
                                props.saveStateToLocalStorage();
                              }}
                            >
                              <Checkbox disabled />
                            </a>
                          )}
                        </TableCell>
                        <TableCell>
                          {openSetting.Period3 === '1' ? (
                            <a
                              href={
                                '/settings/reserve/frameedit?areaId=' +
                                openSetting.AreaId +
                                '&year=' +
                                props.year +
                                '&month=' +
                                6
                              }
                              style={{ color: 'blue' }}
                              onClick={() => {
                                // 状態を保存
                                props.saveStateToLocalStorage();
                              }}
                            >
                              <Checkbox disabled checked />
                            </a>
                          ) : (
                            <a
                              href={
                                '/settings/reserve/frameregist?areaId=' +
                                openSetting.AreaId +
                                '&year=' +
                                props.year +
                                '&month=' +
                                6
                              }
                              style={{ color: 'blue' }}
                              onClick={() => {
                                // 状態を保存
                                props.saveStateToLocalStorage();
                              }}
                            >
                              <Checkbox disabled />
                            </a>
                          )}
                        </TableCell>
                        <TableCell>
                          {openSetting.Period4 === '1' ? (
                            <a
                              href={
                                '/settings/reserve/frameedit?areaId=' +
                                openSetting.AreaId +
                                '&year=' +
                                props.year +
                                '&month=' +
                                7
                              }
                              style={{ color: 'blue' }}
                              onClick={() => {
                                // 状態を保存
                                props.saveStateToLocalStorage();
                              }}
                            >
                              <Checkbox disabled checked />
                            </a>
                          ) : (
                            <a
                              href={
                                '/settings/reserve/frameregist?areaId=' +
                                openSetting.AreaId +
                                '&year=' +
                                props.year +
                                '&month=' +
                                7
                              }
                              style={{ color: 'blue' }}
                              onClick={() => {
                                // 状態を保存
                                props.saveStateToLocalStorage();
                              }}
                            >
                              <Checkbox disabled />
                            </a>
                          )}
                        </TableCell>
                        <TableCell>
                          {openSetting.Period5 === '1' ? (
                            <a
                              href={
                                '/settings/reserve/frameedit?areaId=' +
                                openSetting.AreaId +
                                '&year=' +
                                props.year +
                                '&month=' +
                                8
                              }
                              style={{ color: 'blue' }}
                              onClick={() => {
                                // 状態を保存
                                props.saveStateToLocalStorage();
                              }}
                            >
                              <Checkbox disabled checked />
                            </a>
                          ) : (
                            <a
                              href={
                                '/settings/reserve/frameregist?areaId=' +
                                openSetting.AreaId +
                                '&year=' +
                                props.year +
                                '&month=' +
                                8
                              }
                              style={{ color: 'blue' }}
                              onClick={() => {
                                // 状態を保存
                                props.saveStateToLocalStorage();
                              }}
                            >
                              <Checkbox disabled />
                            </a>
                          )}
                        </TableCell>
                        <TableCell>
                          {openSetting.Period6 === '1' ? (
                            <a
                              href={
                                '/settings/reserve/frameedit?areaId=' +
                                openSetting.AreaId +
                                '&year=' +
                                props.year +
                                '&month=' +
                                9
                              }
                              style={{ color: 'blue' }}
                              onClick={() => {
                                // 状態を保存
                                props.saveStateToLocalStorage();
                              }}
                            >
                              <Checkbox disabled checked />
                            </a>
                          ) : (
                            <a
                              href={
                                '/settings/reserve/frameregist?areaId=' +
                                openSetting.AreaId +
                                '&year=' +
                                props.year +
                                '&month=' +
                                9
                              }
                              style={{ color: 'blue' }}
                              onClick={() => {
                                // 状態を保存
                                props.saveStateToLocalStorage();
                              }}
                            >
                              <Checkbox disabled />
                            </a>
                          )}
                        </TableCell>
                        <TableCell>
                          {openSetting.Period7 === '1' ? (
                            <a
                              href={
                                '/settings/reserve/frameedit?areaId=' +
                                openSetting.AreaId +
                                '&year=' +
                                props.year +
                                '&month=' +
                                10
                              }
                              style={{ color: 'blue' }}
                              onClick={() => {
                                // 状態を保存
                                props.saveStateToLocalStorage();
                              }}
                            >
                              <Checkbox disabled checked />
                            </a>
                          ) : (
                            <a
                              href={
                                '/settings/reserve/frameregist?areaId=' +
                                openSetting.AreaId +
                                '&year=' +
                                props.year +
                                '&month=' +
                                10
                              }
                              style={{ color: 'blue' }}
                              onClick={() => {
                                // 状態を保存
                                props.saveStateToLocalStorage();
                              }}
                            >
                              <Checkbox disabled />
                            </a>
                          )}
                        </TableCell>
                        <TableCell>
                          {openSetting.Period8 === '1' ? (
                            <a
                              href={
                                '/settings/reserve/frameedit?areaId=' +
                                openSetting.AreaId +
                                '&year=' +
                                props.year +
                                '&month=' +
                                11
                              }
                              style={{ color: 'blue' }}
                              onClick={() => {
                                // 状態を保存
                                props.saveStateToLocalStorage();
                              }}
                            >
                              <Checkbox disabled checked />
                            </a>
                          ) : (
                            <a
                              href={
                                '/settings/reserve/frameregist?areaId=' +
                                openSetting.AreaId +
                                '&year=' +
                                props.year +
                                '&month=' +
                                11
                              }
                              style={{ color: 'blue' }}
                              onClick={() => {
                                // 状態を保存
                                props.saveStateToLocalStorage();
                              }}
                            >
                              <Checkbox disabled />
                            </a>
                          )}
                        </TableCell>
                        <TableCell>
                          {openSetting.Period9 === '1' ? (
                            <a
                              href={
                                '/settings/reserve/frameedit?areaId=' +
                                openSetting.AreaId +
                                '&year=' +
                                props.year +
                                '&month=' +
                                12
                              }
                              style={{ color: 'blue' }}
                              onClick={() => {
                                // 状態を保存
                                props.saveStateToLocalStorage();
                              }}
                            >
                              <Checkbox disabled checked />
                            </a>
                          ) : (
                            <a
                              href={
                                '/settings/reserve/frameregist?areaId=' +
                                openSetting.AreaId +
                                '&year=' +
                                props.year +
                                '&month=' +
                                12
                              }
                              style={{ color: 'blue' }}
                              onClick={() => {
                                // 状態を保存
                                props.saveStateToLocalStorage();
                              }}
                            >
                              <Checkbox disabled />
                            </a>
                          )}
                        </TableCell>
                        <TableCell>
                          {openSetting.Period10 === '1' ? (
                            <a
                              href={
                                '/settings/reserve/frameedit?areaId=' +
                                openSetting.AreaId +
                                '&year=' +
                                props.nextYear +
                                '&month=' +
                                1
                              }
                              style={{ color: 'blue' }}
                              onClick={() => {
                                // 状態を保存
                                props.saveStateToLocalStorage();
                              }}
                            >
                              <Checkbox disabled checked />
                            </a>
                          ) : (
                            <a
                              href={
                                '/settings/reserve/frameregist?areaId=' +
                                openSetting.AreaId +
                                '&year=' +
                                props.nextYear +
                                '&month=' +
                                1
                              }
                              style={{ color: 'blue' }}
                              onClick={() => {
                                // 状態を保存
                                props.saveStateToLocalStorage();
                              }}
                            >
                              <Checkbox disabled />
                            </a>
                          )}
                        </TableCell>
                        <TableCell>
                          {openSetting.Period11 === '1' ? (
                            <a
                              href={
                                '/settings/reserve/frameedit?areaId=' +
                                openSetting.AreaId +
                                '&year=' +
                                props.nextYear +
                                '&month=' +
                                2
                              }
                              style={{ color: 'blue' }}
                              onClick={() => {
                                // 状態を保存
                                props.saveStateToLocalStorage();
                              }}
                            >
                              <Checkbox disabled checked />
                            </a>
                          ) : (
                            <a
                              href={
                                '/settings/reserve/frameregist?areaId=' +
                                openSetting.AreaId +
                                '&year=' +
                                props.nextYear +
                                '&month=' +
                                2
                              }
                              style={{ color: 'blue' }}
                              onClick={() => {
                                // 状態を保存
                                props.saveStateToLocalStorage();
                              }}
                            >
                              <Checkbox disabled />
                            </a>
                          )}
                        </TableCell>
                        <TableCell>
                          {openSetting.Period12 === '1' ? (
                            <a
                              href={
                                '/settings/reserve/frameedit?areaId=' +
                                openSetting.AreaId +
                                '&year=' +
                                props.nextYear +
                                '&month=' +
                                3
                              }
                              style={{ color: 'blue' }}
                              onClick={() => {
                                // 状態を保存
                                props.saveStateToLocalStorage();
                              }}
                            >
                              <Checkbox disabled checked />
                            </a>
                          ) : (
                            <a
                              href={
                                '/settings/reserve/frameregist?areaId=' +
                                openSetting.AreaId +
                                '&year=' +
                                props.nextYear +
                                '&month=' +
                                3
                              }
                              style={{ color: 'blue' }}
                              onClick={() => {
                                // 状態を保存
                                props.saveStateToLocalStorage();
                              }}
                            >
                              <Checkbox disabled />
                            </a>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[7, 25, 100]}
              component="div"
              count={props.resultData.length}
              rowsPerPage={props.rowsPerPage}
              page={props.page}
              onPageChange={props.handleChangePage}
              onRowsPerPageChange={props.handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Container>
    </GenericTemplate>
  );
};

export default ReserveFrameSettingList;
