import React, { createContext, useContext, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { findCommonMaster } from 'graphql/queries';
import {
  Prefecture,
  CodeMaster,
  FindCommonMasterQuery,
  CompanyBranch,
  Branch,
  Area,
  Region,
  MServices,
  SchoolYearSetting,
  SchoolYear,
  SchoolYearPeriod,
} from 'API';
import { GraphQLResult } from '@aws-amplify/api-graphql';
// import { useAPI } from 'contexts/APIRequestContext';

const CommonMasterContext = createContext({
  getCodeMaster: (): CodeMaster[] => {
    return [] as CodeMaster[];
  },
  getCodeKind: (_: string): CodeMaster[] => {
    return [] as CodeMaster[];
  },
  getCodeName: (_: string, code: string): string => {
    return '';
  },
  getPrefecture: (): Prefecture[] => {
    return [] as Prefecture[];
  },
  getPrefectureName: (_: string): string => {
    return '';
  },
  getCompanies: (): CompanyBranch[] => {
    return [] as CompanyBranch[];
  },

  getCompanyName: (_: string): string => {
    return '';
  },

  getBranchs: (_: string): Branch[] | undefined => {
    return [] as Branch[];
  },

  findBranch: (_: string, branchId: string): Branch => {
    return {} as Branch;
  },

  getBranchName: (companyId: string, branchId: string): string => {
    return '';
  },

  getBranchTel: (companyId: string, branchId: string): string => {
    return '';
  },

  getBranchBookingMailJoinedByComma: (companyId: string, branchId: string): string => {
    return '';
  },
  getBranchSalesMailJoinedByComma: (companyId: string, branchId: string): string => {
    return '';
  },

  getAreaName: (areaId: string): string => {
    return '';
  },
  getAreaUrl: (areaId: string): string => {
    return '';
  },
  getAreas: (): Area[] => {
    return [] as Area[];
  },
  getAreasByPrefectureCode: (_: string): Area[] => {
    return [] as Area[];
  },
  getRegions: (): Region[] => {
    return [] as Region[];
  },
  getRegionsByAreaId: (_: string): Region[] => {
    return [] as Region[];
  },
  getServices: (): MServices[] => {
    return [] as MServices[];
  },
  getSchoolYearSettings: (): SchoolYearSetting[] => {
    return [] as SchoolYearSetting[];
  },

  getSchoolYearPeriods: (_: string): SchoolYearPeriod[] | undefined => {
    return [] as SchoolYearPeriod[];
  },
  waitForInitialized: (_: Function): void => {},
});

const CommonMasterContextProvider = ({ children }: { children: React.ReactNode }) => {
  let isInitialized = false;
  let codeMaster = [] as CodeMaster[];
  let prefectures = [] as Prefecture[];
  let companyBranch = [] as CompanyBranch[];
  let areas = [] as Area[];
  let regions = [] as Region[];
  let services = [] as MServices[];
  let schoolYearSetting = [] as SchoolYearSetting[];
  // const api = useAPI();

  async function fetchCommonMaster() {
    try {
      const apiData = (await API.graphql(graphqlOperation(findCommonMaster))) as GraphQLResult<FindCommonMasterQuery>;

      if (apiData.data?.findCommonMaster?.Body) {
        console.log('findCommonMaster success');
        codeMaster = apiData.data?.findCommonMaster?.Body.CodeList;
        prefectures = apiData.data?.findCommonMaster?.Body.PrefectureList;
        companyBranch = apiData.data?.findCommonMaster?.Body.CompanyBranchList;
        areas = apiData.data?.findCommonMaster?.Body?.AreaList;
        regions = apiData.data?.findCommonMaster?.Body?.RegionList;
        services = apiData.data?.findCommonMaster?.Body?.ServiceList;
        schoolYearSetting = apiData.data?.findCommonMaster?.Body?.SchoolYearSettingList;
      }
    } catch (e) {
      console.log(e);
    } finally {
      isInitialized = true;
    }
  }

  const waitForInitialized = async (callBack: Function): Promise<void> => {
    var id = setInterval(function () {
      if (isInitialized) {
        // タイマー停止
        clearInterval(id);
        // 完了時、コールバック関数を実行
        callBack();
      }
    }, 300);
  };

  const getCodeMaster = (): CodeMaster[] => {
    return codeMaster;
  };

  const getCodeKind = (codeKind: string): CodeMaster[] => {
    return codeMaster.filter(function (value) {
      return value.CodeKind === codeKind;
    });
  };

  const getCodeName = (codeKind: string, code: string): string => {
    var record = codeMaster.find(function (value) {
      return value.CodeKind === codeKind && value.Code === code;
    });
    if (record && record.Name) {
      return record.Name;
    } else {
      return '';
    }
  };

  const getCompanies = (): CompanyBranch[] => {
    return companyBranch;
  };

  const getCompanyName = (companyId: string): string => {
    var record = companyBranch.find(function (value) {
      return value.CompanyId === companyId;
    });
    if (record) {
      return record.CompanyName;
    } else {
      return '';
    }
  };

  const getBranchs = (companyId: string): Branch[] | undefined => {
    return companyBranch.find((x) => x.CompanyId.toString() === companyId)?.BranchList;
  };

  const findBranch = (companyId: string, branchId: string): Branch => {
    var company = companyBranch.find((x) => x.CompanyId.toString() === companyId);
    var branch = company!.BranchList.find(function (value) {
      return value.BranchId === branchId;
    });
    return branch!;
  };

  const getBranchName = (companyId: string, branchId: string): string => {
    var company = companyBranch.find(function (value) {
      return value.CompanyId === companyId;
    });
    if (company) {
      var branch = company.BranchList.find(function (value) {
        return value.BranchId === branchId;
      });
      if (branch) {
        return branch.BranchName;
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  const getAreasByPrefectureCode = (prefectureCode: string): Area[] => {
    return areas.filter((x) => x.PrefectureCode.toString() === prefectureCode);
  };

  const getAreaName = (areaId: string): string => {
    var targetArea = areas.find(function (value) {
      return value.AreaId === areaId;
    });
    if (targetArea) {
      return targetArea.AreaName;
    } else {
      return '';
    }
  };

  const getAreaUrl = (areaId: string): string => {
    var targetArea = areas.find(function (value) {
      return value.AreaId === areaId;
    });
    if (targetArea) {
      return targetArea.Url ?? '';
    } else {
      return '';
    }
  };

  const getBranchBookingMailJoinedByComma = (companyId: string, branchId: string): string => {
    var company = companyBranch.find(function (value) {
      return value.CompanyId === companyId;
    });
    if (company) {
      var branch = company.BranchList.find(function (value) {
        return value.BranchId === branchId;
      });
      if (branch) {
        var bookingUserMail: string[] = [];
        if (!!branch.Mail1) {
          bookingUserMail.push(branch.Mail1);
        }
        if (!!branch.Mail2) {
          bookingUserMail.push(branch.Mail2);
        }
        if (!!branch.Mail3) {
          bookingUserMail.push(branch.Mail3);
        }
        return bookingUserMail.join(',');
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  const getBranchSalesMailJoinedByComma = (companyId: string, branchId: string): string => {
    var company = companyBranch.find(function (value) {
      return value.CompanyId === companyId;
    });
    if (company) {
      var branch = company.BranchList.find(function (value) {
        return value.BranchId === branchId;
      });
      if (branch) {
        var bookingUserMail: string[] = [];
        if (!!branch.Mail4) {
          bookingUserMail.push(branch.Mail4);
        }
        if (!!branch.Mail5) {
          bookingUserMail.push(branch.Mail5);
        }
        if (!!branch.Mail6) {
          bookingUserMail.push(branch.Mail6);
        }
        return bookingUserMail.join(',');
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  const getBranchTel = (companyId: string, branchId: string): string => {
    var company = companyBranch.find(function (value) {
      return value.CompanyId === companyId;
    });
    if (company) {
      var branch = company.BranchList.find(function (value) {
        return value.BranchId === branchId;
      });
      if (branch) {
        return branch.Tel ?? '';
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  const getPrefecture = (): Prefecture[] => {
    return prefectures;
  };

  const getAreas = (): Area[] => {
    return areas;
  };

  const getRegions = (): Region[] => {
    return regions;
  };

  const getRegionsByAreaId = (areaId: string): Region[] => {
    return regions.filter((region) => region.AreaId === areaId);
  };

  const getPrefectureName = (prefectureCode: string): string => {
    var record = prefectures.find(function (value) {
      return value.PrefectureCode === prefectureCode;
    });
    if (record) {
      return record.PrefectureName;
    } else {
      return '';
    }
  };

  const getServices = (): MServices[] => {
    return services;
  };

  const getSchoolYearSettings = (): SchoolYearSetting[] => {
    return schoolYearSetting;
  };

  const getSchoolYearPeriods = (schoolCode: string): SchoolYearPeriod[] | undefined => {
    return schoolYearSetting.find((x) => x.SchoolYear.toString() === schoolCode)?.SchoolYearPeriodList;
  };

  useEffect(() => {
    console.log('CommonMasterContextProvider effect start');
    fetchCommonMaster();
    console.log('CommonMasterContextProvider effect end');
    return () => console.log('unmounting...');
  }, []);

  return (
    <CommonMasterContext.Provider
      value={{
        getCodeMaster,
        getCodeKind,
        getCodeName,
        getPrefecture,
        getPrefectureName,
        getCompanies,
        getCompanyName,
        getBranchs,
        findBranch,
        getBranchName,
        getBranchBookingMailJoinedByComma,
        getBranchSalesMailJoinedByComma,
        getBranchTel,
        getAreas,
        getAreasByPrefectureCode,
        getAreaName,
        getAreaUrl,
        getRegionsByAreaId,
        getRegions,
        getServices,
        getSchoolYearSettings,
        getSchoolYearPeriods,
        waitForInitialized,
      }}
    >
      {children}
    </CommonMasterContext.Provider>
  );
};
export const useMaster = () => useContext(CommonMasterContext);
export default CommonMasterContextProvider;
