import { FindMSchoolsQuery, MSchools, SchoolRequestData } from 'API';
import { graphqlOperation } from 'aws-amplify';
import { useAPI } from 'contexts/APIRequestContext';
import { findMSchools } from 'graphql/queries';
import { useEffect, useState } from 'react';

const UseSchoolSearchDialog = ({
  handleDialogClose,
  dialogOpen,
}: {
  handleDialogClose: (_?: MSchools) => void;
  dialogOpen: boolean;
}) => {
  const [inputData, setInputData] = useState({} as SchoolRequestData);
  const [resultData, setResultData] = useState([] as MSchools[]);
  const api = useAPI();

  const handleClose = (row?: MSchools) => {
    handleDialogClose(row);
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    setInputData({ ...inputData, [name]: value });
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    const checked = event.target.checked;

    let list;
    if (name == 'SchoolCategoryList') {
      list = inputData['SchoolCategoryList'] as Array<string> | null;
    } else {
      list = inputData['ClassificationList'] as Array<string> | null;
    }
    if (!list) {
      list = [] as string[];
    }
    if (checked) {
      if (!list?.find((x) => x == value)) {
        list?.push(value);
      }
    } else {
      if (list?.find((x) => x == value)) {
        list = list.filter((x) => x != value);
      }
    }
    setInputData({ ...inputData, [name]: list });
  };

  const handleClickSearch = () => {
    fetchSchools();
  };

  async function fetchSchools() {
    const response = (await api.graphql(
      graphqlOperation(findMSchools, {
        data: inputData,
      })
    )) as FindMSchoolsQuery;

    if (response?.findMSchools?.Body?.length) {
      setResultData(response?.findMSchools?.Body as MSchools[]);
    } else {
      setResultData([] as MSchools[]);
    }
  }

  useEffect(() => {}, []);

  return {
    inputData,
    resultData,
    handleClose,
    handleChangeInput,
    handleChangeCheckbox,
    handleClickSearch,
    dialogOpen,
  };
};
export default UseSchoolSearchDialog;
