import { FC, useState } from 'react';
import GenericTemplate from '../../../template/GenericTemplate';
import React from 'react';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import Button from '@mui/material/Button/Button';
import Box from '@mui/material/Box/Box';
import Grid from '@mui/material/Grid/Grid';
import SelectFormControl from '../../../parts/SelectFormControl';
import { SelectValue } from '../../../../types/SelectValue';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import jaLocale from 'date-fns/locale/ja';
import DesktopDatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import {
  Branch,
  FindReserveDateList,
  MSchools,
  Prefecture,
  FindReserveDateRequest,
  Reservation,
  SchoolYearSetting,
} from 'API';
import FormControl from '@mui/material/FormControl/FormControl';
import FormGroup from '@mui/material/FormGroup/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TablePagination from '@mui/material/TablePagination/TablePagination';
import TableHead from '@mui/material/TableHead/TableHead';
import IconButton from '@mui/material/IconButton/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import SchoolSearchDialog from 'views/reserve/reception/dialog/schoolSelect';
import { Link } from '@mui/material';
import { ReserveDateArea } from 'types/ReserveDateArea';
import { CompanyBranch } from 'API';
import BackToTopButton from 'views/parts/BackToTop';

const styles = {
  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
  },

  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 1,
    ml: 1,
  },

  label: {
    mt: '7px',
    textAlign: 'right',
    pr: '10px',
  },

  categoryTitle: {
    borderLeft: 5,
    borderColor: '#0058a0',
    pl: '10px',
  },

  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },

  searchField: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
  },

  textField: {
    width: '100%',
    '& input': {
      padding: '8.5px 14px',
    },
  },

  listButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    m: 0,
  },
};

export interface Props {
  prefectureCode: string;
  schoolPrefectureCode: string;
  schoolYearCode: string;
  companyId: string;
  areaId: string;
  bookingBranchId: string;
  salesBranchId: string;
  fromReserveDay: Date | null;
  toReserveDay: Date | null;
  fromDurationDate: Date | null;
  toDurationDate: Date | null;
  reservationStatusList: Array<string>;
  resultData: FindReserveDateList[];
  page: number;
  rowsPerPage: number;
  handleChangePrefectureCode: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeSchoolPrefectureCode: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeCompany: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeArea: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeBranchName: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeFromReserveDay: (_: Date | null) => void;
  handleChangeToReserveDay: (_: Date | null) => void;
  handleChangeFromDurationDate: (_: Date | null) => void;
  handleChangeToDurationDate: (_: Date | null) => void;
  handleChangeCheckbox: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickSearch: () => void;
  handleSearchSchoolOpen: () => void;
  handleClickDetailButton: (_: FindReserveDateList) => void;
  handleChangeSchoolYearCode: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangePage: (_: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => void;
  handleChangeRowsPerPage: (_: React.ChangeEvent<HTMLInputElement>) => void;

  companyList: CompanyBranch[];
  prefectureList: Prefecture[];
  areaList: ReserveDateArea[];
  branchList: Branch[];
  schoolYearSettingList: SchoolYearSetting[];
  handleSearchSchoolClose: (_?: MSchools) => void;
  searchSchoolOpen: boolean;
  handleResetSearchForm: () => void;
  selectSchool: MSchools;
  inputData: FindReserveDateRequest;
}

const ReserveDateList: FC<Props> = (props) => {
  return (
    <GenericTemplate title="予約日程一覧">
      <Container sx={styles.searchField}>
        <div>
          <BackToTopButton />
        </div>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          検索条件
        </Typography>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={4}>
                <Typography sx={styles.label}>年度</Typography>
              </Grid>
              <Grid item xs={8}>
                <SelectFormControl
                  value={props.schoolYearCode}
                  selectName="SchoolYearCode"
                  selectValues={props.schoolYearSettingList.map((schoolYear, idx) => {
                    return {
                      value: schoolYear.SchoolYear.SchoolYearCode,
                      displayName: schoolYear.SchoolYear.SchoolYearCode,
                    } as SelectValue;
                  })}
                  setCondition={props.handleChangeSchoolYearCode}
                  sx={styles.inputItem}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={3}>
                <Typography sx={styles.label}>宿泊日</Typography>
              </Grid>
              <Grid item xs={8}>
                <Grid container>
                  <Grid item xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
                      <DesktopDatePicker
                        label="yyyy/mm/dd"
                        mask="____/__/__"
                        className="StayStartDate"
                        value={props.fromReserveDay}
                        onChange={(newValue) => props.handleChangeFromReserveDay(newValue)}
                        renderInput={(params) => <TextField {...params} size="small" />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={2} style={{ margin: 'auto', textAlign: 'center' }}>
                    ~
                  </Grid>
                  <Grid item xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
                      <DesktopDatePicker
                        label="yyyy/mm/dd"
                        mask="____/__/__"
                        className="StayEndDate"
                        value={props.toReserveDay}
                        onChange={(newValue) => props.handleChangeToReserveDay(newValue)}
                        renderInput={(params) => <TextField {...params} size="small" />}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={4}>
                <Typography sx={styles.label}>学校所在都道府県</Typography>
              </Grid>
              <Grid item xs={8}>
                <SelectFormControl
                  value={props.schoolPrefectureCode}
                  selectName="SchoolPrefectureCode"
                  selectValues={props.prefectureList.map((prefecture, idx) => {
                    return {
                      value: prefecture.PrefectureCode,
                      displayName: prefecture.PrefectureName,
                    } as SelectValue;
                  })}
                  setCondition={props.handleChangeSchoolPrefectureCode}
                  sx={styles.inputItem}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={3}>
                <Typography sx={styles.label}>学校</Typography>
              </Grid>
              <Grid item xs={8}>
                <Paper component="form" variant="outlined" sx={{ p: '0px 4px', display: 'flex', alignItems: 'center' }}>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    readOnly={true}
                    value={props.selectSchool.SchoolName ? props.selectSchool.SchoolName : ''}
                  />
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton sx={{ p: '6px' }} aria-label="search" onClick={props.handleSearchSchoolOpen}>
                    <SearchIcon />
                  </IconButton>
                </Paper>
                <SchoolSearchDialog
                  handleDialogClose={props.handleSearchSchoolClose}
                  dialogOpen={props.searchSchoolOpen}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={4}>
                <Typography sx={styles.label}>宿泊先都道府県</Typography>
              </Grid>
              <Grid item xs={8}>
                <SelectFormControl
                  value={props.prefectureCode}
                  selectName="PrefectureCode"
                  selectValues={props.prefectureList
                    .filter((x) => x.IsMinpakuArea === 1)
                    .map((prefecture, idx) => {
                      return {
                        value: prefecture.PrefectureCode,
                        displayName: prefecture.PrefectureName,
                      } as SelectValue;
                    })}
                  setCondition={props.handleChangePrefectureCode}
                  sx={styles.inputItem}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={3}>
                <Typography sx={styles.label}>宿泊先地区</Typography>
              </Grid>
              <Grid item xs={8}>
                {props.areaList.length > 0 ? (
                  <SelectFormControl
                    value={props.areaId}
                    selectName="AreaId"
                    selectValues={props.areaList.map((area, idx) => {
                      return { value: area.AreaId, displayName: area.AreaName } as SelectValue;
                    })}
                    setCondition={props.handleChangeArea}
                    sx={styles.inputItem}
                  />
                ) : (
                  <>
                    <SelectFormControl
                      value={'1'}
                      selectName="NoneAreaId"
                      sx={styles.inputItem}
                      isDisabled
                      setCondition={props.handleChangeArea}
                      selectValues={[{ value: '1', displayName: '宿泊先都道府県を選択すると指定できます' }]}
                    />
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={4}>
                <Typography sx={styles.label}>代理店</Typography>
              </Grid>
              <Grid item xs={8}>
                <SelectFormControl
                  value={props.companyId}
                  selectName="AgentCompanyId"
                  selectValues={props.companyList
                    .filter((x) => x.CategoryCode === '03')
                    .map((company, idx) => {
                      return { value: company.CompanyId, displayName: company.CompanyName } as SelectValue;
                    })}
                  setCondition={props.handleChangeCompany}
                  sx={styles.inputItem}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={3}>
                <Typography sx={styles.label}>仮予約期限</Typography>
              </Grid>
              <Grid item xs={8}>
                <Grid container>
                  <Grid item xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
                      <DesktopDatePicker
                        className="ReserveDurationStartDate"
                        label="yyyy/mm/dd"
                        mask="____/__/__"
                        value={props.fromDurationDate}
                        onChange={(newValue) => props.handleChangeFromDurationDate(newValue)}
                        renderInput={(params) => <TextField {...params} size="small" />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={2} style={{ margin: 'auto', textAlign: 'center' }}>
                    ~
                  </Grid>
                  <Grid item xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
                      <DesktopDatePicker
                        className="ReserveDurationEndDate"
                        label="yyyy/mm/dd"
                        mask="____/__/__"
                        value={props.toDurationDate}
                        onChange={(newValue) => props.handleChangeToDurationDate(newValue)}
                        renderInput={(params) => <TextField {...params} size="small" />}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={4}>
                <Typography sx={styles.label}>手配担当</Typography>
              </Grid>
              <Grid item xs={8}>
                {props.branchList.length > 0 ? (
                  <SelectFormControl
                    value={props.bookingBranchId}
                    selectName="BookingBranchId"
                    selectValues={props.branchList.map((branch, idx) => {
                      return { value: branch.BranchId, displayName: branch.BranchName } as SelectValue;
                    })}
                    setCondition={props.handleChangeBranchName}
                    sx={styles.inputItem}
                  />
                ) : (
                  <>
                    <SelectFormControl
                      value={'1'}
                      selectName="NoneBookingBranch"
                      sx={styles.inputItem}
                      isDisabled
                      setCondition={props.handleChangeArea}
                      selectValues={[{ value: '1', displayName: '代理店を選択すると指定できます' }]}
                    />
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={3}>
                <Typography sx={styles.label}>営業担当</Typography>
              </Grid>
              <Grid item xs={8}>
                {props.branchList.length > 0 ? (
                  <SelectFormControl
                    value={props.salesBranchId}
                    selectName="SalesBranchId"
                    selectValues={props.branchList.map((branch, idx) => {
                      return { value: branch.BranchId, displayName: branch.BranchName } as SelectValue;
                    })}
                    setCondition={props.handleChangeBranchName}
                    sx={styles.inputItem}
                  />
                ) : (
                  <>
                    <SelectFormControl
                      value={'1'}
                      selectName="NoneSalesBranch"
                      sx={styles.inputItem}
                      isDisabled
                      setCondition={props.handleChangeArea}
                      selectValues={[{ value: '1', displayName: '代理店を選択すると指定できます' }]}
                    />
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={2}>
            <Typography sx={styles.label}>予約ステータス</Typography>
          </Grid>
          <Grid item xs={10}>
            <FormControl component="fieldset">
              <FormGroup style={{ display: 'block' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="ReservationStatusList"
                      value={1}
                      onChange={props.handleChangeCheckbox}
                      checked={props.reservationStatusList.indexOf('1') > -1}
                    />
                  }
                  label="取消待ち"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="ReservationStatusList"
                      value={2}
                      onChange={props.handleChangeCheckbox}
                      checked={props.reservationStatusList.indexOf('2') > -1}
                    />
                  }
                  label="仮予約"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="ReservationStatusList"
                      value={3}
                      onChange={props.handleChangeCheckbox}
                      checked={props.reservationStatusList.indexOf('3') > -1}
                    />
                  }
                  label="学校申込"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="ReservationStatusList"
                      value={4}
                      onChange={props.handleChangeCheckbox}
                      checked={props.reservationStatusList.indexOf('4') > -1}
                    />
                  }
                  label="学校決定"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="ReservationStatusList"
                      value={5}
                      onChange={props.handleChangeCheckbox}
                      checked={props.reservationStatusList.indexOf('5') > -1}
                    />
                  }
                  label="本予約申込"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="ReservationStatusList"
                      value={6}
                      onChange={props.handleChangeCheckbox}
                      checked={props.reservationStatusList.indexOf('6') > -1}
                    />
                  }
                  label="本決定"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="ReservationStatusList"
                      value={9}
                      onChange={props.handleChangeCheckbox}
                      checked={props.reservationStatusList.indexOf('9') > -1}
                    />
                  }
                  label="キャンセル"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button variant="outlined" sx={styles.button} onClick={props.handleResetSearchForm}>
            検索条件リセット
          </Button>
          <Button variant="contained" sx={styles.button} onClick={props.handleClickSearch}>
            検索
          </Button>
        </Box>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          予約一覧
        </Typography>
        <TableContainer sx={{ minHeight: 300 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>宿泊日</TableCell>
                <TableCell>地区名</TableCell>
                <TableCell>学校</TableCell>
                <TableCell>人数</TableCell>
                <TableCell>予約ステータス</TableCell>
                <TableCell>仮予約期限</TableCell>
                <TableCell>予約ID</TableCell>
                <TableCell>代理店</TableCell>
                <TableCell>詳細</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.resultData
                .slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage)
                .map((reserve, index) => (
                  <TableRow key={index}>
                    <TableCell component="td" scope="row">
                      {reserve.ReservedDay}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {reserve.AreaName}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {reserve.SchoolName}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {reserve.GuestCount}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {reserve.ReservationStatus}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {reserve.DurationDate}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {reserve.ReservationId}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {reserve.AgentList}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      <Link onClick={() => props.handleClickDetailButton(reserve)}>
                        <Button variant="contained" sx={styles.listButton} size="small">
                          詳細
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={props.resultData.length}
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          onPageChange={props.handleChangePage}
          onRowsPerPageChange={props.handleChangeRowsPerPage}
        />
      </Container>
    </GenericTemplate>
  );
};

export default ReserveDateList;
