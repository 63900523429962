import FormErrorAttribute from 'types/FormErrorAttribute';

export interface ErrorData {
  Header: FormErrorAttribute;
  CenterConfirmationDoc: FormErrorAttribute;
}

export const CreateEmptyErrorData = () => {
  return {
    Header: {} as FormErrorAttribute,
    CenterConfirmationDoc: {} as FormErrorAttribute,
  } as ErrorData;
};
