import FormErrorAttribute from 'types/FormErrorAttribute';

export interface ErrorData {
  Header: FormErrorAttribute;
  CopyReservationId: FormErrorAttribute;
  SchoolId: FormErrorAttribute;
  HasGroupingName: FormErrorAttribute;
  GroupingName: FormErrorAttribute;
  HasAnotherResv: FormErrorAttribute;
  GuestCount: FormErrorAttribute;
  ReservationDays: FormErrorAttribute;
  CompanyId: FormErrorAttribute;
  CompanyName: FormErrorAttribute;
  BookingBranchId: FormErrorAttribute;
  BookingUserName: FormErrorAttribute;
  BookingUserTel: FormErrorAttribute;
  BookingUserMail: FormErrorAttribute;
  SalesBranchId: FormErrorAttribute;
  SalesUserName: FormErrorAttribute;
  SalesUserTel: FormErrorAttribute;
  SalesUserMail: FormErrorAttribute;
  Note: FormErrorAttribute;
}

export const CreateEmptyErrorData = () => {
  return {
    Header: {} as FormErrorAttribute,
    CopyReservationId: {} as FormErrorAttribute,
    SchoolId: {} as FormErrorAttribute,
    HasGroupingName: {} as FormErrorAttribute,
    GroupingName: {} as FormErrorAttribute,
    HasAnotherResv: {} as FormErrorAttribute,
    GuestCount: {} as FormErrorAttribute,
    ReservationDays: {} as FormErrorAttribute,
    CompanyId: {} as FormErrorAttribute,
    CompanyName: {} as FormErrorAttribute,
    BookingBranchId: {} as FormErrorAttribute,
    BookingUserName: {} as FormErrorAttribute,
    BookingUserTel: {} as FormErrorAttribute,
    BookingUserMail: {} as FormErrorAttribute,
    SalesBranchId: {} as FormErrorAttribute,
    SalesUserName: {} as FormErrorAttribute,
    SalesUserTel: {} as FormErrorAttribute,
    SalesUserMail: {} as FormErrorAttribute,
    Note: {} as FormErrorAttribute,
  } as ErrorData;
};
