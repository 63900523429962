import FormErrorAttribute from 'types/FormErrorAttribute';

export interface ErrorData {
  Header: FormErrorAttribute;
  ClassCode: FormErrorAttribute;
  GroupCode: FormErrorAttribute;
  AllocateStudentList: FormErrorAttribute;
}

export const CreateEmptyErrorData = () => {
  return {
    Header: {} as FormErrorAttribute,
    ClassCode: {} as FormErrorAttribute,
    GroupCode: {} as FormErrorAttribute,
    AllocateStudentList: {} as FormErrorAttribute,
  } as ErrorData;
};
