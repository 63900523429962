import {
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Link,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { RegistApplyReservation, ReservationAgent, Service } from 'API';
import React, { FC } from 'react';
import { SelectValue } from 'types/SelectValue';
import SelectFormControl from 'views/parts/SelectFormControl';
import { ErrorData } from './ApplicationDialogErrorData';

export interface Props {
  errorAttribute: ErrorData;
  dialogOpen: boolean;
  inputData: RegistApplyReservation;
  reservedDays: string[];
  services: Service[];
  reservationAgents: ReservationAgent[];
  anotherReservationAgents: ReservationAgent[];
  guestCount: number;
  handleChangeReservedDay: (_: boolean, idx: number) => void;
  handleChangeService: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeGuestCount: (_: React.FocusEvent<HTMLInputElement>) => void;
  handleChangeReservationAgent: (_: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  handleChangeItinerary: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleDeleteItinerary: () => void;
  handleChangeAgentConfirmationDoc: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleDeleteAgentConfirmationDoc: () => void;
  handleClose: () => void;
  handleRegist: () => void;
}

const styles = {
  categoryHeader: {
    fontWeight: 'bold',
  },
  itemHeader: {
    backgroundColor: '#ececec',
    fontWeight: 'bold',
  },
  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
  },
  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 1,
    ml: 1,
  },
  label: {
    mt: '7px',
  },
  categoryTitle: {
    borderLeft: 5,
    borderColor: '#0058a0',
    pl: '10px',
  },
  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },
  listButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    m: 0,
  },
  headerButton: {
    minWidth: '120px',
    fontWeight: 'bold',
  },
  headerMessage: {
    color: '#d32f2f',
    fontWeight: 'bold',
  },
  fileLink: {
    mt: '7px',
    mr: '10px',
    wordBreak: 'break-all',
    overflowWrap: 'break-word',
  },
};

const ApplicationDialog: FC<Props> = ({
  errorAttribute,
  dialogOpen,
  inputData,
  reservedDays,
  services,
  reservationAgents,
  anotherReservationAgents,
  guestCount,
  handleChangeReservedDay,
  handleChangeService,
  handleChangeGuestCount,
  handleChangeReservationAgent,
  handleChangeItinerary,
  handleDeleteItinerary,
  handleChangeAgentConfirmationDoc,
  handleDeleteAgentConfirmationDoc,
  handleClose,
  handleRegist,
}) => {
  return (
    <Dialog open={dialogOpen} onClose={handleClose} maxWidth={'md'}>
      <DialogTitle>
        本予約申請
        {errorAttribute.Header.ErrorMessage && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl component="fieldset" error={errorAttribute.Header.IsError}>
                <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                  {errorAttribute.Header.ErrorMessage}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          宿泊する日を選択してください。日帰りの場合は、日帰り体験日を選択してください。
        </Typography>
        <Grid container spacing={2} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            宿泊日 *
          </Grid>
          <Grid item xs={9}>
            <FormControl required error={errorAttribute.ReservedDays.IsError}>
              {reservedDays.map((x, idx) => (
                <FormControlLabel
                  key={idx}
                  label={x!}
                  control={<Checkbox />}
                  onChange={(e, val) => handleChangeReservedDay(val, idx)}
                />
              ))}
              <FormHelperText>{errorAttribute.ReservedDays.ErrorMessage}</FormHelperText>
            </FormControl>
            <Typography variant="subtitle2">
              ・別の予約IDに紐づく予約日程を選択したい場合は、予約センターにお問合せ下さい。同一年度、同一学校、同一エリアに限り、予約IDが異なる予約をまとめることができます。
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          プランを選択してください。
        </Typography>
        <Grid container spacing={2} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>プラン *</Typography>
          </Grid>
          <Grid item xs={9}>
            <SelectFormControl
              value={inputData.ServiceId ?? ''}
              selectName="ServiceId"
              selectValues={services.map((x) => {
                return { value: x.ServiceId, displayName: x.ServiceDisplayName } as SelectValue;
              })}
              setCondition={handleChangeService}
              sx={styles.inputItem}
              error={errorAttribute.ServiceId.IsError}
              errorMessage={errorAttribute.ServiceId.ErrorMessage}
            />
            <Typography variant="subtitle2">
              ・各プランには予約受付開始日が設定されており、通常、宿泊プランは予約日の３年前、日帰りプランは予約日の１年前からの受付です。
            </Typography>
            <Typography variant="subtitle2">・予約受付開始日前のプランは表示されません。</Typography>
          </Grid>
        </Grid>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          人数を指定してください。
        </Typography>
        <Grid container spacing={2} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>人数 *</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              required
              hiddenLabel
              name="GuestCount"
              value={inputData.GuestCount ?? ''}
              onChange={handleChangeGuestCount}
              size="small"
              sx={styles.inputItem}
              error={errorAttribute.GuestCount.IsError}
              helperText={errorAttribute.GuestCount.ErrorMessage}
              inputProps={{ type: 'number', min: 1, max: guestCount }}
            />
          </Grid>
        </Grid>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          代理店を選択してください。
        </Typography>
        <Grid container spacing={2} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            代理店 *
          </Grid>
          <Grid item xs={9}>
            <FormControl required error={errorAttribute.CompanyId.IsError}>
              <RadioGroup value={inputData.CompanyId ?? ''} onChange={handleChangeReservationAgent}>
                {reservationAgents.map((x, idx) => (
                  <FormControlLabel key={idx} label={x.CompanyName!} value={x.CompanyId} control={<Radio />} />
                ))}
              </RadioGroup>
              {anotherReservationAgents &&
                anotherReservationAgents.map((x, idx) => (
                  <FormControlLabel key={idx} label={x.CompanyName!} value={x.CompanyId} control={<Radio />} disabled />
                ))}
              <FormHelperText>{errorAttribute.CompanyId.ErrorMessage}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          行程表をアップロードしてください。
        </Typography>
        <Grid container spacing={2} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>行程表</Typography>
          </Grid>
          <Grid item xs={9}>
            {!!inputData.ItineraryName && (
              <Link
                target="_blank"
                rel="noopener noreferrer"
                sx={styles.fileLink}
                href={inputData.Itinerary!}
                download={inputData.ItineraryName}
              >
                {inputData.ItineraryName}
              </Link>
            )}
            {!!inputData.ItineraryName ? (
              <Button variant="outlined" onClick={handleDeleteItinerary}>
                削除
              </Button>
            ) : (
              <label htmlFor="itinerary">
                <input id="itinerary" type="file" hidden onChange={handleChangeItinerary} />
                <Button variant="outlined" component="span">
                  参照
                </Button>
              </label>
            )}
            <FormControl error={errorAttribute.Itinerary.IsError}>
              <FormHelperText>{errorAttribute.Itinerary.ErrorMessage}</FormHelperText>
            </FormControl>
            <Typography variant="subtitle2">
              ・アップロードできるファイルは、拡張子が「.doc(.docx)」、「.xls(.xlsx)」、「.pdf」のものに限ります。
            </Typography>
            <Typography variant="subtitle2">・ファイルサイズは、「500KB」以下にしてください。</Typography>
          </Grid>
        </Grid>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          決定通知書をアップロードしてください。
        </Typography>
        <Grid container spacing={2} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.fileLink}>決定通知書 *</Typography>
          </Grid>
          <Grid item xs={9}>
            {!!inputData.AgentConfirmationDocName && (
              <Link
                target="_blank"
                rel="noopener noreferrer"
                sx={styles.fileLink}
                href={inputData.AgentConfirmationDoc!}
                download={inputData.AgentConfirmationDocName}
              >
                {inputData.AgentConfirmationDocName}
              </Link>
            )}
            {!!inputData.AgentConfirmationDocName ? (
              <Button variant="outlined" onClick={handleDeleteAgentConfirmationDoc}>
                削除
              </Button>
            ) : (
              <label htmlFor="AgentConfirmationDoc">
                <input id="AgentConfirmationDoc" type="file" hidden onChange={handleChangeAgentConfirmationDoc} />
                <Button variant="outlined" component="span">
                  参照
                </Button>
              </label>
            )}
            <FormControl error={errorAttribute.AgentConfirmationDoc.IsError}>
              <FormHelperText>{errorAttribute.AgentConfirmationDoc.ErrorMessage}</FormHelperText>
            </FormControl>
            <Typography variant="subtitle2">
              ・アップロードできるファイルは、拡張子が「.doc(.docx)」、「.xls(.xlsx)」、「.pdf」のものに限ります。
            </Typography>
            <Typography variant="subtitle2">・ファイルサイズは、「500KB」以下にしてください。</Typography>
          </Grid>
        </Grid>

        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" sx={styles.button} onClick={handleRegist}>
              申請する
            </Button>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" sx={styles.button} onClick={handleClose}>
              閉じる
            </Button>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
export default ApplicationDialog;
