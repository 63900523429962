import { useCallback, useState, useEffect } from 'react';
import React from 'react';
import {
  MServices,
  ServiceRate,
  CompanyBranch,
  ServiceRequest,
  FindServiceDetailQuery,
  ServiceRateRequest,
  RegistServiceMutation,
  UpdateServiceMutation,
  DeleteServiceRateMutation,
  CodeMaster,
} from '../../../../API';
import { useLocation, useNavigate } from 'react-router';
import { useMaster } from 'contexts/CommonMasterContext';
import { useAPI } from 'contexts/APIRequestContext';
import { findServiceDetail } from 'graphql/queries';
import { registService, updateService, deleteServiceRate } from 'graphql/mutations';
import { CreateEmptyErrorData, ErrorData } from './ServiceDetailErrorData';
import { checkAuthority } from 'util/AuthorityCheck';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';
import { scrollToTop } from 'util/Scroll';

const queryString = require('query-string');
const UseServiceDetail = () => {
  const location = useLocation();
  var servicePara = location.state as MServices;
  const [isUpdate, setIsUpdate] = useState(Boolean);
  const [, setError] = useState<undefined>();
  const master = useMaster();
  const api = useAPI();
  const [serviceData, setServiceData] = useState({} as MServices);
  const [serviceRateData, setServiceRateData] = useState([] as ServiceRate[]);
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const navigator = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertDialogCallback, setAlertDialogCallback] = useState({ fn: (_: boolean) => {} });
  const [companyList, setCompanyList] = useState([] as CompanyBranch[]);
  const [serviceCategoryList, setServiceCategoryList] = useState([] as CodeMaster[]);
  const { user } = useAuthenticator();
  const userData = new UserData(user);

  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  /**
   * ブラウザバック
   */
  const back = (): void => {
    window.history.back();
  };

  /**
   * 警告ダイアログ　閉じる
   * @param result 結果
   */
  const handleAlertClose = (result: boolean): void => {
    alertDialogCallback.fn(result);
    setAlertOpen(false);
  };

  const handleChangeDetail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    setServiceData({ ...serviceData, [name]: value });
  };

  const handleChangeFromDate = (value: Date | null) => {
    setServiceData({ ...serviceData, FromDate: value?.toLocaleDateString() });
  };

  const handleChangeToDate = (value: Date | null) => {
    setServiceData({ ...serviceData, ToDate: value?.toLocaleDateString() });
  };

  ///////////////////////////////////////////////////////////////////////////////
  // サービス料金登録更新削除
  ///////////////////////////////////////////////////////////////////////////////
  const handleClickServiceRateEditButton = (serviceRate: ServiceRateRequest) => {
    navigator('/settings/service/price?mode=update', {
      state: {
        ServiceRateId: serviceRate.ServiceRateId,
        ServiceId: serviceRate.ServiceId,
        Rate: serviceRate.Rate,
        FromDate: serviceRate.FromDate,
        EndDate: serviceRate.EndDate,
        UpdateDatetime: serviceRate.UpdateDatetime,
      } as ServiceRateRequest,
    });
  };

  const handleClickServiceRateCreateButton = () => {
    navigator('/settings/service/price?mode=new', {
      state: {
        ServiceId: servicePara.ServiceId,
      } as ServiceRateRequest,
    });
  };

  const handleClickServiceRateDeleteButton = async (serviceRate: ServiceRate): Promise<void> => {
    setAlertDialogCallback({
      fn: async (result: boolean) => {
        if (result) {
          dltServiceRate(serviceRate);
        }
      },
    });
    setAlertContent('削除します。よろしいですか？');
    setAlertOpen(true);
  };

  async function dltServiceRate(serviceRate: ServiceRate) {
    // エラー表示内容をクリア
    setErrorAttribute({
      ...CreateEmptyErrorData(),
      Header: { IsError: true, ErrorMessage: '' },
    });

    let serviceRateReq = {
      ServiceRateId: serviceRate.ServiceRateId,
      Rate: serviceRate.Rate,
      UpdateDatetime: serviceRate.UpdateDatetime,
    } as ServiceRateRequest;

    const response = (
      (await api.graphql({
        query: deleteServiceRate,
        variables: { data: serviceRateReq },
      })) as DeleteServiceRateMutation
    ).deleteServiceRate;

    if (response?.IsSuccess) {
      // リストから削除に成功したレコードを除外
      let newServiceRateList = serviceRateData.filter((item) => item.ServiceRateId != serviceRate.ServiceRateId);
      setServiceRateData(newServiceRateList);
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  ///////////////////////////////////////////////////////////////////////////////
  // サービス情報登録更新削除
  ///////////////////////////////////////////////////////////////////////////////
  const handleClickRegist = () => {
    if (isUpdate) {
      updateServiceDetail();
    } else {
      registServiceDetail();
    }
  };

  const moveNextAfterRegist = async (id: Number): Promise<void> => {
    setAlertDialogCallback({
      fn: async (result: boolean) => {
        if (result) {
          // 編集モードで再読み込み
          navigator('/settings/service/detail?mode=update', {
            state: {
              __typename: 'MServices',
              ServiceId: String(id),
            } as MServices,
          });
        } else {
          back();
        }
      },
    });
    setAlertContent('サービス情報を登録しました。引き続き、サービス料金を登録しますか？');
    setAlertOpen(true);
  };

  async function registServiceDetail() {
    const serviceRequest = {
      CompanyId: serviceData.CompanyId,
      ServiceCategoryCode: serviceData.ServiceCategoryCode,
      ServiceName: serviceData.ServiceName,
      ServiceDisplayName: serviceData.ServiceDisplayName,
      ReservationStartDays: serviceData.ReservationStartDays,
      IsAdmin: serviceData.IsAdmin,
      StayNights: serviceData.StayNights,
      Meals: serviceData.Meals,
      OfferingHours: serviceData.OfferingHours,
      OfferingDayDiv: serviceData.OfferingDayDiv,
      FromDate: serviceData.FromDate,
      ToDate: serviceData.ToDate,
      DisplayOrder: serviceData.DisplayOrder,
      IsActive: serviceData.IsActive,
    } as ServiceRequest;

    const response = (
      (await api.graphql({
        query: registService,
        variables: { data: serviceRequest },
      })) as RegistServiceMutation
    ).registService;

    if (response?.IsSuccess) {
      setIsUpdate(true);
      setErrorAttribute(CreateEmptyErrorData());
      moveNextAfterRegist(Number(response.Body));
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
      scrollToTop();
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
      scrollToTop();
    } else {
      throwError();
    }
  }

  async function updateServiceDetail() {
    const serviceRequest = {
      ServiceId: serviceData.ServiceId,
      CompanyId: serviceData.CompanyId,
      ServiceCategoryCode: serviceData.ServiceCategoryCode,
      ServiceName: serviceData.ServiceName,
      ServiceDisplayName: serviceData.ServiceDisplayName,
      ReservationStartDays: serviceData.ReservationStartDays,
      IsAdmin: serviceData.IsAdmin,
      StayNights: serviceData.StayNights,
      Meals: serviceData.Meals,
      OfferingHours: serviceData.OfferingHours,
      OfferingDayDiv: serviceData.OfferingDayDiv,
      FromDate: serviceData.FromDate,
      ToDate: serviceData.ToDate,
      DisplayOrder: serviceData.DisplayOrder,
      IsActive: serviceData.IsActive,
      UpdateDatetime: serviceData.UpdateDatetime,
    } as ServiceRequest;

    const response = (
      (await api.graphql({
        query: updateService,
        variables: { data: serviceRequest },
      })) as UpdateServiceMutation
    ).updateService;

    if (response?.IsSuccess) {
      // 新規登録後、編集モードで再読みしている為、back すると新規モードに遷移してしまうため、
      // 直接一覧を指定（直したい・・）
      // back();
      navigator('/settings/service/list');
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
      scrollToTop();
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
      scrollToTop();
    } else {
      throwError();
    }
  }

  ///////////////////////////////////////////////////////////////////////////////
  // 初期表示処理
  ///////////////////////////////////////////////////////////////////////////////
  async function fetchServiceData(id: Number) {
    const response = (await api.graphql({
      query: findServiceDetail,
      variables: {
        ServiceId: id,
      },
    })) as FindServiceDetailQuery;
    if (response?.findServiceDetail?.IsSuccess) {
      if (response?.findServiceDetail?.Body) {
        let serviceResponse = response.findServiceDetail.Body.Service as MServices;
        setServiceData({
          ...serviceData,
          ['ServiceId']: serviceResponse.ServiceId,
          ['CompanyId']: serviceResponse.CompanyId,
          ['ServiceCategoryCode']: serviceResponse.ServiceCategoryCode,
          ['ServiceName']: serviceResponse.ServiceName,
          ['ServiceDisplayName']: serviceResponse.ServiceDisplayName,
          ['ReservationStartDays']: serviceResponse.ReservationStartDays,
          ['IsAdmin']: serviceResponse.IsAdmin,
          ['StayNights']: serviceResponse.StayNights,
          ['Meals']: serviceResponse.Meals,
          ['OfferingHours']: serviceResponse.OfferingHours,
          ['OfferingDayDiv']: serviceResponse.OfferingDayDiv,
          ['FromDate']: serviceResponse.FromDate,
          ['ToDate']: serviceResponse.ToDate,
          ['DisplayOrder']: serviceResponse.DisplayOrder,
          ['IsActive']: serviceResponse.IsActive,
          ['UpdateDatetime']: serviceResponse.UpdateDatetime,
        });

        setServiceRateData(response.findServiceDetail.Body.ServiceRate as ServiceRate[]);

        //        setCompanyBranchList(master.getPrefecture());
      }
    } else if (response?.findServiceDetail?.IsSystemError) {
      throwError(response?.findServiceDetail?.ErrorData ?? '');
    } else if (response?.findServiceDetail?.IsInputCheckError && response?.findServiceDetail?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.findServiceDetail?.ErrorData) as ErrorData),
      });
    } else if (response?.findServiceDetail?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.findServiceDetail?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('ServiceDetail', userData.authorityCode)) {
      navigator('/');
    }

    master.waitForInitialized(() => {
      setCompanyList(master.getCompanies());
      setServiceCategoryList(master.getCodeMaster().filter((x) => x.CodeKind === 'service_category_code'));
    });

    if (queryString.parse(location.search).mode === 'update') {
      setIsUpdate(true);
      fetchServiceData(Number(servicePara.ServiceId));
    } else {
      setIsUpdate(false);

      // 初期値をセットする
      setServiceData({
        ...serviceData,
        ['ReservationStartDays']: '0',
        ['IsAdmin']: '0',
        ['StayNights']: '0',
        ['Meals']: '0',
        ['OfferingHours']: '0',
        ['OfferingDayDiv']: '1',
        ['FromDate']: '1900/01/01',
        ['ToDate']: '2099/12/31',
        ['DisplayOrder']: '1',
        ['IsActive']: '1',
      });
    }
  }, []);

  return {
    isUpdate,
    serviceData,
    serviceRateData,
    errorAttribute,
    alertOpen,
    alertContent,
    companyList,
    serviceCategoryList,
    handleChangeDetail,
    handleChangeFromDate,
    handleChangeToDate,
    handleClickRegist,
    handleClickServiceRateCreateButton,
    handleClickServiceRateEditButton,
    handleClickServiceRateDeleteButton,
    handleAlertClose,
  };
};
export default UseServiceDetail;
