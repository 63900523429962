import React, { useEffect, useState, useCallback } from 'react';
import { registAreaService, updateAreaService } from 'graphql/mutations';
import { useLocation } from 'react-router';
import {
  UpdateAreaServiceMutation,
  RegistAreaServiceMutation,
  AreaServiceRequest,
  CompanyBranch,
  CodeMaster,
  MServices,
} from 'API';
import { CreateEmptyErrorData, ErrorData } from './AreaServiceSelectErrorData';
import { useAPI } from 'contexts/APIRequestContext';
import { useMaster } from 'contexts/CommonMasterContext';

const UseAreaServiceSelect = () => {
  const api = useAPI();
  const queryString = require('query-string');
  const location = useLocation();
  const areaServicePara = location.state as AreaServiceRequest;
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [areaServiceData, setAreaServiceData] = useState({} as AreaServiceRequest);
  const [, setError] = useState<undefined>();
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);
  var isUpdate = queryString.parse(location.search).mode === 'update';
  const [companyList, setCompanyList] = useState([] as CompanyBranch[]);
  const master = useMaster();
  const [serviceCategoryList, setServiceCategoryList] = useState([] as CodeMaster[]);
  const [serviceList, setServiceList] = useState([] as MServices[]);
  const [companyId, setCompanyId] = useState('');
  const [serviceCategoryCode, setServiceCategoryCode] = useState('');

  /**
   * ブラウザバック
   */
  const back = (): void => {
    window.history.back();
  };

  const handleChangeDetail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    setAreaServiceData({ ...areaServiceData, [name]: value });
  };

  const handleClickRegist = () => {
    if (isUpdate) {
      update();
    } else {
      regist();
    }
  };

  async function regist() {
    const response = (
      (await api.graphql({
        query: registAreaService,
        variables: { data: areaServiceData },
      })) as RegistAreaServiceMutation
    ).registAreaService;

    if (response?.IsSuccess) {
      back();
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  async function update() {
    const response = (
      (await api.graphql({
        query: updateAreaService,
        variables: { data: areaServiceData },
      })) as UpdateAreaServiceMutation
    ).updateAreaService;

    if (response?.IsSuccess) {
      back();
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  const handleChangeCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Step1. 会社ＩＤ変更とサービスクリア
    setCompanyId(event.target.value);
    setAreaServiceData({ ...areaServiceData, ServiceId: '' });

    // Step2. サービスプルダウンセット
    setServiceList(
      master
        .getServices()
        .filter((x) => x.CompanyId === event.target.value && x.ServiceCategoryCode === serviceCategoryCode)
    );
  };

  const handleChangeServiceCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Step1. 会社ＩＤ入力値変更とサービスクリア
    setServiceCategoryCode(event.target.value);
    setAreaServiceData({ ...areaServiceData, ServiceId: '' });

    // Step2. サービスプルダウンセット
    setServiceList(
      master.getServices().filter((x) => x.CompanyId === companyId && x.ServiceCategoryCode === event.target.value)
    );
  };

  const handleChangeFromDate = (value: Date) => {
    setAreaServiceData({ ...areaServiceData, FromDate: value.toLocaleDateString() });
  };

  const handleChangeToDate = (value: Date) => {
    setAreaServiceData({ ...areaServiceData, ToDate: value.toLocaleDateString() });
  };

  useEffect(() => {
    master.waitForInitialized(() => {
      setCompanyList(master.getCompanies());
      setServiceCategoryList(master.getCodeMaster().filter((x) => x.CodeKind === 'service_category_code'));
    });
    if (isUpdate) {
      let selectedService = master.getServices().filter((x) => x.ServiceId === areaServicePara.ServiceId);
      setServiceList(selectedService);
      setCompanyId(selectedService[0].CompanyId ?? '');
      setServiceCategoryCode(selectedService[0].ServiceCategoryCode ?? '');
      setAreaServiceData(areaServicePara as AreaServiceRequest);
    } else {
      // 新規登録の場合、初期値のある値を予めセットする
      setAreaServiceData({
        AreaId: areaServicePara.AreaId,
        FromDate: '1900/01/01',
        ToDate: '2099/12/31',
      } as unknown as AreaServiceRequest);
    }
  }, []);

  return {
    areaServiceData,
    isUpdate,
    errorAttribute,
    companyList,
    serviceCategoryList,
    serviceList,
    companyId,
    serviceCategoryCode,
    handleChangeDetail,
    handleClickRegist,
    handleChangeCompany,
    handleChangeServiceCategory,
    handleChangeFromDate,
    handleChangeToDate,
  };
};

export default UseAreaServiceSelect;
