import FormErrorAttribute from 'types/FormErrorAttribute';

export interface ErrorData {
  Header: FormErrorAttribute;
  LastName: FormErrorAttribute;
  FirstName: FormErrorAttribute;
  LastKanaName: FormErrorAttribute;
  FirstKanaName: FormErrorAttribute;
  BirthDay: FormErrorAttribute;
  Sex: FormErrorAttribute;
  Occupation: FormErrorAttribute;
  ResidentsRelationCode: FormErrorAttribute;
  Tel: FormErrorAttribute;
  Mail: FormErrorAttribute;
  WorkHistory: FormErrorAttribute;
  Character: FormErrorAttribute;
}

export const CreateEmptyErrorData = () => {
  return {
    Header: {} as FormErrorAttribute,
    LastName: {} as FormErrorAttribute,
    FirstName: {} as FormErrorAttribute,
    LastKanaName: {} as FormErrorAttribute,
    FirstKanaName: {} as FormErrorAttribute,
    BirthDay: {} as FormErrorAttribute,
    Sex: {} as FormErrorAttribute,
    Occupation: {} as FormErrorAttribute,
    ResidentsRelationCode: {} as FormErrorAttribute,
    Tel: {} as FormErrorAttribute,
    Mail: {} as FormErrorAttribute,
    WorkHistory: {} as FormErrorAttribute,
    Character: {} as FormErrorAttribute,
  } as ErrorData;
};
