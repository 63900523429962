import { useCallback, useEffect, useState } from 'react';

import {
  ClearAllocateStudentMutation,
  DeleteAllocateStudentMutation,
  FindClassStudentListQuery,
  Student,
  UpdateAllocateStudentMutation,
} from 'API';
import { useAPI } from 'contexts/APIRequestContext';
import { clearAllocateStudent, deleteAllocateStudent, updateAllocateStudent } from 'graphql/mutations';
import { findClassStudentList } from 'graphql/queries';
import { useLocation } from 'react-router-dom';
import { CreateEmptyErrorData, ErrorData } from './StudentAllocateErrorData';
import { useNavigate } from 'react-router-dom';
import { checkAuthority } from 'util/AuthorityCheck';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';

const UseStudentAllocation = () => {
  const location = useLocation();
  const state = location.state as any;
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [classCode, setClassCode] = useState<number | null>(null);
  const [groupCode, setGroupCode] = useState('');
  const [hasStudent, setHasStudent] = useState(0);
  const [classCodeList, setClassCodeList] = useState<number[]>([]);
  const [studentList, setStudentList] = useState([] as Student[]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertDialogCallback, setAlertDialogCallback] = useState({ fn: (_: boolean) => {} });
  const [, setError] = useState<undefined>();
  const api = useAPI();
  const navigator = useNavigate();
  const { user } = useAuthenticator();
  const userData = new UserData(user);

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('StudentAllocation', userData.authorityCode)) {
      navigator('/');
    }

    const classCode = state.ClassCode ?? true ? state.ClassCode : null;
    setClassCode(classCode);
    const glassCode = state.GroupCode;
    setGroupCode(glassCode);
    fetchClassStudentList(classCode, glassCode);
  }, []);

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  const handleUpdate = () => {
    updateAllocate();
  };

  const handleChangeClassCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (hasStudent) {
      setAlertDialogCallback({
        fn: (result: boolean) => {
          if (result) {
            const classCode = event.target.value ? Number(event.target.value) : null;
            setClassCode(classCode);
            clearAllocate(classCode);
          }
        },
      });
      setAlertContent('組を切り替えると、この民家に生徒割り済みの内容をクリアします。よろしいですか？');
      setAlertOpen(true);
    } else {
      const classCode = event.target.value ? Number(event.target.value) : null;
      setClassCode(classCode);
      fetchClassStudentList(classCode, groupCode);
    }
  };

  const handleChangeGroupCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const groupCode = event.target.value;
    setGroupCode(groupCode);
  };

  const clearAllocate = async (classCode: number | null): Promise<void> => {
    setErrorAttribute(CreateEmptyErrorData());

    const response = (
      (await api.graphql({
        query: clearAllocateStudent,
        variables: {
          data: {
            ReservationId: state.ReservationId,
            MinkaId: state.MinkaId,
            UpdateDatetime: state.UpdateDatetime,
          },
        },
      })) as ClearAllocateStudentMutation
    ).clearAllocateStudent;

    if (response?.IsSuccess) {
      const students = response.Body?.Students as Student[];
      setStudentList(students);

      const classCodeList = response.Body?.ClassCodes ?? [];
      setClassCodeList(classCodeList);
      setClassCode(null);
      setGroupCode('');
      state.ClassCode = null;
      state.GroupCode = null;
      state.UpdateDatetime = response.Body?.UpdateDatetime;

      setHasStudent(response.Body?.HasStudent!);

      if (classCode) {
        fetchClassStudentList(classCode, '');
      }
    } else if (response?.IsSystemError) {
      throwError(response.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  };

  const updateAllocate = async (): Promise<void> => {
    setErrorAttribute(CreateEmptyErrorData());

    const response = (
      (await api.graphql({
        query: updateAllocateStudent,
        variables: {
          data: {
            ReservationId: state.ReservationId,
            MinkaId: state.MinkaId,
            ClassCode: classCode,
            GroupCode: groupCode,
            AllocateStudentList: studentList
              .filter((x) => x.IsSelected)
              .map((x) => {
                return { MinkaId: state.MinkaId, StudentNo: x.StudentNo };
              }),
            UpdateDatetime: state.UpdateDatetime,
          },
        },
      })) as UpdateAllocateStudentMutation
    ).updateAllocateStudent;

    if (response?.IsSuccess) {
      window.history.back();
    } else if (response?.IsSystemError) {
      throwError(response.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  };

  const fetchClassStudentList = async (classCode: number | null, groupCode: string): Promise<void> => {
    setErrorAttribute(CreateEmptyErrorData());

    const response = (
      (await api.graphql({
        query: findClassStudentList,
        variables: {
          data: {
            ReservationId: state.ReservationId,
            MinkaId: state.MinkaId,
            ClassCode: classCode,
            GroupCode: groupCode,
            UpdateDatetime: state.UpdateDatetime,
          },
        },
      })) as FindClassStudentListQuery
    ).findClassStudentList;

    if (response?.IsSuccess) {
      const students = response.Body?.Students as Student[];
      setStudentList(students);

      const classCodeList = response.Body?.ClassCodes ?? [];
      setClassCodeList(classCodeList);
      setClassCode(classCode);
      setGroupCode(groupCode);
      setHasStudent(response.Body?.HasStudent!);

      state.UpdateDatetime = response.Body?.UpdateDatetime;
    } else if (response?.IsSystemError) {
      throwError(response.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  };

  const handleChangeCheck = (event: React.ChangeEvent<HTMLInputElement>, index: number): void => {
    studentList[index].IsSelected = event.target.checked;
    setStudentList(studentList);
  };

  const handleDelete = async (): Promise<void> => {
    setAlertDialogCallback({
      fn: async (result: boolean) => {
        if (result) {
          setErrorAttribute(CreateEmptyErrorData());

          const response = (
            (await api.graphql({
              query: deleteAllocateStudent,
              variables: {
                data: {
                  ReservationId: state.ReservationId,
                  MinkaId: state.MinkaId,
                  UpdateDatetime: state.UpdateDatetime,
                },
              },
            })) as DeleteAllocateStudentMutation
          ).deleteAllocateStudent;

          if (response?.IsSuccess) {
            const students = response.Body?.Students as Student[];
            setStudentList(students);

            const classCodeList = response.Body?.ClassCodes ?? [];
            setClassCodeList(classCodeList);
            setClassCode(null);
            setGroupCode('');
            state.ClassCode = null;
            state.GroupCode = null;
            state.UpdateDatetime = response.Body?.UpdateDatetime;

            setHasStudent(response.Body?.HasStudent!);
          } else if (response?.IsSystemError) {
            throwError(response.ErrorData ?? '');
          } else if (response?.IsInputCheckError && response?.ErrorData) {
            setErrorAttribute({
              ...CreateEmptyErrorData(),
              ...(JSON.parse(response?.ErrorData) as ErrorData),
            });
          } else if (response?.ErrorData) {
            setErrorAttribute({
              ...CreateEmptyErrorData(),
              Header: { IsError: true, ErrorMessage: response?.ErrorData },
            });
          } else {
            throwError();
          }
        }
      },
    });
    setAlertContent('この民家に生徒割り済みの内容をクリアします。よろしいですか？');
    setAlertOpen(true);
  };

  /**
   * 警告ダイアログ　閉じる
   * @param result 結果
   */
  const handleAlertClose = (result: boolean): void => {
    alertDialogCallback.fn(result);
    setAlertOpen(false);
  };

  return {
    errorAttribute,
    hasStudent,
    studentList,
    classCode,
    groupCode,
    classCodeList,
    alertOpen,
    alertContent,
    handleChangeClassCode,
    handleChangeGroupCode,
    handleChangeCheck,
    handleUpdate,
    handleDelete,
    handleAlertClose,
  };
};
export default UseStudentAllocation;
