import { useCallback, useEffect, useState } from 'react';

const UseSessionInvalidMessageDialog = ({
  handleDialogClose,
  dialogOpen,
}: {
  handleDialogClose: () => void;
  dialogOpen: boolean;
}) => {
  const [, setError] = useState<undefined>();

  useEffect(() => {
    if (dialogOpen) {
    }
  }, [dialogOpen]);

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  /**
   * 閉じる
   */
  const handleClose = (): void => {
    handleDialogClose();
  };

  return {
    handleClose,
    dialogOpen,
  };
};
export default UseSessionInvalidMessageDialog;
