import { Container } from '@mui/material';
import Button from '@mui/material/Button/Button';
import FormControl from '@mui/material/FormControl/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import Grid from '@mui/material/Grid/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography/Typography';
import { RegionRequest } from 'API';
import React, { FC } from 'react';
import GenericTemplate from '../../../template/GenericTemplate';
import { ErrorData } from './RegionDetailErrorData';
import FormHelperText from '@mui/material/FormHelperText';
import BackToTopButton from 'views/parts/BackToTop';

const styles = {
  searchField: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
    paddingLeft: '100px',
  },

  label: {
    margin: 'auto',
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
    mr: '10px',
  },

  radioLabel: {
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
    mr: '10px',
  },

  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
  },

  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 3,
    mr: 1,
    float: 'right',
  },

  dataBlock: {
    '& > div': {
      width: '100%',
    },
  },

  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },

  textField: {
    width: '100%',
    '& input': {
      padding: '8px',
    },
  },
};

export interface Props {
  isUpdate: boolean;
  regionData: RegionRequest;
  errorAttribute: ErrorData;
  handleChangeDetail: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickRegist: () => void;
}

const RegionDetail: FC<Props> = (props) => {
  const pushback = function () {
    window.history.back();
  };

  return (
    <GenericTemplate>
      <div>
        <BackToTopButton />
      </div>
      <Container sx={styles.searchField}>
        {props.errorAttribute.Header.ErrorMessage && (
          <Grid container spacing={2} sx={{ pl: 0, pb: 2 }}>
            <Grid item xs={12}>
              <FormControl component="fieldset" error={props.errorAttribute.Header.IsError}>
                <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                  {props.errorAttribute.Header.ErrorMessage}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        )}
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          地域詳細
        </Typography>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>地域コード</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              sx={styles.textField}
              name={'RegionCode'}
              value={props.regionData.RegionCode ?? ''}
              onChange={props.handleChangeDetail}
              inputProps={{ maxLength: 2 }}
              error={props.errorAttribute.RegionCode.IsError}
              helperText={props.errorAttribute.RegionCode.ErrorMessage}
              disabled={props.isUpdate}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>地域名</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              sx={styles.textField}
              name={'RegionName'}
              value={props.regionData.RegionName ?? ''}
              onChange={props.handleChangeDetail}
              inputProps={{ maxLength: 10 }}
              error={props.errorAttribute.RegionName.IsError}
              helperText={props.errorAttribute.RegionName.ErrorMessage}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>有効区分</Typography>
          </Grid>
          <Grid item xs={7}>
            <RadioGroup
              row
              name="IsActive"
              aria-label="position"
              value={props.regionData.IsActive === '1' ? 1 : 0}
              onChange={props.handleChangeDetail}
            >
              <FormControlLabel value={1} control={<Radio color="primary" />} label="有効" />
              <FormControlLabel value={0} control={<Radio color="primary" />} label="無効" />
            </RadioGroup>
            <FormHelperText>{props.errorAttribute.IsActive.ErrorMessage}</FormHelperText>
          </Grid>
        </Grid>
        <Button variant="contained" sx={styles.button} onClick={pushback}>
          閉じる
        </Button>
        <Button variant="contained" sx={styles.button} onClick={props.handleClickRegist}>
          登録
        </Button>
      </Container>
    </GenericTemplate>
  );
};
export default RegionDetail;
