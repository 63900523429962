import { ReservationDay } from 'API';
import { FC } from 'react';
import AddDaysDialog from './AddDaysDialog';
import UseAddDaysDialog from './UseAddDaysDialog';

export interface Props {
  handleDialogClose: (_?: { days: ReservationDay[]; updateDatetime: string }) => void;
  dialogOpen: boolean;
  reservationId: string;
  updateDatetime: string;
  initialValue: string;
}

const AddDaysDialogContainer: FC<Props> = (props: Props) => {
  return <AddDaysDialog {...UseAddDaysDialog(props)} />;
};

export default AddDaysDialogContainer;
