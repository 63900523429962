import { FindStudentQuery, RegistStudentMutation, UpdateStudentMutation, StudentRequest } from 'API';
import { graphqlOperation } from 'aws-amplify';
import { registStudent, updateStudent } from 'graphql/mutations';
import { findStudent } from 'graphql/queries';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useAPI } from '../../../../contexts/APIRequestContext';
import { CreateEmptyErrorData, ErrorData } from './StudentDetailErrorData';
import { UserData } from 'models/UserData';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserCategoryCode } from 'constant/Constant';
import { useNavigate } from 'react-router-dom';
import { checkAuthority } from 'util/AuthorityCheck';
import { scrollToTop } from 'util/Scroll';

const UseStudentDetail = () => {
  const queryString = require('query-string');
  const location = useLocation();
  var isUpdate = queryString.parse(location.search).mode === 'update';
  const student = location.state as StudentRequest;
  const api = useAPI();
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [status, setStatus] = useState(false);
  const [, setError] = useState<undefined>();
  const [studentDetailData, setStudentDetailData] = useState({} as StudentRequest);
  const { user } = useAuthenticator();
  const userData = new UserData(user);
  const isMinpakuCompany = userData.userCategoryCode === UserCategoryCode.MinpakuCompany;
  const navigator = useNavigate();

  const handleChangeDetail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    setStudentDetailData({ ...studentDetailData, [name]: value });
  };

  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  const handleClickRegist = () => {
    if (isUpdate) {
      updateStudentDetail();
    } else {
      createStudentDetail();
    }
  };

  /**
   * ブラウザバック
   */
  const back = (): void => {
    window.history.back();
  };

  async function createStudentDetail() {
    const response = (
      (await api.graphql({
        query: registStudent,
        variables: { data: studentDetailData },
      })) as RegistStudentMutation
    ).registStudent;

    if (response?.IsSuccess) {
      back();
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
      scrollToTop();
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
      scrollToTop();
    } else {
      throwError();
    }
  }

  async function updateStudentDetail() {
    // 編集モード初期起動時に Student として生徒情報を取得しているため、
    // StudentRequest に詰めなおしている
    const studentReq = {
      ReservationId: studentDetailData.ReservationId,
      ClassCode: studentDetailData.ClassCode,
      StudentNo: studentDetailData.StudentNo,
      StudentName: studentDetailData.StudentName,
      StudentKanaName: studentDetailData.StudentKanaName,
      Sex: studentDetailData.Sex,
      SexDisplayName: studentDetailData.SexDisplayName,
      MinkaId: studentDetailData.MinkaId,
      MinkaName: studentDetailData.MinkaName,
      Allergy: studentDetailData.Allergy,
      Disease: studentDetailData.Disease,
      Consern: studentDetailData.Consern,
      Notice: studentDetailData.Notice,
      Memo: studentDetailData.Memo,
      IsActive: studentDetailData.IsActive,
      CompanyId: studentDetailData.CompanyId,
      UpdateDatetime: studentDetailData.UpdateDatetime,
    } as StudentRequest;

    const response = (
      (await api.graphql({
        query: updateStudent,
        variables: { data: studentReq },
      })) as UpdateStudentMutation
    ).updateStudent;

    if (response?.IsSuccess) {
      back();
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
      scrollToTop();
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
      scrollToTop();
    } else {
      throwError();
    }
  }

  async function fetchStudentDeail() {
    if (isUpdate) {
      setStatus(true);
      const response = (await api.graphql(
        graphqlOperation(findStudent, {
          data: {
            ReservationId: student.ReservationId,
            ClassCode: student.ClassCode,
            StudentNo: student.StudentNo,
          },
        })
      )) as FindStudentQuery;

      if (response?.findStudent?.Body) {
        setStudentDetailData(response?.findStudent?.Body as StudentRequest);
      } else {
        setStudentDetailData({} as StudentRequest);
      }
    } else {
      // 新規登録の場合、初期値のある値を予めセットする
      setStudentDetailData({
        ReservationId: student.ReservationId,
        Sex: '1',
        IsActive: '1',
      } as unknown as StudentRequest);
    }
  }

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('StudentDetail', userData.authorityCode)) {
      navigator('/');
    }

    fetchStudentDeail();
  }, []);

  return {
    studentDetailData,
    handleChangeDetail,
    status,
    handleClickRegist,
    errorAttribute,
    isMinpakuCompany,
  };
};
export default UseStudentDetail;
