import { graphqlOperation } from 'aws-amplify';
import { useAPI } from 'contexts/APIRequestContext';
import { findCompanyList } from 'graphql/queries';
import { deleteCompany } from 'graphql/mutations';
import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import {
  Company,
  CompanyRequest,
  CompanyBasicRequest,
  FindCompanyListRequest,
  FindCompanyListQuery,
  DeleteCompanyMutation,
} from 'API';
import { UserCategoryCode } from 'constant/Constant';
import { CreateEmptyErrorData, ErrorData } from './CompanyListErrorData';
import { checkAuthority } from 'util/AuthorityCheck';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';

const UseCompanyList = () => {
  const [inputData, setInputData] = useState({} as FindCompanyListRequest);
  const [companyName, setCompanyName] = useState('');
  const [isActive, setIsActive] = useState('1');
  const [categoryCode, setCategoryCode] = useState(UserCategoryCode.MinpakuCompany);
  const [resultData, setResultData] = useState([] as Company[]);
  const [, setError] = useState<undefined>();
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const api = useAPI();
  const navigator = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertDialogCallback, setAlertDialogCallback] = useState({ fn: (_: boolean) => {} });
  const { user } = useAuthenticator();
  const userData = new UserData(user);

  const defalutPage = 0;
  const defaultRowsPerPage = 25;
  const [page, setPage] = useState(defalutPage);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  const handleChangeCompanyName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCompanyName(value);
    setInputData({ ...inputData, CompanyName: value });
  };

  const handleChangeIsActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setIsActive(value);
    setInputData({ ...inputData, IsActive: value });
  };

  const handleChangeCategoryCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCategoryCode(value);
    setInputData({ ...inputData, CategoryCode: value });
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    setInputData({ ...inputData, [name]: value });
  };

  const handleClickSearch = () => {
    fetchCompanies();
    setPage(defalutPage);
    setRowsPerPage(defaultRowsPerPage);
  };

  const handleClickCreateButton = () => {
    // ローカルストレージまたはセッションストレージに検索条件を退避する
    localStorage.setItem('CompanyList_SearchConditions', JSON.stringify({ inputData, rowsPerPage, page }));
    // 戻った時に状態を復元するフラグをセット
    sessionStorage.setItem('CompanyList_ShouldRestore', 'true');

    navigator('/settings/company/detail?mode=new');
  };

  const handleClickDetailButton = (company: Company) => {
    // ローカルストレージまたはセッションストレージに検索条件を退避する
    localStorage.setItem('CompanyList_SearchConditions', JSON.stringify({ inputData, rowsPerPage, page }));
    // 戻った時に状態を復元するフラグをセット
    sessionStorage.setItem('CompanyList_ShouldRestore', 'true');
    navigator('/settings/company/detail?mode=update', {
      state: {
        __typename: 'Company',
        CompanyId: company.CompanyId,
      } as Company,
    });
  };

  const handleClickDeleteButton = async (company: Company): Promise<void> => {
    setAlertDialogCallback({
      fn: async (result: boolean) => {
        if (result) {
          deleteCmy(company);
        }
      },
    });
    setAlertContent('削除します。よろしいですか？');
    setAlertOpen(true);
  };

  async function fetchCompanies() {
    await fetchAndSetCompanies(inputData);
  }
  async function fetchCompaniesWithParams(params: FindCompanyListRequest) {
    await fetchAndSetCompanies(params);
  }

  async function fetchAndSetCompanies(params: FindCompanyListRequest) {
    const response = (await api.graphql(
      graphqlOperation(findCompanyList, {
        data: params,
      })
    )) as FindCompanyListQuery;

    if (response?.findCompanyList?.Body?.length) {
      setResultData(response.findCompanyList.Body as Company[]);
    } else {
      setResultData([] as Company[]);
    }
  }

  async function deleteCmy(company: Company) {
    // エラー表示内容をクリア
    setErrorAttribute({
      ...CreateEmptyErrorData(),
      Header: { IsError: true, ErrorMessage: '' },
    });

    const companyBasic = {
      CompanyId: company.CompanyId,
      CompanyName: company.CompanyName,
      UpdateDatetime: company.UpdateDatetime,
    } as CompanyBasicRequest;

    const companyRequest = {
      CompanyBasic: companyBasic,
    } as CompanyRequest;

    const response = (
      (await api.graphql({
        query: deleteCompany,
        variables: { data: companyRequest },
      })) as DeleteCompanyMutation
    ).deleteCompany;

    if (response?.IsSuccess) {
      // リストから削除に成功したレコードを除外
      let newCompanyList = resultData.filter((item) => item.CompanyId != company.CompanyId);
      setResultData(newCompanyList);
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  /**
   * 警告ダイアログ　閉じる
   * @param result 結果
   */
  const handleAlertClose = (result: boolean): void => {
    alertDialogCallback.fn(result);
    setAlertOpen(false);
  };

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('CompanyList', userData.authorityCode)) {
      navigator('/');
    }

    // 詳細画面から戻ってきた場合、ローカルストレージに退避していた情報を復元する
    const shouldRestore = sessionStorage.getItem('CompanyList_ShouldRestore');
    if (shouldRestore) {
      const storedConditionsString = localStorage.getItem('CompanyList_SearchConditions');
      let storedConditions = JSON.parse(storedConditionsString ?? '');
      if (storedConditions) {
        setCompanyName(storedConditions?.inputData?.CompanyName ?? '');
        setIsActive(storedConditions?.inputData?.IsActive ?? '');
        setCategoryCode(storedConditions?.inputData?.CategoryCode ?? '');

        setInputData(storedConditions?.inputData);
        fetchCompaniesWithParams(storedConditions?.inputData);
        setRowsPerPage(storedConditions?.rowsPerPage);
        setPage(storedConditions?.page);
      }
    } else {
      // NOTE: 変更を検知して条件をセットしている為、初期値のある項目は起動時に条件指定する。
      inputData.CategoryCode = UserCategoryCode.MinpakuCompany;
      inputData.IsActive = '1';
    }

    // フラグをリセット
    sessionStorage.removeItem('CompanyList_ShouldRestore');
  }, []);

  return {
    companyName,
    isActive,
    categoryCode,
    resultData,
    errorAttribute,
    alertOpen,
    alertContent,
    page,
    rowsPerPage,
    handleChangeCategoryCode,
    handleChangeIsActive,
    handleChangeCompanyName,
    handleChangeInput,
    handleClickSearch,
    handleClickDeleteButton,
    handleClickCreateButton,
    handleClickDetailButton,
    handleAlertClose,
    handleChangePage,
    handleChangeRowsPerPage,
  };
};
export default UseCompanyList;
