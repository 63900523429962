import { FindNotificationQuery, Notification } from 'API';
import { graphqlOperation } from 'aws-amplify';
import { useAPI } from 'contexts/APIRequestContext';
import { findNotification } from 'graphql/queries';
import { useEffect, useState } from 'react';

const UseHome = () => {
  const [systemNotifications, setSystemNotifications] = useState([] as Notification[]);
  const [reserveNotifications, setReserveNotifications] = useState([] as Notification[]);
  const api = useAPI();

  async function fetchNotifications() {
    const response = (await api.graphql(graphqlOperation(findNotification))) as FindNotificationQuery;

    if (response?.findNotification?.Body?.SystemNotification?.length) {
      setSystemNotifications(response?.findNotification?.Body?.SystemNotification as Notification[]);
    } else {
      setSystemNotifications([] as Notification[]);
    }
    if (response?.findNotification?.Body?.ReserveNotification?.length) {
      setReserveNotifications(response?.findNotification?.Body?.ReserveNotification as Notification[]);
    } else {
      setReserveNotifications([] as Notification[]);
    }
  }

  useEffect(() => {
    fetchNotifications();
  }, []);

  return {
    systemNotifications,
    reserveNotifications,
  };
};
export default UseHome;
