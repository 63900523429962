import { useState, useEffect, useCallback } from 'react';
import {
  CompanyBranch,
  Branch,
  MSchools,
  UserRequest,
  User,
  FindUsersQuery,
  DeleteUserMutation,
  FindMSchoolsQuery,
} from 'API';
import React from 'react';
import { useAPI } from 'contexts/APIRequestContext';
import { useMaster } from 'contexts/CommonMasterContext';
import { UserCategoryCode } from 'constant/Constant';
import { findMSchools, findUsers } from 'graphql/queries';
import { deleteUser } from 'graphql/mutations';
import { graphqlOperation } from 'aws-amplify';
import { CreateEmptyErrorData, ErrorData } from './UserListErrorData';
import { useNavigate } from 'react-router-dom';
import { checkAuthority } from 'util/AuthorityCheck';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';

const UseUserList = () => {
  const [userCategoryCode, setUserCategoryCode] = useState('UserCategoryCode.MinpakuCompany');
  const [canAllDept, setCanAllDept] = useState('9');
  const [userName, setUserName] = useState('');
  const [userKanaName, setUserKanaName] = useState('');
  const [userId, setUserId] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [branchId, setBranchId] = useState('');
  const [schoolId, setSchoolId] = useState('');
  const [isActive, setIsActive] = useState('1');
  const [resultData, setResultData] = useState([] as User[]);
  const [selectSchool, setSelectSchool] = useState({} as MSchools);
  const [searchSchoolOpen, setSearchSchoolOpen] = useState(false);
  const [inputData, setInputData] = useState({
    IsActive: '1',
    UserCategoryCode: UserCategoryCode.MinpakuCompany,
  } as UserRequest);
  const [companyList, setCompanyList] = useState([] as CompanyBranch[]);
  const [branchList, setBranchList] = useState([] as Branch[]);
  const master = useMaster();
  const api = useAPI();
  const [showSchool, setShowSchool] = useState(false);
  const [showCompany, setShowCompany] = useState(false);
  const [schoolList, setSchoolList] = useState([] as MSchools[]);
  const [, setError] = useState<undefined>();
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const navigator = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertDialogCallback, setAlertDialogCallback] = useState({ fn: (_: boolean) => {} });
  const { user } = useAuthenticator();
  const userData = new UserData(user);

  const defalutPage = 0;
  const defaultRowsPerPage = 25;

  const [page, setPage] = useState(defalutPage);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  const handleChangeUserCategoryCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const categoryCode = event.target.value;
    setUserCategoryCode(categoryCode);
    setCanAllDept('9');
    if (categoryCode === UserCategoryCode.School) {
      // 学校の場合、会社選択欄を非表示、学校選択を表示
      fetchSchools();
      setShowCompany(false);
      setShowSchool(true);
      setSelectSchool({} as MSchools);
      setCompanyId('');
      setBranchId('');
      setInputData({
        ...inputData,
        CompanyId: '',
        BranchId: '',
        SchoolId: '',
        CanAllDept: '9',
        UserCategoryCode: categoryCode,
      });
    } else if (categoryCode === UserCategoryCode.Minka) {
      // 民家の場合、学校選択を非表示
      setShowCompany(true);
      setShowSchool(false);
      setSelectSchool({} as MSchools);
      setCompanyId('');
      setBranchId('');
      setInputData({
        ...inputData,
        CompanyId: '',
        BranchId: '',
        SchoolId: '',
        CanAllDept: '9',
        UserCategoryCode: categoryCode,
      });
      // 事業者種別で絞り込み
      setCompanyList(
        master.getCompanies().filter((x) => x.IsActive && x.CategoryCode === UserCategoryCode.MinpakuCompany)
      );
    } else {
      // それ以外の場合、会社選択欄を表示、学校選択を非表示
      setShowCompany(true);
      setShowSchool(false);
      setSelectSchool({} as MSchools);
      setCompanyId('');
      setBranchId('');
      setInputData({
        ...inputData,
        CompanyId: '',
        BranchId: '',
        SchoolId: '',
        CanAllDept: '9',
        UserCategoryCode: categoryCode,
      });
      // 事業者種別で絞り込み
      setCompanyList(master.getCompanies().filter((x) => x.IsActive && x.CategoryCode === categoryCode));
    }
    setBranchList([] as Branch[]);
    setResultData([] as User[]);
  };

  const changeDisplayItemBasedOnUserCategory = (categoryCode: string) => {
    if (categoryCode === UserCategoryCode.School) {
      // 学校の場合、会社選択欄を非表示、学校選択を表示
      setShowCompany(false);
      setShowSchool(true);
    } else {
      // それ以外の場合、会社選択欄を表示、学校選択を非表示
      setShowCompany(true);
      setShowSchool(false);
    }
  };

  const handleChangeCanAllDept = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCanAllDept(value);
    setInputData({ ...inputData, CanAllDept: value });
  };

  const handleChangeUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setUserName(value);
    setInputData({ ...inputData, UserName: value });
  };

  const handleChangeUserKanaName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setUserKanaName(value);
    setInputData({ ...inputData, UserKanaName: value });
  };

  const handleChangeUserId = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setUserId(value);
    setInputData({ ...inputData, UserId: value });
  };

  const handleChangeCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCompanyId(value);
    changeCompany(event.target.value);
  };

  /**
   * 代理店情報　代理店名更新
   */
  const changeCompany = (companyId: string) => {
    // Step1. 通常の入力値変更と店名クリア
    inputData.CompanyId = companyId;
    inputData.BranchId = '';

    setInputData({
      ...inputData,
      CompanyId: companyId,
      BranchId: '',
    });

    // Step2. 支店プルダウンデータ変更
    setBranchPullDownList(companyId);
  };

  /**
   * 旅行代理店支店プルダウンリストを更新する
   */
  const setBranchPullDownList = (companyId: string) => {
    const _branchList = master.getBranchs(companyId) as Branch[];
    if (_branchList) {
      setBranchList(_branchList);
    } else {
      setBranchList([] as Branch[]);
    }
  };

  const handleChangeBranch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setBranchId(value);
    setInputData({ ...inputData, BranchId: value });
  };

  const handleChangeSchool = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSchoolId(value);
    setInputData({ ...inputData, SchoolId: value });
  };

  const handleChangeIsActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setIsActive(value);
    setInputData({ ...inputData, IsActive: value });
  };

  const handleChangeDetail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    setInputData({ ...inputData, [name]: value });
    setErrorAttribute({
      ...errorAttribute,
      [event.target.name]: { IsError: false, ErrorMessage: '' },
    });
  };

  const handleSearchSchoolOpen = (): void => {
    setSearchSchoolOpen(true);
  };

  const handleSearchUser = (): void => {
    fetchUsers();
    setPage(defalutPage);
    setRowsPerPage(defaultRowsPerPage);
  };

  const handleDeleteUser = (userId: string): void => {
    setAlertDialogCallback({
      fn: async (result: boolean) => {
        if (result) {
          executeDeleteUser(userId);
        }
      },
    });
    setAlertContent('削除します。この処理は元に戻せません。よろしいですか？');
    setAlertOpen(true);
  };

  async function executeDeleteUser(userId: string) {
    const response = (
      (await api.graphql(
        graphqlOperation(deleteUser, {
          data: userId,
        })
      )) as DeleteUserMutation
    ).deleteUser;

    if (response?.IsSuccess) {
      setResultData(resultData.filter((x) => x.UserId !== userId));
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  async function fetchUsers() {
    await fetchAndSetUsers(inputData);
  }

  async function fetchUsersWithParams(params: UserRequest) {
    await fetchAndSetUsers(params);
  }

  async function fetchAndSetUsers(params: UserRequest) {
    const response = (await api.graphql(
      graphqlOperation(findUsers, {
        data: params,
      })
    )) as FindUsersQuery;

    if (response?.findUsers?.Body?.length) {
      const userList = response.findUsers.Body as User[];

      if (params.UserCategoryCode === UserCategoryCode.School) {
        completionSchools(userList);
      } else {
        userList.forEach((user) => {
          user.CompanyName = master.getCompanies().find((company) => user.CompanyId === company.CompanyId)?.CompanyName;
        });
      }

      setResultData(userList);
    } else {
      setResultData([] as User[]);
    }
  }

  async function completionSchools(userList: User[]) {
    if (schoolList.length) {
      // 学校マスタ取得済の場合、キャッシュした値から学校名を検索
      userList.forEach((user) => {
        user.SchoolName = schoolList.find((school) => user.SchoolId === school.SchoolId)?.SchoolName;
      });
    } else {
      const storedConditionsString = localStorage.getItem('UserList_SearchConditions');
      let storedConditions = JSON.parse(storedConditionsString ?? '') as { schoolList: MSchools[] };
      if (storedConditions) {
        const restoredSchoolList = storedConditions?.schoolList;
        if (restoredSchoolList) {
          setSchoolList(storedConditions.schoolList);
          userList.forEach((user) => {
            user.SchoolName = restoredSchoolList.find((school) => user.SchoolId === school.SchoolId)?.SchoolName;
          });
        }
      } else {
        // 学校マスタ未取得の場合、取得＆学校名を検索
        const response = (await api.graphql(
          graphqlOperation(findMSchools, {
            data: {},
          })
        )) as FindMSchoolsQuery;

        if (response?.findMSchools?.Body?.length) {
          var responseSchoolList = response.findMSchools.Body;
          setSchoolList(responseSchoolList);
          userList.forEach((user) => {
            user.SchoolName = responseSchoolList.find((school) => user.SchoolId === school.SchoolId)?.SchoolName;
          });
        }
      }
    }
    setResultData(userList);
  }

  async function fetchSchools() {
    // 既に学校リストが取得されている場合は何もしない
    if (schoolList.length > 0) return;

    // 学校マスタ未取得の場合、取得＆学校名を検索
    try {
      const response = (await api.graphql(
        graphqlOperation(findMSchools, {
          data: {},
        })
      )) as FindMSchoolsQuery;

      // レスポンスに学校情報があれば、ステートを更新
      if (response?.findMSchools?.Body?.length) {
        setSchoolList(response.findMSchools.Body);
      }
    } catch (error) {
      console.error('Failed to fetch schools:', error);
    }
  }

  const handleSearchSchoolClose = (school?: MSchools): void => {
    if (school) {
      setSelectSchool(school);
      setInputData({
        ...inputData,
        SchoolName: school.SchoolName,
        SchoolId: school.SchoolId,
      });
    } else {
      setInputData({ ...inputData, SchoolName: '', SchoolId: '' });
    }
    setSearchSchoolOpen(false);
  };

  /**
   * 警告ダイアログ　閉じる
   * @param result 結果
   */
  const handleAlertClose = (result: boolean): void => {
    alertDialogCallback.fn(result);
    setAlertOpen(false);
  };

  const saveStateToLocalStorage = () => {
    // ローカルストレージまたはセッションストレージに検索条件を退避する
    localStorage.setItem(
      'UserList_SearchConditions',
      JSON.stringify({ inputData, selectSchool, schoolList, rowsPerPage, page })
    );
    // 戻った時に状態を復元するフラグをセット
    sessionStorage.setItem('UserList_ShouldRestore', 'true');
  };

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('UserList', userData.authorityCode)) {
      navigator('/');
    }

    // 詳細画面から戻ってきた場合、ローカルストレージに退避していた情報を復元する
    const shouldRestore = sessionStorage.getItem('UserList_ShouldRestore');
    if (shouldRestore) {
      const storedConditionsString = localStorage.getItem('UserList_SearchConditions');
      let storedConditions = JSON.parse(storedConditionsString ?? '');
      if (storedConditions) {
        const restoredInputData = storedConditions?.inputData;
        setSchoolList(storedConditions.schoolList);
        fetchUsersWithParams(restoredInputData); // 復元した検索条件を直接引数として渡す
        changeDisplayItemBasedOnUserCategory(restoredInputData.UserCategoryCode);
        setUserCategoryCode(restoredInputData.UserCategoryCode);
        setCanAllDept(restoredInputData.CanAllDept);
        setUserName(restoredInputData.UserName);
        setUserKanaName(restoredInputData.UserKanaName);
        setUserId(restoredInputData.UserId);

        if (restoredInputData.UserCategoryCode === UserCategoryCode.Minka) {
          setCompanyList(
            master.getCompanies().filter((x) => x.IsActive && x.CategoryCode === UserCategoryCode.MinpakuCompany)
          );
        } else {
          setCompanyList(
            master.getCompanies().filter((x) => x.IsActive && x.CategoryCode === restoredInputData.UserCategoryCode)
          );
        }
        setCompanyId(restoredInputData.CompanyId);

        // 先に支店のプルダウンリストを旅行代理店IDに基づき設定する
        setBranchPullDownList(restoredInputData.CompanyId);
        setBranchId(restoredInputData.BranchId ?? '');

        setSchoolId(restoredInputData.SchoolId);
        setIsActive(restoredInputData.IsActive);
        setSelectSchool(storedConditions?.selectSchool ?? ({} as MSchools));
        setInputData(restoredInputData);
        setRowsPerPage(storedConditions?.rowsPerPage);
        setPage(storedConditions?.page);
      }
    } else {
      // 初期表示時は、利用者種別：民泊事業者なので、会社リストを表示の上、選択肢を絞りこむ
      master.waitForInitialized(() => {
        setCompanyList(
          master.getCompanies().filter((x) => x.IsActive && x.CategoryCode === UserCategoryCode.MinpakuCompany)
        );
      });

      setUserCategoryCode(UserCategoryCode.MinpakuCompany);
      setIsActive('1');
      setCanAllDept('9');
      setShowCompany(true);
    }

    // フラグをリセット
    sessionStorage.removeItem('UserList_ShouldRestore');
  }, []);

  return {
    userCategoryCode,
    canAllDept,
    userName,
    userKanaName,
    userId,
    companyId,
    branchId,
    schoolId,
    isActive,
    inputData,
    companyList,
    branchList,
    resultData,
    searchSchoolOpen,
    showSchool,
    showCompany,
    selectSchool,
    errorAttribute,
    alertOpen,
    alertContent,
    page,
    rowsPerPage,
    handleChangeUserCategoryCode,
    handleChangeCanAllDept,
    handleChangeUserName,
    handleChangeUserKanaName,
    handleChangeUserId,
    handleChangeCompany,
    handleChangeBranch,
    handleChangeSchool,
    handleChangeIsActive,
    handleSearchSchoolOpen,
    handleSearchSchoolClose,
    handleChangeDetail,
    handleSearchUser,
    handleDeleteUser,
    handleAlertClose,
    saveStateToLocalStorage,
    handleChangePage,
    handleChangeRowsPerPage,
  };
};
export default UseUserList;
