import React, { useState, createContext, useContext } from 'react';
import Backdrop from '@mui/material/Backdrop';
import LinearProgress from '@mui/material/LinearProgress';
import { API } from 'aws-amplify';
import { GraphQLResult, GraphQLOptions } from '@aws-amplify/api-graphql';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const APIRequestContext = createContext({
  graphql: async (
    options: GraphQLOptions,
    additionalHeaders?: {
      [key: string]: string;
    }
  ): Promise<object | undefined> => {
    return {};
  },
  showErrorMessage: (_: string) => {},
  isRequesting: false,
});

export const APIRequestContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [isRequesting, setIsRequesting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const graphql = async (
    options: GraphQLOptions,
    additionalHeaders?: {
      [key: string]: string;
    }
  ): Promise<object | undefined> => {
    setIsRequesting(true);
    const response = (await API.graphql(options, additionalHeaders)) as GraphQLResult;
    setIsRequesting(false);
    if (response.errors) {
      setErrorMessage(response.errors[0].message);
      return undefined;
    } else {
      return response.data;
    }
  };
  const showErrorMessage = (message: string) => {
    setErrorMessage(message);
  };

  const handleClose = () => {
    setErrorMessage('');
  };

  return (
    <APIRequestContext.Provider
      value={{
        graphql,
        showErrorMessage,
        isRequesting,
      }}
    >
      <Dialog open={errorMessage !== ''} onClose={handleClose}>
        <DialogTitle>エラー発生</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop sx={{ zIndex: 2147483, color: '#fff' }} open={isRequesting}>
        <LinearProgress color="primary" sx={{ width: '90vh' }} />
      </Backdrop>
      {children}
    </APIRequestContext.Provider>
  );
};
export const useAPI = () => useContext(APIRequestContext);
export default APIRequestContextProvider;
