import { UpdateGuestInfoMutation } from 'API';
import { useAPI } from 'contexts/APIRequestContext';
import { useMaster } from 'contexts/CommonMasterContext';
import { updateGuestInfo } from 'graphql/mutations';
import { useCallback, useEffect, useState } from 'react';
import { GuestInfo } from 'types/GuestInfo';
import { CreateEmptyErrorData, ErrorData } from './GuestInfoDialogErrorData';

const UseGuestInfoDialog = ({
  handleDialogClose,
  dialogOpen,
  guestInfo,
  reservationId,
  updateDatetime,
}: {
  handleDialogClose: (_?: GuestInfo, updateDatetime?: string) => void;
  dialogOpen: boolean;
  guestInfo: GuestInfo;
  reservationId: string;
  updateDatetime: string;
}) => {
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [inputData, setInputData] = useState({} as GuestInfo);
  const master = useMaster();
  const api = useAPI();
  const [, setError] = useState<undefined>();

  useEffect(() => {
    if (dialogOpen) {
      // 初期化
      setInputData(guestInfo!);

      setErrorAttribute({ ...CreateEmptyErrorData() });
    }
  }, [dialogOpen]);

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  /**
   * 宿泊者情報　宿泊者情報更新
   * @param event イベント
   */
  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'HasAnotherResv') {
      setInputData({ ...inputData, [event.target.name]: Number(event.target.value) });
    } else {
      setInputData({ ...inputData, [event.target.name]: event.target.value });
    }

    // 値が変更されたらエラー情報をクリア
    setErrorAttribute({ ...errorAttribute, [event.target.name]: { isError: false, errorMessage: '' } });
  };

  /**
   * 宿泊者情報　団別管理更新
   * @param event イベント
   */
  const handleChangeHasGroupingName = (event: React.ChangeEvent<HTMLInputElement>) => {
    inputData.HasGroupingName = Number(event.target.value);
    if (inputData.HasGroupingName !== 1) {
      inputData.GroupingName = '';
    }
    setInputData({ ...inputData, HasGroupingName: inputData.HasGroupingName, GroupingName: inputData.GroupingName });

    // 値が変更されたらエラー情報をクリア
    setErrorAttribute({
      ...errorAttribute,
      HasGroupingName: { IsError: false, ErrorMessage: '' },
      GroupingName: { IsError: false, ErrorMessage: '' },
    });
  };

  /**
   * 宿泊者情報更新処理
   */
  async function executeUpdateGuestInfo() {
    const response = (
      (await api.graphql({
        query: updateGuestInfo,
        variables: {
          data: {
            ReservationId: reservationId,
            HasAnotherResv: inputData.HasAnotherResv,
            HasGroupingName: inputData.HasGroupingName,
            GroupingName: inputData.GroupingName,
            UpdateDatetime: updateDatetime,
          },
        },
      })) as UpdateGuestInfoMutation
    ).updateGuestInfo;

    if (response?.IsSuccess) {
      handleDialogClose(inputData, response.Body!);
    } else if (response?.IsSystemError) {
      throwError(response.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  /**
   * 宿泊人数　登録
   */
  const handleRegist = (): void => {
    if (Object.values(errorAttribute).some((x) => x.IsError)) {
      return;
    }

    executeUpdateGuestInfo();
  };

  /**
   * 宿泊人数　閉じる
   */
  const handleClose = (): void => {
    handleDialogClose();
  };

  return {
    inputData,
    handleChangeInput,
    handleChangeHasGroupingName,
    handleRegist,
    handleClose,
    dialogOpen,
    errorAttribute,
  };
};
export default UseGuestInfoDialog;
