import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Container } from '@mui/material';
import Button from '@mui/material/Button/Button';
import FormControl from '@mui/material/FormControl/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import FormGroup from '@mui/material/FormGroup/FormGroup';
import Grid from '@mui/material/Grid/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography/Typography';
import { MinkaResidentRequest, CodeMaster } from 'API';
import jaLocale from 'date-fns/locale/ja';
import React, { FC } from 'react';
import { SelectValue } from '../../../../types/SelectValue';
import SelectFormControl from '../../../parts/SelectFormControl';
import GenericTemplate from '../../../template/GenericTemplate';
import { ErrorData } from './HousemateDetailErrorData';
import FormHelperText from '@mui/material/FormHelperText';
import BackToTopButton from 'views/parts/BackToTop';

const styles = {
  searchField: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
    paddingLeft: '100px',
  },

  label: {
    margin: 'auto',
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
    mr: '10px',
  },

  labelValue: {
    margin: 'auto',
    textAlign: 'left',
    pr: '10px',
    mt: '7px',
  },

  radioLabel: {
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
    mr: '10px',
  },

  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
  },

  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 3,
    mr: 1,
    float: 'right',
  },

  dataBlock: {
    '& > div': {
      width: '100%',
    },
  },

  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },

  textField: {
    width: '100%',
    '& input': {
      padding: '8px',
    },
  },

  textArea: {
    width: '100%',
    '& input': {
      padding: '8px 14px',
    },
    mt: 1,
    mb: 1,
  },
};

export interface Props {
  birthday: Date | null;
  residentData: MinkaResidentRequest;
  residentRelationList: CodeMaster[];
  housemateAge: string;
  errorAttribute: ErrorData;
  handleChangeBirthday: (_: Date | null) => void;
  handleChangeInput: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickRegist: () => void;
}

const HousemateDetail: FC<Props> = (props) => {
  const pushback = function () {
    window.history.back();
  };

  return (
    <GenericTemplate>
      <div>
        <BackToTopButton />
      </div>
      <Container sx={styles.searchField}>
        {props.errorAttribute.Header.ErrorMessage && (
          <Grid container spacing={2} sx={{ pl: 0, pb: 2 }}>
            <Grid item xs={12}>
              <FormControl component="fieldset" error={props.errorAttribute.Header.IsError}>
                <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                  {props.errorAttribute.Header.ErrorMessage}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        )}
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          同居人詳細
        </Typography>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3} sm={3} md={1} sx={styles.label}>
            <Typography>姓</Typography>
          </Grid>
          <Grid item xs={9} sm={9} md={4}>
            <TextField
              sx={styles.textField}
              name={'LastName'}
              value={props.residentData.LastName ?? ''}
              onChange={props.handleChangeInput}
              inputProps={{ maxLength: 10 }}
              error={props.errorAttribute.LastName.IsError}
              helperText={props.errorAttribute.LastName.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3} sm={3} md={1} sx={styles.label}>
            <Typography>名</Typography>
          </Grid>
          <Grid item xs={9} sm={9} md={4}>
            <TextField
              sx={styles.textField}
              name={'FirstName'}
              value={props.residentData.FirstName ?? ''}
              onChange={props.handleChangeInput}
              inputProps={{ maxLength: 10 }}
              error={props.errorAttribute.FirstName.IsError}
              helperText={props.errorAttribute.FirstName.ErrorMessage}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3} sm={3} md={1} sx={styles.label}>
            <Typography>カナ性</Typography>
          </Grid>
          <Grid item xs={9} sm={9} md={4}>
            <TextField
              sx={styles.textField}
              name={'LastKanaName'}
              value={props.residentData.LastKanaName ?? ''}
              onChange={props.handleChangeInput}
              inputProps={{ maxLength: 10 }}
              error={props.errorAttribute.LastKanaName.IsError}
              helperText={props.errorAttribute.LastKanaName.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3} sm={3} md={1} sx={styles.label}>
            <Typography>カナ名</Typography>
          </Grid>
          <Grid item xs={9} sm={9} md={4}>
            <TextField
              sx={styles.textField}
              name={'FirstKanaName'}
              value={props.residentData.FirstKanaName ?? ''}
              onChange={props.handleChangeInput}
              inputProps={{ maxLength: 10 }}
              error={props.errorAttribute.FirstKanaName.IsError}
              helperText={props.errorAttribute.FirstKanaName.ErrorMessage}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3} sm={3} md={2} sx={styles.radioLabel}>
            <Typography>性別</Typography>
          </Grid>
          <Grid item xs={9} sm={9} md={4}>
            <FormControl component="fieldset">
              <FormGroup style={{ display: 'block' }}>
                <RadioGroup
                  row
                  name="Sex"
                  aria-label="position"
                  value={props.residentData.Sex ?? '1'}
                  onChange={props.handleChangeInput}
                >
                  <FormControlLabel value={1} control={<Radio color="primary" />} label="男" />
                  <FormControlLabel value={2} control={<Radio color="primary" />} label="女" />
                </RadioGroup>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3} sm={3} md={1} sx={styles.label}>
            <Typography>生年月日</Typography>
          </Grid>
          <Grid item xs={9} sm={9} md={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
              <DesktopDatePicker
                value={new Date(props.residentData.BirthDay ?? '')}
                onChange={(newValue) => props.handleChangeBirthday(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    error={props.errorAttribute.BirthDay.IsError}
                    helperText={props.errorAttribute.BirthDay.ErrorMessage}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={3} sm={3} md={1} sx={styles.label}>
            <Typography>年齢</Typography>
          </Grid>
          <Grid item xs={9} sm={9} md={4}>
            <Typography sx={styles.labelValue}>{props.housemateAge}</Typography>{' '}
          </Grid>
        </Grid>

        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={2}>
            <Typography sx={styles.label}>続柄</Typography>
          </Grid>
          <Grid item xs={10}>
            <SelectFormControl
              value={props.residentData.ResidentsRelationCode ?? ''}
              selectName="ResidentsRelationCode"
              selectValues={props.residentRelationList.map((residentRelation, idx) => {
                return {
                  value: residentRelation.Code,
                  displayName: residentRelation.Name,
                } as SelectValue;
              })}
              setCondition={props.handleChangeInput}
              sx={styles.textField}
              error={props.errorAttribute.ResidentsRelationCode.IsError}
              errorMessage={props.errorAttribute.ResidentsRelationCode.ErrorMessage}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={2}>
            <Typography sx={styles.label}>携帯電話番号</Typography>
          </Grid>
          <Grid item xs={10}>
            <TextField
              sx={styles.textField}
              name={'Tel'}
              value={props.residentData.Tel ?? ''}
              onChange={props.handleChangeInput}
              inputProps={{ maxLength: 13 }}
              error={props.errorAttribute.Tel.IsError}
              helperText={props.errorAttribute.Tel.ErrorMessage}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={2}>
            <Typography sx={styles.label}>メール</Typography>
          </Grid>
          <Grid item xs={10}>
            <TextField
              sx={styles.textField}
              name={'Mail'}
              value={props.residentData.Mail ?? ''}
              onChange={props.handleChangeInput}
              inputProps={{ maxLength: 255 }}
              error={props.errorAttribute.Mail.IsError}
              helperText={props.errorAttribute.Mail.ErrorMessage}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={2}>
            <Typography sx={styles.label}>職歴</Typography>
          </Grid>
          <Grid item xs={10}>
            <TextField
              sx={styles.textArea}
              value={props.residentData.WorkHistory}
              rows={4}
              multiline={true}
              variant="outlined"
              inputProps={{ maxLength: 255 }}
              name={'WorkHistory'}
              onChange={props.handleChangeInput}
              error={props.errorAttribute.WorkHistory.IsError}
              helperText={props.errorAttribute.WorkHistory.ErrorMessage}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={2}>
            <Typography sx={styles.label}>人物像</Typography>
          </Grid>
          <Grid item xs={10}>
            <TextField
              sx={styles.textArea}
              value={props.residentData.Character}
              rows={4}
              multiline={true}
              variant="outlined"
              inputProps={{ maxLength: 255 }}
              name={'Character'}
              onChange={props.handleChangeInput}
              error={props.errorAttribute.Character.IsError}
              helperText={props.errorAttribute.Character.ErrorMessage}
            />
          </Grid>
        </Grid>
        <Button variant="contained" sx={styles.button} onClick={pushback}>
          閉じる
        </Button>
        <Button variant="contained" sx={styles.button} onClick={props.handleClickRegist}>
          登録
        </Button>
      </Container>
    </GenericTemplate>
  );
};

export default HousemateDetail;
