import { FormControl, FormHelperText, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { ErrorData } from './TransferReservationErrorData';

export interface Props {
  handleChangeTargetReservationId: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleRegist: () => void;
  handleClose: () => void;
  sourceReservationId: string;
  targetReservationId: string;
  dialogOpen: boolean;
  errorAttribute: ErrorData;
}

const styles = {
  categoryHeader: {
    fontWeight: 'bold',
  },
  itemHeader: {
    backgroundColor: '#ececec',
    fontWeight: 'bold',
  },
  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
  },
  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 1,
    ml: 1,
  },
  label: {
    mt: '7px',
  },
  categoryTitle: {
    borderLeft: 5,
    borderColor: '#0058a0',
    pl: '10px',
  },
  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },
  listButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    m: 0,
  },
};

const TransferReservationDialog: FC<Props> = ({
  handleChangeTargetReservationId,
  handleRegist,
  handleClose,
  sourceReservationId,
  targetReservationId,
  dialogOpen,
  errorAttribute,
}) => {
  return (
    <Dialog open={dialogOpen} onClose={handleClose} maxWidth={'md'}>
      <DialogTitle>
        予約付替え
        {errorAttribute.Header.ErrorMessage && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl component="fieldset" error={errorAttribute.Header.IsError}>
                <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                  {errorAttribute.Header.ErrorMessage}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          {`この予約（ID: ${sourceReservationId} ）に紐づく全ての予約日程を指定の予約IDに付替えます`}
        </Typography>
        <Grid container spacing={2} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>付替え先予約 ID *</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              required
              hiddenLabel
              name="TargetReservationId"
              value={targetReservationId}
              onChange={handleChangeTargetReservationId}
              size="small"
              sx={styles.inputItem}
              error={errorAttribute.TargetReservationId.IsError}
              helperText={errorAttribute.TargetReservationId.ErrorMessage}
              inputProps={{
                type: 'number',
              }}
            />
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={9}>
            <Typography variant="subtitle2">予約の付替えは次の条件を満たす必要があります。</Typography>
            <Typography variant="subtitle2">・付替え元と付替え先の予約が同一年度であること</Typography>
            <Typography variant="subtitle2">・付替え元と付替え先の予約が同一エリアであること</Typography>
            <Typography variant="subtitle2">・付替え元と付替え先の予約が同一校であること</Typography>
            <Typography variant="subtitle2">・付替え元の予約人数と付替え先の予約人数と同じか少ないこと</Typography>
          </Grid>
        </Grid>

        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" sx={styles.button} onClick={handleRegist}>
              登録
            </Button>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" sx={styles.button} onClick={handleClose}>
              閉じる
            </Button>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
export default TransferReservationDialog;
