import FormErrorAttribute from 'types/FormErrorAttribute';

export interface ErrorData {
  Header: FormErrorAttribute;
  ChangeReason: FormErrorAttribute;
  AfterRegion: FormErrorAttribute;
  AfterMinkaId: FormErrorAttribute;
}

export const CreateEmptyErrorData = () => {
  return {
    Header: {} as FormErrorAttribute,
    ChangeReason: {} as FormErrorAttribute,
    AfterRegion: {} as FormErrorAttribute,
    AfterMinkaId: {} as FormErrorAttribute,
  } as ErrorData;
};

