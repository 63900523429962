import FormErrorAttribute from 'types/FormErrorAttribute';

export interface ErrorData {
  Header: FormErrorAttribute;
  ServiceId: FormErrorAttribute;
  ProvideDate: FormErrorAttribute;
  StartTime: FormErrorAttribute;
  EndTime: FormErrorAttribute;
  Rate: FormErrorAttribute;
  Count: FormErrorAttribute;
  Memo: FormErrorAttribute;
}

export const CreateEmptyErrorData = () => {
  return {
    Header: {} as FormErrorAttribute,
    ServiceId: {} as FormErrorAttribute,
    ProvideDate: {} as FormErrorAttribute,
    StartTime: {} as FormErrorAttribute,
    EndTime: {} as FormErrorAttribute,
    Rate: {} as FormErrorAttribute,
    Count: {} as FormErrorAttribute,
    Memo: {} as FormErrorAttribute,
  } as ErrorData;
};
