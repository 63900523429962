import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { MSchools, SchoolRequestData } from 'API';
import { CodeKind } from 'constant/Constant';
import { useMaster } from 'contexts/CommonMasterContext';
import { FC, useState } from 'react';
import { SelectValue } from 'types/SelectValue';
import SelectFormControl from 'views/parts/SelectFormControl';

export interface Props {
  inputData: SchoolRequestData;
  resultData: MSchools[];
  handleClose: (_?: MSchools) => void;
  handleChangeInput: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeCheckbox: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickSearch: () => void;
  dialogOpen: boolean;
}

const styles = {
  categoryHeader: {
    fontWeight: 'bold',
  },
  itemHeader: {
    backgroundColor: '#ececec',
    fontWeight: 'bold',
  },
  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
  },
  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 1,
    ml: 1,
  },
  label: {
    mt: '7px',
  },
  categoryTitle: {
    borderLeft: 5,
    borderColor: '#0058a0',
    pl: '10px',
  },
  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },
  listButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    m: 0,
  },
};

const SchoolSearchDialog: FC<Props> = ({
  inputData,
  resultData,
  handleClose,
  handleChangeInput,
  handleChangeCheckbox,
  handleClickSearch,
  dialogOpen,
}) => {
  const master = useMaster();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Dialog open={dialogOpen} onClose={() => handleClose()} maxWidth={'md'}>
      <DialogTitle>学校選択</DialogTitle>
      <DialogContent>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          検索条件
        </Typography>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>都道府県</Typography>
          </Grid>
          <Grid item xs={9}>
            <SelectFormControl
              value={inputData.PrefectureCode ?? ''}
              selectName="PrefectureCode"
              selectValues={master.getPrefecture().map((prefecture, idx) => {
                return { value: prefecture.PrefectureCode, displayName: prefecture.PrefectureName } as SelectValue;
              })}
              setCondition={handleChangeInput}
              sx={styles.inputItem}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>住所</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              required
              hiddenLabel
              name="Address"
              value={inputData.Address}
              onChange={handleChangeInput}
              sx={styles.inputItem}
              size="small"
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>学校名</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              required
              hiddenLabel
              name="SchoolName"
              value={inputData.SchoolName}
              onChange={handleChangeInput}
              sx={styles.inputItem}
              size="small"
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>学校カナ名</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              required
              hiddenLabel
              name="SchoolKanaName"
              value={inputData.SchoolKanaName}
              onChange={handleChangeInput}
              sx={styles.inputItem}
              size="small"
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>学校コード</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              required
              hiddenLabel
              name="SchoolCode"
              value={inputData.SchoolCode}
              onChange={handleChangeInput}
              sx={styles.inputItem}
              size="small"
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>学校種別</Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControl component="fieldset">
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox name="SchoolCategoryList" value={'B1'} onChange={handleChangeCheckbox} size="small" />
                  }
                  label={master.getCodeName(CodeKind.SchoolCategoryCode, 'B1')}
                />
                <FormControlLabel
                  control={
                    <Checkbox name="SchoolCategoryList" value={'C1'} onChange={handleChangeCheckbox} size="small" />
                  }
                  label={master.getCodeName(CodeKind.SchoolCategoryCode, 'C1')}
                />
                <FormControlLabel
                  control={
                    <Checkbox name="SchoolCategoryList" value={'C2'} onChange={handleChangeCheckbox} size="small" />
                  }
                  label={master.getCodeName(CodeKind.SchoolCategoryCode, 'C2')}
                />
                <FormControlLabel
                  control={
                    <Checkbox name="SchoolCategoryList" value={'D1'} onChange={handleChangeCheckbox} size="small" />
                  }
                  label={master.getCodeName(CodeKind.SchoolCategoryCode, 'D1')}
                />
                <FormControlLabel
                  control={
                    <Checkbox name="SchoolCategoryList" value={'D2'} onChange={handleChangeCheckbox} size="small" />
                  }
                  label={master.getCodeName(CodeKind.SchoolCategoryCode, 'D2')}
                />
                <FormControlLabel
                  control={
                    <Checkbox name="SchoolCategoryList" value={'E1'} onChange={handleChangeCheckbox} size="small" />
                  }
                  label={master.getCodeName(CodeKind.SchoolCategoryCode, 'E1')}
                />
                <FormControlLabel
                  control={
                    <Checkbox name="SchoolCategoryList" value={'F1'} onChange={handleChangeCheckbox} size="small" />
                  }
                  label={master.getCodeName(CodeKind.SchoolCategoryCode, 'F1')}
                />
                <FormControlLabel
                  control={
                    <Checkbox name="SchoolCategoryList" value={'F2'} onChange={handleChangeCheckbox} size="small" />
                  }
                  label={master.getCodeName(CodeKind.SchoolCategoryCode, 'F2')}
                />
                <FormControlLabel
                  control={
                    <Checkbox name="SchoolCategoryList" value={'G1'} onChange={handleChangeCheckbox} size="small" />
                  }
                  label={master.getCodeName(CodeKind.SchoolCategoryCode, 'G1')}
                />
                <FormControlLabel
                  control={
                    <Checkbox name="SchoolCategoryList" value={'H1'} onChange={handleChangeCheckbox} size="small" />
                  }
                  label={master.getCodeName(CodeKind.SchoolCategoryCode, 'H1')}
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>設置区分</Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControl component="fieldset">
              <FormGroup row>
                {master.getCodeKind(CodeKind.ClassificationCode).map((codeMaster, idx) => (
                  <FormControlLabel
                    key={idx}
                    control={
                      <Checkbox
                        name="ClassificationList"
                        value={codeMaster.Code}
                        onChange={handleChangeCheckbox}
                        size="small"
                      />
                    }
                    label={codeMaster.Name}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" sx={styles.button} onClick={handleClickSearch}>
            検索
          </Button>
        </Box>

        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          学校一覧
        </Typography>
        <TableContainer sx={{ minHeight: 300 }}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableBody>
              {resultData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((school, index) => (
                <TableRow key={index}>
                  <TableCell component="td" scope="row">
                    {school.SchoolName}
                  </TableCell>
                  <TableCell component="td" scope="row" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="contained" sx={styles.listButton} onClick={() => handleClose(school)} size="small">
                      選択
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[7, 25, 100]}
          component="div"
          count={resultData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </DialogContent>
    </Dialog>
  );
};
export default SchoolSearchDialog;
