import SearchIcon from '@mui/icons-material/Search';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Container, Link } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {
  Branch,
  CompanyBranch,
  MSchools,
  OptionalService,
  Reservation,
  ReservationAgent,
  Service,
  AreaServiceRate,
  ReservationDay,
} from 'API';
import jaLocale from 'date-fns/locale/ja';
import React, { FC } from 'react';
import { GuestCount } from 'types/GuestCount';
import { GuestInfo } from 'types/GuestInfo';
import { SelectValue } from 'types/SelectValue';
import { ServiceProvider } from 'types/ServiceProvider';
import SelectFormControl from 'views/parts/SelectFormControl';
import AgentDialog from 'views/reserve/reception/dialog/agent';
import AlertDialog from 'views/reserve/reception/dialog/alert';
import ApplicationDialog from 'views/reserve/reception/dialog/application';
import ApprovalDialog from 'views/reserve/reception/dialog/approval';
import CancelDialog from 'views/reserve/reception/dialog/cancel';
import ServiceProviderDialog from 'views/reserve/reception/dialog/company';
import GuestCountDialog from 'views/reserve/reception/dialog/guestCount';
import DurationDateDialog from 'views/reserve/reception/dialog/durationDate';
import GuestInfoDialog from 'views/reserve/reception/dialog/guestInfo';
import OptionDialog from 'views/reserve/reception/dialog/option';
import ServiceDialog from 'views/reserve/reception/dialog/plan';
import SchoolDecisionApplicationDialog from 'views/reserve/reception/dialog/schoolDecision';
import TransferReservationDialog from 'views/reserve/reception/dialog/transferReservation';
import SchoolSearchDialog from 'views/reserve/reception/dialog/schoolSelect';
import GenericTemplate from 'views/template/GenericTemplate';
import AddDaysDialog from 'views/reserve/reception/dialog/adddays';
import SessionInvalidMessageDialog from 'views/reserve/reception/dialog/SessionInvalidMessage';
import { ErrorData } from './ReserveRegistErrorData';
import BackToTopButton from 'views/parts/BackToTop';

export interface Props {
  reservation: Reservation;
  companyList: CompanyBranch[];
  branchList: Branch[];
  copyReservationId: string;
  searchSchoolOpen: boolean;
  copyReservationOpen: boolean;
  includeReservationDate: boolean;
  selectSchool: MSchools;
  guestInfoOpen: boolean;
  guestInfo: GuestInfo;
  guestCountOpen: boolean;
  guestCount: GuestCount;
  durationDateOpen: boolean;
  reservationDay: ReservationDay;
  serviceOpen: boolean;
  service: Service;
  serviceMode: string;
  addDayInitialValue: string;
  optionalServiceOpen: boolean;
  optionalService: OptionalService;
  optionalServiceMode: string;
  agentOpen: boolean;
  agent: ReservationAgent;
  agentMode: string;
  serviceProviderOpen: boolean;
  serviceProvider: ServiceProvider;
  serviceProviderMode: string;
  cancelOpen: boolean;
  alertOpen: boolean;
  alertContent: string;
  applicationOpen: boolean;
  transferReservationOpen: boolean;
  schoolDecisionApplicationOpen: boolean;
  approvalOpen: boolean;
  areaServiceRateOpen: boolean;
  addReserveDayOpen: boolean;
  sessionInvalidMessageOpen: boolean;
  url: string;
  isLedgerOpen: boolean;
  hasControl: boolean;
  canAllDept: boolean;
  isValidSession: boolean;
  handleReload: () => void;
  handleCopyReservation: () => void;
  handleChangeInput: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleEditGuestCount: () => void;
  handleChangeCompany: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeBookingBranch: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeSalesBranch: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeAgentInput: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearchSchoolOpen: () => void;
  handleChangeHasGroupingName: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeIncludeReservationDate: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeGuestCount: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeCopyReservationId: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleEditGuestInfo: () => void;
  handleChangeHasAnotherResv: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleAddDays: () => void;
  handleDeleteDays: (_: number) => void;
  handleChangeDays: (idx: number, name: string, newValue: any) => void;
  handleExtendTerm: (idx: number) => void;
  handleEditDurationDate: (idx: number) => void;
  handleEditService: () => void;
  handleAddOption: () => void;
  handleDeleteOption: (idx: number) => void;
  handleEditOption: (idx: number) => void;
  handleSharedAdd: () => void;
  handleEditAgent: () => void;
  handleDeleteAgent: (idx: number) => void;
  handleDetailAgent: (idx: number) => void;
  handleRegistProvider: () => void;
  handleEditProvider: () => void;
  handleRegist: () => void;
  handleApplySchoolDecision: () => void;
  handleApproveSchoolDecision: () => void;
  handleTransferReservation: () => void;
  handleApplyReservation: () => void;
  handleCancelApplication: () => void;
  handleGoToMinkaAllocateLedger: () => void;
  handleGoToStudentLedger: () => void;
  handleGoToStudentList: () => void;
  handleCancelReservation: () => void;
  handleSharedReserve: () => void;
  handleApproveReservation: () => void;
  handleBackReservationApproval: () => void;
  handleClose: () => void;
  handleSearchSchoolClose: (_?: MSchools) => void;
  handleGuestInfoClose: (_?: GuestInfo) => void;
  handleGuestCountClose: (_?: GuestCount) => void;
  handleDurationDateClose: (_?: ReservationDay) => void;
  handleServiceClose: (_?: Service, updateDatetime?: string) => void;
  handleOptionalServiceClose: (_?: OptionalService) => void;
  handleAgentClose: (_?: { updateDatetime: string; agent: ReservationAgent }) => void;
  handleServiceProviderClose: (_?: ServiceProvider) => void;
  handleCancelClose: () => void;
  handleAlertClose: (_: boolean) => void;
  handleApplicationClose: (updateDatetime?: string) => void;
  handleTransferReservationClose: (_: boolean) => void;
  handleScoolDecisionApplicationClose: (_: boolean) => void;
  handleApprovalClose: (updateDatetime?: string) => void;
  handleGoToAcceptMinkaMail: () => void;
  handleAreaServiceRateOpen: () => void;
  handleAddReserveDayClose: (_?: { updateDatetime: string; days: ReservationDay[] }) => void;
  handleSessionInvalidMessageClose: () => void;
  handleCopyReservationOpen: () => void;
  errorAttribute: ErrorData;
}

const styles = {
  inputItem: {
    width: '97%',
  },
  label: {
    mt: '7px',
  },
  redLabel: {
    mt: '7px',
    color: '#d32f2f',
    fontWeight: 'bold',
  },
  text: {
    mt: '7px',
    wordWrap: 'break-word',
  },
  categoryTitle: {
    pl: '5px',
    mt: '10px',
    mb: '10px',
    fontWeight: 'bold', // 太字
    backgroundColor: '#eee', // 薄いグレー背景
    padding: '0.5rem', // パディング追加
    borderRadius: '5px', // 角を丸くする
  },
  categoryBlock: {
    mt: '5px',
    mx: '5px',
    mb: '10px',
  },
  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 3,
    ml: 1,
  },
  listButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    m: 1,
  },
  headerButton: {
    minWidth: '120px',
    fontWeight: 'bold',
  },
  headerMessage: {
    color: '#d32f2f',
    fontWeight: 'bold',
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
};

const ReserveRegist: FC<Props> = ({
  reservation,
  companyList,
  branchList,
  copyReservationId,
  searchSchoolOpen,
  selectSchool,
  guestInfoOpen,
  guestInfo,
  guestCountOpen,
  guestCount,
  durationDateOpen,
  reservationDay,
  agentOpen,
  serviceOpen,
  service,
  serviceMode,
  addDayInitialValue,
  optionalServiceOpen,
  optionalService,
  optionalServiceMode,
  agent,
  agentMode,
  serviceProviderOpen,
  serviceProvider,
  serviceProviderMode,
  cancelOpen,
  alertOpen,
  alertContent,
  applicationOpen,
  transferReservationOpen,
  schoolDecisionApplicationOpen,
  approvalOpen,
  areaServiceRateOpen,
  addReserveDayOpen,
  sessionInvalidMessageOpen,
  url,
  isLedgerOpen,
  hasControl,
  canAllDept,
  isValidSession,
  copyReservationOpen,
  includeReservationDate,
  handleReload,
  handleCopyReservation,
  handleSearchSchoolOpen,
  handleChangeHasGroupingName,
  handleEditGuestInfo,
  handleChangeHasAnotherResv,
  handleChangeInput,
  handleChangeGuestCount,
  handleChangeCopyReservationId,
  handleEditGuestCount,
  handleChangeCompany,
  handleChangeBookingBranch,
  handleChangeSalesBranch,
  handleChangeAgentInput,
  handleDeleteDays,
  handleChangeDays,
  handleExtendTerm,
  handleEditDurationDate,
  handleEditService,
  handleAddDays,
  handleAddOption,
  handleDeleteOption,
  handleEditOption,
  handleSharedAdd,
  handleEditAgent,
  handleDeleteAgent,
  handleDetailAgent,
  handleRegistProvider,
  handleEditProvider,
  handleRegist,
  handleApplySchoolDecision,
  handleTransferReservation,
  handleApproveSchoolDecision,
  handleApplyReservation,
  handleCancelApplication,
  handleGoToMinkaAllocateLedger,
  handleGoToStudentLedger,
  handleGoToStudentList,
  handleCancelReservation,
  handleSharedReserve,
  handleApproveReservation,
  handleBackReservationApproval,
  handleClose,
  handleSearchSchoolClose,
  handleGuestInfoClose,
  handleGuestCountClose,
  handleDurationDateClose,
  handleServiceClose,
  handleOptionalServiceClose,
  handleAgentClose,
  handleServiceProviderClose,
  handleCancelClose,
  handleAlertClose,
  handleApplicationClose,
  handleTransferReservationClose,
  handleScoolDecisionApplicationClose,
  handleApprovalClose,
  handleGoToAcceptMinkaMail,
  handleAreaServiceRateOpen,
  handleAddReserveDayClose,
  handleSessionInvalidMessageClose,
  handleCopyReservationOpen,
  handleChangeIncludeReservationDate,
  errorAttribute,
}) => {
  return (
    <GenericTemplate title="予約登録">
      <div>
        <BackToTopButton />
      </div>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Stack direction="row" sx={{ mb: 0 }} spacing={0} alignItems="center" justifyContent="flex-start">
            {[300].includes(reservation.Pattern!) ? (
              <Grid container spacing={0} sx={styles.categoryBlock}>
                <Button variant="outlined" sx={styles.listButton} onClick={handleCopyReservationOpen}>
                  予約コピー
                </Button>
                {copyReservationOpen && (
                  <Paper elevation={1} sx={{ padding: 2, border: '1px solid #0058A0', width: '100%' }}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Typography variant="body2" sx={{ maxWidth: '100%' }}>
                          指定した ID
                          の予約内容をコピーします。エリア別・宿泊日別で複数予約する際にご利用ください。コピー対象に予約日程を含めるか否かを選択できます。
                        </Typography>
                      </Grid>
                      <Grid item>
                        <TextField
                          type="text"
                          label="コピーする予約 ID"
                          name="CopyReservationId"
                          hiddenLabel
                          value={copyReservationId ?? ''}
                          onChange={handleChangeCopyReservationId}
                          size="small"
                          sx={{ width: '170px' }}
                          error={errorAttribute.CopyReservationId.IsError}
                          helperText={errorAttribute.CopyReservationId.ErrorMessage}
                          inputProps={{
                            pattern: '\\d*', // 数値のみを許容
                            min: 1,
                            onWheel: (e) => e.preventDefault(),
                          }}
                        />
                      </Grid>

                      <Grid item>
                        <Button
                          variant="contained"
                          onClick={handleCopyReservation}
                          disabled={!copyReservationId}
                          sx={{
                            minWidth: '60',
                            fontWeight: 'bold',
                          }}
                        >
                          コピー
                        </Button>
                      </Grid>

                      <Grid item>
                        <FormControl component="fieldset" error={errorAttribute.HasGroupingName.IsError}>
                          <RadioGroup
                            row
                            name="IncludeReservationDate"
                            value={includeReservationDate}
                            onChange={handleChangeIncludeReservationDate}
                          >
                            <FormControlLabel
                              value={false}
                              control={<Radio />}
                              label={
                                <Typography variant="body2">
                                  予約日程はコピーしない（台帳で指定した日付を使う）
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value={true}
                              control={<Radio />}
                              label={<Typography variant="body2">予約日程もコピーする</Typography>}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Paper>
                )}
              </Grid>
            ) : (
              <></>
            )}
            {[
              11, 12, 13, 230, 231, 232, 310, 311, 320, 321, 322, 330, 331, 410, 411, 420, 421, 430, 431, 710, 711, 720,
              721, 730, 731,
            ].includes(reservation.Pattern!) && (
              <Button variant="contained" sx={styles.listButton} size="small" onClick={handleReload}>
                最新表示
              </Button>
            )}
            {!isLedgerOpen && (
              <Typography sx={styles.headerMessage}>未公開年度の為、参照モードで起動しています。</Typography>
            )}
            {!isValidSession && (
              <Typography sx={styles.headerMessage}>
                セッション情報が古いため、参照モードで起動しています。ログインし直してください。
              </Typography>
            )}
            {[311, 321, 322, 331, 391, 411, 421, 431, 491].includes(reservation.Pattern!) && (
              <Typography sx={styles.headerMessage}>他社の予約情報を参照しています。</Typography>
            )}
          </Stack>
          {errorAttribute.Header.ErrorMessage && (
            <Grid container spacing={2} sx={{ pl: 0, pb: 2 }}>
              <Grid item xs={12}>
                <FormControl component="fieldset" error={errorAttribute.Header.IsError}>
                  <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                    {errorAttribute.Header.ErrorMessage}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          )}
          <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
            宿泊地基本情報
          </Typography>
          <Grid container spacing={2} sx={styles.categoryBlock}>
            <Grid item xs={3}>
              <Typography sx={styles.label}>エリア</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.text}>{reservation.PrefectureName}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>地区</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.text}>{reservation.AreaName}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>受入人数</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.text}>{reservation.MaxGuestCount}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>複数校受入</Typography>
            </Grid>
            <Grid item xs={3}>
              {`1日${reservation.MaxSchoolCount}校${reservation.MaxMultiSchoolGuestCount}名迄`}
            </Grid>

            <Grid item xs={3}>
              <Typography sx={styles.label}>基本入村時間</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.text}>{reservation.AreaCheckInTime}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>基本退村時間</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.text}>{reservation.AreaCheckOutTime}</Typography>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={9}>
              <Typography variant="subtitle2" sx={{ whiteSpace: 'pre-line' }}>
                {reservation.Memo1}
              </Typography>
            </Grid>
          </Grid>

          <Box sx={{ width: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" sx={styles.listButton} onClick={handleAreaServiceRateOpen} size="small">
              料金表
            </Button>
            {url && (
              <Link target="_blank" rel="noopener noreferrer" href={url}>
                <Button variant="contained" sx={styles.listButton} size="small">
                  パンフレット
                </Button>
              </Link>
            )}
          </Box>
          <Grid container spacing={2} sx={styles.categoryBlock}>
            {areaServiceRateOpen && (
              <Grid item xs={3}>
                料金表
              </Grid>
            )}
            {areaServiceRateOpen && (
              <Grid item xs={9}>
                <FormControl sx={{ width: 1 }} component="fieldset">
                  <TableContainer sx={{ marginY: '5px', width: '96%' }}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={styles.nowrap}>サービスカテゴリ</TableCell>
                          <TableCell sx={styles.nowrap}>サービス名</TableCell>
                          <TableCell sx={styles.text}>単価（税別）</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {reservation.AreaServiceRates?.map((rate, idx) => (
                          <TableRow key={idx} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={styles.nowrap}>{rate?.ServiceCategoryName}</TableCell>
                            <TableCell sx={styles.nowrap}>{rate?.ServiceName}</TableCell>
                            <TableCell sx={styles.text}>{rate?.Rate}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <FormHelperText>{errorAttribute.ReservationDays.ErrorMessage}</FormHelperText>
                </FormControl>
                <Typography variant="subtitle2">
                  ※オプションサービスは時期やエリアによっては提供できない場合があります。詳細は予約センターにお問合せください。
                </Typography>
                <Typography variant="subtitle2">
                  ※日帰り民家体験、並びに各種オプションサービスは宿泊の一年前より予約受付けしております。
                </Typography>
              </Grid>
            )}
          </Grid>
          <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
            宿泊者情報
          </Typography>
          <Grid container spacing={2} sx={styles.categoryBlock}>
            <Grid item xs={3}>
              <Typography sx={styles.label}>学校名{[10, 300].includes(reservation.Pattern!) && ' *'}</Typography>
            </Grid>
            <Grid item xs={9}>
              {[10, 300].includes(reservation.Pattern!) ? (
                <FormControl component="fieldset" error={errorAttribute.SchoolId.IsError}>
                  <Paper
                    component="form"
                    variant="outlined"
                    sx={{ p: '0px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                  >
                    <InputBase sx={{ ml: 1, flex: 1 }} readOnly={true} value={selectSchool.SchoolName ?? ''} />
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <IconButton sx={{ p: '10px' }} aria-label="search" onClick={handleSearchSchoolOpen}>
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                  <FormHelperText>{errorAttribute.SchoolId.ErrorMessage}</FormHelperText>
                </FormControl>
              ) : (
                <Typography sx={styles.text}>{reservation.SchoolName}</Typography>
              )}
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>学校カナ名</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography sx={styles.text}>{reservation.SchoolKanaName}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>団別管理{[10, 300].includes(reservation.Pattern!) && ' *'}</Typography>
            </Grid>
            <Grid item xs={9}>
              {[10, 300].includes(reservation.Pattern!) ? (
                <FormControl component="fieldset" error={errorAttribute.HasGroupingName.IsError}>
                  <RadioGroup
                    row
                    name="HasGroupingName"
                    value={reservation.HasGroupingName}
                    onChange={handleChangeHasGroupingName}
                  >
                    <FormControlLabel value={0} control={<Radio />} label="しない" />
                    <FormControlLabel value={1} control={<Radio />} label="する" />
                  </RadioGroup>
                  <FormHelperText>{errorAttribute.HasGroupingName.ErrorMessage}</FormHelperText>
                </FormControl>
              ) : (
                <Typography sx={styles.text}>{reservation.HasGroupingName ? 'する' : 'しない'}</Typography>
              )}
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>
                団名{[10, 300].includes(reservation.Pattern!) && reservation.HasGroupingName === 1 && ' *'}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              {[10, 300].includes(reservation.Pattern!) ? (
                <TextField
                  required
                  hiddenLabel
                  name="GroupingName"
                  value={reservation.GroupingName ?? ''}
                  onChange={handleChangeInput}
                  size="small"
                  sx={styles.inputItem}
                  error={errorAttribute.GroupingName.IsError}
                  helperText={errorAttribute.GroupingName.ErrorMessage}
                  inputProps={{ maxLength: '40' }}
                  disabled={reservation.HasGroupingName === 0}
                />
              ) : (
                <Typography sx={styles.text}>{reservation.GroupingName}</Typography>
              )}
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>分宿{[10, 300].includes(reservation.Pattern!) && ' *'}</Typography>
            </Grid>
            <Grid item xs={9}>
              {[10, 300].includes(reservation.Pattern!) ? (
                <FormControl component="fieldset" error={errorAttribute.HasAnotherResv.IsError}>
                  <RadioGroup
                    row
                    name="HasAnotherResv"
                    value={reservation.HasAnotherResv}
                    onChange={handleChangeHasAnotherResv}
                  >
                    <FormControlLabel value={0} control={<Radio />} label="しない" />
                    <FormControlLabel value={1} control={<Radio />} label="する" />
                  </RadioGroup>
                  <FormHelperText>{errorAttribute.HasAnotherResv.ErrorMessage}</FormHelperText>
                </FormControl>
              ) : (
                <Typography sx={styles.text}>{reservation.HasAnotherResv ? 'する' : 'しない'}</Typography>
              )}
            </Grid>
          </Grid>
          {[13, 230, 330, 430].includes(reservation.Pattern!) && isLedgerOpen && hasControl && isValidSession && (
            <Box sx={{ width: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" sx={styles.listButton} size="small" onClick={handleEditGuestInfo}>
                編集
              </Button>
            </Box>
          )}

          <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
            宿泊人数
          </Typography>
          <Grid container spacing={2} sx={styles.categoryBlock}>
            <Grid item xs={3}>
              <Typography sx={styles.label}>宿泊人数{[10, 300].includes(reservation.Pattern!) && ' *'}</Typography>
            </Grid>
            <Grid item xs={9}>
              {[10, 300].includes(reservation.Pattern!) ? (
                <TextField
                  required
                  type={'text'}
                  hiddenLabel
                  name="GuestCount"
                  value={reservation.GuestCount ?? ''}
                  onChange={handleChangeGuestCount}
                  size="small"
                  sx={styles.inputItem}
                  error={errorAttribute.GuestCount.IsError}
                  helperText={errorAttribute.GuestCount.ErrorMessage}
                  inputProps={{ min: 1, onWheel: (e) => e.preventDefault() }}
                />
              ) : (
                <Typography sx={styles.text}>{reservation.GuestCount}</Typography>
              )}
            </Grid>

            <Grid item xs={3}></Grid>
            <Grid item xs={9}>
              {[10, 300].includes(reservation.Pattern!) ? (
                <>
                  <Typography variant="subtitle2">※数値を入力してください。文字列は 0 に変換されます。</Typography>
                  <Typography variant="subtitle2">
                    ※分宿する場合は、最大宿泊可能人数で仮予約し、本予約申請時に人数を確定（必要に応じて減員）してください。
                  </Typography>
                </>
              ) : (
                <Typography variant="subtitle2">
                  ※人数変更は、予約センターまたは受入事業者にお申し入れください。
                </Typography>
              )}
            </Grid>
          </Grid>
          {[13, 230].includes(reservation.Pattern!) && (
            <Box sx={{ width: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" sx={styles.listButton} size="small" onClick={handleEditGuestCount}>
                編集
              </Button>
            </Box>
          )}

          {[10, 11, 300, 310, 311, 410, 411, 710, 711].includes(reservation.Pattern!) && (
            <>
              <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
                予約日程
              </Typography>
              <Grid container spacing={2} sx={styles.categoryBlock}>
                {/* {[10, 300].includes(reservation.Pattern!) && (
                  <>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={10}>
                      <Button variant="contained" sx={styles.listButton} onClick={handleAddDays} size="small">
                        日程追加
                      </Button>
                    </Grid>
                  </>
                )} */}
                <Grid item xs={3}></Grid>
                <Grid item xs={9}>
                  <FormControl sx={{ width: 0.8 }} component="fieldset" error={errorAttribute.ReservationDays.IsError}>
                    <TableContainer sx={{ marginY: '5px' }}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell>宿泊日{[10, 300].includes(reservation.Pattern!) && ' *'}</TableCell>
                            <TableCell>ステータス</TableCell>
                            <TableCell>仮予約期限</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {reservation.ReservationDays?.map((day, idx) => (
                            <TableRow key={idx} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell scope="row">
                                {([10, 11, 300].includes(reservation.Pattern!) ||
                                  ([310].includes(reservation.Pattern!) &&
                                    !reservation.HasSharedReservation &&
                                    isLedgerOpen &&
                                    hasControl &&
                                    isValidSession)) && (
                                  <Button
                                    variant="outlined"
                                    sx={styles.listButton}
                                    size="small"
                                    onClick={() => handleDeleteDays(idx)}
                                  >
                                    削除
                                  </Button>
                                )}
                              </TableCell>
                              <TableCell>
                                {[10, 300].includes(reservation.Pattern!) && (
                                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
                                    <DesktopDatePicker
                                      label="yyyy/mm/dd"
                                      mask="____/__/__"
                                      value={day!.ReservedDay}
                                      onChange={(newValue) => handleChangeDays(idx, 'ReservedDay', newValue)}
                                      renderInput={(params) => <TextField {...params} size="small" />}
                                    />
                                  </LocalizationProvider>
                                )}
                                {[11, 310, 311, 410, 411, 710, 711].includes(reservation.Pattern!) && (
                                  <Typography>{day!.ReservedDay}</Typography>
                                )}
                              </TableCell>
                              <TableCell>
                                {([10, 11, 300, 310, 311, 410, 411, 710, 711].includes(reservation.Pattern!) &&
                                  day?.ReservationStatus) ||
                                  '---'}
                              </TableCell>
                              <TableCell>
                                {([10, 11, 300, 310, 311, 410, 411, 710, 711].includes(reservation.Pattern!) &&
                                  day?.ReservationStatusCode === '2' &&
                                  day?.DurationDate) ||
                                  '----/--/-- --:--:---'}
                              </TableCell>
                              <TableCell>
                                {[11].includes(reservation.Pattern!) && day.ReservationStatusCode === '2' && (
                                  <Box sx={{ width: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                      variant="contained"
                                      sx={styles.listButton}
                                      size="small"
                                      onClick={() => handleEditDurationDate(idx)}
                                    >
                                      期限編集
                                    </Button>
                                  </Box>
                                )}
                                {[310].includes(reservation.Pattern!) &&
                                  day.CanExtend &&
                                  isLedgerOpen &&
                                  hasControl &&
                                  isValidSession && (
                                    <Box sx={{ width: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                      <Button
                                        variant="contained"
                                        sx={styles.listButton}
                                        size="small"
                                        onClick={() => handleExtendTerm(idx)}
                                      >
                                        期限延長
                                      </Button>
                                    </Box>
                                  )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <FormHelperText>{errorAttribute.ReservationDays.ErrorMessage}</FormHelperText>
                  </FormControl>
                  {([10, 11, 300].includes(reservation.Pattern!) ||
                    ([310].includes(reservation.Pattern!) && isLedgerOpen && hasControl && isValidSession)) && (
                    <>
                      <Grid item xs={9}>
                        <Typography variant="subtitle2">
                          ※仮予約には期限が設定されます（予約日から21日後の17時59分59秒迄）。
                        </Typography>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={9}>
                    <Typography variant="subtitle2">※期限を過ぎた仮予約は、自動削除されます。</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          <Box sx={{ width: 1, display: 'flex', justifyContent: 'flex-end' }}>
            {([10, 11, 300].includes(reservation.Pattern!) ||
              ([310].includes(reservation.Pattern!) && isLedgerOpen && hasControl && isValidSession)) && (
              <>
                <Box sx={{ width: 1, display: 'flex', justifyContent: 'flex-end' }}>
                  <Button variant="contained" sx={styles.listButton} onClick={handleAddDays} size="small">
                    日程追加
                  </Button>
                </Box>
              </>
            )}
          </Box>
          {[
            12, 13, 19, 230, 231, 232, 290, 291, 292, 320, 321, 322, 330, 331, 390, 301, 420, 421, 430, 431, 490, 491,
            720, 721, 730, 731, 790, 791,
          ].includes(reservation.Pattern!) && (
            <>
              <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
                宿泊プラン
              </Typography>
              <Grid container spacing={2} sx={styles.categoryBlock}>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>プラン名</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography sx={styles.text}>{reservation.ServiceName}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>単価</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography sx={styles.text}>{reservation.Rate}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>入村日</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography sx={styles.text}>{reservation.CheckInDate}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>入村時間</Typography>
                </Grid>
                <Grid item xs={9}>
                  {reservation.CheckInTime == '00:00' ? (
                    <Typography sx={styles.redLabel}>入村時間を調整してください</Typography>
                  ) : (
                    <Typography sx={styles.text}>{reservation.CheckInTime} </Typography>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>退村日</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography sx={styles.text}>{reservation.CheckOutDate}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>退村時間</Typography>
                </Grid>
                <Grid item xs={9}>
                  {reservation.CheckOutTime == '00:00' ? (
                    <Typography sx={styles.redLabel}>退村時間を調整してください</Typography>
                  ) : (
                    <Typography sx={styles.text}>{reservation.CheckOutTime}</Typography>
                  )}
                </Grid>
              </Grid>
              {[12, 13, 230].includes(reservation.Pattern!) && (
                <Box sx={{ width: 1, display: 'flex', justifyContent: 'flex-end' }}>
                  <Button variant="contained" sx={styles.listButton} size="small" onClick={handleEditService}>
                    編集
                  </Button>
                </Box>
              )}
            </>
          )}

          {[13, 19, 230, 231, 232, 290, 291, 292, 330, 331, 390, 391, 430, 431, 490, 491, 730, 731, 790, 791].includes(
            reservation.Pattern!
          ) && (
            <>
              <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
                宿泊オプション
              </Typography>
              <Grid container spacing={2} sx={styles.categoryBlock}>
                <Grid item xs={3}>
                  オプション
                </Grid>
                <Grid item xs={9}>
                  <FormControl sx={{ width: 1 }} component="fieldset" error={errorAttribute.ReservationDays.IsError}>
                    {reservation.OptionalServices && reservation.OptionalServices.length > 0 ? (
                      <TableContainer sx={{ marginY: '5px' }}>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell>オプション名</TableCell>
                              <TableCell>料金（１名あたり）</TableCell>
                              <TableCell>数量</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {reservation.OptionalServices.map((optionalService, idx) => (
                              <TableRow key={idx} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell scope="row">
                                  {[13, 230].includes(reservation.Pattern!) && (
                                    <Button
                                      variant="outlined"
                                      sx={styles.listButton}
                                      size="small"
                                      onClick={() => handleDeleteOption(idx)}
                                    >
                                      削除
                                    </Button>
                                  )}
                                </TableCell>
                                <TableCell>{optionalService?.ServiceDisplayName}</TableCell>
                                <TableCell>{optionalService?.Rate}</TableCell>
                                <TableCell>{optionalService?.Count}</TableCell>
                                <TableCell>
                                  {[13, 230].includes(reservation.Pattern!) && (
                                    <Box sx={{ width: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                      <Button
                                        variant="contained"
                                        sx={styles.listButton}
                                        size="small"
                                        onClick={() => handleEditOption(idx)}
                                      >
                                        編集
                                      </Button>
                                    </Box>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      // When reservation.OptionalServices is empty
                      <Typography align="left" sx={{ marginY: '5px' }}>
                        宿泊オプションはありません。
                      </Typography>
                    )}
                    <FormHelperText>{errorAttribute.ReservationDays.ErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </>
          )}
          {[13, 230].includes(reservation.Pattern!) && (
            <Box sx={{ width: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" sx={styles.listButton} onClick={handleAddOption} size="small">
                追加
              </Button>
            </Box>
          )}

          {[
            11, 12, 13, 19, 230, 231, 232, 290, 291, 292, 310, 311, 320, 321, 322, 330, 331, 390, 391, 430, 431, 490,
            491, 730, 731, 790, 791,
          ].includes(reservation.Pattern!) && (
            <>
              <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
                予約登録情報
              </Typography>
              <Grid container spacing={2} sx={styles.categoryBlock}>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>予約番号</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography sx={styles.text}>{reservation.ReservationId}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>承認状況</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography sx={styles.text}>{reservation.ApprovalStatus}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>登録日</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography sx={styles.text}>{reservation.CreateDatetime}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>更新日</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography sx={styles.text}>{reservation.UpdateDatetime}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>承認日</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography sx={styles.text}>{reservation.ApprovedDatetime}</Typography>
                </Grid>
                {[19, 390, 391].includes(reservation.Pattern!) && (
                  <>
                    <Grid item xs={3}>
                      <Typography sx={styles.label}>キャンセル日</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography sx={styles.text}>{reservation.CancelledDatetime}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography sx={styles.label}>キャンセル理由</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography sx={styles.text}>{reservation.CancelledReason}</Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </>
          )}

          {[10, 12, 13, 19, 230, 231, 290, 291, 300, 310, 320, 330, 390, 430, 490, 730, 790].includes(
            reservation.Pattern!
          ) && (
            <>
              <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
                代理店情報
              </Typography>
              <Grid container spacing={2} sx={styles.categoryBlock}>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>代理店名{[10].includes(reservation.Pattern!) && ' *'}</Typography>
                </Grid>
                <Grid item xs={9}>
                  {[10].includes(reservation.Pattern!) ? (
                    <SelectFormControl
                      value={reservation.ReservationAgents![0]?.CompanyId ?? ''}
                      selectName="CompanyId"
                      selectValues={companyList.map((company, idx) => {
                        return { value: company.CompanyId.toString(), displayName: company.CompanyName } as SelectValue;
                      })}
                      setCondition={handleChangeCompany}
                      sx={styles.inputItem}
                      error={errorAttribute.CompanyId.IsError}
                      errorMessage={errorAttribute.CompanyId.ErrorMessage}
                    />
                  ) : (
                    <Typography sx={styles.text}>{reservation.ReservationAgents![0]?.CompanyName}</Typography>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>手配担当店名{[10].includes(reservation.Pattern!) && ' *'}</Typography>
                </Grid>
                <Grid item xs={9}>
                  {[10].includes(reservation.Pattern!) || ([300].includes(reservation.Pattern!) && canAllDept) ? (
                    <SelectFormControl
                      value={reservation.ReservationAgents![0]?.BookingBranchId ?? ''}
                      selectName="BookingBranchId"
                      selectValues={branchList.map((branch, idx) => {
                        return { value: branch.BranchId.toString(), displayName: branch.BranchName } as SelectValue;
                      })}
                      setCondition={handleChangeBookingBranch}
                      sx={styles.inputItem}
                      error={errorAttribute.BookingBranchId.IsError}
                      errorMessage={errorAttribute.BookingBranchId.ErrorMessage}
                    />
                  ) : (
                    <Typography sx={styles.text}>{reservation.ReservationAgents![0]?.BookingBranchName}</Typography>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>
                    手配担当者名{[10, 300].includes(reservation.Pattern!) && ' *'}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  {[10, 300].includes(reservation.Pattern!) ? (
                    <TextField
                      required
                      hiddenLabel
                      name="BookingUserName"
                      value={reservation.ReservationAgents![0]?.BookingUserName ?? ''}
                      onChange={handleChangeAgentInput}
                      size="small"
                      sx={styles.inputItem}
                      error={errorAttribute.BookingUserName.IsError}
                      helperText={errorAttribute.BookingUserName.ErrorMessage}
                    />
                  ) : (
                    <Typography sx={styles.text}>{reservation.ReservationAgents![0]?.BookingUserName}</Typography>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>
                    手配担当TEL{[10, 300].includes(reservation.Pattern!) && ' *'}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  {[10, 300].includes(reservation.Pattern!) ? (
                    <TextField
                      required
                      hiddenLabel
                      name="BookingUserTel"
                      value={reservation.ReservationAgents![0]?.BookingUserTel ?? ''}
                      onChange={handleChangeAgentInput}
                      size="small"
                      sx={styles.inputItem}
                      error={errorAttribute.BookingUserTel.IsError}
                      helperText={errorAttribute.BookingUserTel.ErrorMessage}
                      inputProps={{ type: 'tel' }}
                    />
                  ) : (
                    <Typography sx={styles.text}>{reservation.ReservationAgents![0]?.BookingUserTel}</Typography>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>
                    手配担当Mail - 通知宛先 <br />
                    （カンマ区切りで複数指定可）
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  {[10, 300].includes(reservation.Pattern!) ? (
                    <TextField
                      required
                      hiddenLabel
                      name="BookingUserMail"
                      value={reservation.ReservationAgents![0]?.BookingUserMail ?? ''}
                      onChange={handleChangeAgentInput}
                      size="small"
                      sx={styles.inputItem}
                      error={errorAttribute.BookingUserMail.IsError}
                      helperText={errorAttribute.BookingUserMail.ErrorMessage}
                      inputProps={{ type: 'email' }}
                    />
                  ) : (
                    <Typography sx={styles.text}>{reservation.ReservationAgents![0]?.BookingUserMail}</Typography>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>営業担当店名{[10].includes(reservation.Pattern!) && ' *'}</Typography>
                </Grid>
                <Grid item xs={9}>
                  {[10].includes(reservation.Pattern!) || ([300].includes(reservation.Pattern!) && canAllDept) ? (
                    <SelectFormControl
                      value={reservation.ReservationAgents![0]?.SalesBranchId ?? ''}
                      selectName="SalesBranchId"
                      selectValues={branchList.map((branch, idx) => {
                        return { value: branch.BranchId.toString(), displayName: branch.BranchName } as SelectValue;
                      })}
                      setCondition={handleChangeSalesBranch}
                      sx={styles.inputItem}
                      error={errorAttribute.SalesBranchId.IsError}
                      errorMessage={errorAttribute.SalesBranchId.ErrorMessage}
                    />
                  ) : (
                    <Typography sx={styles.text}>{reservation.ReservationAgents![0]?.SalesBranchName}</Typography>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>営業担当者名</Typography>
                </Grid>
                <Grid item xs={9}>
                  {[10, 300].includes(reservation.Pattern!) ? (
                    <TextField
                      required
                      hiddenLabel
                      name="SalesUserName"
                      value={reservation.ReservationAgents![0]?.SalesUserName ?? ''}
                      onChange={handleChangeAgentInput}
                      size="small"
                      sx={styles.inputItem}
                      error={errorAttribute.SalesUserName.IsError}
                      helperText={errorAttribute.SalesUserName.ErrorMessage}
                    />
                  ) : (
                    <Typography sx={styles.text}>{reservation.ReservationAgents![0]?.SalesUserName}</Typography>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>営業担当TEL</Typography>
                </Grid>
                <Grid item xs={9}>
                  {[10, 300].includes(reservation.Pattern!) ? (
                    <TextField
                      required
                      hiddenLabel
                      name="SalesUserTel"
                      value={reservation.ReservationAgents![0]?.SalesUserTel ?? ''}
                      onChange={handleChangeAgentInput}
                      size="small"
                      sx={styles.inputItem}
                      error={errorAttribute.SalesUserTel.IsError}
                      helperText={errorAttribute.SalesUserTel.ErrorMessage}
                      inputProps={{ type: 'tel' }}
                    />
                  ) : (
                    <Typography sx={styles.text}>{reservation.ReservationAgents![0]?.SalesUserTel}</Typography>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>
                    営業担当Mail - 通知宛先
                    <br />
                    （カンマ区切りで複数指定可）
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  {[10, 300].includes(reservation.Pattern!) ? (
                    <TextField
                      required
                      hiddenLabel
                      name="SalesUserMail"
                      value={reservation.ReservationAgents![0]?.SalesUserMail ?? ''}
                      onChange={handleChangeAgentInput}
                      size="small"
                      sx={styles.inputItem}
                      error={errorAttribute.SalesUserMail.IsError}
                      helperText={errorAttribute.SalesUserMail.ErrorMessage}
                      inputProps={{ type: 'email' }}
                    />
                  ) : (
                    <Typography sx={styles.text}>{reservation.ReservationAgents![0]?.SalesUserMail}</Typography>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>行程表</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href={reservation.ReservationAgents![0]?.ItineraryLink!}
                  >
                    {reservation.ReservationAgents![0]?.ItineraryName}
                  </Link>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>学校決定書</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href={reservation.ReservationAgents![0]?.SchoolConfirmationDocLink!}
                  >
                    {reservation.ReservationAgents![0]?.SchoolConfirmationDocName}
                  </Link>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>決定通知書</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href={reservation.ReservationAgents![0]?.AgentConfirmationDocLink!}
                  >
                    {reservation.ReservationAgents![0]?.AgentConfirmationDocName}
                  </Link>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>決定確認書</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href={reservation.ReservationAgents![0]?.CenterConfirmationDocLink!}
                  >
                    {reservation.ReservationAgents![0]?.CenterConfirmationDocName}
                  </Link>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>依頼・要望</Typography>
                </Grid>
                <Grid item xs={9}>
                  {[10, 300].includes(reservation.Pattern!) ? (
                    <TextField
                      required
                      hiddenLabel
                      name="Note"
                      value={reservation.ReservationAgents![0]?.Note ?? ''}
                      onChange={handleChangeAgentInput}
                      multiline
                      rows="4"
                      sx={styles.inputItem}
                      error={errorAttribute.Note.IsError}
                      helperText={errorAttribute.Note.ErrorMessage}
                    />
                  ) : (
                    <Typography sx={styles.text}>{reservation.ReservationAgents![0]?.Note}</Typography>
                  )}
                </Grid>
              </Grid>
              {[13, 230, 310, 330, 430].includes(reservation.Pattern!) && isLedgerOpen && hasControl && isValidSession && (
                <Box sx={{ width: 1, display: 'flex', justifyContent: 'flex-end' }}>
                  <Button variant="contained" sx={styles.listButton} size="small" onClick={handleEditAgent}>
                    編集
                  </Button>
                </Box>
              )}
            </>
          )}

          {[11].includes(reservation.Pattern!) && (
            <>
              <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
                代理店情報
              </Typography>
              <Grid container spacing={2} sx={styles.categoryBlock}>
                <Grid item xs={3}></Grid>
                <Grid item xs={9}>
                  <Button variant="contained" sx={styles.listButton} onClick={handleSharedAdd} size="small">
                    相乗追加
                  </Button>
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={9}>
                  <FormControl sx={{ width: 1 }} component="fieldset" error={errorAttribute.ReservationDays.IsError}>
                    <TableContainer sx={{ marginY: '5px' }}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell sx={styles.nowrap}>代理店名</TableCell>
                            <TableCell sx={styles.nowrap}>手配担当者名</TableCell>
                            <TableCell sx={styles.nowrap}>営業担当者名</TableCell>
                            <TableCell sx={styles.text}>依頼・要望</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {reservation.ReservationAgents?.map((agent, idx) => (
                            <TableRow key={idx} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell scope="row">
                                <Button
                                  variant="outlined"
                                  sx={styles.listButton}
                                  size="small"
                                  onClick={() => handleDeleteAgent(idx)}
                                >
                                  削除
                                </Button>
                              </TableCell>
                              <TableCell sx={styles.nowrap}>{agent?.CompanyName}</TableCell>
                              <TableCell sx={styles.nowrap}>{agent?.BookingUserName}</TableCell>
                              <TableCell sx={styles.nowrap}>{agent?.SalesUserName}</TableCell>
                              <TableCell sx={styles.text}>{agent?.Note}</TableCell>
                              <TableCell>
                                <Box sx={{ width: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                  <Button
                                    variant="contained"
                                    sx={styles.listButton}
                                    size="small"
                                    onClick={() => handleDetailAgent(idx)}
                                  >
                                    詳細
                                  </Button>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <FormHelperText>{errorAttribute.ReservationDays.ErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </>
          )}

          {[13, 19, 230, 231, 232, 290, 291, 292, 330, 390, 430, 490, 730, 790].includes(reservation.Pattern!) && (
            <>
              <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
                民泊受入事業者情報
              </Typography>
              <Grid container spacing={2} sx={styles.categoryBlock}>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>会社名</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography sx={styles.text}> {reservation.CompanyName} </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>営業所名</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography sx={styles.text}> {reservation.BranchName} </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>担当者</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography sx={styles.text}>{reservation.ManagingUserName}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>入村会場</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography sx={styles.text}>{reservation.CheckInPlace}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>退村会場</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography sx={styles.text}>{reservation.CheckOutPlace}</Typography>
                </Grid>
                <Grid item xs={3}>
                  {[13, 19, 230, 290].includes(reservation.Pattern!) && (
                    <Typography sx={styles.label}>メモ（旅行社には表示されません）</Typography>
                  )}
                </Grid>
                <Grid item xs={9}>
                  {[13, 19, 230, 290].includes(reservation.Pattern!) && (
                    <Typography sx={styles.text}>{reservation.Memo}</Typography>
                  )}
                </Grid>
              </Grid>
              {((reservation.CanUpdateServiceProvider && !reservation.CompanyName) ||
                ([13].includes(reservation.Pattern!) && !reservation.CompanyName)) && (
                <Box sx={{ width: 1, display: 'flex', justifyContent: 'flex-end' }}>
                  <Button variant="contained" sx={styles.listButton} size="small" onClick={handleRegistProvider}>
                    登録
                  </Button>
                </Box>
              )}
              {(reservation.CanUpdateServiceProvider || [13, 230].includes(reservation.Pattern!)) &&
                reservation.CompanyName && (
                  <Box sx={{ width: 1, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="contained" sx={styles.listButton} size="small" onClick={handleEditProvider}>
                      編集
                    </Button>
                  </Box>
                )}
            </>
          )}
          {/* TODO: 過去の予約に紐づくメモを表示したい。
          {[230, 290].includes(reservation.Pattern!) && (
            <>
              <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
                過去のメモ
              </Typography>
              <Grid container spacing={2} sx={styles.categoryBlock}>
                <Grid item xs={2}></Grid>
                <Grid item xs={10}>
                  <FormControl sx={{ width: 1 }} component="fieldset" error={errorAttribute.ReservationDays.IsError}>
                    <TableContainer sx={{ marginY: '5px' }}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>予約番号ID</TableCell>
                            <TableCell>宿泊日</TableCell>
                            <TableCell>宿泊エリア</TableCell>
                            <TableCell>メモ</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {reservation.ReservationDays?.map((day, idx) => (
                            <TableRow key={idx} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell>TODO:予約番号ID</TableCell>
                              <TableCell>TODO:宿泊日</TableCell>
                              <TableCell>TODO:宿泊エリア</TableCell>
                              <TableCell>TODO:メモ</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </FormControl>
                </Grid>
              </Grid>
            </>
          )} */}
        </Box>

        <Stack direction="row" spacing={2} justifyContent="flex-end">
          {[10, 300].includes(reservation.Pattern!) && isLedgerOpen && isValidSession && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" sx={styles.button} onClick={handleRegist}>
                登録
              </Button>
            </Box>
          )}
          {[11, 310].includes(reservation.Pattern!) &&
            reservation.CanSchoolApplication &&
            isLedgerOpen &&
            hasControl &&
            isValidSession && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="contained" sx={styles.button} onClick={handleApplySchoolDecision}>
                  学校決定申込
                </Button>
              </Box>
            )}
          {[11].includes(reservation.Pattern!) && reservation.CanSchoolDecision && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" sx={styles.button} onClick={handleApproveSchoolDecision}>
                学校決定承認
              </Button>
            </Box>
          )}

          {[11].includes(reservation.Pattern!) && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" sx={styles.button} onClick={handleTransferReservation}>
                予約付替
              </Button>
            </Box>
          )}
          {[11, 310].includes(reservation.Pattern!) &&
            reservation.CanApplication &&
            isLedgerOpen &&
            hasControl &&
            isValidSession && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="contained" sx={styles.button} onClick={handleApplyReservation}>
                  本予約申込
                </Button>
              </Box>
            )}
          {[320].includes(reservation.Pattern!) && isLedgerOpen && hasControl && isValidSession && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" sx={styles.button} onClick={handleCancelApplication}>
                本予約申請取消
              </Button>
            </Box>
          )}
          {[23].includes(reservation.Pattern!) && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" sx={styles.button} onClick={handleGoToAcceptMinkaMail}>
                受入民家メール配信
              </Button>
            </Box>
          )}
          {[230].includes(reservation.Pattern!) && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" sx={styles.button} onClick={handleGoToMinkaAllocateLedger}>
                民家割り振り
              </Button>
            </Box>
          )}
          {/* 旅行代理店、学校にはリリース時に置いては、機能開放しない
          {[230, 330, 430, 730].includes(reservation.Pattern!)  && isLedgerOpen && hasControl && isValidSession && ( */}
          {[230].includes(reservation.Pattern!) && isLedgerOpen && hasControl && isValidSession && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" sx={styles.button} onClick={handleGoToStudentLedger}>
                生徒割り振り
              </Button>
            </Box>
          )}
          {/* 旅行代理店、学校にはリリース時に置いては、機能開放しない
          {[230, 330, 430, 730].includes(reservation.Pattern!)  && isLedgerOpen && hasControl && isValidSession && ( */}
          {[230].includes(reservation.Pattern!) && isLedgerOpen && hasControl && isValidSession && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" sx={styles.button} onClick={handleGoToStudentList}>
                生徒管理
              </Button>
            </Box>
          )}
          {[13, 310, 330, 230].includes(reservation.Pattern!) && isLedgerOpen && hasControl && isValidSession && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" sx={styles.button} onClick={handleCancelReservation}>
                予約取消
              </Button>
            </Box>
          )}
          {[311].includes(reservation.Pattern!) && isLedgerOpen && isValidSession && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" sx={styles.button} onClick={handleSharedReserve}>
                相乗予約
              </Button>
            </Box>
          )}
          {[12].includes(reservation.Pattern!) && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" sx={styles.button} onClick={handleApproveReservation}>
                本予約承認
              </Button>
            </Box>
          )}
          {[12].includes(reservation.Pattern!) && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" sx={styles.button} onClick={handleBackReservationApproval}>
                本予約承認差し戻し
              </Button>
            </Box>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" sx={styles.button} onClick={handleClose}>
              閉じる
            </Button>
          </Box>
        </Stack>
      </Container>
      <SchoolSearchDialog handleDialogClose={handleSearchSchoolClose} dialogOpen={searchSchoolOpen} />
      <GuestInfoDialog
        handleDialogClose={handleGuestInfoClose}
        dialogOpen={guestInfoOpen}
        guestInfo={guestInfo}
        reservationId={reservation.ReservationId!}
        updateDatetime={reservation.UpdateDatetime!}
      />
      <GuestCountDialog
        handleDialogClose={handleGuestCountClose}
        dialogOpen={guestCountOpen}
        guestCount={guestCount}
        reservationId={reservation.ReservationId!}
        updateDatetime={reservation.UpdateDatetime!}
      />
      <DurationDateDialog
        handleDialogClose={handleDurationDateClose}
        reservationDay={reservationDay}
        dialogOpen={durationDateOpen}
        reservationId={reservation.ReservationId!}
        updateDatetime={reservation.UpdateDatetime!}
      />
      <ServiceDialog
        handleDialogClose={handleServiceClose}
        dialogOpen={serviceOpen}
        reservationId={reservation.ReservationId!}
        service={service}
        mode={serviceMode}
        updateDatetime={reservation.UpdateDatetime!}
      />
      <OptionDialog
        handleDialogClose={handleOptionalServiceClose}
        dialogOpen={optionalServiceOpen}
        optionalService={optionalService}
        reservationId={reservation.ReservationId!}
        guestCount={reservation.GuestCount!}
        checkInDate={reservation.CheckInDate!}
        updateDatetime={reservation.UpdateDatetime!}
        mode={optionalServiceMode}
      />
      <AgentDialog
        handleDialogClose={handleAgentClose}
        dialogOpen={agentOpen}
        reservationId={reservation.ReservationId!}
        updateDatetime={reservation.UpdateDatetime!}
        agent={agent}
        mode={agentMode}
        reservationDays={reservation.ReservationDays || []}
        schoolConfirmed={Boolean(reservation.SchoolConfirmDate)}
      />
      <ServiceProviderDialog
        handleDialogClose={handleServiceProviderClose}
        dialogOpen={serviceProviderOpen}
        serviceProvider={serviceProvider}
        isReserveAdmin={[13, 19].includes(reservation.Pattern!)}
        reservationId={reservation.ReservationId!}
        mode={serviceProviderMode}
        updateDatetime={reservation.UpdateDatetime!}
      />
      <CancelDialog
        handleDialogClose={handleCancelClose}
        dialogOpen={cancelOpen}
        reservationId={reservation.ReservationId!}
        updateDatetime={reservation.UpdateDatetime!}
      />
      <AlertDialog handleDialogClose={handleAlertClose} dialogOpen={alertOpen} content={alertContent} />
      <ApplicationDialog
        handleDialogClose={handleApplicationClose}
        dialogOpen={applicationOpen}
        reservationId={reservation.ReservationId!}
        updateDatetime={reservation.UpdateDatetime!}
      />
      <TransferReservationDialog
        handleDialogClose={handleTransferReservationClose}
        dialogOpen={transferReservationOpen}
        reservationId={reservation.ReservationId!}
        updateDatetime={reservation.UpdateDatetime!}
      />
      <SchoolDecisionApplicationDialog
        handleDialogClose={handleScoolDecisionApplicationClose}
        dialogOpen={schoolDecisionApplicationOpen}
        reservationId={reservation.ReservationId!}
        updateDatetime={reservation.UpdateDatetime!}
      />
      <ApprovalDialog
        handleDialogClose={handleApprovalClose}
        dialogOpen={approvalOpen}
        reservationId={reservation.ReservationId!}
        updateDatetime={reservation.UpdateDatetime!}
      />
      <AddDaysDialog
        handleDialogClose={handleAddReserveDayClose}
        dialogOpen={addReserveDayOpen}
        reservationId={reservation.ReservationId!}
        updateDatetime={reservation.UpdateDatetime!}
        initialValue={addDayInitialValue}
      />

      <SessionInvalidMessageDialog
        handleDialogClose={handleSessionInvalidMessageClose}
        dialogOpen={sessionInvalidMessageOpen}
      />
    </GenericTemplate>
  );
};
export default ReserveRegist;
