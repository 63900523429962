import { Container, FormControl, FormHelperText } from '@mui/material';
import Button from '@mui/material/Button/Button';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import Grid from '@mui/material/Grid/Grid';
import Radio from '@mui/material/Radio/Radio';
import RadioGroup from '@mui/material/RadioGroup/RadioGroup';
import TextField from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography/Typography';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { MSchools, Prefecture } from '../../../../API';
import { SelectValue } from '../../../../types/SelectValue';
import SelectFormControl from '../../../parts/SelectFormControl';
import GenericTemplate from '../../../template/GenericTemplate';
import { ErrorData } from './SchoolDetailErrorData';

const styles = {
  searchField: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
  },

  label: {
    margin: 'auto',
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
  },
  text: {
    mt: '7px',
    wordWrap: 'break-word',
  },
  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
  },
  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
    '& > div > input': {
      maxHeight: '10px',
    },
  },
  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },
  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    ml: 1,
    float: 'right',
    mt: 1,
  },
  cancelButton: {
    width: 120,
    height: 40,
    mr: 20,
    float: 'right',
    mt: 3,
  },
  textField: {
    width: '100%',
    '& input': {
      padding: '8px 14px',
    },
  },
};

export interface Props {
  prefectureList: Prefecture[];
  inputData: MSchools;
  handleChangePrefectureCode: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeDetail: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickRegist: () => void;
  errorAttribute: ErrorData;
}

const SchoolDetail: FC<Props> = ({
  prefectureList,
  inputData,
  handleChangePrefectureCode,
  handleChangeDetail,
  handleClickRegist,
  errorAttribute,
}) => {
  return (
    <GenericTemplate title="学校詳細">
      <Container sx={styles.searchField}>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          学校情報
        </Typography>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>学校コード *</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              value={inputData.SchoolCode ?? ''}
              name={'SchoolCode'}
              sx={styles.textField}
              error={errorAttribute.SchoolCode.IsError}
              helperText={errorAttribute.SchoolCode.ErrorMessage}
              inputProps={{ maxLength: 13 }}
              onChange={handleChangeDetail}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>種別 *</Typography>
          </Grid>
          <Grid item xs={7}>
            <FormControl component="fieldset" error={errorAttribute.SchoolCategoryCode.IsError}>
              <RadioGroup
                value={inputData.SchoolCategoryCode ?? 'C1'}
                row
                aria-label="position"
                name="SchoolCategoryCode"
                defaultValue="C1"
                onChange={handleChangeDetail}
              >
                <FormControlLabel value="B1" control={<Radio color="primary" />} label="小学校" />
                <FormControlLabel value="C1" control={<Radio color="primary" />} label="中学校" />
                <FormControlLabel value="C2" control={<Radio color="primary" />} label="義務教育学校" />
                <FormControlLabel value="D1" control={<Radio color="primary" />} label="高等学校" />
                <FormControlLabel value="D2" control={<Radio color="primary" />} label="中等教育学校" />
                <FormControlLabel value="E1" control={<Radio color="primary" />} label="特別支援学校" />
                <FormControlLabel value="F1" control={<Radio color="primary" />} label="大学" />
                <FormControlLabel value="F2" control={<Radio color="primary" />} label="短期大学" />
                <FormControlLabel value="G1" control={<Radio color="primary" />} label="高等専門学校" />
                <FormControlLabel value="H1" control={<Radio color="primary" />} label="専修学校" />
              </RadioGroup>
              <FormHelperText>{errorAttribute.SchoolCategoryCode.ErrorMessage}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>都道府県 *</Typography>
          </Grid>
          <Grid item xs={7}>
            <SelectFormControl
              value={inputData.PrefectureCode ?? ''}
              selectName="PrefectureCode"
              selectValues={prefectureList.map((prefecture) => {
                return { value: prefecture.PrefectureCode, displayName: prefecture.PrefectureName } as SelectValue;
              })}
              setCondition={handleChangePrefectureCode}
              sx={styles.inputItem}
              error={errorAttribute.PrefectureCode.IsError}
              errorMessage={errorAttribute.PrefectureCode.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>設置区分 *</Typography>
          </Grid>
          <Grid item xs={7}>
            <FormControl component="fieldset" error={errorAttribute.ClassificationCode.IsError}>
              <RadioGroup
                value={inputData.ClassificationCode ?? '1'}
                row
                aria-label="position"
                name="ClassificationCode"
                defaultValue="1"
                onChange={handleChangeDetail}
              >
                <FormControlLabel value="1" control={<Radio color="primary" />} label="1:国立" />
                <FormControlLabel value="2" control={<Radio color="primary" />} label="2:公立" />
                <FormControlLabel value="3" control={<Radio color="primary" />} label="3:私立" />
              </RadioGroup>
              <FormHelperText>{errorAttribute.ClassificationCode.ErrorMessage}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>学校名 *</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              value={inputData.SchoolName ?? ''}
              name="SchoolName"
              sx={styles.textField}
              inputProps={{ maxLength: 40 }}
              onChange={handleChangeDetail}
              error={errorAttribute.SchoolName.IsError}
              helperText={errorAttribute.SchoolName.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>学校カナ名 *</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              value={inputData.SchoolKanaName ?? ''}
              name="SchoolKanaName"
              sx={styles.textField}
              inputProps={{ maxLength: 80 }}
              onChange={handleChangeDetail}
              error={errorAttribute.SchoolKanaName.IsError}
              helperText={errorAttribute.SchoolKanaName.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>所在地 *</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              value={inputData.Address ?? ''}
              name="Address"
              sx={styles.textField}
              inputProps={{ maxLength: 40 }}
              onChange={handleChangeDetail}
              error={errorAttribute.Address.IsError}
              helperText={errorAttribute.Address.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>郵便番号 *</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              value={inputData.PostalCode ?? ''}
              name="PostalCode"
              sx={styles.textField}
              inputProps={{ maxLength: 7 }}
              onChange={handleChangeDetail}
              error={errorAttribute.PostalCode.IsError}
              helperText={errorAttribute.PostalCode.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>電話番号</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              value={inputData.Tel ?? ''}
              name="Tel"
              sx={styles.textField}
              inputProps={{ maxLength: 13 }}
              onChange={handleChangeDetail}
              error={errorAttribute.Tel.IsError}
              helperText={errorAttribute.Tel.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>ファックス</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              value={inputData.Fax ?? ''}
              name="Fax"
              sx={styles.textField}
              inputProps={{ maxLength: 12 }}
              onChange={handleChangeDetail}
              error={errorAttribute.Fax.IsError}
              helperText={errorAttribute.Fax.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>有効区分 *</Typography>
          </Grid>
          <Grid item xs={7}>
            <FormControl component="fieldset" error={errorAttribute.IsActive.IsError}>
              <RadioGroup
                value={inputData.IsActive ?? '1'}
                row
                aria-label="position"
                name="IsActive"
                defaultValue="1"
                onChange={handleChangeDetail}
              >
                <FormControlLabel value="1" control={<Radio color="primary" />} label="有効" />
                <FormControlLabel value="0" control={<Radio color="primary" />} label="無効" />
              </RadioGroup>
              <FormHelperText>{errorAttribute.IsActive.ErrorMessage}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={10}>
            <Link to="/settings/school/list">
              <Button variant="contained" sx={styles.button}>
                閉じる
              </Button>
            </Link>
            <Button variant="contained" sx={styles.button} onClick={handleClickRegist}>
              登録
            </Button>
          </Grid>
        </Grid>
      </Container>
    </GenericTemplate>
  );
};

export default SchoolDetail;
