import { FC } from 'react';
import ApplicationDialog from './ApplicationDialog';
import UseApplicationDialog from './UseApplicationDialog';

export interface Props {
  handleDialogClose: (_?: string) => void;
  dialogOpen: boolean;
  reservationId: string;
  updateDatetime: string;
}

const ApplicationDialogContainer: FC<Props> = (props: Props) => {
  return <ApplicationDialog {...UseApplicationDialog(props)} />;
};

export default ApplicationDialogContainer;
