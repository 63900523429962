import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Container } from '@mui/material';
import Button from '@mui/material/Button/Button';
import FormControl from '@mui/material/FormControl/FormControl';
import Grid from '@mui/material/Grid/Grid';
import TextField from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography/Typography';
import jaLocale from 'date-fns/locale/ja';
import React, { FC } from 'react';
import { MinkaVehicleRequest } from 'API';
import GenericTemplate from '../../../template/GenericTemplate';
import { ErrorData } from './CarDetailErrorData';
import FormHelperText from '@mui/material/FormHelperText';
import BackToTopButton from 'views/parts/BackToTop';

const styles = {
  label: {
    margin: 'auto',
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
  },

  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
  },

  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
    '& > div > input': {
      maxHeight: '10px',
    },
  },

  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 3,
    ml: 1,
    float: 'right',
  },

  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
    display: 'flex',
  },

  textField: {
    width: '100%',
    '& input': {
      padding: '8px 14px',
    },
  },
};

export interface Props {
  vehicleData: MinkaVehicleRequest;
  errorAttribute: ErrorData;
  handleChangeInput: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickRegist: () => void;
  handleChangeInspectionDate: (_: Date | null) => void;
  handleChangeInsuranceEndDate: (_: Date | null) => void;
}

const CarDetail: FC<Props> = (props) => {
  const pushback = function () {
    window.history.back();
  };

  return (
    <GenericTemplate title="⺠家登録⾞両詳細">
      <div>
        <BackToTopButton />
      </div>
      <Container>
        {props.errorAttribute.Header.ErrorMessage && (
          <Grid container spacing={2} sx={{ pl: 0, pb: 2 }}>
            <Grid item xs={12}>
              <FormControl component="fieldset" error={props.errorAttribute.Header.IsError}>
                <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                  {props.errorAttribute.Header.ErrorMessage}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        )}
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          登録車両詳細
        </Typography>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>車両ナンバー</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              sx={styles.textField}
              name={'CarNo'}
              value={props.vehicleData.CarNo ?? ''}
              onChange={props.handleChangeInput}
              inputProps={{ maxLength: 20 }}
              error={props.errorAttribute.CarNo.IsError}
              helperText={props.errorAttribute.CarNo.ErrorMessage}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>車検期限日</Typography>
          </Grid>
          <Grid item xs={7}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
              <DesktopDatePicker
                label="yyyy/mm/dd"
                mask="____/__/__"
                value={new Date(props.vehicleData.InspectionDate ?? '')}
                onChange={(newValue) => props.handleChangeInspectionDate(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    error={props.errorAttribute.InspectionDate.IsError}
                    helperText={props.errorAttribute.InspectionDate.ErrorMessage}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>任意保険期限日</Typography>
          </Grid>
          <Grid item xs={7}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
              <DesktopDatePicker
                label="yyyy/mm/dd"
                mask="____/__/__"
                value={new Date(props.vehicleData.InsuranceEndDate ?? '')}
                onChange={(newValue) => props.handleChangeInsuranceEndDate(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    error={props.errorAttribute.InsuranceEndDate.IsError}
                    helperText={props.errorAttribute.InsuranceEndDate.ErrorMessage}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>車種</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              sx={styles.textField}
              name={'CarType'}
              value={props.vehicleData.CarType}
              onChange={props.handleChangeInput}
              inputProps={{ maxLength: 40 }}
              error={props.errorAttribute.CarType.IsError}
              helperText={props.errorAttribute.CarType.ErrorMessage}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>人数</Typography>
          </Grid>
          <Grid item xs={7}>
            <FormControl size="small" sx={styles.inputItem}>
              <TextField
                sx={styles.textField}
                name={'Passenger'}
                value={props.vehicleData.Passenger}
                onChange={props.handleChangeInput}
                type={'number'}
                error={props.errorAttribute.Passenger.IsError}
                helperText={props.errorAttribute.Passenger.ErrorMessage}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>保険の内容</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              sx={styles.textField}
              value={props.vehicleData.CarInsurance}
              rows={4}
              multiline={true}
              variant="outlined"
              inputProps={{ maxLength: 255 }}
              name={'CarInsurance'}
              onChange={props.handleChangeInput}
              error={props.errorAttribute.CarInsurance.IsError}
              helperText={props.errorAttribute.CarInsurance.ErrorMessage}
            />
          </Grid>
        </Grid>

        <Grid sx={{ width: '83%' }}>
          <Button variant="contained" sx={styles.button} onClick={pushback}>
            閉じる
          </Button>
          <Button variant="contained" sx={styles.button} onClick={props.handleClickRegist}>
            登録
          </Button>
        </Grid>
      </Container>
    </GenericTemplate>
  );
};

export default CarDetail;
