import { useState, useEffect, useCallback } from 'react';
import React from 'react';
import { DeleteSchoolMutation, FindMSchoolsQuery, MSchools, Prefecture, SchoolRequestData } from '../../../../API';
import { graphqlOperation } from 'aws-amplify';
import { useAPI } from 'contexts/APIRequestContext';
import { findMSchools } from 'graphql/queries';
import { CreateEmptyErrorData, ErrorData } from './SchoolListErrorData';
import { deleteSchool } from 'graphql/mutations';
import { useNavigate } from 'react-router-dom';
import { checkAuthority } from 'util/AuthorityCheck';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';

const UseSchoolList = () => {
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [inputData, setInputData] = useState({} as SchoolRequestData);
  const [resultData, setResultData] = useState([] as MSchools[]);
  const api = useAPI();
  const [, setError] = useState<undefined>();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertDialogCallback, setAlertDialogCallback] = useState({ fn: (_: boolean) => {} });
  const navigator = useNavigate();
  const { user } = useAuthenticator();
  const userData = new UserData(user);

  const defalutPage = 0;
  const defaultRowsPerPage = 25;
  const [page, setPage] = useState(defalutPage);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('SchoolList', userData.authorityCode)) {
      navigator('/');
    }

    // 詳細画面から戻ってきた場合、ローカルストレージに退避していた情報を復元する
    const shouldRestore = sessionStorage.getItem('SchoolList_ShouldRestore');
    if (shouldRestore) {
      const storedConditionsString = localStorage.getItem('SchoolList_SearchConditions');
      let storedConditions = JSON.parse(storedConditionsString ?? '');
      if (storedConditions) {
        // setCompanyName(storedConditions?.inputData?.CompanyName ?? '');
        // setIsActive(storedConditions?.inputData?.IsActive ?? '');
        // setCategoryCode(storedConditions?.inputData?.CategoryCode ?? '');

        setInputData(storedConditions?.inputData);
        fetchSchoolsWithParams(storedConditions?.inputData);
        setRowsPerPage(storedConditions?.rowsPerPage);
        setPage(storedConditions?.page);
      }
    } else {
      // NOTE: 変更を検知して条件をセットしている為、初期値のある項目は起動時に条件指定する。
      inputData.IsActive = '1';
    }

    // フラグをリセット
    sessionStorage.removeItem('SchoolList_ShouldRestore');
  }, []);

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  const saveStateToLocalStorage = () => {
    // ローカルストレージまたはセッションストレージに検索条件を退避する
    localStorage.setItem('SchoolList_SearchConditions', JSON.stringify({ inputData, rowsPerPage, page }));
    // 戻った時に状態を復元するフラグをセット
    sessionStorage.setItem('SchoolList_ShouldRestore', 'true');
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    setInputData({ ...inputData, [name]: value });
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    const checked = event.target.checked;

    let list;
    if (name == 'SchoolCategoryList') {
      list = inputData['SchoolCategoryList'] as Array<string> | null;
    } else {
      list = inputData['ClassificationList'] as Array<string> | null;
    }
    if (!list) {
      list = [] as string[];
    }
    if (checked) {
      if (!list?.find((x) => x == value)) {
        list?.push(value);
      }
    } else {
      if (list?.find((x) => x == value)) {
        list = list.filter((x) => x != value);
      }
    }
    setInputData({ ...inputData, [name]: list });
  };

  const handleClickSearch = () => {
    fetchSchools();
  };

  async function fetchSchools() {
    await fetchAndSetCompanies(inputData);
  }
  async function fetchSchoolsWithParams(params: SchoolRequestData) {
    await fetchAndSetCompanies(params);
  }

  async function fetchAndSetCompanies(params: SchoolRequestData) {
    const response = (await api.graphql(
      graphqlOperation(findMSchools, {
        data: params,
      })
    )) as FindMSchoolsQuery;

    if (response?.findMSchools?.Body?.length) {
      setResultData(response?.findMSchools?.Body as MSchools[]);
    } else {
      setResultData([] as MSchools[]);
    }
  }

  const executeDeleteSchool = async (school: MSchools): Promise<void> => {
    const response = (
      (await api.graphql({
        query: deleteSchool,
        variables: {
          data: {
            SchoolId: school.SchoolId,
            UpdateDatetime: school.UpdateDatetime,
          },
        },
      })) as DeleteSchoolMutation
    ).deleteSchool;

    if (response?.IsSuccess) {
      setResultData(resultData.filter((x) => x.SchoolId !== school.SchoolId));
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  };

  const handleClickDelete = async (school: MSchools): Promise<void> => {
    setAlertDialogCallback({
      fn: async (result: boolean) => {
        if (result) {
          executeDeleteSchool(school);
        }
      },
    });
    setAlertContent('削除します。よろしいですか？');
    setAlertOpen(true);
  };

  /**
   * 警告ダイアログ　閉じる
   * @param result 結果
   */
  const handleAlertClose = (result: boolean): void => {
    alertDialogCallback.fn(result);
    setAlertOpen(false);
  };

  return {
    inputData,
    resultData,
    errorAttribute,
    alertOpen,
    alertContent,
    page,
    rowsPerPage,
    handleChangeInput,
    handleChangeCheckbox,
    handleClickSearch,
    handleClickDelete,
    handleAlertClose,
    saveStateToLocalStorage,
    handleChangePage,
    handleChangeRowsPerPage,
  };
};
export default UseSchoolList;
