import FormErrorAttribute from 'types/FormErrorAttribute';

export interface ErrorData {
  Header: FormErrorAttribute;
  BranchName: FormErrorAttribute;
  PostalCode: FormErrorAttribute;
  PrefectureCode: FormErrorAttribute;
  Address: FormErrorAttribute;
  Tel: FormErrorAttribute;
  Fax: FormErrorAttribute;
  Mail1: FormErrorAttribute;
  Mail2: FormErrorAttribute;
  Mail3: FormErrorAttribute;
  Mail4: FormErrorAttribute;
  Mail5: FormErrorAttribute;
  Mail6: FormErrorAttribute;
}

export const CreateEmptyErrorData = () => {
  return {
    Header: {} as FormErrorAttribute,
    BranchName: {} as FormErrorAttribute,
    PostalCode: {} as FormErrorAttribute,
    PrefectureCode: {} as FormErrorAttribute,
    Address: {} as FormErrorAttribute,
    Tel: {} as FormErrorAttribute,
    Fax: {} as FormErrorAttribute,
    Mail1: {} as FormErrorAttribute,
    Mail2: {} as FormErrorAttribute,
    Mail3: {} as FormErrorAttribute,
    Mail4: {} as FormErrorAttribute,
    Mail5: {} as FormErrorAttribute,
    Mail6: {} as FormErrorAttribute,
  } as ErrorData;
};
