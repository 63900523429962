import { Auth, graphqlOperation } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';
import {
  ApproveSchoolDecisionMutation,
  BackReservationApprovalMutation,
  Branch,
  CancelReservationMutation,
  CompanyBranch,
  DeleteOptionalServiceMutation,
  DeleteReservationAgent,
  DeleteReservationAgentMutation,
  DeleteReservedDay,
  DeleteReservedDayMutation,
  ExtendReservedDayMutation,
  ExtendReservedDayRequest,
  GetMaxMultiSchoolGuestCountQuery,
  GetReservationQuery,
  MSchools,
  OptionalService,
  RegistCancelReservation,
  RegistReservation,
  RegistReservationMutation,
  Reservation,
  ReservationAgent,
  ReservationDay,
  Service,
  IsUserLoggedInMutation,
} from 'API';
import { useAPI } from 'contexts/APIRequestContext';
import { useMaster } from 'contexts/CommonMasterContext';
import {
  approveSchoolDecision,
  backReservationApproval,
  cancelReservation,
  deleteOptionalService,
  deleteReservationAgent,
  deleteReservedDay,
  extendReservedDay,
  registReservation,
  isUserLoggedIn,
} from 'graphql/mutations';
import { getMaxMultiSchoolGuestCount, getReservation } from 'graphql/queries';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GuestCount } from 'types/GuestCount';
import { GuestInfo } from 'types/GuestInfo';
import { ServiceProvider } from 'types/ServiceProvider';
import { getYYYMMDD } from 'util/DateUtil';
import { ReservationFrame } from './ReservationFrame';
import { CreateEmptyErrorData, ErrorData } from './ReserveRegistErrorData';
import { UserData } from 'models/UserData';
import { checkAuthority } from 'util/AuthorityCheck';
import { getJwtToken } from 'util/SessionController';
import { ApprovalStatusCode } from 'constant/Constant';
import { AuthorityCode } from 'constant/Constant';
import { scrollToTop } from 'util/Scroll';

const UseReserveRegist = () => {
  const location = useLocation();
  const reservationFrame = location.state as ReservationFrame;
  const [reservation, setReservation] = useState({} as Reservation);
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [companyList, setCompanyList] = useState([] as CompanyBranch[]);
  const [branchList, setBranchList] = useState([] as Branch[]);
  const [searchSchoolOpen, setSearchSchoolOpen] = useState(false);
  const [guestInfoOpen, setGuestInfoOpen] = useState(false);
  const [guestInfo, setGuestInfo] = useState({} as GuestInfo);
  const [guestCountOpen, setGuestCountOpen] = useState(false);
  const [guestCount, setGuestCount] = useState({} as GuestCount);
  const [durationDateOpen, setDurationDateOpen] = useState(false);
  const [reservationDay, setReservationDay] = useState({} as ReservationDay);
  const [areaServiceRateOpen, setAreaServiceRateOpen] = useState(false);
  const [serviceOpen, setServiceOpen] = useState(false);
  const [service, setService] = useState({} as Service);
  const [serviceMode, setServiceMode] = useState('');
  const [optionalServiceOpen, setOptionalServiceOpen] = useState(false);
  const [copyReservationOpen, setCopyReservationOpen] = useState(false);
  const [includeReservationDate, setIncludeReservationDate] = useState(false);
  const [optionalService, setOptionalService] = useState({} as OptionalService);
  const [optionalServiceIndex, setOptionalServiceIndex] = useState(-1);
  const [optionalServiceMode, setOptionalServiceMode] = useState('');
  const [copyReservationId, setCopyReservationId] = useState('');
  const [agentOpen, setAgentOpen] = useState(false);
  const [agent, setAgent] = useState({} as ReservationAgent);
  const [agentIndex, setAgentIndex] = useState(-1);
  const [agentMode, setAgentMode] = useState('');
  const [serviceProviderOpen, setServiceProviderOpen] = useState(false);
  const [serviceProvider, setServiceProvider] = useState({} as ServiceProvider);
  const [serviceProviderMode, setServiceProviderMode] = useState('');
  const [url, setUrl] = useState('');
  const [selectSchool, setSelectSchool] = useState({} as MSchools);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [approvalOpen, setApprovalOpen] = useState(false);
  const [schoolDecisionApplicationOpen, setSchoolDecisionApplicationOpen] = useState(false);
  const [transferReservationOpen, setTransferReservationOpen] = useState(false);
  const [applicationOpen, setApplicationOpen] = useState(false);
  const [addReserveDayOpen, setAddReserveDayOpen] = useState(false);
  const [sessionInvalidMessageOpen, setSessionInvalidMessageOpen] = useState(false);
  const master = useMaster();
  const api = useAPI();
  const navigate = useNavigate();
  const [alertDialogCallback, setAlertDialogCallback] = useState({ fn: (_: boolean) => {} });
  const { user } = useAuthenticator();
  const [, setError] = useState<undefined>();
  const userData = new UserData(user);
  const [addDayInitialValue, setAddDayInitialValue] = useState('');
  const [isLedgerOpen, setIsLedgerOpen] = useState(true);
  const [hasControl, setHasControl] = useState(true);
  const [canAllDept, setCanAllDept] = useState(false);
  const [isValidSession, setValidSession] = useState(true);

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('ReserveRegist', userData.authorityCode)) {
      navigate('/');
    }
    if (
      window.location.pathname.includes('detail') &&
      !(reservationFrame.ReservationId && reservationFrame.ReservedDay)
    ) {
      // 詳細ダイアログで予約ID、予約日が指定されていない場合は前の画面に戻る
      back();
      return;
    }

    if (master.getCompanies()?.length) {
      setCompanyList(master.getCompanies().filter((x) => x.CategoryCode === '03' && x.IsActive));
      fetchReservation();
    } else {
      master.waitForInitialized(() => {
        setCompanyList(master.getCompanies().filter((x) => x.CategoryCode === '03' && x.IsActive));
        fetchReservation();
      });
    }
  }, []);

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  async function executeCheckUserLoginStatus(): Promise<boolean> {
    const jwtToken = await getJwtToken();
    const response = (
      (await api.graphql(
        graphqlOperation(isUserLoggedIn, {
          data: jwtToken,
        })
      )) as IsUserLoggedInMutation
    ).IsUserLoggedIn;

    if (response) {
      if (response.IsSuccess && response.Body === true) {
        return true;
      } else {
        setValidSession(false);
        setSessionInvalidMessageOpen(true);
        return false;
      }
    }
    return false;
  }

  // コピー元予約を取得してセットします
  async function fetchSourceReservationAndSet() {
    const response = (
      (await api.graphql({
        query: getReservation,
        variables: {
          ReservationId: copyReservationId,
          ReservedDay: '9999/12/31',
          AreaId: null,
        },
      })) as GetReservationQuery
    ).getReservation;
    if (response?.IsSuccess) {
      const _reservation = response?.Body as Reservation;
      if ([311, 321, 322, 331, 391, 411, 421, 431, 491].includes(_reservation.Pattern!)) {
        setErrorAttribute({
          ...errorAttribute,
          Header: { IsError: true, ErrorMessage: '他社の予約はコピーできません' },
        });
        return;
      }

      const originalReservationDays = _reservation.ReservationDays;
      let newReservationDays: ReservationDay[] = [];
      if (includeReservationDate) {
        // 本予約等をコピーしてきた場合を考慮して、ステータス等を編集
        newReservationDays =
          originalReservationDays?.map((day) => ({
            ...day,
            ReservationStatus: '---',
            DurationDate: '----/--/-- --:--:---',
            CanExtend: false,
          })) ?? [];
      } else {
        newReservationDays = [
          {
            __typename: 'ReservationDay',
            ReservedDay: reservationFrame.ReservedDay,
          },
        ];
      }
      setReservation({
        ...reservation,

        SchoolId: _reservation.SchoolId,
        SchoolName: _reservation.SchoolName,
        SchoolKanaName: _reservation.SchoolKanaName,
        HasGroupingName: _reservation.HasGroupingName,
        GroupingName: _reservation.GroupingName,
        HasAnotherResv: _reservation.HasAnotherResv,
        GuestCount: _reservation.GuestCount,
        ReservationDays: newReservationDays,
        // 自店権限者が他営業店の予約をコピーした場合、代理店情報はコピーしない
        ReservationAgents:
          canAllDept ||
          (_reservation.ReservationAgents?.[0]?.BookingBranchId === userData.branchId &&
            _reservation.ReservationAgents?.[0]?.SalesBranchId === userData.branchId)
            ? _reservation.ReservationAgents
            : reservation.ReservationAgents,
      });

      const copiedMSchools: MSchools = {
        __typename: 'MSchools',
        SchoolId: _reservation.SchoolId ?? '',
        SchoolName: _reservation.SchoolName ?? '',
        SchoolKanaName: _reservation.SchoolKanaName ?? '',
      };
      setSelectSchool(copiedMSchools);
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...errorAttribute,
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
      scrollToTop();
    } else {
      throwError();
    }
  }

  async function fetchReservation() {
    const response = (
      (await api.graphql({
        query: getReservation,
        variables: {
          ReservationId: reservationFrame.ReservationId,
          ReservedDay: reservationFrame.ReservedDay,
          AreaId: reservationFrame.AreaId,
        },
      })) as GetReservationQuery
    ).getReservation;

    if (response?.IsSuccess) {
      const _reservation = response?.Body as Reservation;

      if (!reservationFrame.ReservationId) {
        // 新規登録の場合
        _reservation.AreaId = reservationFrame.AreaId;
        _reservation.AreaName = reservationFrame.AreaName;
        _reservation.PrefectureCode = reservationFrame.PrefectureCode;
        _reservation.PrefectureName = reservationFrame.PrefectureName;
        _reservation.MaxGuestCount = reservationFrame.MaxGuestCount;
        _reservation.MaxSchoolCount = reservationFrame.MaxSchoolCount;
        _reservation.MaxMultiSchoolGuestCount = reservationFrame.MaxMultiSchoolGuestCount;
        _reservation.AreaCheckInTime = reservationFrame.CheckInTime;
        _reservation.AreaCheckOutTime = reservationFrame.CheckOutTime;
        _reservation.Memo1 = reservationFrame.Memo1;
        // 選択した予約枠の日付を初期値に設定
        _reservation.ReservationDays![0].ReservedDay = reservationFrame.ReservedDay;
      }

      if (_reservation.ReservationAgents![0]?.CompanyId) {
        const _branchList = master.getBranchs(_reservation.ReservationAgents![0]?.CompanyId) as Branch[];
        if (_branchList) {
          setBranchList(_branchList.filter((x) => x.IsActive));
        }
      }
      setReservation(_reservation);

      setUrl(master.getAreaUrl(reservationFrame.AreaId ?? ''));

      const date = new Date(_reservation.ReservationDays?.[0].ReservedDay?.toString() ?? '');
      const schoolYear = master
        .getSchoolYearSettings()
        .filter((x) => new Date(x.SchoolYear.StartDate) <= date && new Date(x.SchoolYear.EndDate) >= date)
        .shift();

      if (
        userData.authorityCode != AuthorityCode.ReserveAdministrator &&
        userData.authorityCode != AuthorityCode.AcceptAdministrator &&
        schoolYear &&
        new Date(schoolYear.SchoolYear.OpenDatetime) > new Date()
      ) {
        setIsLedgerOpen(false);
      }

      if (
        [300, 310, 320, 330, 400, 410, 420, 430].includes(_reservation.Pattern!) &&
        _reservation.ReservationAgents![0].SalesBranchId != userData.branchId &&
        !userData.canAllDept
      ) {
        setHasControl(false);
      }

      if ([300, 310, 320, 330].includes(_reservation.Pattern!) && !userData.canAllDept) {
        executeCheckUserLoginStatus();
      }
      setCanAllDept(userData.canAllDept);
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...errorAttribute,
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
      scrollToTop();
    } else {
      throwError();
    }
  }

  /**
   * ブラウザバック
   */
  const back = (): void => {
    window.history.back();
  };

  /**
   * 入力値変更
   * @param event イベント
   */
  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    setReservation({ ...reservation, [name]: value });

    // 値が変更されたらエラー情報をクリア
    setErrorAttribute({ ...errorAttribute, [name]: { isError: false, errorMessage: '' } });
  };

  /**
   * 最新表示
   */
  const handleReload = (): void => {
    fetchReservation();
  };

  /**
   * 予約コピー
   */
  const handleCopyReservation = (): void => {
    setErrorAttribute({
      ...errorAttribute,
      Header: { IsError: false, ErrorMessage: '' },
    });
    fetchSourceReservationAndSet();
  };

  /**
   * 宿泊者情報　検索アイコン
   */
  const handleSearchSchoolOpen = (): void => {
    setSearchSchoolOpen(true);
  };

  /**
   * 宿泊者情報　団別管理変更
   * @param event イベント
   */
  const handleChangeHasGroupingName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    reservation.HasGroupingName = Number(event.target.value);
    if (reservation.HasGroupingName !== 1) {
      reservation.GroupingName = '';
    }

    setReservation({ ...reservation, ReservationDays: reservation.ReservationDays });
  };

  /**
   * 宿泊者情報　編集
   */
  const handleEditGuestInfo = (): void => {
    setGuestInfo({
      ...guestInfo,
      SchoolName: reservation.SchoolName!,
      SchoolKanaName: reservation.SchoolKanaName!,
      HasGroupingName: reservation.HasGroupingName,
      GroupingName: reservation.GroupingName!,
      HasAnotherResv: reservation.HasAnotherResv,
    });

    setGuestInfoOpen(true);
  };

  /**
   * 受入可能な人数の最大値を取得する
   */
  async function fetchMaxMultiSchoolGuestCount() {
    const response = (await api.graphql({
      query: getMaxMultiSchoolGuestCount,
      variables: {
        AreaId: reservationFrame.AreaId,
        Date: reservationFrame.ReservedDay,
      },
    })) as GetMaxMultiSchoolGuestCountQuery;

    reservation.GuestCount = response?.getMaxMultiSchoolGuestCount?.Body as number;
    setReservation({ ...reservation, HasAnotherResv: reservation.HasAnotherResv, GuestCount: reservation.GuestCount });
  }

  /**
   * 宿泊者情報　分宿変更時
   */
  const handleChangeHasAnotherResv = (event: React.ChangeEvent<HTMLInputElement>): void => {
    reservation.HasAnotherResv = Number(event.target.value);

    if (reservation.HasAnotherResv) {
      // 分宿する場合、受入可能な人数の最大値を取得する
      fetchMaxMultiSchoolGuestCount();
    } else {
      setReservation({ ...reservation, HasAnotherResv: reservation.HasAnotherResv });
    }
  };

  /** 宿泊人数　更新 */
  const handleChangeGuestCount = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value.trim(); // 空白文字を削除
    if (value === '') {
      setReservation({ ...reservation, GuestCount: null });
    } else {
      const num = Number(value);
      const guestCount = Number.isNaN(num) ? 0 : num;
      setReservation({ ...reservation, GuestCount: guestCount });
    }

    setErrorAttribute({ ...errorAttribute, GuestCount: { IsError: false, ErrorMessage: '' } });
  };

  /** コピー予約IDのセット */
  const handleChangeCopyReservationId = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCopyReservationId(event.target.value);

    setErrorAttribute({ ...errorAttribute, GuestCount: { IsError: false, ErrorMessage: '' } });
  };

  /**
   * 宿泊人数　編集
   */
  const handleEditGuestCount = (): void => {
    setGuestCount({ ...guestCount, GuestCount: reservation.GuestCount! });

    setGuestCountOpen(true);
  };

  /**
   * 料金表　表示切替
   */
  const handleAreaServiceRateOpen = (): void => {
    if (areaServiceRateOpen) {
      setAreaServiceRateOpen(false);
    } else {
      setAreaServiceRateOpen(true);
    }
  };

  /**
   * 予約コピー項目表示制御
   */
  const handleCopyReservationOpen = (): void => {
    if (copyReservationOpen) {
      setCopyReservationOpen(false);
    } else {
      setCopyReservationOpen(true);
    }
  };

  const handleChangeIncludeReservationDate = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setIncludeReservationDate(event.target.value === 'true');
  };

  /**
   * 予約日程　日程追加
   */
  const handleAddDays = () => {
    if (reservation.ApprovalStatusCode === ApprovalStatusCode.NotApploval) {
      // 初回登録後の未承認状態であれば、ダイアログから予約日程を追加
      const lastDate = reservation.ReservationDays?.slice(-1)[0].ReservedDay;
      const initialValue = new Date(String(lastDate));
      initialValue.setDate(initialValue.getDate() + 1);
      setAddDayInitialValue(getYYYMMDD(initialValue, '/'));
      setAddReserveDayOpen(true);
    } else {
      // 初回登録時であれば、行追加で入力
      if (reservation.ReservationDays) {
        if (reservation.ReservationDays.length === 0) {
          reservation.ReservationDays!.push({ ReservedDay: reservationFrame.ReservedDay } as ReservationDay);
        } else {
          const lastDate = reservation.ReservationDays.slice(-1)[0].ReservedDay;
          const newDate = new Date(String(lastDate));
          newDate.setDate(newDate.getDate() + 1);
          reservation.ReservationDays.push({ ReservedDay: getYYYMMDD(newDate, '/') } as ReservationDay);
        }
        setReservation({ ...reservation, ReservationDays: reservation.ReservationDays });
        setErrorAttribute({ ...errorAttribute, ReservationDays: { IsError: false, ErrorMessage: '' } });
      }
    }
  };

  /**
   * 予約日程削除処理
   * @param data リクエストパラメータ
   * @param idx 削除するインデックス
   */
  const executeDeleteReservedDay = async (data: DeleteReservedDay, idx: number): Promise<void> => {
    const response = (
      (await api.graphql({
        query: deleteReservedDay,
        variables: { data },
      })) as DeleteReservedDayMutation
    ).deleteReservedDay;

    if (response?.IsSuccess) {
      reservation.ReservationDays?.splice(idx, 1);
      setReservation({
        ...reservation,
        ReservationDays: reservation.ReservationDays,
        UpdateDatetime: response.Body,
      });
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...errorAttribute,
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
      scrollToTop();
    } else {
      throwError();
    }
  };

  /**
   * 予約日程　削除
   */
  const handleDeleteDays = (idx: number) => {
    if ([11, 310].includes(reservation.Pattern!)) {
      // 未承認の場合
      // メッセージを表示
      setAlertDialogCallback({
        fn: (result: boolean) => {
          if (result) {
            const data = {
              ReservationId: reservation.ReservationId!,
              ReservedDay: reservation.ReservationDays![idx].ReservedDay!,
              UpdateDatetime: reservation.UpdateDatetime,
            } as DeleteReservedDay;

            executeDeleteReservedDay(data, idx);
          }
        },
      });
      setAlertContent(
        '予約日【' +
          reservation.ReservationDays![idx].ReservedDay! +
          '】を削除します。この操作は元に戻せません。削除して宜しいですか？'
      );
      setAlertOpen(true);
    } else {
      if (reservation.ReservationDays) {
        reservation.ReservationDays.splice(idx, 1);
        setReservation({ ...reservation, ReservationDays: reservation.ReservationDays });

        setErrorAttribute({ ...errorAttribute, ReservationDays: { IsError: false, ErrorMessage: '' } });
      }
    }
  };

  /**
   * 予約日程　宿泊日更新
   */
  const handleChangeDays = (idx: number, name: string, value: any) => {
    if (reservation.ReservationDays) {
      (reservation.ReservationDays[idx]![name as keyof ReservationDay] as any) = getYYYMMDD(value, '/');
      setReservation({ ...reservation, ReservationDays: reservation.ReservationDays });

      // 値が変更されたらエラー情報をクリア
      setErrorAttribute({ ...errorAttribute, ReservationDays: { IsError: false, ErrorMessage: '' } });
    }
  };

  /**
   * 予約日程延長処理
   * @param data リクエストパラメータ
   * @param idx 削除するインデックス
   */
  const executeExtendReservedDay = async (data: ExtendReservedDayRequest, idx: number): Promise<void> => {
    const response = (
      (await api.graphql({
        query: extendReservedDay,
        variables: { data },
      })) as ExtendReservedDayMutation
    ).extendReservedDay;

    if (response?.IsSuccess) {
      reservation.ReservationDays![idx].DurationDate = response.Body?.DurationDate;
      reservation.ReservationDays![idx].CanExtend = false;
      setReservation({
        ...reservation,
        ReservationDays: reservation.ReservationDays,
        UpdateDatetime: response.Body?.UpdateDatetime,
      });
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...errorAttribute,
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
      scrollToTop();
    } else {
      throwError();
    }
  };

  /**
   * 予約日程　期間編集
   */
  const handleEditDurationDate = (idx: number): void => {
    const targetReservationDay = reservation.ReservationDays![idx];
    const durationDate = targetReservationDay.DurationDate;
    const date = durationDate ? new Date(durationDate) : null; // Date型に変換
    const formattedDate = date ? date.toISOString().substring(0, 10) : null; // nullを代入するか、別の値を代入する
    setReservationDay({
      ...reservationDay,
      ReservationId: targetReservationDay.ReservationId,
      ReservedDay: targetReservationDay.ReservedDay,
      ReservedMonth: targetReservationDay.ReservedMonth,
      ReservedYear: targetReservationDay.ReservedYear,
      ReservationStatusCode: targetReservationDay.ReservationStatusCode,
      ReservationStatus: targetReservationDay.ReservationStatus,
      DurationDate: formattedDate,
      CanExtend: targetReservationDay.CanExtend,
    });

    setDurationDateOpen(true);
  };

  /**
   * 予約日程　期間延長
   */
  const handleExtendTerm = (idx: number): void => {
    const data = {
      ReservationId: reservation.ReservationId!,
      ReservedDay: reservation.ReservationDays![idx].ReservedDay!,
      UpdateDatetime: reservation.UpdateDatetime,
    } as ExtendReservedDayRequest;

    executeExtendReservedDay(data, idx);
  };

  /**
   * 宿泊プラン　編集
   */
  const handleEditService = (): void => {
    setService({
      ...service,
      ServiceId: reservation.ServiceId!,
      ServiceName: reservation.ServiceName!,
      Rate: reservation.Rate!,
      CheckInDate: reservation.CheckInDate!,
      CheckInTime: reservation.CheckInTime!,
      CheckOutDate: reservation.CheckOutDate!,
      CheckOutTime: reservation.CheckOutTime!,
    });

    setServiceMode('MODIFY');
    setServiceOpen(true);
  };

  /**
   * 宿泊オプション　追加
   */
  const handleAddOption = () => {
    Object.keys(optionalService).forEach((x) => ((optionalService[x as keyof OptionalService] as any) = null));
    setOptionalService(optionalService);
    setOptionalServiceIndex(-1);
    setOptionalServiceMode('NEW');
    setOptionalServiceOpen(true);
  };

  /**
   * 宿泊オプション削除処理
   * @param idx 削除するインデックス
   */
  async function executeDeleteOptionalService(idx: number) {
    const response = (
      (await api.graphql({
        query: deleteOptionalService,
        variables: {
          data: {
            ReservationId: reservation.ReservationId,
            ServiceId: reservation.OptionalServices![idx].ServiceId,
            UpdateDatetime: reservation.UpdateDatetime,
          },
        },
      })) as DeleteOptionalServiceMutation
    ).deleteOptionalService;

    if (response?.IsSuccess) {
      reservation.OptionalServices?.splice(idx, 1);
      setReservation({
        ...reservation,
        OptionalServices: reservation.OptionalServices,
        UpdateDatetime: response.Body,
      });
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...errorAttribute,
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
      scrollToTop();
    } else {
      throwError();
    }
  }

  /**
   * 宿泊オプション　削除
   */
  const handleDeleteOption = (idx: number): void => {
    executeDeleteOptionalService(idx);
  };

  /**
   * 宿泊オプション　編集
   */
  const handleEditOption = (idx: number): void => {
    setOptionalService({ ...reservation.OptionalServices![idx]! });
    setOptionalServiceIndex(idx);
    setOptionalServiceMode('MODIFY');
    setOptionalServiceOpen(true);
  };

  /**
   * 代理店情報　代理店名更新
   * @param event イベント
   */
  const handleChangeCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Step1. 通常の入力値変更と店名クリア
    reservation.ReservationAgents![0]!.CompanyId = event.target.value;
    reservation.ReservationAgents![0]!.BookingBranchId = '';
    reservation.ReservationAgents![0]!.SalesBranchId = '';
    reservation.ReservationAgents![0]!.BookingUserMail = '';
    reservation.ReservationAgents![0]!.BookingUserTel = '';
    reservation.ReservationAgents![0]!.SalesUserMail = '';
    reservation.ReservationAgents![0]!.SalesUserTel = '';

    setReservation({ ...reservation, ReservationAgents: reservation.ReservationAgents! });
    setErrorAttribute({
      ...errorAttribute,
      BookingBranchId: { IsError: false, ErrorMessage: '' },
      SalesBranchId: { IsError: false, ErrorMessage: '' },
      BookingUserMail: { IsError: false, ErrorMessage: '' },
      BookingUserTel: { IsError: false, ErrorMessage: '' },
      SalesUserMail: { IsError: false, ErrorMessage: '' },
      SalesUserTel: { IsError: false, ErrorMessage: '' },
      [event.target.name]: { IsError: false, ErrorMessage: '' },
    });

    // Step2. 支店プルダウンデータ変更
    const _branchList = master.getBranchs(event.target.value) as Branch[];
    if (_branchList) {
      setBranchList(_branchList.filter((x) => x.IsActive));
    } else {
      setBranchList([] as Branch[]);
    }
  };

  /**
   * 代理店情報　手配担当店名更新
   * @param event イベント
   */
  const handleChangeBookingBranch = (event: React.ChangeEvent<HTMLInputElement>) => {
    reservation.ReservationAgents![0]!.BookingBranchId = event.target.value;
    const branch = branchList.filter((x) => x.BranchId == event.target.value)[0];
    if (branch) {
      var bookingUserMail = master.getBranchBookingMailJoinedByComma(branch.CompanyId, branch.BranchId);
      if (!!bookingUserMail) {
        reservation.ReservationAgents![0]!.BookingUserMail = bookingUserMail;
        setErrorAttribute({
          ...errorAttribute,
          BookingUserMail: { IsError: false, ErrorMessage: '' },
        });
      } else {
        reservation.ReservationAgents![0]!.BookingUserMail = '';
      }
      if (!!branch.Tel) {
        reservation.ReservationAgents![0]!.BookingUserTel = branch.Tel;
        setErrorAttribute({
          ...errorAttribute,
          BookingUserTel: { IsError: false, ErrorMessage: '' },
        });
      }
    }

    setReservation({ ...reservation, ReservationAgents: reservation.ReservationAgents! });

    // 値が変更されたらエラー情報をクリア
    setErrorAttribute({ ...errorAttribute, BookingBranchId: { IsError: false, ErrorMessage: '' } });
  };

  /**
   * 代理店情報　営業担当店名更新
   * @param event イベント
   */
  const handleChangeSalesBranch = (event: React.ChangeEvent<HTMLInputElement>) => {
    reservation.ReservationAgents![0]!.SalesBranchId = event.target.value;
    const branch = branchList.filter((x) => x.BranchId == event.target.value)[0];
    if (branch) {
      var salesUserMail = master.getBranchSalesMailJoinedByComma(branch.CompanyId, branch.BranchId);
      if (!!salesUserMail) {
        reservation.ReservationAgents![0]!.SalesUserMail = salesUserMail;
        setErrorAttribute({
          ...errorAttribute,
          SalesUserMail: { IsError: false, ErrorMessage: '' },
        });
      } else {
        reservation.ReservationAgents![0]!.SalesUserMail = '';
      }
      if (!!branch.Tel) {
        reservation.ReservationAgents![0]!.SalesUserTel = branch.Tel;
        setErrorAttribute({
          ...errorAttribute,
          SalesUserTel: { IsError: false, ErrorMessage: '' },
        });
      }
    }
    setReservation({ ...reservation, ReservationAgents: reservation.ReservationAgents! });

    // 値が変更されたらエラー情報をクリア
    setErrorAttribute({ ...errorAttribute, SalesBranchId: { IsError: false, ErrorMessage: '' } });
  };

  /**
   * 代理店情報　代理店情報更新
   * @param event イベント
   */
  const handleChangeAgentInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    (reservation.ReservationAgents![0]![event.target.name as keyof ReservationAgent] as any) = event.target.value;
    setReservation({ ...reservation, ReservationAgents: reservation.ReservationAgents! });

    // 値が変更されたらエラー情報をクリア
    setErrorAttribute({ ...errorAttribute, [event.target.name]: { IsError: false, ErrorMessage: '' } });
  };

  /**
   * 代理店情報（一覧）　相乗追加
   */
  const handleSharedAdd = (): void => {
    Object.keys(agent).forEach((x) => ((agent[x as keyof ReservationAgent] as any) = null));
    setAgent(agent);
    setAgentIndex(-1);
    setAgentMode('NEW');
    setAgentOpen(true);
  };

  /**
   * 代理店情報　編集
   */
  const handleEditAgent = (): void => {
    setAgent({ ...reservation.ReservationAgents![0]! });
    setAgentIndex(0);
    setAgentMode('MODIFY');
    setAgentOpen(true);
  };

  /**
   * 代理店削除処理
   * @param data リクエストパラメータ
   * @param idx 削除するインデックス
   */
  const executeDeleteReservationAgent = async (data: DeleteReservationAgent, idx: number = -1): Promise<void> => {
    const response = (
      (await api.graphql({
        query: deleteReservationAgent,
        variables: { data },
      })) as DeleteReservationAgentMutation
    ).deleteReservationAgent;

    if (response?.IsSuccess) {
      if (idx > -1) {
        reservation.ReservationAgents?.splice(idx, 1);
        setReservation({
          ...reservation,
          ReservationAgents: reservation.ReservationAgents,
          UpdateDatetime: response.Body!,
        });
      } else {
        // 予約削除
        back();
      }
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...errorAttribute,
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
      scrollToTop();
    } else {
      throwError();
    }
  };

  /**
   * 代理店情報（一覧）　削除
   */
  const handleDeleteAgent = async (idx: number): Promise<void> => {
    const data = {
      ReservationId: reservation.ReservationId!,
      CompanyId: reservation.ReservationAgents![idx].CompanyId,
      UpdateDatetime: reservation.UpdateDatetime,
    };
    if (reservation.ReservationAgents!.length > 1) {
      // 相乗予約されている場合、選択した代理店を削除
      setAlertDialogCallback({
        fn: (result: boolean) => {
          if (result) {
            executeDeleteReservationAgent(data, idx);
          }
        },
      });
      setAlertContent(
        '選択した代理店をこの相乗り予約から取り消します。他の予約はそのまま残ります。処理を続行して宜しいですか？'
      );
      setAlertOpen(true);
    } else {
      // 相乗予約されていない場合、メッセージを表示
      setAlertDialogCallback({
        fn: (result: boolean) => {
          if (result) {
            executeDeleteReservationAgent(data);
          }
        },
      });
      setAlertContent('この予約を取り消します。この操作は元に戻せません。予約を取り消しますか？');
      setAlertOpen(true);
    }
  };

  /**
   * 代理店情報（一覧）　詳細
   */
  const handleDetailAgent = (idx: number): void => {
    setAgent({ ...reservation.ReservationAgents![idx]! });
    setAgentIndex(idx);
    setAgentMode('MODIFY');
    setAgentOpen(true);
  };

  /**
   * 民泊受入事業者情報　登録
   */
  const handleRegistProvider = (): void => {
    setServiceProviderMode('REGIST');
    setServiceProviderOpen(true);
  };

  /**
   * 民泊受入事業者情報　編集
   */
  const handleEditProvider = (): void => {
    setServiceProvider({
      ...serviceProvider,
      CompanyId: reservation.CompanyId!,
      CompanyName: reservation.CompanyName!,
      BranchId: reservation.BranchId!,
      BranchName: reservation.BranchName!,
      ManagingUserName: reservation.ManagingUserName!,
      CheckInPlace: reservation.CheckInPlace!,
      CheckOutPlace: reservation.CheckOutPlace!,
      Memo: reservation.Memo!,
    });
    setServiceProviderMode('MODIFY');
    setServiceProviderOpen(true);
  };

  /**
   * 登録
   */
  const handleRegist = async () => {
    const reservationDays = reservation.ReservationDays?.map((x) => {
      return {
        ReservedDay: x?.ReservedDay,
      };
    });

    const data = {
      AreaId: reservation.AreaId,
      PrefectureCode: reservation.PrefectureCode,
      SchoolId: reservation.SchoolId,
      HasAnotherResv: reservation.HasAnotherResv,
      HasGroupingName: reservation.HasGroupingName,
      GroupingName: reservation.GroupingName,
      GuestCount: reservation.GuestCount,
      ReservationDays: reservationDays,
      CompanyId: reservation.ReservationAgents![0]!.CompanyId,
      BookingBranchId: reservation.ReservationAgents![0].BookingBranchId,
      BookingUserName: reservation.ReservationAgents![0].BookingUserName,
      BookingUserTel: reservation.ReservationAgents![0].BookingUserTel,
      BookingUserMail: reservation.ReservationAgents![0].BookingUserMail,
      SalesBranchId: reservation.ReservationAgents![0].SalesBranchId,
      SalesUserName: reservation.ReservationAgents![0].SalesUserName,
      SalesUserTel: reservation.ReservationAgents![0].SalesUserTel,
      SalesUserMail: reservation.ReservationAgents![0].SalesUserMail,
      Note: reservation.ReservationAgents![0].Note,
    } as RegistReservation;

    // API実行
    const response = (
      (await api.graphql({
        query: registReservation,
        variables: { data },
      })) as RegistReservationMutation
    ).registReservation;
    if (response?.IsSuccess) {
      back();
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...errorAttribute,
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
      scrollToTop();
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...errorAttribute,
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
      scrollToTop();
    } else if (response?.IsSystemError) {
      throw response?.ErrorData;
    }
  };

  /**
   * 学校決定申込
   */
  const handleApplySchoolDecision = (): void => {
    setSchoolDecisionApplicationOpen(true);
  };

  /**
   * 学校決定承認処理
   */
  async function executeApproveSchoolDecision() {
    const response = (
      (await api.graphql({
        query: approveSchoolDecision,
        variables: {
          data: {
            ReservationId: reservationFrame.ReservationId,
            UpdateDatetime: reservation.UpdateDatetime,
          },
        },
      })) as ApproveSchoolDecisionMutation
    ).approveSchoolDecision;

    if (response?.IsSuccess) {
      back();
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...errorAttribute,
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
      scrollToTop();
    } else {
      throwError();
    }
  }

  /**
   * 学校決定承認
   */
  const handleApproveSchoolDecision = (): void => {
    executeApproveSchoolDecision();
  };

  /**
   * 予約付替
   */
  const handleTransferReservation = (): void => {
    setTransferReservationOpen(true);
  };

  /**
   * 本予約申込
   */
  const handleApplyReservation = (): void => {
    setApplicationOpen(true);
  };

  /**
   * 本予約申請取消
   */
  const handleCancelApplication = (): void => {
    // 本予約承認差し戻しボタン押下と同じ
    executeBackReservationApproval();
  };

  /**
   * 民家割り振り
   */
  const handleGoToMinkaAllocateLedger = (): void => {
    const urlSearchParams = new URLSearchParams();
    const date = new Date(reservation.CheckInDate?.toString() ?? '');
    const schoolYear = master
      .getSchoolYearSettings()
      .filter((x) => new Date(x.SchoolYear.StartDate) <= date && new Date(x.SchoolYear.EndDate) >= date)[0];
    const period = schoolYear.SchoolYearPeriodList.filter(
      (x) => new Date(x.StartDate) <= date && new Date(x.EndDate) >= date
    )[0];

    urlSearchParams.append('area', reservation.AreaId?.toString() ?? '');
    urlSearchParams.append('year', period.SchoolYearCode);
    urlSearchParams.append('month', period.PeriodCode);
    navigate('/minka/ledger/?' + urlSearchParams.toString());
  };

  /**
   * 生徒割り振り
   */
  const handleGoToStudentLedger = (): void => {
    navigate('/reserve/management/studentledger', {
      state: {
        ReservationId: reservation.ReservationId,
      },
    });
  };

  /**
   * 受入民家メール配信
   */
  const handleGoToAcceptMinkaMail = (): void => {
    navigate('/reserve/management/minkadeliver', {
      state: reservation.ReservationId,
    });
  };

  /**
   * 宿泊生徒管理
   */
  const handleGoToStudentList = (): void => {
    navigate('/reserve/management/studentlist', {
      state: reservation.ReservationId,
    });
  };

  /**
   * 予約取消を行う
   */
  const executeCancelReservation = async (): Promise<void> => {
    const response = (
      (await api.graphql({
        query: cancelReservation,
        variables: {
          data: {
            ReservationId: reservation.ReservationId,
            CompanyId: reservation.ReservationAgents![0].CompanyId,
            UpdateDatetime: reservation.UpdateDatetime,
          } as RegistCancelReservation,
        },
      })) as CancelReservationMutation
    ).cancelReservation;

    if (response?.IsSuccess) {
      window.history.back();
      return;
    } else if (response?.IsSystemError) {
      throwError(response.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  };

  /**
   * 予約取消
   */
  const handleCancelReservation = async (): Promise<void> => {
    if (reservation.ReservationAgents!.length > 1) {
      // 相乗予約されている場合、メッセージを表示
      setAlertDialogCallback({
        fn: (result: boolean) => {
          if (result) {
            setCancelOpen(true);
          }
        },
      });
      setAlertContent(
        'この予約は相乗り予約されています。この操作により、貴社の予約のみ取り消されます。他の代理店の予約は取消されません。予約を取り消しますか？'
      );
      setAlertOpen(true);
      return;
    }

    if (reservation.ApprovalStatusCode === '3') {
      setCancelOpen(true);
    } else {
      setAlertDialogCallback({
        fn: (result: boolean) => {
          if (result) {
            executeCancelReservation();
          }
        },
      });
      setAlertContent(
        '予約を取り消します。相乗り予約されている場合は、貴社の予約のみ取り消されます。予約を取り消しますか？'
      );
      setAlertOpen(true);
      return;
    }
  };

  /**
   * 相乗予約
   */
  const handleSharedReserve = (): void => {
    Object.keys(agent).forEach((x) => ((agent[x as keyof ReservationAgent] as any) = null));
    agent.CompanyId = (user as any).attributes['custom:companyid'];
    agent.BookingBranchId = (user as any).attributes['custom:branchid'];
    setAgent(agent);
    setAgentIndex(-1);
    setAgentMode('SHARE');
    setAgentOpen(true);
  };

  /**
   * 本予約承認
   */
  const handleApproveReservation = (): void => {
    setApprovalOpen(true);
  };

  /**
   * 本予約承認差し戻し処理
   */
  async function executeBackReservationApproval() {
    const response = (
      (await api.graphql({
        query: backReservationApproval,
        variables: {
          data: {
            ReservationId: reservation.ReservationId,
            UpdateDatetime: reservation.UpdateDatetime,
          },
        },
      })) as BackReservationApprovalMutation
    ).backReservationApproval;

    if (response?.IsSuccess) {
      back();
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
      scrollToTop();
    } else {
      throwError();
    }
  }

  /**
   * 本予約承認差し戻し
   */
  const handleBackReservationApproval = (): void => {
    setAlertDialogCallback({
      fn: (result: boolean) => {
        if (result) {
          executeBackReservationApproval();
        }
      },
    });
    setAlertContent('本予約申請を取消します。処理を続行して宜しいですか？');
    setAlertOpen(true);
  };

  /**
   * 閉じる
   */
  const handleClose = (): void => {
    back();
  };

  /**
   * 学校選択　閉じる
   * @param school 学校
   */
  const handleSearchSchoolClose = (school?: MSchools): void => {
    if (school) {
      setSelectSchool(school);
      setReservation({
        ...reservation,
        SchoolName: school.SchoolName,
        SchoolKanaName: school.SchoolKanaName,
        SchoolId: school.SchoolId,
      });
      setErrorAttribute({ ...errorAttribute, SchoolId: { IsError: false, ErrorMessage: '' } });
    }

    setSearchSchoolOpen(false);
  };

  /**
   * 宿泊者情報画面　閉じる
   * @param guestInfo 宿泊者情報
   */
  const handleGuestInfoClose = (guestInfo?: GuestInfo, updateDatetime?: string): void => {
    if (guestInfo) {
      setReservation({
        ...reservation,
        SchoolName: guestInfo?.SchoolName,
        SchoolKanaName: guestInfo.SchoolKanaName,
        HasGroupingName: guestInfo.HasGroupingName,
        GroupingName: guestInfo.GroupingName,
        HasAnotherResv: guestInfo.HasAnotherResv,
        UpdateDatetime: updateDatetime,
      });
    }

    setGuestInfoOpen(false);
  };

  /**
   * 宿泊人数画面　閉じる
   * @param guestCount 宿泊人数
   */
  const handleGuestCountClose = (guestCount?: GuestCount, updateDatetime?: string): void => {
    if (guestCount) {
      setReservation({
        ...reservation,
        GuestCount: guestCount?.GuestCount,
        UpdateDatetime: updateDatetime,
      });
    }

    setGuestCountOpen(false);
  };

  /**
   * 仮予約期限編集画面　閉じる
   * @param reservationDay 予約日程
   */
  const handleDurationDateClose = (reservationDay?: ReservationDay, updateDatetime?: string): void => {
    if (reservationDay) {
      reservationDay.DurationDate = reservationDay.DurationDate + ' 17:59:59';
      const updatedReservationDays = reservation.ReservationDays?.map((rd) =>
        rd.ReservedDay === reservationDay.ReservedDay ? reservationDay : rd
      );

      setReservation({
        ...reservation,
        ReservationDays: updatedReservationDays,
        UpdateDatetime: updateDatetime,
      });
    }

    setDurationDateOpen(false);
  };

  /**
   * 宿泊プラン　閉じる
   * @param service 宿泊プラン
   */
  const handleServiceClose = (service?: Service, updateDatetime?: string): void => {
    if (service) {
      reservation.ServiceId = service.ServiceId;
      reservation.ServiceName = service.ServiceName;
      reservation.Rate = service.Rate;
      reservation.CheckInTime = service.CheckInTime;
      reservation.CheckOutTime = service.CheckOutTime;
      reservation.UpdateDatetime = updateDatetime || reservation.UpdateDatetime;

      setReservation(reservation);
    }

    setServiceMode('');
    setServiceOpen(false);
  };

  /**
   * 宿泊オプション
   * @param optionalService 宿泊オプション
   */
  const handleOptionalServiceClose = (optionalService?: OptionalService, updateDatetime?: string): void => {
    if (optionalService) {
      if (optionalServiceIndex > -1) {
        reservation.OptionalServices![optionalServiceIndex]!.ServiceId = optionalService.ServiceId;
        reservation.OptionalServices![optionalServiceIndex]!.ServiceDisplayName = optionalService.ServiceDisplayName;
        reservation.OptionalServices![optionalServiceIndex]!.Rate = optionalService.Rate;
        reservation.OptionalServices![optionalServiceIndex]!.Count = optionalService.Count;
        reservation.OptionalServices![optionalServiceIndex]!.ProvideDate = optionalService.ProvideDate;
        reservation.OptionalServices![optionalServiceIndex]!.StartTime = optionalService.StartTime;
        reservation.OptionalServices![optionalServiceIndex]!.EndTime = optionalService.EndTime;
        reservation.OptionalServices![optionalServiceIndex]!.Memo = optionalService.Memo;
      } else {
        reservation.OptionalServices?.push(optionalService);
      }

      reservation.UpdateDatetime = updateDatetime;
      setReservation(reservation);
    }

    setOptionalServiceOpen(false);
    setOptionalServiceMode('');
  };

  /**
   * 代理店詳細画面　閉じる
   * @param agent 代理店
   */
  const handleAgentClose = (result?: { updateDatetime: string; agent: ReservationAgent }): void => {
    if (result) {
      if (agentMode === 'SHARE') {
        back();
        return;
      }

      if (agentIndex > -1) {
        reservation.ReservationAgents![agentIndex] = result.agent;
      } else {
        reservation.ReservationAgents!.push(result.agent);
      }

      setReservation({
        ...reservation,
        ReservationAgents: reservation.ReservationAgents,
        UpdateDatetime: result.updateDatetime,
      });
    }

    setAgentMode('');
    setAgentOpen(false);
  };

  /**
   * 予約日程追加画面　閉じる
   * @param result 登録結果
   */
  const handleAddReserveDayClose = (result?: { updateDatetime: string; days: ReservationDay[] }): void => {
    if (result) {
      result.days.forEach((day) => {
        reservation.ReservationDays?.push(day);
      });
      setReservation({
        ...reservation,
        ReservationDays: reservation.ReservationDays,
        UpdateDatetime: result.updateDatetime,
      });
      setErrorAttribute({ ...errorAttribute, ReservationDays: { IsError: false, ErrorMessage: '' } });
    }
    setAddReserveDayOpen(false);
  };

  /**
   * 受入事業者情報画面　閉じる
   * @param serviceProvider 受入事業者情報
   */
  const handleServiceProviderClose = (serviceProvider?: ServiceProvider, updateDatetime?: string): void => {
    if (serviceProvider) {
      reservation.CompanyName = serviceProvider.CompanyName;
      reservation.CheckInPlace = serviceProvider.CheckInPlace;
      reservation.CheckOutPlace = serviceProvider.CheckOutPlace;
      reservation.Memo = serviceProvider.Memo;

      setReservation({
        ...reservation,
        CompanyId: serviceProvider.CompanyId,
        CompanyName: serviceProvider.CompanyName,
        BranchId: serviceProvider.BranchId,
        BranchName: serviceProvider.BranchName,
        ManagingUserName: serviceProvider.ManagingUserName,
        CheckInPlace: serviceProvider.CheckInPlace,
        CheckOutPlace: serviceProvider.CheckOutPlace,
        Memo: serviceProvider.Memo,
        UpdateDatetime: updateDatetime,
      });
    }

    setServiceProviderMode('');
    setServiceProviderOpen(false);
  };

  /**
   * キャンセル理由画面　閉じる
   */
  const handleCancelClose = (): void => {
    setCancelOpen(false);
  };

  /**
   * 警告ダイアログ　閉じる
   * @param result 結果
   */
  const handleAlertClose = (result: boolean): void => {
    alertDialogCallback.fn(result);
    setAlertOpen(false);
  };

  /**
   * 本予約申請画面　閉じる
   * @param updateDatetime 更新日時
   */
  const handleApplicationClose = (updateDatetime?: string): void => {
    setApplicationOpen(false);

    if (updateDatetime) {
      fetchReservation();
      scrollToTop();
    }
  };

  /**
   * 予約付替画面　閉じる
   * @param result 結果
   */
  const handleTransferReservationClose = (result: boolean): void => {
    setTransferReservationOpen(false);

    if (result) {
      back();
    }
  };

  /**
   * 学校決定申請画面　閉じる
   * @param result 結果
   */
  const handleScoolDecisionApplicationClose = (result: boolean): void => {
    setSchoolDecisionApplicationOpen(false);

    if (result) {
      fetchReservation();
      scrollToTop();
    }
  };

  /**
   * 本予約承認画面　閉じる
   * @param updateDatetime 更新日時
   */
  const handleApprovalClose = (updateDatetime?: string): void => {
    setApprovalOpen(false);

    if (updateDatetime) {
      back();
    }
  };

  /**
   * セッション制限メッセージ画面　閉じる
   */
  const handleSessionInvalidMessageClose = (): void => {
    setSessionInvalidMessageOpen(false);
  };

  return {
    reservation,
    companyList,
    branchList,
    copyReservationId,
    searchSchoolOpen,
    selectSchool,
    guestInfoOpen,
    guestInfo,
    guestCountOpen,
    guestCount,
    durationDateOpen,
    reservationDay,
    serviceOpen,
    service,
    serviceMode,
    addDayInitialValue,
    optionalServiceOpen,
    optionalService,
    optionalServiceMode,
    agentOpen,
    agent,
    agentMode,
    serviceProviderOpen,
    serviceProvider,
    serviceProviderMode,
    cancelOpen,
    alertOpen,
    alertContent,
    applicationOpen,
    schoolDecisionApplicationOpen,
    transferReservationOpen,
    sessionInvalidMessageOpen,
    approvalOpen,
    areaServiceRateOpen,
    addReserveDayOpen,
    url,
    isLedgerOpen,
    hasControl,
    canAllDept,
    isValidSession,
    copyReservationOpen,
    includeReservationDate,
    handleReload,
    handleCopyReservation,
    handleSearchSchoolOpen,
    handleChangeHasGroupingName,
    handleEditGuestInfo,
    handleChangeHasAnotherResv,
    handleChangeInput,
    handleChangeGuestCount,
    handleChangeCopyReservationId,
    handleEditGuestCount,
    handleChangeCompany,
    handleChangeBookingBranch,
    handleChangeSalesBranch,
    handleChangeAgentInput,
    handleAddDays,
    handleDeleteDays,
    handleChangeDays,
    handleExtendTerm,
    handleEditDurationDate,
    handleEditService,
    handleAddOption,
    handleDeleteOption,
    handleEditOption,
    handleSharedAdd,
    handleEditAgent,
    handleDeleteAgent,
    handleDetailAgent,
    handleRegistProvider,
    handleEditProvider,
    handleRegist,
    handleApplySchoolDecision,
    handleTransferReservation,
    handleApproveSchoolDecision,
    handleApplyReservation,
    handleCancelApplication,
    handleGoToMinkaAllocateLedger,
    handleGoToStudentLedger,
    handleGoToStudentList,
    handleCancelReservation,
    handleSharedReserve,
    handleApproveReservation,
    handleBackReservationApproval,
    handleClose,
    handleSearchSchoolClose,
    handleGuestInfoClose,
    handleGuestCountClose,
    handleDurationDateClose,
    handleServiceClose,
    handleOptionalServiceClose,
    handleAgentClose,
    handleServiceProviderClose,
    handleCancelClose,
    handleAlertClose,
    handleApplicationClose,
    handleScoolDecisionApplicationClose,
    handleTransferReservationClose,
    handleApprovalClose,
    handleGoToAcceptMinkaMail,
    handleAreaServiceRateOpen,
    handleAddReserveDayClose,
    handleSessionInvalidMessageClose,
    handleCopyReservationOpen,
    handleChangeIncludeReservationDate,
    errorAttribute,
  };
};
export default UseReserveRegist;
