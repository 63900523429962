import { FC } from 'react';
import { GuestInfo } from 'types/GuestInfo';
import GuestInfoDialog from './GuestInfoDialog';
import UseGuestInfoDialog from './UseGuestInfoDialog';

export interface Props {
  handleDialogClose: (_?: GuestInfo) => void;
  dialogOpen: boolean;
  guestInfo: GuestInfo;
  reservationId: string;
  updateDatetime: string;
}

const GuestInfoDialogContainer: FC<Props> = (props: Props) => {
  return <GuestInfoDialog {...UseGuestInfoDialog(props)} />;
};

export default GuestInfoDialogContainer;
