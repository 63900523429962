import { FC } from 'react';
import GenericTemplate from '../../../template/GenericTemplate';
import React from 'react';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import Grid from '@mui/material/Grid/Grid';
import SelectFormControl from '../../../parts/SelectFormControl';
import { SelectValue } from '../../../../types/SelectValue';
import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField/TextField';
import FormControl from '@mui/material/FormControl/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { Branch, Prefecture } from '../../../../API';
import Radio from '@mui/material/Radio/Radio';
import RadioGroup from '@mui/material/RadioGroup/RadioGroup';
import { ErrorData } from './BranchInfoErrorData';
import BackToTopButton from 'views/parts/BackToTop';
import { useMaster } from '../../../../contexts/CommonMasterContext';
import { UserCategoryCode } from 'constant/Constant';

const styles = {
  searchField: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
  },

  label: {
    margin: 'auto',
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
  },

  categoryTitle: {
    pl: '10px',
    mt: '10px',
    mb: '10px',

    borderLeft: '5px solid #0058a0',
  },

  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
    '& > div > input': {
      maxHeight: '10px',
    },
  },

  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },

  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    ml: 1,
    float: 'right',
    mt: 1,
  },

  textField: {
    width: '100%',
    '& input': {
      padding: '8px 14px',
    },
  },
};

export interface Props {
  isUpdate: boolean;
  prefectureList: Prefecture[];
  companyBranchData: Branch;
  errorAttribute: ErrorData;
  alertOpen: boolean;
  alertContent: string;
  handleChangePrefectureCode: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeDetail: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickRegist: () => void;
  handleAlertClose: (_: boolean) => void;
}

const BranchInfo: FC<Props> = (props) => {
  const pushback = function () {
    window.history.back();
  };
  const master = useMaster();
  let isAgent =
    master.getCompanies().filter((x) => x.CompanyId == props.companyBranchData.CompanyId)[0]?.CategoryCode ===
    UserCategoryCode.Agent;

  return (
    <GenericTemplate title="支店・営業店詳細">
      <div>
        <BackToTopButton />
      </div>
      <Container sx={styles.searchField}>
        {props.errorAttribute.Header.ErrorMessage && (
          <Grid container spacing={2} sx={{ pl: 0, pb: 2 }}>
            <Grid item xs={12}>
              <FormControl component="fieldset" error={props.errorAttribute.Header.IsError}>
                <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                  {props.errorAttribute.Header.ErrorMessage}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        )}
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          支店・営業店情報
        </Typography>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>名称</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'BranchName'}
              value={props.companyBranchData.BranchName}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              inputProps={{ maxLength: 40 }}
              error={props.errorAttribute.BranchName.IsError}
              helperText={props.errorAttribute.BranchName.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>都道府県</Typography>
          </Grid>
          <Grid item xs={7}>
            <SelectFormControl
              value={props.companyBranchData.PrefectureCode ?? ''}
              selectName="PrefectureCode"
              selectValues={props.prefectureList.map((prefecture, idx) => {
                return { value: prefecture.PrefectureCode, displayName: prefecture.PrefectureName } as SelectValue;
              })}
              setCondition={props.handleChangePrefectureCode}
              sx={styles.inputItem}
              error={props.errorAttribute.PrefectureCode.IsError}
              errorMessage={props.errorAttribute.PrefectureCode.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>所在地</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'Address'}
              value={props.companyBranchData.Address}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              inputProps={{ maxLength: 40 }}
              error={props.errorAttribute.Address.IsError}
              helperText={props.errorAttribute.Address.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>郵便番号</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'PostalCode'}
              value={props.companyBranchData.PostalCode}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              inputProps={{ maxLength: 7 }}
              error={props.errorAttribute.PostalCode.IsError}
              helperText={props.errorAttribute.PostalCode.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>電話番号</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'Tel'}
              value={props.companyBranchData.Tel}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              inputProps={{ maxLength: 13 }}
              error={props.errorAttribute.Tel.IsError}
              helperText={props.errorAttribute.Tel.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>ファックス</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'Fax'}
              value={props.companyBranchData.Fax}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              inputProps={{ maxLength: 12 }}
              error={props.errorAttribute.Fax.IsError}
              helperText={props.errorAttribute.Fax.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>有効区分</Typography>
          </Grid>
          <Grid item xs={7}>
            <RadioGroup
              row
              name="IsActive"
              aria-label="position"
              value={props.companyBranchData.IsActive ?? '1'}
              onChange={props.handleChangeDetail}
            >
              <FormControlLabel value={1} control={<Radio color="primary" />} label="有効" />
              <FormControlLabel value={0} control={<Radio color="primary" />} label="無効" />
            </RadioGroup>
          </Grid>
        </Grid>

        {isAgent ? (
          <>
            <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
              各種予約通知宛先：手配担当
            </Typography>
            <Grid container spacing={0.5} sx={styles.categoryBlock}>
              <Grid item xs={3}>
                <Typography sx={styles.label}>メール１</Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  name={'Mail1'}
                  value={props.companyBranchData.Mail1}
                  onChange={props.handleChangeDetail}
                  sx={styles.textField}
                  inputProps={{ maxLength: 255 }}
                  error={props.errorAttribute.Mail1.IsError}
                  helperText={props.errorAttribute.Mail1.ErrorMessage}
                  placeholder="ここに指定したメールアドレスが予約登録時の手配担当メール宛先として自動入力されます。"
                />
              </Grid>
              <Grid item xs={3}>
                <Typography sx={styles.label}>メール２</Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  name={'Mail2'}
                  value={props.companyBranchData.Mail2}
                  onChange={props.handleChangeDetail}
                  sx={styles.textField}
                  inputProps={{ maxLength: 255 }}
                  error={props.errorAttribute.Mail2.IsError}
                  helperText={props.errorAttribute.Mail2.ErrorMessage}
                  placeholder="ここに指定したメールアドレスが予約登録時の手配担当メール宛先として自動入力されます。"
                />
              </Grid>
              <Grid item xs={3}>
                <Typography sx={styles.label}>メール３</Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  name={'Mail3'}
                  value={props.companyBranchData.Mail3}
                  onChange={props.handleChangeDetail}
                  sx={styles.textField}
                  inputProps={{ maxLength: 255 }}
                  error={props.errorAttribute.Mail3.IsError}
                  helperText={props.errorAttribute.Mail3.ErrorMessage}
                  placeholder="ここに指定したメールアドレスが予約登録時の手配担当メール宛先として自動入力されます。"
                />
              </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
              各種予約通知宛先：営業担当
            </Typography>
            <Grid container spacing={0.5} sx={styles.categoryBlock}>
              <Grid item xs={3}>
                <Typography sx={styles.label}>メール１</Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  name={'Mail4'}
                  value={props.companyBranchData.Mail4}
                  onChange={props.handleChangeDetail}
                  sx={styles.textField}
                  inputProps={{ maxLength: 255 }}
                  error={props.errorAttribute.Mail4.IsError}
                  helperText={props.errorAttribute.Mail4.ErrorMessage}
                  placeholder="ここに指定したメールアドレスが予約登録時の営業担当メール宛先として自動入力されます。"
                />
              </Grid>
              <Grid item xs={3}>
                <Typography sx={styles.label}>メール２</Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  name={'Mail5'}
                  value={props.companyBranchData.Mail5}
                  onChange={props.handleChangeDetail}
                  sx={styles.textField}
                  inputProps={{ maxLength: 255 }}
                  error={props.errorAttribute.Mail5.IsError}
                  helperText={props.errorAttribute.Mail5.ErrorMessage}
                  placeholder="ここに指定したメールアドレスが予約登録時の営業担当メール宛先として自動入力されます。"
                />
              </Grid>
              <Grid item xs={3}>
                <Typography sx={styles.label}>メール３</Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  name={'Mail6'}
                  value={props.companyBranchData.Mail6}
                  onChange={props.handleChangeDetail}
                  sx={styles.textField}
                  inputProps={{ maxLength: 255 }}
                  error={props.errorAttribute.Mail6.IsError}
                  helperText={props.errorAttribute.Mail6.ErrorMessage}
                  placeholder="ここに指定したメールアドレスが予約登録時の営業担当メール宛先として自動入力されます。"
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
              各種予約通知宛先
            </Typography>
            <Grid container spacing={0.5} sx={styles.categoryBlock}>
              <Grid item xs={3}>
                <Typography sx={styles.label}>メール１</Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  name={'Mail1'}
                  value={props.companyBranchData.Mail1}
                  onChange={props.handleChangeDetail}
                  sx={styles.textField}
                  inputProps={{ maxLength: 255 }}
                  error={props.errorAttribute.Mail1.IsError}
                  helperText={props.errorAttribute.Mail1.ErrorMessage}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography sx={styles.label}>メール２</Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  name={'Mail2'}
                  value={props.companyBranchData.Mail2}
                  onChange={props.handleChangeDetail}
                  sx={styles.textField}
                  inputProps={{ maxLength: 255 }}
                  error={props.errorAttribute.Mail2.IsError}
                  helperText={props.errorAttribute.Mail2.ErrorMessage}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography sx={styles.label}>メール３</Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  name={'Mail3'}
                  value={props.companyBranchData.Mail3}
                  onChange={props.handleChangeDetail}
                  sx={styles.textField}
                  inputProps={{ maxLength: 255 }}
                  error={props.errorAttribute.Mail3.IsError}
                  helperText={props.errorAttribute.Mail3.ErrorMessage}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography sx={styles.label}>メール４</Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  name={'Mail4'}
                  value={props.companyBranchData.Mail4}
                  onChange={props.handleChangeDetail}
                  sx={styles.textField}
                  inputProps={{ maxLength: 255 }}
                  error={props.errorAttribute.Mail4.IsError}
                  helperText={props.errorAttribute.Mail4.ErrorMessage}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography sx={styles.label}>メール５</Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  name={'Mail5'}
                  value={props.companyBranchData.Mail5}
                  onChange={props.handleChangeDetail}
                  sx={styles.textField}
                  inputProps={{ maxLength: 255 }}
                  error={props.errorAttribute.Mail5.IsError}
                  helperText={props.errorAttribute.Mail5.ErrorMessage}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography sx={styles.label}>メール６</Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  name={'Mail6'}
                  value={props.companyBranchData.Mail6}
                  onChange={props.handleChangeDetail}
                  sx={styles.textField}
                  inputProps={{ maxLength: 255 }}
                  error={props.errorAttribute.Mail6.IsError}
                  helperText={props.errorAttribute.Mail6.ErrorMessage}
                />
              </Grid>
            </Grid>
          </>
        )}

        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={10}>
            <Button variant="contained" sx={styles.button} onClick={pushback}>
              閉じる
            </Button>
            <Button variant="contained" sx={styles.button} onClick={props.handleClickRegist}>
              登録
            </Button>
          </Grid>
        </Grid>
      </Container>
    </GenericTemplate>
  );
};

export default BranchInfo;
