import { Container } from '@mui/material';
import Button from '@mui/material/Button/Button';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import { StudentAllocateData, CodeMaster } from 'API';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import GenericTemplate from '../../../template/GenericTemplate';

const styles = {
  searchField: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
  },

  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
    mb: '20px',
  },

  returnButton: {
    width: 120,
    fontWeight: 'bold',
    float: 'left',
    mb: 1,
  },

  listButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    m: 0,
  },

  deleteCell: {
    padding: '6px',
    '& > a': {
      textDecoration: 'none',
    },
  },
};

export interface Props {
  resultManagementStudentLedgerDate: StudentAllocateData[];
  acceptGenderList: CodeMaster[];
  sexList: CodeMaster[];
  handleClickStudentAllocate: (_: number) => void;
  handleClickMinkachange: (_: number) => void;
  handleClickBack: () => void;
}

const StudentAllocateLedger: FC<Props> = ({
  resultManagementStudentLedgerDate,
  acceptGenderList,
  sexList,
  handleClickStudentAllocate,
  handleClickMinkachange,
  handleClickBack,
}) => {
  return (
    <GenericTemplate title="生徒割台帳">
      <Container sx={styles.searchField}>
        <Button variant="contained" sx={styles.returnButton} onClick={handleClickBack}>
          戻る
        </Button>
        <TableContainer sx={{ minHeight: 300 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{ textAlign: 'center' }}></TableCell>
                <TableCell style={{ textAlign: 'center' }}></TableCell>
                <TableCell style={{ maxWidth: 10, textAlign: 'center' }}>組</TableCell>
                <TableCell style={{ maxWidth: 10, textAlign: 'center' }}>班</TableCell>
                <TableCell style={{ maxWidth: 50, textAlign: 'center' }}>民家No.</TableCell>
                <TableCell style={{ maxWidth: 70, textAlign: 'center' }}>民家名</TableCell>
                <TableCell style={{ maxWidth: 10, textAlign: 'center' }}>受入性別</TableCell>
                <TableCell style={{ maxWidth: 10, textAlign: 'center' }}>基本人数</TableCell>
                <TableCell style={{ maxWidth: 10, textAlign: 'center' }}>最大人数</TableCell>
                <TableCell style={{ textAlign: 'center' }}>生徒名</TableCell>
                <TableCell style={{ textAlign: 'center' }}>番号</TableCell>
                <TableCell style={{ textAlign: 'center' }}>性別</TableCell>
                <TableCell style={{ textAlign: 'center' }}>アレルギー</TableCell>
                <TableCell style={{ textAlign: 'center' }}>持病</TableCell>
                <TableCell style={{ textAlign: 'center' }}>不安事項</TableCell>
                <TableCell style={{ textAlign: 'center' }}>注意事項</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resultManagementStudentLedgerDate.map((date, index) => (
                <TableRow key={index}>
                  <TableCell component="td" scope="row" sx={styles.deleteCell}>
                    <Button
                      variant="contained"
                      sx={styles.listButton}
                      size="small"
                      onClick={() => handleClickStudentAllocate(index)}
                    >
                      生徒割
                    </Button>
                  </TableCell>
                  <TableCell component="td" scope="row" sx={styles.deleteCell}>
                    <Button
                      variant="contained"
                      sx={styles.listButton}
                      size="small"
                      onClick={() => handleClickMinkachange(index)}
                    >
                      民家変更
                    </Button>
                  </TableCell>
                  <TableCell style={{ maxWidth: 10 }} component="td" scope="row">
                    {date.ClassCode}
                  </TableCell>
                  <TableCell style={{ maxWidth: 10 }} component="td" scope="row">
                    {date.GroupCode}
                  </TableCell>
                  <TableCell style={{ maxWidth: 50 }} component="td" scope="row">
                    {date.MinkaId}
                  </TableCell>
                  <TableCell style={{ maxWidth: 70 }} component="td" scope="row">
                    {date.MinkaName}
                  </TableCell>
                  <TableCell style={{ maxWidth: 10 }} component="td" scope="row">
                    {acceptGenderList.filter((x) => x.Code === date.AcceptanceGender).map((x) => x.Name)}
                  </TableCell>
                  <TableCell style={{ maxWidth: 10 }} component="td" scope="row">
                    {date.GuestCount}
                  </TableCell>
                  <TableCell style={{ maxWidth: 10 }} component="td" scope="row">
                    {date.MaxGuestCount}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {date.StudentList
                      ? date.StudentList.map((student, index) => (
                          <TableRow>
                            <TableCell>{student.StudentName}</TableCell>
                          </TableRow>
                        ))
                      : ''}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {date.StudentList
                      ? date.StudentList.map((student, index) => (
                          <TableRow>
                            <TableCell>{student.StudentNo}</TableCell>
                          </TableRow>
                        ))
                      : ''}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {date.StudentList
                      ? date.StudentList.map((student, index) => (
                          <TableRow>
                            <TableCell>{sexList.filter((x) => x.Code === student.Sex).map((x) => x.Name)}</TableCell>
                          </TableRow>
                        ))
                      : ''}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {date.StudentList
                      ? date.StudentList.map((student, index) => (
                          <TableRow>
                            <TableCell>{student.Allergy}</TableCell>
                          </TableRow>
                        ))
                      : ''}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {date.StudentList
                      ? date.StudentList.map((student, index) => (
                          <TableRow>
                            <TableCell>{student.Disease}</TableCell>
                          </TableRow>
                        ))
                      : ''}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {date.StudentList
                      ? date.StudentList.map((student, index) => (
                          <TableRow>
                            <TableCell>{student.Consern}</TableCell>
                          </TableRow>
                        ))
                      : ''}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {date.StudentList
                      ? date.StudentList.map((student, index) => (
                          <TableRow>
                            <TableCell>{student.Notice}</TableCell>
                          </TableRow>
                        ))
                      : ''}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </GenericTemplate>
  );
};

export default StudentAllocateLedger;
