import { Container } from '@mui/material';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography/Typography';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Area, Prefecture, SchoolYear, SchoolYearPeriod } from '../../../API';
import { SelectValue } from '../../../types/SelectValue';
import SelectFormControl from '../../parts/SelectFormControl';
import GenericTemplate from '../../template/GenericTemplate';

const styles = {
  searchField: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
  },

  label: {
    margin: 'auto',
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
  },

  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
  },

  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
    '& > div > input': {
      maxHeight: '10px',
    },
  },

  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 1,
  },

  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },
};

export interface Props {
  prefecture: string;
  area: string;
  year: string;
  month: string;
  prefectureList: Prefecture[];
  areaList: Area[];
  yearList: SchoolYear[];
  monthList: SchoolYearPeriod[];
  handleChangePrefecture: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeArea: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeYear: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeMonth: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickSearch: () => void;
}

const MinkaAllocateLedgerSearch: FC<Props> = ({
  prefecture,
  area,
  year,
  month,
  prefectureList,
  areaList,
  yearList,
  monthList,
  handleChangePrefecture,
  handleChangeArea,
  handleChangeYear,
  handleChangeMonth,
  handleClickSearch,
}) => {
  return (
    <GenericTemplate title="民家割検索">
      <Container sx={styles.searchField}>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          検索条件
        </Typography>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>都道府県</Typography>
          </Grid>
          <Grid item xs={7}>
            <SelectFormControl
              value={prefecture}
              selectName="prefecture"
              selectValues={prefectureList.map((prefectures, idx) => {
                return { value: prefectures.PrefectureCode, displayName: prefectures.PrefectureName } as SelectValue;
              })}
              setCondition={handleChangePrefecture}
              sx={styles.inputItem}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>エリア</Typography>
          </Grid>
          <Grid item xs={7}>
            <SelectFormControl
              value={area}
              selectName="area"
              selectValues={areaList
                .filter((x) => x.PrefectureCode === prefecture)
                .map((areas, idx) => {
                  return { value: areas.AreaId, displayName: areas.AreaName } as SelectValue;
                })}
              setCondition={handleChangeArea}
              sx={styles.inputItem}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>年度</Typography>
          </Grid>
          <Grid item xs={7}>
            <SelectFormControl
              value={year ?? ''}
              selectName="Year"
              selectValues={yearList.map((x) => {
                return { value: x.SchoolYearCode, displayName: x.SchoolYearCode } as SelectValue;
              })}
              setCondition={handleChangeYear}
              sx={styles.inputItem}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>月</Typography>
          </Grid>
          <Grid item xs={7}>
            <SelectFormControl
              value={month ?? ''}
              selectName="Month"
              selectValues={monthList
                .filter((x) => x.SchoolYearCode === year)
                .map((x) => {
                  return { value: x.PeriodCode, displayName: x.Month } as SelectValue;
                })}
              setCondition={handleChangeMonth}
              sx={styles.inputItem}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'center', '& > a': { textDecoration: 'none' } }}>
          <Button variant="contained" sx={styles.button} onClick={handleClickSearch}>
            検索
          </Button>
        </Box>
      </Container>
    </GenericTemplate>
  );
};

export default MinkaAllocateLedgerSearch;
