import { Service } from 'API';
import { FC } from 'react';
import PlanDialog from './PlanDialog';
import UsePlanDialog from './UsePlanDialog';

export interface Props {
  handleDialogClose: (_?: Service) => void;
  dialogOpen: boolean;
  reservationId: string;
  service: Service;
  mode: string;
  updateDatetime: string;
}

const PlanDialogContainer: FC<Props> = (props: Props) => {
  return <PlanDialog {...UsePlanDialog(props)} />;
};

export default PlanDialogContainer;
