import { Container } from '@mui/material';
import Button from '@mui/material/Button/Button';
import FormControl from '@mui/material/FormControl/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import Grid from '@mui/material/Grid/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography/Typography';
import { AreaManagerRequest } from 'API';
import React, { FC } from 'react';
import GenericTemplate from '../../../template/GenericTemplate';
import { ErrorData } from './AreaManagerSelectErrorData';
import FormHelperText from '@mui/material/FormHelperText';
import { Branch, CompanyBranch } from 'API';
import { SelectValue } from 'types/SelectValue';
import SelectFormControl from 'views/parts/SelectFormControl';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import jaLocale from 'date-fns/locale/ja';
import BackToTopButton from 'views/parts/BackToTop';

const styles = {
  searchField: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
    paddingLeft: '100px',
  },

  label: {
    margin: 'auto',
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
    mr: '10px',
  },

  radioLabel: {
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
    mr: '10px',
  },

  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
  },

  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 3,
    mr: 1,
    float: 'right',
  },

  dataBlock: {
    '& > div': {
      width: '100%',
    },
  },

  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },

  textField: {
    width: '100%',
    '& input': {
      padding: '8px',
    },
  },

  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
    '& > div > input': {
      maxHeight: '10px',
    },
  },
};

export interface Props {
  areaManagerData: AreaManagerRequest;
  isUpdate: boolean;
  errorAttribute: ErrorData;
  companyList: CompanyBranch[];
  branchList: Branch[];
  handleChangeDetail: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickRegist: () => void;
  handleChangeCompany: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeFromDate: (_: Date) => void;
  handleChangeToDate: (_: Date) => void;
}

const AreaManagerSelect: FC<Props> = (props) => {
  const pushback = function () {
    window.history.back();
  };

  return (
    <GenericTemplate>
      <div>
        <BackToTopButton />
      </div>
      <Container sx={styles.searchField}>
        {props.errorAttribute.Header.ErrorMessage && (
          <Grid container spacing={2} sx={{ pl: 0, pb: 2 }}>
            <Grid item xs={12}>
              <FormControl component="fieldset" error={props.errorAttribute.Header.IsError}>
                <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                  {props.errorAttribute.Header.ErrorMessage}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        )}

        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
            地区管理事業者
          </Typography>
          <Grid container spacing={0.5} sx={styles.categoryBlock}>
            <Grid item xs={3}>
              <Typography sx={styles.label}>事業者</Typography>
            </Grid>
            <Grid item xs={9}>
              <SelectFormControl
                value={props.areaManagerData.CompanyId ?? ''}
                selectName="CompanyId"
                selectValues={props.companyList
                  .filter((x) => x.CategoryCode === '01')
                  .map((company, idx) => {
                    return { value: company.CompanyId, displayName: company.CompanyName } as SelectValue;
                  })}
                setCondition={props.handleChangeCompany}
                sx={styles.inputItem}
                isDisabled={props.isUpdate}
                error={props.errorAttribute.CompanyId.IsError}
                errorMessage={props.errorAttribute.CompanyId.ErrorMessage}
              />
            </Grid>
          </Grid>

          <Grid container spacing={0.5} sx={styles.categoryBlock}>
            <Grid item xs={3}>
              <Typography sx={styles.label}>営業店</Typography>
            </Grid>
            <Grid item xs={9}>
              <SelectFormControl
                value={props.areaManagerData.BranchId ?? ''}
                selectName="BranchId"
                selectValues={props.branchList.map((branch, idx) => {
                  return { value: branch.BranchId, displayName: branch.BranchName } as SelectValue;
                })}
                setCondition={props.handleChangeDetail}
                sx={styles.inputItem}
                isDisabled={props.isUpdate}
                error={props.errorAttribute.BranchId.IsError}
                errorMessage={props.errorAttribute.BranchId.ErrorMessage}
              />
            </Grid>
          </Grid>

          <Grid container spacing={0.5} sx={styles.categoryBlock}>
            <Grid item xs={3}>
              <Typography sx={styles.label}>開始日</Typography>
            </Grid>
            <Grid item xs={9}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
                <DesktopDatePicker
                  label="yyyy/mm/dd"
                  mask="____/__/__"
                  value={new Date(props.areaManagerData.FromDate ?? '')}
                  onChange={(newValue) => props.handleChangeFromDate(new Date(newValue ?? ''))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      style={{ width: '100%' }}
                      error={props.errorAttribute.FromDate.IsError}
                      helperText={props.errorAttribute.FromDate.ErrorMessage}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>

          <Grid container spacing={0.5} sx={styles.categoryBlock}>
            <Grid item xs={3}>
              <Typography sx={styles.label}>終了日</Typography>
            </Grid>
            <Grid item xs={9}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
                <DesktopDatePicker
                  label="yyyy/mm/dd"
                  mask="____/__/__"
                  value={new Date(props.areaManagerData.ToDate ?? '')}
                  onChange={(newValue) => props.handleChangeToDate(new Date(newValue ?? ''))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      style={{ width: '100%' }}
                      error={props.errorAttribute.ToDate.IsError}
                      helperText={props.errorAttribute.ToDate.ErrorMessage}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>{' '}
          </Grid>

          <Grid container spacing={0.5} sx={styles.categoryBlock}>
            <Grid item xs={3}>
              <Typography sx={styles.label}>予約窓口</Typography>
            </Grid>
            <Grid item xs={9}>
              <RadioGroup
                row
                name="IsRepresentativ"
                aria-label="position"
                value={props.areaManagerData.IsRepresentativ === '1' ? 1 : 0}
                onChange={props.handleChangeDetail}
              >
                <FormControlLabel value={1} control={<Radio color="primary" />} label="はい" />
                <FormControlLabel value={0} control={<Radio color="primary" />} label="いいえ" />
              </RadioGroup>
            </Grid>
          </Grid>
        </Grid>
        <Button variant="contained" sx={styles.button} onClick={pushback}>
          閉じる
        </Button>
        <Button variant="contained" sx={styles.button} onClick={props.handleClickRegist}>
          登録
        </Button>
      </Container>
    </GenericTemplate>
  );
};
export default AreaManagerSelect;
