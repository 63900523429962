import React, { useEffect, useState, useCallback } from 'react';
import { registAreaManager, updateAreaManager } from 'graphql/mutations';
import { useLocation } from 'react-router';
import { UpdateAreaManagerMutation, RegistAreaManagerMutation, AreaManagerRequest, CompanyBranch, Branch } from 'API';
import { CreateEmptyErrorData, ErrorData } from './AreaManagerSelectErrorData';
import { useAPI } from 'contexts/APIRequestContext';
import { useMaster } from 'contexts/CommonMasterContext';
import { useNavigate } from 'react-router-dom';
import { checkAuthority } from 'util/AuthorityCheck';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';

const UseAreaManagerSelect = () => {
  const api = useAPI();
  const queryString = require('query-string');
  const location = useLocation();
  const areaManagerPara = location.state as AreaManagerRequest;
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [areaManagerData, setAreaManagerData] = useState({} as AreaManagerRequest);
  const [, setError] = useState<undefined>();
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);
  var isUpdate = queryString.parse(location.search).mode === 'update';
  const [companyList, setCompanyList] = useState([] as CompanyBranch[]);
  const master = useMaster();
  const [branchList, setBranchList] = useState([] as Branch[]);
  const navigator = useNavigate();
  const { user } = useAuthenticator();
  const userData = new UserData(user);

  /**
   * ブラウザバック
   */
  const back = (): void => {
    window.history.back();
  };

  const handleChangeDetail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    setAreaManagerData({ ...areaManagerData, [name]: value });
  };

  const handleClickRegist = () => {
    if (isUpdate) {
      update();
    } else {
      regist();
    }
  };

  async function regist() {
    const response = (
      (await api.graphql({
        query: registAreaManager,
        variables: { data: areaManagerData },
      })) as RegistAreaManagerMutation
    ).registAreaManager;

    if (response?.IsSuccess) {
      back();
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  async function update() {
    const response = (
      (await api.graphql({
        query: updateAreaManager,
        variables: { data: areaManagerData },
      })) as UpdateAreaManagerMutation
    ).updateAreaManager;

    if (response?.IsSuccess) {
      back();
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  const handleChangeCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Step1. 会社ＩＤ入力値変更と支店ＩＤクリア
    setAreaManagerData({ ...areaManagerData, CompanyId: event.target.value, BranchId: '' });

    // Step2. 支店プルダウンデータ変更
    const _branchList = master.getBranchs(event.target.value) as Branch[];
    if (_branchList) {
      setBranchList(_branchList);
    } else {
      setBranchList([] as Branch[]);
    }
  };

  const handleChangeFromDate = (value: Date) => {
    setAreaManagerData({ ...areaManagerData, FromDate: value.toLocaleDateString() });
  };

  const handleChangeToDate = (value: Date) => {
    setAreaManagerData({ ...areaManagerData, ToDate: value.toLocaleDateString() });
  };

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('AreaManagementSelect', userData.authorityCode)) {
      navigator('/');
    }

    master.waitForInitialized(() => {
      setCompanyList(master.getCompanies());
    });
    if (isUpdate) {
      const _branchList = master.getBranchs(areaManagerPara.CompanyId ?? '') as Branch[];
      if (_branchList) {
        setBranchList(_branchList);
      } else {
        setBranchList([] as Branch[]);
      }
      setAreaManagerData(areaManagerPara as AreaManagerRequest);
    } else {
      // 新規登録の場合、初期値のある値を予めセットする
      setAreaManagerData({
        AreaId: areaManagerPara.AreaId,
        IsRepresentativ: '1',
        FromDate: '1900/01/01',
        ToDate: '2099/12/31',
      } as unknown as AreaManagerRequest);
    }
  }, []);

  return {
    areaManagerData,
    isUpdate,
    errorAttribute,
    companyList,
    branchList,
    handleChangeDetail,
    handleClickRegist,
    handleChangeCompany,
    handleChangeFromDate,
    handleChangeToDate,
  };
};

export default UseAreaManagerSelect;
