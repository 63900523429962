import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Service } from 'API';
import { FC } from 'react';
import { ErrorData } from './PlanDialogErrorData';
import { SelectValue } from 'types/SelectValue';
import SelectFormControl from 'views/parts/SelectFormControl';

export interface Props {
  inputData: Service;
  services: Service[];
  handleChangeInput: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeService: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleRegist: () => void;
  handleClose: () => void;
  dialogOpen: boolean;
  errorAttribute: ErrorData;
}

const styles = {
  categoryHeader: {
    fontWeight: 'bold',
  },
  itemHeader: {
    backgroundColor: '#ececec',
    fontWeight: 'bold',
  },
  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
  },
  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 1,
    ml: 1,
  },
  label: {
    mt: '7px',
  },
  text: {
    mt: '7px',
    wordWrap: 'break-word',
  },
  categoryTitle: {
    borderLeft: 5,
    borderColor: '#0058a0',
    pl: '10px',
  },
  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },
  listButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    m: 0,
  },
};

const PlanDialog: FC<Props> = ({
  inputData,
  services,
  handleChangeInput,
  handleChangeService,
  handleRegist,
  handleClose,
  dialogOpen,
  errorAttribute,
}) => {
  return (
    <Dialog open={dialogOpen} onClose={handleClose} maxWidth={'md'}>
      <DialogTitle>宿泊プラン</DialogTitle>
      <DialogContent>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          宿泊プラン
        </Typography>
        <Grid container spacing={2} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>プラン</Typography>
          </Grid>
          <Grid item xs={9}>
            <SelectFormControl
              value={inputData.ServiceId ?? ''}
              selectName="ServiceId"
              selectValues={services.map((x) => {
                return { value: x.ServiceId, displayName: x.ServiceDisplayName } as SelectValue;
              })}
              setCondition={handleChangeService}
              sx={styles.inputItem}
              error={errorAttribute.ServiceId.IsError}
              errorMessage={errorAttribute.ServiceId.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>単価 *</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              required
              hiddenLabel
              name="Rate"
              value={inputData.Rate ?? ''}
              onChange={handleChangeInput}
              size="small"
              sx={styles.inputItem}
              error={errorAttribute.Rate.IsError}
              helperText={errorAttribute.Rate.ErrorMessage}
              inputProps={{ type: 'number', min: 0 }}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>入村日</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography sx={styles.text}>{inputData.CheckInDate}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>入村時間 *</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              required
              hiddenLabel
              name="CheckInTime"
              value={inputData.CheckInTime ?? ''}
              onChange={handleChangeInput}
              size="small"
              error={errorAttribute.CheckInTime.IsError}
              helperText={errorAttribute.CheckInTime.ErrorMessage}
              inputProps={{ type: 'time' }}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>退村日</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography sx={styles.text}>{inputData.CheckOutDate}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>退村時間 *</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              required
              hiddenLabel
              name="CheckOutTime"
              value={inputData.CheckOutTime ?? ''}
              onChange={handleChangeInput}
              size="small"
              error={errorAttribute.CheckOutTime.IsError}
              helperText={errorAttribute.CheckOutTime.ErrorMessage}
              inputProps={{ type: 'time' }}
            />
          </Grid>
        </Grid>

        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" sx={styles.button} onClick={handleRegist}>
              登録
            </Button>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" sx={styles.button} onClick={handleClose}>
              閉じる
            </Button>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
export default PlanDialog;
