const UseAlertDialog = ({
  handleDialogClose,
  dialogOpen,
  content,
}: {
  handleDialogClose: (_: boolean) => void;
  dialogOpen: boolean;
  content: String;
}) => {
  /**
   * はい
   */
  const handleYes = (): void => {
    handleDialogClose(true);
  };

  /**
   * 閉じる
   */
  const handleClose = (): void => {
    handleDialogClose(false);
  };

  return {
    content,
    handleYes,
    handleClose,
    dialogOpen,
  };
};
export default UseAlertDialog;
