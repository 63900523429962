import {
  FindOptionalServiceQuery,
  OptionalService,
  RegistOptionalServiceMutation,
  Service,
  ServiceRate,
  UpdateOptionalServiceMutation,
} from 'API';
import { useAPI } from 'contexts/APIRequestContext';
import { useMaster } from 'contexts/CommonMasterContext';
import { registOptionalService, updateOptionalService } from 'graphql/mutations';
import { findOptionalService } from 'graphql/queries';
import React, { useCallback, useEffect, useState } from 'react';
import { getYYYMMDD } from 'util/DateUtil';
import { CreateEmptyErrorData, ErrorData } from './OptionDialogErrorData';

const UseOptionDialog = ({
  handleDialogClose,
  dialogOpen,
  optionalService,
  reservationId,
  guestCount,
  checkInDate,
  updateDatetime,
  mode,
}: {
  handleDialogClose: (_?: OptionalService, updateDatetime?: string) => void;
  dialogOpen: boolean;
  optionalService: OptionalService;
  reservationId: string;
  guestCount: number;
  checkInDate: string;
  updateDatetime: string;
  mode: string;
}) => {
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [inputData, setInputData] = useState({} as OptionalService);
  const [services, setServices] = useState([] as Service[]);
  const [serviceRates, setServiceRates] = useState([] as ServiceRate[]);
  const master = useMaster();
  const api = useAPI();
  const [, setError] = useState<undefined>();

  useEffect(() => {
    if (dialogOpen) {
      // 初期化
      setErrorAttribute({ ...CreateEmptyErrorData() });

      executeFindOptionalService();
    } else {
      setInputData({} as OptionalService);
    }
  }, [dialogOpen]);

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  /**
   * 宿泊オプション取得処理
   */
  async function executeFindOptionalService() {
    const response = (
      (await api.graphql({
        query: findOptionalService,
        variables: {
          data: {
            ReservationId: reservationId,
            UpdateDatetime: updateDatetime,
          },
        },
      })) as FindOptionalServiceQuery
    ).findOptionalService;

    if (response?.IsSuccess) {
      setServices(response!.Body.Services);
      setServiceRates(response!.Body.ServiceRates);
      if (Object.keys(optionalService).length) {
        setInputData(optionalService);
      } else {
        setInputData({ ...inputData, Count: guestCount, ProvideDate: checkInDate });
      }
    } else if (response?.IsSystemError) {
      throwError(response.ErrorData ?? '');
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...errorAttribute,
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  /**
   * 宿泊オプション　入力値変更時
   * @param event イベント
   */
  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'Rate') {
      setInputData({ ...inputData, [event.target.name]: Number(event.target.value) });
    } else if (event.target.name === 'Count') {
      setInputData({ ...inputData, [event.target.name]: Number(event.target.value || 1) });
    } else {
      setInputData({ ...inputData, [event.target.name]: event.target.value });
    }

    // 値が変更されたらエラー情報をクリア
    setErrorAttribute({ ...errorAttribute, [event.target.name]: { IsError: false, ErrorMessage: '' } });
  };

  /**
   * 宿泊オプション　プラン変更時
   * @param event イベント
   */
  const handleChangeService = (event: React.ChangeEvent<HTMLInputElement>) => {
    inputData.ServiceId = event.target.value;
    inputData.Rate = serviceRates.find((x) => x.ServiceId === event.target.value)?.Rate!;

    setInputData({ ...inputData, ServiceId: inputData.ServiceId, Rate: inputData.Rate });
    setErrorAttribute({
      ...errorAttribute,
      ServiceId: { IsError: false, ErrorMessage: '' },
      Rate: { IsError: false, ErrorMessage: '' },
    });
  };

  /**
   * 宿泊オプション　提供日変更時
   */
  const handleChangeProvideDate = (value: Date | null) => {
    if (value) {
      setInputData({ ...inputData, ProvideDate: getYYYMMDD(value, '/') });
    } else {
      setInputData({ ...inputData, ProvideDate: '' });
    }

    // 値が変更されたらエラー情報をクリア
    setErrorAttribute({ ...errorAttribute, ProvideDate: { IsError: false, ErrorMessage: '' } });
  };

  /**
   * 宿泊オプション登録処理
   */
  async function executeRegistOptionalService() {
    const response = (
      (await api.graphql({
        query: registOptionalService,
        variables: {
          data: {
            ReservationId: reservationId,
            ServiceId: inputData.ServiceId,
            ProvideDate: inputData.ProvideDate,
            StartTime: inputData.StartTime,
            EndTime: inputData.EndTime,
            Rate: inputData.Rate,
            Count: inputData.Count,
            UpdateDatetime: updateDatetime,
          },
        },
      })) as RegistOptionalServiceMutation
    ).registOptionalService;

    if (response?.IsSuccess) {
      handleDialogClose(
        {
          ServiceId: inputData.ServiceId,
          ServiceDisplayName: services.find((x) => x.ServiceId === inputData.ServiceId)?.ServiceDisplayName,
          ProvideDate: inputData.ProvideDate,
          StartTime: inputData.StartTime,
          EndTime: inputData.EndTime,
          Rate: inputData.Rate,
          Count: inputData.Count,
          Memo: inputData.Memo,
        } as OptionalService,
        response.Body!
      );
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  /**
   * 宿泊オプション更新処理
   */
  async function executeUpdateOptionalService() {
    const response = (
      (await api.graphql({
        query: updateOptionalService,
        variables: {
          data: {
            ReservationId: reservationId,
            ServiceId: inputData.ServiceId,
            ProvideDate: inputData.ProvideDate,
            StartTime: inputData.StartTime,
            EndTime: inputData.EndTime,
            Rate: inputData.Rate,
            Count: inputData.Count,
            Memo: inputData.Memo,
            UpdateDatetime: updateDatetime,
          },
        },
      })) as UpdateOptionalServiceMutation
    ).updateOptionalService;

    if (response?.IsSuccess) {
      handleDialogClose(
        {
          ServiceId: inputData.ServiceId,
          ServiceDisplayName: inputData.ServiceDisplayName,
          ProvideDate: inputData.ProvideDate,
          StartTime: inputData.StartTime,
          EndTime: inputData.EndTime,
          Rate: inputData.Rate,
          Count: inputData.Count,
          Memo: inputData.Memo,
        } as OptionalService,
        response.Body!
      );
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  /**
   * 宿泊オプション　登録
   */
  const handleRegist = (): void => {
    if (mode === 'NEW') {
      executeRegistOptionalService();
    } else if (mode === 'MODIFY') {
      executeUpdateOptionalService();
    }
  };

  /**
   * 宿泊プラン　閉じる
   */
  const handleClose = (): void => {
    handleDialogClose();
  };

  return {
    inputData,
    services,
    mode,
    handleChangeInput,
    handleChangeService,
    handleChangeProvideDate,
    handleRegist,
    handleClose,
    dialogOpen,
    errorAttribute,
  };
};
export default UseOptionDialog;
