import { useState, useEffect } from 'react';
import { Area, Branch, Prefecture, MSchools, CompanyBranch, FindReserveDateRequest, SchoolYearSetting } from 'API';
import { ReserveDateArea } from 'types/ReserveDateArea';
import { useMaster } from 'contexts/CommonMasterContext';
import { UserData } from 'models/UserData';
import { graphqlOperation } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { findReserveDateList } from 'graphql/queries';
import { FindReserveDateList, FindReserveDateListQuery } from 'API';
import { useAPI } from 'contexts/APIRequestContext';
import { useNavigate } from 'react-router-dom';
import { ReservationFrame } from 'views/reserve/reception/regist/ReservationFrame';
import { checkAuthority } from 'util/AuthorityCheck';

const UseReserveDateList = () => {
  const [prefectureCode, setPrefectureCode] = useState('');
  const [schoolPrefectureCode, setSchoolPrefectureCode] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [areaId, setAreaId] = useState('');
  const [bookingBranchId, setBookingBranchId] = useState('');
  const [salesBranchId, setSalesBranchId] = useState('');
  const [fromReserveDay, setFromReserveDay] = useState(null as Date | null);
  const [toReserveDay, setToReserveDay] = useState(null as Date | null);
  const [fromDurationDate, setFromDurationDate] = useState(null as Date | null);
  const [toDurationDate, setToDurationDate] = useState(null as Date | null);
  const [prefectureList, setPrefectureList] = useState([] as Prefecture[]);
  const [companyList, setCompanyList] = useState([] as CompanyBranch[]);
  const [areaList, setAreaList] = useState([] as ReserveDateArea[]);
  const [branchList, setBranchList] = useState([] as Branch[]);
  const [reservationStatusList, setReservationStatusList] = useState([] as Array<string>);
  const [inputData, setInputData] = useState({} as FindReserveDateRequest);
  const [resultData, setResultData] = useState([] as FindReserveDateList[]);
  const [searchSchoolOpen, setSearchSchoolOpen] = useState(false);
  const [selectSchool, setSelectSchool] = useState({} as MSchools);
  const [schoolYearSettingList, setSchoolYearSettingList] = useState([] as SchoolYearSetting[]);
  const [schoolYearCode, setSchoolYearCode] = useState('');

  const master = useMaster();
  const { user } = useAuthenticator();
  const userData = new UserData(user);
  const api = useAPI();
  const navigator = useNavigate();

  const defalutPage = 0;
  const defaultRowsPerPage = 25;

  const [page, setPage] = useState(defalutPage);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePrefectureCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPrefectureCode(value);
    changeArea(value);
  };

  /**
   * エリア情報更新
   */
  const changeArea = (prefectureCode: string) => {
    // Step1. 通常の入力値変更と店名クリア
    inputData.PrefectureCode = prefectureCode;
    inputData.AreaId = '';

    setInputData({
      ...inputData,
      PrefectureCode: prefectureCode,
      AreaId: '',
    });

    // Step2. 支店プルダウンデータ変更
    setAreaPullDownList(prefectureCode);
  };

  /**
   * エリアプルダウンリストを更新する
   */
  const setAreaPullDownList = (prefectureCode: string) => {
    const _areaList = master.getAreasByPrefectureCode(prefectureCode) as Area[];
    if (_areaList) {
      setAreaList(_areaList);
    } else {
      setAreaList([] as Area[]);
    }
  };

  const handleChangeArea = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setAreaId(value);
    setInputData({ ...inputData, AreaId: value });
  };

  const handleChangeSchoolPrefectureCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSchoolPrefectureCode(value);
    setInputData({ ...inputData, SchoolPrefectureCode: value });
  };

  const handleChangeCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyId(event.target.value);
    changeCompany(event.target.value);
  };

  /**
   * 代理店情報　代理店名更新
   */
  const changeCompany = (companyId: string) => {
    // Step1. 通常の入力値変更と店名クリア
    inputData.AgentCompanyId = companyId;
    inputData.BookingBranchId = '';
    inputData.SalesBranchId = '';

    setInputData({
      ...inputData,
      AgentCompanyId: companyId,
      BookingBranchId: '',
      SalesBranchId: '',
    });

    // Step2. 支店プルダウンデータ変更
    setBranchPullDownList(companyId);
  };

  /**
   * 旅行代理店支店プルダウンリストを更新する
   */
  const setBranchPullDownList = (companyId: string) => {
    const _branchList = master.getBranchs(companyId) as Branch[];
    if (_branchList) {
      setBranchList(_branchList);
    } else {
      setBranchList([] as Branch[]);
    }
  };

  const handleChangeBranchName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    if (name === 'BookingBranchId') {
      setBookingBranchId(value);
      setInputData({ ...inputData, BookingBranchId: value });
    } else if (name === 'SalesBranchId') {
      setSalesBranchId(value);
      setInputData({ ...inputData, SalesBranchId: value });
    }
  };

  const handleChangeSchoolYearCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSchoolYearCode(value);

    if (value === '' || value === null) {
      setToReserveDay(null);
      setFromReserveDay(null);
      setInputData({
        ...inputData,
        StayStartDate: '',
        StayEndDate: '',
      });
    } else {
      const schoolYearSetting = schoolYearSettingList.find((x) => x.SchoolYear.SchoolYearCode === value);

      if (schoolYearSetting?.SchoolYear?.StartDate && schoolYearSetting?.SchoolYear?.EndDate) {
        const stayStartDate = new Date(schoolYearSetting.SchoolYear.StartDate);
        const stayEndDate = new Date(schoolYearSetting.SchoolYear.EndDate);

        setToReserveDay(new Date(stayEndDate!));
        setFromReserveDay(new Date(stayStartDate!));
        setInputData({
          ...inputData,
          StayStartDate: `${stayStartDate.getFullYear()}-${stayStartDate.getMonth() + 1}-${stayStartDate.getDate()}`,
          StayEndDate: `${stayEndDate.getFullYear()}-${stayEndDate.getMonth() + 1}-${stayEndDate.getDate()}`,
        });
      }
    }
  };

  const handleChangeFromReserveDay = (value: Date | null) => {
    setFromReserveDay(value);
    if (value) {
      setInputData({
        ...inputData,
        // NOTE: getMonth() の結果が、入力値-1になっている・・・
        StayStartDate: value.getFullYear() + '-' + (value.getMonth() + 1) + '-' + value.getDate(),
      });
    } else {
      setInputData({
        ...inputData,
        StayStartDate: '',
      });
    }

    setSchoolYearCode('');
  };

  const handleChangeToReserveDay = (value: Date | null) => {
    setToReserveDay(value);
    if (value) {
      setInputData({
        ...inputData,
        // NOTE: getMonth() の結果が、入力値-1になっている・・・
        StayEndDate: value.getFullYear() + '-' + (value.getMonth() + 1) + '-' + value.getDate(),
      });
    } else {
      setInputData({
        ...inputData,
        StayEndDate: '',
      });
    }

    setSchoolYearCode('');
  };

  const handleChangeFromDurationDate = (value: Date | null) => {
    setFromDurationDate(value);
    if (value) {
      setInputData({
        ...inputData,
        // NOTE: getMonth() の結果が、入力値-1になっている・・・
        ReserveDurationStartDate:
          value.getFullYear() + '-' + (value.getMonth() + 1) + '-' + value.getDate() + ' 00:00:00',
      });
    }
  };

  const handleChangeToDurationDate = (value: Date | null) => {
    setToDurationDate(value);
    if (value) {
      setInputData({
        ...inputData,
        // NOTE: getMonth() の結果が、入力値-1になっている・・・
        ReserveDurationEndDate:
          value.getFullYear() + '-' + (value.getMonth() + 1) + '-' + value.getDate() + ' 23:59:59',
      });
    }
  };

  const handleSearchSchoolOpen = (): void => {
    setSearchSchoolOpen(true);
  };

  const handleResetSearchForm = (): void => {
    setSchoolYearCode('');
    setBookingBranchId('');
    setSalesBranchId('');
    setPrefectureCode('');
    setAreaPullDownList('');
    setSchoolPrefectureCode('');
    setCompanyId('');
    setAreaId('');
    setReservationStatusList([] as Array<string>);
    setFromReserveDay(null);
    setToReserveDay(null);
    setFromDurationDate(null);
    setToDurationDate(null);
    setSelectSchool({} as MSchools);

    setInputData({} as FindReserveDateRequest);
  };

  const handleSearchSchoolClose = (school?: MSchools): void => {
    if (school) {
      setInputData({
        ...inputData,
        SchoolId: school.SchoolId,
        SchoolPrefectureCode: '',
      });
      setSelectSchool(school);
      setSchoolPrefectureCode('');
    } else {
      setInputData({
        ...inputData,
        SchoolId: '',
      });
      setSelectSchool({} as MSchools);
    }
    setSearchSchoolOpen(false);
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    const checked = event.target.checked;

    let list;
    list = inputData['ReservationStatusList'] as Array<string> | null;

    if (!list) {
      list = [] as string[];
    }

    if (checked) {
      if (!list?.find((x) => x == value)) {
        list?.push(value);
      }
    } else {
      if (list?.find((x) => x == value)) {
        list = list.filter((x) => x != value);
      }
    }
    setReservationStatusList(list);
    setInputData({ ...inputData, [name]: list });
  };

  const handleClickSearch = () => {
    fetchReserveDateList();
    setPage(defalutPage);
    setRowsPerPage(defaultRowsPerPage);
  };

  async function fetchReserveDateList() {
    const response = (await api.graphql(
      graphqlOperation(findReserveDateList, {
        data: inputData,
      })
    )) as FindReserveDateListQuery;

    if (response?.findReserveDateList?.Body?.length) {
      setResultData(response?.findReserveDateList?.Body as FindReserveDateList[]);
    } else {
      setResultData([] as FindReserveDateList[]);
    }
  }

  const handleClickDetailButton = (reservation: FindReserveDateList) => {
    // ローカルストレージまたはセッションストレージに検索条件を退避する
    localStorage.setItem(
      'ReserveDateList_SearchConditions',
      JSON.stringify({ inputData, selectSchool, schoolYearCode, rowsPerPage, page })
    );
    localStorage.setItem('ReserveDateList_SearchResult', JSON.stringify(resultData));
    // 戻った時に状態を復元するフラグをセット
    sessionStorage.setItem('ReserveDateList_ShouldRestore', 'true');

    navigator('/reserve/detail', {
      state: {
        ReservationId: reservation.ReservationId,
        ReservedDay: reservation.ReservedDay,
      } as ReservationFrame,
    });
  };

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('ReserveDateList', userData.authorityCode)) {
      navigator('/');
    }

    master.waitForInitialized(() => {
      setPrefectureList(master.getPrefecture());
      setCompanyList(master.getCompanies());
      setSchoolYearSettingList(
        master
          .getSchoolYearSettings()
          .filter((x) => Date.parse(x.SchoolYear.OpenDatetime) < Date.now())
          .sort((n1, n2) => {
            if (n1.SchoolYear.SchoolYearCode > n2.SchoolYear.SchoolYearCode) {
              return -1;
            }
            if (n1.SchoolYear.SchoolYearCode < n2.SchoolYear.SchoolYearCode) {
              return 1;
            }
            return 0;
          })
      );
    });

    // 詳細画面から戻ってきた場合、ローカルストレージに退避していた情報を復元する
    const shouldRestore = sessionStorage.getItem('ReserveDateList_ShouldRestore');
    if (shouldRestore) {
      const storedConditionsString = localStorage.getItem('ReserveDateList_SearchConditions');
      const storedResultString = localStorage.getItem('ReserveDateList_SearchResult');
      let storedConditions = JSON.parse(storedConditionsString ?? '');
      let storedResult = JSON.parse(storedResultString ?? '');
      if (storedConditions) {
        setPrefectureCode(storedConditions?.inputData?.PrefectureCode ?? '');
        // エリアのプルダウンリストを都道府県コードに基づき設定する
        setAreaPullDownList(storedConditions?.inputData?.PrefectureCode ?? '');
        setAreaId(storedConditions?.inputData?.AreaId ?? '');

        setSchoolPrefectureCode(storedConditions?.inputData?.SchoolPrefectureCode ?? '');
        setCompanyId(storedConditions?.inputData?.AgentCompanyId ?? '');
        setFromReserveDay(
          storedConditions?.inputData?.StayStartDate ? new Date(storedConditions.inputData.StayStartDate) : null
        );
        setToReserveDay(
          storedConditions?.inputData?.StayEndDate ? new Date(storedConditions.inputData.StayEndDate) : null
        );

        setFromDurationDate(
          storedConditions?.inputData?.ReserveDurationStartDate
            ? new Date(storedConditions.inputData.ReserveDurationStartDate)
            : null
        );
        setToDurationDate(
          storedConditions?.inputData?.ReserveDurationEndDate
            ? new Date(storedConditions.inputData.ReserveDurationEndDate)
            : null
        );

        setSelectSchool(storedConditions?.selectSchool ?? ({} as MSchools));

        // 先に支店のプルダウンリストを旅行代理店IDに基づき設定する
        setBranchPullDownList(storedConditions?.inputData?.AgentCompanyId ?? '');

        setBookingBranchId(storedConditions?.inputData?.BookingBranchId ?? '');
        setSalesBranchId(storedConditions?.inputData?.SalesBranchId ?? '');
        setSchoolYearCode(storedConditions?.schoolYearCode ?? '');
        setReservationStatusList(storedConditions?.inputData?.ReservationStatusList ?? ([] as Array<string>));

        setInputData(storedConditions?.inputData);
        setRowsPerPage(storedConditions?.rowsPerPage);
        setPage(storedConditions?.page);
      }
      if (storedResult) {
        setResultData(storedResult);
      }
    }

    // フラグをリセット
    sessionStorage.removeItem('ReserveDateList_ShouldRestore');
  }, []);

  return {
    prefectureCode,
    schoolPrefectureCode,
    companyId,
    areaId,
    salesBranchId,
    bookingBranchId,
    fromReserveDay,
    toReserveDay,
    fromDurationDate,
    toDurationDate,
    schoolYearCode,
    prefectureList,
    companyList,
    areaList,
    branchList,
    schoolYearSettingList,
    resultData,
    page,
    rowsPerPage,
    searchSchoolOpen,
    selectSchool,
    reservationStatusList,
    inputData,
    handleChangePrefectureCode,
    handleChangeSchoolPrefectureCode,
    handleChangeCompany,
    handleChangeArea,
    handleChangeBranchName,
    handleChangeFromReserveDay,
    handleChangeToReserveDay,
    handleChangeFromDurationDate,
    handleChangeToDurationDate,
    handleChangeCheckbox,
    handleClickSearch,
    handleClickDetailButton,
    handleChangeSchoolYearCode,
    handleSearchSchoolOpen,
    handleSearchSchoolClose,
    handleResetSearchForm,
    handleChangePage,
    handleChangeRowsPerPage,
  };
};
export default UseReserveDateList;
