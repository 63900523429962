import FormErrorAttribute from 'types/FormErrorAttribute';

export interface ErrorData {
  Header: FormErrorAttribute;
  ReservedDays: FormErrorAttribute;
  ServiceId: FormErrorAttribute;
  GuestCount: FormErrorAttribute;
  CompanyId: FormErrorAttribute;
  Itinerary: FormErrorAttribute;
  AgentConfirmationDoc: FormErrorAttribute;
}

export const CreateEmptyErrorData = () => {
  return {
    Header: {} as FormErrorAttribute,
    ReservedDays: {} as FormErrorAttribute,
    ServiceId: {} as FormErrorAttribute,
    GuestCount: {} as FormErrorAttribute,
    CompanyId: {} as FormErrorAttribute,
    Itinerary: {} as FormErrorAttribute,
    AgentConfirmationDoc: {} as FormErrorAttribute,
  } as ErrorData;
};
