import { useEffect, useState } from 'react';
import { StudentData } from 'types/StudentData';

const UseStudentSimpleList = () => {
  const [resultData, setResultData] = useState([] as StudentData[]);

  useEffect(() => {
    setResultData([
      {
        ReservationId: '123456',
        ClassCode: '1',
        StudentNo: '1',
        StudentName: 'Name1',
        StudentKanaName: 'KanaName1',
        Sex: '男',
        IsActive: 1,
        Minka: '済',
        Allergy: 'XXXXXXXXXXXXXX',
        Consern: 'XXXXXXXX',
        Disease: 'XXXXXXX',
        Notice: 'XXXXXX12',
        BusinessName: '古宇利　四郎',
      },
      {
        ReservationId: '123456',
        ClassCode: '2',
        StudentNo: '2',
        StudentName: 'Name2',
        StudentKanaName: 'KanaName2',
        Sex: '男',
        IsActive: 1,
        Minka: '未',
        Allergy: 'XXXXXXXXXXXXXX',
        Consern: 'XXXXXXXX',
        Disease: 'XXXXXXX',
        Notice: 'XXXXXX12',
        BusinessName: '古宇利　四郎',
      },
      {
        ReservationId: '123456',
        ClassCode: '3',
        StudentNo: '3',
        StudentName: 'Name3',
        StudentKanaName: 'KanaName3',
        Sex: '男',
        IsActive: 1,
        Minka: '未',
        Allergy: 'XXXXXXXXXXXXXX',
        Consern: 'XXXXXXXX',
        Disease: 'XXXXXXX',
        Notice: 'XXXXXX12',
        BusinessName: '古宇利　四郎',
      },
      {
        ReservationId: '123456',
        ClassCode: '1',
        StudentNo: '4',
        StudentName: 'Name4',
        StudentKanaName: 'KanaName4',
        Sex: '男',
        IsActive: 1,
        Minka: '未',
        Allergy: 'XXXXXXXXXXXXXX',
        Consern: 'XXXXXXXX',
        Disease: 'XXXXXXX',
        Notice: 'XXXXXX12',
        BusinessName: '古宇利　四郎',
      },
      {
        ReservationId: '123456',
        ClassCode: '2',
        StudentNo: '5',
        StudentName: 'Name5',
        StudentKanaName: 'KanaName5',
        Sex: '男',
        IsActive: 1,
        Minka: '未',
      },
      {
        ReservationId: '123456',
        ClassCode: '3',
        StudentNo: '6',
        StudentName: 'Name6',
        StudentKanaName: 'KanaName6',
        Sex: '男',
        IsActive: 1,
        Minka: '亥',
        Allergy: 'XXXXXXXXXXXXXX',
        Consern: 'XXXXXXXX',
        Disease: 'XXXXXXX',
        Notice: 'XXXXXX12',
        BusinessName: '古宇利　四郎',
      },
      {
        ReservationId: '123456',
        ClassCode: '1',
        StudentNo: '7',
        StudentName: 'Name7',
        StudentKanaName: 'KanaName7',
        Sex: '男',
        IsActive: 1,
        Minka: '未',
      },
      {
        ReservationId: '123456',
        ClassCode: '3',
        StudentNo: '8',
        StudentName: 'Name8',
        StudentKanaName: 'KanaName8',
        Sex: '男',
        IsActive: 1,
        Minka: '戌',
        Allergy: 'XXXXXXXXXXXXXX',
        Consern: 'XXXXXXXX',
        Disease: 'XXXXXXX',
        Notice: 'XXXXXX12',
        BusinessName: '古宇利　四郎',
      },
      {
        ReservationId: '123456',
        ClassCode: '1',
        StudentNo: '9',
        StudentName: 'Name9',
        StudentKanaName: 'KanaName9',
        Sex: '男',
        IsActive: 1,
        Minka: '酉',
        Allergy: 'XXXXXXXXXXXXXX',
        Consern: 'XXXXXXXX',
        Disease: 'XXXXXXX',
        Notice: 'XXXXXX12',
        BusinessName: '古宇利　四郎',
      },
    ]);
  }, []);

  return {
    resultData,
  };
};
export default UseStudentSimpleList;
