import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import jaLocale from 'date-fns/locale/ja';
import TextField from '@mui/material/TextField';
import { ReservationDay } from 'API';
import { ErrorData } from './AddDaysErrorData';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { FormControl, FormHelperText, Stack } from '@mui/material';

export interface Props {
  inputData: ReservationDay;
  errorAttribute: ErrorData;
  handleRegist: () => void;
  handleClose: () => void;
  handleChangeDays: (newValue: any) => void;
  dialogOpen: boolean;
}

const styles = {
  categoryHeader: {
    fontWeight: 'bold',
  },
  itemHeader: {
    backgroundColor: '#ececec',
    fontWeight: 'bold',
  },
  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
  },
  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 1,
    ml: 1,
  },
  label: {
    mt: '7px',
  },
  categoryTitle: {
    borderLeft: 5,
    borderColor: '#0058a0',
    pl: '10px',
  },
  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },
  listButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    m: 0,
  },
};

const AddDaysDialog: FC<Props> = ({
  inputData,
  errorAttribute,
  handleRegist,
  handleClose,
  handleChangeDays,
  dialogOpen,
}) => {
  return (
    <Dialog open={dialogOpen} onClose={handleClose} maxWidth={'md'}>
      <DialogTitle>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          予約日程追加
        </Typography>
        {errorAttribute.Header.ErrorMessage && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl component="fieldset" error={errorAttribute.Header.IsError}>
                <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                  {errorAttribute.Header.ErrorMessage}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </DialogTitle>
      <DialogContent>
        <Typography>追加する予約日程を指定してください。</Typography>
        <FormControl component="fieldset" error={errorAttribute.ReservationDays.IsError}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
            <DesktopDatePicker
              label="yyyy/mm/dd"
              mask="____/__/__"
              value={inputData!.ReservedDay}
              onChange={(newValue) => handleChangeDays(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
          <FormHelperText>{errorAttribute.ReservationDays.ErrorMessage}</FormHelperText>
        </FormControl>

        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" sx={styles.button} onClick={handleRegist}>
              登録
            </Button>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" sx={styles.button} onClick={handleClose}>
              閉じる
            </Button>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
export default AddDaysDialog;
