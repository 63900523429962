import FormErrorAttribute from 'types/FormErrorAttribute';

export interface ErrorData {
  Header: FormErrorAttribute;
  AreaName: FormErrorAttribute;
  PrefectureCode: FormErrorAttribute;
  FromDate: FormErrorAttribute;
  ToDate: FormErrorAttribute;
  MaxGuestCount: FormErrorAttribute;
  MaxSchoolCount: FormErrorAttribute;
  MaxMultiSchoolGuestCount: FormErrorAttribute;
  MaxTemporaryReservationCount: FormErrorAttribute;
  MaxReservationCount: FormErrorAttribute;
  CheckInTime: FormErrorAttribute;
  CheckOutTime: FormErrorAttribute;
  MultiStayCheckInTime: FormErrorAttribute;
  MultiStayCheckOutTime: FormErrorAttribute;
  DisplayOrder: FormErrorAttribute;
  Url: FormErrorAttribute;
  Memo1: FormErrorAttribute;
  Memo2: FormErrorAttribute;
  Memo3: FormErrorAttribute;
  Memo4: FormErrorAttribute;
  Memo5: FormErrorAttribute;
  IsActive: FormErrorAttribute;
  UpdateDatetime: FormErrorAttribute;
}

export const CreateEmptyErrorData = () => {
  return {
    Header: {} as FormErrorAttribute,
    AreaName: {} as FormErrorAttribute,
    PrefectureCode: {} as FormErrorAttribute,
    FromDate: {} as FormErrorAttribute,
    ToDate: {} as FormErrorAttribute,
    MaxGuestCount: {} as FormErrorAttribute,
    MaxSchoolCount: {} as FormErrorAttribute,
    MaxMultiSchoolGuestCount: {} as FormErrorAttribute,
    MaxTemporaryReservationCount: {} as FormErrorAttribute,
    MaxReservationCount: {} as FormErrorAttribute,
    CheckInTime: {} as FormErrorAttribute,
    CheckOutTime: {} as FormErrorAttribute,
    MultiStayCheckInTime: {} as FormErrorAttribute,
    MultiStayCheckOutTime: {} as FormErrorAttribute,
    DisplayOrder: {} as FormErrorAttribute,
    Url: {} as FormErrorAttribute,
    Memo1: {} as FormErrorAttribute,
    Memo2: {} as FormErrorAttribute,
    Memo3: {} as FormErrorAttribute,
    Memo4: {} as FormErrorAttribute,
    Memo5: {} as FormErrorAttribute,
    IsActive: {} as FormErrorAttribute,
    UpdateDatetime: {} as FormErrorAttribute,
  } as ErrorData;
};

