/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const findReserveReception = /* GraphQL */ `
  query FindReserveReception(
    $PrefectureCode: String!
    $SearchStartDate: String!
    $SearchEndDate: String!
    $IsIncludeCancel: Boolean
  ) {
    findReserveReception(
      PrefectureCode: $PrefectureCode
      SearchStartDate: $SearchStartDate
      SearchEndDate: $SearchEndDate
      IsIncludeCancel: $IsIncludeCancel
    ) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        AreaId
        AreaName
        PrefectureCode
        MaxReservationCount
        Memo1
        FrameList {
          FrameNo
          DateList {
            Date
            Availability
            IsStopped
            NonBusinessDay
            ClosedReason
            Reservation {
              Pattern
              ReservationId
              PrefectureCode
              PrefectureName
              AreaId
              AreaName
              MaxGuestCount
              MaxSchoolCount
              MaxMultiSchoolGuestCount
              Memo1
              AreaServiceRates {
                ServiceId
                CompanyName
                ServiceCategoryName
                ServiceName
                Rate
              }
              AreaCheckInTime
              AreaCheckOutTime
              SchoolId
              SchoolName
              SchoolKanaName
              HasGroupingName
              GroupingName
              HasAnotherResv
              GuestCount
              ServiceId
              ServiceName
              Rate
              CheckInDate
              CheckInTime
              CheckOutDate
              CheckOutTime
              ReservationStatusCode
              WaitingNumber
              ReservationStatus
              ApprovalStatusCode
              ApprovalStatus
              CreateDatetime
              UpdateDatetime
              ApprovedDatetime
              CancelledDatetime
              CancelledReason
              ReservedDay
              DurationDate
              ReservationDays {
                ReservationId
                ReservedDay
                ReservedMonth
                ReservedYear
                ReservationStatusCode
                ReservationStatus
                DurationDate
                CanExtend
              }
              OptionalServices {
                ServiceId
                ServiceDisplayName
                ProvideDate
                StartTime
                EndTime
                Rate
                Count
                Memo
              }
              ReservationAgents {
                CompanyId
                CompanyName
                BookingBranchId
                BookingBranchName
                BookingUserName
                BookingUserTel
                BookingUserMail
                SalesBranchId
                SalesBranchName
                SalesUserName
                SalesUserTel
                SalesUserMail
                ItineraryName
                Itinerary
                ItineraryLink
                SchoolConfirmationDocName
                SchoolConfirmationDoc
                SchoolConfirmationDocLink
                AgentConfirmationDocName
                AgentConfirmationDoc
                AgentConfirmationDocLink
                CenterConfirmationDocName
                CenterConfirmationDoc
                CenterConfirmationDocLink
                Note
              }
              CompanyId
              CompanyName
              ManagingUserName
              BranchId
              BranchName
              CheckInPlace
              CheckOutPlace
              Memo
              HasSharedReservation
              CanSchoolApplication
              CanSchoolDecision
              CanApplication
              CanUpdateServiceProvider
              SchoolConfirmDate
            }
            MaxGuestCount
            MaxSchoolCount
            MaxMultiSchoolGuestCount
            CheckInTime
            CheckOutTime
          }
        }
      }
      ErrorData
    }
  }
`;
export const getReservation = /* GraphQL */ `
  query GetReservation(
    $ReservationId: Int
    $AreaId: Int
    $ReservedDay: String
  ) {
    getReservation(
      ReservationId: $ReservationId
      AreaId: $AreaId
      ReservedDay: $ReservedDay
    ) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        Pattern
        ReservationId
        PrefectureCode
        PrefectureName
        AreaId
        AreaName
        MaxGuestCount
        MaxSchoolCount
        MaxMultiSchoolGuestCount
        Memo1
        AreaServiceRates {
          ServiceId
          CompanyName
          ServiceCategoryName
          ServiceName
          Rate
        }
        AreaCheckInTime
        AreaCheckOutTime
        SchoolId
        SchoolName
        SchoolKanaName
        HasGroupingName
        GroupingName
        HasAnotherResv
        GuestCount
        ServiceId
        ServiceName
        Rate
        CheckInDate
        CheckInTime
        CheckOutDate
        CheckOutTime
        ReservationStatusCode
        WaitingNumber
        ReservationStatus
        ApprovalStatusCode
        ApprovalStatus
        CreateDatetime
        UpdateDatetime
        ApprovedDatetime
        CancelledDatetime
        CancelledReason
        ReservedDay
        DurationDate
        ReservationDays {
          ReservationId
          ReservedDay
          ReservedMonth
          ReservedYear
          ReservationStatusCode
          ReservationStatus
          DurationDate
          CanExtend
        }
        OptionalServices {
          ServiceId
          ServiceDisplayName
          ProvideDate
          StartTime
          EndTime
          Rate
          Count
          Memo
        }
        ReservationAgents {
          CompanyId
          CompanyName
          BookingBranchId
          BookingBranchName
          BookingUserName
          BookingUserTel
          BookingUserMail
          SalesBranchId
          SalesBranchName
          SalesUserName
          SalesUserTel
          SalesUserMail
          ItineraryName
          Itinerary
          ItineraryLink
          SchoolConfirmationDocName
          SchoolConfirmationDoc
          SchoolConfirmationDocLink
          AgentConfirmationDocName
          AgentConfirmationDoc
          AgentConfirmationDocLink
          CenterConfirmationDocName
          CenterConfirmationDoc
          CenterConfirmationDocLink
          Note
        }
        CompanyId
        CompanyName
        ManagingUserName
        BranchId
        BranchName
        CheckInPlace
        CheckOutPlace
        Memo
        HasSharedReservation
        CanSchoolApplication
        CanSchoolDecision
        CanApplication
        CanUpdateServiceProvider
        SchoolConfirmDate
      }
      ErrorData
    }
  }
`;
export const findCommonMaster = /* GraphQL */ `
  query FindCommonMaster {
    findCommonMaster {
      IsSuccess
      IsInputCheckError
      Body {
        PrefectureList {
          PrefectureCode
          PrefectureName
          IsMinpakuArea
          IsActive
        }
        CodeList {
          CodeKind
          Code
          Name
        }
        CompanyBranchList {
          CompanyId
          CategoryCode
          CompanyName
          CompanyShortName
          IsActive
          BranchList {
            CompanyId
            BranchId
            IsMain
            BranchName
            PostalCode
            PrefectureCode
            Address
            Tel
            Fax
            Mail1
            Mail2
            Mail3
            Mail4
            Mail5
            Mail6
            IsActive
            UpdateDatetime
          }
        }
        AreaList {
          AreaId
          AreaName
          PrefectureCode
          FromDate
          ToDate
          MaxGuestCount
          MaxSchoolCount
          MaxMultiSchoolGuestCount
          MaxTemporaryReservationCount
          MaxReservationCount
          CheckInTime
          CheckOutTime
          MultiStayCheckInTime
          MultiStayCheckOutTime
          DisplayOrder
          Url
          Memo1
          Memo2
          Memo3
          Memo4
          Memo5
          IsActive
          UpdateDatetime
        }
        RegionList {
          AreaId
          RegionCode
          RegionName
          IsActive
          UpdateDatetime
        }
        ServiceList {
          ServiceId
          CompanyId
          ServiceCategoryCode
          ServiceName
          ServiceDisplayName
          ReservationStartDays
          IsAdmin
          StayNights
          Meals
          OfferingHours
          OfferingDayDiv
          FromDate
          ToDate
          DisplayOrder
          ServiceRate
          CompanyName
          ServiceCategoryName
          IsActive
          UpdateDatetime
        }
        SchoolYearSettingList {
          SchoolYear {
            SchoolYearCode
            OpenDatetime
            StartDate
            EndDate
          }
          SchoolYearPeriodList {
            SchoolYearCode
            PeriodCode
            Year
            Month
            StartDate
            EndDate
          }
        }
      }
      ErrorData
    }
  }
`;
export const findNotification = /* GraphQL */ `
  query FindNotification {
    findNotification {
      IsSuccess
      IsInputCheckError
      Body {
        SystemNotification {
          NotificationId
          NotificationDate
          NotificationKind
          Subject
          Contents
        }
        ReserveNotification {
          NotificationId
          NotificationDate
          NotificationKind
          Subject
          Contents
        }
      }
      ErrorData
    }
  }
`;
export const findMSchools = /* GraphQL */ `
  query FindMSchools($data: SchoolRequestData) {
    findMSchools(data: $data) {
      IsSuccess
      IsInputCheckError
      Body {
        SchoolId
        SchoolCode
        SchoolCategoryCode
        PrefectureCode
        ClassificationCode
        SchoolName
        SchoolKanaName
        Address
        PostalCode
        Tel
        Fax
        IsActive
        UpdateDatetime
      }
      ErrorData
    }
  }
`;
export const getMaxMultiSchoolGuestCount = /* GraphQL */ `
  query GetMaxMultiSchoolGuestCount($AreaId: Int!, $Date: String!) {
    getMaxMultiSchoolGuestCount(AreaId: $AreaId, Date: $Date) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body
      ErrorData
    }
  }
`;
export const initAcceptCompany = /* GraphQL */ `
  query InitAcceptCompany($data: ReservationCommonRequest) {
    initAcceptCompany(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        Companies {
          CompanyId
          CategoryCode
          CompanyName
          CompanyShortName
          IsActive
          UpdateDatetime
        }
        Branches {
          CompanyId
          BranchId
          IsMain
          BranchName
          PostalCode
          PrefectureCode
          Address
          Tel
          Fax
          Mail1
          Mail2
          Mail3
          Mail4
          Mail5
          Mail6
          IsActive
          UpdateDatetime
        }
        CanUpdateServiceProvider
      }
      ErrorData
    }
  }
`;
export const initApplyReservation = /* GraphQL */ `
  query InitApplyReservation($data: ReservationCommonRequest) {
    initApplyReservation(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        ReservationId
        ReservedDays
        GuestCount
        ReservationAgents {
          CompanyId
          CompanyName
          BookingBranchId
          BookingBranchName
          BookingUserName
          BookingUserTel
          BookingUserMail
          SalesBranchId
          SalesBranchName
          SalesUserName
          SalesUserTel
          SalesUserMail
          ItineraryName
          Itinerary
          ItineraryLink
          SchoolConfirmationDocName
          SchoolConfirmationDoc
          SchoolConfirmationDocLink
          AgentConfirmationDocName
          AgentConfirmationDoc
          AgentConfirmationDocLink
          CenterConfirmationDocName
          CenterConfirmationDoc
          CenterConfirmationDocLink
          Note
        }
        AnotherReservationAgents {
          CompanyId
          CompanyName
          BookingBranchId
          BookingBranchName
          BookingUserName
          BookingUserTel
          BookingUserMail
          SalesBranchId
          SalesBranchName
          SalesUserName
          SalesUserTel
          SalesUserMail
          ItineraryName
          Itinerary
          ItineraryLink
          SchoolConfirmationDocName
          SchoolConfirmationDoc
          SchoolConfirmationDocLink
          AgentConfirmationDocName
          AgentConfirmationDoc
          AgentConfirmationDocLink
          CenterConfirmationDocName
          CenterConfirmationDoc
          CenterConfirmationDocLink
          Note
        }
      }
      ErrorData
    }
  }
`;
export const initApplySchoolDecision = /* GraphQL */ `
  query InitApplySchoolDecision($data: ReservationCommonRequest) {
    initApplySchoolDecision(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        ReservationId
        ReservedDays
      }
      ErrorData
    }
  }
`;
export const initMinkaAllocateSearch = /* GraphQL */ `
  query InitMinkaAllocateSearch {
    InitMinkaAllocateSearch {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        Prefectures {
          PrefectureCode
          PrefectureName
          IsMinpakuArea
          IsActive
        }
        Areas {
          AreaId
          AreaName
          PrefectureCode
          FromDate
          ToDate
          MaxGuestCount
          MaxSchoolCount
          MaxMultiSchoolGuestCount
          MaxTemporaryReservationCount
          MaxReservationCount
          CheckInTime
          CheckOutTime
          MultiStayCheckInTime
          MultiStayCheckOutTime
          DisplayOrder
          Url
          Memo1
          Memo2
          Memo3
          Memo4
          Memo5
          IsActive
          UpdateDatetime
        }
        Years {
          SchoolYearCode
          OpenDatetime
          StartDate
          EndDate
        }
        Months {
          SchoolYearCode
          PeriodCode
          Year
          Month
          StartDate
          EndDate
        }
      }
      ErrorData
    }
  }
`;
export const findService = /* GraphQL */ `
  query FindService($data: FindServiceRequest!) {
    findService(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        ServiceId
        ServiceName
        ServiceDisplayName
        Rate
        CheckInDate
        CheckInTime
        CheckOutDate
        CheckOutTime
      }
      ErrorData
    }
  }
`;
export const findOptionalService = /* GraphQL */ `
  query FindOptionalService($data: ReservationCommonRequest) {
    findOptionalService(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        Services {
          ServiceId
          ServiceName
          ServiceDisplayName
          Rate
          CheckInDate
          CheckInTime
          CheckOutDate
          CheckOutTime
        }
        ServiceRates {
          ServiceRateId
          ServiceId
          Rate
          FromDate
          EndDate
          UpdateDatetime
        }
      }
      ErrorData
    }
  }
`;
export const findReserveDateList = /* GraphQL */ `
  query FindReserveDateList($data: FindReserveDateRequest) {
    findReserveDateList(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        ReservedDay
        AreaId
        AreaName
        SchoolId
        SchoolName
        GuestCount
        ReservationStatusCode
        ReservationStatus
        DurationDate
        ReservationId
        AgentList
        PrefectureCode
      }
      ErrorData
    }
  }
`;
export const findReserveList = /* GraphQL */ `
  query FindReserveList($data: FindReserveListRequest) {
    findReserveList(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        ReservationId
        CheckInDate
        CheckOutDate
        SchoolId
        SchoolName
        GuestCount
        StayNights
        PrefectureCode
        PrefectureName
        AreaId
        AreaName
        AcceptCompanyId
        AcceptCompanyName
        ApprovalStatusCode
        ApprovalStatus
        CompanyId
        CompanyName
        BookingBranchId
        BookingBranchName
        SalesBranchId
        SalesBranchName
      }
      ErrorData
    }
  }
`;
export const findBelongsArea = /* GraphQL */ `
  query FindBelongsArea {
    findBelongsArea {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        AreaId
        CompanyId
        BranchId
        PrefectureCode
      }
      ErrorData
    }
  }
`;
export const findStudentList = /* GraphQL */ `
  query FindStudentList($data: FindStudentListRequest) {
    findStudentList(data: $data) {
      IsSuccess
      IsInputCheckError
      Body {
        ReservationId
        ClassCode
        StudentNo
        StudentName
        StudentKanaName
        Sex
        SexDisplayName
        MinkaId
        MinkaName
        Allergy
        Disease
        Consern
        Notice
        Memo
        IsActive
        IsSelected
        HasAnotherAllocated
        CompanyId
        UpdateDatetime
      }
      ErrorData
    }
  }
`;
export const findStudent = /* GraphQL */ `
  query FindStudent($data: FindStudentRequest) {
    findStudent(data: $data) {
      IsSuccess
      IsInputCheckError
      Body {
        ReservationId
        ClassCode
        StudentNo
        StudentName
        StudentKanaName
        Sex
        SexDisplayName
        MinkaId
        MinkaName
        Allergy
        Disease
        Consern
        Notice
        Memo
        IsActive
        IsSelected
        HasAnotherAllocated
        CompanyId
        UpdateDatetime
      }
      ErrorData
    }
  }
`;
export const downloadPublicFile = /* GraphQL */ `
  query DownloadPublicFile($data: String) {
    downloadPublicFile(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        FileName
        FileBase64String
      }
      ErrorData
    }
  }
`;
export const findStudentLedger = /* GraphQL */ `
  query FindStudentLedger($data: String) {
    findStudentLedger(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        IsAllocateStudent
        IsChangeMinka
        ClassCode
        GroupCode
        HasStudent
        MinkaId
        DisplayMinkaId
        MinkaName
        AcceptanceGender
        GuestCount
        MaxGuestCount
        StudentList {
          ReservationId
          ClassCode
          StudentNo
          StudentName
          StudentKanaName
          Sex
          SexDisplayName
          MinkaId
          MinkaName
          Allergy
          Disease
          Consern
          Notice
          Memo
          IsActive
          IsSelected
          HasAnotherAllocated
          CompanyId
          UpdateDatetime
        }
        AreaId
        RegionCode
        UpdateDatetime
      }
      ErrorData
    }
  }
`;
export const findClassStudentList = /* GraphQL */ `
  query FindClassStudentList($data: AllocateStudentRequest) {
    findClassStudentList(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        Students {
          ReservationId
          ClassCode
          StudentNo
          StudentName
          StudentKanaName
          Sex
          SexDisplayName
          MinkaId
          MinkaName
          Allergy
          Disease
          Consern
          Notice
          Memo
          IsActive
          IsSelected
          HasAnotherAllocated
          CompanyId
          UpdateDatetime
        }
        ClassCodes
        HasStudent
        UpdateDatetime
      }
      ErrorData
    }
  }
`;
export const findMinkaInRegion = /* GraphQL */ `
  query FindMinkaInRegion($ReservationId: String!, $AreaId: String!) {
    findMinkaInRegion(ReservationId: $ReservationId, AreaId: $AreaId) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        AreaId
        RegionCode
        RegionName
        MinkaList {
          MinkaId
          MinkaName
        }
      }
      ErrorData
    }
  }
`;
export const findMinkaList = /* GraphQL */ `
  query FindMinkaList($data: FindMinkaListRequest) {
    findMinkaList(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        MinkaId
        MinkaName
        BussinessName
        ManagingCompanyId
        ManagingBranchId
        MinkaNo
        PostalCode
        PrefectureCode
        AreaId
        RegionCode
        RegionName
        City
        Address
        Tel
        GuestCount
        MaxGuestcount
        AcceptanceGender
        AcceptanceGenderDisplayName
        Pets
        DressingRoom
        AirConditionar
        ToiletType
        SmokeType
        AuthorityNumber
        AuthorizedDate
        OtherAddress
        BankName
        BankBranchName
        AccountType
        AccountNumber
        AccountName
        AccountNameKana
        Memo
        IsAvailable
        IsActive
        UpdateDatetime
        Longitude
        Latitude
        InternetType
        BarrierFreeCapability
        AlertResponse
        BussinessStartDate
        ConstructionDate
        ArchitecturalStructure
        FacilityInsurance
        LandslideWarningArea
        TsunamiWarningArea
        EvacuationSite
        FloodHazard
        RainHazard
        HighTideHazard
      }
      ErrorData
    }
  }
`;
export const findMinka = /* GraphQL */ `
  query FindMinka($MinkaId: String) {
    findMinka(MinkaId: $MinkaId) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        Minka {
          MinkaId
          MinkaName
          BussinessName
          ManagingCompanyId
          ManagingBranchId
          MinkaNo
          PostalCode
          PrefectureCode
          AreaId
          RegionCode
          RegionName
          City
          Address
          Tel
          GuestCount
          MaxGuestcount
          AcceptanceGender
          AcceptanceGenderDisplayName
          Pets
          DressingRoom
          AirConditionar
          ToiletType
          SmokeType
          AuthorityNumber
          AuthorizedDate
          OtherAddress
          BankName
          BankBranchName
          AccountType
          AccountNumber
          AccountName
          AccountNameKana
          Memo
          IsAvailable
          IsActive
          UpdateDatetime
          Longitude
          Latitude
          InternetType
          BarrierFreeCapability
          AlertResponse
          BussinessStartDate
          ConstructionDate
          ArchitecturalStructure
          FacilityInsurance
          LandslideWarningArea
          TsunamiWarningArea
          EvacuationSite
          FloodHazard
          RainHazard
          HighTideHazard
        }
        MinkaResidentRepresentative {
          MinkaId
          ResidentId
          LastName
          FirstName
          LastKanaName
          FirstKanaName
          BirthDay
          Sex
          SexDisplayName
          Occupation
          ResidentsRelationCode
          ResidentsRelationCodeDisplayName
          Tel
          Mail
          WorkHistory
          Character
          Longitude
          Latitude
          InternetType
          BarrierFreeCapability
          AlertResponse
          BussinessStartDate
          ConstructionDate
          ArchitecturalStructure
          FacilityInsurance
          LandslideWarningArea
          TsunamiWarningArea
          EvacuationSite
          FloodHazard
          RainHazard
          HighTideHazard
          UpdateDatetime
        }
        MinkaResidents {
          MinkaId
          ResidentId
          LastName
          FirstName
          LastKanaName
          FirstKanaName
          BirthDay
          Sex
          SexDisplayName
          Occupation
          ResidentsRelationCode
          ResidentsRelationCodeDisplayName
          Tel
          Mail
          WorkHistory
          Character
          Longitude
          Latitude
          InternetType
          BarrierFreeCapability
          AlertResponse
          BussinessStartDate
          ConstructionDate
          ArchitecturalStructure
          FacilityInsurance
          LandslideWarningArea
          TsunamiWarningArea
          EvacuationSite
          FloodHazard
          RainHazard
          HighTideHazard
          UpdateDatetime
        }
        MinkaVehicles {
          MinkaId
          CarNo
          InspectionDate
          InsuranceEndDate
          CarType
          Passenger
          CarInsurance
          UpdateDatetime
        }
        MinkaActivities {
          MinkaId
          Activity
          UpdateDatetime
        }
        MinkaClosingSettings {
          MinkaId
          SchoolYearCode
          Period1
          Period2
          Period3
          Period4
          Period5
          Period6
          Period7
          Period8
          Period9
          Period10
          Period11
          Period12
        }
      }
      ErrorData
    }
  }
`;
export const minkaAllocateSearch = /* GraphQL */ `
  query MinkaAllocateSearch($data: MinkaAllocateSearchRequest) {
    minkaAllocateSearch(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        SchoolYearCode
        PeriodCode
        Year
        Month
        Minkas {
          MinkaId
          MinkaName
          BussinessName
          ManagingCompany
          ManagingBranch
          MinkaNo
          PostalCode
          Prefecture
          AreaId
          RegionCode
          RegionName
          City
          Address
          GuestCount
          MaxGuestcount
          DressingRoom
          AirConditionar
          ToiletType
          SmokeType
          AuthorityNumber
          AuthorizedDate
          IsActive
          AcceptanceGender
          AcceptCount
          IsClosed
          PickUpMinkas {
            IsAllocated
            ReservationId
            MinkaId
            HasStudent
            Memo
            GuestCount
            ApprovalStatusCode
          }
        }
        Reservations {
          ReservationId
          ReservedDay
          ReservedGuestCount
          Year
          Month
          SchoolName
          ServiceName
          CheckInDate
          CheckInTime
          CheckInPlace
          CheckOutDate
          CheckOutTime
          CheckOutPlace
          BranchName
          ManagingUserName
          PickUpMinkaCount
          GuestCount
          MaxGuestCount
          PickUpMinkas {
            IsAllocated
            ReservationId
            MinkaId
            HasStudent
            Memo
            GuestCount
            ApprovalStatusCode
          }
          ApprovalStatusCode
          UpdateDatetime
        }
        IsActiveBefore
        IsActiveAfter
      }
      ErrorData
    }
  }
`;
export const findAcceptMinka = /* GraphQL */ `
  query FindAcceptMinka($data: String) {
    findAcceptMinka(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        ReservationId
        CheckInDate
        CheckOutDate
        SchoolName
        ServiceName
        GroupingName
        Mail
        AllocateList {
          ClassCode
          GroupCode
          MinkaId
          MinkaName
          BussinessName
        }
      }
      ErrorData
    }
  }
`;
export const getSchool = /* GraphQL */ `
  query GetSchool($SchoolId: String) {
    getSchool(SchoolId: $SchoolId) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        SchoolId
        SchoolCode
        SchoolCategoryCode
        PrefectureCode
        ClassificationCode
        SchoolName
        SchoolKanaName
        Address
        PostalCode
        Tel
        Fax
        IsActive
        UpdateDatetime
      }
      ErrorData
    }
  }
`;
export const findCompanyList = /* GraphQL */ `
  query FindCompanyList($data: FindCompanyListRequest) {
    findCompanyList(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        CompanyId
        CategoryCode
        CompanyName
        CompanyShortName
        IsActive
        UpdateDatetime
      }
      ErrorData
    }
  }
`;
export const findCompany = /* GraphQL */ `
  query FindCompany($CompanyId: String) {
    findCompany(CompanyId: $CompanyId) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        Company {
          CompanyId
          CategoryCode
          CompanyName
          CompanyShortName
          IsActive
          UpdateDatetime
        }
        CompanyMain {
          CompanyId
          BranchId
          IsMain
          BranchName
          PostalCode
          PrefectureCode
          Address
          Tel
          Fax
          Mail1
          Mail2
          Mail3
          Mail4
          Mail5
          Mail6
          IsActive
          UpdateDatetime
        }
        CompanyBranch {
          CompanyId
          BranchId
          IsMain
          BranchName
          PostalCode
          PrefectureCode
          Address
          Tel
          Fax
          Mail1
          Mail2
          Mail3
          Mail4
          Mail5
          Mail6
          IsActive
          UpdateDatetime
        }
      }
      ErrorData
    }
  }
`;
export const findServiceList = /* GraphQL */ `
  query FindServiceList($data: FindServiceListRequest) {
    findServiceList(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        ServiceId
        CompanyId
        ServiceCategoryCode
        ServiceName
        ServiceDisplayName
        ReservationStartDays
        IsAdmin
        StayNights
        Meals
        OfferingHours
        OfferingDayDiv
        FromDate
        ToDate
        DisplayOrder
        ServiceRate
        CompanyName
        ServiceCategoryName
        IsActive
        UpdateDatetime
      }
      ErrorData
    }
  }
`;
export const findServiceDetail = /* GraphQL */ `
  query FindServiceDetail($ServiceId: String) {
    findServiceDetail(ServiceId: $ServiceId) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        Service {
          ServiceId
          CompanyId
          ServiceCategoryCode
          ServiceName
          ServiceDisplayName
          ReservationStartDays
          IsAdmin
          StayNights
          Meals
          OfferingHours
          OfferingDayDiv
          FromDate
          ToDate
          DisplayOrder
          ServiceRate
          CompanyName
          ServiceCategoryName
          IsActive
          UpdateDatetime
        }
        ServiceRate {
          ServiceRateId
          ServiceId
          Rate
          FromDate
          EndDate
          UpdateDatetime
        }
      }
      ErrorData
    }
  }
`;
export const findAreaList = /* GraphQL */ `
  query FindAreaList($data: FindAreaListRequest) {
    findAreaList(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        AreaId
        AreaName
        PrefectureCode
        FromDate
        ToDate
        MaxGuestCount
        MaxSchoolCount
        MaxMultiSchoolGuestCount
        MaxTemporaryReservationCount
        MaxReservationCount
        CheckInTime
        CheckOutTime
        MultiStayCheckInTime
        MultiStayCheckOutTime
        DisplayOrder
        Url
        Memo1
        Memo2
        Memo3
        Memo4
        Memo5
        IsActive
        UpdateDatetime
      }
      ErrorData
    }
  }
`;
export const findAreaDetail = /* GraphQL */ `
  query FindAreaDetail($AreaId: String) {
    findAreaDetail(AreaId: $AreaId) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        Area {
          AreaId
          AreaName
          PrefectureCode
          FromDate
          ToDate
          MaxGuestCount
          MaxSchoolCount
          MaxMultiSchoolGuestCount
          MaxTemporaryReservationCount
          MaxReservationCount
          CheckInTime
          CheckOutTime
          MultiStayCheckInTime
          MultiStayCheckOutTime
          DisplayOrder
          Url
          Memo1
          Memo2
          Memo3
          Memo4
          Memo5
          IsActive
          UpdateDatetime
        }
        AreaManager {
          AreaId
          CompanyId
          BranchId
          IsRepresentativ
          FromDate
          ToDate
          UpdateDatetime
          CompanyName
          BranchName
        }
        AreaService {
          AreaId
          ServiceId
          FromDate
          ToDate
          UpdateDatetime
          ServiceName
          CompanyId
          CompanyName
          ServiceCategoryCode
          ServiceCategoryName
        }
        Region {
          AreaId
          RegionCode
          RegionName
          IsActive
          UpdateDatetime
        }
      }
      ErrorData
    }
  }
`;
export const findReservationFrameOpenSettingList = /* GraphQL */ `
  query FindReservationFrameOpenSettingList(
    $data: FindReservationFrameOpenSettingListRequest
  ) {
    findReservationFrameOpenSettingList(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        AreaId
        AreaName
        SchoolYearCode
        FromDate
        ToDate
        Period1
        Period2
        Period3
        Period4
        Period5
        Period6
        Period7
        Period8
        Period9
        Period10
        Period11
        Period12
      }
      ErrorData
    }
  }
`;
export const findAreaReservationFrame = /* GraphQL */ `
  query FindAreaReservationFrame($data: FindAreaReservationFrameRequest) {
    findAreaReservationFrame(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        AreaId
        Date
        Month
        Year
        MaxGuestCount
        MaxSchoolCount
        MaxMultiSchoolGuestCount
        ApprovedSchoolCount
        ApprovedGuestCount
        CheckInTime
        CheckOutTime
        MultiStayCheckInTime
        MultiStayCheckOutTime
        IsStopped
        NonBusinessDay
        ClosedReason
        UpdateDatetime
      }
      ErrorData
    }
  }
`;
export const findNonBusinessDay = /* GraphQL */ `
  query FindNonBusinessDay($data: FindNonBusinessDayRequest) {
    findNonBusinessDay(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        AreaId
        NonBusinessDays
      }
      ErrorData
    }
  }
`;
export const findUsers = /* GraphQL */ `
  query FindUsers($data: UserRequest) {
    findUsers(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        UserId
        Password
        UserName
        Mail
        UserKanaName
        Mail2
        Mail3
        UserCategoryCode
        CompanyId
        CompanyName
        BranchId
        SchoolId
        SchoolName
        MinkaId
        AuthorityCode
        IsActive
        CanAllDept
      }
      ErrorData
    }
  }
`;
export const findUser = /* GraphQL */ `
  query FindUser($data: String) {
    findUser(data: $data) {
      IsSuccess
      IsInputCheckError
      IsSystemError
      Body {
        UserId
        Password
        UserName
        Mail
        UserKanaName
        Mail2
        Mail3
        UserCategoryCode
        CompanyId
        CompanyName
        BranchId
        SchoolId
        SchoolName
        MinkaId
        AuthorityCode
        IsActive
        CanAllDept
      }
      ErrorData
    }
  }
`;
