import { FormControl, FormHelperText, Link, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Branch, CompanyBranch, ReservationAgent, ReservationDay } from 'API';
import { FC } from 'react';
import { SelectValue } from 'types/SelectValue';
import SelectFormControl from 'views/parts/SelectFormControl';
import { ErrorData } from './AgentDialogErrorData';

export interface Props {
  user: any;
  inputData: ReservationAgent;
  companyList: CompanyBranch[];
  branchList: Branch[];
  mode: string;
  reservationDays: ReservationDay[];
  schoolConfirmed: boolean;
  canAllDept: boolean;
  handleChangeCompany: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeBookingBranch: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeSalesBranch: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeAgentInput: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeItinerary: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleDeleteItinerary: () => void;
  handleChangeSchoolConfirmationDoc: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleDeleteSchoolConfirmationDoc: () => void;
  handleChangeAgentConfirmationDoc: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleDeleteAgentConfirmationDoc: () => void;
  handleChangeCenterConfirmationDoc: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleDeleteCenterConfirmationDoc: () => void;
  handleRegist: () => void;
  handleClose: () => void;
  dialogOpen: boolean;
  errorAttribute: ErrorData;
}

const styles = {
  categoryHeader: {
    fontWeight: 'bold',
  },
  itemHeader: {
    backgroundColor: '#ececec',
    fontWeight: 'bold',
  },
  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
  },
  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 1,
    ml: 1,
  },
  label: {
    mt: '7px',
  },
  categoryTitle: {
    borderLeft: 5,
    borderColor: '#0058a0',
    pl: '10px',
  },
  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },
  listButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    m: 0,
  },
  fileLink: {
    mt: '7px',
    mr: '10px',
    wordBreak: 'break-all',
    overflowWrap: 'break-word',
  },
};

const AgentDialog: FC<Props> = ({
  user,
  inputData,
  companyList,
  branchList,
  mode,
  reservationDays,
  schoolConfirmed,
  canAllDept,
  handleChangeCompany,
  handleChangeBookingBranch,
  handleChangeSalesBranch,
  handleChangeAgentInput,
  handleChangeItinerary,
  handleDeleteItinerary,
  handleChangeSchoolConfirmationDoc,
  handleDeleteSchoolConfirmationDoc,
  handleChangeAgentConfirmationDoc,
  handleDeleteAgentConfirmationDoc,
  handleChangeCenterConfirmationDoc,
  handleDeleteCenterConfirmationDoc,
  handleRegist,
  handleClose,
  dialogOpen,
  errorAttribute,
}) => {
  return (
    <Dialog open={dialogOpen} onClose={handleClose} maxWidth={'md'}>
      <DialogTitle>
        代理店情報
        {errorAttribute.Header.ErrorMessage && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl component="fieldset" error={errorAttribute.Header.IsError}>
                <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                  {errorAttribute.Header.ErrorMessage}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          代理店情報
        </Typography>
        <Grid container spacing={2} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>
              代理店名{user.attributes['custom:authority_code'] === '1' && mode !== 'MODIFY' && ' *'}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            {user.attributes['custom:authority_code'] === '1' && mode !== 'MODIFY' ? (
              <SelectFormControl
                value={inputData.CompanyId ?? ''}
                selectName="CompanyId"
                selectValues={companyList.map((company, idx) => {
                  return { value: company.CompanyId.toString(), displayName: company.CompanyName } as SelectValue;
                })}
                setCondition={handleChangeCompany}
                sx={styles.inputItem}
                error={errorAttribute.CompanyId.IsError}
                errorMessage={errorAttribute.CompanyId.ErrorMessage}
              />
            ) : (
              <Typography sx={styles.label}>
                {companyList.find((x) => x.CompanyId === inputData.CompanyId)?.CompanyName}
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>手配担当店名 *</Typography>
          </Grid>
          <Grid item xs={9}>
            {user.attributes['custom:authority_code'] === '1' ||
            (['3', '5'].includes(user.attributes['custom:authority_code']) && canAllDept) ? (
              <SelectFormControl
                value={inputData.BookingBranchId ?? ''}
                selectName="BookingBranchId"
                selectValues={branchList.map((branch, idx) => {
                  return { value: branch.BranchId.toString(), displayName: branch.BranchName } as SelectValue;
                })}
                setCondition={handleChangeBookingBranch}
                sx={styles.inputItem}
                error={errorAttribute.BookingBranchId.IsError}
                errorMessage={errorAttribute.BookingBranchId.ErrorMessage}
              />
            ) : mode === 'MODIFY' ? (
              <Typography sx={styles.label}>
                {branchList.find((x) => x.BranchId === inputData.BookingBranchId)?.BranchName}
              </Typography>
            ) : (
              <Typography sx={styles.label}>
                {branchList.find((x) => x.BranchId === user.attributes['custom:branchid'])?.BranchName}
              </Typography>
            )}
          </Grid>

          {/* 旅行代理店の場合でも手配担当店の変更を制限しない場合、上記ブロックを以下に差し替える
          <Grid item xs={9}>
            <SelectFormControl
              value={inputData.BookingBranchId ?? ''}
              selectName="BookingBranchId"
              selectValues={branchList.map((branch, idx) => {
                return { value: branch.BranchId.toString(), displayName: branch.BranchName } as SelectValue;
              })}
              setCondition={handleChangeBookingBranch}
              sx={styles.inputItem}
              error={errorAttribute.BookingBranchId.IsError}
              errorMessage={errorAttribute.BookingBranchId.ErrorMessage}
            />
          </Grid>
          */}
          <Grid item xs={3}>
            <Typography sx={styles.label}>手配担当者名 *</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              required
              hiddenLabel
              name="BookingUserName"
              value={inputData.BookingUserName ?? ''}
              onChange={handleChangeAgentInput}
              size="small"
              sx={styles.inputItem}
              error={errorAttribute.BookingUserName.IsError}
              helperText={errorAttribute.BookingUserName.ErrorMessage}
              inputProps={{ maxLength: 40 }}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>手配担当TEL *</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              required
              hiddenLabel
              name="BookingUserTel"
              value={inputData.BookingUserTel ?? ''}
              onChange={handleChangeAgentInput}
              size="small"
              sx={styles.inputItem}
              error={errorAttribute.BookingUserTel.IsError}
              helperText={errorAttribute.BookingUserTel.ErrorMessage}
              inputProps={{ type: 'tel', maxLength: 13 }}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>
              手配担当Mail
              <br />
              （カンマ区切りで複数指定可）
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              required
              hiddenLabel
              name="BookingUserMail"
              value={inputData.BookingUserMail ?? ''}
              onChange={handleChangeAgentInput}
              size="small"
              sx={styles.inputItem}
              error={errorAttribute.BookingUserMail.IsError}
              helperText={errorAttribute.BookingUserMail.ErrorMessage}
              inputProps={{ type: 'email', maxLength: 255 }}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>営業担当店名 *</Typography>
          </Grid>

          <Grid item xs={9}>
            {user.attributes['custom:authority_code'] === '1' ||
            (['3', '5'].includes(user.attributes['custom:authority_code']) && canAllDept) ? (
              <SelectFormControl
                value={inputData.SalesBranchId ?? ''}
                selectName="SalesBranchId"
                selectValues={branchList.map((branch, idx) => {
                  return { value: branch.BranchId.toString(), displayName: branch.BranchName } as SelectValue;
                })}
                setCondition={handleChangeSalesBranch}
                sx={styles.inputItem}
                error={errorAttribute.SalesBranchId.IsError}
                errorMessage={errorAttribute.SalesBranchId.ErrorMessage}
              />
            ) : mode === 'MODIFY' ? (
              <Typography sx={styles.label}>
                {branchList.find((x) => x.BranchId === inputData.SalesBranchId)?.BranchName}
              </Typography>
            ) : (
              <Typography sx={styles.label}>
                {branchList.find((x) => x.BranchId === user.attributes['custom:branchid'])?.BranchName}
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>営業担当者名</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              required
              hiddenLabel
              name="SalesUserName"
              value={inputData.SalesUserName ?? ''}
              onChange={handleChangeAgentInput}
              size="small"
              sx={styles.inputItem}
              error={errorAttribute.SalesUserName.IsError}
              helperText={errorAttribute.SalesUserName.ErrorMessage}
              inputProps={{ maxLength: 40 }}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>営業担当者Tel</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              required
              hiddenLabel
              name="SalesUserTel"
              value={inputData.SalesUserTel ?? ''}
              onChange={handleChangeAgentInput}
              size="small"
              sx={styles.inputItem}
              error={errorAttribute.SalesUserTel.IsError}
              helperText={errorAttribute.SalesUserTel.ErrorMessage}
              inputProps={{ type: 'tel', maxLength: 13 }}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>
              営業担当Mail
              <br />
              （カンマ区切りで複数指定可）
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              required
              hiddenLabel
              name="SalesUserMail"
              value={inputData.SalesUserMail ?? ''}
              onChange={handleChangeAgentInput}
              size="small"
              sx={styles.inputItem}
              error={errorAttribute.SalesUserMail.IsError}
              helperText={errorAttribute.SalesUserMail.ErrorMessage}
              inputProps={{ type: 'email', maxLength: 255 }}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>行程表</Typography>
          </Grid>
          <Grid item xs={9}>
            {reservationDays.some((x) => ['5', '6'].includes(x.ReservationStatusCode!)) ? (
              <>
                {!!inputData.ItineraryName && (
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={styles.fileLink}
                    href={inputData.ItineraryLink!}
                    download={inputData.ItineraryName}
                  >
                    {inputData.ItineraryName}
                  </Link>
                )}
                {!!inputData.ItineraryName ? (
                  <Button variant="outlined" onClick={handleDeleteItinerary}>
                    削除
                  </Button>
                ) : (
                  <label htmlFor="itinerary">
                    <input id="itinerary" type="file" hidden onChange={handleChangeItinerary} />
                    <Button variant="outlined" component="span">
                      参照
                    </Button>
                  </label>
                )}
                <FormControl error={errorAttribute.Itinerary.IsError}>
                  <FormHelperText>{errorAttribute.Itinerary.ErrorMessage}</FormHelperText>
                </FormControl>
                <Typography variant="subtitle2">
                  ・アップロードできるファイルは、拡張子が「.doc(.docx)」、「.xls(.xlsx)」、「.pdf」のものに限ります。
                </Typography>
                <Typography variant="subtitle2">・ファイルサイズは、「500KB」以下にしてください。</Typography>
              </>
            ) : (
              <Link target="_blank" rel="noopener noreferrer" href={inputData.ItineraryLink!}>
                {inputData.ItineraryName}
              </Link>
            )}
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>
              学校決定書
              {schoolConfirmed &&
                reservationDays.some((x) => ['3', '4', '5', '6'].includes(x.ReservationStatusCode!)) &&
                ' *'}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            {reservationDays.some((x) => ['3', '4', '5', '6'].includes(x.ReservationStatusCode!)) ? (
              <>
                {!!inputData.SchoolConfirmationDocName && (
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={styles.fileLink}
                    href={inputData.SchoolConfirmationDocLink!}
                    download={inputData.SchoolConfirmationDocName}
                  >
                    {inputData.SchoolConfirmationDocName}
                  </Link>
                )}
                {!!inputData.SchoolConfirmationDocName ? (
                  <Button variant="outlined" onClick={handleDeleteSchoolConfirmationDoc}>
                    削除
                  </Button>
                ) : (
                  <label htmlFor="schoolConfirmationDoc">
                    <input id="schoolConfirmationDoc" type="file" hidden onChange={handleChangeSchoolConfirmationDoc} />
                    <Button variant="outlined" component="span">
                      参照
                    </Button>
                  </label>
                )}
                <FormControl error={errorAttribute.SchoolConfirmationDoc.IsError}>
                  <FormHelperText>{errorAttribute.SchoolConfirmationDoc.ErrorMessage}</FormHelperText>
                </FormControl>
                <Typography variant="subtitle2">
                  ・アップロードできるファイルは、拡張子が「.doc(.docx)」、「.xls(.xlsx)」、「.pdf」のものに限ります。
                </Typography>
                <Typography variant="subtitle2">・ファイルサイズは、「500KB」以下にしてください。</Typography>
              </>
            ) : (
              <Link target="_blank" rel="noopener noreferrer" href={inputData.SchoolConfirmationDocLink!}>
                {inputData.SchoolConfirmationDocName}
              </Link>
            )}
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>
              決定通知書{reservationDays.some((x) => ['5', '6'].includes(x.ReservationStatusCode!)) && ' *'}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            {reservationDays.some((x) => ['5', '6'].includes(x.ReservationStatusCode!)) ? (
              <>
                {!!inputData.AgentConfirmationDocName && (
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={styles.fileLink}
                    href={inputData.AgentConfirmationDocLink!}
                    download={inputData.AgentConfirmationDocName}
                  >
                    {inputData.AgentConfirmationDocName}
                  </Link>
                )}
                {!!inputData.AgentConfirmationDocName ? (
                  <Button variant="outlined" onClick={handleDeleteAgentConfirmationDoc}>
                    削除
                  </Button>
                ) : (
                  <label htmlFor="AgentConfirmationDoc">
                    <input id="AgentConfirmationDoc" type="file" hidden onChange={handleChangeAgentConfirmationDoc} />
                    <Button variant="outlined" component="span">
                      参照
                    </Button>
                  </label>
                )}
                <FormControl error={errorAttribute.AgentConfirmationDoc.IsError}>
                  <FormHelperText>{errorAttribute.AgentConfirmationDoc.ErrorMessage}</FormHelperText>
                </FormControl>
                <Typography variant="subtitle2">
                  ・アップロードできるファイルは、拡張子が「.doc(.docx)」、「.xls(.xlsx)」、「.pdf」のものに限ります。
                </Typography>
                <Typography variant="subtitle2">・ファイルサイズは、「500KB」以下にしてください。</Typography>
              </>
            ) : (
              <Link target="_blank" rel="noopener noreferrer" href={inputData.AgentConfirmationDocLink!}>
                {inputData.AgentConfirmationDocName}
              </Link>
            )}
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>
              決定確認書
              {user.attributes['custom:authority_code'] === '1' &&
                reservationDays.some((x) => ['6'].includes(x.ReservationStatusCode!)) &&
                ' *'}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            {user.attributes['custom:authority_code'] === '1' &&
            reservationDays.some((x) => ['6'].includes(x.ReservationStatusCode!)) ? (
              <>
                {!!inputData.CenterConfirmationDocName && (
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={styles.fileLink}
                    href={inputData.CenterConfirmationDocLink!}
                    download={inputData.CenterConfirmationDocName}
                  >
                    {inputData.CenterConfirmationDocName}
                  </Link>
                )}
                {!!inputData.CenterConfirmationDocName ? (
                  <Button variant="outlined" onClick={handleDeleteCenterConfirmationDoc}>
                    削除
                  </Button>
                ) : (
                  <label htmlFor="CenterConfirmationDoc">
                    <input id="CenterConfirmationDoc" type="file" hidden onChange={handleChangeCenterConfirmationDoc} />
                    <Button variant="outlined" component="span">
                      参照
                    </Button>
                  </label>
                )}
                <FormControl error={errorAttribute.CenterConfirmationDoc.IsError}>
                  <FormHelperText>{errorAttribute.CenterConfirmationDoc.ErrorMessage}</FormHelperText>
                </FormControl>
                <Typography variant="subtitle2">
                  ・アップロードできるファイルは、拡張子が「.doc(.docx)」、「.xls(.xlsx)」、「.pdf」のものに限ります。
                </Typography>
                <Typography variant="subtitle2">・ファイルサイズは、「500KB」以下にしてください。</Typography>
              </>
            ) : (
              <Link target="_blank" rel="noopener noreferrer" href={inputData.CenterConfirmationDocLink!}>
                {inputData.CenterConfirmationDocName}
              </Link>
            )}
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>依頼・要望</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              required
              hiddenLabel
              name="Note"
              value={inputData.Note ?? ''}
              onChange={handleChangeAgentInput}
              multiline
              rows="4"
              sx={styles.inputItem}
              error={errorAttribute.Note.IsError}
              helperText={errorAttribute.Note.ErrorMessage}
              inputProps={{ maxLength: 255 }}
            />
          </Grid>
        </Grid>

        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" sx={styles.button} onClick={handleRegist}>
              登録
            </Button>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" sx={styles.button} onClick={handleClose}>
              閉じる
            </Button>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
export default AgentDialog;
