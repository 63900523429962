import { useAuthenticator } from '@aws-amplify/ui-react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import MaterialDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { styled, useTheme } from '@mui/material/styles';
import { UserData } from 'models/UserData';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { checkAuthority } from 'util/AuthorityCheck';

export interface DrawerHandles {
  handleDrawerOpen(): void;
  handleDrawerClose(): void;
}

const drawerWidth = 240;

const StyledDrawer = styled(MaterialDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(0),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(0),
      },
    }),
  },
}));

const Drawer: FC<any> = ({ toggleDrawer, drawerOpen }) => {
  const theme = useTheme();
  const [adminSettingOpen, setAdminSettingOpen] = useState(false);
  const [feeListOpen, setFeeListOpen] = useState(false);
  const [feeOneOpen, setFeeOneOpen] = useState(false);
  const [feeTwoOpen, setFeeTwoOpen] = useState(false);
  const [feeThreeOpen, setFeeThreeOpen] = useState(false);
  const [reserveSettingOpen, setReserveSettingOpen] = useState(false);
  const { user } = useAuthenticator();
  const userData = new UserData(user);

  const handleAdminSettingClick = () => {
    setAdminSettingOpen(!adminSettingOpen);
  };

  const handleFeeListClick = () => {
    setFeeListOpen(!feeListOpen);
  };
  const handleFeeOneClick = () => {
    setFeeOneOpen(!feeOneOpen);
  };

  const handleFeeTwoClick = () => {
    setFeeTwoOpen(!feeTwoOpen);
  };
  const handleFeeThreeClick = () => {
    setFeeThreeOpen(!feeThreeOpen);
  };

  const handleReserveSettingClick = () => {
    setReserveSettingOpen(!reserveSettingOpen);
  };

  const listStyle = {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  };

  const canSettings = (): boolean => {
    return (
      checkAuthority('SchoolList', userData.authorityCode) ||
      checkAuthority('UserList', userData.authorityCode) ||
      checkAuthority('CompanyList', userData.authorityCode) ||
      canReserveSettings()
    );
  };

  const canReserveSettings = (): boolean => {
    return (
      checkAuthority('ReserveAreaList', userData.authorityCode) ||
      checkAuthority('ServiceList', userData.authorityCode) ||
      checkAuthority('ReserveFrameSettingList', userData.authorityCode)
    );
  };

  return (
    <>
      <StyledDrawer variant="permanent" open={!drawerOpen}>
        <List>
          {checkAuthority('Home', userData.authorityCode) && (
            <Link to="/" style={listStyle}>
              <ListItem button>
                <ListItemText primary="HOME" />
              </ListItem>
            </Link>
          )}
          {checkAuthority('ReserveLedgerSearch', userData.authorityCode) && (
            <Link to="/reserve/ledger/search" style={listStyle}>
              <ListItem button>
                <ListItemText primary="予約台帳" />
              </ListItem>
            </Link>
          )}
          {checkAuthority('ReserveDateList', userData.authorityCode) && (
            <Link to="/reserve/reception/datelist" style={listStyle}>
              <ListItem button>
                <ListItemText primary="予約日程一覧" />
              </ListItem>
            </Link>
          )}
          {checkAuthority('ReserveList', userData.authorityCode) && (
            <Link to="/reserve/management/list" style={listStyle}>
              <ListItem button>
                <ListItemText primary="予約一覧（本決定のみ）" />
              </ListItem>
            </Link>
          )}
          {checkAuthority('MinkaAllocateLedgerSearch', userData.authorityCode) && (
            <Link to="/minka/ledger/search" style={listStyle}>
              <ListItem button>
                <ListItemText primary="民家割" />
              </ListItem>
            </Link>
          )}
          {checkAuthority('MinkaList', userData.authorityCode) && (
            <Link to="/minka/management/list" style={listStyle}>
              <ListItem button>
                <ListItemText primary="民家管理" />
              </ListItem>
            </Link>
          )}
          {canSettings() && (
            <>
              <ListItemButton onClick={handleAdminSettingClick}>
                <ListItemText primary="管理者設定" />
                {adminSettingOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={adminSettingOpen} timeout="auto" unmountOnExit sx={{ pl: '15px' }}>
                <List component="div">
                  {canReserveSettings() && (
                    <>
                      <ListItemButton onClick={handleReserveSettingClick}>
                        <ListItemText primary="予約設定" />
                        {reserveSettingOpen ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      <Collapse in={reserveSettingOpen} timeout="auto" unmountOnExit sx={{ pl: '15px' }}>
                        <List component="div">
                          {checkAuthority('ReserveAreaList', userData.authorityCode) && (
                            <Link to="/settings/reserve/arealist" style={listStyle}>
                              <ListItem button>
                                <ListItemText primary="予約枠（地区）一覧" />
                              </ListItem>
                            </Link>
                          )}
                          {checkAuthority('ServiceList', userData.authorityCode) && (
                            <Link to="/settings/service/list" style={listStyle}>
                              <ListItem button>
                                <ListItemText primary="サービス一覧" />
                              </ListItem>
                            </Link>
                          )}
                          {checkAuthority('ReserveFrameSettingList', userData.authorityCode) && (
                            <Link to="/settings/reserve/framelist" style={listStyle}>
                              <ListItem button>
                                <ListItemText primary="予約枠公開設定一覧" />
                              </ListItem>
                            </Link>
                          )}
                        </List>
                      </Collapse>
                    </>
                  )}
                  {checkAuthority('SchoolList', userData.authorityCode) && (
                    <Link to="/settings/school/list" style={listStyle}>
                      <ListItem button>
                        <ListItemText primary="学校管理" />
                      </ListItem>
                    </Link>
                  )}
                  {checkAuthority('CompanyList', userData.authorityCode) && (
                    <Link to="/settings/company/list" style={listStyle}>
                      <ListItem button>
                        <ListItemText primary="会社管理" />
                      </ListItem>
                    </Link>
                  )}
                  {checkAuthority('UserList', userData.authorityCode) && (
                    <Link to="/settings/user/list" style={listStyle}>
                      <ListItem button>
                        <ListItemText primary="利用者管理" />
                      </ListItem>
                    </Link>
                  )}
                </List>
              </Collapse>
            </>
          )}
          {checkAuthority('AgentDetail', userData.authorityCode) && (
            <Link to="/settings/company/detail?mode=agent" style={listStyle}>
              <ListItem button>
                <ListItemText primary="会社管理" />
              </ListItem>
            </Link>
          )}
          {checkAuthority('AccountSetting', userData.authorityCode) && (
            <Link to="/account" style={listStyle}>
              <ListItem button>
                <ListItemText primary="アカウント設定" />
              </ListItem>
            </Link>
          )}
          <Link
            to=""
            style={listStyle}
            onClick={() => {
              window.open('https://tmp.nicoplus.ltd/', 'tm-manual');
              return false;
            }}
          >
            <ListItem button>
              <ListItemText primary="操作マニュアル" />
            </ListItem>
          </Link>

          <Link
            to=""
            style={listStyle}
            onClick={() => {
              window.open('https://tmp.co.jp/privatehouse/#rink002', 'tm-pamphlet');
              return false;
            }}
          >
            <ListItem button>
              <ListItemText primary="パンフレット" />
            </ListItem>
          </Link>

          <ListItemButton onClick={handleFeeListClick}>
            <ListItemText primary="料金表" />
            {feeListOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={feeListOpen} timeout="auto" unmountOnExit sx={{ pl: '15px' }}>
            <List component="div">
              <ListItemButton onClick={handleFeeOneClick}>
                <ListItemText primary="2024年度" />
                {feeOneOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={feeOneOpen} timeout="auto" unmountOnExit sx={{ pl: '15px' }}>
                <List component="div">
                  <Link
                    to=""
                    style={listStyle}
                    onClick={() => {
                      window.open(
                        'https://tmp.nicoplus.ltd/wp-content/uploads/2024/03/4d059f344b23096dc1dd8ff4311a69c8.pdf',
                        'tm-pamphlet'
                      );
                      return false;
                    }}
                  >
                    <ListItem button>
                      <ListItemText primary="伊江島" />
                    </ListItem>
                  </Link>
                  <Link
                    to=""
                    style={listStyle}
                    onClick={() => {
                      window.open(
                        'https://tmp.nicoplus.ltd/wp-content/uploads/2024/03/5332652b0618268748b2ae90a84b5f74.pdf',
                        'tm-pamphlet'
                      );
                      return false;
                    }}
                  >
                    <ListItem button>
                      <ListItemText primary="沖縄本島" />
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
              <ListItemButton onClick={handleFeeTwoClick}>
                <ListItemText primary="2025年度" />
                {feeTwoOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={feeTwoOpen} timeout="auto" unmountOnExit sx={{ pl: '15px' }}>
                <List component="div">
                  <Link
                    to=""
                    style={listStyle}
                    onClick={() => {
                      window.open(
                        'https://tmp.nicoplus.ltd/wp-content/uploads/2024/03/8e2312bb6be0a20e44f26c83a44041df.pdf',
                        'tm-pamphlet'
                      );
                      return false;
                    }}
                  >
                    <ListItem button>
                      <ListItemText primary="伊江島" />
                    </ListItem>
                  </Link>
                  <Link
                    to=""
                    style={listStyle}
                    onClick={() => {
                      window.open(
                        'https://tmp.nicoplus.ltd/wp-content/uploads/2024/03/dc902f34308a93ebb3b454539f0cd17e.pdf',
                        'tm-pamphlet'
                      );
                      return false;
                    }}
                  >
                    <ListItem button>
                      <ListItemText primary="沖縄本島" />
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
              <ListItemButton onClick={handleFeeThreeClick}>
                <ListItemText primary="2026年度" />
                {feeThreeOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={feeThreeOpen} timeout="auto" unmountOnExit sx={{ pl: '15px' }}>
                <List component="div">
                  <Link
                    to=""
                    style={listStyle}
                    onClick={() => {
                      window.open(
                        'https://tmp.nicoplus.ltd/wp-content/uploads/2024/03/ced5194d265645cab20c65953107f396.pdf',
                        'tm-pamphlet'
                      );
                      return false;
                    }}
                  >
                    <ListItem button>
                      <ListItemText primary="伊江島" />
                    </ListItem>
                  </Link>
                  <Link
                    to=""
                    style={listStyle}
                    onClick={() => {
                      window.open(
                        'https://tmp.nicoplus.ltd/wp-content/uploads/2024/03/8ee1aee3208dc6859be2b7b0fc10c79e.pdf',
                        'tm-pamphlet'
                      );
                      return false;
                    }}
                  >
                    <ListItem button>
                      <ListItemText primary="沖縄本島" />
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
            </List>
          </Collapse>
        </List>
      </StyledDrawer>
    </>
  );
};

export default Drawer;
