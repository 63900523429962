import { ApplySchoolDecisionMutation, InitApplySchoolDecisionQuery, RegistApplySchoolDecision } from 'API';
import { useAPI } from 'contexts/APIRequestContext';
import { applySchoolDecision } from 'graphql/mutations';
import { initApplySchoolDecision } from 'graphql/queries';
import React, { useCallback, useEffect, useState } from 'react';
import { CreateEmptyErrorData, ErrorData } from './SchoolDecisionApplicationDialogErrorData';

const UseSchoolDecisionApplicationDialog = ({
  handleDialogClose,
  dialogOpen,
  reservationId,
  updateDatetime,
}: {
  handleDialogClose: (_: boolean) => void;
  dialogOpen: boolean;
  reservationId: string;
  updateDatetime: string;
}) => {
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [inputData, setInputData] = useState({} as RegistApplySchoolDecision);
  const [reservedDays, setReservedDays] = useState([] as string[]);
  const api = useAPI();
  const [, setError] = useState<undefined>();

  useEffect(() => {
    if (dialogOpen) {
      // 初期化
      setErrorAttribute({ ...CreateEmptyErrorData() });

      executeInitApplyReservation();
    } else {
      setInputData({ ReservedDays: [] as string[] } as RegistApplySchoolDecision);
    }
  }, [dialogOpen]);

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  /**
   * 学校決定申請初期処理
   */
  const executeInitApplyReservation = async (): Promise<void> => {
    const response = (
      (await api.graphql({
        query: initApplySchoolDecision,
        variables: {
          data: {
            ReservationId: reservationId,
            UpdateDatetime: updateDatetime,
          },
        },
      })) as InitApplySchoolDecisionQuery
    ).initApplySchoolDecision;

    if (response?.IsSuccess) {
      setReservedDays(response.Body?.ReservedDays!);
    } else if (response?.IsSystemError) {
      throwError(response.ErrorData ?? '');
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...errorAttribute,
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  };

  /**
   * 宿泊日変更時
   * @param value チェックON/OFF
   * @param idx インデックス
   */
  const handleChangeReservedDay = (value: boolean, idx: number): void => {
    if (value) {
      inputData.ReservedDays!.push(reservedDays[idx]);
    } else {
      inputData.ReservedDays!.splice(inputData.ReservedDays!.indexOf(reservedDays[idx]), 1);
    }

    setInputData(inputData);
    setErrorAttribute({
      ...errorAttribute,
      ReservedDays: { IsError: false, ErrorMessage: '' },
    });
  };

  /**
   * 学校決定通知書変更時
   * @param event イベント
   */
  const handleChangeSchoolConfirmationDoc = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files && event.target.files.length) {
      if (event.target.files[0].size > 500 * 1024) {
        setErrorAttribute({
          ...errorAttribute,
          SchoolConfirmationDoc: { IsError: true, ErrorMessage: 'ファイルサイズは、「500KB」以下にしてください。' },
        });
        return;
      } else {
        setErrorAttribute({
          ...errorAttribute,
          SchoolConfirmationDoc: { IsError: false, ErrorMessage: '' },
        });
      }

      inputData.SchoolConfirmationDocName = event.target.files[0].name;

      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        setInputData({
          ...inputData,
          SchoolConfirmationDocName: inputData.SchoolConfirmationDocName,
          SchoolConfirmationDoc: (reader.result as string).split(';base64,').pop(),
        });
      };
    }
  };

  /**
   * 学校決定通知書削除時
   */
  const handleDeleteSchoolConfirmationDoc = (): void => {
    setInputData({ ...inputData, SchoolConfirmationDoc: '', SchoolConfirmationDocName: '' });
    setErrorAttribute({
      ...errorAttribute,
      SchoolConfirmationDoc: { IsError: false, ErrorMessage: '' },
    });
  };

  /**
   * 閉じる
   */
  const handleClose = (): void => {
    handleDialogClose(false);
  };

  /**
   * 学校決定申請を行う
   */
  const executeApplySchoolDecision = async (): Promise<void> => {
    const response = (
      (await api.graphql({
        query: applySchoolDecision,
        variables: {
          data: {
            ReservationId: reservationId,
            ReservedDays: inputData.ReservedDays,
            SchoolConfirmationDocName: inputData.SchoolConfirmationDocName,
            SchoolConfirmationDoc: inputData.SchoolConfirmationDoc,
            UpdateDatetime: updateDatetime,
          },
        },
      })) as ApplySchoolDecisionMutation
    ).applySchoolDecision;

    if (response?.IsSuccess) {
      handleDialogClose(true);
    } else if (response?.IsSystemError) {
      throwError(response.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      } as ErrorData);
    } else {
      throwError();
    }
  };

  /**
   * 次へ
   */
  const handleRegist = async (): Promise<void> => {
    executeApplySchoolDecision();
  };

  return {
    errorAttribute,
    dialogOpen,
    inputData,
    reservedDays,
    handleChangeReservedDay,
    handleChangeSchoolConfirmationDoc,
    handleDeleteSchoolConfirmationDoc,
    handleClose,
    handleRegist,
  };
};
export default UseSchoolDecisionApplicationDialog;
