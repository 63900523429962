import { Container } from '@mui/material';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List/List';
import ListItem from '@mui/material/ListItem/ListItem';
import TextField from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography/Typography';
import { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import GenericTemplate from '../../template/GenericTemplate';

const styles = {
  categoryTitle: {
    borderLeft: 5,
    borderColor: '#0058a0',
    pl: '10px',
    mb: '30px',
  },

  loadButton: {
    minWidth: '120px',
    fontWeight: 'bold',
    ml: 1,
  },

  button: {
    minWidth: '80px',
    fontWeight: 'bold',
  },

  fileName: {
    width: '60%',
    float: 'right',
    border: 'solid 1px #bdbdbd',
    display: 'flex',
    borderRadius: '5px',
    minHeight: 42,
    padding: 0,
    textAlign: 'center',
    '& input': {
      padding: '9px 14px',
    },
  },

  boxRoot: {
    margin: 'auto',
    paddingTop: '30px',
    paddingBottom: '50px',
    maxWidth: '1000px',
  },
};

export interface Props {}

const MinkaAllocateLedgerRegister: FC<Props> = () => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const files = acceptedFiles.map((file) => <ListItem key={file.name}>{file.name}</ListItem>);

  const pushback = function () {
    window.history.back();
  };

  // @ts-ignore
  return (
    <GenericTemplate title="民家割登録">
      <Container>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          民家割情報をアップロードします。
        </Typography>
        <Grid container>
          <Grid item xs={7}>
            {acceptedFiles.length > 1 ? (
              <TextField sx={styles.fileName} value="Error! Only one File allowed." disabled />
            ) : (
              <List sx={styles.fileName}>{files}</List>
            )}
          </Grid>
          <Grid item xs={3} style={{ paddingLeft: 30 }}>
            <Button variant="contained" sx={styles.button} {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              {files.length > 0 && acceptedFiles.length <= 1 ? (
                <Typography>削除</Typography>
              ) : (
                <Typography>参照</Typography>
              )}
            </Button>
          </Grid>
        </Grid>
        <Box sx={styles.boxRoot}>
          <Grid container>
            <Typography style={{ fontSize: '15px' }}>
              * アップロードできるファイルは、拡張子が「.csv」のものに限ります。
            </Typography>
          </Grid>
          <Grid container>
            <Typography style={{ fontSize: '15px' }}>
              * 一度にアップロードする民家割情報は、同一の予約 ID としてください。
            </Typography>
          </Grid>
          <Grid container>
            <Typography style={{ fontSize: '15px' }}>
              *アップロードするファイルの記入方法は、サンプルを参考にしてください。下記の「サンプルダウンロード」ボタンよりダウンロードできます。
            </Typography>
          </Grid>
          <Grid container>
            <Typography style={{ fontSize: '15px' }}>
              * 記入項目は次の通りです。必須とある項目は入力がないとエラーになります。
            </Typography>
          </Grid>
          <Grid container style={{ paddingLeft: '20px' }}>
            <Typography style={{ fontSize: '15px' }}>* 1列目：予約ID（数値）必須</Typography>
          </Grid>
          <Grid container style={{ paddingLeft: '20px' }}>
            <Typography style={{ fontSize: '15px' }}>* 2列目：民家ID（数値）必須</Typography>
          </Grid>
          <Grid container style={{ paddingLeft: '20px' }}>
            <Typography style={{ fontSize: '15px' }}>* 3列目：組（数値）</Typography>
          </Grid>
          <Grid container style={{ paddingLeft: '20px' }}>
            <Typography style={{ fontSize: '15px' }}>* 4列目：班（文字列）</Typography>
          </Grid>
          <Grid container style={{ paddingLeft: '20px' }}>
            <Typography style={{ fontSize: '15px' }}>* 5列目：メモ（文字列）</Typography>
          </Grid>
          <Grid container style={{ paddingLeft: '20px' }}>
            <Typography style={{ fontSize: '15px' }}>* 6列目：生徒割りフラグ（1:固定）</Typography>
          </Grid>
          <Grid container style={{ paddingLeft: '20px' }}>
            <Typography style={{ fontSize: '15px' }}>* 7列目：キャンセルフラグ（0:固定）</Typography>
          </Grid>
          <Grid container style={{ paddingLeft: '20px' }}>
            <Typography style={{ fontSize: '15px' }}>* 8列目：変更フラグ（0:固定）</Typography>
          </Grid>
          <Grid container>
            <Typography style={{ fontSize: '15px' }}>
              * 一行目はヘッダーです。2行目より生徒情報を記入してください。
            </Typography>
          </Grid>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" sx={styles.loadButton}>
            サンプルダウンロード
          </Button>
          <Button variant="contained" sx={styles.loadButton}>
            アップロード
          </Button>
          <Button variant="contained" sx={styles.loadButton} onClick={pushback}>
            閉じる
          </Button>
        </Box>
      </Container>
    </GenericTemplate>
  );
};

export default MinkaAllocateLedgerRegister;
