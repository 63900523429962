// 1:予約管理者
// 2:民泊受入管理者
// 3:旅行代理店仕入
// 4:旅行代理店営業
// 5:旅行代理店仕入兼営業
// 6:サービス提供事業者
// 7:学校

let AuthorityTable = new Map([
  [
    'Home',
    new Map([
      ['1', true],
      ['2', true],
      ['3', true],
      ['4', true],
      ['5', true],
      ['6', true],
      ['7', true],
    ]),
  ],
  [
    'AccountSetting',
    new Map([
      ['1', true],
      ['2', true],
      ['3', true],
      ['4', true],
      ['5', true],
      ['6', true],
      ['7', true],
    ]),
  ],
  [
    'ReserveLedgerSearch',
    new Map([
      ['1', true],
      ['2', true],
      ['3', true],
      ['4', true],
      ['5', true],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'ReserveLedger',
    new Map([
      ['1', true],
      ['2', true],
      ['3', true],
      ['4', true],
      ['5', true],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'ReserveRegist',
    new Map([
      ['1', true],
      ['2', true],
      ['3', true],
      ['4', true],
      ['5', true],
      ['6', true],
      ['7', true],
    ]),
  ],
  [
    'GuestInfoDialog',
    new Map([
      ['1', true],
      ['2', false],
      ['3', true],
      ['4', false],
      ['5', true],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'GuestCountDialog',
    new Map([
      ['1', true],
      ['2', false],
      ['3', true],
      ['4', false],
      ['5', true],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'PlanDialog',
    new Map([
      ['1', true],
      ['2', false],
      ['3', true],
      ['4', false],
      ['5', true],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'OptionDialog',
    new Map([
      ['1', true],
      ['2', false],
      ['3', true],
      ['4', false],
      ['5', true],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'AcceptCompanyInfoDialog',
    new Map([
      ['1', true],
      ['2', false],
      ['3', true],
      ['4', false],
      ['5', true],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'CancelReasonDialog',
    new Map([
      ['1', true],
      ['2', false],
      ['3', true],
      ['4', false],
      ['5', true],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'SchoolSearchDialog',
    new Map([
      ['1', true],
      ['2', false],
      ['3', true],
      ['4', false],
      ['5', true],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'AgentDialog',
    new Map([
      ['1', true],
      ['2', false],
      ['3', true],
      ['4', false],
      ['5', true],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'ReserveDecideApplication',
    new Map([
      ['1', true],
      ['2', false],
      ['3', true],
      ['4', false],
      ['5', true],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'SchoolDecideApplication',
    new Map([
      ['1', true],
      ['2', false],
      ['3', true],
      ['4', false],
      ['5', true],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'ReserveDateList',
    new Map([
      ['1', true],
      ['2', false],
      ['3', true],
      ['4', true],
      ['5', true],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'ReserveList',
    new Map([
      ['1', true],
      ['2', true],
      ['3', true],
      ['4', true],
      ['5', true],
      ['6', false],
      ['7', true],
    ]),
  ],
  [
    'StudentAllocateLedger',
    new Map([
      ['1', false],
      ['2', true],
      ['3', false],
      ['4', true],
      ['5', true],
      ['6', false],
      ['7', true],
    ]),
  ],
  [
    'StudentAllocation',
    new Map([
      ['1', false],
      ['2', true],
      ['3', false],
      ['4', true],
      ['5', true],
      ['6', false],
      ['7', true],
    ]),
  ],
  [
    'MinkaChange',
    new Map([
      ['1', false],
      ['2', true],
      ['3', false],
      ['4', false],
      ['5', false],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'StudentList',
    new Map([
      ['1', false],
      ['2', true],
      ['3', false],
      ['4', true],
      ['5', true],
      ['6', false],
      ['7', true],
    ]),
  ],
  [
    'StudentDetail',
    new Map([
      ['1', false],
      ['2', true],
      ['3', false],
      ['4', true],
      ['5', true],
      ['6', false],
      ['7', true],
    ]),
  ],
  [
    'StudentBulkInsert',
    new Map([
      ['1', false],
      ['2', true],
      ['3', false],
      ['4', true],
      ['5', true],
      ['6', false],
      ['7', true],
    ]),
  ],
  [
    'AcceptMinkaAllDeliver',
    new Map([
      ['1', false],
      ['2', true],
      ['3', false],
      ['4', false],
      ['5', false],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'MinkaAllocateLedgerSearch',
    new Map([
      ['1', false],
      ['2', true],
      ['3', false],
      ['4', false],
      ['5', false],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'MinkaAllocateLedger',
    new Map([
      ['1', false],
      ['2', true],
      ['3', false],
      ['4', false],
      ['5', false],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'MinkaList',
    new Map([
      ['1', false],
      ['2', true],
      ['3', false],
      ['4', false],
      ['5', false],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'MinkaDetail',
    new Map([
      ['1', false],
      ['2', true],
      ['3', false],
      ['4', false],
      ['5', false],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'HousemateDetail',
    new Map([
      ['1', false],
      ['2', true],
      ['3', false],
      ['4', false],
      ['5', false],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'CarDetail',
    new Map([
      ['1', false],
      ['2', true],
      ['3', false],
      ['4', false],
      ['5', false],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'ClosedMonthDetail',
    new Map([
      ['1', false],
      ['2', true],
      ['3', false],
      ['4', false],
      ['5', false],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'ReserveAreaList',
    new Map([
      ['1', true],
      ['2', false],
      ['3', false],
      ['4', false],
      ['5', false],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'ReserveAreaDetail',
    new Map([
      ['1', true],
      ['2', false],
      ['3', false],
      ['4', false],
      ['5', false],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'AreaManagementSelect',
    new Map([
      ['1', true],
      ['2', false],
      ['3', false],
      ['4', false],
      ['5', false],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'ServiceSelect',
    new Map([
      ['1', true],
      ['2', false],
      ['3', false],
      ['4', false],
      ['5', false],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'ServiceList',
    new Map([
      ['1', true],
      ['2', false],
      ['3', false],
      ['4', false],
      ['5', false],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'ServiceDetail',
    new Map([
      ['1', true],
      ['2', false],
      ['3', false],
      ['4', false],
      ['5', false],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'PriceSetting',
    new Map([
      ['1', true],
      ['2', false],
      ['3', false],
      ['4', false],
      ['5', false],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'ReserveFrameSettingList',
    new Map([
      ['1', true],
      ['2', true],
      ['3', false],
      ['4', false],
      ['5', false],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'ReserveFrameRegist',
    new Map([
      ['1', true],
      ['2', true],
      ['3', false],
      ['4', false],
      ['5', false],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'ReserveFrameEdit',
    new Map([
      ['1', true],
      ['2', true],
      ['3', false],
      ['4', false],
      ['5', false],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'AcceptAdjustment',
    new Map([
      ['1', true],
      ['2', true],
      ['3', false],
      ['4', false],
      ['5', false],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'AcceptPeriodAdjustment',
    new Map([
      ['1', true],
      ['2', true],
      ['3', false],
      ['4', false],
      ['5', false],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'SchoolList',
    new Map([
      ['1', true],
      ['2', false],
      ['3', false],
      ['4', false],
      ['5', false],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'SchoolDetail',
    new Map([
      ['1', true],
      ['2', false],
      ['3', false],
      ['4', false],
      ['5', false],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'CompanyList',
    new Map([
      ['1', true],
      ['2', false],
      ['3', false],
      ['4', false],
      ['5', false],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'CompanyDetail',
    new Map([
      ['1', true],
      ['2', false],
      ['3', false],
      ['4', false],
      ['5', false],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'AgentDetail',
    new Map([
      ['1', false],
      ['2', false],
      ['3', true],
      ['4', false],
      ['5', true],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'BranchInfo',
    new Map([
      ['1', true],
      ['2', false],
      ['3', true],
      ['4', false],
      ['5', true],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'UserList',
    new Map([
      ['1', true],
      ['2', false],
      ['3', false],
      ['4', false],
      ['5', false],
      ['6', false],
      ['7', false],
    ]),
  ],
  [
    'UserDetail',
    new Map([
      ['1', true],
      ['2', false],
      ['3', false],
      ['4', false],
      ['5', false],
      ['6', false],
      ['7', false],
    ]),
  ],
]);

export const checkAuthority = (screenId: string, authorityCode: string): boolean => {
  return AuthorityTable.get(screenId)?.get(authorityCode) ?? false;
};
