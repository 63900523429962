import FormErrorAttribute from 'types/FormErrorAttribute';

export interface ErrorData {
  Header: FormErrorAttribute;
  CompanyId: FormErrorAttribute;
  ServiceCategoryCode: FormErrorAttribute;
  ServiceName: FormErrorAttribute;
  ServiceDisplayName: FormErrorAttribute;
  ReservationStartDays: FormErrorAttribute;
  IsAdmin: FormErrorAttribute;
  StayNights: FormErrorAttribute;
  Meals: FormErrorAttribute;
  OfferingHours: FormErrorAttribute;
  OfferingDayDiv: FormErrorAttribute;
  FromDate: FormErrorAttribute;
  ToDate: FormErrorAttribute;
  DisplayOrder: FormErrorAttribute;
  IsActive: FormErrorAttribute;
}

export const CreateEmptyErrorData = () => {
  return {
    Header: {} as FormErrorAttribute,
    CompanyId: {} as FormErrorAttribute,
    ServiceCategoryCode: {} as FormErrorAttribute,
    ServiceName: {} as FormErrorAttribute,
    ServiceDisplayName: {} as FormErrorAttribute,
    ReservationStartDays: {} as FormErrorAttribute,
    IsAdmin: {} as FormErrorAttribute,
    StayNights: {} as FormErrorAttribute,
    Meals: {} as FormErrorAttribute,
    OfferingHours: {} as FormErrorAttribute,
    OfferingDayDiv: {} as FormErrorAttribute,
    FromDate: {} as FormErrorAttribute,
    ToDate: {} as FormErrorAttribute,
    DisplayOrder: {} as FormErrorAttribute,
    IsActive: {} as FormErrorAttribute,
  } as ErrorData;
};

