import { FC } from 'react';
import GenericTemplate from '../../../template/GenericTemplate';
import React from 'react';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import Grid from '@mui/material/Grid/Grid';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import Button from '@mui/material/Button/Button';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import TableBody from '@mui/material/TableBody/TableBody';
import TextField from '@mui/material/TextField/TextField';
import Box from '@mui/material/Box/Box';
import { Link } from 'react-router-dom';
import { Area } from '../../../../API';
import { ErrorData } from './ReserveFrameRegistErrorData';

const queryString = require('query-string');

const styles = {
  searchField: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
  },

  label: {
    margin: 'auto',
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
  },

  categoryHeader: {
    fontWeight: 'bold',
  },

  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
  },

  selectButton: {
    minWidth: '120px',
    fontWeight: 'bold',
    ml: 1,
    mt: 1,
    float: 'right',
  },

  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    ml: 1,
    mt: 1,
  },

  table: {
    textAlign: 'center',
    border: '1px solid #000',
    '& > tbody > tr > td': {
      border: '1px solid #000',
      textAlign: 'center',
    },
    '& > thead > tr > th': {
      border: '1px solid #000',
    },
  },

  tableRow: {
    border: '1px solid #000',
  },

  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },

  textField: {
    width: '100%',
    '& input': {
      padding: '8px 14px',
    },
  },
};

export interface Props {
  areaReservationFrameData: Area;
  errorAttribute: ErrorData;
  year: any;
  month: number;
  firstDate: Date;
  lastDate: Date;
  numDays: number;
  calendar: any[];
  checkedDay: number[];
  handleChangeDetail: (_: React.ChangeEvent<HTMLInputElement>) => void;
  makeCalendarData: () => void;
  handleCheckedDay: (_: React.ChangeEvent<HTMLInputElement>) => void;
  selectAllDay: () => void;
  deselectAllDay: () => void;
  handleClickRegist: () => void;
}

const ReserveFrameRegist: FC<Props> = (props) => {
  const renderTableBody = () => {
    props.makeCalendarData();
    return (
      <TableBody>
        {props.calendar.map((row, idx: number) => {
          return (
            <TableRow key={idx}>
              {row.map((item: never, index: number) => {
                return (
                  <TableCell align="center" key={index}>
                    {item}
                    <br />
                    {item !== '' ? (
                      <Checkbox
                        onChange={props.handleCheckedDay}
                        checked={props.checkedDay.indexOf(item) > -1}
                        value={item}
                      />
                    ) : null}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  return (
    <GenericTemplate title="予約枠設定">
      <Container sx={styles.searchField}>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          予約枠設定：{props.year + '年' + props.month + '月' + '（' + props.areaReservationFrameData.AreaName + '）'}
        </Typography>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>最大受入人数</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'MaxGuestCount'}
              value={props.areaReservationFrameData.MaxGuestCount}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="number"
              error={props.errorAttribute.MaxGuestCount.IsError}
              helperText={props.errorAttribute.MaxGuestCount.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>同時受入校数</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'MaxSchoolCount'}
              value={props.areaReservationFrameData.MaxSchoolCount}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="number"
              error={props.errorAttribute.MaxSchoolCount.IsError}
              helperText={props.errorAttribute.MaxSchoolCount.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>同時受入人数</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'MaxMultiSchoolGuestCount'}
              value={props.areaReservationFrameData.MaxMultiSchoolGuestCount}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="number"
              error={props.errorAttribute.MaxMultiSchoolGuestCount.IsError}
              helperText={props.errorAttribute.MaxMultiSchoolGuestCount.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>入村時間</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'CheckInTime'}
              value={props.areaReservationFrameData.CheckInTime}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="time"
              error={props.errorAttribute.CheckInTime.IsError}
              helperText={props.errorAttribute.CheckInTime.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>退村時間</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'CheckOutTime'}
              value={props.areaReservationFrameData.CheckOutTime}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="time"
              error={props.errorAttribute.CheckOutTime.IsError}
              helperText={props.errorAttribute.CheckOutTime.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>連泊入村時間</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'MultiStayCheckInTime'}
              value={props.areaReservationFrameData.MultiStayCheckInTime}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="time"
              error={props.errorAttribute.MultiStayCheckInTime.IsError}
              helperText={props.errorAttribute.MultiStayCheckInTime.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>連泊退村時間</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'MultiStayCheckOutTime'}
              value={props.areaReservationFrameData.MultiStayCheckOutTime}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              type="time"
              error={props.errorAttribute.MultiStayCheckOutTime.IsError}
              helperText={props.errorAttribute.MultiStayCheckOutTime.ErrorMessage}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', marginTop: '20px' }}>
          <Grid container spacing={0.5} sx={styles.categoryBlock}>
            <Grid item xs={9}>
              <Typography variant="h6" gutterBottom component="div" sx={{ marginLeft: '10px' }}>
                休業日を選択してください。
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" sx={styles.selectButton} onClick={props.deselectAllDay}>
                全てクリア
              </Button>
              <Button variant="contained" sx={styles.selectButton} onClick={props.selectAllDay}>
                全て選択
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={12}>
            <TableContainer sx={{ minHeight: 300 }}>
              <Table sx={styles.table} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: 'red', fontWeight: 'bold' }} align="center">
                      日
                    </TableCell>
                    <TableCell align="center">月</TableCell>
                    <TableCell align="center">火</TableCell>
                    <TableCell align="center">水</TableCell>
                    <TableCell align="center">木</TableCell>
                    <TableCell align="center">金</TableCell>
                    <TableCell sx={{ color: 'blue', fontWeight: 'bold' }} align="center">
                      土
                    </TableCell>
                  </TableRow>
                </TableHead>
                {renderTableBody()}
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'center', '& > a': { textDecoration: 'none' } }}>
          <Button variant="contained" sx={styles.button} onClick={props.handleClickRegist}>
            登録
          </Button>
          <Link to="/settings/reserve/framelist">
            <Button variant="contained" sx={styles.button}>
              閉じる
            </Button>
          </Link>
        </Box>
      </Container>
    </GenericTemplate>
  );
};

export default ReserveFrameRegist;
