import { Container } from '@mui/material';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import FormControl from '@mui/material/FormControl/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import FormGroup from '@mui/material/FormGroup/FormGroup';
import Grid from '@mui/material/Grid/Grid';
import Radio from '@mui/material/Radio/Radio';
import RadioGroup from '@mui/material/RadioGroup/RadioGroup';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TableHead from '@mui/material/TableHead/TableHead';
import TablePagination from '@mui/material/TablePagination/TablePagination';
import TableRow from '@mui/material/TableRow/TableRow';
import TextField from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography/Typography';
import { Student } from 'API';
import React, { FC, useState } from 'react';
import { SelectValue } from '../../../../types/SelectValue';
import SelectFormControl from '../../../parts/SelectFormControl';
import GenericTemplate from '../../../template/GenericTemplate';
import { ErrorData } from './StudentListErrorData';
import FormHelperText from '@mui/material/FormHelperText';
import AlertDialog from '../alert';
import BackToTopButton from 'views/parts/BackToTop';

const styles = {
  root: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
  },

  label: {
    margin: 'auto',
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
  },

  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
  },

  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
  },

  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 1,
    mb: 1,
    ml: 1,
  },

  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },

  textField: {
    width: '100%',
    '& input': {
      padding: '8px 14px',
    },
  },

  listButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    m: 0,
  },

  radioGroup: {
    '& label > span:first-Child': {
      padding: '6px 9px',
    },
  },

  checkboxGroup: {
    '& label > span:first-Child': {
      padding: '0px 9px',
    },
    display: 'block',
  },

  returnButton: {
    width: 120,
    float: 'left',
    mb: 1,
    mt: 1,
    fontWeight: 'bold',
  },

  deleteCell: {
    '& > a': {
      textDecoration: 'none',
    },
  },
};

export interface Props {
  classCode: string;
  studentNo: string;
  studentName: string;
  classCodeList: Student[];
  resultData: Student[];
  errorAttribute: ErrorData;
  alertOpen: boolean;
  alertContent: string;
  handleChangeClassCode: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeStudentNo: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeStudentName: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeSex: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeCheckbox: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeIsActive: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickDetailButton: (_: Student) => void;
  handleClickCreateButton: () => void;
  handleClickStudentbulkButton: () => void;
  handleClickSearch: () => void;
  handleDelete: (_: Student) => void;
  handleAlertClose: (_: boolean) => void;
}

const StudentList: FC<Props> = (props) => {
  const pushBack = function () {
    window.history.back();
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <GenericTemplate title="生徒一覧">
      <div>
        <BackToTopButton />
      </div>
      <Container sx={styles.root}>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={12}>
            <Button variant="contained" sx={styles.returnButton} onClick={pushBack}>
              戻る
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
              検索条件
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>組</Typography>
          </Grid>
          <Grid item xs={7}>
            <SelectFormControl
              value={props.classCode}
              selectName="ClassCode"
              selectValues={props.classCodeList.map((student, idx) => {
                return { value: student.ClassCode, displayName: student.ClassCode } as unknown as SelectValue;
              })}
              setCondition={props.handleChangeClassCode}
              sx={styles.inputItem}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>生徒番号</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              sx={styles.textField}
              type="number"
              value={props.studentNo}
              onChange={props.handleChangeStudentNo}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>生徒名</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              sx={styles.textField}
              defaultValue={''}
              placeholder={'生徒名の一部を入力してください'}
              value={props.studentName}
              onChange={props.handleChangeStudentName}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>性別</Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="position"
                name="Sex"
                defaultValue="top"
                sx={styles.radioGroup}
                onChange={props.handleChangeSex}
              >
                <FormControlLabel value="1" control={<Radio color="primary" />} label="男性" />
                <FormControlLabel value="2" control={<Radio color="primary" />} label="女性" />
                <FormControlLabel value="3" control={<Radio color="primary" />} label="その他" />
                <FormControlLabel value="0" control={<Radio color="primary" />} label="すべて" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>有効区分</Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue="top"
                sx={styles.radioGroup}
                onChange={props.handleChangeIsActive}
              >
                <FormControlLabel value="1" control={<Radio color="primary" />} label="有効" />
                <FormControlLabel value="0" control={<Radio color="primary" />} label="無効" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label} style={{ marginTop: 2 }}>
              ⺠家割当
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControl component="fieldset">
              <FormGroup sx={styles.checkboxGroup}>
                <FormControlLabel
                  control={<Checkbox name="MinkaList" value={1} onChange={props.handleChangeCheckbox} />}
                  label="済"
                />
                <FormControlLabel
                  control={<Checkbox name="MinkaList" value={0} onChange={props.handleChangeCheckbox} />}
                  label="未"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'center', '& > a': { textDecoration: 'none' } }}>
          <Button variant="contained" sx={styles.button} onClick={props.handleClickSearch}>
            検索
          </Button>
          <Button variant="contained" sx={styles.button} onClick={props.handleClickCreateButton}>
            新規作成
          </Button>
          <Button variant="contained" sx={styles.button} onClick={props.handleClickStudentbulkButton}>
            一括登録
          </Button>
        </Box>
        {props.errorAttribute.Header.ErrorMessage && (
          <Grid container spacing={2} sx={{ pl: 0, pb: 2 }}>
            <Grid item xs={12}>
              <FormControl component="fieldset" error={props.errorAttribute.Header.IsError}>
                <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                  {props.errorAttribute.Header.ErrorMessage}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        )}
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          生徒一覧
        </Typography>
        <TableContainer sx={{ minHeight: 300 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>予約ID</TableCell>
                <TableCell>組</TableCell>
                <TableCell>番号</TableCell>
                <TableCell>氏名</TableCell>
                <TableCell>カナ氏名</TableCell>
                <TableCell>性別</TableCell>
                <TableCell>アレルギー</TableCell>
                <TableCell>持病</TableCell>
                <TableCell>不安事項</TableCell>
                <TableCell>注意事項</TableCell>
                <TableCell>割当民家</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.resultData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((student, index) => (
                <TableRow key={index}>
                  <TableCell component="td" scope="row">
                    <Button
                      variant="contained"
                      sx={styles.listButton}
                      size="small"
                      onClick={() => props.handleDelete(student)}
                    >
                      削除
                    </Button>
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {student.ReservationId}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {student.ClassCode}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {student.StudentNo}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {student.StudentName}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {student.StudentKanaName}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {student.SexDisplayName}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100px' }}
                  >
                    {student.Allergy}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100px' }}
                  >
                    {student.Disease}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100px' }}
                  >
                    {student.Consern}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100px' }}
                  >
                    {student.Notice}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {student.MinkaName}
                  </TableCell>
                  <TableCell component="td" scope="row" sx={styles.deleteCell}>
                    <Button
                      variant="contained"
                      sx={styles.listButton}
                      size="small"
                      onClick={() => props.handleClickDetailButton(student)}
                    >
                      詳細
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={props.resultData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Container>
      <AlertDialog
        handleDialogClose={props.handleAlertClose}
        dialogOpen={props.alertOpen}
        content={props.alertContent}
      />
    </GenericTemplate>
  );
};

export default StudentList;
