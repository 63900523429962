import { FC, useState } from 'react';
import GenericTemplate from '../../../template/GenericTemplate';
import React from 'react';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import Grid from '@mui/material/Grid/Grid';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Table from '@mui/material/Table/Table';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import Button from '@mui/material/Button/Button';
import TableBody from '@mui/material/TableBody/TableBody';
import Box from '@mui/material/Box/Box';
import { Link } from 'react-router-dom';
import { Area } from '../../../../API';
import { useLocation } from 'react-router';

const queryString = require('query-string');

const styles = {
  searchField: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
  },

  selectButton: {
    minWidth: '120px',
    fontWeight: 'bold',
    ml: 1,
    mt: 1,
    float: 'right',
  },

  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    ml: 1,
    mt: 1,
  },

  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
  },

  table: {
    textAlign: 'center',
    border: '1px solid #000',
    '& > tbody > tr > td': {
      border: '1px solid #000',
      textAlign: 'center',
    },
    '& > thead > tr > th': {
      border: '1px solid #000',
    },
  },

  tableRow: {
    border: '1px solid #000',
  },

  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },
};

export interface Props {
  areaReservationFrameData: Area;
  year: any;
  month: number;
  firstDate: Date;
  lastDate: Date;
  numDays: number;
  calendar: any[];
  checkedDay: number[];
  makeCalendarData: () => void;
  handleCheckedDay: (_: React.ChangeEvent<HTMLInputElement>) => void;
  selectAllDay: () => void;
  deselectAllDay: () => void;
  handleClickRegist: () => void;
}

const ReserveFrameEdit: FC<Props> = (props) => {
  const renderTableBody = () => {
    props.makeCalendarData();
    return (
      <TableBody>
        {props.calendar.map((row, idx: number) => {
          return (
            <TableRow key={idx}>
              {row.map((item: any, index: number) => {
                return (
                  <TableCell align="center" key={index}>
                    {item}
                    <br />
                    {item !== '' ? (
                      <Checkbox
                        onChange={props.handleCheckedDay}
                        checked={props.checkedDay.indexOf(item) > -1}
                        value={item}
                      />
                    ) : null}
                    <br />
                    {item !== '' ? (
                      <a
                        id="link"
                        href={
                          '/settings/reserve/adjustment?areaId=' +
                          props.areaReservationFrameData.AreaId +
                          '&year=' +
                          props.year +
                          '&month=' +
                          props.month +
                          '&day=' +
                          item
                        }
                        style={{ color: 'blue' }}
                      >
                        受入調整
                      </a>
                    ) : null}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  return (
    <GenericTemplate title="予約枠設定">
      <Container sx={styles.searchField}>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          予約枠設定：{props.year + '年' + props.month + '月' + '（' + props.areaReservationFrameData?.AreaName + '）'}
        </Typography>
        <Box sx={{ display: 'flex', marginTop: '10px' }}>
          <Grid container spacing={0.5} sx={styles.categoryBlock}>
            <Grid item xs={9}>
              <Typography variant="h6" gutterBottom component="div" sx={{ marginLeft: '10px' }}>
                休業日を選択してください。
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" sx={styles.selectButton} onClick={props.deselectAllDay}>
                全てクリア
              </Button>
              <Button variant="contained" sx={styles.selectButton} onClick={props.selectAllDay}>
                全て選択
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={12}>
            <TableContainer sx={{ minHeight: 300 }}>
              <Table sx={styles.table} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: 'red', fontWeight: 'bold' }} align="center">
                      日
                    </TableCell>
                    <TableCell align="center">月</TableCell>
                    <TableCell align="center">火</TableCell>
                    <TableCell align="center">水</TableCell>
                    <TableCell align="center">木</TableCell>
                    <TableCell align="center">金</TableCell>
                    <TableCell sx={{ color: 'blue', fontWeight: 'bold' }} align="center">
                      土
                    </TableCell>
                  </TableRow>
                </TableHead>
                {renderTableBody()}
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'center', '& > a': { textDecoration: 'none' } }}>
          <Button variant="contained" sx={styles.button} onClick={props.handleClickRegist}>
            休業日更新
          </Button>
          <Link
            to={
              '/settings/reserve/periodadjustment?areaId=' +
              props.areaReservationFrameData.AreaId +
              '&firstDt=' +
              props.firstDate.toLocaleDateString() +
              '&lastDt=' +
              props.lastDate.toLocaleDateString()
            }
          >
            <Button variant="contained" sx={styles.button}>
              期間指定受入調整
            </Button>
          </Link>
          <Link to="/settings/reserve/framelist">
            <Button variant="contained" sx={styles.button}>
              閉じる
            </Button>
          </Link>
        </Box>
      </Container>
    </GenericTemplate>
  );
};

export default ReserveFrameEdit;
