import { FC } from 'react';
import { ServiceProvider } from 'types/ServiceProvider';
import AcceptCompanyInfoDialog from './AcceptCompanyInfoDialog';
import UseAcceptCompanyInfoDialog from './UseAcceptCompanyInfoDialog';

export interface Props {
  handleDialogClose: (_?: ServiceProvider, updateDatetime?: string) => void;
  dialogOpen: boolean;
  serviceProvider: ServiceProvider;
  isReserveAdmin: boolean;
  reservationId: string;
  mode: string;
  updateDatetime: string;
}

const AcceptCompanyInfoDialogContainer: FC<Props> = (props: Props) => {
  return <AcceptCompanyInfoDialog {...UseAcceptCompanyInfoDialog(props)} />;
};

export default AcceptCompanyInfoDialogContainer;
