import FormErrorAttribute from 'types/FormErrorAttribute';

export interface ErrorData {
  Header: FormErrorAttribute;
  SchoolCode: FormErrorAttribute;
  SchoolCategoryCode: FormErrorAttribute;
  PrefectureCode: FormErrorAttribute;
  ClassificationCode: FormErrorAttribute;
  SchoolName: FormErrorAttribute;
  SchoolKanaName: FormErrorAttribute;
  Address: FormErrorAttribute;
  PostalCode: FormErrorAttribute;
  Tel: FormErrorAttribute;
  Fax: FormErrorAttribute;
  IsActive: FormErrorAttribute;
  UpdateDatetime: FormErrorAttribute;
}

export const CreateEmptyErrorData = () => {
  return {
    Header: {} as FormErrorAttribute,
    SchoolCode: {} as FormErrorAttribute,
    SchoolCategoryCode: {} as FormErrorAttribute,
    PrefectureCode: {} as FormErrorAttribute,
    ClassificationCode: {} as FormErrorAttribute,
    SchoolName: {} as FormErrorAttribute,
    SchoolKanaName: {} as FormErrorAttribute,
    Address: {} as FormErrorAttribute,
    PostalCode: {} as FormErrorAttribute,
    Tel: {} as FormErrorAttribute,
    Fax: {} as FormErrorAttribute,
    IsActive: {} as FormErrorAttribute,
    UpdateDatetime: {} as FormErrorAttribute,
  } as ErrorData;
};
