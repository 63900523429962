import { Container, TablePagination } from '@mui/material';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import FormControl from '@mui/material/FormControl/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import FormGroup from '@mui/material/FormGroup/FormGroup';
import Grid from '@mui/material/Grid/Grid';
import Radio from '@mui/material/Radio/Radio';
import RadioGroup from '@mui/material/RadioGroup/RadioGroup';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TextField from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography/Typography';
import React, { FC } from 'react';
import { Prefecture, Area, Region, Minka } from '../../../../API';
import { SelectValue } from '../../../../types/SelectValue';
import SelectFormControl from '../../../parts/SelectFormControl';
import GenericTemplate from '../../../template/GenericTemplate';
import { ErrorData } from './MinkaListErrorData';
import FormHelperText from '@mui/material/FormHelperText';
import AlertDialog from '../alert';
import BackToTopButton from 'views/parts/BackToTop';

const styles = {
  searchField: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
  },

  label: {
    margin: 'auto',
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
  },

  labelValue: {
    margin: 'auto',
    textAlign: 'left',
    pr: '10px',
    mt: '7px',
  },

  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
  },

  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
    '& > div > input': {
      maxHeight: '10px',
    },
  },

  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 1,
    ml: 1,
  },

  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },

  textField: {
    width: '100%',
    '& input': {
      padding: '8px 14px',
    },
  },

  listButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    m: 0,
  },

  deleteCell: {
    '& > a': {
      textDecoration: 'none',
    },
  },
};

export interface Props {
  companyName: string;
  branchName: string;
  prefectureCode: string;
  areaId: string;
  regionCode: string;
  minkaNo: string;
  minkaName: string;
  isActive: string;
  prefectureList: Prefecture[];
  areaList: Area[];
  regionList: Region[];
  resultData: Minka[];
  errorAttribute: ErrorData;
  alertOpen: boolean;
  alertContent: string;
  page: number;
  rowsPerPage: number;
  handleChangePrefecture: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeArea: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeRegion: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeMinkaNo: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeMinkaName: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeIsActive: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickSearch: () => void;
  handleDelete: (_: Minka) => void;
  handleClickCreateButton: () => void;
  handleClickDetailButton: (_: Minka) => void;
  handleAlertClose: (_: boolean) => void;
  handleChangePage: (_: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => void;
  handleChangeRowsPerPage: (_: React.ChangeEvent<HTMLInputElement>) => void;
}

const MinkaList: FC<Props> = (props) => {
  return (
    <GenericTemplate title="⺠家⼀覧">
      <div>
        <BackToTopButton />
      </div>
      <Container sx={styles.searchField}>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          検索条件
        </Typography>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>所属会社</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography sx={styles.labelValue}>{props.companyName}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>所属支店・営業店</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography sx={styles.labelValue}>{props.branchName}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>都道府県</Typography>
          </Grid>
          <Grid item xs={7}>
            <SelectFormControl
              value={props.prefectureCode}
              selectName="PrefectureCode"
              selectValues={props.prefectureList
                .filter((x) => x.IsMinpakuArea === 1)
                .map((prefecture, idx) => {
                  return { value: prefecture.PrefectureCode, displayName: prefecture.PrefectureName } as SelectValue;
                })}
              setCondition={props.handleChangePrefecture}
              sx={styles.inputItem}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>地区</Typography>
          </Grid>
          <Grid item xs={7}>
            {props.areaList.length > 0 ? (
              <SelectFormControl
                value={props.areaId}
                selectName="AreaId"
                selectValues={props.areaList.map((area, idx) => {
                  return { value: area.AreaId, displayName: area.AreaName } as SelectValue;
                })}
                setCondition={props.handleChangeArea}
                sx={styles.inputItem}
              />
            ) : (
              <>
                <SelectFormControl
                  value={'1'}
                  selectName="NoneAreaId"
                  sx={styles.inputItem}
                  isDisabled
                  setCondition={props.handleChangeArea}
                  selectValues={[{ value: '1', displayName: '宿泊先都道府県を選択すると指定できます' }]}
                />
              </>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>地域</Typography>
          </Grid>
          <Grid item xs={7}>
            {props.regionList.length > 0 ? (
              <SelectFormControl
                value={props.regionCode}
                selectName="RegionCode"
                selectValues={props.regionList.map((region, idx) => {
                  return { value: region.RegionCode, displayName: region.RegionName } as SelectValue;
                })}
                setCondition={props.handleChangeRegion}
                sx={styles.inputItem}
              />
            ) : (
              <>
                <SelectFormControl
                  value={'1'}
                  selectName="NoneRegionCode"
                  sx={styles.inputItem}
                  isDisabled
                  setCondition={props.handleChangeRegion}
                  selectValues={[{ value: '1', displayName: '地区を選択すると指定できます' }]}
                />
              </>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>民家No</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name="MinkaNo"
              sx={styles.textField}
              value={props.minkaNo}
              placeholder={'民家Noを入力してください'}
              type="number"
              onChange={props.handleChangeMinkaNo}
              defaultValue={''}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>民家名</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name="MinkaName"
              sx={styles.textField}
              defaultValue={''}
              placeholder={'民家名の全部または一部を入力してください'}
              value={props.minkaName}
              onChange={props.handleChangeMinkaName}
              inputProps={{ maxLength: 20 }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>有効区分</Typography>
          </Grid>
          <Grid item xs={7}>
            <FormControl component="fieldset">
              <FormGroup style={{ display: 'block' }}>
                <RadioGroup row aria-label="position" defaultValue="1">
                  <FormControlLabel
                    value="1"
                    control={
                      <Radio
                        color="primary"
                        name="IsActive"
                        value={'1'}
                        onChange={props.handleChangeIsActive}
                        checked={props.isActive === '1'}
                      />
                    }
                    label="有効"
                  />
                  <FormControlLabel
                    value="0"
                    control={
                      <Radio
                        color="primary"
                        name="IsActive"
                        value={'0'}
                        onChange={props.handleChangeIsActive}
                        checked={props.isActive === '0'}
                      />
                    }
                    label="無効"
                  />
                </RadioGroup>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'center', '& > a': { textDecoration: 'none' } }}>
          <Button variant="outlined" sx={styles.button} onClick={props.handleClickCreateButton}>
            新規作成
          </Button>
          <Button variant="contained" sx={styles.button} onClick={props.handleClickSearch}>
            検索
          </Button>
        </Box>
      </Container>
      {props.errorAttribute.Header.ErrorMessage && (
        <Grid container spacing={2} sx={{ pl: 0, pb: 2 }}>
          <Grid item xs={12}>
            <FormControl component="fieldset" error={props.errorAttribute.Header.IsError}>
              <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                {props.errorAttribute.Header.ErrorMessage}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      )}
      <Container sx={styles.searchField}>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          民家一覧
        </Typography>
        <TableContainer sx={{ minHeight: 300 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>地域</TableCell>
                <TableCell>民家ID</TableCell>
                <TableCell>民家No</TableCell>
                <TableCell>民家名</TableCell>
                <TableCell>屋号</TableCell>
                <TableCell>受入性別</TableCell>
                <TableCell>人数</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.resultData
                .slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage)
                .map((minka, index) => (
                  <TableRow key={index}>
                    <TableCell component="td" scope="row">
                      <Button
                        variant="contained"
                        sx={styles.listButton}
                        size="small"
                        onClick={() => props.handleDelete(minka)}
                      >
                        削除
                      </Button>
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {minka.RegionName}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {minka.MinkaId}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {minka.MinkaNo}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {minka.MinkaName}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {minka.BussinessName}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {minka.AcceptanceGenderDisplayName}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {minka.GuestCount}
                    </TableCell>
                    <TableCell component="td" scope="row" sx={styles.deleteCell}>
                      <Button
                        variant="contained"
                        sx={styles.listButton}
                        size="small"
                        onClick={() => props.handleClickDetailButton(minka)}
                      >
                        詳細
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 300]}
          component="div"
          count={props.resultData.length}
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          onPageChange={props.handleChangePage}
          onRowsPerPageChange={props.handleChangeRowsPerPage}
        />
        <AlertDialog
          handleDialogClose={props.handleAlertClose}
          dialogOpen={props.alertOpen}
          content={props.alertContent}
        />
      </Container>
    </GenericTemplate>
  );
};

export default MinkaList;
