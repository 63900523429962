import { ApproveReservation, ApproveReservationMutation } from 'API';
import { useAPI } from 'contexts/APIRequestContext';
import { approveReservation } from 'graphql/mutations';
import React, { useCallback, useEffect, useState } from 'react';
import { CreateEmptyErrorData, ErrorData } from './ApprovalDialogErrorData';

const UseApprovalDialog = ({
  handleDialogClose,
  dialogOpen,
  reservationId,
  updateDatetime,
}: {
  handleDialogClose: (_?: string) => void;
  dialogOpen: boolean;
  reservationId: string;
  updateDatetime: string;
}) => {
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [inputData, setInputData] = useState({} as ApproveReservation);
  const api = useAPI();
  const [, setError] = useState<undefined>();

  useEffect(() => {
    if (dialogOpen) {
      // 初期化
      setErrorAttribute({ ...CreateEmptyErrorData() });
    } else {
      setInputData({ ReservedDays: [] as string[] } as ApproveReservation);
    }
  }, [dialogOpen]);

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  /**
   * 決定確認書変更時
   * @param event イベント
   */
  const handleChangeCenterConfirmationDoc = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files && event.target.files.length) {
      if (event.target.files[0].size > 500 * 1024) {
        setErrorAttribute({
          ...errorAttribute,
          CenterConfirmationDoc: { IsError: true, ErrorMessage: 'ファイルサイズは500KB以下にしてください。' },
        });
        return;
      } else {
        setErrorAttribute({
          ...errorAttribute,
          CenterConfirmationDoc: { IsError: false, ErrorMessage: '' },
        });
      }

      inputData.CenterConfirmationDocName = event.target.files[0].name;

      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        setInputData({
          ...inputData,
          CenterConfirmationDocName: inputData.CenterConfirmationDocName,
          CenterConfirmationDoc: (reader.result as string).split(';base64,').pop(),
        });
      };
    }
  };

  /**
   * 決定確認書削除時
   */
  const handleDeleteCenterConfirmationDoc = (): void => {
    setInputData({ ...inputData, CenterConfirmationDocName: '', CenterConfirmationDoc: '' });
    setErrorAttribute({
      ...errorAttribute,
      CenterConfirmationDoc: { IsError: false, ErrorMessage: '' },
    });
  };

  /**
   * 閉じる
   */
  const handleClose = (): void => {
    handleDialogClose();
  };

  /**
   * 本予約承認を行う
   */
  const executeApplyReservation = async (): Promise<void> => {
    const response = (
      (await api.graphql({
        query: approveReservation,
        variables: {
          data: {
            ReservationId: reservationId,
            CenterConfirmationDocName: inputData.CenterConfirmationDocName,
            CenterConfirmationDoc: inputData.CenterConfirmationDoc,
            UpdateDatetime: updateDatetime,
          },
        },
      })) as ApproveReservationMutation
    ).approveReservation;

    if (response?.IsSuccess) {
      handleDialogClose(response.Body!);
    } else if (response?.IsSystemError) {
      throwError(response.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      } as ErrorData);
    } else {
      throwError();
    }
  };

  /**
   * 次へ
   */
  const handleRegist = async (): Promise<void> => {
    executeApplyReservation();
  };

  return {
    errorAttribute,
    dialogOpen,
    inputData,
    handleChangeCenterConfirmationDoc,
    handleDeleteCenterConfirmationDoc,
    handleClose,
    handleRegist,
  };
};

export default UseApprovalDialog;
