import { useCallback, useState, useEffect } from 'react';
import React from 'react';
import {
  Company,
  Branch,
  Prefecture,
  CompanyRequest,
  FindCompanyQuery,
  CompanyBranchRequest,
  RegistCompanyMutation,
  UpdateCompanyMutation,
  DeleteCompanyBranchMutation,
} from '../../../../API';
import { useLocation, useNavigate } from 'react-router';
import { useMaster } from 'contexts/CommonMasterContext';
import { useAPI } from 'contexts/APIRequestContext';
import { findCompany } from 'graphql/queries';
import { registCompany, updateCompany, deleteCompanyBranch } from 'graphql/mutations';
import { CreateEmptyErrorData, ErrorData } from './CompanyDetailErrorData';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';
import { AuthorityCode, UserCategoryCode } from 'constant/Constant';
import { checkAuthority } from 'util/AuthorityCheck';
import { scrollToTop } from 'util/Scroll';

const queryString = require('query-string');

const UseSchoolDetail = () => {
  const location = useLocation();
  var companyPara = location.state as Company;
  const [isUpdate, setIsUpdate] = useState(Boolean);
  const [isReserveAdmin, setIsReserveAdmin] = useState(Boolean);
  const [companyId, setCompanyId] = useState('');
  const [, setError] = useState<undefined>();
  const master = useMaster();
  const api = useAPI();
  const [prefectureList, setPrefectureList] = useState([] as Prefecture[]);
  const [companyData, setCompanyData] = useState({} as Company);
  const [companyMainData, setCompanyMainData] = useState({} as Branch);
  const [companyBranchData, setCompanyBranchData] = useState([] as Branch[]);
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const navigator = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertDialogCallback, setAlertDialogCallback] = useState({ fn: (_: boolean) => {} });
  const { user } = useAuthenticator();
  const userData = new UserData(user);
  const [canAllDept, setCanAllDept] = useState(false);

  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  /**
   * ブラウザバック
   */
  const back = (): void => {
    window.history.back();
  };

  /**
   * 警告ダイアログ　閉じる
   * @param result 結果
   */
  const handleAlertClose = (result: boolean): void => {
    alertDialogCallback.fn(result);
    setAlertOpen(false);
  };

  const handleChangeDetail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    setCompanyData({ ...companyData, [name]: value });
  };

  const handleChangeCompanyMain = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    setCompanyMainData({ ...companyMainData, [name]: value });
  };

  const handleChangePrefectureCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeCompanyMain(event);
  };

  ///////////////////////////////////////////////////////////////////////////////
  // 支店・営業店登録更新削除
  ///////////////////////////////////////////////////////////////////////////////
  const handleClickBranchEditButton = (companyBranch: CompanyBranchRequest) => {
    navigator('/settings/company/branch?mode=update', {
      state: {
        CompanyId: companyBranch.CompanyId,
        BranchId: companyBranch.BranchId,
        IsMain: companyBranch.IsMain,
        BranchName: companyBranch.BranchName,
        PostalCode: companyBranch.PostalCode,
        PrefectureCode: companyBranch.PrefectureCode,
        Address: companyBranch.Address,
        Tel: companyBranch.Tel,
        Fax: companyBranch.Fax,
        Mail1: companyBranch.Mail1,
        Mail2: companyBranch.Mail2,
        Mail3: companyBranch.Mail3,
        Mail4: companyBranch.Mail4,
        Mail5: companyBranch.Mail5,
        Mail6: companyBranch.Mail6,
        IsActive: companyBranch.IsActive,
        UpdateDatetime: companyBranch.UpdateDatetime,
      } as CompanyBranchRequest,
    });
  };

  const handleClickBranchCreateButton = () => {
    navigator('/settings/company/branch?mode=new', {
      state: {
        CompanyId: companyId,
      } as CompanyBranchRequest,
    });
  };

  const handleClickBranchDeleteButton = async (companyBranch: Branch): Promise<void> => {
    setAlertDialogCallback({
      fn: async (result: boolean) => {
        if (result) {
          deleteResident(companyBranch);
        }
      },
    });
    setAlertContent('削除します。よろしいですか？');
    setAlertOpen(true);
  };

  async function deleteResident(companyBranch: Branch) {
    // エラー表示内容をクリア
    setErrorAttribute({
      ...CreateEmptyErrorData(),
      Header: { IsError: true, ErrorMessage: '' },
    });

    let companyBranchReq = {
      BranchId: companyBranch.BranchId,
      BranchName: companyBranch.BranchName,
      UpdateDatetime: companyBranch.UpdateDatetime,
    } as CompanyBranchRequest;

    const response = (
      (await api.graphql({
        query: deleteCompanyBranch,
        variables: { data: companyBranchReq },
      })) as DeleteCompanyBranchMutation
    ).deleteCompanyBranch;

    if (response?.IsSuccess) {
      // リストから削除に成功したレコードを除外
      let newBranchList = companyBranchData.filter((item) => item.BranchId != companyBranch.BranchId);
      setCompanyBranchData(newBranchList);
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  ///////////////////////////////////////////////////////////////////////////////
  // 会社基本情報登録更新削除
  ///////////////////////////////////////////////////////////////////////////////
  const handleClickRegist = () => {
    if (isUpdate) {
      updateCompanyDetail();
    } else {
      registCompanyDetail();
    }
  };

  const moveNextAfterRegist = async (id: Number): Promise<void> => {
    setAlertDialogCallback({
      fn: async (result: boolean) => {
        if (result) {
          // 編集モードで再読み込み
          navigator('/settings/company/detail?mode=update', {
            state: {
              __typename: 'Company',
              CompanyId: String(id),
            } as Company,
          });
        } else {
          back();
        }
      },
    });
    setAlertContent('会社情報を登録しました。引き続き、支店・営業店等を登録しますか？');
    setAlertOpen(true);
  };

  async function registCompanyDetail() {
    const companyRequest = {
      CompanyBasic: companyData,
      CompanyBranch: companyMainData,
    } as CompanyRequest;

    const response = (
      (await api.graphql({
        query: registCompany,
        variables: { data: companyRequest },
      })) as RegistCompanyMutation
    ).registCompany;

    if (response?.IsSuccess) {
      setIsUpdate(true);
      setCompanyId(response.Body!);
      setErrorAttribute(CreateEmptyErrorData());
      moveNextAfterRegist(Number(response.Body));
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
      scrollToTop();
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
      scrollToTop();
    } else {
      throwError();
    }
  }

  async function updateCompanyDetail() {
    const companyRequest = {
      CompanyBasic: companyData,
      CompanyBranch: companyMainData,
    } as CompanyRequest;

    const response = (
      (await api.graphql({
        query: updateCompany,
        variables: { data: companyRequest },
      })) as UpdateCompanyMutation
    ).updateCompany;

    if (response?.IsSuccess) {
      if (queryString.parse(location.search).mode === 'agent') {
        back();
      }
      // 新規登録後、編集モードで再読みしている為、back すると新規モードに遷移してしまうため、
      // 直接一覧を指定（直したい・・）
      // back();
      navigator('/settings/company/list');
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
      scrollToTop();
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
      scrollToTop();
    } else {
      throwError();
    }
  }

  ///////////////////////////////////////////////////////////////////////////////
  // 初期表示処理
  ///////////////////////////////////////////////////////////////////////////////
  async function fetchCompanyData(id: Number) {
    const response = (await api.graphql({
      query: findCompany,
      variables: {
        CompanyId: id,
      },
    })) as FindCompanyQuery;
    if (response?.findCompany?.IsSuccess) {
      if (response?.findCompany?.Body) {
        let companyResponse = response.findCompany.Body.Company as Company;
        setCompanyData({
          ...companyData,
          ['CompanyId']: companyResponse.CompanyId,
          ['CategoryCode']: companyResponse.CategoryCode,
          ['CompanyName']: companyResponse.CompanyName,
          ['CompanyShortName']: companyResponse.CompanyShortName,
          ['IsActive']: companyResponse.IsActive,
          ['UpdateDatetime']: companyResponse.UpdateDatetime,
        });

        let companyMainResponse = response.findCompany.Body.CompanyMain as Branch;
        setCompanyMainData({
          ...companyMainData,
          ['CompanyId']: companyMainResponse.CompanyId,
          ['BranchId']: companyMainResponse.BranchId,
          ['IsMain']: companyMainResponse.IsMain,
          ['BranchName']: companyMainResponse.BranchName,
          ['PostalCode']: companyMainResponse.PostalCode,
          ['PrefectureCode']: companyMainResponse.PrefectureCode,
          ['Address']: companyMainResponse.Address,
          ['Tel']: companyMainResponse.Tel,
          ['Fax']: companyMainResponse.Fax,
          ['Mail1']: companyMainResponse.Mail1,
          ['Mail2']: companyMainResponse.Mail2,
          ['Mail3']: companyMainResponse.Mail3,
          ['Mail4']: companyMainResponse.Mail4,
          ['Mail5']: companyMainResponse.Mail5,
          ['Mail6']: companyMainResponse.Mail6,
          ['IsActive']: companyMainResponse.IsActive,
          ['UpdateDatetime']: companyMainResponse.UpdateDatetime,
        });
        setCompanyBranchData(response.findCompany.Body.CompanyBranch as Branch[]);

        setPrefectureList(master.getPrefecture());
      }
    } else if (response?.findCompany?.IsSystemError) {
      throwError(response?.findCompany?.ErrorData ?? '');
    } else if (response?.findCompany?.IsInputCheckError && response?.findCompany?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.findCompany?.ErrorData) as ErrorData),
      });
    } else if (response?.findCompany?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.findCompany?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (userData.userCategoryCode === UserCategoryCode.Agent) {
      if (!checkAuthority('AgentDetail', userData.authorityCode)) {
        navigator('/');
      }
    } else if (!checkAuthority('CompanyDetail', userData.authorityCode)) {
      navigator('/');
    }

    master.waitForInitialized(() => {
      setPrefectureList(master.getPrefecture());
    });
    setIsReserveAdmin(userData.authorityCode === AuthorityCode.ReserveAdministrator);
    setCanAllDept((user as any).attributes['custom:can_all_dept'] === 'True');
    if (queryString.parse(location.search).mode === 'update') {
      setIsUpdate(true);
      setCompanyId(companyPara.CompanyId);
      fetchCompanyData(Number(companyPara.CompanyId));
    } else if (queryString.parse(location.search).mode === 'agent') {
      setIsUpdate(true);
      setCompanyId(userData.companyId);
      fetchCompanyData(Number(userData.companyId));
    } else {
      setIsUpdate(false);
      // 初期値をセットする
      setCompanyData({
        ...companyData,
        ['CategoryCode']: '01',
        ['IsActive']: 1,
      });

      setCompanyMainData({
        ...companyMainData,
        ['BranchName']: '本社',
        ['IsMain']: 0,
        ['IsActive']: 1,
      });
    }
  }, []);

  return {
    isUpdate,
    isReserveAdmin,
    prefectureList,
    companyData,
    companyMainData,
    companyBranchData,
    errorAttribute,
    alertOpen,
    alertContent,
    canAllDept,
    handleChangeDetail,
    handleChangeCompanyMain,
    handleChangePrefectureCode,
    handleClickRegist,
    handleClickBranchCreateButton,
    handleClickBranchEditButton,
    handleClickBranchDeleteButton,
    handleAlertClose,
  };
};
export default UseSchoolDetail;
