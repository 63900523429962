import React, { useEffect, useState, useCallback } from 'react';
import { registMinkaVehicle, updateMinkaVehicle } from 'graphql/mutations';
import { useLocation } from 'react-router';
import { UpdateMinkaVehicleMutation, RegistMinkaVehicleMutation, MinkaVehicleRequest } from 'API';
import { CreateEmptyErrorData, ErrorData } from './CarDetailErrorData';
import { useAPI } from 'contexts/APIRequestContext';
import { useNavigate } from 'react-router-dom';
import { checkAuthority } from 'util/AuthorityCheck';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';

const UseCarDetail = () => {
  const api = useAPI();
  const queryString = require('query-string');
  const location = useLocation();
  const navigator = useNavigate();
  const { user } = useAuthenticator();
  const userData = new UserData(user);

  const minkaVehiclePara = location.state as MinkaVehicleRequest;
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [vehicleData, setVehicleData] = useState({} as MinkaVehicleRequest);
  const [, setError] = useState<undefined>();
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);
  var isUpdate = queryString.parse(location.search).mode === 'update';

  /**
   * ブラウザバック
   */
  const back = (): void => {
    window.history.back();
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.type === 'number' && event.target.value === '' ? undefined : event.target.value; // MEMO: スキーマ定義が Int の場合、画面入力値を空に変更した場合、"" 空文字扱いとなり、エラーとなる為の対応
    const name = event.target.name;
    setVehicleData({ ...vehicleData, [name]: value });
  };

  const handleChangeInspectionDate = (value: Date | null) => {
    setVehicleData({ ...vehicleData, InspectionDate: value?.toLocaleDateString() });
  };

  const handleChangeInsuranceEndDate = (value: Date | null) => {
    setVehicleData({ ...vehicleData, InsuranceEndDate: value?.toLocaleDateString() });
  };

  const handleClickRegist = () => {
    if (isUpdate) {
      updateResident();
    } else {
      registResident();
    }
  };

  async function registResident() {
    const response = (
      (await api.graphql({
        query: registMinkaVehicle,
        variables: { data: vehicleData },
      })) as RegistMinkaVehicleMutation
    ).registMinkaVehicle;

    if (response?.IsSuccess) {
      back();
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  async function updateResident() {
    const response = (
      (await api.graphql({
        query: updateMinkaVehicle,
        variables: { data: vehicleData },
      })) as UpdateMinkaVehicleMutation
    ).updateMinkaVehicle;

    if (response?.IsSuccess) {
      back();
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('CarDetail', userData.authorityCode)) {
      navigator('/');
    }

    if (isUpdate) {
      setVehicleData(minkaVehiclePara as MinkaVehicleRequest);
    } else {
      // 新規登録の場合、初期値のある値を予めセットする
      setVehicleData({
        MinkaId: minkaVehiclePara.MinkaId,
      } as unknown as MinkaVehicleRequest);
    }
  }, []);

  return {
    handleClickRegist,
    handleChangeInput,
    handleChangeInspectionDate,
    handleChangeInsuranceEndDate,
    vehicleData,
    errorAttribute,
  };
};
export default UseCarDetail;
