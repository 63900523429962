import { graphqlOperation, input } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { findReservationFrameOpenSettingList } from 'graphql/queries';
import { useCallback, useState, useEffect } from 'react';
import { useAPI } from 'contexts/APIRequestContext';
import { CreateEmptyErrorData } from './ReserveFrameSettingListErrorData';
import { useMaster } from 'contexts/CommonMasterContext';
import {
  Prefecture,
  SchoolYearSetting,
  ReservationFrameOpenSettingForList,
  FindReservationFrameOpenSettingListRequest,
  FindReservationFrameOpenSettingListQuery,
} from '../../../../API';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';
import { checkAuthority } from 'util/AuthorityCheck';

const UseReserveFrameSettingList = () => {
  const [prefectureCode, setPrefectureCode] = useState('');
  const [schoolYearCode, setSchoolYearCode] = useState('');
  const [inputData, setInputData] = useState({} as FindReservationFrameOpenSettingListRequest);
  const [schoolYearSettingList, setSchoolYearSettingList] = useState([] as SchoolYearSetting[]);
  const [prefectureList, setPrefectureList] = useState([] as Prefecture[]);
  const [resultData, setResultData] = useState([] as ReservationFrameOpenSettingForList[]);
  const master = useMaster();
  const [, setError] = useState<undefined>();
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const api = useAPI();
  const navigate = useNavigate();
  const { user } = useAuthenticator();
  const userData = new UserData(user);
  const [year, setYear] = useState(null as number | null);
  const [nextYear, setNextYear] = useState(null as number | null);

  const defalutPage = 0;
  const defaultRowsPerPage = 25;

  const [page, setPage] = useState(defalutPage);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  const handleChangePrefectureCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPrefectureCode(value);
    setInputData({ ...inputData, PrefectureCode: value });
  };

  const handleChangeSchoolYearCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSchoolYearCode(value);
    setInputData({ ...inputData, SchoolYearCode: value });
  };

  // 詳細画面から戻ってきた際に、props.inputData.XXXX では、画面描画できなかったため、
  // props.prefectureCode というように単一責任のバインディングに変更
  // const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = event.target.value;
  //   const name = event.target.name;
  //   setInputData({ ...inputData, [name]: value });
  // };

  const saveStateToLocalStorage = () => {
    // ローカルストレージまたはセッションストレージに検索条件を退避する
    localStorage.setItem('ReserveFrameSettingList_SearchConditions', JSON.stringify({ inputData, rowsPerPage, page }));
    // 戻った時に状態を復元するフラグをセット
    sessionStorage.setItem('ReserveFrameSettingList_ShouldRestore', 'true');
  };

  const handleClickSearch = () => {
    // エラー表示内容をクリア
    setErrorAttribute({
      ...CreateEmptyErrorData(),
      Header: { IsError: true, ErrorMessage: '' },
    });

    // 入力値チェック
    if (!inputData.PrefectureCode) {
      setErrorAttribute({ ...errorAttribute, PrefectureCode: { IsError: true, ErrorMessage: '入力してください' } });
      return;
    }
    if (!inputData.SchoolYearCode) {
      setErrorAttribute({ ...errorAttribute, SchoolYearCode: { IsError: true, ErrorMessage: '入力してください' } });
      return;
    }
    fetchData();
    setPage(defalutPage);
    setRowsPerPage(defaultRowsPerPage);
  };

  async function fetchData() {
    const response = (await api.graphql(
      graphqlOperation(findReservationFrameOpenSettingList, {
        data: inputData,
      })
    )) as FindReservationFrameOpenSettingListQuery;

    if (response?.findReservationFrameOpenSettingList?.Body?.length) {
      setResultData([] as ReservationFrameOpenSettingForList[]); // 検索条件変更後の再検索結果が画面に反映されないため、初期化する
      setResultData(response.findReservationFrameOpenSettingList.Body as ReservationFrameOpenSettingForList[]);
    } else {
      setResultData([] as ReservationFrameOpenSettingForList[]);
    }
    setYear(Number(inputData.SchoolYearCode));
    setNextYear(Number(inputData.SchoolYearCode) + 1);
  }

  async function fetchDataWithParams(params: FindReservationFrameOpenSettingListRequest) {
    const response = (await api.graphql(
      graphqlOperation(findReservationFrameOpenSettingList, {
        data: params,
      })
    )) as FindReservationFrameOpenSettingListQuery;

    if (response?.findReservationFrameOpenSettingList?.Body?.length) {
      setResultData(response.findReservationFrameOpenSettingList.Body as ReservationFrameOpenSettingForList[]);
    } else {
      setResultData([] as ReservationFrameOpenSettingForList[]);
    }

    setYear(Number(params.SchoolYearCode));
    setNextYear(Number(params.SchoolYearCode) + 1);
  }

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('ReserveFrameSettingList', userData.authorityCode)) {
      navigate('/');
    }
    master.waitForInitialized(() => {
      setPrefectureList(master.getPrefecture().filter((x) => x.IsMinpakuArea === 1));
      // 都道府県が1件しか無ければ、1件目の都道府県コードをセット
      if (prefectureList.length === 1) {
        setInputData({ ...inputData, PrefectureCode: prefectureList[0].PrefectureCode });
      }
      setSchoolYearSettingList(
        master
          .getSchoolYearSettings()
          .filter((x) => Date.parse(x.SchoolYear.EndDate) > Date.now())
          .sort((n1, n2) => {
            if (n1.SchoolYear.SchoolYearCode > n2.SchoolYear.SchoolYearCode) {
              return -1;
            }
            if (n1.SchoolYear.SchoolYearCode < n2.SchoolYear.SchoolYearCode) {
              return 1;
            }
            return 0;
          })
      );
    });

    // 詳細画面から戻ってきた場合、ローカルストレージに退避していた情報を復元する
    const shouldRestore = sessionStorage.getItem('ReserveFrameSettingList_ShouldRestore');
    if (shouldRestore) {
      const storedConditionsString = localStorage.getItem('ReserveFrameSettingList_SearchConditions');
      let storedConditions = JSON.parse(storedConditionsString ?? '');
      if (storedConditions) {
        const restoredInputData = storedConditions?.inputData;
        setPrefectureCode(restoredInputData.PrefectureCode);
        setSchoolYearCode(restoredInputData.SchoolYearCode);
        setInputData(restoredInputData); // 詳細から戻ったときにsetPrefectureCode、setSchoolYearCodeのみでよいはずだが、検索ボタンクリックで未入力となってしまうため。。
        fetchDataWithParams(restoredInputData); // 復元した検索条件を直接引数として渡す
        setRowsPerPage(storedConditions?.rowsPerPage);
        setPage(storedConditions?.page);
      }
    }

    // フラグをリセット
    sessionStorage.removeItem('ReserveFrameSettingList_ShouldRestore');
  }, []);

  return {
    prefectureCode,
    schoolYearCode,
    year,
    nextYear,
    resultData,
    errorAttribute,
    prefectureList,
    schoolYearSettingList,
    page,
    rowsPerPage,
    handleChangePrefectureCode,
    handleChangeSchoolYearCode,
    handleClickSearch,
    handleChangePage,
    handleChangeRowsPerPage,
    saveStateToLocalStorage,
  };
};
export default UseReserveFrameSettingList;
