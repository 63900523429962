import { FC } from 'react';
import GenericTemplate from '../../../template/GenericTemplate';
import React from 'react';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import Grid from '@mui/material/Grid/Grid';
import TextField from '@mui/material/TextField/TextField';
import FormControl from '@mui/material/FormControl/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { ServiceRate } from '../../../../API';
import Button from '@mui/material/Button/Button';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import jaLocale from 'date-fns/locale/ja';
import DesktopDatePicker from '@mui/lab/DatePicker';
import { ErrorData } from './PriceSettingErrorData';
import BackToTopButton from 'views/parts/BackToTop';

const styles = {
  searchField: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
  },

  label: {
    margin: 'auto',
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
  },

  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
  },

  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
    '& > div > input': {
      maxHeight: '10px',
    },
  },

  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    ml: 1,
    float: 'right',
    mt: 1,
  },

  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },

  textField: {
    width: '100%',
    '& input': {
      padding: '8px 14px',
    },
  },
};

export interface Props {
  isUpdate: boolean;
  serviceRateData: ServiceRate;
  errorAttribute: ErrorData;
  alertOpen: boolean;
  alertContent: string;
  handleChangeDetail: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickRegist: () => void;
  handleAlertClose: (_: boolean) => void;
  handleChangeFromDate: (_: Date | null) => void;
  handleChangeEndDate: (_: Date | null) => void;
}

const PriceSetting: FC<Props> = (props) => {
  const pushback = function () {
    window.history.back();
  };

  return (
    <GenericTemplate title="料金設定">
      <div>
        <BackToTopButton />
      </div>
      <Container sx={styles.searchField}>
        {props.errorAttribute.Header.ErrorMessage && (
          <Grid container spacing={2} sx={{ pl: 0, pb: 2 }}>
            <Grid item xs={12}>
              <FormControl component="fieldset" error={props.errorAttribute.Header.IsError}>
                <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                  {props.errorAttribute.Header.ErrorMessage}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        )}
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          料金
        </Typography>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>税別単価</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'Rate'}
              value={props.serviceRateData.Rate}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              inputProps={{ maxLength: 40 }}
              error={props.errorAttribute.Rate.IsError}
              helperText={props.errorAttribute.Rate.ErrorMessage}
              type="number"
            />
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>開始日</Typography>
          </Grid>
          <Grid item xs={7}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
              <DesktopDatePicker
                label="yyyy/mm/dd"
                mask="____/__/__"
                value={new Date(props.serviceRateData.FromDate ?? '')}
                onChange={(newValue) => props.handleChangeFromDate(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    style={{ width: '100%' }}
                    error={props.errorAttribute.FromDate.IsError}
                    helperText={props.errorAttribute.FromDate.ErrorMessage}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>終了日</Typography>
          </Grid>
          <Grid item xs={7}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
              <DesktopDatePicker
                label="yyyy/mm/dd"
                mask="____/__/__"
                value={new Date(props.serviceRateData.EndDate ?? '')}
                onChange={(newValue) => props.handleChangeEndDate(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    style={{ width: '100%' }}
                    error={props.errorAttribute.EndDate.IsError}
                    helperText={props.errorAttribute.EndDate.ErrorMessage}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={10}>
            <Button variant="contained" sx={styles.button} onClick={pushback}>
              閉じる
            </Button>
            <Button variant="contained" sx={styles.button} onClick={props.handleClickRegist}>
              登録
            </Button>
          </Grid>
        </Grid>
      </Container>
    </GenericTemplate>
  );
};

export default PriceSetting;
