import { Container, FormControl, FormHelperText, TablePagination, Tabs, Tab, Box } from '@mui/material';
import { useNavigate } from 'react-router';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DatePicker';
import jaLocale from 'date-fns/locale/ja';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Button from '@mui/material/Button/Button';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import Grid from '@mui/material/Grid/Grid';
import Radio from '@mui/material/Radio/Radio';
import RadioGroup from '@mui/material/RadioGroup/RadioGroup';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TextField from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography/Typography';
import React, { FC, useState } from 'react';
import { getAge } from 'util/DateUtil';
import {
  Prefecture,
  Area,
  Region,
  Minka,
  MinkaActivity,
  MinkaResident,
  MinkaVehicle,
  MinkaClosingSetting,
} from '../../../../API';
import { SelectValue } from '../../../../types/SelectValue';
import SelectFormControl from '../../../parts/SelectFormControl';
import GenericTemplate from '../../../template/GenericTemplate';
import { ErrorData } from './MinkaDetailErrorData';
import AlertDialog from '../alert';
import BackToTopButton from 'views/parts/BackToTop';

const styles = {
  searchField: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
  },

  label: {
    margin: 'auto',
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
  },

  labelValue: {
    margin: 'auto',
    textAlign: 'left',
    pr: '10px',
    mt: '7px',
  },

  categoryHeader: {
    fontWeight: 'bold',
  },

  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
  },

  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
    '& > div > input': {
      maxHeight: '10px',
    },
    '& > p': {
      margin: 0,
    },
  },

  textArea: {
    width: '100%',
    '& input': {
      padding: '8px 14px',
    },
    mt: 1,
    mb: 1,
  },

  button: {
    minWidth: '120px',
    mr: 1,
    fontWeight: 'bold',
    float: 'right',
    mt: 3,
  },

  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },

  textField: {
    width: '100%',
    '& input': {
      padding: '8px 14px',
    },
  },

  createButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    mt: 1,
    mb: 1,
  },

  listButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    m: 0,
  },

  deleteCell: {
    '& > a': {
      textDecoration: 'none',
    },
  },
};

export interface Props {
  isUpdate: boolean;
  activityStatus: boolean;
  activity: string;
  prefectureList: Prefecture[];
  companyName: string;
  branchName: string;
  prefectureCode: string;
  areaId: string;
  regionCode: string;
  areaList: Area[];
  regionList: Region[];
  minkaDetailData: Minka;
  residentRepresentativeData: MinkaResident;
  residentData: MinkaResident[];
  vehicleData: MinkaVehicle[];
  activityData: MinkaActivity[];
  closingSettingData: MinkaClosingSetting[];
  errorAttribute: ErrorData;
  alertOpen: boolean;
  alertContent: string;
  yearsOfExperience: string;
  ageOfBuilding: string;
  representativeAge: string;
  handleChangeDetail: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeResidentRepresentative: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeArea: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangePrefectureCode: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeRegion: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeBirthday: (_: Date | null) => void;
  handleChangeAuthorizedDate: (_: Date | null) => void;
  handleChangeBussinessStartDate: (_: Date | null) => void;
  handleChangeConstructionDate: (_: Date | null) => void;
  handleClickRegist: () => void;
  handleClickResidentCreateButton: () => void;
  handleClickResidentEditButton: (_: MinkaResident) => void;
  handleClickResidentDeleteButton: (_: MinkaResident) => void;
  handleClickVehicleCreateButton: () => void;
  handleClickVehicleEditButton: (_: MinkaVehicle) => void;
  handleClickVehicleDeleteButton: (_: MinkaVehicle) => void;
  handleClickClosingSettingEditButton: (_: MinkaClosingSetting) => void;
  handleAddActivity: () => void;
  handleChangeActivity: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleCancelActivity: () => void;
  handleClickActivityCreateButton: () => void;
  handleClickActivityDeleteButton: (_: MinkaActivity) => void;
  handleAlertClose: (_: boolean) => void;
  handleOpenMap: () => void;
}

const MinkaDetail: FC<Props> = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [tabIndex, setTabIndex] = useState(0);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  const navigator = useNavigate();

  const pushback = function () {
    if (document.URL.includes('from=minkaledger')) {
      window.history.back();
    } else {
      navigator('/minka/management/list');
    }
  };

  return (
    <GenericTemplate title="民家詳細">
      <div>
        <BackToTopButton />
      </div>
      <Container sx={styles.searchField}>
        {props.isUpdate ? (
          <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab label="民家基本情報" />
            <Tab label="位置情報" />
            <Tab label="受入環境" />
            <Tab label="防災情報" />
            <Tab label="民家代表者" />
            <Tab label="口座情報" />
            <Tab label="同居人" />
            <Tab label="登録車両" />
            <Tab label="民家体験メニュー" />
            <Tab label="休業月" />
            <Tab label="メモ" />
          </Tabs>
        ) : (
          <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab label="民家基本情報" />
            <Tab label="位置情報" />
            <Tab label="受入環境" />
            <Tab label="防災情報" />
            <Tab label="民家代表者" />
            <Tab label="口座情報" />
          </Tabs>
        )}
        {props.errorAttribute.Header.ErrorMessage && (
          <Grid container spacing={2} sx={{ pl: 0, pb: 2 }}>
            <Grid item xs={12}>
              <FormControl component="fieldset" error={props.errorAttribute.Header.IsError}>
                <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                  {props.errorAttribute.Header.ErrorMessage}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        )}
        <TabPanel value={tabIndex} index={0}>
          <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
            民家基本情報
          </Typography>
          <Grid container spacing={0.5} sx={styles.categoryBlock}>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography sx={styles.label}>所属会社</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    sx={styles.textField}
                    name={'CompanyName'}
                    value={props.companyName}
                    onChange={props.handleChangeDetail}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>所属店</Typography>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    sx={styles.textField}
                    name={'BranchName'}
                    value={props.branchName}
                    onChange={props.handleChangeDetail}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </Grid>{' '}
            {props.isUpdate ? (
              <>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography sx={styles.label}>民家ID</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        sx={styles.textField}
                        name={'MinkaId'}
                        value={props.minkaDetailData.MinkaId}
                        onChange={props.handleChangeDetail}
                        disabled={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container>
                    <Grid item xs={3}>
                      <Typography sx={styles.label}>民家No</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        sx={styles.textField}
                        name={'MinkaNo'}
                        value={props.minkaDetailData.MinkaNo}
                        onChange={props.handleChangeDetail}
                        error={props.errorAttribute.MinkaNo.IsError}
                        helperText={props.errorAttribute.MinkaNo.ErrorMessage}
                        type="number"
                        disabled={props.isUpdate}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>民家No</Typography>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    sx={styles.textField}
                    name={'MinkaNo'}
                    value={props.minkaDetailData.MinkaNo}
                    onChange={props.handleChangeDetail}
                    error={props.errorAttribute.MinkaNo.IsError}
                    helperText={props.errorAttribute.MinkaNo.ErrorMessage}
                    type="number"
                    disabled={props.isUpdate}
                  />
                </Grid>
              </>
            )}{' '}
            <Grid item xs={3}>
              <Typography sx={styles.label}>都道府県</Typography>
            </Grid>
            <Grid item xs={7}>
              <SelectFormControl
                value={props.prefectureCode ?? ''}
                selectName="PrefectureCode"
                selectValues={props.prefectureList
                  .filter((x) => x.IsMinpakuArea === 1)
                  .map((prefecture, idx) => {
                    return { value: prefecture.PrefectureCode, displayName: prefecture.PrefectureName } as SelectValue;
                  })}
                setCondition={props.handleChangePrefectureCode}
                sx={styles.inputItem}
                error={props.errorAttribute.PrefectureCode.IsError}
                errorMessage={props.errorAttribute.PrefectureCode.ErrorMessage}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>地区</Typography>
            </Grid>
            <Grid item xs={7}>
              {props.areaList.length > 0 ? (
                <SelectFormControl
                  value={props.areaId ?? ''}
                  selectName="AreaId"
                  selectValues={props.areaList.map((area, idx) => {
                    return { value: area.AreaId, displayName: area.AreaName } as SelectValue;
                  })}
                  setCondition={props.handleChangeArea}
                  sx={styles.inputItem}
                  error={props.errorAttribute.AreaId.IsError}
                  errorMessage={props.errorAttribute.AreaId.ErrorMessage}
                />
              ) : (
                <>
                  <SelectFormControl
                    value={'1'}
                    selectName="NoneAreaId"
                    sx={styles.inputItem}
                    isDisabled
                    setCondition={props.handleChangeArea}
                    selectValues={[{ value: '1', displayName: '道府県を選択すると指定できます' }]}
                    error={props.errorAttribute.AreaId.IsError}
                    errorMessage={props.errorAttribute.AreaId.ErrorMessage}
                  />
                </>
              )}
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>地域</Typography>
            </Grid>
            <Grid item xs={7}>
              {props.regionList.length > 0 ? (
                <SelectFormControl
                  value={props.regionCode ?? ''}
                  selectName="RegionCode"
                  selectValues={props.regionList.map((region, idx) => {
                    return { value: region.RegionCode, displayName: region.RegionName } as SelectValue;
                  })}
                  setCondition={props.handleChangeRegion}
                  sx={styles.inputItem}
                  error={props.errorAttribute.RegionCode.IsError}
                  errorMessage={props.errorAttribute.RegionCode.ErrorMessage}
                />
              ) : (
                <>
                  <SelectFormControl
                    value={'1'}
                    selectName="NoneRegionCode"
                    sx={styles.inputItem}
                    isDisabled
                    setCondition={props.handleChangeRegion}
                    selectValues={[{ value: '1', displayName: '地区を選択すると指定できます' }]}
                    error={props.errorAttribute.RegionCode.IsError}
                    errorMessage={props.errorAttribute.RegionCode.ErrorMessage}
                  />
                </>
              )}
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography sx={styles.label}>民家名</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    sx={styles.textField}
                    name={'MinkaName'}
                    value={props.minkaDetailData.MinkaName}
                    onChange={props.handleChangeDetail}
                    error={props.errorAttribute.MinkaName.IsError}
                    helperText={props.errorAttribute.MinkaName.ErrorMessage}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>屋号</Typography>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    sx={styles.textField}
                    name={'BussinessName'}
                    value={props.minkaDetailData.BussinessName}
                    onChange={props.handleChangeDetail}
                    error={props.errorAttribute.BussinessName.IsError}
                    helperText={props.errorAttribute.BussinessName.ErrorMessage}
                    inputProps={{ maxLength: 40 }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography sx={styles.label}>基本受入人数</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    sx={styles.textField}
                    style={{ paddingLeft: '2px' }}
                    name={'GuestCount'}
                    value={props.minkaDetailData.GuestCount}
                    onChange={props.handleChangeDetail}
                    type="number"
                    error={props.errorAttribute.GuestCount.IsError}
                    helperText={props.errorAttribute.GuestCount.ErrorMessage}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>最大人数</Typography>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    sx={styles.textField}
                    name={'MaxGuestcount'}
                    value={props.minkaDetailData.MaxGuestcount}
                    onChange={props.handleChangeDetail}
                    type="number"
                    error={props.errorAttribute.MaxGuestcount.IsError}
                    helperText={props.errorAttribute.MaxGuestcount.ErrorMessage}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography sx={styles.label}>許可取得日</Typography>
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
                    <DesktopDatePicker
                      label="yyyy/mm/dd"
                      mask="____/__/__"
                      value={new Date(props.minkaDetailData.AuthorizedDate ?? '')}
                      onChange={(newValue) => props.handleChangeAuthorizedDate(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          error={props.errorAttribute.AuthorizedDate.IsError}
                          helperText={props.errorAttribute.AuthorizedDate.ErrorMessage}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>許可番号</Typography>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    sx={styles.textField}
                    name={'AuthorityNumber'}
                    value={props.minkaDetailData.AuthorityNumber}
                    onChange={props.handleChangeDetail}
                    inputProps={{ maxLength: 10 }}
                    error={props.errorAttribute.AuthorityNumber.IsError}
                    helperText={props.errorAttribute.AuthorityNumber.ErrorMessage}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography sx={styles.label}>民泊受入開始日</Typography>
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
                    <DesktopDatePicker
                      label="yyyy/mm/dd"
                      mask="____/__/__"
                      value={new Date(props.minkaDetailData.BussinessStartDate ?? '')}
                      onChange={(newValue) => props.handleChangeBussinessStartDate(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          error={props.errorAttribute.BussinessStartDate.IsError}
                          helperText={props.errorAttribute.BussinessStartDate.ErrorMessage}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>受入年数</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography sx={styles.labelValue}>{props.yearsOfExperience}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>固定電話番号</Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                sx={styles.textField}
                name={'Tel'}
                value={props.minkaDetailData.Tel}
                onChange={props.handleChangeDetail}
                inputProps={{ maxLength: 13 }}
                error={props.errorAttribute.Tel.IsError}
                helperText={props.errorAttribute.Tel.ErrorMessage}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>受入性別</Typography>
            </Grid>
            <Grid item xs={7}>
              <RadioGroup
                row
                name="AcceptanceGender"
                aria-label="position"
                value={props.minkaDetailData.AcceptanceGender ?? '3'}
                onChange={props.handleChangeDetail}
              >
                <FormControlLabel value={1} control={<Radio color="primary" />} label="男" />
                <FormControlLabel value={2} control={<Radio color="primary" />} label="女" />
                <FormControlLabel value={3} control={<Radio color="primary" />} label="両方" />
              </RadioGroup>
              <FormHelperText>{props.errorAttribute.AcceptanceGender.ErrorMessage}</FormHelperText>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>旅館賠償保険</Typography>
            </Grid>
            <Grid item xs={7}>
              <RadioGroup
                row
                name="FacilityInsurance"
                aria-label="position"
                value={props.minkaDetailData.FacilityInsurance ?? '2'}
                onChange={props.handleChangeDetail}
              >
                <FormControlLabel value={1} control={<Radio color="primary" />} label="加入" />
                <FormControlLabel value={0} control={<Radio color="primary" />} label="未加入" />
                <FormControlLabel value={2} control={<Radio color="primary" />} label="未確認" />
              </RadioGroup>
              <FormHelperText>{props.errorAttribute.AcceptanceGender.ErrorMessage}</FormHelperText>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>受入状況</Typography>
            </Grid>
            <Grid item xs={7}>
              <RadioGroup
                row
                name="IsAvailable"
                aria-label="position"
                value={props.minkaDetailData.IsAvailable ?? '1'}
                onChange={props.handleChangeDetail}
              >
                <FormControlLabel value={1} control={<Radio color="primary" />} label="受入可" />
                <FormControlLabel value={0} control={<Radio color="primary" />} label="受入不可" />
              </RadioGroup>
              <FormHelperText>{props.errorAttribute.IsAvailable.ErrorMessage}</FormHelperText>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>有効区分</Typography>
            </Grid>
            <Grid item xs={7}>
              <RadioGroup
                row
                name="IsActive"
                aria-label="position"
                value={props.minkaDetailData.IsActive ?? '1'}
                onChange={props.handleChangeDetail}
              >
                <FormControlLabel value={1} control={<Radio color="primary" />} label="有効" />
                <FormControlLabel value={0} control={<Radio color="primary" />} label="無効" />
              </RadioGroup>
              <FormHelperText>{props.errorAttribute.IsActive.ErrorMessage}</FormHelperText>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={tabIndex} index={1}>
          <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
            位置情報
          </Typography>
          <Grid container spacing={0.5} sx={styles.categoryBlock}>
            <Grid item xs={3}>
              <Typography sx={styles.label}>郵便番号</Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                sx={styles.textField}
                name={'PostalCode'}
                value={props.minkaDetailData.PostalCode}
                onChange={props.handleChangeDetail}
                error={props.errorAttribute.PostalCode.IsError}
                helperText={props.errorAttribute.PostalCode.ErrorMessage}
                inputProps={{ maxLength: 7 }}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>市区町村</Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                sx={styles.textField}
                name={'City'}
                value={props.minkaDetailData.City}
                onChange={props.handleChangeDetail}
                inputProps={{ maxLength: 10 }}
                error={props.errorAttribute.City.IsError}
                helperText={props.errorAttribute.City.ErrorMessage}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>住所</Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                sx={styles.textField}
                name={'Address'}
                value={props.minkaDetailData.Address}
                onChange={props.handleChangeDetail}
                inputProps={{ maxLength: 40 }}
                error={props.errorAttribute.Address.IsError}
                helperText={props.errorAttribute.Address.ErrorMessage}
                placeholder={'市区町村以下をご記入ください'}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>緯度</Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                sx={styles.textField}
                style={{ paddingLeft: '2px' }}
                name={'Latitude'}
                value={props.minkaDetailData.Latitude}
                onChange={props.handleChangeDetail}
                type="number"
                error={props.errorAttribute.Latitude.IsError}
                helperText={props.errorAttribute.Latitude.ErrorMessage}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>経度</Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                sx={styles.textField}
                name={'Longitude'}
                value={props.minkaDetailData.Longitude}
                onChange={props.handleChangeDetail}
                type="number"
                error={props.errorAttribute.Longitude.IsError}
                helperText={props.errorAttribute.Longitude.ErrorMessage}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}></Typography>
            </Grid>
            <Grid item xs={7}>
              <Button variant="contained" sx={styles.button} onClick={props.handleOpenMap}>
                Google Map で確認する
              </Button>{' '}
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={tabIndex} index={2}>
          <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
            受入環境
          </Typography>
          <Grid container spacing={0.5} sx={styles.categoryBlock}>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography sx={styles.label}>建築年月日</Typography>
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
                    <DesktopDatePicker
                      label="yyyy/mm/dd"
                      mask="____/__/__"
                      value={new Date(props.minkaDetailData.ConstructionDate ?? '')}
                      onChange={(newValue) => props.handleChangeConstructionDate(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          error={props.errorAttribute.ConstructionDate.IsError}
                          helperText={props.errorAttribute.ConstructionDate.ErrorMessage}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>築年数</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography sx={styles.labelValue}>{props.ageOfBuilding}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>建築構造</Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                sx={styles.textField}
                name={'ArchitecturalStructure'}
                value={props.minkaDetailData.ArchitecturalStructure}
                onChange={props.handleChangeDetail}
                inputProps={{ maxLength: 80 }}
                error={props.errorAttribute.ArchitecturalStructure.IsError}
                helperText={props.errorAttribute.ArchitecturalStructure.ErrorMessage}
              />
            </Grid>

            <Grid item xs={3}>
              <Typography sx={styles.label}>ペット</Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                sx={styles.textField}
                name={'Pets'}
                value={props.minkaDetailData.Pets}
                onChange={props.handleChangeDetail}
                inputProps={{ maxLength: 80 }}
                error={props.errorAttribute.Pets.IsError}
                helperText={props.errorAttribute.Pets.ErrorMessage}
              />
            </Grid>

            <Grid item xs={3}>
              <Typography sx={styles.label}>喫煙者</Typography>
            </Grid>
            <Grid item xs={7}>
              <RadioGroup
                row
                name="SmokeType"
                aria-label="position"
                value={props.minkaDetailData.SmokeType ?? '1'}
                defaultValue={1}
                onChange={props.handleChangeDetail}
              >
                <FormControlLabel value={1} control={<Radio color="primary" />} label="無" />
                <FormControlLabel value={2} control={<Radio color="primary" />} label="有（屋内）" />
                <FormControlLabel value={3} control={<Radio color="primary" />} label="有（屋外）" />
              </RadioGroup>
              <FormHelperText>{props.errorAttribute.SmokeType.ErrorMessage}</FormHelperText>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>トイレ</Typography>
            </Grid>
            <Grid item xs={7}>
              <RadioGroup
                row
                name="ToiletType"
                aria-label="position"
                value={props.minkaDetailData.ToiletType ?? '1'}
                onChange={props.handleChangeDetail}
              >
                <FormControlLabel value={1} control={<Radio color="primary" />} label="洋式" />
                <FormControlLabel value={2} control={<Radio color="primary" />} label="洋式(ウォシュレット)" />
                <FormControlLabel value={3} control={<Radio color="primary" />} label="和式" />
              </RadioGroup>
              <FormHelperText>{props.errorAttribute.ToiletType.ErrorMessage}</FormHelperText>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>エアコン</Typography>
            </Grid>
            <Grid item xs={7}>
              <RadioGroup
                row
                name="AirConditionar"
                aria-label="position"
                value={props.minkaDetailData.AirConditionar ?? '1'}
                onChange={props.handleChangeDetail}
              >
                <FormControlLabel value={1} control={<Radio color="primary" />} label="有" />
                <FormControlLabel value={0} control={<Radio color="primary" />} label="無" />
              </RadioGroup>
              <FormHelperText>{props.errorAttribute.AirConditionar.ErrorMessage}</FormHelperText>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>風呂の脱衣所</Typography>
            </Grid>
            <Grid item xs={7}>
              <RadioGroup
                row
                name="DressingRoom"
                aria-label="position"
                value={props.minkaDetailData.DressingRoom ?? '1'}
                onChange={props.handleChangeDetail}
              >
                <FormControlLabel value={1} control={<Radio color="primary" />} label="有" />
                <FormControlLabel value={0} control={<Radio color="primary" />} label="無" />
              </RadioGroup>
              <FormHelperText>{props.errorAttribute.DressingRoom.ErrorMessage}</FormHelperText>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>インターネット</Typography>
            </Grid>
            <Grid item xs={7}>
              <RadioGroup
                row
                name="InternetType"
                aria-label="position"
                value={props.minkaDetailData.InternetType ?? '1'}
                onChange={props.handleChangeDetail}
              >
                <FormControlLabel value={1} control={<Radio color="primary" />} label="有（Wifi）" />
                <FormControlLabel value={2} control={<Radio color="primary" />} label="有（有線）" />
                <FormControlLabel value={0} control={<Radio color="primary" />} label="無" />
              </RadioGroup>
              <FormHelperText>{props.errorAttribute.InternetType.ErrorMessage}</FormHelperText>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>バリアフリー対応</Typography>
            </Grid>
            <Grid item xs={7}>
              <RadioGroup
                row
                name="BarrierFreeCapability"
                aria-label="position"
                value={props.minkaDetailData.BarrierFreeCapability ?? '2'}
                onChange={props.handleChangeDetail}
              >
                <FormControlLabel value={1} control={<Radio color="primary" />} label="可" />
                <FormControlLabel value={0} control={<Radio color="primary" />} label="不可" />
                <FormControlLabel value={2} control={<Radio color="primary" />} label="未確認" />
              </RadioGroup>
              <FormHelperText>{props.errorAttribute.BarrierFreeCapability.ErrorMessage}</FormHelperText>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>生徒注意喚起対応</Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                sx={styles.textArea}
                value={props.minkaDetailData.AlertResponse}
                rows={4}
                multiline={true}
                variant="outlined"
                inputProps={{ maxLength: 255 }}
                name={'AlertResponse'}
                onChange={props.handleChangeDetail}
              />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={tabIndex} index={3}>
          <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
            水害ハザードマップ
          </Typography>{' '}
          <Grid container spacing={0.5} sx={styles.categoryBlock}>
            <Grid item xs={3}>
              <Typography sx={styles.label}>洪水</Typography>
            </Grid>
            <Grid item xs={7}>
              <RadioGroup
                row
                name="FloodHazard"
                aria-label="position"
                value={props.minkaDetailData.FloodHazard ?? '2'}
                onChange={props.handleChangeDetail}
              >
                <FormControlLabel value={0} control={<Radio color="primary" />} label="外" />
                <FormControlLabel value={1} control={<Radio color="primary" />} label="内" />
                <FormControlLabel value={2} control={<Radio color="primary" />} label="未確認" />
              </RadioGroup>
              <FormHelperText>{props.errorAttribute.FloodHazard.ErrorMessage}</FormHelperText>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>雨水出水</Typography>
            </Grid>
            <Grid item xs={7}>
              <RadioGroup
                row
                name="RainHazard"
                aria-label="position"
                value={props.minkaDetailData.RainHazard ?? '2'}
                onChange={props.handleChangeDetail}
              >
                <FormControlLabel value={0} control={<Radio color="primary" />} label="外" />
                <FormControlLabel value={1} control={<Radio color="primary" />} label="内" />
                <FormControlLabel value={2} control={<Radio color="primary" />} label="未確認" />
              </RadioGroup>
              <FormHelperText>{props.errorAttribute.RainHazard.ErrorMessage}</FormHelperText>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>高潮</Typography>
            </Grid>
            <Grid item xs={7}>
              <RadioGroup
                row
                name="HighTideHazard"
                aria-label="position"
                value={props.minkaDetailData.HighTideHazard ?? '2'}
                onChange={props.handleChangeDetail}
              >
                <FormControlLabel value={0} control={<Radio color="primary" />} label="外" />
                <FormControlLabel value={1} control={<Radio color="primary" />} label="内" />
                <FormControlLabel value={2} control={<Radio color="primary" />} label="未確認" />
              </RadioGroup>
              <FormHelperText>{props.errorAttribute.HighTideHazard.ErrorMessage}</FormHelperText>
            </Grid>
          </Grid>
          <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
            避難区域
          </Typography>
          <Grid container spacing={0.5} sx={styles.categoryBlock}>
            <Grid item xs={3}>
              <Typography sx={styles.label}>土砂災害警戒区域</Typography>
            </Grid>
            <Grid item xs={7}>
              <RadioGroup
                row
                name="LandslideWarningArea"
                aria-label="position"
                value={props.minkaDetailData.LandslideWarningArea ?? '2'}
                onChange={props.handleChangeDetail}
              >
                <FormControlLabel value={0} control={<Radio color="primary" />} label="外" />
                <FormControlLabel value={1} control={<Radio color="primary" />} label="内" />
                <FormControlLabel value={2} control={<Radio color="primary" />} label="未確認" />
              </RadioGroup>
              <FormHelperText>{props.errorAttribute.DressingRoom.ErrorMessage}</FormHelperText>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>津波災害警戒区域</Typography>
            </Grid>
            <Grid item xs={7}>
              <RadioGroup
                row
                name="TsunamiWarningArea"
                aria-label="position"
                value={props.minkaDetailData.TsunamiWarningArea ?? '2'}
                onChange={props.handleChangeDetail}
              >
                <FormControlLabel value={0} control={<Radio color="primary" />} label="外" />
                <FormControlLabel value={1} control={<Radio color="primary" />} label="内" />
                <FormControlLabel value={2} control={<Radio color="primary" />} label="未確認" />
              </RadioGroup>
              <FormHelperText>{props.errorAttribute.DressingRoom.ErrorMessage}</FormHelperText>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>災害時の避難場所</Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                sx={styles.textField}
                name={'EvacuationSite'}
                value={props.minkaDetailData.EvacuationSite}
                onChange={props.handleChangeDetail}
                inputProps={{ maxLength: 255 }}
                error={props.errorAttribute.EvacuationSite.IsError}
                helperText={props.errorAttribute.EvacuationSite.ErrorMessage}
              />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={tabIndex} index={4}>
          <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
            民家代表者
          </Typography>
          <Grid container spacing={0.5} sx={styles.categoryBlock}>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography sx={styles.label}>姓</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    sx={styles.textField}
                    style={{ paddingLeft: '2px' }}
                    inputProps={{ maxLength: 10 }}
                    name={'LastName'}
                    value={props.residentRepresentativeData.LastName}
                    onChange={props.handleChangeResidentRepresentative}
                    error={props.errorAttribute.LastName.IsError}
                    helperText={props.errorAttribute.LastName.ErrorMessage}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>名</Typography>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    sx={styles.textField}
                    name={'FirstName'}
                    value={props.residentRepresentativeData.FirstName}
                    onChange={props.handleChangeResidentRepresentative}
                    inputProps={{ maxLength: 10 }}
                    error={props.errorAttribute.FirstName.IsError}
                    helperText={props.errorAttribute.FirstName.ErrorMessage}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography sx={styles.label}>カナ性</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    sx={styles.textField}
                    style={{ paddingLeft: '2px' }}
                    name={'LastKanaName'}
                    value={props.residentRepresentativeData.LastKanaName}
                    onChange={props.handleChangeResidentRepresentative}
                    inputProps={{ maxLength: 10 }}
                    error={props.errorAttribute.LastKanaName.IsError}
                    helperText={props.errorAttribute.LastKanaName.ErrorMessage}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>カナ名</Typography>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    sx={styles.textField}
                    name={'FirstKanaName'}
                    value={props.residentRepresentativeData.FirstKanaName}
                    onChange={props.handleChangeResidentRepresentative}
                    inputProps={{ maxLength: 10 }}
                    error={props.errorAttribute.FirstKanaName.IsError}
                    helperText={props.errorAttribute.FirstKanaName.ErrorMessage}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>性別</Typography>
            </Grid>
            <Grid item xs={7}>
              <RadioGroup
                row
                aria-label="position"
                name="Sex"
                value={props.residentRepresentativeData.Sex ?? '1'}
                onChange={props.handleChangeResidentRepresentative}
              >
                <FormControlLabel value={1} control={<Radio color="primary" />} label="男性" />
                <FormControlLabel value={2} control={<Radio color="primary" />} label="女性" />
              </RadioGroup>
              <FormHelperText>{props.errorAttribute.Sex.ErrorMessage}</FormHelperText>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography sx={styles.label}>生年月日</Typography>
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
                    <DesktopDatePicker
                      label="yyyy/mm/dd"
                      mask="____/__/__"
                      value={new Date(props.residentRepresentativeData.BirthDay ?? '')}
                      onChange={(newValue) => props.handleChangeBirthday(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          error={props.errorAttribute.BirthDay.IsError}
                          helperText={props.errorAttribute.BirthDay.ErrorMessage}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={3}>
                  <Typography sx={styles.label}>年齢</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography sx={styles.labelValue}>{props.representativeAge}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>携帯電話番号</Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                sx={styles.textField}
                name={'Tel'}
                value={props.residentRepresentativeData.Tel}
                onChange={props.handleChangeResidentRepresentative}
                inputProps={{ maxLength: 13 }}
                error={props.errorAttribute.Tel.IsError}
                helperText={props.errorAttribute.Tel.ErrorMessage}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>メール</Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                sx={styles.textField}
                name={'Mail'}
                value={props.residentRepresentativeData.Mail}
                onChange={props.handleChangeResidentRepresentative}
                inputProps={{ maxLength: 255 }}
                error={props.errorAttribute.Mail.IsError}
                helperText={props.errorAttribute.Mail.ErrorMessage}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>職業</Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                sx={styles.textField}
                name={'Occupation'}
                value={props.residentRepresentativeData.Occupation}
                onChange={props.handleChangeResidentRepresentative}
                inputProps={{ maxLength: 20 }}
                error={props.errorAttribute.Occupation.IsError}
                helperText={props.errorAttribute.Occupation.ErrorMessage}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>職歴</Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                sx={styles.textArea}
                value={props.residentRepresentativeData.WorkHistory}
                rows={4}
                multiline={true}
                variant="outlined"
                inputProps={{ maxLength: 255 }}
                name={'WorkHistory'}
                onChange={props.handleChangeResidentRepresentative}
                error={props.errorAttribute.WorkHistory.IsError}
                helperText={props.errorAttribute.WorkHistory.ErrorMessage}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>人物像</Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                sx={styles.textArea}
                value={props.residentRepresentativeData.Character}
                rows={4}
                multiline={true}
                variant="outlined"
                inputProps={{ maxLength: 255 }}
                name={'Character'}
                onChange={props.handleChangeResidentRepresentative}
                error={props.errorAttribute.Character.IsError}
                helperText={props.errorAttribute.Character.ErrorMessage}
              />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={tabIndex} index={5}>
          <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
            口座情報
          </Typography>
          <Grid container spacing={0.5} sx={styles.categoryBlock}>
            <Grid item xs={3}>
              <Typography sx={styles.label}>銀行名</Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                sx={styles.textField}
                name={'BankName'}
                value={props.minkaDetailData.BankName}
                onChange={props.handleChangeDetail}
                inputProps={{ maxLength: 16 }}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>支店名</Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                sx={styles.textField}
                name={'BankBranchName'}
                value={props.minkaDetailData.BankBranchName}
                onChange={props.handleChangeDetail}
                inputProps={{ maxLength: 16 }}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>口座種別</Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                sx={styles.textField}
                name={'AccountType'}
                value={props.minkaDetailData.AccountType}
                onChange={props.handleChangeDetail}
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>口座番号</Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                sx={styles.textField}
                name={'AccountNumber'}
                value={props.minkaDetailData.AccountNumber}
                onChange={props.handleChangeDetail}
                inputProps={{ maxLength: 7 }}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>口座名</Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                sx={styles.textField}
                name={'AccountName'}
                value={props.minkaDetailData.AccountName}
                onChange={props.handleChangeDetail}
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>口座カナ名</Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                sx={styles.textField}
                name={'AccountNameKana'}
                value={props.minkaDetailData.AccountNameKana}
                onChange={props.handleChangeDetail}
                inputProps={{ maxLength: 40 }}
              />
            </Grid>
          </Grid>
        </TabPanel>

        {props.isUpdate ? (
          <div>
            <TabPanel value={tabIndex} index={6}>
              {' '}
              <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
                同居人
              </Typography>
              <Grid container spacing={0.5} sx={styles.categoryBlock}>
                <Grid item xs={2}></Grid>
                <Grid item xs={8}>
                  <Button
                    variant="contained"
                    sx={styles.createButton}
                    size="small"
                    onClick={() => props.handleClickResidentCreateButton()}
                  >
                    追加
                  </Button>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>姓</TableCell>
                          <TableCell>名</TableCell>
                          <TableCell>性別</TableCell>
                          <TableCell>生年月日</TableCell>
                          <TableCell>年齢</TableCell>
                          <TableCell>続柄</TableCell>
                          <TableCell>連絡先</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {props.residentData
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((resident, index) => (
                            <TableRow key={index}>
                              <TableCell component="td" scope="row">
                                <Button
                                  variant="contained"
                                  sx={styles.listButton}
                                  size="small"
                                  onClick={() => props.handleClickResidentDeleteButton(resident)}
                                >
                                  削除
                                </Button>
                              </TableCell>
                              <TableCell component="td" scope="row">
                                {resident.LastName}
                              </TableCell>
                              <TableCell component="td" scope="row">
                                {resident.FirstName}
                              </TableCell>
                              <TableCell component="td" scope="row">
                                {resident.SexDisplayName}
                              </TableCell>
                              <TableCell component="td" scope="row">
                                {resident.BirthDay != '0001-01-01' ? resident.BirthDay : '--'}
                              </TableCell>
                              <TableCell component="td" scope="row">
                                {resident.BirthDay != '0001-01-01' ? getAge(new Date(resident.BirthDay!)) : '--'}
                              </TableCell>
                              <TableCell component="td" scope="row">
                                {resident.ResidentsRelationCodeDisplayName}
                              </TableCell>
                              <TableCell component="td" scope="row">
                                {resident.Tel}
                              </TableCell>
                              <TableCell component="td" scope="row" sx={styles.deleteCell} align="right">
                                <Button
                                  variant="contained"
                                  sx={styles.listButton}
                                  size="small"
                                  onClick={() => props.handleClickResidentEditButton(resident)}
                                >
                                  編集
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[7, 25, 100]}
                    component="div"
                    count={props.residentData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={tabIndex} index={7}>
              {' '}
              <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
                登録車両
              </Typography>
              <Grid container spacing={0.5} sx={styles.categoryBlock}>
                <Grid item xs={2}></Grid>
                <Grid item xs={8}>
                  <Button
                    variant="contained"
                    sx={styles.createButton}
                    size="small"
                    onClick={() => props.handleClickVehicleCreateButton()}
                  >
                    追加
                  </Button>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>車両ナンバー</TableCell>
                          <TableCell>車検期限日</TableCell>
                          <TableCell>任意保険期限日</TableCell>
                          <TableCell>車種</TableCell>
                          <TableCell>人数</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {props.vehicleData
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((vehicle, index) => (
                            <TableRow key={index}>
                              <TableCell component="td" scope="row">
                                <Button
                                  variant="contained"
                                  sx={styles.listButton}
                                  size="small"
                                  onClick={() => props.handleClickVehicleDeleteButton(vehicle)}
                                >
                                  削除
                                </Button>
                              </TableCell>
                              <TableCell component="td" scope="row">
                                {vehicle.CarNo}
                              </TableCell>
                              <TableCell component="td" scope="row">
                                {vehicle.InspectionDate}
                              </TableCell>
                              <TableCell component="td" scope="row">
                                {vehicle.InsuranceEndDate}
                              </TableCell>
                              <TableCell component="td" scope="row">
                                {vehicle.CarType}
                              </TableCell>
                              <TableCell component="td" scope="row">
                                {vehicle.Passenger}
                              </TableCell>
                              <TableCell component="td" scope="row" sx={styles.deleteCell} align="right">
                                <Button
                                  variant="contained"
                                  sx={styles.listButton}
                                  size="small"
                                  onClick={() => props.handleClickVehicleEditButton(vehicle)}
                                >
                                  編集
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[7, 25, 100]}
                    component="div"
                    count={props.vehicleData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={tabIndex} index={8}>
              {' '}
              <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
                民家体験メニュー
              </Typography>
              <Grid container spacing={0.5} sx={styles.categoryBlock}>
                <Grid item xs={2}></Grid>
                <Grid item xs={8}>
                  <Button variant="contained" sx={styles.createButton} onClick={props.handleAddActivity}>
                    追加
                  </Button>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>体験内容</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {props.activityData.map((activity, index) => (
                          <TableRow key={index}>
                            <TableCell component="td" scope="row">
                              <Button
                                variant="contained"
                                sx={styles.listButton}
                                size="small"
                                onClick={() => props.handleClickActivityDeleteButton(activity)}
                              >
                                削除
                              </Button>
                            </TableCell>
                            <TableCell component="td" scope="row">
                              {activity.Activity}
                            </TableCell>
                            <TableCell component="td" scope="row" sx={styles.deleteCell} align="right"></TableCell>
                          </TableRow>
                        ))}
                        {props.activityStatus ? (
                          <TableRow>
                            <TableCell component="td" scope="row">
                              <Button
                                variant="contained"
                                sx={styles.listButton}
                                size="small"
                                onClick={props.handleCancelActivity}
                              >
                                キャンセル
                              </Button>
                            </TableCell>
                            <TableCell component="td" scope="row">
                              <TextField
                                value={props.activity}
                                onChange={props.handleChangeActivity}
                                sx={styles.textField}
                                error={props.errorAttribute.Activity.IsError}
                                helperText={props.errorAttribute.Activity.ErrorMessage}
                              />
                            </TableCell>
                            <TableCell component="td" scope="row" sx={styles.deleteCell} align="right">
                              <Button
                                variant="contained"
                                sx={styles.listButton}
                                size="small"
                                onClick={props.handleClickActivityCreateButton}
                              >
                                登録
                              </Button>
                            </TableCell>
                          </TableRow>
                        ) : (
                          ''
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={tabIndex} index={9}>
              {' '}
              <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
                休業月
              </Typography>
              <Grid container spacing={0.5} sx={styles.categoryBlock}>
                <Grid item xs={2}></Grid>
                <Grid item xs={8}>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>年度</TableCell>
                          <TableCell>4月</TableCell>
                          <TableCell>5月</TableCell>
                          <TableCell>6月</TableCell>
                          <TableCell>7月</TableCell>
                          <TableCell>8月</TableCell>
                          <TableCell>9月</TableCell>
                          <TableCell>10月</TableCell>
                          <TableCell>11月</TableCell>
                          <TableCell>12月</TableCell>
                          <TableCell>1月</TableCell>
                          <TableCell>2月</TableCell>
                          <TableCell>3月</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {props.closingSettingData
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((closingSetting, index) => (
                            <TableRow key={index}>
                              <TableCell component="td" scope="row">
                                {closingSetting.SchoolYearCode}
                              </TableCell>
                              <TableCell component="td" scope="row">
                                {closingSetting.Period1 === '1' ? '休' : '-'}
                              </TableCell>
                              <TableCell component="td" scope="row">
                                {closingSetting.Period2 === '1' ? '休' : '-'}
                              </TableCell>
                              <TableCell component="td" scope="row">
                                {closingSetting.Period3 === '1' ? '休' : '-'}
                              </TableCell>
                              <TableCell component="td" scope="row">
                                {closingSetting.Period4 === '1' ? '休' : '-'}
                              </TableCell>
                              <TableCell component="td" scope="row">
                                {closingSetting.Period5 === '1' ? '休' : '-'}
                              </TableCell>
                              <TableCell component="td" scope="row">
                                {closingSetting.Period6 === '1' ? '休' : '-'}
                              </TableCell>
                              <TableCell component="td" scope="row">
                                {closingSetting.Period7 === '1' ? '休' : '-'}
                              </TableCell>
                              <TableCell component="td" scope="row">
                                {closingSetting.Period8 === '1' ? '休' : '-'}
                              </TableCell>
                              <TableCell component="td" scope="row">
                                {closingSetting.Period9 === '1' ? '休' : '-'}
                              </TableCell>
                              <TableCell component="td" scope="row">
                                {closingSetting.Period10 === '1' ? '休' : '-'}
                              </TableCell>
                              <TableCell component="td" scope="row">
                                {closingSetting.Period11 === '1' ? '休' : '-'}
                              </TableCell>
                              <TableCell component="td" scope="row">
                                {closingSetting.Period12 === '1' ? '休' : '-'}
                              </TableCell>
                              <TableCell component="td" scope="row" sx={styles.deleteCell} align="right">
                                <Button
                                  variant="contained"
                                  sx={styles.createButton}
                                  size="small"
                                  onClick={() => props.handleClickClosingSettingEditButton(closingSetting)}
                                >
                                  編集
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={tabIndex} index={10}>
              {' '}
              <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
                メモ
              </Typography>
              <Grid container spacing={0.5} sx={styles.categoryBlock}>
                <Grid item xs={2}></Grid>
                <Grid item xs={8}>
                  <TextField
                    sx={styles.textArea}
                    value={props.minkaDetailData.Memo}
                    rows={4}
                    multiline={true}
                    variant="outlined"
                    inputProps={{ maxLength: 255 }}
                    name={'Memo'}
                    onChange={props.handleChangeDetail}
                  />
                </Grid>
              </Grid>
            </TabPanel>
          </div>
        ) : (
          ''
        )}
        <Grid container>
          <Grid item xs={10}>
            <Button variant="contained" sx={styles.button} onClick={pushback}>
              閉じる
            </Button>
            {/*<Link to={'/minka/management/list'}>
              <Button variant="contained" sx={styles.button}>
                閉じる
              </Button>
            </Link>*/}

            <Button variant="contained" sx={styles.button} onClick={props.handleClickRegist}>
              登録
            </Button>
          </Grid>
        </Grid>
      </Container>
      <AlertDialog
        handleDialogClose={props.handleAlertClose}
        dialogOpen={props.alertOpen}
        content={props.alertContent}
      />
    </GenericTemplate>
  );
};

function TabPanel(props: { children?: React.ReactNode; index: any; value: any }) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
export default MinkaDetail;
