import FormErrorAttribute from 'types/FormErrorAttribute';

export interface ErrorData {
  Header: FormErrorAttribute;
  OldPassword: FormErrorAttribute;
  Password: FormErrorAttribute;
  PasswordConfirm: FormErrorAttribute;
  Name: FormErrorAttribute;
  KanaName: FormErrorAttribute;
  BranchId: FormErrorAttribute;
  Mail1: FormErrorAttribute;
  Mail2: FormErrorAttribute;
  Mail3: FormErrorAttribute;
}

export const CreateEmptyErrorData = () => {
  return {
    Header: {} as FormErrorAttribute,
    OldPassword: {} as FormErrorAttribute,
    Password: {} as FormErrorAttribute,
    PasswordConfirm: {} as FormErrorAttribute,
    Name: {} as FormErrorAttribute,
    KanaName: {} as FormErrorAttribute,
    BranchId: {} as FormErrorAttribute,
    Mail1: {} as FormErrorAttribute,
    Mail2: {} as FormErrorAttribute,
    Mail3: {} as FormErrorAttribute,
  } as ErrorData;
};
