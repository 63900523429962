import { AddReservationDay, AddReservationDayMutation, ReservationDay } from 'API';
import { useAPI } from 'contexts/APIRequestContext';
import React, { useCallback, useEffect, useState } from 'react';
import { CreateEmptyErrorData, ErrorData } from './AddDaysErrorData';
import { getYYYMMDD } from 'util/DateUtil';
import { addReservationDay } from 'graphql/mutations';

const UseAddDaysDialog = ({
  handleDialogClose,
  dialogOpen,
  reservationId,
  updateDatetime,
  initialValue,
}: {
  handleDialogClose: (_?: { days: ReservationDay[]; updateDatetime: string }) => void;
  dialogOpen: boolean;
  reservationId: string;
  updateDatetime: string;
  initialValue: string;
}) => {
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [inputData, setInputData] = useState({} as ReservationDay);
  const api = useAPI();
  const [, setError] = useState<undefined>();

  /**
   * 閉じる
   */
  const handleClose = (): void => {
    handleDialogClose();
  };

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  useEffect(() => {
    if (dialogOpen) {
      setInputData({
        ReservationId: reservationId,
        ReservedDay: initialValue,
      } as ReservationDay);
      setErrorAttribute({ ...CreateEmptyErrorData() });
    }
  }, [dialogOpen]);

  /**
   * 予約日程登録処理
   */
  async function executeRegistDay() {
    const response = (
      (await api.graphql({
        query: addReservationDay,
        variables: {
          data: {
            ReservationId: reservationId,
            ReservationDays: [inputData.ReservedDay],
            UpdateDatetime: updateDatetime,
          },
        },
      })) as AddReservationDayMutation
    ).addReservationDay;

    if (response?.IsSuccess) {
      let data = response.Body! as AddReservationDay;
      handleDialogClose({
        days: data.ReservationDays ?? ([] as ReservationDay[]),
        updateDatetime: data.UpdateDatetime,
      });
    } else if (response?.IsSystemError) {
      throwError(response.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  /**
   * 予約日程　登録
   */
  const handleRegist = (): void => {
    executeRegistDay();
  };

  /**
   * 予約日程　宿泊日更新
   */
  const handleChangeDays = (value: any) => {
    setInputData({ ...inputData, ReservedDay: getYYYMMDD(value, '/') });

    // 値が変更されたらエラー情報をクリア
    setErrorAttribute({ ...errorAttribute, ReservationDays: { IsError: false, ErrorMessage: '' } });
  };

  return {
    inputData,
    errorAttribute,
    handleRegist,
    handleClose,
    handleChangeDays,
    dialogOpen,
  };
};
export default UseAddDaysDialog;
