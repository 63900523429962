import FormErrorAttribute from 'types/FormErrorAttribute';

export interface ErrorData {
  Header: FormErrorAttribute;
  CompanyId: FormErrorAttribute;
  BranchId: FormErrorAttribute;
  ManagingUserName: FormErrorAttribute;
  CheckOutPlace: FormErrorAttribute;
  CheckInPlace: FormErrorAttribute;
  Memo: FormErrorAttribute;
}

export const CreateEmptyErrorData = () => {
  return {
    Header: {} as FormErrorAttribute,
    CompanyId: {} as FormErrorAttribute,
    BranchId: {} as FormErrorAttribute,
    ManagingUserName: {} as FormErrorAttribute,
    CheckOutPlace: {} as FormErrorAttribute,
    CheckInPlace: {} as FormErrorAttribute,
    Memo: {} as FormErrorAttribute,
  } as ErrorData;
};
