import { UpdateDurationDateMutation } from 'API';
import { useAPI } from 'contexts/APIRequestContext';
import { useMaster } from 'contexts/CommonMasterContext';
import { updateDurationDate } from 'graphql/mutations';
import { useCallback, useEffect, useState } from 'react';
import { ReservationDay } from 'API';
import { getYYYMMDD } from 'util/DateUtil';
import { CreateEmptyErrorData, ErrorData } from './DurationDateErrorData';

const UseDurationDateDialog = ({
  handleDialogClose,
  dialogOpen,
  reservationDay,
  reservationId,
  updateDatetime,
}: {
  handleDialogClose: (_?: ReservationDay, updateDatetime?: string) => void;
  dialogOpen: boolean;
  reservationDay: ReservationDay;
  reservationId: string;
  updateDatetime: string;
}) => {
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [inputData, setInputData] = useState({} as ReservationDay);
  const master = useMaster();
  const api = useAPI();
  const [, setError] = useState<undefined>();

  useEffect(() => {
    if (dialogOpen) {
      // 初期化
      setInputData(reservationDay!);

      setErrorAttribute({ ...CreateEmptyErrorData() });
    }
  }, [dialogOpen]);

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  /**
   * 仮予約期日変更時
   */
  const handleChangeDurationDate = (value: Date | null) => {
    if (value) {
      setInputData({ ...inputData, DurationDate: getYYYMMDD(value, '/') });
    } else {
      setInputData({ ...inputData, DurationDate: '' });
    }

    // 値が変更されたらエラー情報をクリア
    setErrorAttribute({ ...errorAttribute, DurationDate: { IsError: false, ErrorMessage: '' } });
  };

  /**
   * 仮予約期限更新処理
   */
  async function executeUpdateDurationDate() {
    const response = (
      (await api.graphql({
        query: updateDurationDate,
        variables: {
          data: {
            ReservationId: reservationId,
            ReservedDay: inputData.ReservedDay,
            DurationDate: inputData.DurationDate,
            UpdateDatetime: updateDatetime,
          },
        },
      })) as UpdateDurationDateMutation
    ).updateDurationDate;

    if (response?.IsSuccess) {
      handleDialogClose(inputData, response.Body!);
    } else if (response?.IsSystemError) {
      throwError(response.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  /**
   * 仮予約期限　登録
   */
  const handleRegist = (): void => {
    executeUpdateDurationDate();
  };

  /**
   * 仮予約期限　閉じる
   */
  const handleClose = (): void => {
    handleDialogClose();
  };

  return {
    inputData,
    guestCount: reservationDay.DurationDate,
    handleChangeDurationDate,
    handleRegist,
    handleClose,
    dialogOpen,
    errorAttribute,
  };
};
export default UseDurationDateDialog;
