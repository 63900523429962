import FormErrorAttribute from 'types/FormErrorAttribute';

export interface ErrorData {
  PrefectureCode: FormErrorAttribute;
  DisplayStartDate: FormErrorAttribute;
}

export const CreateEmptyErrorData = () => {
  return {
    PrefectureCode: {} as FormErrorAttribute,
    DisplayStartDate: {} as FormErrorAttribute,
  } as ErrorData;
};
