import { useState, useEffect } from 'react';
import { CreateEmptyErrorData } from './AccountSettingErrorData';
import { AccountSettingData } from './AccountSettingData';
import { useNavigate } from 'react-router-dom';
import { useMaster } from 'contexts/CommonMasterContext';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';
import { checkAuthority } from 'util/AuthorityCheck';
import { ICognitoUserAttributeData } from 'amazon-cognito-identity-js';
import { Branch } from 'API';
import { FindMSchoolsQuery, MSchools, SchoolRequestData } from 'API';
import { graphqlOperation } from 'aws-amplify';
import { useAPI } from 'contexts/APIRequestContext';
import { findMSchools } from 'graphql/queries';
import { AuthorityCode } from 'constant/Constant';
import { scrollToTop } from 'util/Scroll';

const UseAccountSetting = () => {
  const [branchList, setBranchList] = useState([] as Branch[]);
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const navigate = useNavigate();
  const master = useMaster();
  const { user } = useAuthenticator();
  const userData = new UserData(user);
  const [inputData, setInputData] = useState({} as AccountSettingData);
  const [companyName, setCompanyName] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const api = useAPI();

  /**
   * 入力値変更
   * @param event イベント
   */
  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    setInputData({ ...inputData, [name]: value });

    // 値が変更されたらエラー情報をクリア
    setErrorAttribute({ ...errorAttribute, [name]: { isError: false, errorMessage: '' } });
  };

  /* メールアドレスのパターン 正規表現*/
  let mailPattern = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/;
  /* パスワードのパターン 正規表現 */
  let passwordPattern =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$*.{}()?"!@#%&,><':;|_~`=+-])[A-Za-z0-9$*.{}()?"!@#%&,><':;|_~`=+-]{8,}$/;

  const handleClickRegist = () => {
    let isError = false;
    let _errorAttribute = CreateEmptyErrorData();
    // 入力値チェック
    if ((inputData.Password || inputData.PasswordConfirm) && !inputData.OldPassword) {
      _errorAttribute.OldPassword = { IsError: true, ErrorMessage: '入力してください' };
      isError = true;
    }
    if (inputData.Password && !inputData.PasswordConfirm) {
      _errorAttribute.PasswordConfirm = { IsError: true, ErrorMessage: '入力してください' };
      isError = true;
    }
    if (!inputData.Password && inputData.PasswordConfirm) {
      _errorAttribute.Password = { IsError: true, ErrorMessage: '入力してください' };
      isError = true;
    }
    if (inputData.Password && inputData.PasswordConfirm && inputData.Password !== inputData.PasswordConfirm) {
      _errorAttribute.PasswordConfirm = { IsError: true, ErrorMessage: 'パスワードが一致しません。' };
      isError = true;
    }
    if (!isError && inputData.Password && !passwordPattern.test(inputData.Password)) {
      _errorAttribute.Password = {
        IsError: true,
        ErrorMessage: '8文字以上で、英数字記号を1つ以上含めて入力してください',
      };
      isError = true;
    }
    if (!inputData.Name) {
      _errorAttribute.Name = { IsError: true, ErrorMessage: '入力してください' };
      isError = true;
    }
    if (!inputData.KanaName) {
      _errorAttribute.KanaName = { IsError: true, ErrorMessage: '入力してください' };
      isError = true;
    }
    if (!inputData.BranchId) {
      _errorAttribute.BranchId = { IsError: true, ErrorMessage: '入力してください' };
      isError = true;
    }
    if (!inputData.Mail1) {
      _errorAttribute.Mail1 = { IsError: true, ErrorMessage: '入力してください' };
      isError = true;
    }
    if (inputData.Mail1 && !mailPattern.test(inputData.Mail1)) {
      _errorAttribute.Mail1 = { IsError: true, ErrorMessage: '正しいメールアドレスの形式で入力してください' };
      isError = true;
    }
    if (inputData.Mail2 && !mailPattern.test(inputData.Mail2)) {
      _errorAttribute.Mail2 = { IsError: true, ErrorMessage: '正しいメールアドレスの形式で入力してください' };
      isError = true;
    }
    if (inputData.Mail3 && !mailPattern.test(inputData.Mail3)) {
      _errorAttribute.Mail3 = { IsError: true, ErrorMessage: '正しいメールアドレスの形式で入力してください' };
      isError = true;
    }
    if (isError) {
      _errorAttribute.Header = { IsError: true, ErrorMessage: '入力エラーがあります。エラー内容を確認してください。' };
      setErrorAttribute(_errorAttribute);
      scrollToTop();
      return;
    } else {
      setErrorAttribute(CreateEmptyErrorData());
    }

    const f = () => {
      // ユーザ情報更新
      user.updateAttributes(
        [
          { Name: 'name', Value: inputData.Name } as ICognitoUserAttributeData,
          { Name: 'custom:kana_name', Value: inputData.KanaName } as ICognitoUserAttributeData,
          { Name: 'custom:branchid', Value: inputData.BranchId ?? '' } as ICognitoUserAttributeData,
          { Name: 'email', Value: inputData.Mail1 } as ICognitoUserAttributeData,
          { Name: 'custom:mail_2', Value: inputData.Mail2 ?? '' } as ICognitoUserAttributeData,
          { Name: 'custom:mail_3', Value: inputData.Mail3 ?? '' } as ICognitoUserAttributeData,
        ],
        (err: Error | undefined, result: string | undefined) => {
          if (err) {
            alert(err);
          } else {
            alert('更新が完了しました');
          }
        }
      );
    };
    // パスワード更新
    if (inputData.Password) {
      user.changePassword(
        inputData.OldPassword,
        inputData.Password,
        (err: Error | undefined, result: string | undefined) => {
          if (err) {
            alert(err);
            return;
          } else {
            f();
          }
        }
      );
    } else {
      f();
    }
  };

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('AccountSetting', userData.authorityCode)) {
      navigate('/');
    }
    // Cognitoより最新のユーザー情報を取得
    user.getUserData((error, userData) => {
      setInputData({
        Name: userData?.UserAttributes.find((x) => x.Name === 'name')?.Value,
        KanaName: userData?.UserAttributes.find((x) => x.Name === 'custom:kana_name')?.Value,
        BranchId: userData?.UserAttributes.find((x) => x.Name === 'custom:branchid')?.Value,
        Mail1: userData?.UserAttributes.find((x) => x.Name === 'email')?.Value,
        Mail2: userData?.UserAttributes.find((x) => x.Name === 'custom:mail_2')?.Value,
        Mail3: userData?.UserAttributes.find((x) => x.Name === 'custom:mail_3')?.Value,
      } as AccountSettingData);
    });
    if (userData.authorityCode !== AuthorityCode.School) {
      // 学校職員以外の利用者の場合、会社名と支店名リストを取得
      master.waitForInitialized(() => {
        setCompanyName(master.getCompanies().find((x) => x.CompanyId === userData.companyId)?.CompanyName ?? '');

        let _branchList = master.getBranchs(userData.companyId) as Branch[];
        if (_branchList) {
          setBranchList(_branchList);
        } else {
          setBranchList([] as Branch[]);
        }
      });
    } else {
      // 学校職員の場合、所属している学校名を取得
      const fetchSchool = async () => {
        const response = (await api.graphql(
          graphqlOperation(findMSchools, {
            data: { SchoolId: userData.schoolId } as SchoolRequestData,
          })
        )) as FindMSchoolsQuery;

        if (response?.findMSchools?.Body?.length) {
          setSchoolName((response?.findMSchools?.Body as MSchools[])[0].SchoolName);
        }
      };
      fetchSchool();
    }
  }, []);

  return {
    inputData,
    errorAttribute,
    branchList,
    companyName,
    schoolName,
    handleClickRegist,
    handleChangeInput,
  };
};
export default UseAccountSetting;
