import { FC, useState } from 'react';
import GenericTemplate from '../../../template/GenericTemplate';
import React from 'react';
import { FormHelperText, Container, FormControl } from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import Grid from '@mui/material/Grid/Grid';
import SelectFormControl from '../../../parts/SelectFormControl';
import { SelectValue } from '../../../../types/SelectValue';
import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField/TextField';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import { Link } from 'react-router-dom';
import { Branch, Company, CompanyBranch, MSchools, User, UserRequest } from '../../../../API';
import Radio from '@mui/material/Radio/Radio';
import RadioGroup from '@mui/material/RadioGroup/RadioGroup';
import { UserData } from 'types/UserData';
import Paper from '@mui/material/Paper/Paper';
import InputBase from '@mui/material/InputBase/InputBase';
import Divider from '@mui/material/Divider/Divider';
import IconButton from '@mui/material/IconButton/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import SchoolSearchDialog from '../../../reserve/reception/dialog/schoolSelect';
import { UserCategoryCode } from 'constant/Constant';
import { ErrorData } from './UserErrorData';

const styles = {
  searchField: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
  },

  label: {
    margin: 'auto',
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
  },

  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
  },

  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
    '& > div > input': {
      maxHeight: '10px',
    },
  },

  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },

  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    ml: 1,
    float: 'right',
    mt: 1,
  },

  textField: {
    width: '100%',
    '& input': {
      padding: '8px 14px',
    },
  },
};

export interface Props {
  branchList: Branch[];
  companyList: CompanyBranch[];
  inputData: UserRequest;
  handleChangeDetail: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeCompany: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleRegistUser: () => void;
  isUpdateMode: boolean;
  handleChangeUserCategoryCode: (_: React.ChangeEvent<HTMLInputElement>) => void;
  searchSchoolOpen: boolean;
  handleSearchSchoolOpen: () => void;
  selectSchool: MSchools;
  handleSearchSchoolClose: (_?: MSchools) => void;
  errorAttribute: ErrorData;
}

const UserDetail: FC<Props> = ({
  branchList,
  companyList,
  inputData,
  handleChangeDetail,
  handleChangeCompany,
  handleRegistUser,
  isUpdateMode,
  handleChangeUserCategoryCode,
  searchSchoolOpen,
  handleSearchSchoolOpen,
  selectSchool,
  handleSearchSchoolClose,
  errorAttribute,
}) => {
  return (
    <GenericTemplate title="利用者詳細">
      <Container sx={styles.searchField}>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          利用者情報
        </Typography>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>ユーザID</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'UserId'}
              value={inputData.UserId}
              onChange={handleChangeDetail}
              sx={styles.textField}
              inputProps={{ maxLength: 20 }}
              disabled={isUpdateMode}
              error={errorAttribute.UserId.IsError}
              helperText={errorAttribute.UserId.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>パスワード</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'Password'}
              value={inputData.Password}
              onChange={handleChangeDetail}
              sx={styles.textField}
              inputProps={{ maxLength: 64, autoComplete: 'off' }}
              error={errorAttribute.Password.IsError}
              helperText={errorAttribute.Password.ErrorMessage}
              placeholder="パスワードを変更する場合に入力してください。変更しない場合は空白のままとしてください。"
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>氏名</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'UserName'}
              value={inputData.UserName}
              onChange={handleChangeDetail}
              sx={styles.textField}
              inputProps={{ maxLength: 20 }}
              error={errorAttribute.UserName.IsError}
              helperText={errorAttribute.UserName.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>カナ氏名</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'UserKanaName'}
              value={inputData.UserKanaName}
              onChange={handleChangeDetail}
              sx={styles.textField}
              inputProps={{ maxLength: 40 }}
              error={errorAttribute.UserKanaName.IsError}
              helperText={errorAttribute.UserKanaName.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>利用者種別</Typography>
          </Grid>
          <Grid item xs={7}>
            <FormControl component="fieldset" error={errorAttribute.UserCategoryCode.IsError}>
              <RadioGroup
                row
                aria-label="UserCategoryCode"
                name="UserCategoryCode"
                value={inputData.UserCategoryCode ?? '01'}
                onChange={handleChangeUserCategoryCode}
              >
                <FormControlLabel
                  value={UserCategoryCode.MinpakuCompany}
                  control={<Radio color="primary" />}
                  label="民泊事業者"
                  disabled={isUpdateMode}
                />
                <FormControlLabel
                  value={UserCategoryCode.Agent}
                  control={<Radio color="primary" />}
                  label="旅行代理店"
                  disabled={isUpdateMode}
                />
                <FormControlLabel
                  value={UserCategoryCode.ServiceCompany}
                  control={<Radio color="primary" />}
                  label="サービス提供業者"
                  disabled={isUpdateMode}
                />
                <FormControlLabel
                  value={UserCategoryCode.Minka}
                  control={<Radio color="primary" />}
                  label="民家"
                  disabled={isUpdateMode}
                />
                <FormControlLabel
                  value={UserCategoryCode.School}
                  control={<Radio color="primary" />}
                  label="学校"
                  disabled={isUpdateMode}
                />
              </RadioGroup>
              <FormHelperText>{errorAttribute.UserCategoryCode.ErrorMessage}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>利用者権限</Typography>
          </Grid>
          <Grid item xs={7}>
            <FormControl error={errorAttribute.AuthorityCode.IsError}>
              <RadioGroup
                row
                aria-label="AuthorityCode"
                name="AuthorityCode"
                value={inputData.AuthorityCode ?? ''}
                onChange={handleChangeDetail}
              >
                {inputData.UserCategoryCode === UserCategoryCode.MinpakuCompany ? (
                  <FormControlLabel value="1" control={<Radio color="primary" />} label="予約管理者" />
                ) : (
                  ''
                )}
                {inputData.UserCategoryCode === UserCategoryCode.MinpakuCompany ? (
                  <FormControlLabel value="2" control={<Radio color="primary" />} label="民泊受入管理者" />
                ) : (
                  ''
                )}
                {inputData.UserCategoryCode === UserCategoryCode.Agent ? (
                  <FormControlLabel value="3" control={<Radio color="primary" />} label="旅行代理店仕入" />
                ) : (
                  ''
                )}
                {inputData.UserCategoryCode === UserCategoryCode.Agent ? (
                  <FormControlLabel value="4" control={<Radio color="primary" />} label="旅行代理店営業" />
                ) : (
                  ''
                )}
                {inputData.UserCategoryCode === UserCategoryCode.Agent ? (
                  <FormControlLabel value="5" control={<Radio color="primary" />} label="旅行代理店仕入兼営業" />
                ) : (
                  ''
                )}
                {inputData.UserCategoryCode === UserCategoryCode.ServiceCompany ? (
                  <FormControlLabel value="6" control={<Radio color="primary" />} label="サービス提供事業者" />
                ) : (
                  ''
                )}
                {inputData.UserCategoryCode === UserCategoryCode.Minka ? (
                  <FormControlLabel value="7" control={<Radio color="primary" />} label="民家" />
                ) : (
                  ''
                )}
                {inputData.UserCategoryCode === UserCategoryCode.School ? (
                  <FormControlLabel value="8" control={<Radio color="primary" />} label="学校" />
                ) : (
                  ''
                )}
              </RadioGroup>
              <FormHelperText>{errorAttribute.AuthorityCode.ErrorMessage}</FormHelperText>
            </FormControl>
          </Grid>
          {inputData.UserCategoryCode === UserCategoryCode.MinpakuCompany ||
          inputData.UserCategoryCode === UserCategoryCode.Agent ||
          inputData.UserCategoryCode === UserCategoryCode.ServiceCompany ||
          inputData.UserCategoryCode === UserCategoryCode.Minka ? (
            <Grid container spacing={0.5} sx={styles.categoryBlock}>
              <Grid item xs={3}>
                <Typography sx={styles.label}>会社</Typography>
              </Grid>
              <Grid item xs={7}>
                <SelectFormControl
                  value={inputData.CompanyId}
                  selectName="CompanyId"
                  selectValues={companyList.map((company, idx) => {
                    return { value: company.CompanyId, displayName: company.CompanyName } as SelectValue;
                  })}
                  setCondition={handleChangeCompany}
                  sx={styles.inputItem}
                  error={errorAttribute.CompanyId.IsError}
                  errorMessage={errorAttribute.CompanyId.ErrorMessage}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography sx={styles.label}>支店・営業店</Typography>
              </Grid>
              <Grid item xs={7}>
                <SelectFormControl
                  value={inputData.BranchId}
                  selectName="BranchId"
                  selectValues={branchList?.map((branch, idx) => {
                    return { value: branch.BranchId, displayName: branch.BranchName } as SelectValue;
                  })}
                  setCondition={handleChangeDetail}
                  sx={styles.inputItem}
                  error={errorAttribute.BranchId.IsError}
                  errorMessage={errorAttribute.BranchId.ErrorMessage}
                />
              </Grid>
            </Grid>
          ) : (
            ''
          )}

          {inputData.UserCategoryCode === UserCategoryCode.Agent ? (
            <Grid container spacing={0.5} sx={styles.categoryBlock}>
              <Grid item xs={3}>
                <Typography sx={styles.label}>権限範囲</Typography>
              </Grid>
              <Grid item xs={7}>
                <RadioGroup
                  row
                  aria-label="CanAllDept"
                  name="CanAllDept"
                  defaultValue="top"
                  value={inputData.CanAllDept ?? '0'}
                  onChange={handleChangeDetail}
                >
                  <FormControlLabel value="1" control={<Radio color="primary" />} label="全店" />
                  <FormControlLabel value="0" control={<Radio color="primary" />} label="自店のみ" />
                </RadioGroup>
                <FormHelperText>{errorAttribute.CanAllDept.ErrorMessage}</FormHelperText>
              </Grid>
            </Grid>
          ) : (
            ''
          )}

          {inputData.UserCategoryCode === UserCategoryCode.School ? (
            <Grid container spacing={0.5} sx={styles.categoryBlock}>
              <Grid item xs={3}>
                <Typography sx={styles.label}>所属学校名</Typography>
              </Grid>

              <Grid item xs={7}>
                <TextField
                  name={'SchoolId'}
                  value={inputData.SchoolName}
                  onChange={handleChangeDetail}
                  sx={styles.textField}
                  inputProps={{ maxLength: 20 }}
                  disabled={isUpdateMode}
                  error={errorAttribute.SchoolName.IsError}
                  helperText={errorAttribute.SchoolName.ErrorMessage}
                />
              </Grid>
            </Grid>
          ) : (
            ''
          )}
          <Grid item xs={3}>
            <Typography sx={styles.label}>有効区分</Typography>
          </Grid>
          <Grid item xs={7}>
            <RadioGroup
              row
              aria-label="IsActive"
              name="IsActive"
              defaultValue="top"
              value={inputData.IsActive ?? '1'}
              onChange={handleChangeDetail}
            >
              <FormControlLabel value="1" control={<Radio color="primary" />} label="有効" />
              <FormControlLabel value="0" control={<Radio color="primary" />} label="無効" />
            </RadioGroup>
            <FormHelperText>{errorAttribute.IsActive.ErrorMessage}</FormHelperText>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>メール1</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'Mail'}
              value={inputData.Mail}
              onChange={handleChangeDetail}
              sx={styles.textField}
              inputProps={{ maxLength: 255 }}
              error={errorAttribute.Mail.IsError}
              helperText={errorAttribute.Mail.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>メール2</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'Mail2'}
              value={inputData.Mail2}
              onChange={handleChangeDetail}
              sx={styles.textField}
              inputProps={{ maxLength: 255 }}
              error={errorAttribute.Mail2.IsError}
              helperText={errorAttribute.Mail2.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>メール3</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'Mail3'}
              value={inputData.Mail3}
              onChange={handleChangeDetail}
              sx={styles.textField}
              inputProps={{ maxLength: 255 }}
              error={errorAttribute.Mail3.IsError}
              helperText={errorAttribute.Mail3.ErrorMessage}
            />
          </Grid>
          <Grid item xs={10}>
            <Link to="/settings/user/list">
              <Button variant="contained" sx={styles.button}>
                閉じる
              </Button>
            </Link>
            <Button variant="contained" sx={styles.button} onClick={handleRegistUser}>
              登録
            </Button>
          </Grid>
        </Grid>
      </Container>
    </GenericTemplate>
  );
};

export default UserDetail;
