import { Container, FormHelperText } from '@mui/material';
import Button from '@mui/material/Button/Button';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import FormControl from '@mui/material/FormControl/FormControl';
import Grid from '@mui/material/Grid/Grid';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TextField from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography/Typography';
import { Student } from 'API';
import React, { FC } from 'react';
import SelectFormControl from '../../../parts/SelectFormControl';
import GenericTemplate from '../../../template/GenericTemplate';
import AlertDialog from '../alert';
import { ErrorData } from './StudentAllocateErrorData';
import BackToTopButton from 'views/parts/BackToTop';

const styles = {
  root: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
  },

  inputItem: {
    boxSizing: 'border-box',
    '& > div > div': {
      height: '40px',
    },
    '& > div': {
      width: '100%',
    },
  },

  textField: {
    width: '100%',
    '& input': {
      padding: '8px 14px',
    },
  },

  returnButton: {
    width: 120,
    float: 'left',
    mb: '20px',
    mt: '20px',
    fontWeight: 'bold',
  },

  registButton: {
    width: 120,
    float: 'right',
    mb: '20px',
    mt: '20px',
    ml: '20px',
    fontWeight: 'bold',
  },

  label: {
    pr: '10px',
    textAlign: 'right',
    margin: 'auto',
    mr: '0px',
    ml: '0px',
  },
};

export interface Props {
  errorAttribute: ErrorData;
  hasStudent: number;
  studentList: Student[];
  classCode: number | null;
  groupCode: string;
  classCodeList: number[];
  alertOpen: boolean;
  alertContent: string;
  handleChangeClassCode: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeGroupCode: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeCheck: (_: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  handleUpdate: () => void;
  handleDelete: () => void;
  handleAlertClose: (_: boolean) => void;
}

const StudentAllocation: FC<Props> = (props) => {
  const pushBack = function () {
    window.history.back();
  };

  return (
    <GenericTemplate title="生徒割">
      <div>
        <BackToTopButton />
      </div>
      <Container sx={styles.root}>
        {props.errorAttribute.Header.ErrorMessage && (
          <Grid container spacing={2} sx={{ pl: 0, pb: 2 }}>
            <Grid item xs={12}>
              <FormControl component="fieldset" error={props.errorAttribute.Header.IsError}>
                <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                  {props.errorAttribute.Header.ErrorMessage}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        )}
        <Button variant="contained" sx={styles.returnButton} onClick={pushBack}>
          戻る
        </Button>
        {props.hasStudent ? (
          <>
            <Button variant="contained" sx={styles.registButton} onClick={props.handleDelete}>
              削除
            </Button>
            <Button variant="contained" sx={styles.registButton} onClick={props.handleUpdate}>
              編集
            </Button>
          </>
        ) : (
          <Button variant="contained" sx={styles.registButton} onClick={props.handleUpdate}>
            登録
          </Button>
        )}
        <Grid container>
          <Grid item xs={12} sm={6} md={6} sx={{ paddingBottom: '10px' }}>
            <Grid container>
              <Grid item xs={1} sx={styles.label}>
                <Typography>組</Typography>
              </Grid>
              <Grid item xs={3} sx={styles.inputItem}>
                <FormControl variant="outlined">
                  <SelectFormControl
                    value={props.classCode ?? ''}
                    selectName="ClassCode"
                    selectValues={props.classCodeList.map((x) => {
                      return { value: String(x), displayName: String(x) };
                    })}
                    setCondition={props.handleChangeClassCode}
                    sx={styles.inputItem}
                    error={props.errorAttribute.ClassCode.IsError}
                    errorMessage={props.errorAttribute.ClassCode.ErrorMessage}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={1} sx={styles.label}>
                <Typography>班</Typography>
              </Grid>
              <Grid item xs={3} sx={styles.inputItem}>
                <TextField
                  required
                  sx={styles.textField}
                  value={props.groupCode ?? ''}
                  error={props.errorAttribute.GroupCode.IsError}
                  helperText={props.errorAttribute.GroupCode.ErrorMessage}
                  onChange={props.handleChangeGroupCode}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <TableContainer sx={{ maxHeight: 500 }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{ position: 'sticky' }}>番号</TableCell>
                <TableCell style={{ position: 'sticky' }}>生徒名</TableCell>
                <TableCell style={{ position: 'sticky' }}>生徒名カナ</TableCell>
                <TableCell style={{ position: 'sticky' }}>性別</TableCell>
                <TableCell style={{ position: 'sticky' }}>アレルギー</TableCell>
                <TableCell style={{ position: 'sticky' }}>持病</TableCell>
                <TableCell style={{ position: 'sticky' }}>不安事項</TableCell>
                <TableCell style={{ position: 'sticky' }}>注意事項</TableCell>
                <TableCell style={{ position: 'sticky' }}>
                  選択
                  {props.errorAttribute.AllocateStudentList.IsError && (
                    <Typography style={{ color: '#d32f2f', fontSize: '0.75rem' }}>
                      {props.errorAttribute.AllocateStudentList.ErrorMessage}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.studentList.map((student, index) => (
                <TableRow key={index}>
                  <TableCell component="td" scope="row">
                    {student.StudentNo}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {student.StudentName}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {student.StudentKanaName}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {student.SexDisplayName}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100px' }}
                  >
                    {student.Allergy}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100px' }}
                  >
                    {student.Disease}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100px' }}
                  >
                    {student.Consern}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100px' }}
                  >
                    {student.Notice}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {student.IsActive === '0' || student.HasAnotherAllocated ? (
                      <Checkbox disabled />
                    ) : (
                      <Checkbox
                        key={student.StudentNo}
                        defaultChecked={student.IsSelected!}
                        onChange={(event) => props.handleChangeCheck(event, index)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <AlertDialog
        handleDialogClose={props.handleAlertClose}
        dialogOpen={props.alertOpen}
        content={props.alertContent}
      />
    </GenericTemplate>
  );
};

export default StudentAllocation;
