const DayOfWeekStr = ['日', '月', '火', '水', '木', '金', '土'];

export const getDisplayDate = (date: Date, isDiaplayWeek: boolean = false): string => {
  if (isDiaplayWeek) {
    return (
      date.getFullYear() +
      ' 年 ' +
      (date.getMonth() + 1) +
      ' 月 ' +
      date.getDate() +
      '日 (' +
      DayOfWeekStr[date.getDay()] +
      ')'
    );
  } else {
    return date.getFullYear() + ' 年 ' + (date.getMonth() + 1) + ' 月 ' + date.getDate() + '日';
  }
};

export const getDisplayMonth = (date: Date): string => {
  return date.getFullYear() + ' 年 ' + (date.getMonth() + 1) + ' 月 ';
};

export const getDisplaySimpleDate = (date: Date, isDiaplayWeek: boolean = false): string => {
  if (isDiaplayWeek) {
    return date.getMonth() + 1 + '月' + date.getDate() + '日 (' + DayOfWeekStr[date.getDay()] + ')';
  } else {
    return date.getMonth() + 1 + '月' + date.getDate() + '日';
  }
};

export const getDisplaySimpleDateSlash = (date: Date, isDiaplayWeek: boolean = false): string => {
  if (isDiaplayWeek) {
    return date.getMonth() + 1 + '/' + date.getDate() + ' (' + DayOfWeekStr[date.getDay()] + ')';
  } else {
    return date.getMonth() + 1 + '/' + date.getDate() + ' ';
  }
};

export const getDisplaySimpleDateFromString = (stringDate: string, isDiaplayWeek: boolean = false): string => {
  const date = new Date(stringDate);
  return getDisplaySimpleDate(date, isDiaplayWeek);
};

export const getDisplaySimpleDateSlashFromString = (stringDate: string, isDiaplayWeek: boolean = false): string => {
  const date = new Date(stringDate);
  return getDisplaySimpleDateSlash(date, isDiaplayWeek);
};

export const getDateDiffFromString = (stringStartDate: string, stringEndDate: string): number => {
  const startDate = new Date(stringStartDate);
  const endDateDate = new Date(stringEndDate);
  const diffTime = Math.abs(endDateDate.getTime() - startDate.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

export const getDateDiffYearsMonths = (date: Date): string => {
  const now = new Date();
  let years = now.getFullYear() - date.getFullYear();
  let months = now.getMonth() - date.getMonth();

  if (months < 0) {
    years--;
    months += 12;
  }
  return `${years}年${months}ケ月`;
};

export const getAge = (birthDate: Date): string => {
  const now = new Date();
  let years = now.getFullYear() - birthDate.getFullYear();
  let months = now.getMonth() - birthDate.getMonth();
  let days = now.getDate() - birthDate.getDate();

  if (days < 0) {
    months--;
    days += new Date(now.getFullYear(), now.getMonth(), 0).getDate();
  }

  if (months < 0) {
    years--;
    months += 12;
  }

  return `${years}歳`;
};

// 使用例:
const birthDate = new Date(1990, 6, 25); // 1990年7月25日 (月は0始まりなので7月は6)
console.log(getDateDiffYearsMonths(birthDate)); // "X年Yケ月" が出力されます。

export const getYYYMMDD = (date: Date, delimiter: string): string => {
  if (!date) {
    return '';
  }

  var y = date.getFullYear();
  var m = ('00' + (date.getMonth() + 1)).slice(-2);
  var d = ('00' + date.getDate()).slice(-2);
  return y + delimiter + m + delimiter + d;
};
