import { useState, useEffect, useCallback } from 'react';
import React from 'react';
import {
  Branch,
  Company,
  CompanyBranch,
  FindUserQuery,
  MSchools,
  RegistUserMutation,
  UpdateUserMutation,
  User,
  UserRequest,
} from '../../../../API';
import { useLocation, useNavigate } from 'react-router';
import { useAPI } from 'contexts/APIRequestContext';
import { useMaster } from 'contexts/CommonMasterContext';
import { UserCategoryCode } from 'constant/Constant';
import { findUser } from 'graphql/queries';
import { registUser, updateUser } from 'graphql/mutations';
import { graphqlOperation } from 'aws-amplify';
import { CreateEmptyErrorData, ErrorData } from './UserErrorData';
import { checkAuthority } from 'util/AuthorityCheck';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';

const queryString = require('query-string');

const UseUserDetail = () => {
  const location = useLocation();
  var userId = queryString.parse(location.search).id;

  const [branchList, setBranchList] = useState([] as Branch[]);
  const [companyList, setCompanyList] = useState([] as CompanyBranch[]);
  const [selectSchool, setSelectSchool] = useState({} as MSchools);
  const [searchSchoolOpen, setSearchSchoolOpen] = useState(false);
  const master = useMaster();
  const api = useAPI();
  const [, setError] = useState<undefined>();
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [inputData, setInputData] = useState({} as UserRequest);
  const [isUpdateMode, setUpdateMode] = useState(false);
  const navigator = useNavigate();
  const { user } = useAuthenticator();
  const userData = new UserData(user);

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  const handleChangeCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;

    const _branchList = master.getBranchs(value) as Branch[];
    if (_branchList) {
      setBranchList(_branchList.filter((x) => x.IsActive));
    } else {
      setBranchList([] as Branch[]);
    }

    setInputData({ ...inputData, BranchId: '', [name]: value });
    setErrorAttribute({
      ...errorAttribute,
      BranchId: { IsError: false, ErrorMessage: '' },
      [event.target.name]: { IsError: false, ErrorMessage: '' },
    });
  };

  const handleChangeDetail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    setInputData({ ...inputData, [name]: value });
    setErrorAttribute({
      ...errorAttribute,
      [event.target.name]: { IsError: false, ErrorMessage: '' },
    });
  };

  const handleRegistUser = () => {
    if (isUpdateMode) {
      executeUpdateUser();
    } else {
      executeRegistUser();
    }
  };

  const handleSearchSchoolOpen = (): void => {
    setSearchSchoolOpen(true);
  };

  const handleSearchSchoolClose = (school?: MSchools): void => {
    if (school) {
      setSelectSchool(school);
      setInputData({
        ...inputData,
        SchoolName: school.SchoolName,
        SchoolId: school.SchoolId,
      });
    } else {
      setInputData({ ...inputData, SchoolName: '', SchoolId: '' });
    }
    setSearchSchoolOpen(false);
  };

  const handleChangeUserCategoryCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    // 関連データをクリア
    setInputData({
      ...inputData,
      AuthorityCode: '',
      CompanyId: '',
      BranchId: '',
      SchoolId: '',
      SchoolName: '',
      UserCategoryCode: event.target.value,
    });
    setSelectSchool({} as MSchools);
    if (
      event.target.value === UserCategoryCode.ServiceCompany ||
      event.target.value === UserCategoryCode.MinpakuCompany ||
      event.target.value === UserCategoryCode.Agent
    ) {
      // 事業者種別で絞り込み
      setCompanyList(master.getCompanies().filter((x) => x.IsActive && x.CategoryCode === event.target.value));
      setBranchList([] as Branch[]);
    } else if (event.target.value === UserCategoryCode.Minka) {
      // 受入事業者で絞り込み
      setCompanyList(
        master.getCompanies().filter((x) => x.IsActive && x.CategoryCode === UserCategoryCode.MinpakuCompany)
      );
      setBranchList([] as Branch[]);
    }
  };

  async function executeRegistUser() {
    const response = (
      (await api.graphql(
        graphqlOperation(registUser, {
          data: inputData,
        })
      )) as RegistUserMutation
    ).registUser;

    if (response?.IsSuccess) {
      navigator('/settings/user/list');
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  async function executeUpdateUser() {
    const response = (
      (await api.graphql(
        graphqlOperation(updateUser, {
          data: inputData,
        })
      )) as UpdateUserMutation
    ).updateUser;

    if (response?.IsSuccess) {
      //      window.history.back();
      navigator('/settings/user/list');
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  async function getUserDetailData() {
    const response = (await api.graphql(
      graphqlOperation(findUser, {
        data: userId,
      })
    )) as FindUserQuery;

    if (response?.findUser?.Body) {
      var user = response.findUser.Body;
      if (user.CompanyId) {
        if (user.UserCategoryCode === UserCategoryCode.Minka) {
          // 会社プルダウン（利用者属性による絞り込み）
          setCompanyList(
            master.getCompanies().filter((x) => x.IsActive && x.CategoryCode === UserCategoryCode.MinpakuCompany)
          );
        } else {
          // 会社プルダウン（利用者属性による絞り込み）
          setCompanyList(master.getCompanies().filter((x) => x.IsActive && x.CategoryCode === user.UserCategoryCode));
        }

        // 支店プルダウン（会社IDによる絞り込み）
        const _branchList = master.getBranchs(user.CompanyId) as Branch[];
        if (_branchList) {
          setBranchList(_branchList.filter((x) => x.IsActive));
        } else {
          setBranchList([] as Branch[]);
        }
      }

      if (user.UserCategoryCode === UserCategoryCode.School) {
        const storedConditionsString = localStorage.getItem('UserList_SearchConditions');
        let storedConditions = JSON.parse(storedConditionsString ?? '') as { schoolList: MSchools[] };
        if (storedConditions) {
          const restoredSchoolList = storedConditions?.schoolList;
          if (restoredSchoolList) {
            user.SchoolName = restoredSchoolList.find((school) => user.SchoolId === school.SchoolId)?.SchoolName;
          }
        }
      }

      setInputData(user as UserRequest);
    } else {
      setInputData({} as UserRequest);
    }
  }

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('UserDetail', userData.authorityCode)) {
      navigator('/');
    }

    master.waitForInitialized(() => {
      if (userId) {
        setUpdateMode(true);
        getUserDetailData();
      } else {
        setInputData({ IsActive: '1', UserCategoryCode: UserCategoryCode.MinpakuCompany } as User);
        setCompanyList(
          master.getCompanies().filter((x) => x.IsActive && x.CategoryCode === UserCategoryCode.MinpakuCompany)
        );
      }
      setBranchList([] as Branch[]);
    });
  }, []);

  return {
    branchList,
    companyList,
    inputData,
    handleChangeDetail,
    handleChangeCompany,
    handleRegistUser,
    isUpdateMode,
    handleChangeUserCategoryCode,
    searchSchoolOpen,
    handleSearchSchoolOpen,
    selectSchool,
    handleSearchSchoolClose,
    errorAttribute,
  };
};
export default UseUserDetail;
