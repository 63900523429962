import { CognitoUserAmplify } from '@aws-amplify/ui';

export class UserData {
  userName: string;
  name: string;
  kanaName: string;
  mail1: string;
  mail2: string;
  mail3: string;
  userCategoryCode: string;
  authorityCode: string;
  companyId: string;
  branchId: string;
  schoolId: string;
  minkaId: string;
  isActive: boolean;
  canAllDept: boolean;

  constructor(user: CognitoUserAmplify) {
    this.userName = user.username ?? '';
    this.name = (user as any).attributes['name'];
    this.kanaName = (user as any).attributes['custom:kana_name'];
    this.mail1 = (user as any).attributes['email'];
    this.mail2 = (user as any).attributes['custom:mail_2'];
    this.mail3 = (user as any).attributes['custom:mail_3'];
    this.userCategoryCode = (user as any).attributes['custom:user_category_code'];
    this.authorityCode = (user as any).attributes['custom:authority_code'];
    this.companyId = (user as any).attributes['custom:companyid'];
    this.branchId = (user as any).attributes['custom:branchid'];
    this.schoolId = (user as any).attributes['custom:schoolid'];
    this.minkaId = (user as any).attributes['custom:minkaid'];
    this.isActive = (user as any).attributes['custom:is_active'];
    this.canAllDept = (user as any).attributes['custom:can_all_dept'] === 'True';
  }
}
