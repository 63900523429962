import { FC } from 'react';
import CancelDialog from './CancelDialog';
import UseCancelDialog from './UseCancelDialog';

export interface Props {
  handleDialogClose: () => void;
  dialogOpen: boolean;
  reservationId: string;
  updateDatetime: string;
}

const CancelDialogContainer: FC<Props> = (props: Props) => {
  return <CancelDialog {...UseCancelDialog(props)} />;
};

export default CancelDialogContainer;
