import { useState, useEffect, useCallback } from 'react';
import { registCompanyBranch, updateCompanyBranch } from 'graphql/mutations';
import { useLocation, useNavigate } from 'react-router';
import React from 'react';
import {
  Branch,
  Prefecture,
  CompanyBranchRequest,
  RegistCompanyBranchMutation,
  UpdateCompanyBranchMutation,
} from '../../../../API';
import { CreateEmptyErrorData, ErrorData } from './BranchInfoErrorData';
import { useMaster } from 'contexts/CommonMasterContext';
import { useAPI } from 'contexts/APIRequestContext';
import { checkAuthority } from 'util/AuthorityCheck';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';
import { scrollToTop } from 'util/Scroll';

const UseBranchInfo = () => {
  const api = useAPI();
  const queryString = require('query-string');
  const location = useLocation();
  const companyBranchPara = location.state as CompanyBranchRequest;
  const [, setError] = useState<undefined>();
  const master = useMaster();
  const [prefectureList, setPrefectureList] = useState([] as Prefecture[]);
  const [companyBranchData, setCompanyBranchData] = useState({} as Branch);
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertDialogCallback, setAlertDialogCallback] = useState({ fn: (_: boolean) => {} });
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);
  const navigator = useNavigate();
  const { user } = useAuthenticator();
  const userData = new UserData(user);

  var isUpdate = queryString.parse(location.search).mode === 'update';

  /**
   * ブラウザバック
   */
  const back = (): void => {
    window.history.back();
  };

  /**
   * 警告ダイアログ　閉じる
   * @param result 結果
   */
  const handleAlertClose = (result: boolean): void => {
    alertDialogCallback.fn(result);
    setAlertOpen(false);
  };

  const handleChangeDetail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    setCompanyBranchData({ ...companyBranchData, [name]: value });
  };

  const handleChangePrefectureCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeDetail(event);
  };

  const handleClickRegist = () => {
    if (isUpdate) {
      updateBranch();
    } else {
      registBranch();
    }
  };

  async function registBranch() {
    const response = (
      (await api.graphql({
        query: registCompanyBranch,
        variables: { data: companyBranchData },
      })) as RegistCompanyBranchMutation
    ).registCompanyBranch;

    if (response?.IsSuccess) {
      back();
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  async function updateBranch() {
    const response = (
      (await api.graphql({
        query: updateCompanyBranch,
        variables: { data: companyBranchData },
      })) as UpdateCompanyBranchMutation
    ).updateCompanyBranch;

    if (response?.IsSuccess) {
      back();
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
      scrollToTop();
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
      scrollToTop();
    } else {
      throwError();
    }
  }

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('BranchInfo', userData.authorityCode)) {
      navigator('/');
    }

    master.waitForInitialized(() => {
      setPrefectureList(master.getPrefecture());
    });
    if (isUpdate) {
      setCompanyBranchData(companyBranchPara as Branch);
    } else {
      // 新規登録の場合、初期値のある値を予めセットする
      setCompanyBranchData({ CompanyId: companyBranchPara.CompanyId, IsMain: '0', IsActive: '1' } as unknown as Branch);
    }
  }, []);

  return {
    isUpdate,
    prefectureList,
    companyBranchData,
    errorAttribute,
    alertOpen,
    alertContent,
    handleChangeDetail,
    handleChangePrefectureCode,
    handleClickRegist,
    handleAlertClose,
  };
};
export default UseBranchInfo;
