import FormErrorAttribute from 'types/FormErrorAttribute';

export interface ErrorData {
  Header: FormErrorAttribute;
  CompanyId: FormErrorAttribute;
  BranchId: FormErrorAttribute;
  IsRepresentativ: FormErrorAttribute;
  FromDate: FormErrorAttribute;
  ToDate: FormErrorAttribute;
  UpdateDatetime: FormErrorAttribute;
}

export const CreateEmptyErrorData = () => {
  return {
    Header: {} as FormErrorAttribute,
    CompanyId: {} as FormErrorAttribute,
    BranchId: {} as FormErrorAttribute,
    IsRepresentativ: {} as FormErrorAttribute,
    FromDate: {} as FormErrorAttribute,
    ToDate: {} as FormErrorAttribute,
    UpdateDatetime: {} as FormErrorAttribute,
  } as ErrorData;
};

