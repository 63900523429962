import React, { FC } from 'react';
import GenericTemplate from 'views/template/GenericTemplate';
import { Container } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DatePicker';
import jaLocale from 'date-fns/locale/ja';
import TextField from '@mui/material/TextField';
import { SelectValue } from 'types/SelectValue';
import SelectFormControl from 'views/parts/SelectFormControl';
import Grid from '@mui/material/Grid';
import { ErrorData } from './SearchLedgerErrorData';
import { Prefecture } from 'API';

const styles = {
  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '300px',
  },
  datePicker: {
    width: '300px',
  },
  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 1,
    ml: 1,
  },
  label: {
    mt: '7px',
  },
  categoryTitle: {
    borderLeft: 5,
    borderColor: '#0058a0',
    pl: '10px',
  },
  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },
};

export interface Props {
  prefectureCode: string;
  displayStartDate: Date | null;
  handleChangePrefectureCode: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeDisplayStartDate: (_: Date | null) => void;
  handleClickSearch: () => void;
  prefectureList: Prefecture[];
  errorAttribute: ErrorData;
}

const SearchLedgerCondition: FC<Props> = ({
  prefectureCode,
  displayStartDate,
  handleChangePrefectureCode,
  handleChangeDisplayStartDate,
  handleClickSearch,
  prefectureList,
  errorAttribute,
}) => {
  return (
    <GenericTemplate title="予約台帳表示条件入力">
      <Container>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          検索条件
        </Typography>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>都道府県</Typography>
          </Grid>
          <Grid item xs={9}>
            <SelectFormControl
              value={prefectureCode}
              selectName="PrefectureCode"
              selectValues={prefectureList.map((prefecture, idx) => {
                return { value: prefecture.PrefectureCode, displayName: prefecture.PrefectureName } as SelectValue;
              })}
              setCondition={handleChangePrefectureCode}
              sx={styles.inputItem}
              error={errorAttribute.PrefectureCode.IsError}
              errorMessage={errorAttribute.PrefectureCode.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>表示開始日</Typography>
          </Grid>
          <Grid item xs={9}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
              <DesktopDatePicker
                label="yyyy/mm/dd"
                mask="____/__/__"
                value={displayStartDate}
                onChange={(newValue) => handleChangeDisplayStartDate(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={styles.datePicker}
                    size="small"
                    error={errorAttribute.DisplayStartDate.IsError}
                    helperText={errorAttribute.DisplayStartDate.ErrorMessage}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" sx={styles.button} onClick={handleClickSearch}>
            検索
          </Button>
        </Box>
      </Container>
    </GenericTemplate>
  );
};
export default SearchLedgerCondition;
