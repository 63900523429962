import FormErrorAttribute from 'types/FormErrorAttribute';

export interface ErrorData {
  Header: FormErrorAttribute;
  UserId: FormErrorAttribute;
  Password: FormErrorAttribute;
  UserName: FormErrorAttribute;
  Mail: FormErrorAttribute;
  UserKanaName: FormErrorAttribute;
  Mail2: FormErrorAttribute;
  Mail3: FormErrorAttribute;
  UserCategoryCode: FormErrorAttribute;
  CompanyId: FormErrorAttribute;
  CompanyName: FormErrorAttribute;
  BranchId: FormErrorAttribute;
  SchoolId: FormErrorAttribute;
  SchoolName: FormErrorAttribute;
  MinkaId: FormErrorAttribute;
  AuthorityCode: FormErrorAttribute;
  IsActive: FormErrorAttribute;
  CanAllDept: FormErrorAttribute;
}

export const CreateEmptyErrorData = () => {
  return {
    Header: {} as FormErrorAttribute,
    UserId: {} as FormErrorAttribute,
    Password: {} as FormErrorAttribute,
    UserName: {} as FormErrorAttribute,
    Mail: {} as FormErrorAttribute,
    UserKanaName: {} as FormErrorAttribute,
    Mail2: {} as FormErrorAttribute,
    Mail3: {} as FormErrorAttribute,
    UserCategoryCode: {} as FormErrorAttribute,
    CompanyId: {} as FormErrorAttribute,
    CompanyName: {} as FormErrorAttribute,
    BranchId: {} as FormErrorAttribute,
    SchoolId: {} as FormErrorAttribute,
    SchoolName: {} as FormErrorAttribute,
    MinkaId: {} as FormErrorAttribute,
    AuthorityCode: {} as FormErrorAttribute,
    IsActive: {} as FormErrorAttribute,
    CanAllDept: {} as FormErrorAttribute,
  } as ErrorData;
};
