import {
  MinkaAllocateMinka,
  MinkaAllocateReservation,
  MinkaAllocateSearchQuery,
  UpdateMinkaAllocateRequest,
  UpdateMinkaAllocationMutation,
  Minka,
} from 'API';
import { useAPI } from 'contexts/APIRequestContext';
import { updateMinkaAllocation } from 'graphql/mutations';
import { minkaAllocateSearch } from 'graphql/queries';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { ReservationFrame } from 'views/reserve/reception/regist/ReservationFrame';
import { CreateEmptyErrorData } from './MinkaAllocateLedgerErrorData';
import { checkAuthority } from 'util/AuthorityCheck';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';

const queryString = require('query-string');

const UseMinkaAllocateLedger = () => {
  const location = useLocation();
  const api = useAPI();
  const navigator = useNavigate();
  const areaId = queryString.parse(location.search).area;

  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [isActiveBefore, setIsActiveBefore] = useState(false);
  const [isActiveAfter, setIsActiveAfter] = useState(false);
  const [yearMonth, setYearMonth] = useState<Date>(new Date());
  const [schoolYearCode, setSchoolYearCode] = useState(queryString.parse(location.search).year);
  const [periodCode, setPeriodCode] = useState(queryString.parse(location.search).month);
  const [minkas, setMinkas] = useState([] as MinkaAllocateMinka[]);
  const [reservations, setReservations] = useState([] as MinkaAllocateReservation[]);
  const [, setError] = useState<undefined>();

  const [editButton, setEditButton] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [checkedList, setCheckedList] = useState([] as boolean[]);
  const [memoList, setMemoList] = useState([] as string[]);
  const forceUpdate = useReducer(() => ({}), {})[1] as () => void;
  const { user } = useAuthenticator();
  const userData = new UserData(user);

  React.useLayoutEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  });

  const handleWindowResize = () => {
    (document.querySelector('#tableContainer') as HTMLElement).style.height = window.outerHeight - 260 + 'px';
  };

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('MinkaAllocateLedger', userData.authorityCode)) {
      navigator('/');
    }

    executeMinkaAllocateSearchService(0);
  }, []);

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  const executeMinkaAllocateSearchService = async (mode: number): Promise<void> => {
    const response = (
      (await api.graphql({
        query: minkaAllocateSearch,
        variables: {
          data: {
            AreaId: areaId,
            SchoolYearCode: schoolYearCode,
            PeriodCode: periodCode,
            Mode: mode,
          },
        },
      })) as MinkaAllocateSearchQuery
    ).minkaAllocateSearch;

    if (response?.IsSuccess) {
      setIsActiveBefore(response.Body?.IsActiveBefore!);
      setIsActiveAfter(response.Body?.IsActiveAfter!);
      setSchoolYearCode(response.Body?.SchoolYearCode!);
      setPeriodCode(response.Body?.PeriodCode!);
      setYearMonth(new Date(response.Body?.Year!, response.Body?.Month! - 1));
      setMinkas(response.Body?.Minkas as MinkaAllocateMinka[]);
      setReservations(response.Body?.Reservations as MinkaAllocateReservation[]);

      // URLを変更する
      const url = new URL(window.location.href);
      url.searchParams.set('year', response.Body?.SchoolYearCode!);
      url.searchParams.set('month', response.Body?.PeriodCode!);
      window.history.replaceState(null, '', url.href);
    } else if (response?.IsSystemError) {
      throwError(response.ErrorData ?? '');
    } else {
      throwError();
    }
  };

  const handleBeforeMonth = () => {
    executeMinkaAllocateSearchService(-1);
  };

  const handleNextMonth = () => {
    executeMinkaAllocateSearchService(1);
  };

  const handleClickReservationLink = (reservation: MinkaAllocateReservation) => {
    navigator('/reserve/detail', {
      state: {
        ReservationId: reservation.ReservationId,
        ReservedDay: reservation.ReservedDay,
      } as ReservationFrame,
    });
  };

  const handleClickMinkaLink = (minka: MinkaAllocateMinka) => {
    navigator('/minka/management/detail?mode=update&from=minkaledger', {
      state: {
        __typename: 'Minka',
        MinkaId: minka.MinkaId,
      } as Minka,
    });
  };

  const handleClickEditButton = (index: number) => {
    setCheckedList(minkas.map((x) => x.PickUpMinkas![index]).map((x) => x.IsAllocated!));
    setMemoList(minkas.map((x) => x.PickUpMinkas![index]).map((x) => x.Memo!));
    setEditIndex(index);
    setEditButton(true);
  };

  const handleClickCloseButton = () => {
    setEditButton(false);
  };

  const executeUpdateMinkaAllocation = async (data: UpdateMinkaAllocateRequest): Promise<void> => {
    const response = (
      (await api.graphql({
        query: updateMinkaAllocation,
        variables: { data },
      })) as UpdateMinkaAllocationMutation
    ).updateMinkaAllocation;

    if (response?.IsSuccess) {
      reservations.find((x) => x.ReservationId === data.ReservationId)!.UpdateDatetime = response.Body;

      setEditButton(false);

      executeMinkaAllocateSearchService(0);
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...errorAttribute,
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
      window.scrollTo(0, 0);
    } else {
      throwError();
    }
  };

  const handleClickConfirmButton = (reservation: MinkaAllocateReservation) => {
    const data: UpdateMinkaAllocateRequest = {
      ReservationId: reservation.ReservationId!,
      UpdateDatetime: reservation.UpdateDatetime!,
      Minkas: [],
    };

    document.querySelectorAll<HTMLElement>('.edit-cel-js').forEach((x) => {
      const minkaId = x.dataset['minkaId']!;
      const checkBox = x.querySelector<HTMLInputElement>('input[type="checkbox"]')!;
      const input = x.querySelector<HTMLInputElement>('input[type="text"]')!;

      if (!checkBox || !input || !checkBox.checked) {
        return;
      }

      data.Minkas!.push({ MinkaId: minkaId, Memo: input.value });
    });

    executeUpdateMinkaAllocation(data);
  };

  const handleChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>, index: number): void => {
    checkedList[index] = e.target.checked;
    setCheckedList(checkedList);
    forceUpdate();
  };

  const handleChangeMemo = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number): void => {
    memoList[index] = e.target.value;
    setMemoList(memoList);
    forceUpdate();
  };

  return {
    editIndex,
    editButton,
    isActiveBefore,
    isActiveAfter,
    yearMonth,
    minkas,
    reservations,
    handleBeforeMonth,
    handleNextMonth,
    handleClickReservationLink,
    handleClickMinkaLink,
    handleClickEditButton,
    handleClickCloseButton,
    handleClickConfirmButton,
    errorAttribute,
    checkedList,
    memoList,
    handleChangeCheckbox,
    handleChangeMemo,
  };
};
export default UseMinkaAllocateLedger;
