import { createTheme } from '@mui/material/styles';
import { indigo, pink } from '@mui/material/colors';

const Theme = createTheme({
  palette: {
    primary: {
      main: '#0058a0',
    },
    secondary: pink,
  },
});
export default Theme;
