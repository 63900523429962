import React, { FC, useState } from 'react';
import GenericTemplate from '../../../template/GenericTemplate';
import { Container, FormControl, FormHelperText, TablePagination } from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import Grid from '@mui/material/Grid/Grid';
import SelectFormControl from '../../../parts/SelectFormControl';
import { SelectValue } from '../../../../types/SelectValue';
import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField/TextField';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import { Company, Branch, Prefecture } from '../../../../API';
import Radio from '@mui/material/Radio/Radio';
import RadioGroup from '@mui/material/RadioGroup/RadioGroup';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableBody from '@mui/material/TableBody/TableBody';
import { ErrorData } from './CompanyDetailErrorData';
import AlertDialog from '../../alert';
import { UserCategoryCode } from 'constant/Constant';
import { useNavigate } from 'react-router';
import BackToTopButton from 'views/parts/BackToTop';

const styles = {
  searchField: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
  },

  label: {
    margin: 'auto',
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
  },

  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
  },

  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
    '& > div > input': {
      maxHeight: '10px',
    },
  },

  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },

  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mr: 1,
    float: 'right',
    mt: 1,
  },

  textField: {
    width: '100%',
    '& input': {
      padding: '8px 14px',
    },
  },

  listButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    m: 0,
  },

  createButton: {
    minWidth: '80px',
    fontWeight: 'bold',
    mt: 1,
    float: 'left',
    mb: 1,
  },

  deleteCell: {
    '& > a': {
      textDecoration: 'none',
    },
  },
};

export interface Props {
  isUpdate: boolean;
  isReserveAdmin: boolean;
  prefectureList: Prefecture[];
  companyData: Company;
  companyMainData: Branch;
  companyBranchData: Branch[];
  errorAttribute: ErrorData;
  alertOpen: boolean;
  alertContent: string;
  canAllDept: boolean;
  handleChangeDetail: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeCompanyMain: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangePrefectureCode: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickRegist: () => void;
  handleClickBranchCreateButton: () => void;
  handleClickBranchEditButton: (_: Branch) => void;
  handleClickBranchDeleteButton: (_: Branch) => void;
  handleAlertClose: (_: boolean) => void;
}

const CompanyDetail: FC<Props> = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const navigator = useNavigate();
  const pushback = function () {
    if (document.URL.includes('mode=agent')) {
      window.history.back();
    } else {
      navigator('/settings/company/list');
    }
  };

  return (
    <GenericTemplate title="会社詳細">
      <div>
        <BackToTopButton />
      </div>
      <Container sx={styles.searchField}>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          会社基本情報
        </Typography>
        {props.errorAttribute.Header.ErrorMessage && (
          <Grid container spacing={2} sx={{ pl: 0, pb: 2 }}>
            <Grid item xs={12}>
              <FormControl component="fieldset" error={props.errorAttribute.Header.IsError}>
                <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                  {props.errorAttribute.Header.ErrorMessage}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>会社名</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'CompanyName'}
              value={props.companyData.CompanyName}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              inputProps={{ maxLength: 40 }}
              error={props.errorAttribute.CompanyName.IsError}
              helperText={props.errorAttribute.CompanyName.ErrorMessage}
              disabled={!props.isReserveAdmin}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>会社表示名</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'CompanyShortName'}
              value={props.companyData.CompanyShortName}
              onChange={props.handleChangeDetail}
              sx={styles.textField}
              inputProps={{ maxLength: 20 }}
              error={props.errorAttribute.CompanyShortName.IsError}
              helperText={props.errorAttribute.CompanyShortName.ErrorMessage}
              disabled={!props.isReserveAdmin}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>会社種別</Typography>
          </Grid>
          <Grid item xs={7}>
            <RadioGroup
              row
              name="CategoryCode"
              aria-label="position"
              value={props.companyData.CategoryCode ?? '01'}
              onChange={props.handleChangeDetail}
            >
              <FormControlLabel
                value={UserCategoryCode.MinpakuCompany}
                control={<Radio color="primary" value={UserCategoryCode.MinpakuCompany} />}
                label="民泊事業者"
                disabled={props.isUpdate}
              />
              <FormControlLabel
                value={UserCategoryCode.Agent}
                control={<Radio color="primary" value={UserCategoryCode.Agent} />}
                label="旅行代理店"
                disabled={props.isUpdate}
              />
              <FormControlLabel
                value={UserCategoryCode.ServiceCompany}
                control={<Radio color="primary" value={UserCategoryCode.ServiceCompany} />}
                label="サービス提供業者"
                disabled={props.isUpdate}
              />
            </RadioGroup>
            <FormHelperText>{props.errorAttribute.CategoryCode.ErrorMessage}</FormHelperText>
          </Grid>
        </Grid>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          本社情報
        </Typography>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>都道府県</Typography>
          </Grid>
          <Grid item xs={7}>
            <SelectFormControl
              value={props.companyMainData.PrefectureCode ?? ''}
              selectName="PrefectureCode"
              selectValues={props.prefectureList.map((prefecture, idx) => {
                return { value: prefecture.PrefectureCode, displayName: prefecture.PrefectureName } as SelectValue;
              })}
              setCondition={props.handleChangePrefectureCode}
              sx={styles.inputItem}
              error={props.errorAttribute.PrefectureCode.IsError}
              errorMessage={props.errorAttribute.PrefectureCode.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>本店所在地</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'Address'}
              value={props.companyMainData.Address}
              onChange={props.handleChangeCompanyMain}
              sx={styles.textField}
              inputProps={{ maxLength: 40 }}
              error={props.errorAttribute.Address.IsError}
              helperText={props.errorAttribute.Address.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>郵便番号</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'PostalCode'}
              value={props.companyMainData.PostalCode}
              onChange={props.handleChangeCompanyMain}
              sx={styles.textField}
              inputProps={{ maxLength: 7 }}
              error={props.errorAttribute.PostalCode.IsError}
              helperText={props.errorAttribute.PostalCode.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>電話番号</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'Tel'}
              value={props.companyMainData.Tel}
              onChange={props.handleChangeCompanyMain}
              sx={styles.textField}
              inputProps={{ maxLength: 13 }}
              error={props.errorAttribute.Tel.IsError}
              helperText={props.errorAttribute.Tel.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>ファックス</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'Fax'}
              value={props.companyMainData.Fax}
              onChange={props.handleChangeCompanyMain}
              sx={styles.textField}
              inputProps={{ maxLength: 12 }}
              error={props.errorAttribute.Fax.IsError}
              helperText={props.errorAttribute.Fax.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>メール１</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'Mail1'}
              value={props.companyMainData.Mail1}
              onChange={props.handleChangeCompanyMain}
              sx={styles.textField}
              inputProps={{ maxLength: 255 }}
              error={props.errorAttribute.Mail1.IsError}
              helperText={props.errorAttribute.Mail1.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>メール２</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'Mail2'}
              value={props.companyMainData.Mail2}
              onChange={props.handleChangeCompanyMain}
              sx={styles.textField}
              inputProps={{ maxLength: 255 }}
              error={props.errorAttribute.Mail2.IsError}
              helperText={props.errorAttribute.Mail2.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>メール３</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name={'Mail3'}
              value={props.companyMainData.Mail3}
              onChange={props.handleChangeCompanyMain}
              sx={styles.textField}
              inputProps={{ maxLength: 255 }}
              error={props.errorAttribute.Mail3.IsError}
              helperText={props.errorAttribute.Mail3.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>有効区分</Typography>
          </Grid>
          <Grid item xs={7}>
            <RadioGroup
              row
              name="IsActive"
              aria-label="position"
              value={props.companyData.IsActive ?? '1'}
              onChange={props.handleChangeDetail}
            >
              <FormControlLabel
                value={1}
                control={<Radio color="primary" />}
                label="有効"
                disabled={!props.isReserveAdmin}
              />
              <FormControlLabel
                value={0}
                control={<Radio color="primary" />}
                label="無効"
                disabled={!props.isReserveAdmin}
              />
            </RadioGroup>
            <FormHelperText>{props.errorAttribute.IsActive.ErrorMessage}</FormHelperText>
          </Grid>
        </Grid>
        {props.isUpdate ? (
          <div>
            <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
              支店・営業店
            </Typography>
            <Grid container spacing={0.5} sx={styles.categoryBlock}>
              <Grid item xs={12}>
                {(props.isReserveAdmin || props.canAllDept) && (
                  <Button
                    variant="contained"
                    sx={styles.createButton}
                    size="small"
                    onClick={() => props.handleClickBranchCreateButton()}
                  >
                    追加
                  </Button>
                )}
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>本支店ID</TableCell>
                        <TableCell>名称</TableCell>
                        <TableCell>住所</TableCell>
                        <TableCell>電話</TableCell>
                        <TableCell>有効区分</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.companyBranchData
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((branch, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              {(props.isReserveAdmin || props.canAllDept) && (
                                <Button
                                  variant="contained"
                                  sx={styles.listButton}
                                  size="small"
                                  onClick={() => props.handleClickBranchDeleteButton(branch)}
                                >
                                  削除
                                </Button>
                              )}
                            </TableCell>
                            <TableCell component="td" scope="row">
                              {branch.BranchId}
                            </TableCell>
                            <TableCell component="td" scope="row">
                              {branch.BranchName}
                            </TableCell>
                            <TableCell component="td" scope="row">
                              {
                                props.prefectureList.find((x) => x.PrefectureCode === branch.PrefectureCode)
                                  ?.PrefectureName
                              }
                              {branch.Address}
                            </TableCell>
                            <TableCell component="td" scope="row">
                              {branch.Tel}
                            </TableCell>
                            <TableCell component="td" scope="row">
                              {branch.IsActive === 1 ? '有効' : '無効'}
                            </TableCell>
                            <TableCell sx={styles.deleteCell}>
                              <Button
                                variant="contained"
                                sx={styles.listButton}
                                size="small"
                                onClick={() => props.handleClickBranchEditButton(branch)}
                              >
                                編集
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  component="div"
                  count={props.companyBranchData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          </div>
        ) : (
          ''
        )}
        <Grid container>
          <Grid item xs={10}>
            <Button variant="contained" sx={styles.button} onClick={pushback}>
              閉じる
            </Button>
            {/* <Link to="/settings/company/list">
              <Button variant="contained" sx={styles.button}>
                閉じる
              </Button>
            </Link> */}
            {(props.isReserveAdmin || props.canAllDept) && (
              <Button variant="contained" sx={styles.button} onClick={props.handleClickRegist}>
                登録
              </Button>
            )}
          </Grid>
        </Grid>
      </Container>
      <AlertDialog
        handleDialogClose={props.handleAlertClose}
        dialogOpen={props.alertOpen}
        content={props.alertContent}
      />
    </GenericTemplate>
  );
};

export default CompanyDetail;
