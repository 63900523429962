import { Container } from '@mui/material';
import Button from '@mui/material/Button/Button';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import Grid from '@mui/material/Grid/Grid';
import Radio from '@mui/material/Radio/Radio';
import RadioGroup from '@mui/material/RadioGroup/RadioGroup';
import TextField from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography/Typography';
import React, { FC } from 'react';
import { StudentRequest } from 'API';
import GenericTemplate from '../../../template/GenericTemplate';
import { ErrorData } from './StudentDetailErrorData';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import BackToTopButton from 'views/parts/BackToTop';

const styles = {
  searchField: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
  },

  label: {
    margin: 'auto',
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
  },

  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
  },

  textArea: {
    width: '100%',
    '& input': {
      padding: '8px 14px',
    },
    mt: 1,
    mb: 1,
  },

  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
  },

  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },

  textField: {
    width: '100%',
    '& input': {
      padding: '8px 14px',
    },
  },

  button: {
    fontWeight: 'bold',
    float: 'right',
    mt: 1,
    mr: 1,
    minWidth: '120px',
  },
};

export interface Props {
  studentDetailData: StudentRequest;
  handleChangeDetail: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickRegist: () => void;
  status: boolean;
  errorAttribute: ErrorData;
  isMinpakuCompany: boolean;
}

const StudentDetail: FC<Props> = ({
  studentDetailData,
  handleChangeDetail,
  status,
  handleClickRegist,
  errorAttribute,
  isMinpakuCompany,
}) => {
  const pushBack = function () {
    window.history.back();
  };

  return (
    <GenericTemplate title="生徒詳細">
      <div>
        <BackToTopButton />
      </div>
      <Container sx={styles.searchField}>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          生徒情報
        </Typography>
        {errorAttribute.Header.ErrorMessage && (
          <Grid container spacing={2} sx={{ pl: 0, pb: 2 }}>
            <Grid item xs={12}>
              <FormControl component="fieldset" error={errorAttribute.Header.IsError}>
                <FormHelperText sx={{ ml: 0, fontSize: '1.1rem', fontWeight: 'bold' }}>
                  {errorAttribute.Header.ErrorMessage}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>組</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              sx={styles.textField}
              name={'ClassCode'}
              value={studentDetailData.ClassCode ?? ''}
              onChange={handleChangeDetail}
              type="number"
              disabled={status}
              error={errorAttribute.ClassCode.IsError}
              helperText={errorAttribute.ClassCode.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>生徒番号</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              sx={styles.textField}
              name={'StudentNo'}
              value={studentDetailData.StudentNo ?? ''}
              onChange={handleChangeDetail}
              type="number"
              disabled={status}
              error={errorAttribute.StudentNo.IsError}
              helperText={errorAttribute.StudentNo.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>生徒名</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              sx={styles.textField}
              name={'StudentName'}
              value={studentDetailData.StudentName ?? ''}
              onChange={handleChangeDetail}
              inputProps={{ maxLength: 20 }}
              error={errorAttribute.StudentName.IsError}
              helperText={errorAttribute.StudentName.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>生徒カナ名</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              sx={styles.textField}
              name={'StudentKanaName'}
              value={studentDetailData.StudentKanaName ?? ''}
              onChange={handleChangeDetail}
              inputProps={{ maxLength: 40 }}
              error={errorAttribute.StudentKanaName.IsError}
              helperText={errorAttribute.StudentKanaName.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>性別</Typography>
          </Grid>
          <Grid item xs={7}>
            <RadioGroup
              row
              name="Sex"
              aria-label="position"
              value={studentDetailData.Sex ?? '1'}
              onChange={handleChangeDetail}
            >
              <FormControlLabel value={1} control={<Radio color="primary" />} label="男" />
              <FormControlLabel value={2} control={<Radio color="primary" />} label="女" />
              <FormControlLabel value={3} control={<Radio color="primary" />} label="その他" />
            </RadioGroup>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>アレルギー</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              sx={styles.textField}
              name={'Allergy'}
              value={studentDetailData.Allergy ?? ''}
              onChange={handleChangeDetail}
              inputProps={{ maxLength: 80 }}
              error={errorAttribute.Allergy.IsError}
              helperText={errorAttribute.Allergy.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>持病</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              sx={styles.textField}
              name={'Disease'}
              value={studentDetailData.Disease ?? ''}
              onChange={handleChangeDetail}
              inputProps={{ maxLength: 80 }}
              error={errorAttribute.Disease.IsError}
              helperText={errorAttribute.Disease.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>不安事項</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              sx={styles.textField}
              name={'Consern'}
              value={studentDetailData.Consern ?? ''}
              onChange={handleChangeDetail}
              inputProps={{ maxLength: 80 }}
              error={errorAttribute.Consern.IsError}
              helperText={errorAttribute.Consern.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>注意事項</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              sx={styles.textField}
              name={'Notice'}
              value={studentDetailData.Notice ?? ''}
              onChange={handleChangeDetail}
              inputProps={{ maxLength: 80 }}
              error={errorAttribute.Notice.IsError}
              helperText={errorAttribute.Notice.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>有効区分</Typography>
          </Grid>
          <Grid item xs={7}>
            <RadioGroup
              row
              name="IsActive"
              aria-label="position"
              value={studentDetailData.IsActive ?? '1'}
              onChange={handleChangeDetail}
            >
              <FormControlLabel value={1} control={<Radio color="primary" />} label="有効" />
              <FormControlLabel value={0} control={<Radio color="primary" />} label="無効" />
            </RadioGroup>
          </Grid>
        </Grid>
        <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
          割当民家
        </Typography>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>受入民家</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography style={{ paddingRight: '10px', marginTop: '7px' }}>{studentDetailData.MinkaName}</Typography>
          </Grid>
        </Grid>
        {isMinpakuCompany ? (
          <Typography variant="h6" gutterBottom component="div" sx={styles.categoryTitle}>
            事業者メモ
          </Typography>
        ) : (
          ''
        )}

        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}></Grid>
          <Grid item xs={7}>
            {isMinpakuCompany ? (
              <TextField
                sx={styles.textArea}
                name="Memo"
                multiline
                rows={4}
                value={studentDetailData.Memo ?? ''}
                onChange={handleChangeDetail}
                variant="outlined"
                inputProps={{ maxLength: 80 }}
                error={errorAttribute.Memo.IsError}
                helperText={errorAttribute.Memo.ErrorMessage}
              />
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={10}>
            <Button variant="contained" sx={styles.button} onClick={pushBack}>
              戻る
            </Button>
            <Button variant="contained" sx={styles.button} onClick={handleClickRegist}>
              登録
            </Button>
          </Grid>
        </Grid>
      </Container>
    </GenericTemplate>
  );
};

export default StudentDetail;
