import FormErrorAttribute from 'types/FormErrorAttribute';

export interface ErrorData {
  Header: FormErrorAttribute;
  ClassCode: FormErrorAttribute;
  StudentNo: FormErrorAttribute;
  StudentName: FormErrorAttribute;
  SchoolName: FormErrorAttribute;
  StudentKanaName: FormErrorAttribute;
  Sex: FormErrorAttribute;
  Allergy: FormErrorAttribute;
  Disease: FormErrorAttribute;
  Consern: FormErrorAttribute;
  Notice: FormErrorAttribute;
  Memo: FormErrorAttribute;
  UpdateDatetime: FormErrorAttribute;
}

export const CreateEmptyErrorData = () => {
  return {
    Header: {} as FormErrorAttribute,
    ClassCode: {} as FormErrorAttribute,
    StudentNo: {} as FormErrorAttribute,
    StudentName: {} as FormErrorAttribute,
    SchoolName: {} as FormErrorAttribute,
    StudentKanaName: {} as FormErrorAttribute,
    Sex: {} as FormErrorAttribute,
    Allergy: {} as FormErrorAttribute,
    Disease: {} as FormErrorAttribute,
    Consern: {} as FormErrorAttribute,
    Notice: {} as FormErrorAttribute,
    Memo: {} as FormErrorAttribute,
    UpdateDatetime: {} as FormErrorAttribute,
  } as ErrorData;
};

