import { graphqlOperation } from 'aws-amplify';
import { useAPI } from 'contexts/APIRequestContext';
import { findServiceList } from 'graphql/queries';
import { deleteService } from 'graphql/mutations';
import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import {
  CompanyBranch,
  MServices,
  ServiceRequest,
  FindServiceListRequest,
  FindServiceListQuery,
  DeleteServiceMutation,
} from 'API';
import { CreateEmptyErrorData, ErrorData } from './ServiceListErrorData';
import { useMaster } from 'contexts/CommonMasterContext';
import { checkAuthority } from 'util/AuthorityCheck';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';

const UseServiceList = () => {
  const [companyId, setCompanyId] = useState('');
  const [isActive, setIsActive] = useState('1');
  const [inputData, setInputData] = useState({} as FindServiceListRequest);
  const [resultData, setResultData] = useState([] as MServices[]);
  const [, setError] = useState<undefined>();
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const api = useAPI();
  const navigator = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertDialogCallback, setAlertDialogCallback] = useState({ fn: (_: boolean) => {} });
  const master = useMaster();
  const [companyList, setCompanyList] = useState([] as CompanyBranch[]);
  const { user } = useAuthenticator();
  const userData = new UserData(user);
  const [serviceCategoryCodeList, setServiceCategoryCodeList] = useState([] as Array<string>);

  const defalutPage = 0;
  const defaultRowsPerPage = 25;
  const [page, setPage] = useState(defalutPage);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  const handleChangeCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCompanyId(value);
    setInputData({ ...inputData, CompanyId: value });
  };

  const handleChangeIsActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setIsActive(value);
    setInputData({ ...inputData, IsActive: value });
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    setInputData({ ...inputData, [name]: value });
  };

  const handleClickSearch = () => {
    fetchServices();
    setPage(defalutPage);
    setRowsPerPage(defaultRowsPerPage);
  };

  const handleClickCreateButton = () => {
    // ローカルストレージまたはセッションストレージに検索条件を退避する
    localStorage.setItem('ServiceList_SearchConditions', JSON.stringify({ inputData, rowsPerPage, page }));
    // 戻った時に状態を復元するフラグをセット
    sessionStorage.setItem('ServiceList_ShouldRestore', 'true');

    navigator('/settings/service/detail?mode=new');
  };

  const handleClickDetailButton = (service: MServices) => {
    // ローカルストレージまたはセッションストレージに検索条件を退避する
    localStorage.setItem('ServiceList_SearchConditions', JSON.stringify({ inputData, rowsPerPage, page }));
    // 戻った時に状態を復元するフラグをセット
    sessionStorage.setItem('ServiceList_ShouldRestore', 'true');

    navigator('/settings/service/detail?mode=update', {
      state: {
        __typename: 'MServices',
        ServiceId: service.ServiceId,
      } as MServices,
    });
  };

  const handleClickDeleteButton = async (service: MServices): Promise<void> => {
    setAlertDialogCallback({
      fn: async (result: boolean) => {
        if (result) {
          deleteServiceAndServiceRate(service);
        }
      },
    });
    setAlertContent('削除します。よろしいですか？');
    setAlertOpen(true);
  };

  async function fetchServices() {
    await fetchAndSetCompanies(inputData);
  }

  async function fetchServicesWithParams(params: FindServiceListRequest) {
    await fetchAndSetCompanies(params);
  }

  async function fetchAndSetCompanies(params: FindServiceListRequest) {
    const response = (await api.graphql(
      graphqlOperation(findServiceList, {
        data: params,
      })
    )) as FindServiceListQuery;

    if (response?.findServiceList?.Body?.length) {
      setResultData(response.findServiceList.Body as MServices[]);
    } else {
      setResultData([] as MServices[]);
    }
  }

  async function deleteServiceAndServiceRate(service: MServices) {
    // エラー表示内容をクリア
    setErrorAttribute({
      ...CreateEmptyErrorData(),
      Header: { IsError: true, ErrorMessage: '' },
    });

    const serviceRequest = {
      ServiceId: service.ServiceId,
      ServiceName: service.ServiceName,
      UpdateDatetime: service.UpdateDatetime,
    } as ServiceRequest;

    const response = (
      (await api.graphql({
        query: deleteService,
        variables: { data: serviceRequest },
      })) as DeleteServiceMutation
    ).deleteService;

    if (response?.IsSuccess) {
      // リストから削除に成功したレコードを除外
      let newServiceList = resultData.filter((item) => item.ServiceId != service.ServiceId);
      setResultData(newServiceList);
    } else if (response?.IsSystemError) {
      throwError(response?.ErrorData ?? '');
    } else if (response?.IsInputCheckError && response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        ...(JSON.parse(response?.ErrorData) as ErrorData),
      });
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...CreateEmptyErrorData(),
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  }

  /**
   * 警告ダイアログ　閉じる
   * @param result 結果
   */
  const handleAlertClose = (result: boolean): void => {
    alertDialogCallback.fn(result);
    setAlertOpen(false);
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    const checked = event.target.checked;

    let list;
    list = inputData['ServiceCategoryCode'] as Array<string> | null;

    if (!list) {
      list = [] as string[];
    }

    if (checked) {
      if (!list?.find((x) => x == value)) {
        list?.push(value);
      }
    } else {
      if (list?.find((x) => x == value)) {
        list = list.filter((x) => x != value);
      }
    }
    setServiceCategoryCodeList(list);
    setInputData({ ...inputData, [name]: list });
  };

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('ServiceList', userData.authorityCode)) {
      navigator('/');
    }

    master.waitForInitialized(() => {
      setCompanyList(master.getCompanies());
    });

    // 詳細画面から戻ってきた場合、ローカルストレージに退避していた情報を復元する
    const shouldRestore = sessionStorage.getItem('ServiceList_ShouldRestore');
    if (shouldRestore) {
      const storedConditionsString = localStorage.getItem('ServiceList_SearchConditions');
      let storedConditions = JSON.parse(storedConditionsString ?? '');
      if (storedConditions) {
        setCompanyId(storedConditions?.inputData?.CompanyId ?? '');
        setIsActive(storedConditions?.inputData?.IsActive ?? '');
        setServiceCategoryCodeList(storedConditions?.inputData?.ServiceCategoryCode ?? ([] as Array<string>));

        setInputData(storedConditions?.inputData);
        fetchServicesWithParams(storedConditions?.inputData);
        setRowsPerPage(storedConditions?.rowsPerPage);
        setPage(storedConditions?.page);
      }
    } else {
      // 初期表示時は、利用者種別：民泊事業者なので、会社リストを表示の上、選択肢を絞りこむ
      inputData.IsActive = '1';
    }

    // フラグをリセット
    sessionStorage.removeItem('ServiceList_ShouldRestore');
  }, []);

  return {
    inputData,
    companyId,
    isActive,
    resultData,
    errorAttribute,
    alertOpen,
    alertContent,
    companyList,
    serviceCategoryCodeList,
    page,
    rowsPerPage,
    handleChangeInput,
    handleChangeIsActive,
    handleChangeCompany,
    handleChangeCheckbox,
    handleClickSearch,
    handleClickDeleteButton,
    handleClickCreateButton,
    handleClickDetailButton,
    handleAlertClose,
    handleChangePage,
    handleChangeRowsPerPage,
  };
};
export default UseServiceList;
