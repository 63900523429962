import FormErrorAttribute from 'types/FormErrorAttribute';

export interface ErrorData {
  Header: FormErrorAttribute;
  HasAnotherResv: FormErrorAttribute;
  GroupingName: FormErrorAttribute;
  HasGroupingName: FormErrorAttribute;
  SchoolName: FormErrorAttribute;
}

export const CreateEmptyErrorData = () => {
  return {
    Header: {} as FormErrorAttribute,
    HasAnotherResv: {} as FormErrorAttribute,
    GroupingName: {} as FormErrorAttribute,
    HasGroupingName: {} as FormErrorAttribute,
    SchoolName: {} as FormErrorAttribute,
  } as ErrorData;
};
