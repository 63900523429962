import { FindStudentLedgerQuery, StudentAllocateData, CodeMaster } from 'API';
import { useAPI } from 'contexts/APIRequestContext';
import { findStudentLedger } from 'graphql/queries';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CreateEmptyErrorData } from './StudentAllocateLedgerErrorData';
import { useMaster } from 'contexts/CommonMasterContext';
import { checkAuthority } from 'util/AuthorityCheck';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserData } from 'models/UserData';

const UseStudentAllocateLedger = () => {
  const location = useLocation();
  const reservationId = (location.state as any).ReservationId as string;
  const [errorAttribute, setErrorAttribute] = useState(CreateEmptyErrorData());
  const [resultManagementStudentLedgerDate, setManagementStudentLedgerDate] = useState([] as StudentAllocateData[]);
  const api = useAPI();
  const navigate = useNavigate();
  const [, setError] = useState<undefined>();
  const master = useMaster();
  const [acceptGenderList, setAcceptGenderList] = useState([] as CodeMaster[]);
  const [sexList, setSexList] = useState([] as CodeMaster[]);
  const { user } = useAuthenticator();
  const userData = new UserData(user);

  useEffect(() => {
    // 権限が無い場合はHOMEへディスパッチ
    if (!checkAuthority('StudentAllocateLedger', userData.authorityCode)) {
      navigate('/');
    }

    master.waitForInitialized(() => {
      setAcceptGenderList(master.getCodeMaster().filter((x) => x.CodeKind === 'acceptance_gender'));
      setSexList(master.getCodeMaster().filter((x) => x.CodeKind === 'sex'));
    });
    executeFindStudentLedger();
  }, []);

  /**
   * ErrorBoundaryに通知するための処理
   */
  const throwError = useCallback((err: string = '') => {
    setError(() => {
      throw new Error(err);
    });
  }, []);

  const executeFindStudentLedger = async (): Promise<void> => {
    const response = (
      (await api.graphql({
        query: findStudentLedger,
        variables: { data: reservationId },
      })) as FindStudentLedgerQuery
    ).findStudentLedger;

    if (response?.IsSuccess) {
      setManagementStudentLedgerDate(response.Body as StudentAllocateData[]);
    } else if (response?.IsSystemError) {
      throwError(response.ErrorData ?? '');
    } else if (response?.ErrorData) {
      setErrorAttribute({
        ...errorAttribute,
        Header: { IsError: true, ErrorMessage: response?.ErrorData },
      });
    } else {
      throwError();
    }
  };

  const handleClickStudentAllocate = (index: number): void => {
    const data = resultManagementStudentLedgerDate[index];

    navigate('/reserve/management/studentallocation', {
      state: {
        ReservationId: reservationId,
        MinkaId: data.MinkaId,
        ClassCode: data.ClassCode,
        GroupCode: data.GroupCode,
        UpdateDatetime: data.UpdateDatetime,
      },
    });
  };

  const handleClickMinkachange = (index: number): void => {
    const data = resultManagementStudentLedgerDate[index];

    navigate('/reserve/management/minkachange', {
      state: {
        ReservationId: reservationId,
        AreaId: data.AreaId,
        RegionCode: data.RegionCode,
        MinkaId: data.MinkaId,
        MinkaName: data.MinkaName,
        ClassCode: data.ClassCode,
        GroupCode: data.GroupCode,
      },
    });
  };

  const handleClickBack = (): void => {
    window.history.back();
  };

  return {
    resultManagementStudentLedgerDate,
    acceptGenderList,
    sexList,
    handleClickStudentAllocate,
    handleClickMinkachange,
    handleClickBack,
  };
};
export default UseStudentAllocateLedger;
