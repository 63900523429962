import { Container } from '@mui/material';
import Button from '@mui/material/Button/Button';
import Grid from '@mui/material/Grid/Grid';
import TextField from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography/Typography';
import React, { FC } from 'react';
import GenericTemplate from '../../../template/GenericTemplate';
import { MinkaInRegion, ChangeMinkaRequest, MinkaLabelValue } from 'API';
import { ErrorData } from './MinkaChangeErrorData';
import SelectFormControl from 'views/parts/SelectFormControl';
import { SelectValue } from 'types/SelectValue';

const styles = {
  searchField: {
    '&.MuiContainer-root': {
      '@media screen and (min-width: 1200px)': {
        maxWidth: '1400px',
      },
    },
  },

  label: {
    margin: 'auto',
    textAlign: 'right',
    pr: '10px',
    mt: '7px',
  },

  categoryTitle: {
    pl: '10px',
    borderLeft: '5px solid #0058a0',
  },

  inputItem: {
    ph: '2px',
    boxSizing: 'border-box',
    width: '100%',
  },

  button: {
    minWidth: '120px',
    fontWeight: 'bold',
    mt: 1,
    mr: 1,
    float: 'right',
  },

  categoryBlock: {
    mt: '5px',
    px: '10px',
    mb: '5px',
  },

  textField: {
    width: '100%',
    '& input': {
      padding: '8px 14px',
      textAlign: 'center',
    },
    background: '#f4f4f7',
  },

  textArea: {
    width: '100%',
    '& input': {
      padding: '8px 14px',
    },
  },
};

export interface Props {
  handleChangeRegion: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeInput: (_: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickRegist: () => void;
  handleClose: () => void;
  minkaInRegions: MinkaInRegion[];
  minkaList: MinkaLabelValue[];
  inputData: ChangeMinkaRequest;
  minkaName: string;
  afterRegion: string;
  errorAttribute: ErrorData;
}

const MinkaChange: FC<Props> = (props) => {
  return (
    <GenericTemplate title="⺠家変更">
      <Container sx={styles.searchField}>
        <Grid container spacing={0.5} sx={styles.categoryBlock}>
          <Grid item xs={3}>
            <Typography sx={styles.label}>変更前⺠家</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField sx={styles.textField} value={props.minkaName} disabled />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>変更理由</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              sx={styles.textArea}
              multiline
              rows={4}
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              value={props.inputData.ChangeReason}
              error={props.errorAttribute.ChangeReason.IsError}
              helperText={props.errorAttribute.ChangeReason.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>変更後⺠家所在地域</Typography>
          </Grid>
          <Grid item xs={7}>
            <SelectFormControl
              value={props.afterRegion}
              selectName="AfterRegion"
              selectValues={props.minkaInRegions.map((minkaInRegion, idx) => {
                return { value: minkaInRegion.RegionCode, displayName: minkaInRegion.RegionName } as SelectValue;
              })}
              setCondition={props.handleChangeRegion}
              sx={styles.inputItem}
              error={props.errorAttribute.AfterRegion.IsError}
              errorMessage={props.errorAttribute.AfterRegion.ErrorMessage}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.label}>変更後⺠家</Typography>
          </Grid>
          <Grid item xs={7}>
            <SelectFormControl
              value={props.inputData.AfterMinkaId}
              selectName="AfterMinkaId"
              selectValues={props.minkaList.map((minka, idx) => {
                return { value: minka.MinkaId.toString(), displayName: minka.MinkaName } as SelectValue;
              })}
              setCondition={props.handleChangeInput}
              sx={styles.inputItem}
              error={props.errorAttribute.AfterMinkaId.IsError}
              errorMessage={props.errorAttribute.AfterMinkaId.ErrorMessage}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={10}>
            <Button variant="contained" sx={styles.button} onClick={props.handleClose}>
              閉じる
            </Button>
            <Button variant="contained" sx={styles.button} onClick={props.handleClickRegist}>
              登録
            </Button>
          </Grid>
        </Grid>
      </Container>
    </GenericTemplate>
  );
};

export default MinkaChange;
