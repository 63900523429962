/** コードマスタのコード種別 */
export const CodeKind = {
  /** 民家受入可能性別 */
  AcceptanceGender: 'acceptance_gender',
  /** 性別 */
  Sex: 'sex',
  /** 同居人続柄 */
  ResidentsRelationCode: 'residents_relation_code',
  /** レポート種別 */
  ReportCategory: 'report_category',
  /** サービス種別 */
  ServiceCategoryCode: 'service_category_code',
  /** 承認ステータス */
  ApprovalStatusCode: 'approval_status_code',
  /** 予約ステータス */
  ReservationStatusCode: 'reservation_status_code',
  /** 学校種別 */
  SchoolCategoryCode: 'school_category_code',
  /** 設置区分 */
  ClassificationCode: 'classification_code',
  /** 利用者種別 */
  UserCategoryCode: 'user_category_code',
  /** 民家トイレ様式 */
  ToiletType: 'toilet_type',
  /** 民家内喫煙者の有無 */
  SmokeType: 'smoke_type',
};

/** 権限コード */
export const AuthorityCode = {
  /** 1:予約管理者 */
  ReserveAdministrator: '1',
  /** 2:民泊受入管理者 */
  AcceptAdministrator: '2',
  /** 3:旅行代理店仕入 */
  AgentBuyer: '3',
  /** 4:旅行代理店営業 */
  AgentSales: '4',
  /** 5:旅行代理店仕入兼営業 */
  AgentBuyerSales: '5',
  /** 6:サービス提供事業者 */
  Servicer: '6',
  /** 7:学校 */
  School: '7',
};

/** 予約ステータスコード */
export const ReservationStatusCode = {
  /** 1:取消待ち */
  CancelWait: '1',
  /** 2:仮予約 */
  Tentative: '2',
  /** 3:学校決定申込 */
  SchoolApplication: '3',
  /** 4:学校決定 */
  SchoolDecide: '4',
  /** 5:本予約申込 */
  BookingApplication: '5',
  /** 6:本決定 */
  Reserved: '6',
  /** 9:キャンセル */
  Cancel: '9',
};

/** 承認ステータスコード */
export const ApprovalStatusCode = {
  /** 1:未承認 */
  NotApploval: '1',
  /** 2:承認待ち */
  ApprovalWait: '2',
  /** 3:承認済み */
  ApprovalDecide: '3',
  /** 9:キャンセル */
  Cancel: '9',
};

/** 利用者カテゴリコード */
export const UserCategoryCode = {
  /** 01: 民泊事業者 */
  MinpakuCompany: '01',
  /** 02: サービス事業者 */
  ServiceCompany: '02',
  /** 03: 旅行代理店 */
  Agent: '03',
  /** 04: 学校 */
  School: '04',
  /** 05: 民家 */
  Minka: '05',
};
